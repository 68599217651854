<template>
  <el-dialog v-model="visible" width="550px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ form.id === "" ? $t("pcbas.pcba.new") : $t("pcbas.pcba.edit") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="add-cla-top">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" :model="form" label-width="180px">
            <el-row>
              <el-form-item
                label="Machining type ："
                class="custom-el-form-rules"
              >
                <el-input
                  v-model="form.machiningType"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Chip to chip ：">
                <el-input
                  v-model="form.chipToChip"
                  @input="(v) => (form.chipToChip = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Position time：">
                <el-input
                  v-model="form.positionTime"
                  @input="(v) => (form.positionTime = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Tool change：">
                <el-input
                  v-model="form.toolChange"
                  @input="(v) => (form.toolChange = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Index：">
                <el-input
                  v-model="form.indexValue"
                  @input="(v) => (form.indexValue = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Pallet change：">
                <el-input
                  v-model="form.palletChange"
                  @input="(v) => (form.palletChange = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Door open/close time：">
                <el-input
                  v-model="form.openOrCloseTime"
                  @input="
                    (v) => (form.openOrCloseTime = v.replace(/[^\d.]/g, ''))
                  "
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Loading/unloading time：">
                <el-input
                  v-model="form.loadingOrUnloadingTime"
                  @input="
                    (v) =>
                      (form.loadingOrUnloadingTime = v.replace(/[^\d.]/g, ''))
                  "
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="BTS time：">
                <el-input
                  v-model="form.btsTime"
                  @input="(v) => (form.btsTime = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Cavity：">
                <el-input
                  v-model="form.cavity"
                  @input="(v) => (form.cavity = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Maximum Splindle Speed：">
                <el-input
                  v-model="form.maxSpeed"
                  @input="(v) => (form.maxSpeed = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Tool Approach：">
                <el-input
                  v-model="form.toolApproach"
                  @input="(v) => (form.toolApproach = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Tool Retract：">
                <el-input
                  v-model="form.toolRetract"
                  @input="(v) => (form.toolRetract = v.replace(/[^\d.]/g, ''))"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Material：">
                <el-input
                  v-model="form.materialName"
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("pcbas.pcba.cancle") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("pcbas.pcba.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../../assets/js/commonUtil.js";
export default {
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        machiningType: "",
        chipToChip: "",
        positionTime: "",
        toolChange: "",
        indexValue: "",
        palletChange: "",
        openOrCloseTime: "",
        loadingOrUnloadingTime: "",
        btsTime: "",
        cavity: "",
        maxSpeed: "",
        toolApproach: "",
        toolRetract: "",
        materialName: "",
        copyId: "",
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        id: "",
        machiningType: "",
        chipToChip: "",
        positionTime: "",
        toolChange: "",
        indexValue: "",
        palletChange: "",
        openOrCloseTime: "",
        loadingOrUnloadingTime: "",
        btsTime: "",
        cavity: "",
        maxSpeed: "",
        toolApproach: "",
        toolRetract: "",
        materialName: "",
        copyId: "",
      };
    },
    confirm() {
      if (this.form.machiningType == "") {
        commonUtil.showTipInfo("Please enter Machining Type", "", "warning");
        return;
      }
      let data = {
        id: this.form.id,
        machiningType: this.form.machiningType,
        chipToChip:
          this.form.chipToChip === "" ? 0 : parseFloat(this.form.chipToChip),
        positionTime:
          this.form.positionTime === ""
            ? 0
            : parseFloat(this.form.positionTime),
        toolChange:
          this.form.toolChange === "" ? 0 : parseFloat(this.form.toolChange),
        indexValue:
          this.form.indexValue === "" ? 0 : parseFloat(this.form.indexValue),
        palletChange:
          this.form.palletChange === ""
            ? 0
            : parseFloat(this.form.palletChange),
        openOrCloseTime:
          this.form.openOrCloseTime === ""
            ? 0
            : parseFloat(this.form.openOrCloseTime),
        loadingOrUnloadingTime:
          this.form.loadingOrUnloadingTime === ""
            ? 0
            : parseFloat(this.form.loadingOrUnloadingTime),
        btsTime: this.form.btsTime === "" ? 0 : parseFloat(this.form.btsTime),
        cavity: this.form.cavity === "" ? 0 : parseFloat(this.form.cavity),
        maxSpeed:
          this.form.maxSpeed === "" ? 0 : parseFloat(this.form.maxSpeed),
        toolApproach:
          this.form.toolApproach === ""
            ? 0
            : parseFloat(this.form.toolApproach),
        toolRetract:
          this.form.toolRetract === "" ? 0 : parseFloat(this.form.toolRetract),
        materialName: this.form.materialName,
        copyId: this.form.copyId,
      };
      this.$emit("confirmInfo", data);
      // this.close()
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9 {
  padding: 9px;
}
.el-row {
  margin-bottom: 10px;
}
.color-red {
  color: red;
}
.align-right {
  text-align: right;
}
</style>