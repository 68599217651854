<template>
  <el-dialog v-model="visible" width="400px" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16"></div>
        <div @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <div class="rate-cla">
        <el-form
          :inline="true"
          :model="form"
          label-width="100px"
          label-position="left"
          class="custom-el-form-rules"
        >
          <el-form-item :label="$t('userlists.userlist.name') + '：'">
            <el-input v-model="form.name" placeholder="" />
          </el-form-item>
          <el-form-item
            :label="$t('changePriceLists.changePriceList.email') + '：'"
            class="custom-el-form-rules"
          >
            <el-input v-model="form.email" placeholder="" />
          </el-form-item>
        </el-form>
        <!-- <div class="form-item flex">
          <span>{{ $t("userlists.userlist.name") + "：" }}</span>
          <el-input v-model="form.name" placeholder="" />
        </div>
        <div class="form-item flex">
          <span>{{ $t("changePriceLists.changePriceList.email") + "：" }}</span>
          <el-input v-model="form.email" placeholder="" />
        </div> -->
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          @click="addSubmit"
          >{{ $t("pcbas.pcba.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import "@/assets/css/custom-el-form-rules.css";
import commonUtil from "@/assets/js/commonUtil.js";
export default {
  emits: ["getCompleteCC"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        name: "",
        email: "",
        type: 4,
      },
    };
  },
  mounted() {},
  methods: {
    close() {
      this.form = {
        id: "",
        name: "",
        email: "",
        type: 4,
      };
      this.visible = false;
    },
    addSubmit() {
      if (this.form.name == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseEnter") +
            " " +
            this.$t("userlists.userlist.name") +
            "！",
          "",
          "warning"
        );
        return;
      }
      if (this.form.email == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseEnter") +
            " " +
            this.$t("changePriceLists.changePriceList.email") +
            "！",
          "",
          "warning"
        );
        return;
      }
      var re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
      if (!re.test(this.form.email)) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseTrueEmail"),
          "",
          "warning"
        );
        return;
      }
      this.$emit("getCompleteCC", this.form);
      this.close();
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}

.line-32 {
  line-height: 32px;
}
.mg-5 {
  margin: 5px;
}
.mgr-10 {
  margin-right: 10px;
}
.arrows-img {
  width: 20px;
}
</style>
