<template>
  <div class="tcm-content">
    <div class="tcm-content-title">
      <div class="dis-flex">
        {{
          type == "template"
            ? $t("templateLists.templateList.purchasedCell")
            : $t("changePriceLists.changePriceList.purchasedComponents")
        }}
      </div>
      <div class="dis-flex" v-show="type != 'template'">
        <div class="totaol-price-50" style="border-right: 1px solid">
          {{ $t("changePriceLists.changePriceList.purchaseCost") }}:
          {{ oripurchaseCost_PUR }}
        </div>
        <div class="totaol-price-50">
          {{ $t("changePriceLists.changePriceList.purchaseCost") }}:
          {{ purchaseCost_PUR }}
        </div>
      </div>
    </div>
    <div class="dis-flex tcm-form">
      <div class="tcm-form-50" style="border-right: 1px solid">
        <table class="tcm-content-form-table">
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.currency") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.convertCurrency") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.exchangeRate") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.processDescription") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.vendor") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.consumption") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.unitMeasure") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.costUoM") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.subTotalCost") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.priceReference") }}</td>
          </tr>
          <tr v-for="(item, index) in oriFormData" :key="index">
            <td>{{ item.currencyName }}</td>
            <td>{{ item.convertCurrencyName }}</td>
            <td>{{ item.converRate }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.vendor }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.unitMeasureName }}</td>
            <td>{{ item.unitCost }}</td>
            <td>{{ item.totalCost }}</td>
            <td>{{ item.price }}</td>
          </tr>
        </table>
      </div>
      <div class="tcm-form-50">
        <table class="tcm-content-form-table">
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.currency") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.convertCurrency") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.exchangeRate") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.processDescription") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.vendor") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.consumption") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.unitMeasure") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.costUoM") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.subTotalCost") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.priceReference") }}</td>
            <td>{{ $t("templateLists.templateList.operate") }}</td>
          </tr>
          <tr v-for="(item, index) in formData" :key="index">
            <td>{{ item.currencyName }}</td>
            <td>{{ item.convertCurrencyName }}</td>
            <td>{{ item.converRate }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.vendor }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.unitMeasureName }}</td>
            <td>{{ item.unitCost }}</td>
            <td>{{ item.totalCost }}</td>
            <td>{{ item.price }}</td>

            <td class="flex">
              <span
                style="color: #409eff; cursor: pointer"
                @click="editRow(index)"
                >{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span
              >
              <el-icon
                v-if="item.completeApplyId"
                class="Modify-Price"
                :size="15"
                @click="
                  ChangePriceBtn(item.completeApplyId, index, item.ParentId)
                "
                style="color: var(--themeColor)"
                ><Edit
              /></el-icon>
            </td>
          </tr>
        </table>
      </div>
      <!-- <div class="tcm-content-form" v-for="(item, index) in formData" :key="index">
				<div class="tcm-arrows" v-show="type != 'template'">
					<img src="../../assets/img/tcm/zuojiantou.png" class="tcm-arrows-img mgr-10" @click="reorder('left', index)"/>
					<img src="../../assets/img/tcm/youjiantou.png" class="tcm-arrows-img" @click="reorder('right', index)"/>
				</div>
				<div v-show="type == 'template'" style="margin-bottom: 10px">
					<el-row v-show="operateType != 'add'">
						<el-col :span="9">
							<div class="size-14 padding-9-0"><span class="color-red">*</span>Cell No</div>
						</el-col>
						<el-col :span="13"><el-input v-model="item.number" style="width: calc(100% - 20px);" disabled /></el-col>
						<el-col :span="2"></el-col>
					</el-row>
					
					<el-row>
						<el-col :span="9">
							<div class="size-14 padding-9-0"><span class="color-red">*</span>Cell Name</div>
						</el-col>
						<el-col :span="13"><el-input v-model="item.name" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
						<el-col :span="2"></el-col>
					</el-row>
					<el-row>
						<el-col :span="9">
							<div class="size-14 padding-9-0"><span class="color-red">*</span>Commodity</div>
						</el-col>
						<el-col :span="13">
							<el-tree-select clearable v-model="item.commodityId" append-to-body  @change="changeCommodityId($event, index)" style="width: calc(100% - 20px);" :disabled=" operateType == 'detail'" :data="CommodityOptions" :props="defaultProps" check-strictly :render-after-expand="false" :default-expand-all="false" />
						</el-col>
						<el-col :span="2"></el-col>
					</el-row>
					<el-row>
						<el-col :span="9">
							<div class="size-14 padding-9-0"><span class="color-red">*</span>Region</div>
						</el-col>
						<el-col :span="13">
							<el-select clearable  v-model="item.regionId" placeholder="" style="width: calc(100% - 20px);" :disabled=" operateType == 'detail'">
								<el-option
								    v-for="item in regionOptions"
								    :key="item.id"
								    :label="item.name"
								    :value="item.id"
								/>
							</el-select>
						</el-col>
						<el-col :span="2"></el-col>
					</el-row>
					<el-row v-show="operateType != 'add'">
						<el-col :span="9">
							<div class="size-14 padding-9-0">Version</div>
						</el-col>
						<el-col :span="13"><el-input v-model="item.version" style="width: calc(100% - 20px);" disabled /></el-col>
						<el-col :span="2"></el-col>
					</el-row>
					<el-row>
						<el-col :span="9">
							<div class="size-14 padding-9-0">Comments</div>
						</el-col>
						<el-col :span="13"><el-input v-model="item.comment" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
						<el-col :span="2"></el-col>
					</el-row>
				</div>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.processDescription')}}</div>
					</el-col>
					<el-col :span="13" v-show="type == 'template'"><el-input v-model="item.processName" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="13" v-show="type != 'template'"><el-input v-model="item.name" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2">
						<img src="../../assets/img/tcm/renminbi.png" style="width: 25px;margin-top: 3.5px;"/>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.consumption')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.quantity" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.unitMeasure')}}</div>
					</el-col>
					<el-col :span="13">
						<el-select clearable  v-model="item.unit" style="width: calc(100% - 20px);"  placeholder="" :disabled="operateType == 'detail'">
						    <el-option
						      v-for="it in unitOptions"
						      :key="it.id"
						      :label="it.name"
						      :value="it.id"
						    />
						</el-select>
					</el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.costUoM')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.unitCost" style="width: calc(100% - 20px);" :disabled="operateType == 'detail'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.subTotalCost')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.totalCost" style="width: calc(100% - 20px);" disabled /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row>
					<el-col :span="9">
						<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.priceReference')}}</div>
					</el-col>
					<el-col :span="13"><el-input v-model="item.price" style="width: calc(100% - 20px);" :disabled="operateType == 'detail' || type != 'alloperate'" /></el-col>
					<el-col :span="2"></el-col>
				</el-row>
				<el-row v-show="type != 'template' && operateType != 'detail'">
					<el-col>
						<div class="align-right">
							<el-button type="info" @click="deleteMoudle(index)">{{$t('unifiedPrompts.unifiedPrompt.delete')}}</el-button>
							<el-button type="info" @click="copyMoudle(index)">Copy</el-button>
						</div>
						
					</el-col>
				</el-row>
				<el-row v-show="type == 'template' && operateType != 'detail'">
					<el-col>
						<div class="align-right">
							<el-button type="info" color="#335883" @click="cancle">cancle</el-button>
							<el-button type="info" color="#335883" @click="confirm">confirm</el-button>
						</div>
						
					</el-col>
				</el-row>
			</div>
			<div class="tcm-form-solid" v-show="type != 'template' && operateType != 'detail'" @click="addMoudle">
				<el-icon :size="35" color="#8c8c8c"><Plus /></el-icon>
			</div> -->
    </div>
    <PurDia
      ref="purDialog"
      :CommodityId="CommodityId"
      @getChoosePurId="getChoosePurId"
    ></PurDia>
    <EditPur
      ref="editPurDia"
      :unitOptions="unitOptions"
      @submitPur="submitPur"
    ></EditPur>
    <AddRate ref="rateDialog" @rateSubmit="rateSubmit"></AddRate>

    <!-- <ChangePrice ref="ChangePrice" @changePrice="ChangePrice"></ChangePrice> -->
  </div>
</template>

<script>
import {
  getCategorys,
  getTemplatePurchasesDetail,
  getMultiRate,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import PurDia from "../purManagement/purDia.vue";
import EditPur from "./editPur.vue";
import AddRate from "../tcmTemplateManagement/addRate.vue";
// import ChangePrice from "../tcmTemplateManagement/ChangePrice.vue";
export default {
  props: [
    "type",
    "operateType",
    "CommodityId",
    "CommodityName",
    "CommodityOptions",
    "regionOptions",
    "currencyOptions",
    "currencyId",
  ],
  emits: ["goBack", "confirmData"],
  components: {
    PurDia,
    EditPur,
    AddRate,
    // ChangePrice,
  },
  data() {
    return {
      // 原始数据
      oripurchaseCost_PUR: "",
      oriFormData: [],
      // 修改后的数据
      purchaseCost_PUR: "",
      formData: [
        {
          id: "",
          number: "",
          name: "",
          version: "",
          comment: "",
          isEnable: 1,
          processName: "",
          quantity: "",
          unit: "",
          unitCost: "",
          totalCost: "",
          price: "",
          commodityId: "",
          regionId: "",
          converRate_Before: "",
          unitCost_Before: "",
          totalCost_Before: "",
          ParentId: "",
        },
      ],
      // 单位选项
      unitOptions: [],
      // 商品分类显示字段
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 编辑时删除的组件id
      purchaseDelIds: [],
      editIndex: 0,
      addCellList: [],
      addCellExchangeList: [],
    };
  },
  mounted() {
    this.getUnit();
    console.log(this.$route.query, "this.$route.query");
    if (this.$route.query.id) {
      this.ChangePriceId = this.$route.query.id;
    }
  },
  methods: {
    ChangePriceBtn(id, index, ParentId) {
      this.editIndex = index;
      // this.diaType = "choosePrice";
      // this.operateIndex = index;
      // this.$refs.ChangePrice.GetChangePriceId(this.ChangePriceId);
      // this.$parent.ShowChangePriceDig(this.ChangePriceId);
      this.$parent.ShowChangePriceDig(id, ParentId);
    },
    ChangePrice(id) {
      console.log(id, "----aaa");
      this.$emit("GetDetail", id);
    },
    // 获取单位选项
    getUnit() {
      getCategorys(
        {
          TypeID: "MeasureUnit",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.unitOptions = response.data;
          }
        }
      );
    },
    // 取消
    cancle() {
      this.$emit("goBack");
    },
    // 提交
    confirm() {
      this.$emit("confirmData", this.formData);
    },
    // 添加组件
    addMoudle() {
      // console.log(this.CommodityId)
      if (this.CommodityId == "") {
        commonUtil.showTipInfo("请选择商品类别！", "", "warning");
        return;
      }
      this.$refs.purDialog.searchForm.page = 1;
      this.$refs.purDialog.getlist();
      this.$refs.purDialog.visible = true;
    },
    // 添加空组件
    addEmptyMoudle() {
      let random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
          Math.pow(10, 10 - 1)
      );
      this.formData.push({
        id: "",
        number: "",
        name: "",
        version: "",
        comment: "",
        isEnable: 1,
        processName: "",
        quantity: "",
        unit: "",
        unitCost: "",
        totalCost: "",
        price: "",
        commodityId: "",
        regionId: "",
        ParentId: random,
      });
    },
    // 获取添加组件的详情信息
    getChoosePurId(id) {
      // console.log(id)
      let _this = this;
      getTemplatePurchasesDetail(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            // this.CommodityId = response.result.commodityId
            // let cLines = []
            // response.result.cLines.forEach(function(val, index) {
            // 	cLines.push({
            // 		fieldName: val.paramName,
            // 		calculatorTableId: val.calculator_TableId
            // 	})
            // })
            response.result.forEach(function (resVal, resIndex) {
              let moudle = resVal;
              moudle.id = "";
              // moudle.cLines = cLines
              _this.formData.push(moudle);
            });
            _this.purchaseCost_PUR = 0;
            this.formData.forEach(function (val, index) {
              _this.purchaseCost_PUR += parseFloat(
                val.totalCost == "" ? 0 : val.totalCost
              );
            });
            _this.purchaseCost_PUR = _this.purchaseCost_PUR.toFixed(3);
            this.$refs.purDialog.visible = false;
          }
          // console.log(response)
        }
      );
    },
    // 删除组件
    deleteMoudle(index) {
      if (this.purchaseDelIds.indexOf(this.formData[index].id) > -1) {
        this.purchaseDelIds.splice(
          this.purchaseDelIds.indexOf(this.formData[index].id),
          1
        );
      } else {
        this.purchaseDelIds.push(this.formData[index].id);
      }
      // console.log(this.purchaseDelIds)
      this.formData.splice(index, 1);
      if (this.formData.length > 0) {
        this.toCompute(this.formData[0]);
      }
    },
    // 复制组件
    copyMoudle(index) {
      // console.log(this.formData[index])
      // this.formData.push(JSON.parse(JSON.stringify(this.formData[index])))
      let data = JSON.parse(JSON.stringify(this.formData[index]));
      data.id = "";
      this.formData.push(data);
      if (this.formData.length > 0) {
        this.toCompute(this.formData[0]);
      }
      // this.formData.push(this.formData[index])
    },
    // 计算
    toCompute(row) {
      // console.log(row)
      let _this = this;
      if (row.unitCost == "" || row.quantity == "") {
        return;
      }
      row.totalCost = (
        parseFloat(row.quantity) * parseFloat(row.unitCost)
      ).toFixed(3);
      _this.purchaseCost_PUR = 0;
      this.formData.forEach(function (val, index) {
        _this.purchaseCost_PUR += parseFloat(
          val.totalCost == "" ? 0 : val.totalCost
        );
      });
      // console.log(_this.purchaseCost_PUR )
      _this.purchaseCost_PUR = _this.purchaseCost_PUR.toFixed(3);
      this.$parent.$refs.sumTcm.form.purchaseCost_SUM = _this.purchaseCost_PUR;
      let priceDiffrence = 0;
      priceDiffrence =
        parseFloat(_this.purchaseCost_PUR === "" ? 0 : _this.purchaseCost_PUR) -
        parseFloat(
          _this.oripurchaseCost_PUR === "" ? 0 : _this.oripurchaseCost_PUR
        );
      this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM =
        parseFloat(this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM) +
        priceDiffrence;
      this.$parent.$refs.sumTcm.form.totalPriceReport_SUM = parseFloat(
        (
          parseFloat(this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM) *
          parseFloat(
            this.$parent.$refs.gen.genList[0].rate == ""
              ? 0
              : this.$parent.$refs.gen.genList[0].rate
          )
        ).toFixed(3)
      );
    },
    // 修改分类
    changeCommodityId(val, index) {
      this.formData[index].commodityId = val;
      // console.log(val)
      // console.log(index)
    },
    // 切换顺序
    reorder(arrow, index) {
      if (index == 0 && arrow == "left") {
        commonUtil.showTipInfo("已在第一位，不可往左移！", "", "warning");
        return;
        // console.log(index)
      }
      if (index == this.formData.length - 1 && arrow == "right") {
        commonUtil.showTipInfo("已在最后一位，不可往右移！", "", "warning");
        return;
        // console.log(index)
      }
      if (arrow == "left") {
        [this.formData[index - 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index - 1],
        ];
        this.$forceUpdate();
      } else if (arrow == "right") {
        [this.formData[index + 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index + 1],
        ];
        this.$forceUpdate();
      }
      // console.log(arrow)
    },
    //编辑
    editRow(index) {
      console.log(index, "---------");
      // console.log(this.formData[index])
      this.editIndex = index;
      this.$refs.editPurDia.CurrencyOptions = this.currencyOptions;
      this.$refs.editPurDia.form = JSON.parse(
        JSON.stringify(this.formData[index])
      );
      this.$refs.editPurDia.visible = true;
    },
    // 编辑提交
    submitPur(form) {
      // console.log(form)
      this.formData[this.editIndex] = form;
      this.toCompute(this.formData[this.editIndex]);
    },
    // 获取选择组件税率对应关系
    getMultiRates(arr, type) {
      let _this = this;
      let multiRow = [];
      arr.forEach(function (resVal, resIndex) {
        let moudle = resVal;
        moudle.currencyId = _this.currencyId;
        moudle.currId =
          type == "tcm" && moudle.exchangeRate
            ? moudle.exchangeRate.currency
            : moudle.convertCurrencyId;
        moudle.currCNYId = _this.currencyId;
        moudle.exchangeRate = "";
        if (type == "tcm") {
          moudle.name = resVal.programName;
          moudle.unitCost = resVal.totalPriceCurr_SUM;
          moudle.completeApplyId = resVal.id;
        }
        moudle.calculateData = "";
        multiRow.push(moudle);
      });
      _this.addCellList = multiRow;
      // this.$refs.purDialog.visible = false;
      getMultiRate(
        {
          genList: multiRow,
        },
        (response) => {
          if (response && response.code == 200) {
            _this.addCellExchangeList = [];
            let untaxedNum = 0;
            _this.addCellExchangeList = response.data[0].exchangeList;
            _this.addCellExchangeList.forEach(function (excVal, excIndex) {
              if (excVal.exchangeRate === "") {
                excVal.hasRate = false;
                untaxedNum++;
              } else {
                excVal.hasRate = true;
              }
            });
            // 如果其中有无税率的情况则弹窗输入
            if (untaxedNum > 0) {
              _this.$refs.rateDialog.addCellExchangeList =
                _this.addCellExchangeList;
              _this.$refs.rateDialog.visible = true;
            } else {
              // 如果税率都有则转换
              _this.$refs.rateDialog.visible = false;
              _this.addCellExchangeList.forEach(function (rateVal, rateIndex) {
                _this.addCellList.forEach(function (cellVal, cellIndex) {
                  if (rateVal.id == cellVal.id) {
                    cellVal.exchangeRate = rateVal.exchangeRate;
                    cellVal.converRate = rateVal.exchangeRate;
                    cellVal.unitCost =
                      parseFloat(
                        cellVal.unitCost === "" ||
                          cellVal.unitCost === null ||
                          !cellVal.unitCost
                          ? "0"
                          : cellVal.unitCost
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                    cellVal.totalCost =
                      parseFloat(
                        cellVal.totalCost === "" ||
                          cellVal.totalCost === null ||
                          !cellVal.totalCost
                          ? "0"
                          : cellVal.totalCost
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                  }
                });
              });
              this.changePriceRefrence();
              // _this.addCellTransfer()
            }
          }
        }
      );
    },
    changePriceRefrence() {
      this.formData[this.editIndex].completeApplyId = "";
      if (this.addCellList.length > 0) {
        if (this.addCellList[0].completeApplyId) {
          this.formData[this.editIndex].completeApplyId =
            this.addCellList[0].completeApplyId;
        }
        this.formData[this.editIndex].price = this.addCellList[0].name;
        this.formData[this.editIndex].unitCost = this.addCellList[0].unitCost;
        this.toCompute(this.formData[this.editIndex]);
      }
    },
    // 填写完税率后获取并且转化数据
    rateSubmit(rateList) {
      let _this = this;
      rateList.forEach(function (rateVal, rateIndex) {
        _this.addCellList.forEach(function (cellVal, cellIndex) {
          if (rateVal.id == cellVal.id) {
            cellVal.exchangeRate = rateVal.exchangeRate;
            cellVal.converRate = rateVal.exchangeRate;
            cellVal.unitCost =
              parseFloat(
                cellVal.unitCost === "" ||
                  cellVal.unitCost === null ||
                  !cellVal.unitCost
                  ? "0"
                  : cellVal.unitCost
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
            cellVal.totalCost =
              parseFloat(
                cellVal.totalCost === "" ||
                  cellVal.totalCost === null ||
                  !cellVal.totalCost
                  ? "0"
                  : cellVal.totalCost
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
          }
        });
      });
      _this.$refs.rateDialog.visible = false;
      // if (this.diaType == "add") {
      //   this.addCellTransfer();
      // } else if (this.diaType == "choosePrice") {
      this.changePriceRefrence();
      // }
    },
  },
};
</script>

<style scoped>
.tcm-content {
  /* padding: 20px 20px 20px 20px; */
}
.tcm-content-title {
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  line-height: 25px;
  font-weight: bold;
  position: relative;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: space-between;
}
.totaol-price-50 {
  padding: 0 10px;
}
.tcm-form {
  box-sizing: border-box;
  flex-wrap: no-wrap;
  height: calc(100% - 92px);
  background-color: rgba(255, 255, 255, 0.7);
}
.tcm-form-50 {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  overflow: auto;
}
.tcm-content-form {
  width: 30%;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin: 0 1.6%;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.tcm-form-solid {
  width: 30%;
  border-radius: 5px;
  border: 1px dashed #8c8c8c;
  margin: 0 1.6%;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  line-height: 410px;
  cursor: pointer;
}
.dis-flex {
  display: flex;
}
.size-14 {
  font-size: 14px;
  position: relative;
}
.line-32 {
  line-height: 32px;
}
.padding-9-0 {
  padding: 9px 0;
}
.el-row {
  margin-bottom: 10px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.align-right {
  text-align: right;
}
.add-cla {
  padding-top: 5px;
  margin-left: 10px;
  cursor: pointer;
}
.color-red {
  color: red;
  position: absolute;
  left: -8px;
}
.mgr-10 {
  margin-right: 10px;
}
.tcm-arrows {
  text-align: right;
  margin-bottom: 10px;
}
.tcm-arrows-img {
  width: 25px;
  cursor: pointer;
}
table {
  border-spacing: 5px 10px;
  height: calc(100vh - 290px);
}
.tcm-content-form-table tr {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.tcm-content-form-table td {
  width: 120px;
  text-align: center;
}
.Modify-Price {
  margin-left: 10px;
  color: #409eff;
  cursor: pointer;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>