<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">
        {{ $t("homePage.home.MyDraft") }}
      </div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <!-- <div class="handle-btn flex">
      <div class="handle-btn-item" @click="edit">VemChange</div>
    </div> -->
    <div class="regional_table" style="margin-top: 10px">
      <el-table
        border
        stripe
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @row-click="RowClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="partNumber"
          :label="$t('tcmdatalists.tcmdatalist.partNumber')"
          align="center"
        />
        <el-table-column
          prop="programName"
          :label="$t('changePriceLists.changePriceList.programName')"
          align="center"
        />
        <el-table-column
          prop="commodityName"
          :label="$t('tcmdatalists.tcmdatalist.commodity')"
          width="110"
          align="center"
        />

        <el-table-column
          prop="regionName"
          :label="$t('tcmdatalists.tcmdatalist.regionName')"
          align="center"
        />
        <!-- <el-table-column
          prop="documentNo"
          :label="$t('tcmdatalists.tcmdatalist.documentNO')"
          align="center"
        /> -->
        <el-table-column
          prop="tcmRequest"
          :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
          align="center"
        />
        <el-table-column
          prop="partDescription"
          :label="$t('tcmdatalists.tcmdatalist.partDescription')"
          align="center"
        />

        <el-table-column
          prop="quoteType"
          :label="$t('tcmdatalists.tcmdatalist.quotationType')"
          align="center"
        />
        <el-table-column
          prop="tcmOwner"
          :label="$t('tcmdatalists.tcmdatalist.tcmOwner')"
          align="center"
        />

        <el-table-column
          prop="status"
          :label="$t('tcmdatalists.tcmdatalist.workStatus')"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.status == '0'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.notStart") }}
            </div>
            <div v-show="scope.row.status == '2'" class="color-0000FF">
              {{ $t("tcmdatalists.tcmdatalist.Incomplete") }}
            </div>
            <div v-show="scope.row.status == '3'">
              {{ $t("tcmdatalists.tcmdatalist.delivered") }}
            </div>
            <div v-show="scope.row.status == '1'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.inProcess") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="urgency"
          :label="$t('tcmdatalists.tcmdatalist.priority')"
          width="110"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.urgency == 'High'" class="color-ff0000">
              {{ $t("tcmdatalists.tcmdatalist.high") }}
            </div>
            <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.middle") }}
            </div>
            <div v-show="scope.row.urgency == 'Low'">
              {{ $t("tcmdatalists.tcmdatalist.low") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          :label="$t('tcmdatalists.tcmdatalist.startDate')"
          sortable
          align="center"
        />
        <el-table-column
          prop="doDate"
          :label="$t('changePriceLists.changePriceList.dueDate')"
          sortable
          align="center"
        />
        <el-table-column
          width="80"
          fixed="right"
          class-name="lastTd"
          align="center"
          :label="$t('homePage.home.Action')"
        >
          <template #default="scope">
            <el-popover
              placement="bottom"
              trigger="hover"
              v-if="scope.row.status == '2' || scope.row.status == '3'"
            >
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <!-- <div
                  class="action-btn"
                  v-show="scope.row.status == '2' || scope.row.status == '3'"
                  @click="showDetail(scope.row.id)"
                >
                  <img
                    style="width: 15px; height: 15px"
                    src="../../assets/img/tcm/computer.svg"
                  />
                  <span>Computer</span>
                </div> -->
                <div
                  class="action-btn"
                  v-show="scope.row.status == '2' || scope.row.status == '3'"
                >
                  <img
                    style="width: 15px; height: 15px"
                    src="../../assets/img/tcm/revoke.png"
                  />
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.revoke") }}</span>
                </div>
                <!-- <div
                  class="action-btn"
                  v-show="scope.row.status == '0' || scope.row.status == '1'"
                  v-if="this.btnEdit > 0"
                  @click="edit(scope.row.id)"
                >
                  <img
                    style="width: 15px; height: 15px"
                    src="../../assets/img/tcm/edit.svg"
                  />
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div> -->
                <!-- <div
                  class="action-btn"
                  v-show="scope.row.status == '1' && this.btnEdit > 0"
                  @click="edit(scope.row.id)"
                >
                  <img
                    style="width: 15px; height: 15px"
                    src="../../assets/img/tcm/sandclock.svg"
                  />
                  <span>Edit</span>
                </div> -->
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>

  <!-- <div class="tcm-list">
        <div class="tcm-search">
            <table>
                <tr>
                    <td>
                        {{$t('tcmdatalists.tcmdatalist.commodity')}}
                        <el-tree-select clearable :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="searchForm.CommodityId" :data="CommodityOptions" :props="defaultProps" check-strictly :render-after-expand="false" :default-expand-all="true" />
                    </td>
                    <td>
                        {{$t('tcmdatalists.tcmdatalist.date')}}
                        <el-date-picker v-model="searchForm.date" type="daterange" range-separator="To" :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')" :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')" size="default" value-format="YYYY-MM-DD" @change="changeDate" />
                    </td>
                    <td>
                        {{$t('tcmdatalists.tcmdatalist.status')}}
                        <el-select clearable  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="searchForm.Enable">
                            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </td>
                    <td>
                        <el-input v-model="searchForm.Keyword" :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')" />
                    </td>
                    <td>
                        <div class="operate-btn" style="margin-right: 10px;" @click="search">{{$t('tcmdatalists.tcmdatalist.search')}}</div>
                        <!== <div class="operate-btn">Advanced search</div> ==>
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="padding-top: 10px;">
                        <!== <div class="operate-btn mrg-10" v-if="this.btnAdd>0">{{$t('unifiedPrompts.unifiedPrompt.new')}}</div> ==>
                        <!== <div class="operate-btn mrg-10" v-if="this.btnDel>0">{{$t('unifiedPrompts.unifiedPrompt.batchDelete')}}</div> ==>
                    </td>
                </tr>
            </table>
        </div>
        <div class="tcm-hold"></div>
        <div class="tcm-table">
            <el-table :empty-text="$t('tcmlists.tcmlist.noData')" :data="tableData" border style="width: 100%">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column width="55" align="center">
                    <template #default="scope">
                        <img src="../../assets/img/tcm/computer.svg" style="cursor: pointer;" v-show="scope.row.status == '2' || scope.row.status == '3'" @click="showDetail(scope.row.id)" />
                        <img src="../../assets/img/tcm/revoke.png" style="cursor: pointer; height: 25px;" v-show="scope.row.status == '2' || scope.row.status == '3'" />
                        <img src="../../assets/img/tcm/edit.svg" @click="edit(scope.row.id)" style="cursor: pointer;" v-show="scope.row.status == '0'" v-if="this.btnEdit>0" />
                        <img src="../../assets/img/tcm/sandclock.svg" style="cursor: pointer;" @click="edit(scope.row.id)" v-show="scope.row.status == '1'" v-if="this.btnEdit>0" />
                    </template>
                </el-table-column>
                <el-table-column prop="commodityName" :label="($t('tcmdatalists.tcmdatalist.commodity'))" width="110" align="center" />
				<el-table-column prop="programName" :label="$t('changePriceLists.changePriceList.programName')" align="center"/>
                <el-table-column prop="regionName" :label="($t('tcmdatalists.tcmdatalist.regionName'))" align="center" />
                <el-table-column prop="documentNo" :label="($t('tcmdatalists.tcmdatalist.documentNO'))" align="center" />
                <el-table-column prop="tcmRequest" :label="($t('tcmdatalists.tcmdatalist.tcmRequester'))" align="center" />
                <el-table-column prop="partDescription" :label="($t('tcmdatalists.tcmdatalist.partDescription'))" align="center" />
                <el-table-column prop="partNumber" :label="($t('tcmdatalists.tcmdatalist.partNumber'))" align="center" />
                <el-table-column prop="date" :label="($t('tcmdatalists.tcmdatalist.startDate'))" sortable align="center" />
                <el-table-column prop="doDate" :label="$t('changePriceLists.changePriceList.dueDate')" sortable align="center" />
                <el-table-column prop="urgency" :label="($t('tcmdatalists.tcmdatalist.priority'))" width="110" align="center">
                    <template #default="scope">
                        <div v-show="scope.row.urgency == 'High'" class="color-ff0000">{{$t('tcmdatalists.tcmdatalist.high')}}</div>
                        <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23">{{$t('tcmdatalists.tcmdatalist.middle')}}</div>
                        <div v-show="scope.row.urgency == 'Low'">{{$t('tcmdatalists.tcmdatalist.low')}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="quoteType" :label="($t('tcmdatalists.tcmdatalist.quotationType'))" align="center" />
                <el-table-column prop="tcmOwner" :label="($t('tcmdatalists.tcmdatalist.tcmOwner'))" align="center" />
                <el-table-column prop="status" :label="($t('tcmdatalists.tcmdatalist.workStatus'))" align="center">
                    <template #default="scope">
                        <div v-show="scope.row.status == '0'" class="color-F59A23">{{$t('tcmdatalists.tcmdatalist.notStart')}}</div>
                        <div v-show="scope.row.status == '2'" class="color-0000FF">{{$t('tcmdatalists.tcmdatalist.Incomplete')}}</div>
                        <div v-show="scope.row.status == '3'">{{$t('tcmdatalists.tcmdatalist.delivered')}}</div>
                        <div v-show="scope.row.status == '1'" class="color-D9001B">{{$t('tcmdatalists.tcmdatalist.inProcess')}}</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-cla">
            <div>
                <el-input v-model="searchForm.limit" @input="val=> searchForm.limit = val.replace(/[^\d]/g, '')" min="1" style="width: 130px" @change="handleSizeChange">
                    <template #suffix>
                        {{$t('ccls.ccl.barPage')}}
                    </template>
                </el-input>
            </div>
            <el-pagination layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page="searchForm.page" @current-change="handleCurrentChange" />
        </div>

    </div> -->
</template>

<script>
import {
  GetOrgs,
  getApplicationList,
  deleteApplyDetail,
  updateStatusCostApplys,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import { ElMessageBox } from "element-plus";
export default {
  name: "DraftList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: "",
        PageType: 0,
        isDraft: 1,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
        isDisable: -1,
      },
      // commodity选项
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // status选项
      statusOptions: [],
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      timers: "",
    };
  },
  mounted() {
    this.autoHeight();
    this.getList();
    this.getCommdity();
    this.timer = setTimeout(this.gettime, 1000);
    this.getUnits();
  },

  methods: {
    RowClick(row) {
      // v-show="scope.row.status == '0' || scope.row.status == '1'"
      //             v-if="this.btnEdit > 0"

      if (this.btnEdit > 0) {
        if (row.status == "1" || row.status == "0") {
          this.edit(row.id);
        }
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 205; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.timers = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        this.statusOptions = [
          {
            value: 0,
            label: "All",
          },
          {
            value: 1,
            label: "Enable",
          },
          {
            value: 2,
            label: "Disable",
          },
        ];
      } else {
        this.statusOptions = [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "激活",
          },
          {
            value: 2,
            label: "禁用",
          },
        ];
      }
      clearTimeout(this.timers);
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加" || e.name == "Add") {
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.name == "Delete") {
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.name == "Edit") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    getList() {
      this.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: "",
        PageType: 0,
        isDraft: 1,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
        isDisable: -1,
      };
      this.search();
    },
    search() {
      getApplicationList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 新增
    addEntry() {
      this.$router.push({
        path: "/tcmAdd",
        name: "TcmAdd",
        query: {
          type: "add",
        },
      });
    },
    // 删除
    deleteApp() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      deleteApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 详情
    showDetail(id) {
      this.$router.push({
        path: "/addOperate",
        name: "AddOperate",
        query: {
          type: "detail",
          disabled: true,
          id: id,
          crumbs: ["My Draft", "My Draft Detail"],
        },
      });
    },
    // 编辑
    edit(id) {
      this.$router.push({
        path: "/addOperate",
        name: "AddOperate",
        query: {
          type: "edit",
          id: id,
          crumbs: ["My Draft", "My Draft Edit"],
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>
