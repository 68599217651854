<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">Commodity Management</div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="add"
        v-if="this.btnAdd > 0"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
    </div>
    <el-row :gutter="10">
      <el-col
        :span="4"
        style="
          width: 250px;
          min-width: 250px;
          max-width: 250px;
          background-color: #fff;
        "
      >
        <div class="tree-cla">
          <div class="tree-cla-search">
            <input
              v-model="filterText"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </div>
          <div :style="{ height: TableHeight + 'px' }" class="tree-box">
            <el-tree
              ref="treeRef"
              class="filter-tree"
              :data="treedata"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="20" style="max-width: calc(100% - 250px)">
        <div class="regional_table">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            border
            stripe
            style="width: 100%"
            :height="TableHeight"
            :data="commodityList"
            @row-click="RowClick"
          >
            <el-table-column
              prop="name"
              :label="$t('userlists.userlist.names')"
              align="center"
            />
            <el-table-column
              prop="parentName"
              :label="$t('userlists.userlist.parent')"
              align="center"
            />
            <el-table-column
              prop="isEnable"
              :label="$t('userlists.userlist.enable')"
              align="center"
            >
              <template #default="scope">
                <el-icon
                  :size="16"
                  color="#40D4A8"
                  v-show="scope.row.isEnable == '1'"
                >
                  <SuccessFilled />
                </el-icon>
                <el-icon
                  :size="16"
                  color="#F56C6C"
                  v-show="scope.row.isEnable == '2'"
                >
                  <CircleCloseFilled />
                </el-icon>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('templateLists.templateList.operate')"
              width="80"
              fixed="right"
              class-name="lastTd"
              align="center"
            >
              <template #default="scope">
                <el-popover placement="bottom" trigger="hover">
                  <template #reference>
                    <div style="text-align: center">
                      <el-icon :size="15"><MoreFilled /></el-icon>
                    </div>
                  </template>
                  <div class="action-box">
                    <div
                      class="action-btn"
                      @click="del(scope.row)"
                      v-if="this.btnDel > 0"
                    >
                      <el-icon><Delete /></el-icon>
                      <span>
                        {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
                      </span>
                    </div>
                    <div
                      class="action-btn"
                      @click="Edit(scope.row)"
                      v-if="this.btnEdit > 0"
                    >
                      <el-icon><Edit /></el-icon>
                      <span>
                        {{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span
                      >
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div>
            Showing {{ pageSize }} from {{ total }} data
            <el-popover placement="top" :width="70" trigger="click">
              <template #reference>
                <el-icon
                  style="
                    margin-left: 5px;
                    font-size: 17px;
                    vertical-align: middle;
                  "
                  ><Operation
                /></el-icon>
              </template>
              <el-input
                v-model="searchForm.limit"
                @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
                :min="1"
                style="width: 130px"
                @change="handleSizeChange"
              >
                <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
              </el-input>
            </el-popover>
          </div>
          <el-pagination
            layout="prev, pager, next"
            background
            :total="total"
            :page-size="pageSize"
            :current-page="searchForm.page"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-col>
    </el-row>
    <AddCommodity
      ref="addDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="addSubmit"
    ></AddCommodity>
    <EditCommodity
      ref="editDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="editSubmit"
    ></EditCommodity>
    <MoudleDia ref="moudleDialog"></MoudleDia>
  </div>

  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <el-row>
        <el-col :span="20">
          <table>
            <tr>
              <td>
                <el-input
                  v-model="searchForm.Keyword"
                  :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                />
              </td>
              <td>
                <div
                  class="operate-btn"
                  style="margin-right: 10px"
                  @click="search"
                >
                  {{ $t("userlists.userlist.search") }}
                </div>
                <!== <div class="operate-btn">Advanced search</div> ==>
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="4">
          <div class="align-right">
            <div
              class="operate-btn"
              style="margin-top: 3px"
              @click="add"
              v-if="this.btnAdd > 0"
            >
              {{ $t("unifiedPrompts.unifiedPrompt.new") }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-row :gutter="10">
        <el-col
          :span="4"
          style="width: 250px; min-width: 250px; max-width: 250px"
        >
          <div class="tree-cla">
            <el-input
              v-model="filterText"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
              style="margin-bottom: 10px"
            />
            <el-tree
              :empty-text="$t('tcmlists.tcmlist.noData')"
              ref="treeRef"
              class="filter-tree"
              :data="treedata"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              @node-click="handleNodeClick"
            />
          </div>
        </el-col>
        <el-col :span="20" style="max-width: calc(100% - 250px)">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="commodityList"
            border
            style="width: 100%"
          >
            <el-table-column
              :label="$t('userlists.userlist.operate')"
              align="center"
              width="250"
            >
              <template #default="scope">
                <div class="flex-around">
                  <div
                    class="operate-btnx"
                    @click="Edit(scope.row)"
                    v-if="this.btnEdit > 0"
                  >
                    {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
                  </div>
                  <div
                    class="operate-btnx"
                    @click="del(scope.row)"
                    v-if="this.btnDel > 0"
                  >
                    {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('userlists.userlist.names')"
              align="center"
            />
            <el-table-column
              prop="parentName"
              :label="$t('userlists.userlist.parent')"
              align="center"
            />
            <el-table-column
              prop="isEnable"
              :label="$t('userlists.userlist.enable')"
              align="center"
            >
              <template #default="scope">
                <el-icon
                  :size="16"
                  color="#40D4A8"
                  v-show="scope.row.isEnable == '1'"
                >
                  <SuccessFilled />
                </el-icon>
                <el-icon
                  :size="16"
                  color="#F56C6C"
                  v-show="scope.row.isEnable == '2'"
                >
                  <CircleCloseFilled />
                </el-icon>
              </template>
            </el-table-column>
          </el-table>
          <div class="page-cla">
            <div>
              <el-input
                v-model="searchForm.limit"
                @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
                min="1"
                style="width: 130px"
                @change="handleSizeChange"
              >
                <template #suffix>
                  {{ $t("ccls.ccl.barPage") }}
                </template>
              </el-input>
            </div>
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              :current-page="searchForm.page"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <AddCommodity
      ref="addDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="addSubmit"
    ></AddCommodity>
    <EditCommodity
      ref="editDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="editSubmit"
    ></EditCommodity>
    <MoudleDia ref="moudleDialog"></MoudleDia>
  </div> -->
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
import AddCommodity from "./addCommodity.vue";
import EditCommodity from "./editCommodity.vue";
import {
  GetOrgs,
  CommodityLsit,
  addCommodity,
  delCommodity,
  editCommodity,
} from "@/assets/js/requestApi.js";
export default {
  name: "commoditylist",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  components: {
    AddCommodity,
    EditCommodity,
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        id: "",
        Name: "",
        NameEn: "",
        ParentId: "",
        ParentName: "",
        Keyword: "",
        page: 1,
        limit: 10,
      },
      // status选项
      statusOptions: [
        {
          value: "",
          label: "All",
        },
        {
          value: "1",
          label: "Enable",
        },
        {
          value: "2",
          label: "Disable",
        },
      ],
      commodityList: [],
      total: 0,
      pageSize: 10,
      filterText: "",
      treedata: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
    };
  },

  mounted() {
    this.autoHeight();
    this.initList();
    this.getTreeData();
    this.search();
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    RowClick(row) {
      if (this.btnEdit > 0) this.Edit(row);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        console.log(1);
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.$refs.addDialog.getUnits();
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加" || e.domId == "Add") {
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.domId == "Delete") {
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.domId == "Edit") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    // 渲染列表
    initList() {
      this.searchForm = {
        id: "",
        name: "",
        nameEn: "",
        parentId: "",
        parentName: "",
        Keyword: "",
        page: 1,
        limit: 10,
      };
      this.search();
    },
    // 查询
    search() {
      CommodityLsit(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.commodityList = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 删除用户
    del(row) {
      if (row.parentId === "root") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.delRootInfo"),
          "",
          "warning"
        );
        return;
      }
      delCommodity([row.id], (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.getTreeData();
          this.search();
        }
      });
    },
    // 新增
    add() {
      if (this.searchForm.id == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseDChooseCommdity"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addDialog.visible = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    addSubmit(form) {
      addCommodity(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
        }
        this.getTreeData();
        this.search();
      });
    },
    // 编辑
    Edit(row) {
      this.$refs.editDialog.visible = true;
      this.$refs.editDialog.form.id = row.id;
      this.$refs.editDialog.form.name = row.name;
      this.$refs.editDialog.form.nameEn = row.nameEn;
      this.$refs.editDialog.form.parentName = row.parentName;
      this.$refs.editDialog.form.parentId = row.parentId;
      this.$refs.editDialog.form.isEnable = row.isEnable;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    editSubmit(form) {
      editCommodity(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.editSuccess"),
            "",
            "success"
          );
          this.getTreeData();
          this.search();
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 树转化
    getTreeData() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            let data = response.Result;
            data.forEach(function (val) {
              val.disabled = val.isEnable == 1 ? false : true;
            });
            this.treedata = _this.transferList(response.Result);
          }
        }
      );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {
      this.searchForm.id = data.Id;
      this.searchForm.name = data.Name;
      this.$refs.addDialog.form.parentName = data.Name;
      this.$refs.addDialog.form.parentId = data.Id;
      this.search();
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.Id === item.ParentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
    // 显示选择部门弹窗
    showMoudleDia(val) {
      if (val) {
        this.$refs.moudleDialog.visible = true;
      }
    },
    // 显示选择部门弹窗
    showMoudleDia(val) {
      if (val) {
        this.$refs.moudleDialog.visible = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.tree-box {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
