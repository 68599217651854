<template>
	<div>
		<div class="add-top">
			<el-row>
				<el-col :span="10">
					<el-row style="padding-top: 15px;">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Volume</div>
								<el-input v-model="form.volume"  :disabled="type == 'detail'" @input="(v) => (form.volume = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">cm3</div>
							</div>
						</el-col>
						
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Density</div>
								<el-input v-model="form.density" :disabled="type == 'detail'" @input="(v) => (form.density = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">g/cm3</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Net Weight</div>
								<el-input v-model="form.netWeight" disabled placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">g</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">No. of Cavities</div>
								<el-input v-model="form.cavities" :disabled="type == 'detail'" @input="(v) => (form.cavities = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">Numbers</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Runner Length</div>
								<el-input v-model="form.length" :disabled="type == 'detail'" @input="(v) => (form.length = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">mm</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Runner Diameter</div>
								<el-input v-model="form.diameter" :disabled="type == 'detail'" @input="(v) => (form.diameter = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">mm</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Runner Volume</div>
								<el-input v-model="form.runnerVolume" disabled placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">mm3</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Runner Weight</div>
								<el-input v-model="form.runnerWeight" disabled placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">g.</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Gross Weight</div>
								<el-input v-model="form.grossWeight" disabled placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">g</div>
							</div>
						</el-col>
					</el-row>
				</el-col>
				<el-col :span="4" v-show="type != 'detail'">
					<div class="operate-btn" @click="compute">Compute</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import {GetPartComputeResultByParameter} from '../../assets/js/requestApi.js'
	import commonUtil from '../../assets/js/commonUtil.js'
	export default {
		props: ['type'],
		data() {
			return {
				form: {
					volume: '',
					density: '',
					cavities: '',
					length: '',
					diameter: '',
					netWeight: '',
					runnerVolume: '',
					runnerWeight: '',
					grossWeight: ''
				},
				tonnageList: ['50', '60', '80', '85', '90', '100', '120', '150', '160', '180', '200', '220', '250', '260', '280', '300', '330', '350', '380', '400', '450', '500']
			}
		},
		methods: {
			// 计算
			compute() {
				if (this.form.cavities === '0') {
					commonUtil.showTipInfo('No. of Cavities can not be zero! ', '', 'warning');
					return;
				}
				let form = {
					volume: this.form.volume === '' ? 0 : parseFloat(this.form.volume),
					density: this.form.density === '' ? 0 : parseFloat(this.form.density),
					cavities: this.form.cavities === '' ? 1 : parseFloat(this.form.cavities),
					length: this.form.length === '' ? 0 : parseFloat(this.form.length),
					diameter: this.form.diameter === '' ? 0 : parseFloat(this.form.diameter)
				}
				GetPartComputeResultByParameter(form, response=>{
					// console.log(response)
					if (response && response.code == 200) {
						this.form.netWeight = response.result.netWeight
						this.form.runnerVolume = response.result.runnerVolume
						this.form.runnerWeight = response.result.runnerWeight
						this.form.grossWeight = response.result.grossWeight
					}
				})
			}
		}
	}
</script>

<style scoped>
	.add-top{
		padding: 20px;
		box-sizing: border-box;
	}
	.dis-flex{
		display: flex;
		justify-content: space-around;
	}
	.line-32{
		line-height: 32px;
	}
	.height-32{
		height: 32px;
	}
	.mgr-20{
		margin-right: 20px;
	}
	.mgr-10 {
		margin-right: 10px;
	}
	.mgt-15{
		margin-top: 12px;
	}
	.align-right{
		text-align: right;
	}
	.add-top-text{
		font-size: 14px;
		width: 150px;
	}
	.operate-btn{
		border-radius: 5px;
		background-color: var(--themeColor);
		color: #fff;
		text-align: center;
		padding: 7px 10px;
		display: inline-block;
		min-width: 80px;
		cursor: pointer;
	}
	.size-16{
		font-size: 16px;
	}
	.text-sub{
		font-size: 14px;
		margin-left: 20px;
		margin-top: 10px;
	}
</style>