
<template>
  <el-dialog
    v-model="visible"
    width="1200px"
    :destroy-on-close="true"
    :show-close="false"
  >
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">{{ $t("userlists.userlist.assignUsers") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-row :gutter="10">
        <el-col :span="4">
          <div class="tree-cla">
            <el-input
              v-model="filterText"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
              style="margin-bottom: 10px"
            />
            <el-tree
              ref="treeRef"
              class="filter-tree"
              :data="treedata"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              @node-click="handleNodeClick"
            />
          </div>
        </el-col>
        <el-col :span="20">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            ref="roleTable"
            :data="user_List"
            border
            style="width: 100%"
            @toggleRowSelection="toggleRowSelection(arr)"
            @select-all="handleAllSelection"
            :row-key="
              (row) => {
                return row.id;
              }
            "
            @select="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              reserve-selection
            />
            <el-table-column
              prop="account"
              :label="$t('userlists.userlist.jobNumber')"
              align="center"
            />
            <el-table-column
              prop="name"
              :label="$t('userlists.userlist.name')"
              align="center"
            />
            <el-table-column
              prop="email"
              :label="$t('userlists.userlist.mailbox')"
              align="center"
            />
            <el-table-column
              prop="phone"
              :label="$t('userlists.userlist.phone')"
              align="center"
            />
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="searchForm.page"
              :page-size="searchForm.limit"
              layout="prev, pager, next"
              :total="this.total"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  treeDepartment,
  userList,
  showTreeRole,
} from "@/assets/js/requestApi.js";
import { nextTick } from "vue";

export default {
  props: ["roleId"],
  emits: ["handleSubmit"],
  data() {
    return {
      visible: false,
      filterText: "",
      defaultProps: {
        children: "children",
        label: "name",
      },
      treedata: [],
      user_List: [],
      form: {
        roleId: "",
        userIds: [],
      },
      searchForm: {
        id: "",
        orgId: "",
        account: "",
        name: "",
        email: "",
        phone: "",
        status: "",
        organizationIds: "",
        organizations: "",
        count: "",
        keywords: "",
        page: 1, //当前页
        limit: 10, //每页
      },
      total: 0,
      // 已勾选数据
      chooseData: [],
    };
  },

  methods: {
    close() {
      this.$parent.search();
      this.visible = false;
      this.searchForm.page = 1;
      this.$parent.searchForm.id = "";
    },
    handleSubmit() {
      let _this = this;
      //this.chooseData = this.$refs.roleTable.getSelectionRows();
      _this.form.userIds = [];
      _this.chooseData.forEach(function (val) {
        _this.form.userIds.push(val);
      });
      this.form.roleId = this.roleId;
      this.$emit("handleSubmit", this.form);
      this.close();
    },
    //全部取消/选中
    handleAllSelection(data) {
      var _this = this;
      var selection = this.$refs.roleTable.store.states.isAllSelected.value; //默认是全部取消
      if (selection) {
        //全选 当页的数据全部选中
        _this.chooseData = [
          ..._this.chooseData,
          ...this.user_List.map((x) => x.id),
        ];
      } else {
        //全不选  当页的数据全部取消选中
        for (var i in this.user_List) {
          var item = this.user_List[i];
          var arrIndex = this.chooseData.findIndex((x) => x == item.id);
          if (arrIndex != -1) {
            _this.chooseData.splice(arrIndex, 1);
          }
        }
      }
      this.distinct();
    },
    removeOrAddChoseData(data, row) {
      let selected = true;
      if (row) {
        selected = data.length && data.indexOf(row) !== -1;
      }
      if (!selected) {
        //当前行被删除
        //删除选中项数据
        let index = this.chooseData.findIndex((x) => x == row.id);
        this.chooseData.splice(index, 1);
      } else {
        this.chooseData.push(row.id);
      }
    },
    distinct() {
      let selectionArr = [];
      selectionArr = this.chooseData;
      selectionArr = new Set(selectionArr); //去重
      this.chooseData = [...selectionArr];
    },
    // 表格多选
    handleSelectionChange(data, row) {
      this.removeOrAddChoseData(data, row);
      this.distinct();
    },
    // 树点击
    handleNodeClick(data) {
      this.searchForm.orgId = data.id;
      this.searchForm.name = data.name;
      this.getlist();
    },
    //从接口中获取用户已经设置的角色
    getRemoteUserRoles(then) {
      showTreeRole({ roleId: this.roleId }, (response) => {
        if (response && response.code == 200) {
          this.chooseData = response.data.map((x) => x.id);
          if (then) then();
        }
      });
    },
    // 列表渲染
    getlist() {
      var _this = this;
      userList(_this.searchForm, (response) => {
        if (response && response.code == 200) {
          _this.user_List = response.data;
          _this.total = response.count;
          // 用户角色回显
          nextTick(function () {
            _this.reShow();
          });
        }
      });
    },
    //回显
    reShow() {
      var _this = this;
      for (let i = 0; i < _this.user_List.length; i++) {
        for (let j = 0; j < _this.chooseData.length; j++) {
          if (_this.user_List[i].id == _this.chooseData[j]) {
            _this.$refs.roleTable.toggleRowSelection(_this.user_List[i], true);
          }
        }
      }
    },
    //树渲染
    getTreeData() {
      treeDepartment({ id: "" }, (response) => {
        if (response && response.code == 200) {
          this.treedata = this.transferList(response.data);
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.id === item.parentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.getlist();
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.tcm-table {
  height: calc(100% - 90px);
  padding: 0 20px;
  box-sizing: border-box;
}
.tree-cla {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #ebeef5;
}
.el-row {
  height: 100%;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>