<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">Exchange Rate</div>
      <div class="main-handle flex">
        <!-- <div class="main-handle-item">
         
        </div> -->
        <div class="main-handle-item">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>

        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div class="handle-btn-item" @click="add" v-if="this.btnAdd > 0">
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div class="handle-btn-item" @click="bathOperate('start')">
        {{ $t("userlists.userlist.batchEnable") }}
      </div>
      <div class="handle-btn-item" @click="bathOperate('stop')">
        {{ $t("userlists.userlist.batchDisabling") }}
      </div>
      <div style="margin-left: 10px">
        <span>{{ $t("userlists.userlist.revisions") }}</span>
        <el-switch
          v-model="historyVal"
          class="ml-2"
          inline-prompt
          style="--el-switch-on-color: #335883"
          active-text="Y"
          inactive-text="N"
        />
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @row-click="RowClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="ids"
          :label="$t('userlists.userlist.id')"
          align="center"
        />
        <el-table-column
          prop="name"
          :label="$t('userlists.userlist.names')"
          align="center"
        />
        <el-table-column
          prop="currencyName"
          :label="$t('userlists.userlist.currentCurrency')"
          align="center"
        />
        <el-table-column
          prop="currencyCNYReportCurrencyName"
          :label="$t('userlists.userlist.reportCurrency')"
          align="center"
        />
        <!-- <el-table-column prop="currencySymbol" label="symbol" align="center" /> -->
        <el-table-column
          prop="exchangeRate"
          :label="$t('userlists.userlist.exchangeRate')"
          align="center"
        />
        <el-table-column
          prop="version"
          :label="$t('userlists.userlist.version')"
          align="center"
        />
        <el-table-column
          :label="$t('userlists.userlist.enable')"
          align="center"
          width="100"
          prop="isEnable"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.isEnable == '1'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.isEnable == '2'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          align="center"
          fixed="right"
          class-name="lastTd"
          prop="action"
          label="action"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div
                  class="action-btn"
                  @click="edit(scope.row)"
                  v-if="this.btnEdit > 0"
                >
                  <el-icon><Edit /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
                <div
                  class="action-btn"
                  @click="del(scope.row.id)"
                  v-if="this.btnDel > 0"
                >
                  <el-icon><Delete /></el-icon>
                  <span> {{ $t("unifiedPrompts.unifiedPrompt.delete") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        Showing {{ pageSize }} from {{ total }} data
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <AddDia ref="addDialog" @confirmInfo="confirmInfo"></AddDia>
  </div>
  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <el-row>
        <el-col :span="6">
          <table>
            <tr>
              <td>
                <el-input
                  v-model="searchForm.Keyword"
                  :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                />
              </td>
              <td>
                <div
                  class="operate-btn"
                  style="margin-right: 10px"
                  @click="search"
                >
                  {{ $t("userlists.userlist.search") }}
                </div>
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="12">
          <div class="align-right">
            <div
              class="operate-btn"
              style="margin-right: 10px"
              @click="add"
              v-if="this.btnAdd > 0"
            >
              {{ $t("unifiedPrompts.unifiedPrompt.new") }}
            </div>
            <div
              class="operate-btn"
              style="margin-right: 10px"
              @click="bathOperate('start')"
            >
              {{ $t("userlists.userlist.batchEnable") }}
            </div>
            <div
              class="operate-btn"
              style="margin-right: 10px"
              @click="bathOperate('stop')"
            >
              {{ $t("userlists.userlist.batchDisabling") }}
            </div>

            <!== <div class="operate-btn" style="margin-right: 10px;" @click="bathOperate('delete')">批量删除</div> ==>
          </div>
        </el-col>
        <el-col :span="4">
          <div style="text-align: right">
            <span style="line-height: 32px; margin-right: 10px">{{
              $t("userlists.userlist.revisions")
            }}</span>
            <el-switch
              v-model="historyVal"
              class="ml-2"
              inline-prompt
              style="--el-switch-on-color: #335883"
              active-text="Y"
              inactive-text="N"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :empty-text="$t('tcmlists.tcmlist.noData')"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          :label="$t('userlists.userlist.operate')"
          align="center"
          width="200"
        >
          <template #default="scope">
            <div class="flex-around">
              <div
                class="operate-btnx"
                @click="edit(scope.row)"
                v-if="this.btnEdit > 0"
              >
                {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
              </div>
              <div
                class="operate-btnx"
                @click="del(scope.row.id)"
                v-if="this.btnDel > 0"
              >
                {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="ids"
          :label="$t('userlists.userlist.id')"
          align="center"
        />
        <el-table-column
          prop="name"
          :label="$t('userlists.userlist.names')"
          align="center"
        />
        <el-table-column
          prop="currencyName"
          :label="$t('userlists.userlist.currentCurrency')"
          align="center"
        />
        <el-table-column
          prop="currencyCNYReportCurrencyName"
          :label="$t('userlists.userlist.reportCurrency')"
          align="center"
        />
        <!== <el-table-column prop="currencySymbol" label="symbol" align="center" /> ==>
        <el-table-column
          prop="exchangeRate"
          :label="$t('userlists.userlist.exchangeRate')"
          align="center"
        />
        <el-table-column
          prop="version"
          :label="$t('userlists.userlist.version')"
          align="center"
        />
        <el-table-column
          :label="$t('userlists.userlist.enable')"
          align="center"
          width="100"
          prop="isEnable"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.isEnable == '1'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.isEnable == '2'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-cla">
        <div>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix>
              {{ $t("ccls.ccl.barPage") }}
            </template>
          </el-input>
        </div>
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <AddDia ref="addDialog" @confirmInfo="confirmInfo"></AddDia>
  </div> -->
</template>

<script>
import {
  loadExchangeRatess,
  AddOrUpdateExchangeRatess,
  deleteOneExchangeRatess,
  deleteExchangeRatess,
  EnableByIdsExchangeRatess,
  DisableByIdsExchangeRatess,
} from "@/assets/js/requestApi";
import commonUtil from "../../assets/js/commonUtil.js";
import AddDia from "./addDia.vue";
export default {
  name: "RateList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  components: {
    AddDia,
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        Keyword: "",
        page: 1, //当前页
        limit: 10, //每页个数
        VersionType: 1,
      },
      total: 0,
      pageSize: 10,
      tableData: [],
      currency: {
        id: "",
        currencyCNYReportCurrencyName: "",
        currencyName: "",
      },
      // 某一条数据
      list: [],
      // 已勾选的数据
      chooseData: [],
      // 是否为历史版本
      historyVal: false,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
    };
  },
  watch: {
    historyVal(val) {
      if (val) {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 2;
        this.search();
      } else {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 1;
        this.search();
      }
    },
  },
  mounted() {
    this.autoHeight();
    this.initList();
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    RowClick(row) {
      if (this.btnEdit > 0) {
        this.edit(row);
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加" || e.domId == "Add") {
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.domId == "Delete") {
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.domId == "Edit") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    // 渲染列表
    initList() {
      this.searchForm = {
        Keyword: "",
        page: 1, //当前页
        limit: 10, //每页个数
        VersionType: 1,
      };
      this.search();
    },
    // 查询
    search() {
      loadExchangeRatess(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 删除
    del(id) {
      let data = [];
      data.push(id);
      deleteOneExchangeRatess(data, (response) => {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
          "",
          "success"
        );
        this.search();
      });
    },
    // 新增
    add() {
      this.$refs.addDialog.getRegion();
      this.$refs.addDialog.form = {
        id: "",
        name: "",
        currency: "",
        currencyCNYReportCurrency: "",
        version: 1,
        exchangeRate: "",
        isEnable: true,
      };
      this.$refs.addDialog.visible = true;
    },
    // 新增提交
    confirmInfo(form) {
      let data = {
        // id: form.id,
        // id: '',
        name: form.name,
        currency: form.currency,
        currencyCNYReportCurrency: form.currencyCNYReportCurrency,
        version: form.version,
        exchangeRate: form.exchangeRate,
        isEnable: form.isEnable ? 1 : 2,
      };
      AddOrUpdateExchangeRatess(data, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 编辑
    edit(row) {
      this.$refs.addDialog.getRegion();
      this.$refs.addDialog.form.id = row.id;
      this.$refs.addDialog.form.name = row.name;
      this.$refs.addDialog.form.currency = row.currencyId;
      this.$refs.addDialog.form.currencyCNYReportCurrency =
        row.currencyCNYReportCurrencyId;
      this.$refs.addDialog.form.version = Number(row.version) + 1;
      this.$refs.addDialog.form.exchangeRate = row.exchangeRate;
      this.$refs.addDialog.form.isEnable = row.isEnable == 1 ? true : false;
      this.$refs.addDialog.visible = true;
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 批量操作
    bathOperate(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      if (type == "delete") {
        deleteExchangeRatess(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(response.message, "", "success");
            this.search();
          }
        });
      } else if (type == "start") {
        EnableByIdsExchangeRatess(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(response.message, "", "success");
            this.search();
          }
        });
      } else if (type == "stop") {
        DisableByIdsExchangeRatess(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(response.message, "", "success");
            this.search();
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>