
<template>
  <el-dialog v-model="visible" width="40%" destroy-on-close :show-close="false">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("templateLists.templateList.ChangeCommodity") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-form">
      <el-tree
        ref="tree"
        class="filter-tree custom-el-tree"
        :data="treeData"
        :props="defaultProps"
        default-expand-all
        check-strictly
        :filter-node-method="filterNode"
        :highlight-current="true"
        node-key="Id"
        show-checkbox
        @check="handleCheckChange"
      >
        <template #default="{ node, data }">
          <span class="custom-tree-node">
            <span class="my-text" :title="node.label">{{ node.label }}</span>
            <span class="my-badge">{{ data.Quantity }}</span>
          </span>
        </template>
      </el-tree>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{
          $t("userlists.userlist.cancel")
        }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="toConfirm"
          >{{ $t("userlists.userlist.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
  <script>
export default {
  props: ["treeData"],
  data() {
    return {
      visible: false,
      CheckId: "",
      defaultProps: {
        children: "children",
        label: "Name",
      },
    };
  },
  mounted() {},
  methods: {
    close() {
      this.visible = false;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {
      console.log(data);
      if (data.disabled) {
        commonUtil.showTipInfo("当前商品分类已禁用！", "", "warning");
        return;
      }
    },
    handleCheckChange(data, list) {
      if (data.disabled) {
        commonUtil.showTipInfo("当前商品分类已禁用！", "", "warning");
        return;
      }
      if (list.checkedKeys.length > 0) {
        this.$refs.tree.setCheckedKeys([data.Id]);
        this.CheckId = data.Id;
      } else this.CheckId = "";
    },
    toConfirm() {
      console.log(this.CheckId, "this.CheckId");
      if (this.CheckId) {
        this.$emit("ModifyCommodity", this.CheckId);
        this.visible = false;
      }
    },
  },
};
</script>
  <style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0 !important;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.align-right {
  text-align: right;
}
.tcm-table {
  height: calc(100% - 90px);
  padding: 0 20px;
  box-sizing: border-box;
}
.tree-cla {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #ebeef5;
}
.el-row {
  height: 100%;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
</style>
  
