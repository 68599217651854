<template>
  <div style="height: 100%">
    <div class="top-box">
      <div class="crumbs-box">
        <div class="crumbs-box-item parent" @click="Goback">Back</div>
        <div class="line"></div>
        <div class="crumbs-box-item" v-for="(item, index) in crumbs">
          {{ item }}
          <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-top_handle">
        <div class="title">
          {{ $t("unifiedPrompts.unifiedPrompt.ImportList") }}
        </div>
        <div class="main-handle flex">
          <div class="main-handle-item flex" style="align-items: center">
            <span style="padding-right: 5px">
              {{ $t("tcmdatalists.tcmdatalist.commodity") }}</span
            >
            <el-tree-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              v-model="searchForm.CommodityId"
              :data="CommodityOptions"
              :props="defaultProps"
              check-strictly
              :render-after-expand="false"
              :default-expand-all="true"
            />
          </div>
          <!-- 日期选择 -->
          <div class="main-handle-item">
            <div class="select-box">
              <div class="select-btn">
                <el-icon class="Calendar"><Calendar /></el-icon>
                <span :style="{ color: searchForm.date ? '#606266' : '' }">{{
                  searchForm.date
                    ? searchForm.date[0] + " - " + searchForm.date[1]
                    : $t("tcmdatalists.tcmdatalist.pleaseSlect")
                }}</span>
                <el-icon v-show="!searchForm.date">
                  <ArrowDownBold />
                </el-icon>
                <el-icon :size="18" v-show="searchForm.date">
                  <Close />
                </el-icon>
              </div>
              <!-- el-icon searchForm -->
              <el-date-picker
                class="date-picker"
                style="
                  width: 200px;
                  height: 30px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  opacity: 0;
                "
                v-model="searchForm.date"
                type="daterange"
                range-separator="To"
                :start-placeholder="
                  $t('unifiedPrompts.unifiedPrompt.startDate')
                "
                :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
                size="default"
                value-format="YYYY-MM-DD"
                @change="changeDate"
              />
            </div>
          </div>
          <div class="main-handle-item">
            <input
              type="text"
              v-model="searchForm.Keyword"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </div>
          <div class="search-btn" @click="search">
            {{ $t("tcmlists.tcmlist.search") }}
          </div>
        </div>
      </div>
      <div class="handle-btn flex">
        <!-- <div class="handle-btn-item" @click="edit">xxx</div> -->
        <div class="handle-btn-item" @click="editData">
          {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
        </div>
        <el-upload
          ref="upload"
          class="upload-demo custom-el-upload-list-none"
          action="/"
          :on-change="importData"
          :auto-upload="false"
        >
          <template #trigger>
            <div class="handle-btn-item">
              {{ $t("ccls.ccl.import") }}
            </div>
          </template>
        </el-upload>

        <div class="handle-btn-item" @click="selectTcms">
          {{ $t("unifiedPrompts.unifiedPrompt.selectTCM") }}
        </div>
        <div class="handle-btn-item" @click="deleteTcms">
          {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
        </div>
        <div class="handle-btn-item" @click="DownCBDTemplate">
          {{ $t("unifiedPrompts.unifiedPrompt.DownCBDTemplate") }}
        </div>
        <div class="handle-btn-item" @click="CallbackBtn">
          {{ $t("homePage.home.Callback") }}
        </div>
      </div>
      <div class="regional_table">
        <!-- @row-click="RowClick" -->
        <el-table
          :empty-text="$t('tcmlists.tcmlist.noData')"
          :data="tableData"
          border
          stripe
          :height="TableHeight"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="commodityName"
            :label="$t('tcmdatalists.tcmdatalist.commodity')"
            width="110"
            align="center"
          />
          <el-table-column
            prop="programName"
            :label="$t('changePriceLists.changePriceList.programName')"
            align="center"
          />
          <!-- <el-table-column
            prop="documentNo"
            :label="$t('tcmdatalists.tcmdatalist.documentNO')"
            align="center"
          /> -->
          <el-table-column
            prop="tcmOwner"
            :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
            align="center"
          />
          <el-table-column
            prop="partDescription"
            :label="$t('tcmdatalists.tcmdatalist.partDescription')"
            align="center"
          />
          <el-table-column
            prop="partNumber"
            :label="$t('tcmdatalists.tcmdatalist.partNumber')"
            align="center"
          />
          <el-table-column
            prop="date"
            :label="$t('tcmdatalists.tcmdatalist.startDate')"
            sortable
            align="center"
          />
          <el-table-column
            prop="approvedTime"
            :label="$t('tcmdatalists.tcmdatalist.dueDate')"
            sortable
            align="center"
          />
          <el-table-column
            prop="quoteType"
            :label="$t('tcmdatalists.tcmdatalist.quotationType')"
            align="center"
          />
          <el-table-column
            prop="tcmRequest"
            :label="$t('tcmdatalists.tcmdatalist.tcmOwner')"
            align="center"
          />
          <el-table-column
            prop="importType"
            :label="$t('tcmdatalists.tcmdatalist.workStatus')"
            align="center"
          >
            <template #default="scope">
              <div v-show="scope.row.importType == 1" class="color-F59A23">
                {{ $t("tcmdatalists.tcmdatalist.unfinished") }}
              </div>
              <div v-show="scope.row.importType == 2" class="color-67C23A">
                {{ $t("tcmdatalists.tcmdatalist.finished") }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            width="80"
            fixed="right"
            class-name="lastTd"
            align="center"
            :label="$t('homePage.home.Action')"
          >
            <template #default="scope">
              <el-popover
                style="padding: 5px !important"
                placement="bottom"
                trigger="hover"
              >
                <template #reference>
                  <div style="text-align: center">
                    <el-icon :size="15"><MoreFilled /></el-icon>
                  </div>
                </template>

                <div class="action-box">
                  <div class="action-btn" @click="CallbackBtn(scope.row.id)">
                    <el-icon style="color: #2d8c1d"><CopyDocument /></el-icon>
                    <span> {{ $t("homePage.home.Callback") }}</span>
                  </div>
                </div>
              </el-popover>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="pagination">
        <div>
          Showing {{ pageSize }} from {{ total }} data
          <el-popover placement="top" :width="70" trigger="click">
            <template #reference>
              <el-icon
                style="
                  margin-left: 5px;
                  font-size: 17px;
                  vertical-align: middle;
                "
                ><Operation
              /></el-icon>
            </template>
            <el-input
              v-model="searchForm.limit"
              @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
              :min="1"
              style="width: 130px"
              @change="handleSizeChange"
            >
              <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
            </el-input>
          </el-popover>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <SelectTcm ref="tcmDia" @selectTCM="tcmImport"></SelectTcm>
  </div>
  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <table>
        <tr>
          <td>
            {{ $t("tcmdatalists.tcmdatalist.commodity") }}
            <el-tree-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              v-model="searchForm.CommodityId"
              :data="CommodityOptions"
              :props="defaultProps"
              check-strictly
              :render-after-expand="false"
              :default-expand-all="true"
            />
          </td>
          <td>
            {{ $t("tcmdatalists.tcmdatalist.date") }}
            <el-date-picker
              v-model="searchForm.date"
              type="daterange"
              range-separator="To"
              :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
              :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
              size="default"
              value-format="YYYY-MM-DD"
              @change="changeDate"
            />
          </td>
          <!== <td>
						{{$t('tcmdatalists.tcmdatalist.status')}}
						<el-select clearable  v-model="searchForm.status"   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
						    <el-option
						      v-for="item in statusOptions"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value"
						    />
						</el-select>
					</td> ==>
          <td>
            <el-input
              v-model="searchForm.Keyword"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </td>
          <td>
            <div class="operate-btn" style="margin-right: 10px" @click="search">
              {{ $t("tcmdatalists.tcmdatalist.search") }}
            </div>
            <!== <div class="operate-btn">Advanced search</div> ==>
          </td>
        </tr>
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <div class="operate-btn mrg-10" @click="editData">
              {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
            </div>
            <div style="display: inline-block">
              <el-upload
                ref="upload"
                class="upload-demo custom-el-upload-list-none"
                action="/"
                :on-change="importData"
                :auto-upload="false"
              >
                <template #trigger>
                  <div class="operate-btn" style="margin-right: 10px">
                    {{ $t("ccls.ccl.import") }}
                  </div>
                </template>
              </el-upload>
            </div>
            <div class="operate-btn mrg-10" @click="selectTcms">
              {{ $t("unifiedPrompts.unifiedPrompt.selectTCM") }}
            </div>
            <div class="operate-btn mrg-10" @click="deleteTcms">
              {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
            </div>
          </el-col>
        </el-row>
      </table>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <!== <el-table-column width="55" align="center">
					<template #default="scope">
						<img src="../../assets/img/tcm/computer.svg" style="cursor: pointer;" @click="showDetail(scope.row)" />
					</template>
				</el-table-column> ==>
        <el-table-column
          prop="commodityName"
          :label="$t('tcmdatalists.tcmdatalist.commodity')"
          width="110"
          align="center"
        />
        <el-table-column
          prop="programName"
          :label="$t('changePriceLists.changePriceList.programName')"
          align="center"
        />
        <el-table-column
          prop="documentNo"
          :label="$t('tcmdatalists.tcmdatalist.documentNO')"
          align="center"
        />
        <el-table-column
          prop="tcmOwner"
          :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
          align="center"
        />
        <el-table-column
          prop="partDescription"
          :label="$t('tcmdatalists.tcmdatalist.partDescription')"
          align="center"
        />
        <el-table-column
          prop="partNumber"
          :label="$t('tcmdatalists.tcmdatalist.partNumber')"
          align="center"
        />
        <el-table-column
          prop="date"
          :label="$t('tcmdatalists.tcmdatalist.startDate')"
          sortable
          align="center"
        />
        <el-table-column
          prop="approvedTime"
          :label="$t('tcmdatalists.tcmdatalist.dueDate')"
          sortable
          align="center"
        />
        <!== <el-table-column prop="urgency" :label="($t('tcmdatalists.tcmdatalist.priority'))" width="110" align="center">
					<template #default="scope">
                        <div v-show="scope.row.urgency == 'High'" class="color-ff0000">{{$t('tcmdatalists.tcmdatalist.high')}}</div>
                        <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23"> {{$t('tcmdatalists.tcmdatalist.middle')}}</div>
                        <div v-show="scope.row.urgency == 'Low'">{{$t('tcmdatalists.tcmdatalist.low')}}</div>
					</template>
				</el-table-column> ==>
        <el-table-column
          prop="quoteType"
          :label="$t('tcmdatalists.tcmdatalist.quotationType')"
          align="center"
        />
        <el-table-column
          prop="tcmRequest"
          :label="$t('tcmdatalists.tcmdatalist.tcmOwner')"
          align="center"
        />
        <!== <el-table-column prop="priceType" :label="$t('tcmlists.tcmlist.priceType')"  align="center">
					<template #default="scope">
						<div v-show="scope.row.priceType == '0'" class="color-F59A23">{{$t('tcmdatalists.tcmdatalist.unchangedPrice')}}</div>
						<div v-show="scope.row.priceType == '1'" class="color-D9001B">{{$t('tcmdatalists.tcmdatalist.changedprice')}}</div>
					</template>
				</el-table-column> ==>
        <el-table-column
          prop="importType"
          :label="$t('tcmdatalists.tcmdatalist.workStatus')"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.importType == 1" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.unfinished") }}
            </div>
            <div v-show="scope.row.importType == 2" class="color-67C23A">
              {{ $t("tcmdatalists.tcmdatalist.finished") }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-cla">
      <div>
        <el-input
          v-model="searchForm.limit"
          @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
          min="1"
          style="width: 130px"
          @change="handleSizeChange"
        >
          <template #suffix>
            {{ $t("ccls.ccl.barPage") }}
          </template>
        </el-input>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
    <SelectTcm ref="tcmDia" @selectTCM="tcmImport"></SelectTcm>
  </div> -->
</template>

<script>
import {
  GetOrgs,
  getApplicationListCursor,
  deleteApplyDetail,
  ImportTCMDataBaseExcel,
  ExportTCMDataBaseExcel,
  ExportDataTableExcel,
  GetDataIsDuplicate,
  ExportTCMDataBaseExcelNew,
  AddCompletedCBD,
  DownloadTemplateExcel,
  CallBackCostApply,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import { ElMessageBox } from "element-plus";
import SelectTcm from "./selectTcm.vue";
export default {
  components: {
    SelectTcm,
  },
  name: "importList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      crumbs: ["TCM DATA", "Import List"],
      TableHeight: 0,
      searchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        StartDate_Approved: "",
        EndDate_Approved: "",
        status: "",
        Keyword: "",
        priceType: "",
        PageType: 3,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
      },
      // commodity选项
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // status选项
      statusOptions: [],
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      timers: "",
      // 数据重复是否继续导入
      isContinue: false,
    };
  },

  mounted() {
    this.autoHeight();
    this.getList();
    this.getCommdity();
    this.timer = setTimeout(this.gettime, 1000);
    this.getUnits();
  },
  methods: {
    CallbackBtn() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }

      if (this.chooseData[0].importType == 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.operationByFinished"),
          "",
          "warning"
        );
        return;
      }

      let id = this.chooseData[0].id;
      CallBackCostApply({ id }, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    Goback() {
      this.$router.go(-1);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 245; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.timers = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        this.statusOptions = [
          {
            value: "",
            label: "All",
          },
          {
            value: 0,
            label: "Yet to start",
          },
          {
            value: 1,
            label: "In process",
          },
          {
            value: 2,
            label: "To be approved",
          },
          {
            value: 4,
            label: "Under approved",
          },
          {
            value: 5,
            label: "Rejected",
          },
          {
            value: 6,
            label: "Approved",
          },
        ];
      } else {
        this.statusOptions = [
          {
            value: "",
            label: "全部",
          },
          {
            value: 0,
            label: "未开始",
          },
          {
            value: 1,
            label: "进行中",
          },
          {
            value: 2,
            label: "待审批",
          },
          {
            value: 4,
            label: "审批中",
          },
          {
            value: 5,
            label: "驳回",
          },
          {
            value: 6,
            label: "通过",
          },
        ];
      }
      clearInterval(this.timers);
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加") {
          this.btnAdd = 1;
        }
        if (e.name == "删除") {
          this.btnDel = 1;
        }
        if (e.name == "编辑") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    getList() {
      this.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        StartDate_Approved: "",
        EndDate_Approved: "",
        status: "",
        Keyword: "",
        priceType: "",
        PageType: 3,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    search() {
      getApplicationListCursor(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },

    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        // this.searchForm.StartDate = val[0]
        // this.searchForm.EndDate = val[1]
        this.searchForm.StartDate_Approved = val[0];
        this.searchForm.EndDate_Approved = val[1];
      } else {
        this.searchForm.StartDate_Approved = "";
        this.searchForm.EndDate_Approved = "";
      }
    },
    // 	// 新增
    // addEntry() {
    // 	this.$router.push({
    // 		path: '/tcmAdd',
    // 		name: 'TcmAdd',
    // 		query: {
    // 			type: 'add',
    // 		}
    // 	})
    // },
    // 		// 删除
    // deleteApp() {
    // 	console.log(this.chooseData)
    // 	if (this.chooseData.length == 0) {
    // 		commonUtil.showTipInfo(this.$t('userlists.userlist.selecWant'), '', 'warning');
    // 		return;
    // 	}
    // 	let ids = []
    // 	this.chooseData.forEach(function(val, index){
    // 		ids.push(val.id)
    // 	})
    // 	deleteApplyDetail(ids, response=>{
    // 		if (response && response.code == 200) {
    // 			commonUtil.showTipInfo(this.$t('unifiedPrompts.unifiedPrompt.deleteSuccessfully'), '', 'success')
    // 			this.search()
    // 		}
    // 	})
    // },
    // 编辑
    // edit(id) {
    // 	this.$router.push({
    // 		path: '/addOperate',
    // 		name: 'AddOperate',
    // 		query: {
    // 			type: 'edit',
    // 			id: id
    // 		}
    // 	})
    // },
    // 详情
    showDetail(row) {
      if (row.status == "6") {
        // this.$router.push({
        // 	path: '/addOperate',
        // 	name: 'AddOperate',
        // 	query: {
        // 		type: 'detail',
        // 		disabled: true,
        // 		id: row.id
        // 	}
        // })
        this.$router.push({
          path: "/tcmResult",
          name: "tcmResult",
          query: {
            id: row.id,
          },
        });
      }
    },
    // 导入
    importData(files) {
      let format = new FormData();
      format.append("file", files.raw);
      format.append("Type", 5);
      let excelFormat = new FormData();
      excelFormat.append("file", files.raw);
      GetDataIsDuplicate(format, (response) => {
        if (response && response.code == 200) {
          if (response.data == "") {
            excelFormat.append("DataIsDuplicate", "");
            this.importExcel(excelFormat, files);
          } else {
            // 有重复数据
            ElMessageBox.confirm(
              response.data +
                this.$t("unifiedPrompts.unifiedPrompt.dupImportQue")
            )
              .then(() => {
                excelFormat.append("DataIsDuplicate", "");
                this.isContinue = true;
                this.importExcel(excelFormat, files);
                done();
              })
              .catch(() => {
                if (!this.isContinue) {
                  excelFormat.append("DataIsDuplicate", response.data);
                  this.importExcel(excelFormat, files);
                }
                this.isContinue = false;
                // catch error
              });
          }
          // } else {
          // 	commonUtil.showTipInfo(response.message,"", "error");
          // 	this.getList()
          // 	this.$refs.upload.handleRemove(files)
          // }
        }
      });
    },
    // 导入
    importExcel(format, files) {
      ImportTCMDataBaseExcel(
        format,
        (response) => {
          if (response && response.code == 200) {
            this.getList();
            this.$refs.upload.handleRemove(files);
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importSuccessfully"),
              "",
              "success"
            );
          }
        },
        (errRes) => {
          if (errRes && errRes.code == 500) {
            ExportDataTableExcel('"' + errRes.data + '"', (res) => {});
          } else {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importFail"),
              "",
              "error"
            );
          }
          this.getList();
          this.$refs.upload.handleRemove(files);
        }
      );
    },
    // // 导入
    // importData(files) {
    // 	console.log('files', files)
    // 	let format = new FormData()
    // 	format.append('file', files.raw)
    // 	format.append('Type', 1)
    // 	console.log(format,1111111111111);
    // 	ImportTCMDataBaseExcel(format, response=>{
    // 		if (response && response.code == 200) {
    // 			this.getList()
    // 			this.$refs.upload.clearFiles()
    // 			commonUtil.showTipInfo(this.$t('unifiedPrompts.unifiedPrompt.importSuccessfully'), "", "success");
    // 		}
    // 	}, errRes=>{
    // 		console.log(errRes)
    // 		if (errRes && errRes.code == 500) {
    // 			ExportDataTableExcel('"' + errRes.data + '"', res=>{
    // 				console.log(res)
    // 			})
    // 		} else {
    // 			commonUtil.showTipInfo(this.$t('unifiedPrompts.unifiedPrompt.importFail') ,"", "error");
    // 		}
    // 		this.getList()
    // 		this.$refs.upload.clearFiles()
    // 	})
    // },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 导出
    // exportData() {
    // 	console.log(this.chooseData)
    // 	if (this.chooseData.length == 0) {
    // 		commonUtil.showTipInfo(this.$t('userlists.userlist.selecWant'), '', 'warning')
    // 		return;
    // 	}
    // 	let ids = []
    // 	this.chooseData.forEach(function(val, key) {
    // 		ids.push(val.id)
    // 	})
    // 	ExportTCMDataBaseExcel(ids, response=>{

    // 	})
    // },
    // 导出(改版)
    exportData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      ExportTCMDataBaseExcelNew(ids, (response) => {});
    },
    RowClick(row) {
      // if (row.status == "6") {
      //   this.$router.push({
      //     path: "/tcmResult",
      //     name: "tcmResult",
      //     query: {
      //       id: row.id,
      //     },
      //   });
      // }
      // if (row.importType == 2) {
      //   commonUtil.showTipInfo(
      //     this.$t("unifiedPrompts.unifiedPrompt.isFinishedNotOperate"),
      //     "",
      //     "warning"
      //   );
      //   return;
      // }
      // this.$router.push({
      //   path: "/addOperate",
      //   name: "AddOperate",
      //   query: {
      //     type: "editImport",
      //     id: row.id,
      //   },
      // });
    },
    // 编辑数据
    editData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData[0].importType == 2) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.isFinishedNotOperate"),
          "",
          "warning"
        );
        return;
      }
      this.$router.push({
        path: "/addOperate",
        name: "AddOperate",
        query: {
          type: "editImport",
          id: this.chooseData[0].id,
          crumbs: ["TCM DATA", "Import List", "Import List Edit"],
          saveFlag: true,
        },
      });
    },
    // 选择tcm
    selectTcms() {
      this.$refs.tcmDia.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "6",
        Keyword: "",
        priceType: 0,
        PageType: 4,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
        isDisable: 0,
      };
      this.$refs.tcmDia.getlist();
      this.$refs.tcmDia.visible = true;
    },
    // 获取选择的tcm数据
    tcmImport(ids) {
      AddCompletedCBD(
        ids,
        (response) => {
          if (response && response.code == 200) {
            this.$refs.tcmDia.close();
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importSuccessfully"),
              "",
              "success"
            );
            this.getList();
          }
        },
        (errResponse) => {
          if (errResponse && errResponse.code == 500) {
            commonUtil.showTipInfo(
              errResponse.message +
                this.$t("unifiedPrompts.unifiedPrompt.duplicateImport"),
              "",
              "warning"
            );
          }
        }
      );
    },
    deleteTcms() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let statusList = [];
      this.chooseData.forEach(function (val, index) {
        statusList.push(val.importType);
      });
      if (statusList.indexOf(2) > -1) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.deleteFinished"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      deleteApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // cbd模版下载
    DownCBDTemplate() {
      DownloadTemplateExcel(8, (response) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.top-box {
  position: fixed;
  width: calc(100% - 260px);
  top: 70px;
  left: 235px;
  z-index: 99;
  background-color: #fff;
}
.crumbs-box {
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
.main {
  padding-top: 15px;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>