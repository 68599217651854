import { createApp } from "vue";
import store from './views/store'
import App from "./App.vue";
import router from './router';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/base.scss'
import '@/assets/css/common.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { setupI18n } from '@/components/language/index';
import { addColor } from '../src/theme/configColor';
import HistoryIcon from '@/components/icons/HistoryIcon.vue'
import CallbackIcon from '@/components/icons/CallbackIcon.vue'
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component("History", HistoryIcon);
app.component("Callback", CallbackIcon);
app.use(router)
app.use(ElementPlus)
app.use(store)
app.mount('#app')
setupI18n(app);
addColor()