// 主题样式
import themes from './theme.json'
 
export function addColor(init) {
	console.log(init)
	console.log('init')
	let theme = localStorage.getItem('theme')
    let keys = ['blue','red']
	if (init === undefined) {
		const obj = themes[keys.indexOf(theme) != -1  ? theme :'blue']
		// html 添加主题样式
		for (const key in obj) {
		    document.documentElement.style.setProperty(key, obj[key]);
		}
	} else {
		const obj = themes[keys.indexOf(init) != -1  ? init :'blue']
		// html 添加主题样式
		for (const key in obj) {
		    document.documentElement.style.setProperty(key, obj[key]);
		}
	}
    
}