<template>
  <div>
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="Goback">Back</div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ item }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>
    <div class="main">
      <!-- 顶部选择币种、price\year -->
      <div class="add-top">
        <el-row>
          <el-col :span="16">
            <el-row style="padding-top: 15px">
              <el-col :span="24">
                <div class="dis-flex">
                  <div class="line-32 add-top-text">Ext. Currency</div>
                  <el-select
                    clearable
                    :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                    filterable
                    v-model="form.extCurrencyId"
                    placeholder=""
                    class="mgr-20"
                    style="width: 150px"
                  >
                    <el-option
                      v-for="item in currencyOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                    clearable
                    :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                    filterable
                    v-model="form.extCurrencyId2"
                    placeholder=""
                    style="width: 150px"
                  >
                    <el-option
                      v-for="item in currencyOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <div style="width: 170px" class="line-32"></div>
                  <div style="width: 150px" class="line-32"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24">
                <div class="dis-flex">
                  <div class="line-32 add-top-text"></div>
                  <div
                    style="width: 170px"
                    class="line-32 btn-text"
                    @click="changeRate('1')"
                  >
                    Exchange1
                  </div>
                  <div
                    style="width: 170px"
                    class="line-32 btn-text"
                    @click="changeRate('2')"
                  >
                    Exchange2
                  </div>
                  <div style="width: 150px" class="line-32">Extra</div>
                  <div style="width: 150px" class="line-32"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24">
                <div class="dis-flex">
                  <div class="line-32 add-top-text">Nexteer Buy</div>
                  <el-input
                    v-model="form.nexteerBuy"
                    disabled
                    placeholder=""
                    class="mgr-20"
                    style="width: 150px"
                  />
                  <el-input
                    v-model="form.nexteerBuy2"
                    disabled
                    placeholder=""
                    style="width: 150px"
                  />
                  <div style="width: 170px" class="line-32">
                    <el-input
                      v-model="form.nexteerPercent"
                      @input="transferFormat($event, form, 'nexteerPercent')"
                      placeholder=""
                      style="width: 150px"
                    />
                    %
                  </div>
                  <div style="width: 150px" class="line-32">
                    <el-input
                      v-model="form.nexteerBuyPercent2"
                      disabled
                      placeholder=""
                      style="width: 150px"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24">
                <div class="dis-flex">
                  <div class="line-32 add-top-text">Supplier Buy</div>
                  <el-input
                    v-model="form.supplierBuy"
                    disabled
                    placeholder=""
                    class="mgr-20"
                    style="width: 150px"
                  />
                  <el-input
                    v-model="form.supplierBuy2"
                    disabled
                    placeholder=""
                    style="width: 150px"
                  />
                  <div style="width: 170px" class="line-32">
                    <el-input
                      v-model="form.supplierPercent"
                      @input="transferFormat($event, form, 'supplierPercent')"
                      placeholder=""
                      style="width: 150px"
                    />
                    %
                  </div>
                  <div style="width: 150px" class="line-32">
                    <el-input
                      v-model="form.supplierBuyPercent2"
                      disabled
                      placeholder=""
                      style="width: 150px"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="mgt-15" v-for="(item, index) in directBuyList">
              <el-col :span="24">
                <div class="dis-flex">
                  <div class="line-32 add-top-text">{{ item.name }}</div>
                  <el-input
                    v-model="item.exchangePrice1"
                    disabled
                    @input="transferFormat($event, item, 'exchangePrice1')"
                    placeholder=""
                    class="mgr-20"
                    style="width: 150px"
                  />
                  <el-input
                    v-model="item.exchangePrice2"
                    disabled
                    @input="transferFormat($event, item, 'exchangePrice2')"
                    placeholder=""
                    style="width: 150px"
                  />
                  <div style="width: 170px" class="line-32">
                    <el-input
                      v-model="item.percent"
                      @input="transferFormat($event, item, 'percent')"
                      placeholder=""
                      style="width: 150px"
                    />
                    %
                  </div>
                  <div style="width: 150px" class="line-32">
                    <el-input
                      v-model="item.percentPrice"
                      disabled
                      placeholder=""
                      style="width: 150px"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24">
                <div class="dis-flex">
                  <div class="line-32 add-top-text">Total</div>
                  <el-input
                    v-model="form.total"
                    disabled
                    placeholder=""
                    class="mgr-20"
                    style="width: 150px"
                  />
                  <el-input
                    v-model="form.total2"
                    disabled
                    placeholder=""
                    style="width: 150px"
                  />
                  <div style="width: 170px" class="line-32"></div>
                  <div style="width: 150px" class="line-32">
                    <el-input
                      v-model="form.totalPercent2"
                      disabled
                      placeholder=""
                      style="width: 150px"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24" class="align-right">
                <div
                  class="operate-btn"
                  style="margin-right: 50px"
                  @click="compute"
                >
                  Compute
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8" style="background-color: #f2f6fc">
            <el-row style="padding-top: 15px">
              <el-col :span="24">
                <div class="dis-flex">
                  <div class="line-32 add-top-text">Year</div>
                  <el-select
                    clearable
                    :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                    filterable
                    v-model="form.year"
                    placeholder=""
                    style="width: 150px"
                  >
                    <el-option
                      v-for="item in yearOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </div>
              </el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24">
                <div class="dis-flex">
                  <div class="line-32 add-top-text">Price Type</div>
                  <el-select
                    clearable
                    :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                    filterable
                    v-model="form.priceType"
                    placeholder=""
                    style="width: 150px"
                  >
                    <el-option
                      v-for="item in priceOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </div>
              </el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24">
                <div class="height-32"></div>
              </el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24">
                <div class="height-32"></div>
              </el-col>
            </el-row>
            <el-row class="mgt-15" v-for="(item, index) in directBuyList">
              <el-col :span="24">
                <div class="height-32"></div>
              </el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24" class="align-right"></el-col>
            </el-row>
            <el-row class="mgt-15">
              <el-col :span="24" class="align-right">
                <div
                  class="operate-btn"
                  style="margin-right: 50px"
                  @click="changePrice"
                >
                  Get Price
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <!-- 搜索框 -->
      <div class="tcm-search">
        <table>
          <tr>
            <td>
              <div class="search-text-cla">Nexteer Part</div>
              <el-input
                v-model="searchForm.key"
                placeholder=""
                style="width: calc(100% - 150px)"
              />
            </td>
            <td>
              <div class="search-text-cla">Description</div>
              <el-input
                v-model="searchForm.description"
                placeholder=""
                style="width: calc(100% - 150px)"
              />
            </td>
            <td>
              <div class="operate-btn" @click="search">
                {{ $t("pcbas.pcba.search") }}
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td colspan="4" style="padding-top: 5px">
              <div class="handle-btn flex">
                <div
                  class="handle-btn-item"
                  style="margin-right: 10px"
                  @click="addData"
                >
                  {{ $t("pcbas.pcba.new") }}
                </div>
                <div
                  class="handle-btn-item"
                  style="margin-right: 10px"
                  @click="editData"
                >
                  {{ $t("pcbas.pcba.edit") }}
                </div>
                <div
                  class="handle-btn-item"
                  style="margin-right: 10px"
                  @click="deleteData"
                >
                  {{ $t("pcbas.pcba.delete") }}
                </div>
                <el-upload
                  ref="upload"
                  class="upload-demo custom-el-upload-list-none"
                  action="/"
                  :on-change="importData"
                  :auto-upload="false"
                >
                  <template #trigger>
                    <div class="handle-btn-item" style="margin-right: 10px">
                      {{ $t("pcbas.pcba.import") }}
                    </div>
                  </template>
                </el-upload>
                <div
                  class="handle-btn-item"
                  style="margin-right: 10px"
                  @click="downTemplate"
                >
                  {{ $t("pcbas.pcba.downTemplate") }}
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="regional_table">
        <el-table
          :empty-text="$t('tcmlists.tcmlist.noData')"
          :data="tableData"
          border
          stripe
          height="500"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="nexteerPart"
            label="Nexteer Part"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.nexteerPart }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="reference"
            label="Reference"
            show-overflow-tooltip
            align="center"
          >
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.reference }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="description"
            label="Description"
            show-overflow-tooltip
            align="center"
          >
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.description }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="Quantity" align="center">
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.quantity }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="nexteerChart"
            label="Nexteer Chart "
            align="center"
          >
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.nexteerChart }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="footPrint"
            label="PCB footprint"
            align="center"
          >
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.footPrint }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="comments" label="Comments" align="center">
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.comments }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="tempRating"
            label="Temp. Rating"
            align="center"
          >
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.tempRating }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="msl" label="MSL" align="center">
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.msl }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="directBuy" label="Direct Buy" align="center">
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.directBuy }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="year" label="Year" align="center">
            <template #default="scope">
              <div :class="scope.row.unitPrice == '0' ? 'color-ff0000' : ''">
                {{ scope.row.year }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="Status" align="center">
            <template #default="scope">
              <div
                :class="
                  scope.row.status == 'EOL'
                    ? 'color-ff0000'
                    : scope.row.unitPrice == '0'
                    ? 'color-ff0000'
                    : ''
                "
              >
                {{ scope.row.status }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="currencyName" label="Currency" align="center">
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.currencyName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="pirceType" label="Price Type" align="center">
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.pirceType }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="unitPrice" label="Unit Price" align="center">
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.unitPrice }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="subTotalPrice"
            label="Sub-Total Price"
            align="center"
          >
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.subTotalPrice }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="extCurrencyName"
            label="Ext. Currency"
            align="center"
          >
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.extCurrencyName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="converRate"
            label="Exchange Rate"
            align="center"
          >
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.converRate }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="extPrice" label="Ext.Price" align="center">
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.extPrice }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="extCurrencyName2"
            label="Ext. Currency2"
            align="center"
          >
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.extCurrencyName2 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="converRate2"
            label="Exchange Rate2"
            align="center"
          >
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.converRate2 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="extPrice2" label="Ext.Price2" align="center">
            <template #default="scope">
              <div
                :class="
                  scope.row.unitPrice == '0' ? 'color-ff0000' : 'color-F59A23'
                "
              >
                {{ scope.row.extPrice2 }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination" style="padding: 0 10px">
        <div>
          Showing {{ pageSize }} from {{ total }} data
          <el-popover placement="top" :width="70" trigger="click">
            <template #reference>
              <el-icon
                style="
                  margin-left: 5px;
                  font-size: 17px;
                  vertical-align: middle;
                "
                ><Operation
              /></el-icon>
            </template>
            <el-input
              v-model="searchForm.limit"
              @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
              :min="1"
              style="width: 130px"
              @change="handleSizeChange"
            >
              <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
            </el-input>
          </el-popover>
        </div>

        <el-pagination
          layout="prev, pager, next"
          :total="total"
          background
          :page-size="pageSize"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <addEBomDetail
      ref="addDia"
      :currencyOptions="currencyOptions"
      @confirmInfo="confirmInfo"
    ></addEBomDetail>
    <ExchangeRateDia ref="exDia" @rateSubmit="rateSubmit"></ExchangeRateDia>
  </div>
</template>

<script>
import addEBomDetail from "./addEBomDetail.vue";
import ExchangeRateDia from "./exchangeRateDia.vue";
import {
  GetEbomComputeResult,
  EBomsLoadDetail,
  loadCurrency,
  GetEbomComputeResultByParameter,
  EBomsAddOrUpdateDetail,
  EBomsDeleteDetail,
  ImportEbom,
  GetDirectBuyList,
  GetEbomCurrencyList,
  BatchChangeRate,
  ChangeUnitPrice,
  DownloadTemplateExcel,
} from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
export default {
  components: {
    addEBomDetail,
    ExchangeRateDia,
  },
  data() {
    return {
      form: {
        id: "",
        extCurrencyId: "",
        extCurrencyId2: "",
        nexteerBuy: "",
        nexteerBuy2: "",
        supplierBuy: "",
        supplierBuy2: "",
        total: "",
        total2: "",
        year: "",
        priceType: "",
        otherTotal: "",
        otherTotal2: "",
        otherJson: "",
        converRate2: "",
        currChangeList: [],
        nexteerPercent: "",
        supplierPercent: "",
        nexteerBuyPercent2: "",
        supplierBuyPercent2: "",
        totalPercent2: "",
      },
      // 货币选项
      currencyOptions: [],
      // 当前年份
      currentYear: "",
      // 年份选项(年后20年)
      yearOptions: [],
      // 价格选项
      priceOptions: ["high", "middle", "low"],
      searchForm: {
        mainId: "",
        description: "",
        page: 1,
        limit: 10,
        key: "",
      },
      // 详情列表数据
      tableData: [],
      // 总页数
      total: 0,
      pageSize: 10,
      // 已选择数据
      chooseData: [],
      // directBuy列表数据
      directBuyList: [],
      crumbs: ["Tools", "Process Calculator", "PCBA", "eBom", "Compute Detail"],
    };
  },
  mounted() {
    this.getAllCurrencyOptions();
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id;
      this.searchForm.mainId = this.$route.query.id;
      // this.getComputeDetail()
      // this.getList()
    }
    let date = new Date();
    let year = date.getFullYear();
    let startYear = year - 20;
    this.currentYear = year;
    this.yearOptions = [];
    for (let i = 0; i < 41; i++) {
      this.yearOptions.push((startYear + i).toString());
    }
  },
  methods: {
    // 查询所有货币
    getAllCurrencyOptions() {
      loadCurrency(
        {
          IsOrderNumber: 0,
          Keyword: "",
          page: 1, //当前页
          limit: 99999, //每页个数
        },
        (response) => {
          if (response && response.code == 200) {
            this.currencyOptions = response.data === null ? [] : response.data;
            if (this.form.id) {
              this.getComputeDetail();
              this.getList();
            }
          }
        }
      );
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addData() {
      this.$refs.addDia.form.mainId = this.form.id;
      if (this.form.extCurrencyId == "") {
        this.$refs.addDia.form.extCurrencyId =
          this.currencyOptions.length == 0 ? "" : this.currencyOptions[0].id;
      } else {
        this.$refs.addDia.form.extCurrencyId = this.form.extCurrencyId;
      }
      if (this.form.extCurrencyId2 == "") {
        this.$refs.addDia.form.extCurrencyId2 =
          this.currencyOptions.length == 0 ? "" : this.currencyOptions[0].id;
      } else {
        this.$refs.addDia.form.extCurrencyId2 = this.form.extCurrencyId2;
      }
      if (this.form.year == "") {
        this.$refs.addDia.form.year = this.currentYear;
      } else {
        this.$refs.addDia.form.year = this.form.year;
      }
      if (this.form.priceType == "") {
        this.$refs.addDia.form.pirceType = "high";
      } else {
        this.$refs.addDia.form.pirceType = this.form.priceType;
      }
      this.$refs.addDia.visible = true;
    },
    // 获取新增数据
    confirmInfo(form) {
      EBomsAddOrUpdateDetail(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.$refs.addDia.close();
          // this.getComputeDetail()
          this.getDirectBuy();
          this.getList();
        } else {
          commonUtil.showTipInfo("NexteerPart data duplication", "", "warning");
        }
      });
    },
    // 编辑
    editData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addDia.form = JSON.parse(JSON.stringify(this.chooseData[0]));
      this.$refs.addDia.form.comment = JSON.parse(
        JSON.stringify(this.chooseData[0].comments)
      );
      this.$refs.addDia.visible = true;
    },
    deleteData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      EBomsDeleteDetail(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 根据id获取计算结果
    getComputeDetail(type) {
      GetEbomComputeResult(
        {
          id: this.form.id,
        },
        (response) => {
          if (response && response.code == 200) {
            if (response.result.extCurrencyId == "") {
              this.form.extCurrencyId =
                this.currencyOptions.length == 0
                  ? ""
                  : this.currencyOptions[0].id;
            } else {
              this.form.extCurrencyId = response.result.extCurrencyId;
            }
            if (response.result.extCurrencyId2 == "") {
              this.form.extCurrencyId2 =
                this.currencyOptions.length == 0
                  ? ""
                  : this.currencyOptions[0].id;
            } else {
              this.form.extCurrencyId2 = response.result.extCurrencyId2;
            }
            if (response.result.year == "") {
              this.form.year = this.currentYear;
            } else {
              this.form.year = response.result.year;
            }
            if (response.result.priceType == "") {
              this.form.priceType = "high";
            } else {
              this.form.priceType = response.result.priceType;
            }
            this.form.nexteerBuy = response.result.nexteerBuy;
            this.form.nexteerBuy2 = response.result.nexteerBuy2;
            this.form.nexteerPercent =
              response.result.nexteerPercent === null
                ? ""
                : response.result.nexteerPercent;
            this.form.nexteerBuyPercent2 = response.result.nexteerBuyPercent2;
            // this.form.pcB2 = response.result.pcB2
            // this.form.pcb = response.result.pcb
            this.form.supplierBuy = response.result.supplierBuy;
            this.form.supplierBuy2 = response.result.supplierBuy2;
            this.form.supplierPercent =
              response.result.supplierPercent === null
                ? ""
                : response.result.supplierPercent;
            this.form.supplierBuyPercent2 = response.result.supplierBuyPercent2;
            this.form.total = response.result.total;
            this.form.total2 = response.result.total2;
            this.form.otherJson =
              response.result.otherJson === null ||
              response.result.otherJson === ""
                ? "[]"
                : response.result.otherJson;
            this.directBuyList =
              response.result.otherJson === null ||
              response.result.otherJson === ""
                ? []
                : JSON.parse(response.result.otherJson);
            this.form.otherTotal =
              response.result.otherTotal === null ||
              response.result.otherTotal === ""
                ? 0
                : response.result.otherTotal;
            this.form.otherTotal2 =
              response.result.otherTotal2 === null ||
              response.result.otherTotal2 === ""
                ? 0
                : response.result.otherTotal2;
            // this.form.converRate2 = response.result.converRate2
            // this.form.currChangeList = response.result.currChangeList
            this.form.totalPercent2 = response.result.totalPercent2;
            this.changeRate("2", true);
            this.getDirectBuy(type);
          }
        }
      );
    },
    // 初始化detail列表
    getList() {
      this.searchForm = {
        mainId: this.form.id,
        description: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    search() {
      EBomsLoadDetail(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 获取directbuy
    getDirectBuy(type) {
      let _this = this;
      GetDirectBuyList(
        {
          mainId: this.form.id,
        },
        (response) => {
          if (response && response.code == 200) {
            response.result.forEach(function (item, index) {
              let isHasSample = false;
              _this.directBuyList.forEach(function (val) {
                if (val.name == item) {
                  isHasSample = true;
                  return;
                }
              });
              if (!isHasSample) {
                _this.directBuyList.push({
                  name: item,
                  exchangePrice1: "",
                  exchangePrice2: "",
                  percentPrice: "",
                  percent: "",
                });
              }
            });
            if (type == "changeRate") {
              this.compute();
            }
          }
        }
      );
    },
    // 计算
    compute() {
      this.computeOtherData();
      if (this.form.extCurrencyId == "") {
        commonUtil.showTipInfo("Please choose Ext. Currency", "", "warning");
        return;
      }
      if (this.form.extCurrencyId2 == "") {
        commonUtil.showTipInfo("Please choose Ext. Currency", "", "warning");
        return;
      }
      // if (this.form.year == '') {
      // 	commonUtil.showTipInfo('Please choose Year', '', 'warning');
      // 	return;
      // }
      // if (this.form.priceType == '') {
      // 	commonUtil.showTipInfo('Please choose Price', '', 'warning');
      // 	return;
      // }
      let otherJson = JSON.parse(this.form.otherJson);
      otherJson.forEach(function (val, index) {
        val.exchangePrice1 =
          val.exchangePrice1 === "" ||
          val.exchangePrice1 === null ||
          val.exchangePrice1 === undefined
            ? 0
            : JSON.parse(val.exchangePrice1);
        val.exchangePrice2 =
          val.exchangePrice2 === "" ||
          val.exchangePrice2 === null ||
          val.exchangePrice2 === undefined
            ? 0
            : JSON.parse(val.exchangePrice2);
        val.percentPrice =
          val.percentPrice === "" ||
          val.percentPrice === null ||
          val.percentPrice === undefined
            ? 0
            : JSON.parse(val.percentPrice);
        // val.percent = val.percent === '' || val.percent === null || val.percent === undefined ? 0 : JSON.parse(val.percent)
      });
      GetEbomComputeResultByParameter(
        {
          id: this.form.id,
          extCurrencyId: this.form.extCurrencyId,
          extCurrencyId2: this.form.extCurrencyId2,
          year: this.form.year,
          priceType: this.form.priceType,
          nexteerPercent:
            this.form.nexteerPercent === ""
              ? this.form.nexteerPercent
              : JSON.parse(this.form.nexteerPercent),
          supplierPercent:
            this.form.supplierPercent === ""
              ? this.form.supplierPercent
              : JSON.parse(this.form.supplierPercent),
          // otherTotal: this.form.otherTotal,
          // otherTotal2: this.form.otherTotal2,
          otherJson: JSON.stringify(otherJson),
        },
        (response) => {
          if (response && response.code == 200) {
            this.form.extCurrencyId = response.result.extCurrencyId;
            this.form.extCurrencyId2 = response.result.extCurrencyId2;
            this.form.nexteerBuy = response.result.nexteerBuy;
            this.form.nexteerBuy2 = response.result.nexteerBuy2;
            this.form.nexteerPercent =
              response.result.nexteerPercent === null
                ? ""
                : response.result.nexteerPercent;
            this.form.nexteerBuyPercent2 = response.result.nexteerBuyPercent2;
            // this.form.pcB2 = response.result.pcB2
            // this.form.pcb = response.result.pcb
            this.form.supplierBuy = response.result.supplierBuy;
            this.form.supplierBuy2 = response.result.supplierBuy2;
            this.form.supplierPercent =
              response.result.supplierPercent === null
                ? ""
                : response.result.supplierPercent;
            this.form.supplierBuyPercent2 = response.result.supplierBuyPercent2;
            this.form.total = response.result.total;
            this.form.total2 = response.result.total2;
            if (response.result.year !== "") {
              this.form.year = response.result.year;
            }
            if (response.result.priceType !== "") {
              this.form.priceType = response.result.priceType;
            }

            this.form.otherJson =
              response.result.otherJson === null ||
              response.result.otherJson === ""
                ? ""
                : response.result.otherJson;
            this.directBuyList =
              response.result.otherJson === null ||
              response.result.otherJson === ""
                ? []
                : JSON.parse(response.result.otherJson);
            this.form.otherTotal =
              response.result.otherTotal === null ||
              response.result.otherTotal === ""
                ? 0
                : response.result.otherTotal;
            this.form.otherTotal2 =
              response.result.otherTotal2 === null ||
              response.result.otherTotal2 === ""
                ? 0
                : response.result.otherTotal2;
            this.form.totalPercent2 = response.result.totalPercent2;
            this.getDirectBuy();
            this.getList();
          }
        },
        (errspon) => {
          commonUtil.showTipInfo(
            errspon.message +
              " unit price is 0, please re-calculate after completion",
            "",
            "error"
          );
        }
      );
    },
    // 上传
    importData(files) {
      let format = new FormData();
      format.append("file", files.raw);
      format.append("Type", 1);
      format.append("mainId", this.form.id);
      ImportEbom(format, (response) => {
        if (response && response.code == 200) {
          this.getList();
          this.$refs.upload.clearFiles();
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.importSuccessfully"),
            "",
            "success"
          );
        } else {
          commonUtil.showTipInfo(response.message, "", "error");
        }
      });
    },
    // 转换数据格式并计算总和
    transferFormat(val, item, name) {
      item[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
      // this.computeOtherData()
    },
    // 计算除yes\no之外的数据总和
    computeOtherData() {
      let _this = this;
      let convertRate = this.form.converRate2;
      this.form.otherTotal = 0;
      this.form.otherTotal2 = 0;
      this.directBuyList.forEach(function (val, index) {
        _this.form.otherTotal =
          parseFloat(_this.form.otherTotal == "" ? 0 : _this.form.otherTotal) +
          parseFloat(val.exchangePrice1 == "" ? 0 : val.exchangePrice1);
        val.exchangePrice2 =
          parseFloat(convertRate) *
          parseFloat(val.exchangePrice1 == "" ? 0 : val.exchangePrice1);
        _this.form.otherTotal2 =
          parseFloat(
            _this.form.otherTotal2 == "" ? 0 : _this.form.otherTotal2
          ) + parseFloat(val.exchangePrice2 == "" ? 0 : val.exchangePrice2);
      });
      this.form.otherJson = JSON.stringify(this.directBuyList);
    },
    // 转换税率
    changeRate(type, isCompute) {
      if (!isCompute) {
        if (this.form.extCurrencyId === "" || this.form.extCurrencyId2 === "") {
          commonUtil.showTipInfo("Please choose currency First!", "", "error");
          return;
        }
      }
      GetEbomCurrencyList(
        {
          extCurr1: this.form.extCurrencyId,
          extCurr2: this.form.extCurrencyId2,
          mainId: this.form.id,
          type: type,
        },
        (response) => {
          if (response && response.code == 200) {
            if (isCompute) {
              if (response.result && response.result.length > 0) {
                this.form.converRate2 = response.result[0].rate;
              }
            } else {
              this.$refs.exDia.addCellExchangeList = response.result;
              this.$refs.exDia.type = type;
              this.$refs.exDia.visible = true;
            }
          }
        }
      );
    },
    // 修改税率
    rateSubmit(form, type) {
      // if (type == '1') {
      this.form.currChangeList = form;
      // } else {
      // 	this.form.converRate2 = form[0].rate
      // }
      let submitform = JSON.parse(JSON.stringify(this.form));
      submitform.type = type;
      BatchChangeRate(submitform, (response) => {
        if (response && response.code == 200) {
          this.$refs.exDia.close();
          // this.getComputeDetail('changeRate')
          this.changeRate("2", true);
          this.getList();
        }
      });
    },
    // 修改unitprice
    changePrice() {
      ChangeUnitPrice(
        {
          id: this.form.id,
          year: this.form.year,
          priceType: this.form.priceType,
        },
        (response) => {
          if (response && response.code == 200) {
            this.getList();
          }
        }
      );
    },
    // 下载模板
    downTemplate() {
      DownloadTemplateExcel(7, (response) => {});
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding-top: 20px;
  width: 100%;
  min-height: 100%;
  background: url("../../../assets/img/car-table-bg.png") no-repeat right;
  background-position: right 4% bottom 0;
  background-size: 650px 600px;
  background-color: #fff;
}
.add-top {
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ebedf0;
}
.dis-flex {
  display: flex;
  justify-content: space-around;
}
.line-32 {
  line-height: 32px;
}
.height-32 {
  height: 32px;
}
.mgr-20 {
  margin-right: 20px;
}
.mgr-10 {
  margin-right: 10px;
}
.mgt-15 {
  margin-top: 12px;
}
.align-right {
  text-align: right;
}
.add-top-text {
  font-size: 14px;
  width: 150px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.tcm-search {
  width: 100%;
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 10px;
  box-sizing: border-box;
}
.search-text-cla {
  display: inline-flex;
  width: 80px;
}
.tcm-search td {
  width: 20%;
  padding: 0 10px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.btn-text {
  color: var(--themeColor);
  cursor: pointer;
  font-size: 14px;
}
.color-ff0000 {
  color: #ff0000;
}
.color-F59A23 {
  color: #f59a23;
}
.color-0000FF {
  color: #0000ff;
}
.color-D9001B {
  color: #d9001b;
}
.color-67C23A {
  color: #40d4a8;
}

.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>