<template>
	<el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
	    <template #header="{ close, titleId, titleClass }">
	      <div class="my-header">
	        <div class="size-16">{{form.id === '' ? $t("pcbas.pcba.new") : $t("pcbas.pcba.edit")}}</div>
	        <div style="cursor: pointer;" @click="close">
	          <el-icon :size="20"  style="color: var(--themeColor);"><CircleCloseFilled /></el-icon>
	        </div>
	      </div>
	    </template>
	    <div class="add-cla-top">
	    	<el-row>
	    		<el-col :span="24">
	    			<el-form :inline="true" :model="form" label-width="150px">
	    				<el-row>
							<el-form-item label="Tool Name：" class="custom-el-form-rules">
								<el-input v-model="form.toolName" placeholder="" style="width: 214px;"/>
							</el-form-item>
	    				</el-row>
						<el-row>
							<el-form-item label="Tool type：">
								<el-input v-model="form.toolType" placeholder="" style="width: 214px;"/>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="Machining type：">
								<el-input v-model="form.machiningTypeName" disabled placeholder="" style="width: 214px;"/>
							</el-form-item>
						</el-row>
	    				<el-row>
							<el-form-item label="Speed：">
								<el-input v-model="form.alSpeed" @input="(v) => (form.alSpeed = v.replace(/[^\d.]/g, ''))" placeholder="" style="width: 214px;"/>
							</el-form-item>
	    				</el-row>
						<el-row>
							<el-form-item label="Feed：">
								<el-input v-model="form.alFeed" @input="(v) => (form.alFeed = v.replace(/[^\d.]/g, ''))" placeholder="" style="width: 214px;"/>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="Remark：">
								<el-input v-model="form.remark" placeholder="" style="width: 214px;"/>
							</el-form-item>
						</el-row>
	    			</el-form>
	    		</el-col>
	    	</el-row>
	    </div>
		<template #footer>
		      <span class="dialog-footer">
		        <el-button @click="close">{{$t('pcbas.pcba.cancle')}}</el-button>
		        <el-button  style="background-color: var(--themeColor); color: var(--menuTextActive);margin-right: 16px" @click="confirm">{{$t('pcbas.pcba.confirm')}}</el-button>
		      </span>
		</template>
	  </el-dialog>
</template>

<script>
	import commonUtil from '../../../assets/js/commonUtil.js'
	export default{
		emits: ['confirmInfo'],
		data() {
			return {
				visible: false,
				form: {
					id: '',
					toolName: '',
					toolType: '',
					machiningTypeName: '',
					machiningType: '',
					alSpeed: '',
					alFeed: '',
					feFeed: '',
					feSpeed: '',
					remark: ''
				},
			}
		},
		methods: {
			close() {
				this.visible = false
				this.form = {
					id: '',
					toolName: '',
					toolType: '',
					machiningTypeName: '',
					machiningType: '',
					alSpeed: '',
					alFeed: '',
					feFeed: '',
					feSpeed: '',
					remark: ''
				}
			},
			confirm() {
				if (this.form.toolName == '') {
					commonUtil.showTipInfo('Please enter tool name', '', 'warning');
					return;
				}
				let data = {
					id: this.form.id,
					toolName: this.form.toolName,
					toolType: this.form.toolType,
					machiningType: this.form.machiningType,
					alSpeed: this.form.alSpeed === '' ? 0 : parseFloat(this.form.alSpeed),
					alFeed: this.form.alFeed === '' ? 0 : parseFloat(this.form.alFeed),
					feFeed: this.form.feFeed === '' ? 0 : parseFloat(this.form.feFeed),
					feSpeed: this.form.feSpeed === '' ? 0 : parseFloat(this.form.feSpeed),
					remark: this.form.remark
				}
				// console.log(data)
				this.$emit('confirmInfo', data)
				// this.close()
			}
		}
	}
</script>

<style scoped>
	.my-header {
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	}
	.size-16{
		font-size: 16px;
	}
	.padding-9{
		padding: 9px;
	}
	.el-row {
	  margin-bottom: 10px;
	}
	.color-red{
		color: red;
	}
	.align-right{
		text-align: right;
	}
</style>