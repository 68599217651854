<template>
  <div style="padding: 15px">
    <div class="Total-MaterialCost">
      <div class="MaterialCost">
        <p>Total Net Raw Material Cost</p>
        <input type="text" />
      </div>
    </div>
    <div class="Raw-box">
      <div class="Raw-card" v-for="item in 5">
        <div class="Raw-top">
          <div class="Raw-title">Purchased Components & Outside Processing</div>
          <div class="Raw-handle">
            <el-icon><MoreFilled /></el-icon>
          </div>
        </div>
        <div class="Raw-card-main">
          <div class="Raw-edit-btn">
            <el-icon><EditPen /></el-icon>
          </div>
          <div class="Raw-card-item" v-for="item in 9">
            <p>Currency</p>
            <input type="text" />
            <el-icon><CirclePlus /></el-icon>
          </div>
        </div>
        <div class="Raw-top" style="padding: 10px 0px">
          <div class="Raw-title">
            <el-icon><CirclePlus /></el-icon>
            <span style="padding-left: 10px">Add new card</span>
          </div>
          <div class="Raw-handle">
            <el-icon style="margin-right: -20px"><DeleteFilled /></el-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-box">
      <div class="handle-btn">Save</div>
    </div>
  </div>
</template>
    <script>
export default {};
</script>
    <style lang="scss" scoped>
.bottom-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.handle-btn {
  color: #fff;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #2f4cdd;
  border-radius: 10px;
  cursor: pointer;
}
.Raw-box {
  display: flex;
  flex-wrap: wrap;
}
.Raw-card {
  margin-right: 15px;
  margin-top: 15px;
  padding: 10px 35px 0px 20px;
  width: 390px;
  border-radius: 10px;
  color: #363b64;
  background: #f2f3f5;
  // background: linear-gradient(0deg, #f4f7ff, #f4f7ff),
  //   linear-gradient(94.84deg, #fff 3.78%, #fcfdff 9.75%, #e6eeff 102.09%),
  //   linear-gradient(126.58deg, #fff -18.12%, hsla(0, 0%, 100%, 0) 26.77%);
  .Raw-top {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    .Raw-title {
      display: flex;
      align-items: center;
    }
    .el-icon {
      font-size: 18px;
      font-weight: 600;
      color: #a098ae;
    }
  }
  .Raw-edit-btn {
    margin-bottom: -20px;
    text-align: right;
    font-size: 20px;
  }
  .Raw-card-main {
    padding: 10px 20px 10px;
    width: 100%;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8) !important;
    p {
      padding: 10px 0 10px 4px;
      font-weight: 600;
      color: #363b64;
    }
    input {
      width: 80%;
      background: #f6f6f6;
      border: 1px solid #718ebf;
      border-radius: 10px;
    }
  }
}
.Raw-card-item {
  .el-icon {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #a098ae;
  }
}
.Total-MaterialCost {
  display: flex;
  justify-content: flex-end;
  color: #363b64;
  font-weight: 600;
  .MaterialCost {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input {
    width: 200px;
    font-size: 12px;
    background: #f6f6f6;
    border: 1px solid #718ebf;
    border-radius: 10px;
  }
}
</style>