<template>
  <div style="padding: 15px">
    <!-- <span @click="SwichMode = !SwichMode">Swich</span> -->
    <div class="Total-MaterialCost">
      <div>
        <div
          style="margin-left: 10px"
          v-show="type != 'template' && myTemplate !== false"
        >
          <el-upload
            class="custom-el-upload-picture"
            :file-list="materialfileList"
            action="/"
            :auto-upload="false"
            multiple
            :limit="5"
            list-type="picture-card"
            accept="image/*"
            :on-preview="handlePictureCardPreview"
            :on-remove="handlePictureCardRemove"
            :on-change="pictureCardChange"
            v-show="operateType != 'detail'"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
          <div class="tcm-content-rawPic" v-show="operateType == 'detail'">
            <el-image
              v-for="(item, index) in materialfileList"
              style="width: 40px; height: 40px; margin-right: 10px"
              :src="item.url"
              :zoom-rate="1.2"
              :preview-src-list="attachmentList"
              :initial-index="index"
              fit="cover"
            />
          </div>
        </div>
      </div>
      <div class="MaterialCost" v-show="type != 'template'">
        <p>{{ $t("changePriceLists.changePriceList.totalCost") }}</p>
        <input v-model="materialCost_RM" disabled type="text" />
      </div>
    </div>
    <!-- <div class="Raw-box" v-show="!SwichMode">
      <div class="Raw-card" v-for="(item, index) in formData" :key="index">
        <div class="Raw-top">
          <div class="Raw-title">Raw <br />Material</div>
          <div class="Raw-handle">
            <!== <el-icon><MoreFilled /></el-icon> ==>
            <div class="flex" v-show="type != 'template'">
              <img
                src="../../assets/img/tcm/zuojiantou.png"
                style="width: 20px; height: 20px"
                @click="reorder('left', index)"
              />
              <img
                src="../../assets/img/tcm/youjiantou.png"
                style="width: 20px; height: 20px"
                @click="reorder('right', index)"
              />
            </div>
          </div>
        </div>
        <div class="Raw-card-main">
          <div class="Raw-edit-btn">
            <el-icon><EditPen /></el-icon>
          </div>
          <!== currency ==>
          <div class="Raw-card-item">
            <p>{{ $t("changePriceLists.changePriceList.currency") }}</p>
            <el-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              filterable
              v-model="item.currencyId"
              placeholder=""
              style="width: calc(100% - 20px)"
              :disabled="operateType == 'detail' || type != 'template'"
            >
              <el-option
                v-for="item in CurrencyOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <!== convertCurrency ==>
          <div class="Raw-card-item" v-show="type != 'template'">
            <p>{{ $t("changePriceLists.changePriceList.convertCurrency") }}</p>
            <el-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              filterable
              v-model="item.convertCurrencyId"
              placeholder=""
              style="width: calc(100% - 20px)"
              disabled
            >
              <el-option
                v-for="item in CurrencyOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <!== exchangeRate ==>
          <div class="Raw-card-item" v-show="type != 'template'">
            <p>{{ $t("changePriceLists.changePriceList.exchangeRate") }}</p>
            <el-input
              v-model="item.converRate"
              @input="
                transferFormat($event, item, 'converRate'), toComputeCost(item)
              "
              @change="toComputeCost(item)"
              @blur="toComputeCost(item)"
              :disabled="operateType == 'detail'"
              style="width: calc(100% - 20px)"
            />
          </div>
          <!== cellNo ==>
          <div v-show="type == 'template'">
            <div class="Raw-card-item" v-show="operateType != 'add'">
              <p>{{ $t("templateLists.templateList.cellNo") }}</p>
              <el-input
                v-model="item.number"
                style="width: calc(100% - 20px)"
                disabled
              />
            </div>
            <div class="Raw-card-item">
              <p>{{ $t("templateLists.templateList.cellName") }}</p>
              <el-input
                v-model="item.name"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
              />
            </div>
            <div class="Raw-card-item">
              <p>{{ $t("changePriceLists.changePriceList.commodity") }}</p>
              <el-tree-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="item.commodityId"
                append-to-body
                @change="changeCommodityId($event, index)"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
              >
                <template #default="{ data: { Name } }">
                  <el-tooltip :content="Name" placement="top" effect="light">
                    {{ Name }}
                  </el-tooltip>
                </template>
              </el-tree-select>
            </div>
            <div class="Raw-card-item">
              <p>{{ $t("templateLists.templateList.region") }}</p>
              <el-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="item.regionId"
                placeholder=""
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
              >
                <el-option
                  v-for="item in regionOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="Raw-card-item" v-show="operateType != 'add'">
              <p>{{ $t("templateLists.templateList.version") }}</p>
              <el-input
                v-model="item.version"
                style="width: calc(100% - 20px)"
                disabled
              />
            </div>
            <div class="Raw-card-item">
              <p>{{ $t("templateLists.templateList.comments") }}</p>
              <el-input
                v-model="item.comment"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
              />
            </div>
          </div>

          <div>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.materialDescription") }}
              </p>
              <el-input
                v-model="item.description"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
              />
            </div>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.vendor") }}
              </p>
              <el-input
                v-model="item.vendor"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
              />
            </div>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.netWeight") }}
              </p>
              <el-input
                v-model="item.netMWeight"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail' || type != 'alloperate'"
                @input="
                  transferFormat($event, item, 'netMWeight'),
                    toComputeCost(item)
                "
                @change="toComputeCost(item)"
                @blur="toComputeCost(item)"
              />
            </div>
            <!== grossWeight ==>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.grossWeight") }}
              </p>
              <div class="flex">
                <el-input
                  v-model="item.grossMWeight"
                  style="width: calc(100% - 20px)"
                  :disabled="operateType == 'detail' || type != 'alloperate'"
                  @input="
                    transferFormat($event, item, 'grossMWeight'),
                      toComputeCost(item)
                  "
                  @change="toComputeCost(item)"
                  @blur="toComputeCost(item)"
                />
                <img
                  src="../../assets/img/tcm/daikuanjisuanqi.png"
                  v-show="type == 'alloperate' && operateType != 'detail'"
                  @click="openCal(index, 'grossMWeight')"
                />
              </div>
            </div>
            <!== unitMeasure ==>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.unitMeasure") }}
              </p>
              <el-select
                clearable
                filterable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="item.unitMeasureId"
                style="width: calc(100% - 20px)"
                placeholder=""
                :disabled="operateType == 'detail'"
              >
                <el-option
                  v-for="it in unitOptions"
                  :key="it.id"
                  :label="it.name"
                  :value="it.id"
                />
              </el-select>
            </div>
            <!== materialMeasure ==>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.materialMeasure") }}
              </p>
              <el-input
                v-model="item.unitCost"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'unitCost'), toComputeCost(item)
                "
                @change="toComputeCost(item)"
                @blur="toComputeCost(item)"
              />
            </div>
            <!== scrapPrice ==>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.scrapPrice") }}
              </p>
              <el-input
                v-model="item.unitResaleValue"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'unitResaleValue'),
                    toComputeCost(item)
                "
                @change="toComputeCost(item)"
                @blur="toComputeCost(item)"
              />
            </div>
            <!== country ==>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.country") }}
              </p>
              <el-input
                v-model="item.country"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
              />
            </div>
            <!== date ==>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.date") }}
              </p>
              <el-input
                v-model="item.date"
                style="width: calc(100% - 20px)"
                :disabled="operateType == 'detail'"
              />
            </div>
            <!== materialCost ==>
            <div class="Raw-card-item">
              <p>
                {{ $t("changePriceLists.changePriceList.materialCost") }}
              </p>
              <el-input
                v-model="item.cost"
                style="width: calc(100% - 20px)"
                disabled
              />
            </div>
            <div v-show="type == 'template' && operateType != 'detail'">
              <div class="flex" style="margin: 10px 0">
                <div class="submit-btn" @click="cancle">
                  {{ $t("userlists.userlist.cancel") }}
                </div>
                <div class="submit-btn" @click="confirm">
                  {{ $t("userlists.userlist.confirm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Raw-top" style="padding: 10px 0px">
          <div
            class="Raw-title"
            v-show="type != 'template' && operateType != 'detail'"
            @click="addEmptyMoudle"
          >
            <!== <el-icon><CirclePlus /></el-icon>
            <span style="padding-left: 10px">{{
              $t("changePriceLists.changePriceList.fromTemplate")
            }}</span> ==>
          </div>
          <div
            class="Raw-handle"
            style="margin-right: -20px"
            v-show="type != 'template' && operateType != 'detail'"
          >
            <el-icon @click="copyMoudle(index)"><CopyDocument /></el-icon>
            <el-icon @click="deleteMoudle(index)" style="margin-left: 5px"
              ><DeleteFilled
            /></el-icon>
          </div>
        </div>
      </div>
      <div
        class="Raw-card"
        v-show="type != 'template' && operateType != 'detail'"
      >
        <div class="Raw-top">
          <div class="Raw-title">Raw <br />Material</div>
          <!== <div class="Raw-handle">
            <el-icon><MoreFilled /></el-icon>
          </div> ==>
        </div>
        <div class="tcm-form-solid">
          <div @click="addMoudle" class="add-cla-template">
            <el-icon :size="35" color="#8c8c8c"><Plus /></el-icon>
            <div>{{ $t("changePriceLists.changePriceList.fromTemplate") }}</div>
          </div>
        </div>
        <div class="Raw-top" style="padding: 10px 0px">
          <div
            class="Raw-title"
            v-show="type != 'template'"
            @click="addEmptyMoudle"
          >
            <el-icon><CirclePlus /></el-icon>
            <span style="padding-left: 10px">
              {{ $t("changePriceLists.changePriceList.materialInput") }}
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="flex" style="padding: 20px">
      <div class="table-box" id="idname">
        <table class="add-table">
          <tr>
            <td></td>
            <td
              v-for="(item, index) in formData"
              :key="index"
              style="border-bottom: 1px solid #ccc"
            >
              <div
                class="flex"
                style="
                  position: relative;
                  width: 100%;
                  height: 100%;
                  justify-content: space-evenly;
                  align-items: center;
                "
              >
                <span class="Serial_number">#{{ index + 1 }}</span>
                <img
                  v-show="type != 'template'"
                  src="../../assets/img/tcm/zuojiantou.png"
                  style="width: 23px; height: 23px"
                  @click="reorder('left', index)"
                />
                <img
                  v-show="type != 'template'"
                  src="../../assets/img/tcm/youjiantou.png"
                  style="width: 23px; height: 23px"
                  @click="reorder('right', index)"
                />
                <el-icon
                  v-show="type != 'template' && operateType != 'detail'"
                  :size="18"
                  @click="copyMoudle(index)"
                  ><CopyDocument
                /></el-icon>
                <el-icon
                  v-show="type != 'template' && operateType != 'detail'"
                  :size="18"
                  @click="deleteMoudle(index)"
                  ><DeleteFilled
                /></el-icon>
                <div
                  v-if="type == 'template' && operateType != 'detail'"
                  class="submit-btn"
                  @click="cancle"
                >
                  {{ $t("userlists.userlist.cancel") }}
                </div>
                <div
                  v-if="type == 'template' && operateType != 'detail'"
                  class="submit-btn"
                  @click="confirm"
                >
                  {{ $t("userlists.userlist.confirm") }}
                </div>
              </div>
            </td>
          </tr>
          <!-- <div class="btn-text" @click="deleteRow(index)">Delete</div> -->
          <tr>
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.materialDescription") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.description"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.currency") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="item.currencyId"
                placeholder=""
                :disabled="operateType == 'detail' || type != 'template'"
              >
                <el-option
                  v-for="item in CurrencyOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
          </tr>
          <tr v-show="type != 'template'">
            <td>
              <div>
                <span class="color-red">*</span>
                {{ $t("changePriceLists.changePriceList.convertCurrency") }}
              </div>
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="item.convertCurrencyId"
                placeholder=""
                disabled
              >
                <el-option
                  v-for="item in CurrencyOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
          </tr>
          <tr v-show="type != 'template'">
            <td>
              <div>
                <span class="color-red">*</span>
                {{ $t("changePriceLists.changePriceList.exchangeRate") }}
              </div>
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.converRate"
                @input="
                  transferFormat($event, item, 'converRate'),
                    toComputeCost(item)
                "
                @change="toComputeCost(item)"
                @blur="toComputeCost(item)"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <!-- <template v-show="type == 'template'"> -->
          <tr v-show="operateType != 'add' && type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("templateLists.templateList.cellNo") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.number" disabled />
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("templateLists.templateList.cellName") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.name"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.commodity") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-tree-select
                style="width: 100%"
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="item.commodityId"
                append-to-body
                @change="changeCommodityId($event, index)"
                :disabled="operateType == 'detail'"
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
              >
                <template #default="{ data: { Name } }">
                  <el-tooltip :content="Name" placement="top" effect="light">
                    {{ Name }}
                  </el-tooltip>
                </template>
              </el-tree-select>
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>
              <span class="color-red">*</span>
              {{ $t("templateLists.templateList.region") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                v-model="item.regionId"
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                :disabled="operateType == 'detail'"
              >
                <el-option
                  v-for="item in regionOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
          </tr>
          <tr v-show="operateType != 'add' && type == 'template'">
            <td>{{ $t("templateLists.templateList.version") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.version" disabled />
            </td>
          </tr>
          <tr v-show="type == 'template'">
            <td>{{ $t("templateLists.templateList.comments") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.comment"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <!-- </template> -->
          <!-- --------- -->

          <tr>
            <td>{{ $t("changePriceLists.changePriceList.vendor") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.vendor"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>
              {{ $t("changePriceLists.changePriceList.netWeight") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.netMWeight"
                :disabled="operateType == 'detail' || type != 'alloperate'"
                @input="
                  transferFormat($event, item, 'netMWeight'),
                    toComputeCost(item)
                "
                @change="toComputeCost(item)"
                @blur="toComputeCost(item)"
              />
            </td>
          </tr>

          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>
              {{ $t("changePriceLists.changePriceList.grossWeight") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <div class="flex" style="flex: 1; align-items: center">
                <el-input
                  v-model="item.grossMWeight"
                  :disabled="operateType == 'detail' || type != 'alloperate'"
                  @input="
                    transferFormat($event, item, 'grossMWeight'),
                      toComputeCost(item)
                  "
                  @change="toComputeCost(item)"
                  @blur="toComputeCost(item)"
                />
                <img
                  style="width: 25px; height: 25px"
                  src="../../assets/img/tcm/daikuanjisuanqi.png"
                  v-show="type == 'alloperate' && operateType != 'detail'"
                  @click="openCal(index, 'grossMWeight')"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.unitMeasure") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-select
                style="width: 100%"
                clearable
                filterable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="item.unitMeasureId"
                :disabled="operateType == 'detail'"
              >
                <el-option
                  v-for="it in unitOptions"
                  :key="it.id"
                  :label="it.name"
                  :value="it.id"
                />
              </el-select>
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.materialMeasure") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.unitCost"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'unitCost'), toComputeCost(item)
                "
                @change="toComputeCost(item)"
                @blur="toComputeCost(item)"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>
              {{ $t("changePriceLists.changePriceList.scrapPrice") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.unitResaleValue"
                :disabled="operateType == 'detail'"
                @input="
                  transferFormat($event, item, 'unitResaleValue'),
                    toComputeCost(item)
                "
                @change="toComputeCost(item)"
                @blur="toComputeCost(item)"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.country") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.country"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.date") }}</td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input
                v-model="item.date"
                :disabled="operateType == 'detail'"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-red" v-show="type != 'template'">*</span>
              {{ $t("changePriceLists.changePriceList.materialCost") }}
            </td>
            <td v-for="(item, index) in formData" :key="index">
              <el-input v-model="item.cost" disabled />
            </td>
          </tr>
        </table>
      </div>
      <div
        class="add-column"
        v-show="type != 'template' && operateType != 'detail'"
      >
        <div @click="addMoudle">
          {{ $t("changePriceLists.changePriceList.fromTemplate") }}
        </div>
        <div @click="addEmptyMoudle" style="border-top: 1px solid #ccc">
          {{ $t("changePriceLists.changePriceList.materialInput") }}
        </div>
      </div>
    </div>
    <el-dialog
      v-model="visible"
      width="40%"
      destroy-on-close
      :show-close="false"
    >
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header">
          <div class="size-16">
            {{ $t("changePriceLists.changePriceList.materialInput") }}
          </div>
          <div style="cursor: pointer" @click="visible = false">
            <el-icon :size="20"><Close /></el-icon>
          </div>
        </div>
      </template>
      <div class="Raw-card-main">
        <div class="Raw-card-item">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.materialDescription") }}
          </p>
          <el-input
            v-model="AddForm.description"
            style="width: calc(100% - 20px)"
          />
        </div>

        <!-- currency -->
        <div class="Raw-card-item">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.currency") }}
          </p>
          <el-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            filterable
            v-model="AddForm.currencyId"
            placeholder=""
            style="width: calc(100% - 20px)"
            disabled
          >
            <el-option
              v-for="item in CurrencyOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <!-- convertCurrency -->
        <div class="Raw-card-item">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.convertCurrency") }}
          </p>
          <el-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            filterable
            v-model="AddForm.convertCurrencyId"
            placeholder=""
            @change="CalcRate"
            style="width: calc(100% - 20px)"
          >
            <el-option
              v-for="item in CurrencyOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <!-- exchangeRate -->
        <div class="Raw-card-item">
          <p>
            <span class="color-red">*</span>
            {{ $t("changePriceLists.changePriceList.exchangeRate") }}
          </p>
          <el-input
            v-model="AddForm.converRate"
            @input="
              transferFormat($event, AddForm, 'converRate'),
                toComputeCost(AddForm)
            "
            @change="toComputeCost(AddForm)"
            @blur="toComputeCost(AddForm)"
            style="width: calc(100% - 20px)"
          />
        </div>
        <div>
          <div class="Raw-card-item">
            <p>
              {{ $t("changePriceLists.changePriceList.vendor") }}
            </p>
            <el-input
              v-model="AddForm.vendor"
              style="width: calc(100% - 20px)"
            />
          </div>
          <div class="Raw-card-item">
            <p>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.netWeight") }}
            </p>
            <el-input
              v-model="AddForm.netMWeight"
              style="width: calc(100% - 20px)"
              @input="
                transferFormat($event, AddForm, 'netMWeight'),
                  toComputeCost(AddForm)
              "
              @change="toComputeCost(AddForm)"
              @blur="toComputeCost(AddForm)"
            />
          </div>
          <!-- grossWeight -->
          <div class="Raw-card-item">
            <p>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.grossWeight") }}
            </p>
            <div class="flex" style="width: calc(100% - 20px)">
              <el-input
                v-model="AddForm.grossMWeight"
                style="width: calc(100%)"
                @input="
                  transferFormat($event, AddForm, 'grossMWeight'),
                    toComputeCost(AddForm)
                "
                @change="toComputeCost(AddForm)"
                @blur="toComputeCost(AddForm)"
              />
              <img
                src="../../assets/img/tcm/daikuanjisuanqi.png"
                @click="openCal_Add('grossMWeight')"
              />
            </div>
          </div>
          <!-- unitMeasure -->
          <div class="Raw-card-item">
            <p>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.unitMeasure") }}
            </p>
            <el-select
              clearable
              filterable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              v-model="AddForm.unitMeasureId"
              style="width: calc(100% - 20px)"
              placeholder=""
            >
              <el-option
                v-for="it in unitOptions"
                :key="it.id"
                :label="it.name"
                :value="it.id"
              />
            </el-select>
          </div>
          <!-- materialMeasure -->
          <div class="Raw-card-item">
            <p>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.materialMeasure") }}
            </p>
            <el-input
              v-model="AddForm.unitCost"
              style="width: calc(100% - 20px)"
              :disabled="operateType == 'detail'"
              @input="
                transferFormat($event, AddForm, 'unitCost'),
                  toComputeCost(AddForm)
              "
              @change="toComputeCost(AddForm)"
              @blur="toComputeCost(AddForm)"
            />
          </div>
          <div class="Raw-card-item">
            <p>
              <span class="color-red">*</span>

              {{ $t("changePriceLists.changePriceList.scrapPrice") }}
            </p>
            <el-input
              v-model="AddForm.unitResaleValue"
              style="width: calc(100% - 20px)"
              @input="
                transferFormat($event, AddForm, 'unitResaleValue'),
                  toComputeCost(AddForm)
              "
              @change="toComputeCost(AddForm)"
              @blur="toComputeCost(AddForm)"
            />
          </div>
          <!-- country -->
          <div class="Raw-card-item">
            <p>
              {{ $t("changePriceLists.changePriceList.country") }}
            </p>
            <el-input
              v-model="AddForm.country"
              style="width: calc(100% - 20px)"
            />
          </div>
          <!-- date -->
          <div class="Raw-card-item">
            <p>
              {{ $t("changePriceLists.changePriceList.date") }}
            </p>
            <el-input v-model="AddForm.date" style="width: calc(100% - 20px)" />
          </div>
          <!-- materialCost -->
          <div class="Raw-card-item">
            <p>
              <span class="color-red">*</span>
              {{ $t("changePriceLists.changePriceList.materialCost") }}
            </p>
            <el-input
              v-model="AddForm.cost"
              style="width: calc(100% - 20px)"
              disabled
            />
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="visible = false">{{
            $t("userlists.userlist.cancel")
          }}</el-button>
          <el-button
            style="
              background-color: var(--themeColor);
              color: var(--menuTextActive);
              margin-right: 16px;
            "
            @click="addEmptyMoudleSubmit"
            >{{ $t("userlists.userlist.confirm") }}</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="rawPredialogVisible">
      <img
        w-full
        :src="dialogImageUrl"
        alt="Preview Image"
        style="width: 100%"
      />
    </el-dialog>
    <CalculatorList
      ref="calList"
      :applyId="applyId"
      type="edit"
      @chooseRow="chooseRow"
      @submitPlastic="submitPlastic"
    ></CalculatorList>
    <PreviewDia
      ref="preDia"
      type="template"
      :formulaPre="outform.formulaPre"
      :previewResultx="previewResultx"
      :fileListUrl="fileListUrl"
      :intermediateBondList="intermediateBondListPreview"
      :valueList="valueList"
      @tocompute="tocompute"
      @toConfirm="toConfirm"
    ></PreviewDia>
    <RawDia
      ref="rawDialog"
      :CommodityId="CommodityId"
      :CurrencyOptions="CurrencyOptions"
      @getChooseRawId="getChooseRawId"
    ></RawDia>
    <AddRate ref="rateDialog" @rateSubmit="rateSubmit"></AddRate>
  </div>
</template>
<script>
import {
  getCategorys,
  GetCalculatorDetail,
  InputParameterAnalysis,
  getTemplateMaterialsDetail,
  getMultiRate,
} from "../../assets/js/requestApi.js";
import "../../assets/css/custom-el-upload-picture.css";
import commonUtil from "../../assets/js/commonUtil.js";
import CalculatorList from "../processCalculatorManagement/calculatorListDia.vue";
import PreviewDia from "../processCalculatorManagement/previewDia.vue";
import RawDia from "../rawManagement/rawDia.vue";
import AddRate from "./addRate.vue";
export default {
  props: [
    "type",
    "operateType",
    "CommodityId",
    "CommodityName",
    "CommodityOptions",
    "regionOptions",
    "CurrencyOptions",
    "currencyId",
    "myTemplate",
    "applyId",
  ],
  emits: ["goBack", "confirmData"],
  components: {
    CalculatorList,
    PreviewDia,
    RawDia,
    AddRate,
  },
  data() {
    return {
      materialCost_RM: "",
      formData: [
        {
          id: "",
          number: "",
          name: "",
          version: "",
          vendor: "",
          comment: "",
          isEnable: 1,
          description: "",
          netMWeight: "",
          grossMWeight: "",
          unitMeasureId: "",
          unitCost: "",
          unitResaleValue: "",
          country: "",
          cost: "",
          commodityId: "",
          regionId: "",
          currencyId: "",
          convertCurrencyId: "",
          converRate: "",
          cLines: [],
          date: "",
          // 历史数据
          calculateData: "",
        },
      ],
      // 单位选项
      unitOptions: [],
      // 商品分类显示字段
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 工艺计算器详细数据
      form: {
        id: "",
        process_Calculator_Code: "",
        name: "",
        commodityId: "",
        commodityName: "",
        version: "",
        comments: "",
      },
      // 上传的文件
      fileListx: [],
      fileListUrl: [],
      // 表格内容
      tableData: [],
      outform: {
        name: "",
        unit: "",
        formula: "",
        formulaVal: "",
        formulaPre: "",
      },
      // 符号
      symbol: ["+", "-", "*", "/", "sum", "if", "(", ")"],
      // 计算结果
      previewResultx: 0,
      // 打开计算器输入框类型
      calType: "input",
      // 中间键列表
      intermediateBondList: [],
      // 数值列表
      valueList: [],
      // 计算后中间键列表
      intermediateBondListPreview: [],
      // 当前操作数据下标
      operateIndex: "",
      // 当前选择的工艺计算器id
      operateCalId: "",
      // 编辑时删除的组件id
      materialDelIds: [],
      // Net Material Weight/Material Scrap Resale Value / Unit of Measure乘积之和
      NmCost: 0,
      // 选择的组件税率转化数组
      addCellExchangeList: [],
      // 选择的组件数据
      addCellList: [],
      // 计算调用参数
      computeNum: 0,
      // 页面总的上传文件
      materialfileList: [],
      // 预览上传的文件
      rawPredialogVisible: false,
      dialogImageUrl: "",
      // 预览图片路径
      attachmentList: [],
      visible: false,
      AddForm: {},
    };
  },
  mounted() {
    this.getUnit();
  },
  watch: {
    computeNum: {
      handler(nval, oval) {
        if (nval == this.intermediateBondListPreview.length) {
          this.$refs.preDia.visible = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    // 获取单位选项
    getUnit() {
      getCategorys(
        {
          TypeID: "MeasureUnit",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.unitOptions = response.data;
          }
        }
      );
    },
    // 取消
    cancle() {
      this.$emit("goBack");
    },
    // 提交
    confirm() {
      this.formData[0].convertCurrencyId = this.formData[0].currencyId;
      this.formData[0].converRate = "1";
      this.$emit("confirmData", this.formData);
    },
    // 打开计算器
    openCal(index, name) {
      let _this = this;
      if (this.operateType == "detail") {
        return;
      }
      // if (this.formData[index].netMWeight == '') {
      // 	commonUtil.showTipInfo('请输入Net Material Weight！', '', 'warning')
      // 	return;
      // }
      this.operateIndex = index;
      this.$refs.calList.operateName = name;
      let form = this.formData[index].calculateData
        ? JSON.parse(this.formData[index].calculateData)
        : [];

      this.$refs.calList.getTreeData();
      this.$refs.calList.visible = true;
      if (form.length > 0) {
        this.$refs.calList.getlist(1);
        form.forEach(function (val, index) {
          if (val.operateName == name) {
            _this.$refs.calList.setData(val);
          }
        });
      } else {
        this.$refs.calList.getlist(2);
        this.$refs.calList.activeName = "first";
        this.$refs.calList.activeRadio = "tonnage";
      }
    },
    openCal_Add(name) {
      let _this = this;
      // if (this.formData[index].netMWeight == '') {
      // 	commonUtil.showTipInfo('请输入Net Material Weight！', '', 'warning')
      // 	return;
      // }
      this.$refs.calList.operateName = name;
      let form = this.AddForm.calculateData
        ? JSON.parse(this.AddForm.calculateData)
        : [];

      this.$refs.calList.getTreeData();
      this.$refs.calList.visible = true;
      if (form.length > 0) {
        this.$refs.calList.getlist(1);
        form.forEach(function (val, index) {
          if (val.operateName == name) {
            _this.$refs.calList.setData(val);
          }
        });
      } else {
        this.$refs.calList.getlist(2);
        this.$refs.calList.activeName = "first";
        this.$refs.calList.activeRadio = "tonnage";
      }
    },
    // 获取选择的工艺计算器
    chooseRow(row) {
      this.operateCalId = row.id;
      this.getDetail(row.id);
    },
    // 获取选择的plastic的值
    submitPlastic(value, form) {
      this.$refs.calList.visible = false;
      this.formData[this.operateIndex].grossMWeight = value;
      if (value !== "") {
        if (this.formData[this.operateIndex].calculateData === "") {
          let calculateData = [];
          calculateData.push(form);
          this.formData[this.operateIndex].calculateData =
            JSON.stringify(calculateData);
        } else {
          let calculateData = JSON.parse(
            this.formData[this.operateIndex].calculateData
          );
          let newCalculateData = [];
          // 是否包含当前数据
          let isContain = false;
          let containIndex = "";
          calculateData.forEach(function (val, index) {
            newCalculateData.push(val);
            if (val.operateName == form.operateName) {
              isContain = true;
              containIndex = index;
            }
          });
          if (isContain) {
            newCalculateData[containIndex] = form;
          } else {
            newCalculateData.push(form);
          }
          this.formData[this.operateIndex].calculateData =
            JSON.stringify(newCalculateData);
        }
      }
      this.toComputeCost(this.formData[this.operateIndex]);
    },
    // 根据id获取选择的工艺计算器详情
    getDetail(id) {
      let _this = this;
      this.computeNum = 0;
      GetCalculatorDetail('"' + id + '"', (response) => {
        if (response && response.code == 200) {
          this.form = {
            id: response.data[0].id,
            process_Calculator_Code: response.data[0].process_Calculator_Code,
            name: response.data[0].process_Calculator_Name,
            commodityId: response.data[0].commodityId,
            commodityName: response.data[0].commodList[0].name,
            version: response.data[0].version,
            comments: response.data[0].comments,
          };
          _this.intermediateBondList = [];
          _this.valueList = [];
          this.tableData = response.data[0].edit_List;
          this.tableData.forEach(function (val, key) {
            val.formulaVal = val.formulaPre
              ? val.formulaPre.replace(/\|/g, "")
              : "";
            if (val.formula != "") {
              _this.intermediateBondList.push(val);
            } else {
              _this.valueList.push(val);
            }
          });
          this.intermediateBondListPreview = JSON.parse(
            JSON.stringify(this.intermediateBondList)
          );
          this.outform = {
            name: response.data[0].outputName,
            unit: response.data[0].outputUnit,
            formula: response.data[0].outputFormulaPre.replace(/\|/g, ""),
            formulaVal: response.data[0].outputFormula,
            formulaPre: response.data[0].outputFormulaPre,
          };
          this.fileListx = response.data[0].pictureList;
          // 计算默认值
          let list = [];
          this.valueList.forEach(function (val, key) {
            list.push({
              paramValue: val.thedefaultvalue,
              paramentName: val.parameterName,
            });
          });
          this.intermediateBondList.forEach(function (val, key) {
            list.push({
              paramValue: val.formulaPre,
              paramentName: val.parameterName,
            });
          });

          this.intermediateBondListPreview.forEach(function (val, key) {
            // val.formulaPre
            let data = {
              outputFormula: val.formulaPre,
              inputParameterList: list,
            };
            InputParameterAnalysis(data, (response) => {
              _this.computeNum++;
              if (response && response.code == 200) {
                val.thedefaultvalue = response.data;
              }
            });
          });
          this.previewResultx = 0;
          if (this.intermediateBondListPreview.length == 0) {
            this.$refs.preDia.visible = true;
          }
          // let data = {
          // 	outputFormula: this.outform.formulaPre,
          // 	inputParameterList: list
          // }

          // this.tocompute(data)
        }
      });
    },
    // 计算结果
    tocompute(val) {
      InputParameterAnalysis(val, (response) => {
        if (response && response.code == 200) {
          this.previewResultx = response.data;
          this.$refs.preDia.visible = true;
        }
      });
    },
    // 获取最后计算的值
    toConfirm(val) {
      this.$refs.calList.visible = false;
      this.formData[this.operateIndex].grossMWeight = val;
      this.formData[this.operateIndex].cLines = [];
      this.formData[this.operateIndex].cLines.push({
        fieldName: "GrossMWeight",
        calculatorTableId: this.operateCalId,
      });
      this.toComputeCost(this.formData[this.operateIndex]);
    },
    // 添加组件
    addMoudle() {
      if (
        this.CommodityId == "" ||
        this.CommodityId == null ||
        !this.CommodityId
      ) {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.commodityRequired"),
          "",
          "warning"
        );
        return;
      }
      if (
        this.currencyId == "" ||
        this.currencyId == null ||
        !this.currencyId
      ) {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.currencyRequired"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.rawDialog.searchForm.page = 1;
      this.$refs.rawDialog.getlist();
      this.$refs.rawDialog.visible = true;
    },
    // 添加空组件
    addEmptyMoudle() {
      this.visible = true;
      this.AddForm = {
        id: "",
        number: "",
        name: "",
        version: "",
        vendor: "",
        comment: "",
        isEnable: 1,
        description: "",
        netMWeight: "",
        grossMWeight: "",
        unitMeasureId: "",
        unitCost: "",
        unitResaleValue: "",
        country: "",
        cost: "",
        commodityId: "",
        currencyId: this.currencyId ? this.currencyId : "",
        convertCurrencyId: this.currencyId ? this.currencyId : "",
        converRate: this.currencyId ? "1" : "",
        exchangeRate: this.currencyId ? "1" : "",
        regionId: "",
        cLines: [],
        date: "",
        calculateData: "",
      };
    },
    addEmptyMoudleSubmit() {
      this.formData.push(this.AddForm);
      this.visible = false;
      this.$nextTick(() => {
        let outDiv = document.getElementById("idname");
        const scrollLeft = this.formData.length * 221 + 201;
        outDiv.scrollTo(scrollLeft, 0);
      });
    },
    // 获取添加组件的详情信息
    getChooseRawId(id) {
      let _this = this;
      getTemplateMaterialsDetail(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            // response.result.forEach(function(resVal, resIndex) {
            // 	let cLines = []
            // 	resVal.cLines.forEach(function(val, index) {
            // 		cLines.push({
            // 			fieldName: val.paramName,
            // 			calculatorTableId: val.calculator_TableId
            // 		})
            // 	})
            // 	let moudle = resVal
            // 	moudle.id  = ''
            // 	moudle.cLines = cLines
            // 	_this.formData.push(moudle)
            // })
            // _this.materialCost_RM = 0
            // this.formData.forEach(function(val, index) {
            // 	_this.materialCost_RM += parseFloat(val.cost === '' || !val.cost ? 0 : val.cost)
            // })
            // _this.materialCost_RM = _this.materialCost_RM.toFixed(3)
            // this.$refs.rawDialog.visible = false
            this.getMultiRates(response.result);
          }
        }
      );
    },
    // 获取选择组件税率对应关系
    getMultiRates(arr) {
      let _this = this;
      let multiRow = [];
      arr.forEach(function (resVal, resIndex) {
        let cLines = [];
        resVal.cLines.forEach(function (val, index) {
          cLines.push({
            fieldName: val.paramName,
            calculatorTableId: val.calculator_TableId,
          });
        });
        let moudle = resVal;
        // moudle.id  = ''
        moudle.cLines = cLines;
        // moudle.currId = moudle.currencyId
        moudle.currencyId = _this.currencyId;
        moudle.currId = moudle.convertCurrencyId;
        moudle.currCNYId = _this.currencyId;
        moudle.exchangeRate = "";
        moudle.calculateData = "";
        multiRow.push(moudle);
      });
      _this.addCellList = multiRow;
      this.$refs.rawDialog.visible = false;
      getMultiRate(
        {
          genList: multiRow,
        },
        (response) => {
          if (response && response.code == 200) {
            _this.addCellExchangeList = [];
            let untaxedNum = 0;
            _this.addCellExchangeList = response.data[0].exchangeList;
            _this.addCellExchangeList.forEach(function (excVal, excIndex) {
              if (excVal.exchangeRate === "") {
                excVal.hasRate = false;
                untaxedNum++;
              } else {
                excVal.hasRate = true;
              }
            });
            // 如果其中有无税率的情况则弹窗输入
            if (untaxedNum > 0) {
              _this.$refs.rateDialog.addCellExchangeList =
                _this.addCellExchangeList;
              _this.$refs.rateDialog.visible = true;
            } else {
              // 如果税率都有则转换
              _this.$refs.rateDialog.visible = false;
              _this.addCellExchangeList.forEach(function (rateVal, rateIndex) {
                _this.addCellList.forEach(function (cellVal, cellIndex) {
                  if (rateVal.id == cellVal.id) {
                    cellVal.exchangeRate = rateVal.exchangeRate;
                    cellVal.converRate = rateVal.exchangeRate;
                    cellVal.unitCost =
                      parseFloat(
                        cellVal.unitCost === "" ||
                          cellVal.unitCost === null ||
                          !cellVal.unitCost
                          ? "0"
                          : cellVal.unitCost
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                    cellVal.unitResaleValue =
                      parseFloat(
                        cellVal.unitResaleValue === "" ||
                          cellVal.unitResaleValue === null ||
                          !cellVal.unitResaleValue
                          ? "0"
                          : cellVal.unitResaleValue
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                    cellVal.cost =
                      parseFloat(
                        cellVal.cost === "" ||
                          cellVal.cost === null ||
                          !cellVal.cost
                          ? "0"
                          : cellVal.cost
                      ) *
                      (parseFloat(cellVal.exchangeRate) === 0
                        ? 1
                        : parseFloat(cellVal.exchangeRate));
                  }
                });
              });
              _this.addCellTransfer();
            }
          }
        }
      );
    },
    // 填写完税率后获取并且转化数据
    rateSubmit(rateList) {
      let _this = this;
      rateList.forEach(function (rateVal, rateIndex) {
        _this.addCellList.forEach(function (cellVal, cellIndex) {
          if (rateVal.id == cellVal.id) {
            cellVal.exchangeRate = rateVal.exchangeRate;
            cellVal.converRate = rateVal.exchangeRate;
            cellVal.unitCost =
              parseFloat(
                cellVal.unitCost === "" ||
                  cellVal.unitCost === null ||
                  !cellVal.unitCost
                  ? "0"
                  : cellVal.unitCost
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
            cellVal.unitResaleValue =
              parseFloat(
                cellVal.unitResaleValue === "" ||
                  cellVal.unitResaleValue === null ||
                  !cellVal.unitResaleValue
                  ? "0"
                  : cellVal.unitResaleValue
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
            cellVal.cost =
              parseFloat(
                cellVal.cost === "" || cellVal.cost === null || !cellVal.cost
                  ? "0"
                  : cellVal.cost
              ) *
              (parseFloat(cellVal.exchangeRate) === 0
                ? 1
                : parseFloat(cellVal.exchangeRate));
          }
        });
      });
      _this.$refs.rateDialog.visible = false;
      this.addCellTransfer();
    },
    // 添加转化税率后的组件并重新计算
    addCellTransfer() {
      let _this = this;
      _this.addCellList.forEach(function (resVal, resIndex) {
        resVal.id = "";
        _this.formData.push(resVal);
      });
      // _this.materialCost_RM = 0
      this.formData.forEach(function (val, index) {
        _this.toComputeCost(val);
        // _this.materialCost_RM += parseFloat(val.cost === '' || !val.cost ? 0 : val.cost)
      });
      // _this.materialCost_RM = _this.materialCost_RM.toFixed(3)
    },
    // 删除组件
    deleteMoudle(index) {
      if (this.materialDelIds.indexOf(this.formData[index].id) > -1) {
        this.materialDelIds.splice(
          this.materialDelIds.indexOf(this.formData[index].id),
          1
        );
      } else {
        this.materialDelIds.push(this.formData[index].id);
      }
      this.formData.splice(index, 1);
      if (this.formData.length > 0) {
        this.toComputeCost(this.formData[0]);
      } else {
        this.materialCost_RM = 0;
        if (this.type != "template") {
          this.$parent.$refs.proTcm.toComputeAll();
          this.$parent.$refs.ohTcm.tocompute();
        }
      }
    },
    // 复制组件
    copyMoudle(index) {
      let data = JSON.parse(JSON.stringify(this.formData[index]));
      data.id = "";
      this.formData.push(data);
      if (this.formData.length > 0) {
        this.toComputeCost(this.formData[0]);
      }
      this.$nextTick(() => {
        let outDiv = document.getElementById("idname");
        const scrollLeft = this.formData.length * 221 + 201;
        outDiv.scrollTo(scrollLeft, 0);
      });
    },
    // 限制
    transferFormat(val, item, name) {
      item[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
      if (name === "converRate" && item[name] !== "") {
        if (this.type != "template" && this.currencyId === "") {
          commonUtil.showTipInfo(
            this.$t("templateLists.templateList.currencyRequired"),
            "",
            "warning"
          );
          return;
        }
        let exchangeList = item.exchangeRate.split(".");
        let converRateList = item[name].split(".");
        item.unitCost =
          (parseFloat(
            item.unitCost === "" || item.unitCost === null || !item.unitCost
              ? "0"
              : item.unitCost
          ) /
            parseFloat(
              item.exchangeRate === 0 ||
                item.exchangeRate === "0" ||
                (exchangeList.length == 2 && exchangeList[1] == "")
                ? 1
                : item.exchangeRate
            )) *
          (parseFloat(item[name]) === 0 ||
          (converRateList.length == 2 && converRateList[1] == "")
            ? 1
            : parseFloat(item[name]));
        item.unitResaleValue =
          (parseFloat(
            item.unitResaleValue === "" ||
              item.unitResaleValue === null ||
              !item.unitResaleValue
              ? "0"
              : item.unitResaleValue
          ) /
            parseFloat(
              item.exchangeRate === 0 ||
                item.exchangeRate === "0" ||
                (exchangeList.length == 2 && exchangeList[1] == "")
                ? 1
                : item.exchangeRate
            )) *
          (parseFloat(item[name]) === 0 ||
          (converRateList.length == 2 && converRateList[1] == "")
            ? 1
            : parseFloat(item[name]));
        item.cost =
          (parseFloat(
            item.cost === "" || item.cost === null || !item.cost
              ? "0"
              : item.cost
          ) /
            parseFloat(
              item.exchangeRate === 0 ||
                item.exchangeRate === "0" ||
                (exchangeList.length == 2 && exchangeList[1] == "")
                ? 1
                : item.exchangeRate
            )) *
          (parseFloat(item[name]) === 0 ||
          (converRateList.length == 2 && converRateList[1] == "")
            ? 1
            : parseFloat(item[name]));
        item.exchangeRate = item[name];
      }
      if (name === "grossMWeight") {
        item.calculateData = "";
      }
    },
    // 计算单个material cost
    toComputeCost(row) {
      let _this = this;
      if (
        row.netMWeight === "" ||
        row.grossMWeight === "" ||
        row.unitCost === "" ||
        row.unitResaleValue === ""
      ) {
        return;
      }
      row.cost = (
        parseFloat(
          row.grossMWeight === "" || !row.grossMWeight ? "0" : row.grossMWeight
        ) *
          parseFloat(row.unitCost) -
        (parseFloat(
          row.grossMWeight === "" || !row.grossMWeight ? "0" : row.grossMWeight
        ) -
          parseFloat(
            row.netMWeight === "" || !row.netMWeight ? "0" : row.netMWeight
          )) *
          parseFloat(row.unitResaleValue)
      ).toFixed(12);

      _this.materialCost_RM = 0;
      _this.NmCost = 0;
      this.formData.forEach(function (val, index) {
        _this.materialCost_RM += parseFloat(
          val.cost == "" || !val.cost ? "0" : val.cost
        );
        _this.NmCost +=
          parseFloat(val.netMWeight) *
          parseFloat(val.unitResaleValue == "" ? 0 : val.unitResaleValue);
      });
      _this.materialCost_RM = _this.materialCost_RM.toFixed(12);
      _this.NmCost = _this.NmCost.toFixed(12);

      if (_this.type != "template") {
        _this.$parent.$refs.proTcm.toComputeAll();
        _this.$parent.$refs.ohTcm.tocompute();
      }
    },
    // 修改分类
    changeCommodityId(val, index) {
      this.formData[index].commodityId = val;
    },
    // 切换顺序
    reorder(arrow, index) {
      if (index == 0 && arrow == "left") {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.firstPlace"),
          "",
          "warning"
        );
        return;
      }
      if (index == this.formData.length - 1 && arrow == "right") {
        commonUtil.showTipInfo(
          this.$t("templateLists.templateList.endPlace"),
          "",
          "warning"
        );
        return;
      }
      if (arrow == "left") {
        [this.formData[index - 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index - 1],
        ];
        this.$forceUpdate();
      } else if (arrow == "right") {
        [this.formData[index + 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index + 1],
        ];
        this.$forceUpdate();
      }
    },
    // 预览上传的图片
    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.rawPredialogVisible = true;
    },
    // 删除图片
    handlePictureCardRemove(file, fileList) {
      this.materialfileList = fileList;
    },
    pictureCardChange(file, fileList) {
      this.materialfileList.push(file);
    },
    // 计算汇率
    CalcRate(val) {
      getMultiRate(
        {
          genList: [
            {
              id: "",
              currId: this.AddForm.currencyId,
              currCNYId: val,
            },
          ],
        },
        (response) => {
          if (response && response.code == 200) {
            this.AddForm.converRate =
              response.data[0].exchangeList[0].exchangeRate;
            this.transferFormat(
              this.AddForm.converRate,
              this.AddForm,
              "converRate"
            );
            this.toComputeCost(this.AddForm);
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.handle-btn {
  color: #fff;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #2f4cdd;
  border-radius: 10px;
  cursor: pointer;
}
.Raw-box {
  display: flex;
  flex-wrap: wrap;
}
.Raw-card {
  margin-right: 15px;
  margin-top: 15px;
  padding: 10px 35px 0px 20px;
  width: 30%;
  border-radius: 10px;
  color: #363b64;
  background: #f2f3f5;
  .Raw-top {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    .Raw-title {
      display: flex;
      align-items: center;
    }
    .el-icon {
      font-size: 18px;
      font-weight: 600;
      color: #a098ae;
    }
  }
  .Raw-edit-btn {
    margin-bottom: -20px;
    text-align: right;
    font-size: 20px;
  }
  .Raw-card-main {
    padding: 10px 20px 10px;
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8) !important;
    p {
      padding: 10px 0 10px 4px;
      font-weight: 600;
      color: #363b64;
    }
    input {
      width: 80%;
      background: #f6f6f6;
      border: 1px solid #718ebf;
      border-radius: 10px;
    }
  }
}
.Raw-card-item {
  display: flex;
  margin-bottom: 10px;
  p {
    width: 220px;
    font-size: 14px;
  }
  .el-icon {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #a098ae;
  }
  img {
    display: inline-block;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
.Total-MaterialCost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: #363b64;
  font-weight: 600;
  .MaterialCost {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input {
    width: 200px;
    background: #f6f6f6;
    border: 1px solid #718ebf;
    border-radius: 10px;
  }
}
.submit-btn {
  margin-left: 5px;
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  background: #b2bde7;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}

.tcm-form-solid {
  position: relative;
  height: 600px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #c0c4cc;
}
.add-cla-template {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.add-table {
  max-width: 100%;
  border-collapse: collapse;
  // background: #f5f7fa;
}
.add-table td {
  // border: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  padding: 0px 5px;
  text-align: center;
}
.btn-text {
  color: var(--themeColor);
  cursor: pointer;
  font-size: 12px;
}
:deep .el-input__wrapper {
  background: transparent;
}

.table-box {
  overflow-x: scroll;
}
table {
  border: 1px solid #ccc;
  border-right: none;
  flex-shrink: 0;
  flex-shrink: 0;
}
table tr {
  display: flex;
  text-align: center;
}
table td {
  display: flex;
  align-items: center;
  width: 210px;
  height: 30px;
  word-wrap: break-word;
  word-break: break-all;
}

table td:nth-child(1) {
  width: 190px;
  font-size: 13px !important;
  text-align: center;
}

.add-column {
  flex-shrink: 0;
  width: 100px;
  height: 75px;
  line-height: 37px;
  border: 1px solid #dcdfe6;
  border-left: none;
  text-align: center;
  cursor: pointer;
}

table tr > td:first-child,
table tr > td:first-child {
  position: sticky;
  left: 0;
  z-index: 88;
  background-color: #f5f7fa;
  font-size: 15px;
  text-align: left;
}
.Serial_number {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 17px;
  font-weight: 600;
  color: #445581;
}
.el-input,
/deep/ .el-select .el-input__inner {
  height: 25px !important;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>