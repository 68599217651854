<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.ModulesManagement") }}</div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
        <!-- <div
          class="search-btn"
          @click="add"
          v-if="this.btnAdd > 0"
          style="margin-left: 10px"
        >
          {{ $t("unifiedPrompts.unifiedPrompt.new") }}
        </div>
        <div
          class="search-btn"
          @click="addMenu"
          v-if="this.btnAdd > 0"
          style="margin-left: 10px"
        >
          {{ $t("ccls.ccl.newModules") }}
        </div>
        <div class="search-btn" @click="editMenu" style="margin-left: 10px">
          {{ $t("ccls.ccl.editMenu") }}
        </div> -->
      </div>
    </div>
    <div class="handle-btn flex">
      <div class="handle-btn-item" @click="add" v-if="this.btnAdd > 0">
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div
        class="handle-btn-item"
        @click="addMenu"
        v-if="this.btnAdd > 0"
        style="margin-left: 10px"
      >
        {{ $t("ccls.ccl.newModules") }}
      </div>
      <div class="handle-btn-item" @click="editMenu" style="margin-left: 10px">
        {{ $t("ccls.ccl.editMenu") }}
      </div>
    </div>
    <el-row :gutter="10">
      <el-col :span="4" style="width: 250px; background-color: #fff">
        <div class="tree-cla">
          <div class="tree-cla-search">
            <input
              v-model="filterText"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </div>
          <div :style="{ height: TableHeight + 'px' }" class="tree-box">
            <el-tree
              :empty-text="$t('tcmlists.tcmlist.noData')"
              ref="treeRef"
              class="filter-tree"
              :data="treeData"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              @node-click="handleNodeClick"
            />
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="regional_table">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="user_List"
            border
            stripe
            style="width: 100%"
            :height="TableHeight"
            @row-click="rowClick"
          >
            <el-table-column
              prop="name"
              :label="$t('userlists.userlist.name')"
              align="center"
            />
            <el-table-column
              prop="parentName"
              :label="$t('userlists.userlist.parentNodeName')"
              align="center"
            />
            <el-table-column
              prop="url"
              :label="$t('userlists.userlist.path')"
              align="center"
            />
            <el-table-column
              prop="status"
              :label="$t('userlists.userlist.isEnable')"
              align="center"
              width="100"
            >
              <template #default="scope">
                <el-icon
                  :size="16"
                  color="#40D4A8"
                  v-show="scope.row.status == '0'"
                >
                  <SuccessFilled />
                </el-icon>
                <el-icon
                  :size="16"
                  color="#F56C6C"
                  v-show="scope.row.status == '1'"
                >
                  <CircleCloseFilled />
                </el-icon>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('templateLists.templateList.operate')"
              width="80"
              fixed="right"
              class-name="lastTd"
              align="center"
            >
              <template #default="scope">
                <el-popover placement="bottom" trigger="hover">
                  <template #reference>
                    <div style="text-align: center">
                      <el-icon><MoreFilled /></el-icon>
                    </div>
                  </template>
                  <div class="action-box">
                    <div
                      class="action-btn"
                      @click="Edit(scope.row)"
                      v-if="this.btnEdit > 0"
                    >
                      <el-icon><Edit /></el-icon>
                      <span>
                        {{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span
                      >
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div>
            {{ $t("homePage.home.Showing") }} {{ pageSize }}
            {{ $t("homePage.home.From") }} {{ total }}
            {{ $t("homePage.home.Data") }}
            <el-popover placement="top" :width="70" trigger="click">
              <template #reference>
                <el-icon
                  style="
                    margin-left: 5px;
                    font-size: 17px;
                    vertical-align: middle;
                  "
                  ><Operation
                /></el-icon>
              </template>
              <el-input
                v-model="searchForm.limit"
                @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
                :min="1"
                style="width: 130px"
                @change="handleSizeChange"
              >
                <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
              </el-input>
            </el-popover>
          </div>
          <el-pagination
            layout="prev, pager, next"
            background
            :total="total"
            :page-size="pageSize"
            :current-page="searchForm.page"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-col>
      <el-col :span="4" style="width: 250px">
        <div class="regional_table">
          <el-table
            border
            stripe
            :empty-text="$t('tcmlists.tcmlist.noData')"
            ref="buttonRef"
            :height="TableHeight"
            :data="buttonList"
            style="width: 100%"
            @select="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column
              property="name"
              :label="$t('userlists.userlist.existingMenu')"
            />
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 模块弹窗 -->
    <Addmodule
      ref="addDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="addSubmit"
    ></Addmodule>
    <Editmodule
      ref="editDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="editSubmit"
    ></Editmodule>
    <!-- 菜单弹窗 -->
    <AddMenu
      ref="addMenuDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="addMenuSubmit"
    ></AddMenu>
    <EditMenu
      ref="editMenuDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="editMenuSubmit"
    ></EditMenu>
  </div>

  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <el-row>
        <el-col :span="14">
          <table>
            <tr>
              <td>
                <el-input
                  v-model="searchForm.Keyword"
                  :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                />
              </td>
              <td>
                <div
                  class="operate-btn"
                  style="margin-right: 10px"
                  @click="search"
                >
                  {{ $t("userlists.userlist.search") }}
                </div>
                <!== <div class="operate-btn">Advanced search</div> ==>
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="10">
          <div class="align-right">
            <div
              class="operate-btn"
              style="margin-top: 3px"
              @click="add"
              v-if="this.btnAdd > 0"
            >
              {{ $t("ccls.ccl.newModule") }}
            </div>
            <div
              class="operate-btn"
              style="margin-top: 3px"
              @click="addMenu"
              v-if="this.btnAdd > 0"
            >
              {{ $t("ccls.ccl.newModules") }}
            </div>
            <div class="operate-btn" style="margin-top: 3px" @click="editMenu">
              {{ $t("ccls.ccl.editMenu") }}
            </div>
            <!== <div class="operate-btn" style="margin-top: 3px" @click="delMenu" v-if="this.btnDel>0">
							{{$t('ccls.ccl.newModuless')}}
						</div> ==>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-row :gutter="10">
        <el-col
          :span="4"
          style="width: 250px; min-width: 250px; max-width: 250px"
        >
          <div class="tree-cla">
            <el-input
              v-model="filterText"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
              style="margin-bottom: 10px"
            />
            <el-tree
              :empty-text="$t('tcmlists.tcmlist.noData')"
              ref="treeRef"
              class="filter-tree"
              :data="treeData"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              @node-click="handleNodeClick"
            />
          </div>
        </el-col>
        <el-col :span="16" style="max-width: calc(100% - 500px)">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="user_List"
            border
            @row-click="rowClick"
            style="width: 100%"
          >
            <el-table-column
              :label="$t('ccls.ccl.operate')"
              align="center"
              width="250"
            >
              <template #default="scope">
                <div class="flex-around">
                  <div
                    class="operate-btnx"
                    @click="Edit(scope.row)"
                    v-if="this.btnEdit > 0"
                  >
                    {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
                  </div>
                  <!== <div class="operate-btnx" @click="del(scope.row.id)" v-if="this.btnDel>0">
                        				{{$t('unifiedPrompts.unifiedPrompt.delete')}}
                        			</div> ==>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('userlists.userlist.name')"
              align="center"
            />
            <el-table-column
              prop="parentName"
              :label="$t('userlists.userlist.parentNodeName')"
              align="center"
            />
            <el-table-column
              prop="url"
              :label="$t('userlists.userlist.path')"
              align="center"
            />
            <el-table-column
              prop="status"
              :label="$t('userlists.userlist.isEnable')"
              align="center"
              width="100"
            >
              <template #default="scope">
                <el-icon
                  :size="16"
                  color="#40D4A8"
                  v-show="scope.row.status == '0'"
                >
                  <SuccessFilled />
                </el-icon>
                <el-icon
                  :size="16"
                  color="#F56C6C"
                  v-show="scope.row.status == '1'"
                >
                  <CircleCloseFilled />
                </el-icon>
              </template>
            </el-table-column>
          </el-table>
          <div class="page-cla">
            <div>
              <el-input
                v-model="searchForm.limit"
                @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
                min="1"
                style="width: 130px"
                @change="handleSizeChange"
              >
                <template #suffix>
                  {{ $t("ccls.ccl.barPage") }}
                </template>
              </el-input>
            </div>
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              :current-page="searchForm.page"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-col>
        <el-col
          :span="4"
          style="width: 250px; min-width: 250px; max-width: 250px"
        >
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            ref="buttonRef"
            :data="buttonList"
            style="width: 100%"
            @select="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column
              property="name"
              :label="$t('userlists.userlist.existingMenu')"
            />
          </el-table>
        </el-col>
      </el-row>
    </div>
    <!== 模块弹窗 ==>
    <Addmodule
      ref="addDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="addSubmit"
    ></Addmodule>
    <Editmodule
      ref="editDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="editSubmit"
    ></Editmodule>
    <!== 菜单弹窗 ==>
    <AddMenu
      ref="addMenuDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="addMenuSubmit"
    ></AddMenu>
    <EditMenu
      ref="editMenuDialog"
      @showMoudleDia="showMoudleDia"
      v-on:handleSubmit="editMenuSubmit"
    ></EditMenu>
    <!== <MoudleDia ref="moudleDialog"></MoudleDia> ==>
  </div> -->
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
import Addmodule from "./addmodule.vue";
import Editmodule from "./editmodule.vue";
import AddMenu from "./addMenu.vue";
import EditMenu from "./editMenu.vue";
import {
  moduleTree,
  moduleList,
  addmodule,
  editmodule,
  delsmodule,
  butttonList,
  addButton,
  editButton,
  delButton,
} from "@/assets/js/requestApi.js";
import { createLogger } from "vuex";
export default {
  name: "modulelist",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  components: {
    Addmodule,
    Editmodule,
    AddMenu,
    EditMenu,
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        ModuleId: "",
        id: "",
        name: "",
        nameEn: "",
        parentId: "",
        parentName: "",
        url: "",
        Keyword: "",
        IsPhaseII: 1,
        page: 1, //当前页
        limit: 10, //每页个数
      },
      user_List: [],
      total: 0,
      pageSize: 10,
      filterText: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      buttonList: [],
      button: {
        id: "",
      },
      menuList: [],
      power: [],
      powers: "",
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
    };
  },
  mounted() {
    this.autoHeight();
    this.initList();
    this.getTreeData();
    this.search();
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.$refs.addDialog.getUnits();
      this.$refs.addMenuDialog.getUnits();
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (
          e.name == "添加" ||
          e.domId == "AddModule" ||
          e.domId == "AddMoudle"
        ) {
          this.btnAdd = 1;
        }
        if (
          e.name == "删除" ||
          e.domId == "DeleteModule" ||
          e.domId == "DeleteMoudle"
        ) {
          this.btnDel = 1;
        }
        if (
          e.name == "编辑" ||
          e.domId == "EditModule" ||
          e.domId == "EditMoudle"
        ) {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    // 渲染列表
    initList() {
      this.searchForm = {
        id: "",
        name: "",
        nameEn: "",
        parentId: "",
        parentName: "",
        url: "",
        Keyword: "",
        IsPhaseII: sessionStorage.getItem("phaseII") == 2 ? 1 : 0,
        page: 1, //当前页
        limit: 10, //每页个数
      };
      this.search();
    },
    // 查询
    search() {
      moduleList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.user_List = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 删除用户
    del(id) {
      delsmodule((id = [id]), (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
          this.getTreeData();
        }
      });
    },

    add() {
      this.$refs.addDialog.visible = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    addSubmit(form) {
      addmodule(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
          this.search();
          this.getTreeData();
        }
      });
    },
    // 编辑
    Edit(row) {
      this.$refs.editDialog.form.id = row.id;
      this.$refs.editDialog.form.name = row.name;
      this.$refs.editDialog.form.nameEn = row.nameEn;
      this.$refs.editDialog.form.nameSpain = row.nameSpain ? "" : "";
      this.$refs.editDialog.form.namePoland = row.namePoland ? "" : "";
      this.$refs.editDialog.form.parentId = row.parentId;
      this.$refs.editDialog.form.parentName = row.parentName;
      this.$refs.editDialog.form.url = row.url;
      this.$refs.editDialog.form.status = row.status;
      this.$refs.editDialog.form.Code = row.code;
      this.$refs.editDialog.form.sortNo = row.sortNo;
      this.$refs.editDialog.form.isPhaseII = row.isPhaseII ? 0 : 0;
      this.$refs.editDialog.visible = true;
    },
    editSubmit(form) {
      editmodule(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.editSuccess"),
            "",
            "success"
          );
          this.search();
          this.getTreeData();
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 获取组织树数据
    getTreeData() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "zh") {
        moduleTree(
          { IsPhaseII: sessionStorage.getItem("phaseII") == 2 ? 1 : 0 },
          (response) => {
            if (response && response.code == 200) {
              this.treeData = this.transferList(response.result);
            }
          }
        );
      } else {
        moduleTree(
          { IsPhaseII: sessionStorage.getItem("phaseII") == 2 ? 1 : 0 },
          (response) => {
            if (response && response.code == 200) {
              response.result.forEach((e) => {
                e.name = e.nameEn;
              });
              this.treeData = this.transferList(response.result);
            }
          }
        );
      }
    },
    // 树点击事件
    handleNodeClick(data) {
      this.searchForm.ModuleId = data.id;
      this.searchForm.name = data.name;
      this.search();
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.id === item.parentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
    // 显示选择部门弹窗
    showMoudleDia(val) {
      if (val) {
        this.$refs.addDialog.visible = true;
        this.$refs.editDialog.visible = true;
      }
    },
    // 查询菜单列表
    getButton() {
      if (this.searchForm.id != "") {
        butttonList({ moduleId: this.searchForm.id }, (response) => {
          if (response && response.code == 200 && this.powers == "zh") {
            this.buttonList = response.result;
          } else {
            response.result.forEach((e) => {
              if (e.name == "删除" || e.name == "Delete") {
                e.name = "Delete";
              }
              if (e.name == "添加" || e.name == "Add") {
                e.name = "Add";
              }
              if (e.name == "编辑" || e.name == "Edit") {
                e.name = "Edit";
              }
              if (e.name == "预览" || e.name == "Preview") {
                e.name = "Preview";
              }
            });
            this.buttonList = response.result;
          }
        });
      }
    },
    // 点击行 显示已有菜单
    rowClick(row, column, event) {
      this.searchForm.id = row.id;
      this.getButton();
    },
    // 新增菜单
    addMenu() {
      if (this.searchForm.id == "") {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addMenuDialog.visible = true;
    },
    addMenuSubmit(form) {
      addButton(
        {
          name: form.name,
          class: form.class,
          domId: form.domId,
          icon: form.icon,
          sort: form.sort,
          moduleId: this.searchForm.id,
        },
        (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
              "",
              "success"
            );
            this.getButton();
          }
        }
      );
    },
    // 点击菜单
    handleSelectionChange(selection, row) {
      this.button = row;
      this.button.id = row.id;
      for (let i = 0; i < selection.length; i++) {
        this.menuList.push(selection[i].id);
      }
    },
    // 编辑菜单
    editMenu() {
      if (this.button.id == "") {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.editMenuDialog.form.id = this.button.id;
      this.$refs.editMenuDialog.form.name = this.button.name;
      this.$refs.editMenuDialog.form.sort = this.button.sort;
      this.$refs.editMenuDialog.form.domId = this.button.domId;
      this.$refs.editMenuDialog.visible = true;
    },
    editMenuSubmit(form) {
      editButton(
        {
          name: form.name,
          class: form.class,
          domId: form.domId,
          icon: form.icon,
          sort: form.sort,
          id: form.id,
          moduleId: this.searchForm.id,
        },
        (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.editSuccess"),
              "",
              "success"
            );
            this.getButton();
          }
        }
      );
    },
    // 删除菜单
    delMenu() {
      // this.menuList
      delButton(this.menuList, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.getButton();
        }
      });
    },
  },
};
</script>


<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.tree-box {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
