<template>
  <div class="tcm-content">
    <div class="tcm-content-form">
      <el-form :model="form" label-width="400px">
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.rawMaterialCost')"
            >
              <el-input
                v-model="form.materialCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.materialCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.materialCost_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.materialCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.purchasedCost')"
            >
              <el-input
                v-model="form.purchaseCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.purchaseCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.purchaseCost_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.purchaseCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.directLaborCost')"
            >
              <el-input
                v-model="form.laborCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.laborCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.laborCost_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.laborCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.burdenCost')"
            >
              <el-input
                v-model="form.burdenCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.burdenCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.burdenCost_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.burdenCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.setUpCost')"
            >
              <el-input
                v-model="form.setUpCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.setUpCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.setUpCost_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.setUpCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="
                $t('changePriceLists.changePriceList.packagingFreightCost')
              "
            >
              <el-input
                v-model="form.packageCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.packageCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.packageCost_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.packageCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.amortizationFees')"
            >
              <el-input
                v-model="form.otherCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.otherCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.otherCost_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.otherCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.addedCost')"
            >
              <el-input
                v-model="form.amortizeCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.amortizeCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.amortizeCost_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.amortizeCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.processScrapCost')"
            >
              <el-input
                v-model="form.scrapCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.scrapCost_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.scrapCost_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.scrapCost_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.sgaAndProfit')"
            >
              <el-input
                v-model="form.sgaProfit_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  form.sgaProfit_SUM !== "" &&
                  form.totalPriceCurr_SUM !== "" &&
                  form.sgaProfit_SUM !== null &&
                  form.totalPriceCurr_SUM
                    ? (
                        (form.sgaProfit_SUM / form.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row>
					<el-col :span="12">
						<el-form-item label="Total Net Wt">
							<el-input v-model="form.Currency" style="width: 214px;" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="KBS Supplier Cost/Weight">
							<el-input v-model="form.Currency" style="width: 214px;" />
						</el-form-item>
					</el-col>
				</el-row> -->
      </el-form>
      <el-form :model="form" label-width="400px">
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalSellPrice')"
            >
              <el-input
                v-model="totalPriceCurr_SUM"
                disabled
                style="width: 214px"
              >
                <!-- <template #prefix>{{exchangeRateUnit}}</template> -->
                <template #prefix
                  >{{ exchangeRateUnit }}
                  {{
                    parseFloat(
                      form.totalPriceCurr_SUM === "" ||
                        form.totalPriceCurr_SUM === null
                        ? 0
                        : form.totalPriceCurr_SUM
                    ).toFixed(3)
                  }}</template
                >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="
                $t('changePriceLists.changePriceList.totalSellPriceBefore')
              "
            >
              <el-input
                v-model="totalPriceCurr_SUM_Before"
                disabled
                style="width: 214px"
              >
                <template #prefix
                  >{{ exchangeRateUnit }}
                  {{
                    parseFloat(
                      form.totalPriceCurr_SUM_Before === "" ||
                        form.totalPriceCurr_SUM_Before === null
                        ? 0
                        : form.totalPriceCurr_SUM_Before
                    ).toFixed(3)
                  }}</template
                >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalSellCurrency')"
            >
              <el-input
                v-model="totalPriceReport_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix
                  >{{ reportExchangeRateUnit }}
                  {{
                    parseFloat(
                      form.totalPriceReport_SUM === "" ||
                        form.totalPriceReport_SUM === null
                        ? 0
                        : form.totalPriceReport_SUM
                    ).toFixed(3)
                  }}</template
                >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="
                $t('changePriceLists.changePriceList.totalSellCurrencyBefore')
              "
            >
              <el-input
                v-model="totalPriceReport_SUM_Before"
                disabled
                style="width: 214px"
              >
                <template #prefix
                  >{{ reportExchangeRateUnit }}
                  {{
                    parseFloat(
                      form.totalPriceReport_SUM_Before === "" ||
                        form.totalPriceReport_SUM_Before === null
                        ? 0
                        : form.totalPriceReport_SUM_Before
                    ).toFixed(3)
                  }}</template
                >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
					<el-col :span="24">
						<el-form-item :label="$t('changePriceLists.changePriceList.attachments')">
							<el-row>
								<div class="file-cla" v-show="fileListx.length > 0">
									<div v-for="(item, index) in fileListx" style="margin-right: 10px;">
										<div>
											<img src="../../assets/img/tcm/doc.png" style="width: 30px;height: 30px;"/>
										</div>
										<div style="text-align: center;">
											<img src="../../assets/img/tcm/cuowu.png" style="width: 12px;height: 12px;margin-right: 4px;cursor: pointer;" @click="toDetele(index, 'file')"/>
											<img src="../../assets/img/tcm/yunxiazai.png" style="width: 12px;height: 12px;cursor: pointer;"/>
										</div>
									</div>
								</div>
								<el-col :span="2" v-show="operateType != 'detail'">
									<el-upload
									  class="custom-el-upload-list-none"
									  style="display: inline-block;margin-top: 10px;"
									  action="/"
									  :auto-upload="false"
									  multiple
									  :limit="10"
									  :file-list="fileListx"
									  :on-change="filechange">
									  <el-button size="small">上传文件</el-button>
									</el-upload>
								</el-col>
							</el-row>
						</el-form-item>
						
					</el-col>
				</el-row> -->
      </el-form>
    </div>
    <div class="btn-cla">
      <el-button
        style="
          background-color: var(--themeColor);
          color: var(--menuTextActive);
        "
        @click="cancle"
        >{{ $t("changePriceLists.changePriceList.cancle") }}</el-button
      >
      <!-- <el-button color="#335883" v-show="operateType != 'detail'" @click="saveTemplate">SAVE</el-button> -->
      <el-button
        style="
          background-color: var(--themeColor);
          color: var(--menuTextActive);
        "
        v-show="operateType != 'detail'"
        @click="saveDraft"
        >{{ $t("changePriceLists.changePriceList.saveDraft") }}</el-button
      >
      <el-button
        style="
          background-color: var(--themeColor);
          color: var(--menuTextActive);
        "
        @click="preview"
        >{{ $t("changePriceLists.changePriceList.submit") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { GetRate } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: [
    "operateType",
    "exchangeRateUnit",
    "ReportCurrencyOptions",
    "reportExchangeRateUnit",
  ],
  emits: ["cancle", "saveTemplate", "previewTemplate", "saveDraft"],
  data() {
    return {
      totalPriceCurr_SUM: "",
      totalPriceReport_SUM: "",
      form: {
        materialCost_SUM: "",
        setUpCost_SUM: "",
        purchaseCost_SUM: "",
        packageCost_SUM: "",
        laborCost_SUM: "",
        otherCost_SUM: "",
        burdenCost_SUM: "",
        amortizeCost_SUM: "",
        sgaProfit_SUM: "",
        scrapCost_SUM: "",
        totalPriceCurr_SUM: "",
        totalPriceReport_SUM: "",
        totalPriceCurr_SUM_Before: "",
        totalPriceReport_SUM_Before: "",
      },
      // currency选项
      // CurrencyOptions: [{
      // 	value: 'Option1',
      // 	label: 'Option1'
      // }, {
      // 	value: 'Option2',
      // 	label: 'Option2'
      // }],
      // ReportCurrency选项
      // ReportCurrencyOptions: [{
      // 	id: 'Option1',
      // 	name: 'Option1'
      // }, {
      // 	id: 'Option2',
      // 	name: 'Option2'
      // }],
      fileListx: [],
      // 选择的货币
      reportCurrency: "",
    };
  },
  methods: {
    // 文件上传
    filechange(file, fileList) {
      this.fileListx.push(file);
    },
    // 删除图片
    toDetele(index) {
      this.fileListx.splice(index, 1);
    },
    // 取消
    cancle() {
      this.$emit("cancle");
    },
    // 保存为模板
    saveTemplate() {
      this.$emit("saveTemplate", "template");
    },
    // 保存为草稿
    saveDraft() {
      this.$emit("saveDraft", "draft");
    },
    // 预览
    preview() {
      this.$emit("previewTemplate");
    },
    // 展示柱状图
    reportForm() {
      if (this.reportCurrency == "") {
        commonUtil.showTipInfo("请选择需要转换的货币！", "", "warning");
        return;
      }
      let currency = this.$parent.$refs.gen.genList[0].Currency;
      GetRate(
        {
          currency: currency,
          reportCurrency: this.reportCurrency,
        },
        (response) => {
          if (response && response.code == 200) {
            if (response.result == null) {
              commonUtil.showTipInfo(
                "当前数据无汇率，请重新选择！",
                "",
                "warning"
              );
            } else {
              let rate = response.result.exchangeRate;
              let reportData = {
                materialCost_SUM: (
                  parseFloat(this.form.materialCost_SUM) * rate
                ).toFixed(3),
                setUpCost_SUM: (
                  parseFloat(this.form.setUpCost_SUM) * rate
                ).toFixed(3),
                purchaseCost_SUM: (
                  parseFloat(this.form.purchaseCost_SUM) * rate
                ).toFixed(3),
                packageCost_SUM: (
                  parseFloat(this.form.packageCost_SUM) * rate
                ).toFixed(3),
                laborCost_SUM: (
                  parseFloat(this.form.laborCost_SUM) * rate
                ).toFixed(3),
                burdenCost_SUM: (
                  parseFloat(this.form.burdenCost_SUM) * rate
                ).toFixed(3),
                amortizeCost_SUM: (
                  parseFloat(this.form.amortizeCost_SUM) * rate
                ).toFixed(3),
                sgaProfit_SUM: (
                  parseFloat(this.form.sgaProfit_SUM) * rate
                ).toFixed(3),
                scrapCost_SUM: (
                  parseFloat(this.form.scrapCost_SUM) * rate
                ).toFixed(3),
                totalPriceCurr_SUM: (
                  parseFloat(this.form.totalPriceCurr_SUM) * rate
                ).toFixed(3),
                totalPriceReport_SUM: (
                  parseFloat(this.form.totalPriceReport_SUM) * rate
                ).toFixed(3),
              };
              this.$refs.sumDialog.drawChart(reportData);
            }
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.tcm-content {
  padding: 20px 90px 20px 20px;
  background: rgba(255, 255, 255, 0.5);
}
.tcm-content-title {
  /* background-color: #f2f2f2; */
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
/* .tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
} */
.tcm-content-form {
  width: calc(100% - 50px);
  margin-left: 50px;
  /* background-color: #f2f2f2; */
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.btn-cla {
  width: 50%;
  margin: 20px auto;
  justify-content: space-around;
  display: flex;
}
.file-cla {
  padding: 0px 20px 0px 0;
  display: flex;
}
.report-cur {
  display: flex;
  line-height: 32px;
  position: absolute;
  right: 20px;
  top: 15px;
  font-weight: normal;
}
.mgr-10 {
  margin-right: 10px;
}
</style>