<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">{{ $t("userlists.userlist.user") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form :model="form" :rules="rules" ref="form" label-width="120px">
        <el-form-item
          :label="$t('userlists.userlist.jobNumber')"
          prop="account"
        >
          <el-input v-model="form.account" />
        </el-form-item>
        <el-form-item :label="$t('userlists.userlist.name')" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item :label="$t('userlists.userlist.phone')">
          <el-input v-model="form.phone" />
        </el-form-item>
        <el-form-item :label="$t('userlists.userlist.mailbox')" prop="email">
          <el-input v-model="form.email" />
        </el-form-item>
        <el-form-item :label="$t('userlists.userlist.password')">
          <el-input
            v-model="form.password"
            type="password"
            :placeholder="$t('userlists.userlist.thePassword')"
            disabled
          />
        </el-form-item>
        <el-form-item
          :label="$t('userlists.userlist.department')"
          prop="organizationIds"
        >
          <el-input @click="showMoudleDia" v-model="form.organizations" />
        </el-form-item>
        <el-form-item
          :label="$t('userlists.userlist.commodityType')"
          prop="commodityIds"
        >
          <el-input @click="showMoudleDiao" v-model="form.commodityName" />
        </el-form-item>
        <!-- <el-form-item :label="$t('userlists.userlist.sex')" prop="sex">
                    <el-select clearable  v-model="form.sex" :placeholder="
                            $t('tcmdatalists.tcmdatalist.pleaseSlect')
                        ">
                        <el-option :label="$t('userlists.userlist.man')" value="1"></el-option>
                        <el-option :label="$t('userlists.userlist.woman')" value="0"></el-option>
                    </el-select>
                </el-form-item> -->
        <el-form-item :label="$t('ccls.ccl.disable')">
          <el-switch
            style="width: 100%"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{
          $t("userlists.userlist.cancel")
        }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="addSubmit"
          >{{ $t("userlists.userlist.confirm") }}</el-button
        >
      </span>
    </template>
    <TreeDepartment
      ref="TreeDepartmentDialog"
      @handleSubmit="DepartmentTree"
    ></TreeDepartment>
    <TreeCommodity
      ref="TreeCommodityDialog"
      @handleSubmit="CommodityTree"
    ></TreeCommodity>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
import TreeDepartment from "./treeDepartment.vue";
import TreeCommodity from "./commodity.vue";
export default {
  components: {
    TreeDepartment,
    TreeCommodity,
    commonUtil,
  },
  emits: ["handleSubmit"],
  data() {
    return {
      visible: false,
      form: {
        account: "",
        name: "",
        phone: "",
        email: "",
        sex: 1,
        password: "",
        organizations: [],
        organizationIds: [],
        commodityName: [],
        commodityIds: [],
      },
      timer: "",
      rules: {},
    };
  },
  created() {
    this.getUnits();
  },
  methods: {
    getUnits() {
      this.timer = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      let reg = /^1[356789][0-9]\d{8}$/;
      var phone1 = (rule, value, callback) => {
        // if (value === '') {
        //     callback(
        //         new Error(
        //             this.$t('userlists.userlist.pleasePhoneNumber')
        //         )
        //     )
        // } else
        if (!reg.test(this.form.phone)) {
          callback(new Error(this.$t("userlists.userlist.pleasePhoneNumbers")));
        } else {
          callback();
        }
      };
      let res =
        /^[a-zA-Z0-9]+([-_.][A-Za-zd0-9]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      var email1 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error(this.$t("userlists.userlist.pleaseEmailaddress")));
        } else if (!res.test(this.form.email)) {
          callback(
            new Error(this.$t("userlists.userlist.pleaseEmailaddresss"))
          );
        } else {
          callback();
        }
      };
      if (this.powers == "en" || this.powers == null) {
        this.rules = {
          account: [
            {
              required: true,
              message: "Please enter account number",
              trigger: "blur",
            },
          ],
          name: [
            {
              required: true,
              message: "Please enter First name&Last name",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              validator: phone1,
              trigger: "blur",
            },
          ],
          email: [
            {
              required: true,
              validator: email1,
              trigger: "blur",
            },
          ],
          // sex: [
          //     {
          //         required: true,
          //         message: 'Please select gender',
          //         trigger: 'blur',
          //     },
          // ],
          organizationIds: [
            {
              required: true,
              message: "Please select Department",
              trigger: "change",
            },
          ],
          commodityIds: [
            {
              required: true,
              message: "Please select a product category",
              trigger: "change",
            },
          ],
        };
      } else {
        this.rules = {
          account: [
            {
              required: true,
              message: "请输入账号",
              trigger: "blur",
            },
          ],
          name: [
            {
              required: true,
              message: "请输入姓名",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              validator: phone1,
              trigger: "blur",
            },
          ],
          email: [
            {
              required: true,
              validator: email1,
              trigger: "blur",
            },
          ],
          // sex: [
          //     {
          //         required: true,
          //         message: '请选择性别',
          //         trigger: 'blur',
          //     },
          // ],
          organizationIds: [
            {
              required: true,
              message: "请选择部门",
              trigger: "change",
            },
          ],
          commodityIds: [
            {
              required: true,
              message: "请选择商品类别",
              trigger: "change",
            },
          ],
        };
      }
      clearInterval(this.timer);
    },
    close() {
      this.$refs.form.resetFields();
      this.form.organizations = [];
      this.form.organizationIds = [];
      this.form.commodityIds = [];
      this.form.commodityName = [];
      this.visible = false;
    },
    // 显示选择部门弹窗
    showMoudleDia() {
      this.$refs.TreeDepartmentDialog.getTree(this.form.organizationIds);
      this.$refs.TreeDepartmentDialog.visible = true;
    },
    // 显示选择商品弹窗
    showMoudleDiao() {
      // this.$refs.TreeCommodityDialog.getTree(this.form.organizationIds);
      this.$refs.TreeCommodityDialog.getTreeData();
      this.$refs.TreeCommodityDialog.visible = true;
    },
    addSubmit() {
      let _this = this;
      this.$refs.form.validate(function (e) {
        if (e) {
          _this.$emit("handleSubmit", _this.form);
          _this.visible = false;
          _this.$refs.form.resetFields();
        } else {
          if (_this.form.account == "") {
            commonUtil.showTipInfo(
              _this.$t("userlists.userlist.pleaseNumber"),
              "",
              "warning"
            );
            return false;
          } else if (_this.form.name == "") {
            commonUtil.showTipInfo(
              _this.$t("userlists.userlist.pleaseFirstName"),
              "",
              "warning"
            );
            return false;
          }
          // else if (_this.form.phone == '') {
          //                    commonUtil.showTipInfo(
          //                        _this.$t('userlists.userlist.pleasePhoneNumber'),
          //                        '',
          //                        'warning'
          //                    )
          //                    return false
          //                }
          else if (_this.form.email == "") {
            commonUtil.showTipInfo(
              _this.$t("userlists.userlist.pleaseEmailaddress"),
              "",
              "warning"
            );
            return false;
          }
          // else if (_this.form.sex == '') {
          //                    commonUtil.showTipInfo(
          //                        _this.$t('userlists.userlist.pleaseGender'),
          //                        '',
          //                        'warning'
          //                    )
          //                    return false
          //                }
          else if (_this.form.organizationIds == "") {
            commonUtil.showTipInfo(
              _this.$t("userlists.userlist.pleaseDepartments"),
              "",
              "warning"
            );
            return false;
          } else if (_this.form.commodityIds == "") {
            commonUtil.showTipInfo(
              _this.$t("userlists.userlist.pleaseCommodity"),
              "",
              "warning"
            );
            return false;
          }
        }
      });
    },
    DepartmentTree(form) {
      let _this = this;
      form.organizations.forEach(function (val, key) {
        if (_this.form.organizations.indexOf(val) < 0) {
          _this.form.organizations.push(val);
          _this.form.organizationIds.push(form.organizationIds[key]);
        }
      });
      this.$refs.TreeDepartmentDialog.visible = true;
    },
    CommodityTree(form) {
      let _this = this;
      form.Name.forEach(function (val, key) {
        if (_this.form.commodityName.indexOf(val) < 0) {
          _this.form.commodityName.push(val);
          _this.form.commodityIds.push(form.Id[key]);
        }
      });
      this.$refs.TreeCommodityDialog.visible = true;
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
</style>
