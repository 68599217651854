<template>
  <div class="tcm-list">
    <div class="tcm-search">
      <table>
        <tr>
          <td>
            {{ $t("tcmdatalists.tcmdatalist.commodity") }}
            <el-tree-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              v-model="searchForm.CommodityId"
              :data="CommodityOptions"
              :props="defaultProps"
              check-strictly
              :render-after-expand="false"
              :default-expand-all="true"
            />
            <!-- <el-select clearable  v-model="searchForm.CommodityId"  placeholder="">
						    <el-option
						      v-for="item in commodityOptions"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value"
						    />
						</el-select> -->
          </td>
          <td>
            {{ $t("tcmdatalists.tcmdatalist.date") }}
            <el-date-picker
              v-model="searchForm.date"
              type="daterange"
              range-separator="To"
              :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
              :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
              size="default"
              value-format="YYYY-MM-DD"
              @change="changeDate"
            />
          </td>
          <td>
            {{ $t("tcmdatalists.tcmdatalist.status") }}
            <el-select
              clearable
              v-model="searchForm.status"
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </td>
          <td>
            <el-input
              v-model="searchForm.Keyword"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </td>
          <td>
            <div class="operate-btn" style="margin-right: 10px" @click="search">
              {{ $t("tcmdatalists.tcmdatalist.search") }}
            </div>
            <!-- <div class="operate-btn">Advanced search</div> -->
          </td>
        </tr>
      </table>
    </div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column width="55" align="center">
          <template #default="scope">
            <img
              src="../../assets/img/tcm/sandclock.svg"
              style="cursor: pointer"
              v-show="scope.row.status != '6'"
              @click="editProcess(scope.row.id, scope.row.status)"
            />
            <img
              src="../../assets/img/tcm/computer.svg"
              style="cursor: pointer"
              v-show="scope.row.status == '6'"
              @click="editProcess(scope.row.id, scope.row.status)"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="commodityName"
          :label="$t('tcmdatalists.tcmdatalist.commodity')"
          width="110"
          align="center"
        />
        <!-- <el-table-column
          prop="documentNo"
          :label="$t('tcmdatalists.tcmdatalist.documentNO')"
          align="center"
        /> -->
        <el-table-column
          prop="tcmRequest"
          :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="partDescription"
          :label="$t('tcmdatalists.tcmdatalist.partDescription')"
          align="center"
        />
        <el-table-column
          prop="partNumber"
          :label="$t('tcmdatalists.tcmdatalist.partNumber')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="date"
          :label="$t('tcmdatalists.tcmdatalist.startDate')"
          sortable
          width="125"
          align="center"
        />
        <el-table-column
          prop="doDate"
          :label="$t('tcmdatalists.tcmdatalist.dueDate')"
          sortable
          width="120"
          align="center"
        />
        <el-table-column
          prop="urgency"
          :label="$t('tcmdatalists.tcmdatalist.priority')"
          width="110"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.urgency == 'High'" class="color-ff0000">
              {{ $t("tcmdatalists.tcmdatalist.high") }}
            </div>
            <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.middle") }}
            </div>
            <div v-show="scope.row.urgency == 'Low'">
              {{ $t("tcmdatalists.tcmdatalist.low") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="quoteType"
          :label="$t('tcmdatalists.tcmdatalist.quotationType')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="tcmOwner"
          :label="$t('tcmdatalists.tcmdatalist.tcmOwner')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="status"
          :label="$t('tcmlists.tcmlist.workStatus')"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.status == '0'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.notStart") }}
            </div>
            <div v-show="scope.row.status == '1'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.inProcess") }}
            </div>
            <div v-show="scope.row.status == '2'" class="color-0000FF">
              {{ $t("tcmdatalists.tcmdatalist.toApproved") }}
            </div>
            <div v-show="scope.row.status == '4'">
              {{ $t("tcmdatalists.tcmdatalist.underApproved") }}
            </div>
            <div v-show="scope.row.status == '5'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.rejected") }}
            </div>
            <div v-show="scope.row.status == '6'" class="color-67C23A">
              {{ $t("tcmdatalists.tcmdatalist.approved") }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-cla">
      <div>
        <el-input
          v-model="searchForm.limit"
          @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
          min="1"
          style="width: 130px"
          @change="handleSizeChange"
        >
          <template #suffix>
            {{ $t("ccls.ccl.barPage") }}
          </template>
        </el-input>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  getApplicationList,
  updateStatusCostApplys,
  GetOrgs,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      searchForm: {
        timer: "",
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
      },
      // commodity选项
      // commodity选项
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // status选项
      statusOptions: [],
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
    };
  },
  mounted() {
    this.getList();
    this.getCommdity();
    this.getUnits();
  },
  methods: {
    getUnits() {
      this.timer = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        this.statusOptions = [
          {
            value: "",
            label: "All",
          },
          {
            value: 0,
            label: "Not start",
          },
          {
            value: 1,
            label: "In process",
          },
          {
            value: 2,
            label: "To be approved",
          },
          {
            value: 4,
            label: "Under approved",
          },
          {
            value: 5,
            label: "Rejected",
          },
          {
            value: 6,
            label: "Approved",
          },
        ];
      } else {
        this.statusOptions = [
          {
            value: "",
            label: "全部",
          },
          {
            value: 0,
            label: "未开始",
          },
          {
            value: 1,
            label: "进行中",
          },
          {
            value: 2,
            label: "待审批",
          },
          {
            value: 4,
            label: "审批中",
          },
          {
            value: 5,
            label: "驳回",
          },
          {
            value: 6,
            label: "通过",
          },
        ];
      }
      clearInterval(this.timer);
    },
    getList() {
      this.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        PageType: 2,
        versionType: 1,
        previewData: {},
      };
      if (this.$route.query.Time) {
        this.searchForm.StartDate = this.$route.query.Time;
      }
      if (this.$route.query.status) {
        this.searchForm.status = parseFloat(this.$route.query.status);
      }
      this.search();
    },
    search() {
      getApplicationList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 继续编辑(进入审批)
    editProcess(id, status) {
      this.$router.push({
        path: "/apprcverSUM",
        name: "apprcverSUM",
        query: {
          type: "edit",
          id: id,
          status: status,
        },
      });
    },
  },
};
</script>

<style scoped>
.tcm-search {
  width: 100%;
  background-color: var(--searchBg);
  padding: 20px;
  box-sizing: border-box;
}
.tcm-search td {
  /* width: 20%; */
  padding: 0 10px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.color-ff0000 {
  color: #ff0000;
}
.color-F59A23 {
  color: #f59a23;
}
.color-0000FF {
  color: #0000ff;
}
.color-D9001B {
  color: #d9001b;
}
.color-67C23A {
  color: #40d4a8;
}
.mlg-10 {
  margin-left: 10px;
}
.mrg-10 {
  margin-right: 10px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.box-card {
  margin-bottom: 10px;
}
.drawer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.drawer-footer {
  display: flex;
  justify-content: flex-end;
}
</style>