<template>
  <el-dialog v-model="visible" width="400px" :show-close="false">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("tcmlists.tcmlist.classification") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-row>
        <el-col>
          <div class="tree-cla">
            <el-tree
              :empty-text="$t('tcmlists.tcmlist.noData')"
              ref="treeRef"
              class="filter-tree"
              :data="treeData"
              node-key="id"
              :props="defaultProps"
              default-expand-all
              show-checkbox
              @check-change="handleCheckChange"
              :check-strictly="true"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { GetOrgs, moduleTree } from "@/assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: ["userId"],
  data() {
    return {
      powers: "",
      timer: "",
      visible: false,
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeData: [],
      form: {
        id: "",
        name: "",
      },
    };
  },

  methods: {
    close() {
      this.visible = false;
    },
    // 树点击
    handleCheckChange(data, checked, indeterminate) {},
    // 提交按钮
    handleSubmit() {
      let res = this.$refs.treeRef.getCheckedNodes();
      if (res == "") {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.commodityRequired"),
          "",
          "warning"
        );
      }
      this.visible = false;
      this.form.id = res[0].id;
      this.form.name = res[0].name;
      this.$emit("handleSubmit", this.form);
      this.visible = false;
    },
    getTree() {
      this.timer = setTimeout(this.gettime, 500);
    },
    gettime() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        moduleTree(
          { IsPhaseII: sessionStorage.getItem("phaseII") == 2 ? 1 : 0 },
          (response) => {
            if (response && response.code == 200) {
              response.result.forEach((e) => {
                e.name = e.nameEn;
              });
              this.treeData = this.transferList(response.result);
            }
          }
        );
      } else {
        moduleTree(
          { IsPhaseII: sessionStorage.getItem("phaseII") == 2 ? 1 : 0 },
          (response) => {
            if (response && response.code == 200) {
              // let treedata = response.Result;
              this.treeData = this.transferList(response.result);
              // 部门列表回显
              // echoList('"' + this.userId + '"', (response) => {
              //   if (response && response.code == 200) {
              //     let arr = [];
              //     for (let i = 0; i < treedata.length; i++) {
              //       for (let j = 0; j < response.data.length; j++) {
              //         if (treedata[i].id == response.data[j].id) {
              //           arr.push(treedata[i]);
              //         }
              //       }
              //     }
              //     console.log("arr", arr);
              //     this.arr = arr;
              //     this.$refs.treeRef.setCheckedNodes(arr);
              //   }
              // });
            }
          }
        );
      }
      clearTimeout(this.timer);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 数据转化
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.id === item.parentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
</style>
