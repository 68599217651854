<template>
    <el-dialog v-model="visible" width="600px" :show-close="false">
        <template #header="{ close, titleId, titleClass }">
            <div class="my-header">
                <div class="size-16">{{$t('ccls.ccl.editFormula')}}</div>
                <div style="cursor: pointer;" @click="close">
                    <el-icon :size="20"  style="color: var(--themeColor);">
                        <CircleCloseFilled />
                    </el-icon>
                </div>
            </div>
        </template>
        <div>
            <el-row :gutter="10">
                <el-col :span="8">
                    <div class="mgb-10 width-170 dis-between" v-show="!isIf">
                        <el-button size="small" @click="enterKey('roundup')">ROUNDUP</el-button>
                        <el-button size="small" @click="enterKey('round')">ROUND</el-button>
                    </div>
                    <div class="width-170">
                        <div class="mgb-10 dis-between">
                            <el-button size="small" class="width-31" @click="enterKey('7')">7</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('8')">8</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('9')">9</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('/')">/</el-button>
                        </div>
                        <div class="mgb-10 dis-between">
                            <el-button size="small" class="width-31" @click="enterKey('4')">4</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('5')">5</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('6')">6</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('*')">*</el-button>
                        </div>
                        <div class="mgb-10 dis-between">
                            <el-button size="small" class="width-31" @click="enterKey('1')">1</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('2')">2</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('3')">3</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('-')">-</el-button>
                        </div>
                        <div class="mgb-10 dis-between">
                            <el-button size="small" class="width-31" @click="enterKey('del')" style="padding: 5px 6.5px;">del</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('0')">0</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('.')">.</el-button>
                            <el-button size="small" class="width-31" @click="enterKey('+')">+</el-button>
                        </div>
                        <div class="dis-between">
                            <el-button size="small" class="width-31" @click="enterKey('(')">(</el-button>
                            <el-button size="small" class="width-31" @click="enterKey(')')">)</el-button>
                            <!-- <el-button size="small" class="width-31" @click="enterKey('sum')">sum</el-button> -->
                            <el-button size="small" class="width-31" @click="enterKey('if')" v-show="!isIf">if</el-button>
							<div class="width-31" style="margin-left: 12px;"></div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="15">
                    <div class="mgb-10">
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-select clearable  :no-data-text="$t('tcmlists.tcmlist.noData')" v-model="value" size="small" @change="changeValueVal" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                                    <el-option v-for="item in valueList" :key="item.parameterName" :label="item.parameterName" :value="item.parameterName" />
                                </el-select>
                            </el-col>
                            <el-col :span="12">
                                <el-select clearable  :no-data-text="$t('tcmlists.tcmlist.noData')" v-model="intermediateBond" size="small" @change="changeValue" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                                    <el-option v-for="item in intermediateBondList" :key="item.parameterName" :label="item.parameterName" :value="item.parameterName" />
                                </el-select>
                            </el-col>
                        </el-row>

                    </div>
                    <div>
                        <el-input v-model="formula" type="textarea" :rows="7" resize="none" :readonly="true" />
                    </div>
                </el-col>
            </el-row>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="close">{{$t('ccls.ccl.cancel')}}</el-button>
                <el-button  style="background-color: var(--themeColor); color: var(--menuTextActive);margin-right: 16px" @click="submit">{{$t('ccls.ccl.confirm')}}</el-button>
            </span>
        </template>
        <IfDia ref="ifDialog" @setIfFunc="setIfFunc"></IfDia>
        <RoundDia ref="roundDialog" :valueList="valueList" :intermediateBondList="intermediateBondList" :roundType="roundType" @setRoundFunc="setRoundFunc"></RoundDia>
    </el-dialog>
</template>

<script>
import IfDia from "./ifDia.vue";
import RoundDia from "./roundDia.vue";
import { InputParameterAnalysis } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
    emits: ["submitFormula"],
    props: ["valueList", "intermediateBondList"],
    components: {
        IfDia,
        RoundDia,
    },
    data() {
        return {
            visible: false,
            // 值
            value: "",
            // 中间键
            intermediateBond: "",
            // 计算公式
            formula: "",
            // 计算公式数组
            formulaArr: [],
            // 代名词公式数组
            formulaPronoun: [],
            // round类型
            roundType: "",
            // 符号
            symbol: [
                "+",
                "-",
                "*",
                "/",
                "sum",
                "if",
                "(",
                ")",
                "Round",
                "Ceiling",
            ],
			// 是否是if函数
			isIf: false
        };
    },
    methods: {
        enterKey(key) {
            console.log(key);
            let _this = this;
            if (
                key != "del" &&
                key != "if" &&
                key != "round" &&
                key != "roundup"
            ) {
                this.formulaArr.push(key);
                this.formulaPronoun.push(key);
            } else if (key == "if") {
				console.log(this.valueList)
				this.$refs.ifDialog.valueList = this.valueList
				this.$refs.ifDialog.intermediateBondList = this.intermediateBondList
                this.$refs.ifDialog.visible = true;
            } else if (key == "round" || key == "roundup") {
                this.roundType = key;
                this.$refs.roundDialog.visible = true;
            } else {
                this.formulaArr.pop();
                this.formulaPronoun.pop();
            }
            // this.formula = this.formulaPronoun.join('$').replace(/\$/g, '')
            // console.log(this.formula)
            // this.formula = this.formula.replace(/\|/g, '')
            this.formula = this.converseData(this.formulaPronoun);
            // this.formula = this.formula.replace(/'input'/g, '')
            // this.formula = this.formula.replace(/'input'/g, '')
        },
        submit() {
            console.log(this.formulaArr);
            console.log(this.formulaPronoun);
            let formulaPre = [];
            this.formulaArr.forEach(function (value, index) {
                if (Array.isArray(value)) {
                    value.forEach(function (ifvalue, ifindex) {
                        formulaPre.push(ifvalue);
                    });
                } else {
                    formulaPre.push(value);
                }
            });
            // 校验计算使用公式
            let formulaPrex = formulaPre.join("");
            console.log(formulaPrex);

            let list = [];
            this.$parent.valueList.forEach(function (val, key) {
                list.push({
                    paramValue: val.thedefaultvalue,
                    paramentName: val.parameterName,
                });
            });
            this.$parent.intermediateBondList.forEach(function (val, key) {
                list.push({
                    paramValue: val.formulaPre,
                    paramentName: val.parameterName,
                });
            });
            let data = {
                outputFormula: formulaPrex,
                inputParameterList: list,
            };
			console.log(data)
			console.log( this.formulaArr)
			console.log( this.formulaPronoun)
			if (data.outputFormula === '') {
				this.$emit(
				    "submitFormula",
				    this.formulaArr,
				    this.formulaPronoun
				);
				this.close();
			} else {
				InputParameterAnalysis(data, (response) => {
				    if (response && response.code == 200) {
				        console.log(response);
				        if (response.data === "") {
				            commonUtil.showTipInfo(
				                this.$t("ccls.ccl.theFormula"),
				                "",
				                "warning"
				            );
				            return;
				        } else {
				            this.$emit(
				                "submitFormula",
				                this.formulaArr,
				                this.formulaPronoun
				            );
				            this.close();
				        }
				    }
				});
			}
            
        },
        changeValue(val) {
            let _this = this;
            let valx = "|" + val + "|";
            this.formulaArr.push(valx);

            let valIndex = "";
            this.intermediateBondList.forEach(function (valuex, index) {
                if (valuex.parameterName == val) {
                    valIndex = index;
                }
            });
            this.formulaPronoun.push(
                this.intermediateBondList[valIndex].ParameterNumName
            );
            // this.formula = this.formulaPronoun.join('$').replace(/\$/g, '')
            // this.formula = this.formula.replace(/\|/g, '')
            this.formula = this.converseData(this.formulaPronoun);
            this.value = "";
            this.intermediateBond = "";
        },
        changeValueVal(val) {
            let _this = this;
            let valx = "|" + val + "|";
            this.formulaArr.push(valx);

            let valIndex = "";
            this.valueList.forEach(function (valuex, index) {
                if (valuex.parameterName == val) {
                    valIndex = index;
                }
            });
            this.formulaPronoun.push(this.valueList[valIndex].ParameterNumName);
            this.formula = this.converseData(this.formulaPronoun);
            // this.formula = this.formulaPronoun.join('$').replace(/\$/g, '')
            // this.formula = this.formula.replace(/\|/g, '')
            this.value = "";
            this.intermediateBond = "";
        },
        close() {
            this.formula = "";
            this.formulaArr = [];
            this.formulaPronoun = [];
            this.visible = false;
        },
        // 获取if公式
        setIfFunc(val, valFormula) {
			console.log(valFormula)
            this.formulaArr.push(valFormula);
            // this.formula = this.formulaPronoun.join('$').replace(/\$/g, '') + val.join('')
            // this.formula = this.formula.replace(/\|/g, '')
            this.formulaPronoun.push(val);
            this.formula = this.converseData(this.formulaPronoun);
			console.log(this.formulaArr)
			console.log(this.formulaPronoun)
			console.log(this.formula)
        },
        // 获取round公式
        setRoundFunc(val, valc) {
            console.log(val);
            this.formulaArr.push(val);
            this.formulaPronoun.push(valc);
            // 去除最后一个数据
            let roundArr = JSON.parse(JSON.stringify(val));
            roundArr.pop();
            console.log(roundArr);
            // this.formula = this.formulaPronoun.join('$').replace(/\$/g, '') + roundArr.join('')
            this.formula = this.converseData(this.formulaPronoun);
        },
        // 计算器结果数据转化
        converseData(val) {
            let _this = this;
            let data = [];
            // 初始数字位
            let startnum = 0;
            let endnum = 0;
            val.forEach(function (value, key) {
                if (_this.symbol.indexOf(value) > -1) {
                    endnum = key;
                    let num = val.slice(startnum, endnum);
                    data.push(num.join(""));
                    data.push(value);
                    startnum = key + 1;
                } else if (Array.isArray(value)) {
                    endnum = key;
                    let num = val.slice(startnum, endnum);
                    data.push(num.join(""));
                    if (
                        value.indexOf("Round") > -1 ||
                        value.indexOf("RoundUp") > -1
                    ) {
                        let roundArr = JSON.parse(JSON.stringify(value));
                        roundArr.pop();
                        roundArr.forEach(function (valx, valueIndex) {
                            data.push(valx);
                        });
                    } else {
                        value.forEach(function (valx, valueIndex) {
                            data.push(valx);
                        });
                    }

                    startnum = key + 1;
                } else {
                    if (key == val.length - 1) {
                        endnum = -1;
                        let num =
                            val.slice(startnum - val.length, endnum).join("") +
                            val.slice(-1);
                        data.push(num);
                    }
                }
            });
            data = data.join("$").replace(/\$/g, "");
            data = data.replace(/\|/g, "");
			data = data.replace(/roundRound/g, "");
			data = data.replace(/round/g, "");
			console.log(data)
            return data;
        },
    },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
    margin-right: 0;
}
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.width-170 {
    width: 170px;
}
.width-31 {
    width: 31px;
}
.size-16 {
    font-size: 16px;
}
.mgb-10 {
    margin-bottom: 10px;
}
.dis-between {
    display: flex;
    justify-content: space-between;
}
</style>