<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">
        {{ $t("homePage.home.RegionManagement") }}
      </div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div class="handle-btn-item" @click="add" v-if="this.btnAdd > 0">
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div class="handle-btn-item" @click="bathOperate('start')">
        {{ $t("userlists.userlist.batchEnable") }}
      </div>
      <div class="handle-btn-item" @click="bathOperate('stop')">
        {{ $t("userlists.userlist.batchDisabling") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @row-click="RowClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="ids" label="ID" align="center" />
        <el-table-column
          prop="name"
          :label="$t('ccls.ccl.name')"
          align="center"
        />
        <el-table-column
          :label="$t('ccls.ccl.enable')"
          align="center"
          prop="isEnable"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.isEnable == '1'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.isEnable == '2'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          fixed="right"
          class-name="lastTd"
          align="center"
          prop="action"
          :label="$t('homePage.home.Action')"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div
                  class="action-btn"
                  @click="edit(scope.row)"
                  v-if="this.btnEdit > 0"
                >
                  <el-icon><Edit /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
                <div
                  class="action-btn"
                  @click="del(scope.row.id)"
                  v-if="this.btnDel > 0"
                >
                  <el-icon><Delete /></el-icon>
                  <span> {{ $t("unifiedPrompts.unifiedPrompt.delete") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
  <AddDia ref="addDialog" @confirmInfo="confirmInfo"></AddDia>
</template>
<script>
import {
  loadRegions,
  AddOrUpdateRegions,
  deleteOneRegions,
  deleteRegions,
  EnableByIdsRegions,
  DisableByIdsRegions,
} from "@/assets/js/requestApi";
import commonUtil from "../../assets/js/commonUtil.js";
import AddDia from "./addDia.vue";
export default {
  name: "RegionList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  components: {
    AddDia,
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        Keyword: "",
        page: 1, //当前页
        limit: 10, //每页个数
      },
      total: 0,
      pageSize: 10,
      tableData: [],
      // 已勾选的数据
      chooseData: [],
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
    };
  },
  mounted() {
    this.autoHeight();
    this.initList();
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    RowClick(row) {
      if (this.btnEdit > 0) {
        this.edit(row);
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加" || e.domId == "Add") {
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.domId == "Delete") {
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.domId == "Edit") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    // 渲染列表
    initList() {
      this.searchForm = {
        Keyword: "",
        page: 1, //当前页
        limit: 10, //每页个数
      };
      this.search();
    },
    // 查询
    search() {
      loadRegions(this.searchForm, (response) => {
        console.log(response);
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 删除
    del(id) {
      deleteOneRegions('"' + id + '"', (response) => {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
          "",
          "success"
        );
        this.search();
      });
    },
    // 新增
    add() {
      this.$refs.addDialog.visible = true;
    },
    // 新增提交
    confirmInfo(form) {
      console.log(form);
      let data = {
        id: form.id,
        name: form.name,
        nameEn: form.nameEn,
        isEnable: form.isEnable ? 1 : 2,
      };
      AddOrUpdateRegions(data, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 编辑
    edit(row) {
      this.$refs.addDialog.form.id = row.id;
      this.$refs.addDialog.form.name = row.name;
      this.$refs.addDialog.form.nameEn = row.nameEn;
      this.$refs.addDialog.form.isEnable = row.isEnable == 1 ? true : false;
      this.$refs.addDialog.visible = true;
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      console.log(this.searchForm.limit);
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 表格多选
    handleSelectionChange(val) {
      console.log(val);
      this.chooseData = val;
    },
    // 批量操作
    bathOperate(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      if (type == "delete") {
        deleteRegions(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(response.message, "", "success");
            this.search();
          }
        });
      } else if (type == "start") {
        EnableByIdsRegions(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(response.message, "", "success");
            this.search();
          }
        });
      } else if (type == "stop") {
        DisableByIdsRegions(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(response.message, "", "success");
            this.search();
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>