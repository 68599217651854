<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.CC") }}</div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="regional_table" style="margin-top: 10px">
      <el-table
        border
        stripe
        :data="tableData"
        :height="TableHeight"
        @row-click="RowClick"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="commodityName"
          :label="$t('tcmlists.tcmlist.commodity')"
          width="110"
          align="center"
        />
        <el-table-column
          prop="programName"
          :label="$t('changePriceLists.changePriceList.programName')"
          align="center"
        />
        <el-table-column
          prop="regionName"
          :label="$t('tcmdatalists.tcmdatalist.regionName')"
          align="center"
        />
        <!-- <el-table-column
          prop="documentNo"
          :label="$t('tcmlists.tcmlist.documentNO')"
          align="center"
        /> -->
        <el-table-column
          prop="tcmOwner"
          :label="$t('tcmlists.tcmlist.tcmRequester')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="partDescription"
          :label="$t('tcmlists.tcmlist.partDescription')"
          align="center"
        />
        <el-table-column
          prop="partNumber"
          :label="$t('tcmlists.tcmlist.partNumber')"
          width="120"
          align="center"
        />

        <el-table-column
          prop="urgency"
          :label="$t('tcmlists.tcmlist.priority')"
          width="110"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.urgency == 'High'" class="color-ff0000">
              {{ $t("tcmdatalists.tcmdatalist.high") }}
            </div>
            <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.middle") }}
            </div>
            <div v-show="scope.row.urgency == 'Low'">
              {{ $t("tcmdatalists.tcmdatalist.low") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="quoteType"
          :label="$t('tcmlists.tcmlist.quotationType')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="tcmRequest"
          :label="$t('tcmlists.tcmlist.tcmOwner')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="status"
          :label="$t('tcmlists.tcmlist.workStatus')"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.status == '0'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.notStart") }}
            </div>
            <div v-show="scope.row.status == '1'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.inProcess") }}
            </div>
            <div v-show="scope.row.status == '2'" class="color-0000FF">
              {{ $t("tcmdatalists.tcmdatalist.toApproved") }}
            </div>
            <div v-show="scope.row.status == '4'">
              {{ $t("tcmdatalists.tcmdatalist.underApproved") }}
            </div>
            <div v-show="scope.row.status == '5'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.rejected") }}
            </div>
            <div v-show="scope.row.status == '6'" class="color-67C23A">
              {{ $t("tcmdatalists.tcmdatalist.approved") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          :label="$t('tcmlists.tcmlist.startDate')"
          width="125"
          align="center"
        />
        <el-table-column
          prop="doDate"
          :label="$t('changePriceLists.changePriceList.dueDate')"
          sortable
          width="120"
          align="center"
        />
        <el-table-column
          width="80"
          fixed="right"
          class-name="lastTd"
          align="center"
          prop="action"
          :label="$t('homePage.home.Action')"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div class="action-btn" @click="showDetail(scope.row.id)">
                  <img
                    style="width: 15px; height: 15px"
                    src="../../assets/img/tcm/computer.svg"
                  />
                  <span>Computer</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>

  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <table>
        <tr>
          <!== <td>
						{{$t('tcmlists.tcmlist.commodity')}}
						<el-tree-select clearable :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"  v-model="searchForm.CommodityId" :data="CommodityOptions" :props="defaultProps" check-strictly :render-after-expand="false" :default-expand-all="true" />
					</td>
					<td>
						{{$t('tcmlists.tcmlist.date')}}
						<el-date-picker
							v-model="searchForm.date"
							type="daterange"
							range-separator="To"
							:start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
							:end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
							size="default"
							value-format="YYYY-MM-DD"
							@change="changeDate"
						/>
					</td> ==>
          <!== <td>
						{{$t('tcmlists.tcmlist.workStatus')}}
						<el-select clearable  v-model="searchForm.status"   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
						    <el-option
						      v-for="item in statusOptions"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value"
						    />
						</el-select>
						
						
					</td> ==>
          <td>
            <el-input
              v-model="searchForm.Keyword"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </td>
          <td>
            <div class="operate-btn" style="margin-right: 10px" @click="search">
              {{ $t("tcmlists.tcmlist.search") }}
            </div>
            <!== <div class="operate-btn">Advanced search</div> ==>
          </td>
        </tr>
      </table>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="" width="80" align="center">
          <template #default="scope">
            <img
              src="../../assets/img/tcm/computer.svg"
              style="cursor: pointer"
              @click="showDetail(scope.row.id)"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="commodityName"
          :label="$t('tcmlists.tcmlist.commodity')"
          width="110"
          align="center"
        />
        <el-table-column
          prop="programName"
          :label="$t('changePriceLists.changePriceList.programName')"
          align="center"
        />
        <el-table-column
          prop="regionName"
          :label="$t('tcmdatalists.tcmdatalist.regionName')"
          align="center"
        />
        <el-table-column
          prop="documentNo"
          :label="$t('tcmlists.tcmlist.documentNO')"
          align="center"
        />
        <el-table-column
          prop="tcmOwner"
          :label="$t('tcmlists.tcmlist.tcmRequester')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="partDescription"
          :label="$t('tcmlists.tcmlist.partDescription')"
          align="center"
        />
        <el-table-column
          prop="partNumber"
          :label="$t('tcmlists.tcmlist.partNumber')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="date"
          :label="$t('tcmlists.tcmlist.startDate')"
          width="125"
          align="center"
        />
        <el-table-column
          prop="doDate"
          :label="$t('changePriceLists.changePriceList.dueDate')"
          sortable
          width="120"
          align="center"
        />
        <el-table-column
          prop="urgency"
          :label="$t('tcmlists.tcmlist.priority')"
          width="110"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.urgency == 'High'" class="color-ff0000">
              {{ $t("tcmdatalists.tcmdatalist.high") }}
            </div>
            <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.middle") }}
            </div>
            <div v-show="scope.row.urgency == 'Low'">
              {{ $t("tcmdatalists.tcmdatalist.low") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="quoteType"
          :label="$t('tcmlists.tcmlist.quotationType')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="tcmRequest"
          :label="$t('tcmlists.tcmlist.tcmOwner')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="status"
          :label="$t('tcmlists.tcmlist.workStatus')"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.status == '0'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.notStart") }}
            </div>
            <div v-show="scope.row.status == '1'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.inProcess") }}
            </div>
            <div v-show="scope.row.status == '2'" class="color-0000FF">
              {{ $t("tcmdatalists.tcmdatalist.toApproved") }}
            </div>
            <div v-show="scope.row.status == '4'">
              {{ $t("tcmdatalists.tcmdatalist.underApproved") }}
            </div>
            <div v-show="scope.row.status == '5'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.rejected") }}
            </div>
            <div v-show="scope.row.status == '6'" class="color-67C23A">
              {{ $t("tcmdatalists.tcmdatalist.approved") }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-cla">
      <div>
        <el-input
          v-model="searchForm.limit"
          @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
          min="1"
          style="width: 130px"
          @change="handleSizeChange"
        >
          <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
        </el-input>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div> -->
</template>

<script>
import { LoadForCC, GetOrgs } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import { ElMessageBox } from "element-plus";
export default {
  name: "TcmCC",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        status: "",
        StartDate: "",
        EndDate: "",
        StartDate_Approved: "",
        EndDate_Approved: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        PageType: 0,
        versionType: 0,
      },
      // commodity选项
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // status选项
      statusOptions: [],
      tableData: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      // 数据重复是否继续
      isContinue: false,
    };
  },
  activated() {
    this.search();
  },
  mounted() {
    this.autoHeight();

    this.getList();
    this.getCommdity();
    this.getUnits();
  },
  methods: {
    RowClick(row) {
      this.showDetail(row.id);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 205; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.timer = setInterval(this.gettime, 1000);
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.powers = JSON.parse(localStorage.getItem("translate"));
      this.power.forEach((e) => {
        if (e.name == "添加") {
          this.btnAdd = 1;
        }
        if (e.name == "删除") {
          this.btnDel = 1;
        }
        if (e.name == "编辑") {
          this.btnEdit = 1;
        }
      });

      if (this.powers == "en" || this.powers == null) {
        this.statusOptions = [
          {
            value: "",
            label: "All",
          },
          {
            value: 0,
            label: "Not start",
          },
          {
            value: 1,
            label: "In process",
          },
          {
            value: 2,
            label: "To be approved",
          },
          {
            value: 4,
            label: "Under approved",
          },
          {
            value: 5,
            label: "Rejected",
          },
          {
            value: 6,
            label: "Approved",
          },
        ];
      } else {
        this.statusOptions = [
          {
            value: "",
            label: "全部",
          },
          {
            value: 0,
            label: "未开始",
          },
          {
            value: 1,
            label: "进行中",
          },
          {
            value: 2,
            label: "待审批",
          },
          {
            value: 4,
            label: "审批中",
          },
          {
            value: 5,
            label: "驳回",
          },
          {
            value: 6,
            label: "通过",
          },
        ];
      }

      clearTimeout(this.timer);
    },
    getList() {
      this.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        status: "",
        StartDate: "",
        EndDate: "",
        StartDate_Approved: "",
        EndDate_Approved: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        PageType: 0,
        versionType: 0,
      };
      this.search();
    },
    search() {
      LoadForCC(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 详情
    showDetail(id) {
      this.$router.push({
        path: "/tcmAdd",
        name: "TcmAdd",
        query: {
          type: "detail",
          disabled: true,
          id: id,
        },
      });
      // this.$router.push({
      // 	path: '/tcmResult',
      // 	name: 'tcmResult',
      // 	query: {
      // 		id: id
      // 	}
      // })
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>
