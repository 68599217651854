<template>
  <div class="tcm-add">
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="Goback">
        {{ $t("homePage.home.Back") }}
      </div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ item }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>
    <div class="tcm-title">
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" :model="form" label-width="200px">
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('ccls.ccl.nexteerPart')"
                  class="custom-el-form-rules"
                >
                  <el-input
                    v-model="form.nexteerPart"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.cclname')">
                  <el-input
                    v-model="form.name"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.desc')">
                  <el-input
                    v-model="form.description"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.revision')">
                  <el-input
                    v-model="form.revisionID"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.project')">
                  <el-input
                    v-model="form.project"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('ccls.ccl.annualVolumn')">
                  <el-input
                    v-model="form.annualVolume"
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="$t('changePriceLists.changePriceList.region')"
                >
                  <el-select
                    clearable
                    :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                    filterable
                    v-model="form.regionId"
                    placeholder=""
                    style="width: 214px"
                  >
                    <el-option
                      v-for="item in regionOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8"></el-col>
              <el-col :span="8" style="text-align: right">
                <div class="operate-btn" @click="submit('add')">
                  {{ $t("ccls.ccl.submit") }}
                </div>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <!-- <el-col :span="2">
					
				</el-col> -->
      </el-row>
    </div>
    <div class="tcm-content-form border-bottom">
      <el-row>
        <el-col :span="22">
          <el-form label-width="250px">
            <div>
              <el-row>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.workingLength')">
                    <el-input
                      v-model="form.workingPanelLength"
                      style="width: 214px"
                      @input="
                        transferFormat($event, 'workingPanelLength'),
                          toCompute('size')
                      "
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.cclSize')">
                    <el-input
                      v-model="form.size"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.cclPanel')">
                    <el-input
                      v-model="form.panelQTYCCL"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.lengthPcb')">
                    <el-input
                      v-model="form.lengthOfPCB"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.workingWidth')">
                    <el-input
                      v-model="form.workingPanelWidth"
                      style="width: 214px"
                      @input="
                        transferFormat($event, 'workingPanelWidth'),
                          toCompute('size')
                      "
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.cclLength')">
                    <el-input
                      v-model="form.cclLength"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.cclPcb')">
                    <el-input
                      v-model="form.pcbqtyccl"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.widthPcb')">
                    <el-input
                      v-model="form.widthOfPCB"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.panelization')">
                    <el-input
                      v-model="form.panelizationQTY"
                      style="width: 214px"
                      @input="
                        transferFormat($event, 'panelizationQTY'),
                          toCompute('size')
                      "
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.cclWidth')">
                    <el-input
                      v-model="form.cclWidth"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.cclUtility')">
                    <el-input
                      v-model="form.panelUtility"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('ccls.ccl.pcbQtyAll')">
                    <el-input
                      v-model="form.pcs"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div v-show="form.size !== ''">
              <el-row>
                <el-col :span="24" style="text-align: center">
                  <img class="jt-img" src="../../assets/img/tcm/xiangxia.png" />
                </el-col>
              </el-row>
              <el-row style="margin-top: 20px">
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.cclType')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.materialType"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in typeOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.cclSelection')">
                    <el-input
                      v-model="form.ozValue"
                      style="width: 214px"
                      @input="transferFormat($event, 'ozValue')"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.supplier')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.supplier_Name"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in nameOptions"
                        :key="item.colName"
                        :label="item.colName"
                        :value="item.colName"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="8">
									<el-form-item :label="$t('ccls.ccl.material')">
										<el-select clearable  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="form.materialTG" style="width: 214px;">
											<el-option
												v-for="item in MaterialOptions"
												:key="item"
												:label="item"
												:value="item"
											/>
										</el-select>
									</el-form-item>
								</el-col> -->
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.Layers')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.numberOfLayers"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in LayersOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <!-- <el-col :span="8">
									<el-form-item :label="$t('ccls.ccl.innerThickness')">
										<el-select clearable  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="form.internalCopperThickness" style="width: 214px;">
											<el-option
												v-for="item in InnersOptions"
												:key="item"
												:label="item"
												:value="item"
											/>
										</el-select>
									</el-form-item>
								</el-col> -->
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.outerThickness')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.outerCopperThickness"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in OuterOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.ViasDiameter')">
                    <!-- <el-input v-model="form.viasDiameter"  style="width: 214px;" @input="transferFormat($event, 'viasDiameter')" /> -->
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.viasDiameter"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in viasDiameterOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.prepreg')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.prepreg"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in PrepregOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.managementCharge')">
                    <el-input
                      v-model="form.managementCharge"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.cclProfit')">
                    <el-input
                      v-model="form.profit"
                      disabled
                      style="width: 214px"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.viaFilling')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.viaFilling"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in viaOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.soldermask')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.soldermask"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in maskOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.numberHoles')">
                    <el-input
                      v-model="form.numberOfHoles"
                      style="width: 214px"
                      @input="transferFormat($event, 'numberOfHoles')"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.silkscreen')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.silkscreen"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in skillOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.surfaceArtwork')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.surfaceArtwork"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in artworkOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.depanel')">
                    <el-select
                      clearable
                      :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                      v-model="form.depanel"
                      style="width: 214px"
                    >
                      <el-option
                        v-for="item in depanelOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.lengthPcb')">
                    <el-input
                      v-model="form.lengthOfPCB"
                      style="width: 214px"
                      @input="transferFormat($event, 'lengthOfPCB')"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.widthPcb')">
                    <el-input
                      v-model="form.widthOfPCB"
                      style="width: 214px"
                      @input="transferFormat($event, 'widthOfPCB')"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('ccls.ccl.cclIndex')">
                    <el-input
                      v-model="form.coefficient"
                      style="width: 214px"
                      @input="transferFormat($event, 'coefficient')"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8"></el-col>
                <el-col :span="8"></el-col>
                <el-col :span="8">
                  <div class="operate-btn" @click="toCompute('all')">
                    Calcute
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="tcm-content-form" v-show="form.size !== ''">
      <el-form label-width="150px" label-position="left">
        <div style="padding: 0 20px">
          <div>
            <el-form-item :label="$t('ccls.ccl.directCost')">
              <el-input v-model="directValue" disabled style="width: 214px" />
            </el-form-item>
          </div>
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="directHistory"
            border
            style="width: 100%; margin-bottom: 18px"
          >
            <el-table-column
              prop="categoryName"
              :label="$t('ccls.ccl.name')"
              align="center"
            >
              <template #default="scope">
                <div v-show="scope.row.categoryName == '1'">Core</div>
                <div v-show="scope.row.categoryName == '2'">PP</div>
                <div v-show="scope.row.categoryName == '3'">Copper Foil</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="cclType"
              :label="$t('ccls.ccl.type')"
              align="center"
            />
            <el-table-column
              prop="value"
              :label="$t('ccls.ccl.cost')"
              align="center"
            />
            <el-table-column
              prop="ozValue"
              :label="$t('ccls.ccl.qty')"
              align="center"
            />
            <el-table-column
              prop="finalCost"
              :label="$t('ccls.ccl.totalCostList')"
              align="center"
            />
          </el-table>
          <div>
            <el-form-item :label="$t('ccls.ccl.processCost')">
              <el-input v-model="proValue" disabled style="width: 214px" />
            </el-form-item>
          </div>
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="proHistory"
            border
            style="width: 100%; margin-bottom: 18px"
          >
            <el-table-column
              prop="processName"
              :label="$t('ccls.ccl.proName')"
              align="center"
            />
            <!-- <el-table-column prop="programName" label="Type" align="center"/> -->
            <el-table-column
              prop="totalCost"
              :label="$t('ccls.ccl.cost')"
              align="center"
            />
            <el-table-column
              prop="ozValue"
              :label="$t('ccls.ccl.qty')"
              align="center"
            />
            <el-table-column
              prop="finalCost"
              :label="$t('ccls.ccl.totalCostList')"
              align="center"
            />
          </el-table>
        </div>
      </el-form>
      <el-form label-width="250px">
        <!-- <el-row>
					<el-col :span="8">
						<el-form-item :label="$t('ccls.ccl.directCost')">
							<el-input v-model="form.directMaterialCost" disabled style="width: 214px;"/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item :label="$t('ccls.ccl.processCost')">
							<el-input v-model="form.processCost" disabled style="width: 214px;"/>
						</el-form-item>
					</el-col>
				</el-row> -->
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('ccls.ccl.otherCost')">
              <el-input
                v-model="form.otherIndirectFee"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('ccls.ccl.logisticsCost')">
              <el-input
                v-model="form.logistics"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('ccls.ccl.totalCostCCL')">
              <el-input v-model="form.total" disabled style="width: 214px" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('ccls.ccl.totalCost')">
              <el-input v-model="form.total2" disabled style="width: 214px" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('ccls.ccl.pricePc')">
              <el-input v-model="form.price_pc" disabled style="width: 214px" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  GetTotalCost,
  AddOrUpdateCCLSizes,
  GetCCLSizes,
  getRegions,
  GetDirectMaterialList,
  GetTypeListBySize,
  GetHistoryListCCL,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  watch: {
    $route: {
      immediate: true,
      handler(nval) {
        let type = nval.query.type;
        if (type == "edit") this.crumbs = ["CCL Size", "Edit CCL Size"];
        else this.crumbs = ["PCB Calculator"];
      },
    },
  },
  data() {
    return {
      form: {
        id: "",
        nexteerPart: "",
        name: "",
        description: "",
        revisionID: "",
        project: "",
        annualVolume: "",
        regionId: "",
        supplier_Name: "",
        numberOfLayers: "",
        internalCopperThickness: "",
        outerCopperThickness: "",
        materialTG: "",
        prepreg: "",
        viaFilling: "",
        soldermask: "",
        silkscreen: "",
        depanel: "",
        surfaceArtwork: "",
        numberOfHoles: 0,
        lengthOfPCB: 0,
        widthOfPCB: 0,
        viasDiameter: "",
        workingPanelLength: 0,
        workingPanelWidth: 0,
        panelizationQTY: 1,
        otherIndirectFee: 0,
        directMaterialCost: 0,
        processCost: 0,
        logistics: 0,
        managementCharge: 4.5,
        size: "",
        cclLength: 0,
        cclWidth: 0,
        total: 0,
        total2: 0,
        price_pc: 0,
        ozValue: 1,
        materialType: "",
        coefficient: 1,
        profit: 10,
        pcbqtyccl: 0,
        panelQTYCCL: 0,
        panelUtility: 0,
      },
      nameOptions: ["KB Lamination", "SY Lamination"],
      LayersOptions: ["2", "4", "6", "8"],
      InnersOptions: ["0.5 oz", "1 oz", "2 oz", "3 oz"],
      OuterOptions: ["0.5 oz", "1 oz", "2 oz", "3 oz"],
      viasDiameterOptions: [
        {
          id: 0,
          name: "<=0.2mm",
        },
        {
          id: 1,
          name: ">=0.25mm",
        },
      ],
      MaterialOptions: ["170", "150"],
      artworkOptions: ["OSP", "ENIG", "Immersion Tin"],
      PrepregOptions: [],
      // viaOptions: ['Type V', 'Type VII'],
      viaOptions: [
        {
          id: "Type P",
          name: "Type V, soldermask filling",
        },
        {
          id: "Type V",
          name: "Type V, soldermask & resin mixture",
        },
        {
          id: "Type VII",
          name: "Via Type VII( PHP-900 IR-6X )",
        },
      ],
      maskOptions: ["Yes", "No"],
      skillOptions: ["Yes", "No"],
      depanelOptions: ["Regular", "Irregular"],
      typeOptions: [],
      commodityOptions: [],
      // 区域选项
      regionOptions: [],
      // 过程数据
      directValue: "",
      directHistory: [],
      proValue: "",
      proHistory: [],
      crumbs: [],
    };
  },
  mounted() {
    this.getRegion();
    this.getSupplier();
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id;
      this.getDetail();
      this.getHistoryList();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      GetCCLSizes(
        {
          id: this.form.id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.form = response.result;
            this.form.viasDiameter =
              response.result.viasDiameter === "" ||
              response.result.viasDiameter === null
                ? ""
                : parseFloat(response.result.viasDiameter);
            this.form.total2 = (
              parseFloat(this.form.total) /
              ((parseFloat(this.form.cclLength) *
                parseFloat(this.form.cclWidth)) /
                1000000)
            ).toFixed(12);
            this.GetTypeList("1");
            this.GetTypeList("2");
          }
        }
      );
    },
    // 获取供应商选项
    getSupplier() {
      let _this = this;
      GetDirectMaterialList("", (response) => {
        if (response && response.code == 200) {
          if (response.result.length > 0) {
            this.nameOptions = JSON.parse(
              JSON.stringify(response.result[0].directMaterialLst)
            );
          }
        }
      });
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    // 限制
    transferFormat(val, name) {
      this.form[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
      // this.toCompute()
    },
    // 计算
    toCompute(type) {
      // if (type === 'all') {
      if (this.form.id === "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.saveBasicData"),
          "",
          "warning"
        );
        return;
      }
      // }
      GetTotalCost(
        {
          id: this.form.id,
          supplier_Name: this.form.supplier_Name,
          numberOfLayers: this.form.numberOfLayers,
          internalCopperThickness: this.form.internalCopperThickness,
          outerCopperThickness: this.form.outerCopperThickness,
          materialTG:
            this.form.materialTG === "" ? 0 : parseFloat(this.form.materialTG),
          prepreg: this.form.prepreg,
          viaFilling: this.form.viaFilling,
          soldermask: this.form.soldermask,
          silkscreen: this.form.silkscreen,
          depanel: this.form.depanel,
          surfaceArtwork: this.form.surfaceArtwork,
          numberOfHoles:
            this.form.numberOfHoles === ""
              ? 0
              : parseFloat(this.form.numberOfHoles),
          lengthOfPCB:
            this.form.lengthOfPCB === ""
              ? 0
              : parseFloat(this.form.lengthOfPCB),
          widthOfPCB:
            this.form.widthOfPCB === "" ? 0 : parseFloat(this.form.widthOfPCB),
          viasDiameter: this.form.viasDiameter.toString(),
          workingPanelLength:
            this.form.workingPanelLength === ""
              ? 0
              : parseFloat(this.form.workingPanelLength),
          workingPanelWidth:
            this.form.workingPanelWidth === ""
              ? 0
              : parseFloat(this.form.workingPanelWidth),
          panelizationQTY:
            this.form.panelizationQTY === ""
              ? 1
              : parseFloat(this.form.panelizationQTY),
          ozValue: this.form.ozValue,
          materialType: this.form.materialType,
          coefficient:
            this.form.coefficient === ""
              ? 1
              : parseFloat(this.form.coefficient),
          managementCharge:
            this.form.managementCharge === ""
              ? 4.5
              : parseFloat(this.form.managementCharge),
          profit: this.form.profit === "" ? 10 : parseFloat(this.form.profit),
        },
        (response) => {
          if (response && response.code == 200) {
            if (type === "size") {
              if (
                this.form.size !== "" &&
                response.data[0].size != this.form.size &&
                this.form.materialType !== ""
              ) {
                this.form.materialType = "";
                this.form.supplier_Name = "";
                commonUtil.showTipInfo(
                  this.$t("ccls.ccl.chooseCSToCal"),
                  "",
                  "warning"
                );
              }
              this.form.size = response.data[0].size;
              this.form.panelQTYCCL = response.data[0].panelQTYCCL;
              this.form.panelUtility = response.data[0].panelUtility;
              this.form.pcbqtyccl = response.data[0].pcbqtyccl;
              this.form.cclLength = response.data[0].cclLength;
              this.form.cclWidth = response.data[0].cclWidth;
              this.form.pcs = response.data[0].pcs;
              this.GetTypeList("1");
              this.GetTypeList("2");
              this.getHistoryList();
            } else {
              this.form.size = response.data[0].size;
              this.form.panelQTYCCL = response.data[0].panelQTYCCL;
              this.form.panelUtility = response.data[0].panelUtility;
              this.form.pcbqtyccl = response.data[0].pcbqtyccl;
              this.form.cclLength = response.data[0].cclLength;
              this.form.cclWidth = response.data[0].cclWidth;
              this.form.directMaterialCost =
                response.data[0].directMaterialCost;
              this.form.logistics = response.data[0].logistics;
              this.form.managementCharge = response.data[0].managementCharge;
              this.form.otherIndirectFee = response.data[0].otherIndirectFee;
              this.form.price_pc = response.data[0].price_pc;
              this.form.processCost = response.data[0].processCost;
              this.form.total = response.data[0].total;
              this.form.total2 = (
                parseFloat(response.data[0].total) /
                ((parseFloat(response.data[0].cclLength) *
                  parseFloat(response.data[0].cclWidth)) /
                  1000000)
              ).toFixed(12);
              this.form.pcs = response.data[0].pcs;
              this.submit("calcute");
            }
          }
        }
      );
    },
    // 获取计算过程数据
    getHistoryList() {
      GetHistoryListCCL(
        {
          id: this.form.id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.directValue = response.result.directMaterialCost;
            this.directHistory = response.result.materialList;
            this.proValue = response.result.processCost;
            this.proHistory = response.result.processList;
          }
        }
      );
    },
    // 根据cclsize获取下拉选项
    GetTypeList(type) {
      GetTypeListBySize(
        {
          rowCategory: type,
          rowSize: type == "1" ? this.form.size : "",
        },
        (response) => {
          if (response && response.code == 200) {
            if (type == "1") {
              this.typeOptions = response.result;
            } else {
              this.PrepregOptions = response.result;
            }
          }
        }
      );
    },
    // 提交
    submit(type) {
      if (this.form.nexteerPart == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.enterNexteerPart"),
          "",
          "warning"
        );
        return;
      }
      AddOrUpdateCCLSizes(this.form, (response) => {
        if (response && response.code == 200) {
          this.form.id = response.result;
          if (type == "calcute") {
            this.getHistoryList();
          }
          // this.$router.push('/CCLsize')
        }
      });
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.tcm-content-form {
  margin-top: 30px;
  /* padding: 0 40px 30px 40px; */
  padding-bottom: 30px;
  position: relative;
}
.border-bottom {
  border-bottom: 1px solid #9a9a9a;
}
.jt-img {
  width: 50px;
  height: 50px;
}

.tcm-add {
  min-height: 100%;
  padding-top: 20px;
  position: relative;
  background: #fff;
}
.tcm-title {
  position: relative;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #9a9a9a;
}
.hover-menu {
  width: 80px;
  height: 120px;
  padding: 15px 0;
  box-sizing: border-box;
  background-color: rgba(215, 215, 215, 0.474509803921569);
  border-radius: 5px;
  position: fixed;
  top: 150px;
  right: 0;
  z-index: 888;
}
.hover-btn {
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  color: #fff;
  background: rgba(174, 174, 174, 0.623529411764706);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  margin-left: 30px;
  cursor: pointer;
}
.choose-btn {
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  margin-left: 30px;
  text-align: center;
  border-radius: 0px 5px 5px 0;
  padding: 8px 0;
  background-color: #fff;
  border-left: 4px solid var(--themeColor);
  cursor: pointer;
}
.operate-cla {
  position: absolute;
  right: 10px;
  top: 12px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.submit-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
</style>