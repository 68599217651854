import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import store from '../views/store'
import Login from '../pages/login/login.vue'
import Home from '../pages/home.vue'
import HomePage from '../pages/homepage/homepage.vue'
import Tcmlist from '../pages/tcmApplication/tcmlist.vue'
import TcmCC from '../pages/tcmApplication/tcmCC.vue'
import TcmAdd from '../pages/tcmApplication/tcmAdd.vue'
import ApplicationDetail from '../pages/tcmApplication/applicationDetail.vue'
import TcmCompare from '../pages/tcmApplication/tcmCompare.vue'
import ProcessCalculatorManagement from '../pages/processCalculatorManagement/processCalculatorList.vue'
import AddCalculator from '../pages/processCalculatorManagement/addCalculator.vue'
import TcmDatabaseList from '../pages/tcmDatabase/tcmlist.vue'
import TemplateList from '../pages/tcmTemplateManagement/templateList.vue'
import AddTemplate from '../pages/tcmTemplateManagement/addTemplate.vue'
import PreviewTemplate from '../pages/tcmTemplateManagement/previewTemplate.vue'
import UserList from '../pages/userManagement/userlist.vue'
import RoleList from '../pages/roleManagement/roleList.vue'
import GroupList from '../pages/userGroupManagement/userList.vue'
import ParameterPage from '../pages/parameterManagement/parameterPage.vue'
import OperateList from '../pages/tcmOperation/operateList.vue'
import Approver from '../pages/tcmApprover/approver.vue'
import apprcverSUM from '../pages/tcmApprover/apprcverSUM.vue'
import AddOperate from '../pages/tcmOperation/addOperate.vue'
import OpePreview from '../pages/tcmOperation/previewInfo.vue'
import DraftList from '../pages/myDraft/draftList.vue'
import EditDraft from '../pages/myDraft/editDraft.vue'
import RawList from '../pages/rawManagement/rawList.vue'
import AddRaw from '../pages/rawManagement/addRaw.vue'
import ProList from '../pages/processingManagement/proList.vue'
import AddPro from '../pages/processingManagement/addPro.vue'
import PurList from '../pages/purManagement/purList.vue'
import AddPur from '../pages/purManagement/addPur.vue'
import DepartmentManagement from '../pages/divisionManagements/divisionManagement.vue'
import addDivision from '../pages/divisionManagements/addDivision.vue'
import editDivision from '../pages/divisionManagements/editDivision.vue'
import RegionList from '../pages/regionManagement/regionList.vue'
import TypeList from '../pages/typeManagement/typeList.vue'
import RateList from '../pages/rateManagement/rateList.vue'
import commoditylist from '../pages/commodityManagement/commoditylist.vue'
import modulelist from '../pages/moduleManagement/modulelist.vue'
import CurrencyList from '../pages/currencyManagement/currencyList.vue'
import tcmdatalist from '../pages/tcmdata/tcmdatalist.vue'
import importList from '../pages/tcmdata/ImportList.vue'
import CCLsize from '../pages/CCLsize/CCLsize.vue'
import CCLsizeDetail from '../pages/CCLsize/CCLsizeDetail.vue'
import calculator from '../pages/calculator/calculator.vue'
import pcbCalculator from '../pages/calculator/pcbCalculator.vue'
import tcmResult from '../pages/tcmOperation/tcmResult.vue'
import DirectMaterial from '../pages/CCLsize/DirectMaterial.vue'
import DirectMaterialModified from '../pages/CCLsize/DirectMaterialModified.vue'
import ProcessIndirect from '../pages/CCLsize/ProcessIndirect.vue'
import gradelist from '../pages/Grade/gradelist.vue'
import OperationDetailsList from '../pages/OperationDetails/OperationDetailsList.vue'
import tonnagelist from '../pages/Tonnage/tonnagelist.vue'
import TonnageCalculatorlist from '../pages/TonnageCalculator/TonnageCalculatorlist.vue'
import allTonnageList from '../pages/TonnageCalculator/allTonnageList.vue'
import CalculatedDatelist from '../pages/CalculatedDate/CalculatedDateList.vue'
import ChangePriceList from '../pages/priceManagement/changePriceList.vue'
import ChangePrice from '../pages/priceManagement/changePrice.vue'
import MyTemplateList from '../pages/myTemplate/myTemplateList.vue'
import yesPriceList from '../pages/pcbaManagement/yesPrice/yesPricing.vue'
import addYesPrice from '../pages/pcbaManagement/yesPrice/addYesPrice.vue'
import eBomList from '../pages/pcbaManagement/eBom/eBomList.vue'
import eBomDetail from '../pages/pcbaManagement/eBom/eBomDetail.vue'
import noPriceList from '../pages/pcbaManagement/noPrice/noPricing.vue'
import tonnage from '../pages/plasticManagement/tonnage.vue'
import partWeight from '../pages/plasticManagement/partWeight.vue'
import cycleTime from '../pages/plasticManagement/cycleTime.vue'
import circleTime from '../pages/plasticManagement/circleTime.vue'
import bracketWeight from '../pages/bracketWeight/bracketWeight.vue'
import ctList from '../pages/ctManagement/ct/ctList.vue'
import ctDetailList from '../pages/ctManagement/ctDetail/ctDetailList.vue'
import toolList from '../pages/ctManagement/tool/toolList.vue'
import equimentList from '../pages/ctManagement/equiment/equimentList.vue'
import EditTcmData from '../pages/tcmdata/editTcmData.vue'
import NewPage from '@/pages/tcmTemplateManagement/NewPage.vue'
// 二期
import supplierCollectList from '../pages/phaseII/supplierCollect/supplierCollectList.vue'
import supplierCoAdd from '../pages/phaseII/supplierCollect/supplierCoAdd.vue'
import supplierAccount from '../pages/phaseII/supplierCollect/supplierAccount.vue'

const routerHistory = createWebHashHistory()
const router = createRouter({
	history: routerHistory,
	routes: [{
		path: '/',
		component: Home,
		redirect: '/login',
		children: [{
			name: 'HomePage',
			path: '/homePage',
			fullPath: '/homePage',
			component: HomePage,
			meta: {
				"title": "HomePage",
				"zhTitle": "首页",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'Tcmlist',
			path: '/tcmlist',
			fullPath: '/tcmlist',
			component: Tcmlist,
			meta: {
				"title": "TCM Application",
				"zhTitle": "申请",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'TcmCC',
			path: '/tcmCC',
			fullPath: '/tcmCC',
			component: TcmCC,
			meta: {
				"title": "TCM CC",
				"zhTitle": "抄送",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'TcmAdd',
			path: '/tcmAdd',
			component: TcmAdd,
			meta: {
				"title": "Add Application",
				"zhTitle": "新增申请单",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": true
			},
		}, {
			name: 'ApplicationDetail',
			path: '/applicationDetail',
			fullPath: '/applicationDetail',
			component: ApplicationDetail,
			meta: {
				"title": "Application Detail",
				"zhTitle": "申请单详情",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": true
			},
		}, {
			name: 'ProcessCalculatorManagement',
			path: '/processCalculatorManagement',
			component: ProcessCalculatorManagement,
			meta: {
				"title": "Process Calculator",
				"zhTitle": "工艺计算器",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'AddCalculator',
			path: '/addCalculator',
			component: AddCalculator,
			meta: {
				"title": "Add Calculator",
				"zhTitle": "新增工艺计算器",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'TcmDatabaseList',
			path: '/tcmDatabaseList',
			component: TcmDatabaseList,
			meta: {
				"title": "TCM Database List",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'TemplateList',
			path: '/templateList',
			component: TemplateList,
			meta: {
				"title": "Template",
				"zhTitle": "模板管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'AddTemplate',
			path: '/addTemplate',
			component: AddTemplate,
			meta: {
				"title": "Add Template",
				"zhTitle": "新增模板",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'PreviewTemplate',
			path: '/previewTemplate',
			component: PreviewTemplate,
			meta: {
				"title": "Preview Template",
				"zhTitle": "预览模板",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'UserList',
			path: '/userlist',
			component: UserList,
			meta: {
				"title": "User Management",
				"zhTitle": "用户管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'RoleList',
			path: '/roleList',
			component: RoleList,
			meta: {
				"title": "Role Management",
				"zhTitle": "角色管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'GroupList',
			path: '/groupList',
			component: GroupList,
			meta: {
				"title": "Group Management",
				"zhTitle": "用户组管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'ParameterPage',
			path: '/parameterPage',
			component: ParameterPage,
			meta: {
				"title": "Parameter Page",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'OperateList',
			path: '/operateList',
			component: OperateList,
			meta: {
				"title": "TCM Operation",
				"zhTitle": "操作",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'Approver',
			path: '/Approver',
			component: Approver,
			meta: {
				"title": "Tcm Approval",
				"zhTitle": "审批",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'AddOperate',
			path: '/addOperate',
			component: AddOperate,
			meta: {
				"title": "Operate",
				"zhTitle": "操作编辑",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'apprcverSUM',
			path: '/apprcverSUM',
			component: apprcverSUM,
			meta: {
				"title": "Approval",
				"zhTitle": "审批",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'OpePreview',
			path: '/opePreview',
			component: OpePreview,
			meta: {
				"title": "OpePreview",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'DraftList',
			path: '/draftList',
			component: DraftList,
			meta: {
				"title": "My Draft",
				"zhTitle": "我的草稿",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'EditDraft',
			path: '/editDraft',
			component: EditDraft,
			meta: {
				"title": "Edit Draft",
				"zhTitle": "编辑草稿",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'TcmCompare',
			path: '/tcmCompare',
			component: TcmCompare,
			meta: {
				"title": "TCM Compare",
				"zhTitle": "对比",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'RawList',
			path: '/rawList',
			component: RawList,
			meta: {
				"title": "Material Module",
				"zhTitle": "物料模板",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'AddRaw',
			path: '/addRaw',
			component: AddRaw,
			meta: {
				"title": "Add Material Module",
				"zhTitle": "新增物料模板",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'ProList',
			path: '/proList',
			component: ProList,
			meta: {
				"title": "Processing Module",
				"zhTitle": "工艺模板",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'AddPro',
			path: '/addPro',
			component: AddPro,
			meta: {
				"title": "Add Processing Module",
				"zhTitle": "新增工艺模板",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'PurList',
			path: '/purList',
			component: PurList,
			meta: {
				"title": "Components",
				"zhTitle": "采购模板",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'AddPur',
			path: '/addPur',
			component: AddPur,
			meta: {
				"title": "Add Components",
				"zhTitle": "新增采购模板",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		},
		{
			name: 'DepartmentManagement',
			path: '/divisionManagement',
			component: DepartmentManagement,
			meta: {
				"title": "Department Management",
				"zhTitle": "部门管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'addDivision',
			path: '/addDivision',
			component: addDivision,
			meta: {
				"title": "Add Division",
				"zhTitle": "新增部门",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'editDivision',
			path: '/editDivision',
			component: editDivision,
			meta: {
				"title": "Edit Division",
				"zhTitle": "编辑部门",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'RegionList',
			path: '/regionList',
			component: RegionList,
			meta: {
				"title": "Region Management",
				"zhTitle": "区域管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'TypeList',
			path: '/typeList',
			component: TypeList,
			meta: {
				"title": "Request Quote Type",
				"zhTitle": "报价类型",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'RateList',
			path: '/rateList',
			component: RateList,
			meta: {
				"title": "ExchangeRate",
				"zhTitle": "税率管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'commoditylist',
			path: '/commoditylist',
			component: commoditylist,
			meta: {
				"title": "CommodityManagement",
				"zhTitle": "商品分类管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'modulelist',
			path: '/modulelist',
			component: modulelist,
			meta: {
				"title": "Modules Management",
				"zhTitle": "模块管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'CurrencyList',
			path: '/currencyList',
			component: CurrencyList,
			meta: {
				"title": "Currency Management",
				"zhTitle": "货币管理",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'tcmdatalist',
			path: '/tcmdatalist',
			component: tcmdatalist,
			meta: {
				"title": "TCM Data",
				"zhTitle": "TCM 数据库",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'importList',
			path: '/importList',
			component: importList,
			meta: {
				"title": "Import List",
				"zhTitle": "TCM 导入列表",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'CCLsize',
			path: '/CCLsize',
			component: CCLsize,
			meta: {
				"title": "CCL Size",
				"zhTitle": "CCL尺寸",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'CCLsizeDetail',
			path: '/CCLsizeDetail',
			component: CCLsizeDetail,
			meta: {
				"title": "CCL Size Detail",
				"zhTitle": "CCL尺寸详情",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'calculator',
			path: '/calculator',
			component: pcbCalculator,
			meta: {
				"title": "Calculator",
				"zhTitle": "计算器",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			}
		}, {
			name: 'tcmResult',
			path: '/tcmResult',
			component: tcmResult,
			meta: {
				"title": "TCM Result",
				"zhTitle": "详情",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'DirectMaterial',
			path: '/directMaterial',
			component: DirectMaterialModified,
			meta: {
				"title": "DirectMaterial",
				"zhTitle": "直接材料",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'ProcessIndirect',
			path: '/ProcessIndirect',
			component: ProcessIndirect,
			meta: {
				"title": "Indirect Process",
				"zhTitle": "间接工序",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'gradelist',
			path: '/gradelist',
			component: gradelist,
			meta: {
				"title": "Grade",
				"zhTitle": "等级",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'OperationDetailsList',
			path: '/OperationDetailsList',
			component: OperationDetailsList,
			meta: {
				"title": "Operation Details",
				"zhTitle": "操作详情",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'tonnagelist',
			path: '/tonnagelist',
			component: tonnagelist,
			meta: {
				"title": "Tonnage",
				"zhTitle": "吨位",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'TonnageCalculatorlist',
			path: '/TonnageCalculatorlist',
			component: TonnageCalculatorlist,
			meta: {
				"title": "Tonnage Calculator Detail",
				"zhTitle": "Tonnage Calculator Detail",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'allTonnageList',
			path: '/allTonnageList',
			component: allTonnageList,
			meta: {
				"title": "Tonnage Calculator",
				"zhTitle": "Tonnage Calculator",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'CalculatedDatelist',
			path: '/CalculatedDatelist',
			component: CalculatedDatelist,
			meta: {
				"title": "Calculated Date List",
				"zhTitle": "Calculated Date List",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'ChangePriceList',
			path: '/changePriceList',
			component: ChangePriceList,
			meta: {
				"title": "Change Price List",
				"zhTitle": "CBD改价",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'ChangePrice',
			path: '/changePrice',
			component: ChangePrice,
			meta: {
				"title": "Change Price",
				"zhTitle": "改价",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": true
			},
		}, {
			name: 'MyTemplateList',
			path: '/myTemplateList',
			component: MyTemplateList,
			meta: {
				"title": "My Template",
				"zhTitle": "个人模板",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		},
		{
			name: 'EditTcmData',
			path: '/EditTcmData',
			component: EditTcmData,
			meta: {
				"title": "Edit Tcm",
				"zhTitle": "Edit Tcm",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		},
		{
			name: 'yesPriceList',
			path: '/yesPriceList',
			component: yesPriceList,
			meta: {
				"title": "YES Price",
				"zhTitle": "YES Price",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'addYesPrice',
			path: '/addYesPrice',
			component: addYesPrice,
			meta: {
				"title": "Add YES Price",
				"zhTitle": "Add YES Price",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'eBomList',
			path: '/eBomList',
			component: eBomList,
			meta: {
				"title": "eBom List",
				"zhTitle": "eBom List",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'eBomDetail',
			path: '/eBomDetail',
			component: eBomDetail,
			meta: {
				"title": "eBom Detail",
				"zhTitle": "eBom Detail",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'noPriceList',
			path: '/noPriceList',
			component: noPriceList,
			meta: {
				"title": "No Price",
				"zhTitle": "No Price",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'tonnage',
			path: '/tonnage',
			component: tonnage,
			meta: {
				"title": "tonnage",
				"zhTitle": "tonnage",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'partWeight',
			path: '/partWeight',
			component: partWeight,
			meta: {
				"title": "partWeight",
				"zhTitle": "partWeight",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'cycleTime',
			path: '/cycleTime',
			component: cycleTime,
			meta: {
				"title": "cycleTime",
				"zhTitle": "cycleTime",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'circleTime',
			path: '/circleTime',
			component: circleTime,
			meta: {
				"title": "circleTime",
				"zhTitle": "circleTime",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'bracketWeight',
			path: '/bracketWeight',
			component: bracketWeight,
			meta: {
				"title": "bracketWeight",
				"zhTitle": "bracketWeight",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'ctList',
			path: '/ctList',
			component: ctList,
			meta: {
				"title": "CT Calculator List",
				"zhTitle": "CT Calculator List",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'ctDetailList',
			path: '/ctDetailList',
			component: ctDetailList,
			meta: {
				"title": "CT Calculator",
				"zhTitle": "CT Calculator",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'toolList',
			path: '/toolList',
			component: toolList,
			meta: {
				"title": "Custom Tool List",
				"zhTitle": "Custom Tool List",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'equimentList',
			path: '/equimentList',
			component: equimentList,
			meta: {
				"title": "Custom Equiment List",
				"zhTitle": "Custom Equiment List",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'supplierCollectList',
			path: '/supplierCollectList',
			component: supplierCollectList,
			meta: {
				"title": "Supplier Collect List",
				"zhTitle": "Supplier Collec tList",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'supplierCoAdd',
			path: '/supplierCoAdd',
			component: supplierCoAdd,
			meta: {
				"title": "Add Supplier",
				"zhTitle": "Add Supplier",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		}, {
			name: 'supplierAccount',
			path: '/supplierAccount',
			component: supplierAccount,
			meta: {
				"title": "Supplier Account",
				"zhTitle": "Supplier Account",
				"requireAuth": false,
				"showHeader": true,
				"showNav": true,
				"showtag": true,
				"showPosition": true,
				"keepAlive": false
			},
		},
		]
	}, {
		name: 'Login',
		path: '/login',
		component: Login,
		meta: {
			requireAuth: true
		},
	},

	{
		name: "blank",
		path: "/blank",
	}]
})
router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (sessionStorage.getItem('token') && sessionStorage.getItem('token') != '') { //判断本地是否存在access_token
			next();
		} else {
			if (to.path === '/login') {
				store.commit('ADD_KEEP_ALIVE', to)
				next();
			} else {
				next({
					path: '/login'
				})
			}
		}
	} else {
		store.commit('ADD_KEEP_ALIVE', to)
		next();
	}
	/*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
	if (to.fullPath == "/login") {
		if (sessionStorage.getItem('token') && sessionStorage.getItem('token') != '') {
			next({
				path: from.fullPath
			});
		} else {
			store.commit('ADD_KEEP_ALIVE', to)
			next();
		}
	}
});
export default router