<template>
  <el-dialog
    v-model="visible"
    width="70%"
    :show-close="false"
    destroy-on-close
    @close="close"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("unifiedPrompts.unifiedPrompt.selectTCM") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div style="height: 500px">
      <el-col :span="24">
        <table style="margin-bottom: 10px">
          <tr>
            <td style="width: 260px">
              <el-input v-model="searchForm.Keyword" placeholder="keywords" />
            </td>
            <td style="width: 260px">
              <el-button
                style="
                  background-color: var(--themeColor);
                  color: var(--menuTextActive);
                  margin-right: 16px;
                "
                @click="search"
                >{{ $t("userlists.userlist.search") }}</el-button
              >
            </td>
          </tr>
        </table>
      </el-col>
      <div class="regional_table">
        <el-table
          stripe
          :empty-text="$t('tcmlists.tcmlist.noData')"
          :data="tableData"
          border
          :row-key="
            (row) => {
              return row.id;
            }
          "
          @selection-change="handleSelectionChange"
          height="400"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            reserve-selection
            width="55"
            align="center"
          ></el-table-column>
          <!-- <el-table-column prop="select" label="Select" width="110" align="center">
					<template #default="scope">
						<el-button color="#335883" @click="select(scope.row.id)">select</el-button>
					</template>
				</el-table-column> --><el-table-column
            prop="commodityName"
            :label="$t('tcmdatalists.tcmdatalist.commodity')"
            width="110"
            align="center"
          />
          <!-- <el-table-column
            prop="documentNo"
            :label="$t('tcmdatalists.tcmdatalist.documentNO')"
            align="center"
          /> -->
          <el-table-column
            prop="tcmOwner"
            :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
            align="center"
          />
          <el-table-column
            prop="partDescription"
            :label="$t('tcmdatalists.tcmdatalist.partDescription')"
            align="center"
          />
          <el-table-column
            prop="partNumber"
            :label="$t('tcmdatalists.tcmdatalist.partNumber')"
            width="120"
            align="center"
          />
          <el-table-column
            prop="date"
            :label="$t('tcmdatalists.tcmdatalist.startDate')"
            sortable
            width="125"
            align="center"
          />
          <el-table-column
            prop="approvedTime"
            :label="$t('tcmdatalists.tcmdatalist.dueDate')"
            sortable
            width="120"
            align="center"
          />
          <el-table-column
            prop="urgency"
            :label="$t('tcmdatalists.tcmdatalist.priority')"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="scope.row.urgency == 'High'" class="color-ff0000">
                {{ $t("tcmdatalists.tcmdatalist.high") }}
              </div>
              <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23">
                {{ $t("tcmdatalists.tcmdatalist.middle") }}
              </div>
              <div v-show="scope.row.urgency == 'Low'">
                {{ $t("tcmdatalists.tcmdatalist.low") }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="quoteType"
            :label="$t('tcmdatalists.tcmdatalist.quotationType')"
            align="center"
          />
          <el-table-column
            prop="tcmRequest"
            :label="$t('tcmdatalists.tcmdatalist.tcmOwner')"
            width="120"
            align="center"
          />

          <el-table-column
            prop="version"
            :label="$t('templateLists.templateList.version')"
            width="120"
            align="center"
          />
        </el-table>
      </div>
      <div class="pagination">
        <p>{{ total }} data</p>
        <el-pagination
          layout="prev, pager, next"
          background
          :total="total"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{
          $t("userlists.userlist.cancel")
        }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="toConfirm"
          >{{ $t("userlists.userlist.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { getApplicationList } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: ["currencyId"],
  emits: ["selectTCM"],
  data() {
    return {
      visible: false,
      searchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "6",
        Keyword: "",
        PageType: 3,
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
        isDisable: 0,
      },
      // 总页数
      total: 0,
      tableData: [],
      chooseData: [],
    };
  },
  mounted() {},
  methods: {
    getlist() {
      this.search();
    },
    // 查询
    search() {
      getApplicationList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 表格勾选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 选择
    // select(id) {
    // 	this.$emit('selectTCM', id)
    // 	this.close()
    // },
    close() {
      this.visible = false;
      this.chooseData = [];
    },
    toConfirm() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseChooseCell"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      this.$emit("selectTCM", ids);
      // this.$emit('getChooseRawId', this.chooseData.id)
      this.chooseData = [];
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>