<template>
  <el-dialog
    v-model="visible"
    width="80%"
    top="10vh"
    destroy-on-close
    :show-close="false"
  >
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">History</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        prop="flowStepName"
        :label="$t('components.AuditComments.处理步骤')"
        width="180"
      />
      <el-table-column
        prop="flowOwnerName"
        :label="$t('components.AuditComments.签名')"
        width="180"
      />
      <el-table-column
        prop="flowStatusText"
        :label="$t('components.AuditComments.操作')"
        width="180"
      />
      <el-table-column
        prop="flowTime"
        :label="$t('components.AuditComments.日期')"
        width="180"
      />
      <el-table-column
        prop="remark"
        :label="$t('components.AuditComments.备注')"
      />
    </el-table>
    <!-- <el-pagination layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page="pageIndex" @current-change="handleCurrentChange" />-->
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">Close</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { getHistory } from "../../assets/js/requestApi.js";
export default {
  props: {},

  data() {
    return {
      pageIndex: 0,
      pageSize: 10,
      visible: false,
      tableData: [],
      total: 0,
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    getList(id) {
      getHistory(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.tableData = response.data;
          }
        }
      );
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      var that = this;
      this.$emit("PageChange", {
        pageIndex: that.pageIndex,
        pageSize: that.pageSize,
      });
    },
  },
};
</script>

<style  scoped lang="scss">
.my-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>
