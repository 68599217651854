<template>
  <el-dialog v-model="visible" width="70%" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">{{ $t("ccls.ccl.previewCal") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form :model="inputform" label-width="auto">
            <el-form-item
              v-for="(item, index) in valueList"
              :label="item.parameterName"
            >
              <el-input
                v-model="item.thedefaultvalue"
                @input="
                  (v) => (item.thedefaultvalue = v.replace(/[^\d.]/g, ''))
                "
                style="width: 214px"
              />
            </el-form-item>
            <el-form-item
              v-for="(item, index) in intermediateBondList"
              :label="item.parameterName"
            >
              <el-input
                v-model="item.thedefaultvalue"
                disabled
                style="width: 214px"
              />
            </el-form-item>

            <el-form-item label=" ">
              <div style="">
                <div class="complate-cla" @click="compute">
                  <img
                    src="../../assets/img/tcm/jiantou_yemian_xiangxia.png"
                    class="complate-cla-img"
                  />
                  {{ $t("ccls.ccl.computeCal") }}
                </div>
              </div>
            </el-form-item>
            <el-form-item :label="previewName">
              <el-input
                v-model="previewResultx"
                style="width: 214px"
                disabled
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="16">
          <div class="img-cla">
            <img
              v-for="(item, index) in fileListUrl"
              :key="index"
              :src="item.url"
              style="width: 100%"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          v-show="type == 'preview'"
          @click="close"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          v-show="type == 'template'"
          @click="toConfirm"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: [
    "formulaPre",
    "previewResultx",
    "fileListUrl",
    "valueList",
    "intermediateBondList",
    "type",
    "previewName",
  ],
  emits: ["tocompute", "toConfirm"],
  data() {
    return {
      visible: false,
      // 表单数据
      inputform: {
        name: "",
        value: "",
        unit: "",
        formula: "",
        weight: "",
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    // 计算
    compute() {
      let list = [];
      this.valueList.forEach(function (val, key) {
        list.push({
          paramValue: val.thedefaultvalue,
          paramentName: val.parameterName,
        });
      });
      this.intermediateBondList.forEach(function (val, key) {
        list.push({
          paramValue: val.formulaPre,
          paramentName: val.parameterName,
        });
      });
      let data = {
        outputFormula: this.formulaPre,
        inputParameterList: list,
      };
      this.$emit("tocompute", data, this.valueList, this.intermediateBondList);
    },
    toConfirm() {
      this.$emit("toConfirm", this.previewResultx);
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.img-cla {
  width: 100%;
  border-left: 1px solid;
  padding: 0 10px;
  box-sizing: border-box;
  max-height: 400px;
  overflow-y: auto;
}
.complate-cla {
  background: #f2f2f2;
  border-radius: 5px;
  padding: 0 10px 0 35px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 32px;
  display: inline-block;
}
.complate-cla-img {
  width: 20px;
  position: absolute;
  top: 7px;
  left: 10px;
  cursor: pointer;
}
</style>