<template>
  <div style="background: #fff">
    <div class="tcm-title">
      <div class="title flex" style="align-items: center; padding: 0">
        <el-icon @click="GoBack" style="padding-right: 10px"><Back /></el-icon>
        {{ $t("homePage.home.task") }}
        <span style="padding-left: 10px; font-weight: 500; color: #909399">{{
          partDescription
        }}</span>
      </div>
      <div class="flex">
        <div class="bts" v-show="this.status == 5">
          {{ $t("changePriceLists.changePriceList.rejected") }}
        </div>
        <div class="bts" v-show="this.status == 6" style="margin-left: 10px">
          {{ $t("changePriceLists.changePriceList.approved") }}
        </div>
      </div>
    </div>
    <div class="tcm-content">
      <div class="tcm-content-title">
        {{ $t("changePriceLists.changePriceList.rawMaterial") }}
        <div class="tcm-content-rawPic">
          <img
            src="http://vemtest.nexteerchina.cn:81/UploadFile/logo.png"
            alt=""
          />
          <!-- 
          <img
            src="http://vemtest.nexteerchina.cn:81/UploadFile/logo.png"
            alt=""
          /> -->
          <el-image
            v-for="(item, index) in previewData.attachmentList"
            style="width: 40px; height: 40px; margin-right: 10px"
            :src="item.url"
            :zoom-rate="1.2"
            :preview-src-list="attachmentList"
            :initial-index="index"
            fit="cover"
          />
        </div>
      </div>
      <div class="tcm-content-form">
        <table>
          <tr>
            <td style="width: 150px">
              {{ $t("changePriceLists.changePriceList.materialDescription") }}
            </td>
            <td v-for="item in previewData.materialLine">
              <el-input v-model="item.description" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.netWeight") }}</td>
            <td v-for="item in previewData.materialLine">
              <el-input v-model="item.netMWeight" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.grossWeight") }}</td>
            <td v-for="item in previewData.materialLine">
              <el-input v-model="item.grossMWeight" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.unitMeasure") }}</td>
            <td v-for="item in previewData.materialLine">
              <el-select
                clearable
                v-model="item.unitMeasureId"
                disabled
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              >
                <el-option
                  v-for="item in unitOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.materialMeasure") }}
            </td>
            <td v-for="item in previewData.materialLine">
              <el-input v-model="item.unitCost" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.scrapPrice") }}</td>
            <td v-for="item in previewData.materialLine">
              <el-input v-model="item.unitResaleValue" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.country") }}</td>
            <td v-for="item in previewData.materialLine">
              <el-input v-model="item.country" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.materialCost") }}</td>
            <td v-for="item in previewData.materialLine">
              <el-input v-model="item.cost" disabled />
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title mgt-10">
        {{ $t("changePriceLists.changePriceList.purchasedComponents") }}
      </div>
      <div class="tcm-content-form">
        <table>
          <tr>
            <td style="width: 150px">
              {{ $t("changePriceLists.changePriceList.processDescription") }}
            </td>
            <td v-for="item in previewData.purchaseLine">
              <el-input v-model="item.name" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.consumption") }}</td>
            <td v-for="item in previewData.purchaseLine">
              <el-input v-model="item.quantity" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.unitMeasure") }}</td>
            <td v-for="item in previewData.purchaseLine">
              <el-select
                clearable
                v-model="item.unit"
                disabled
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              >
                <el-option
                  v-for="item in unitOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.costUoM") }}</td>
            <td v-for="item in previewData.purchaseLine">
              <el-input v-model="item.unitCost" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.subTotalCost") }}</td>
            <td v-for="item in previewData.purchaseLine">
              <el-input v-model="item.totalCost" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.priceReference") }}</td>
            <td v-for="item in previewData.purchaseLine">
              <el-input v-model="item.price" disabled />
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title mgt-10">
        {{ $t("changePriceLists.changePriceList.processing") }}
      </div>
      <div class="tcm-content-form">
        <table>
          <tr>
            <td style="width: 150px">
              <div class="form-type-title">
                {{ $t("changePriceLists.changePriceList.setUp") }}# 1
              </div>
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ $t("changePriceLists.changePriceList.annualVolume") }}
            </td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.annualVolume" disabled />
            </td>
          </tr>
          <tr>
            <td style="width: 150px">
              {{ $t("changePriceLists.changePriceList.setUpTime") }}
            </td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.setTime" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.setUpPersons") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.setPerson" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.piecesPerLot") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.pieceLot" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.setUpCosts") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.pieceCost" disabled />
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-type-title">
                {{ $t("changePriceLists.changePriceList.operation") }} # 1
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.operationDescription") }}
            </td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.name" disabled />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.descriptionSize") }}
            </td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.equSize" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.consumption") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.quantity" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.piecesHour") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.pieceHour" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.piecesPer") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.pieceCycle" disabled />
            </td>
          </tr>
          <tr>
            <td>
              {{
                $t("changePriceLists.changePriceList.manufacturingEfficiency")
              }}
            </td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.efficiencyPercent" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.standardHour") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.standardHour" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.directRate") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.directRate" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.setUpRate") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.setUpRate" disabled />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.directOperators") }}
            </td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.directOperateQty" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.directAssembly") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.assemblyDirectCost" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.burdenVariable") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.variableRate" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.burdenRate") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.fixedRate" disabled />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.burdenDepreciation") }}
            </td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.depreciationRate" disabled />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("changePriceLists.changePriceList.totalBurdenRate") }}
            </td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.totalBurdenRate" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.burdenCost") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.assemblyBurdenCost" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.subTotalCost") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.totalCost" disabled />
            </td>
          </tr>
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.processScrap") }}</td>
            <td v-for="item in previewData.processLine">
              <el-input v-model="item.scrapPercent" disabled />
            </td>
          </tr>
        </table>
      </div>
      <div class="tcm-content-title mgt-10">
        {{ $t("changePriceLists.changePriceList.dutiesCustoms") }}
      </div>
      <div class="tcm-content-form">
        <el-form :model="previewData" label-width="200px">
          <!-- <el-row>
                        <el-col>
                            <el-form-item :label="$t('changePriceLists.changePriceList.calculationMethod')">
                                <el-select clearable  v-model="previewData.calculationMethod" disabled :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                                    <el-option v-for="item in calculationMethodOptions" :key="item.value" :label="item.label" :value="item.value" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item :label="$t('changePriceLists.changePriceList.freightCost')">
                                <el-input v-model="previewData.freightCost" disabled style="width: 214px;" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('changePriceLists.changePriceList.wareCost')">
                                <el-input v-model="previewData.wareHouseCost" disabled style="width: 214px;" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('changePriceLists.changePriceList.packagingCost')">
                                <el-input v-model="previewData.packageCost" disabled style="width: 214px;" />
                            </el-form-item>
                        </el-col>
                    </el-row> -->
          <el-row>
            <el-col>
              <el-form-item
                :label="$t('changePriceLists.changePriceList.freightCost')"
              >
                <el-input
                  v-model="previewData.freightCost"
                  disabled
                  style="width: 214px; margin-right: 10px"
                >
                  <template #suffix>
                    <el-icon
                      class="el-input__icon"
                      v-show="previewData.calculationMethod_Freight == 2"
                      >%</el-icon
                    >
                  </template>
                </el-input>
                <el-select
                  clearable
                  v-if="
                    $t(
                      'changePriceLists.changePriceList.calculationMethodOption'
                    ) == 'calculationMethodOption'
                  "
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="previewData.calculationMethod_Freight"
                  disabled
                >
                  <el-option
                    v-for="item in calculationMethodOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-select
                  clearable
                  v-if="
                    $t(
                      'changePriceLists.changePriceList.calculationMethodOption'
                    ) == 'calculationMethodOptions'
                  "
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="previewData.calculationMethod_Freight"
                  disabled
                >
                  <el-option
                    v-for="item in calculationMethodOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <span
                  v-show="previewData.calculationMethod_Freight == 2"
                  style="margin-left: 10px; color: gray"
                  >{{ freightCostNum }}</span
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                :label="$t('changePriceLists.changePriceList.wareCost')"
              >
                <el-input
                  v-model="previewData.wareHouseCost"
                  disabled
                  style="width: 214px; margin-right: 10px"
                >
                  <template #suffix>
                    <el-icon
                      class="el-input__icon"
                      v-show="previewData.calculationMethod_Ware == 2"
                      >%</el-icon
                    >
                  </template>
                </el-input>
                <el-select
                  clearable
                  v-if="
                    $t(
                      'changePriceLists.changePriceList.calculationMethodOption'
                    ) == 'calculationMethodOption'
                  "
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="previewData.calculationMethod_Ware"
                  disabled
                >
                  <el-option
                    v-for="item in calculationMethodOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-select
                  clearable
                  v-if="
                    $t(
                      'changePriceLists.changePriceList.calculationMethodOption'
                    ) == 'calculationMethodOptions'
                  "
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="previewData.calculationMethod_Ware"
                  disabled
                >
                  <el-option
                    v-for="item in calculationMethodOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <span
                  v-show="previewData.calculationMethod_Ware == 2"
                  style="margin-left: 10px; color: gray"
                  >{{ wareHouseCostNum }}</span
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                :label="$t('changePriceLists.changePriceList.packagingCost')"
              >
                <el-input
                  v-model="previewData.packageCost"
                  disabled
                  style="width: 214px; margin-right: 10px"
                >
                  <template #suffix>
                    <el-icon
                      class="el-input__icon"
                      v-show="previewData.calculationMethod_Package == 2"
                      >%</el-icon
                    >
                  </template>
                </el-input>
                <el-select
                  clearable
                  v-if="
                    $t(
                      'changePriceLists.changePriceList.calculationMethodOption'
                    ) == 'calculationMethodOption'
                  "
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="previewData.calculationMethod_Package"
                  disabled
                >
                  <el-option
                    v-for="item in calculationMethodOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-select
                  clearable
                  v-if="
                    $t(
                      'changePriceLists.changePriceList.calculationMethodOption'
                    ) == 'calculationMethodOptions'
                  "
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="previewData.calculationMethod_Package"
                  disabled
                >
                  <el-option
                    v-for="item in calculationMethodOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <span
                  v-show="previewData.calculationMethod_Package == 2"
                  style="margin-left: 10px; color: gray"
                  >{{ packageCostNum }}</span
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                :label="$t('changePriceLists.changePriceList.taxesFees')"
              >
                <el-input
                  v-model="previewData.otherCost"
                  disabled
                  style="width: 214px; margin-right: 10px"
                >
                  <template #suffix>
                    <el-icon
                      class="el-input__icon"
                      v-show="previewData.calculationMethod_Taxes == 2"
                      >%</el-icon
                    >
                  </template>
                </el-input>
                <el-select
                  clearable
                  v-if="
                    $t(
                      'changePriceLists.changePriceList.calculationMethodOption'
                    ) == 'calculationMethodOption'
                  "
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="previewData.calculationMethod_Taxes"
                  disabled
                >
                  <el-option
                    v-for="item in calculationMethodOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-select
                  clearable
                  v-if="
                    $t(
                      'changePriceLists.changePriceList.calculationMethodOption'
                    ) == 'calculationMethodOptions'
                  "
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="previewData.calculationMethod_Taxes"
                  disabled
                >
                  <el-option
                    v-for="item in calculationMethodOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <span
                  v-show="previewData.calculationMethod_Taxes == 2"
                  style="margin-left: 10px; color: gray"
                  >{{ otherCostNum }}</span
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form :model="previewData" label-width="500px" label-position="left">
          <!-- <el-row>
                        <el-col>
                            <el-form-item :label="$t('changePriceLists.changePriceList.taxesFees')">
                                <el-input v-model="previewData.otherCost" disabled style="width: 214px;" />
                            </el-form-item>
                        </el-col>
                    </el-row> -->
          <el-row>
            <el-col>
              <el-form-item
                :label="
                  $t('changePriceLists.changePriceList.amortizationDescription')
                "
              >
                <el-input
                  v-model="previewData.amortizationDescription"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                :label="$t('changePriceLists.changePriceList.totalAmortized')"
              >
                <el-input
                  v-model="previewData.totalPieces"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                :label="$t('changePriceLists.changePriceList.totalTooling')"
              >
                <el-input
                  v-model="previewData.totalAmortize"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                :label="$t('changePriceLists.changePriceList.amortizationCost')"
              >
                <el-input
                  v-model="previewData.amortizeCost"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tcm-content-title mgt-10">
        {{ $t("changePriceLists.changePriceList.sgaProfitScrap") }}
      </div>
      <div class="tcm-content-form">
        <el-form :model="previewData" label-width="300px">
          <div class="form-type-title">
            {{ $t("changePriceLists.changePriceList.sgas") }}
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item :label="$t('changePriceLists.changePriceList.sga')">
                <el-input
                  v-model="previewData.sga"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.sgaCost')"
              >
                <el-input
                  v-model="previewData.sgaCost"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-type-title">
            {{ $t("changePriceLists.changePriceList.profit") }}
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.profitMaterial')"
              >
                <el-input
                  v-model="previewData.materialProfit"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.profitComponents')"
              >
                <el-input
                  v-model="previewData.addProfit"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.totalprofitCost')"
              >
                <el-input
                  v-model="previewData.totalProfitCost"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.profitValue')"
              >
                <el-input
                  v-model="previewData.purchaseProfit"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.totalProfit')"
              >
                <el-input
                  v-model="previewData.totalProfit"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.materialScrap')"
              >
                <el-input
                  v-model="previewData.materialPC"
                  disabled
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tcm-content-title mgt-10">
        {{ $t("changePriceLists.changePriceList.costSummary") }}
      </div>
      <div class="tcm-content-form">
        <el-form :model="previewData" label-width="400px">
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.rawMaterialCost')"
              >
                <el-input
                  v-model="previewData.materialCost_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.purchasedCost')"
              >
                <el-input
                  v-model="previewData.purchaseCost_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.directLaborCost')"
              >
                <el-input
                  v-model="previewData.laborCost_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.burdenCost')"
              >
                <el-input
                  v-model="previewData.burdenCost_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.setUpCost')"
              >
                <el-input
                  v-model="previewData.setUpCost_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="
                  $t('changePriceLists.changePriceList.totalManufacturing')
                "
              >
                <el-input
                  v-model="previewData.manufacturingCost_PRO"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="
                  $t('changePriceLists.changePriceList.packagingFreightCost')
                "
              >
                <el-input
                  v-model="previewData.packageCost_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.amortizationFees')"
              >
                <el-input
                  v-model="previewData.otherCost_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.amortizationCost')"
              >
                <el-input
                  v-model="previewData.amortizeCost_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.sgaAndProfit')"
              >
                <el-input
                  v-model="previewData.sgaProfit_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.processScrapCost')"
              >
                <el-input
                  v-model="previewData.scrapCost_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.totalSellPrice')"
              >
                <el-input
                  v-model="previewData.totalPriceCurr_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ exchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                :label="
                  $t('changePriceLists.changePriceList.totalSellCurrency')
                "
              >
                <el-input
                  v-model="previewData.totalPriceReport_SUM"
                  disabled
                  style="width: 214px"
                >
                  <template #prefix>{{ reportExchangeRateUnit }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="btn-cla">
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          @click="cancle"
          >{{ $t("changePriceLists.changePriceList.cancle") }}</el-button
        >
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          v-show="this.status != 6 && this.status != 5"
          @click="reject"
          >{{ $t("changePriceLists.changePriceList.rejecit") }}</el-button
        >
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          v-show="this.status != 6 && this.status != 5"
          @click="submit"
          >{{ $t("changePriceLists.changePriceList.approve") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetApplyDetail,
  UpdateApplyStatus,
  getCategorys,
} from "../../assets/js/requestApi.js";
export default {
  data() {
    return {
      partDescription: "",
      id: "",
      status: "",
      previewData: [],
      calculationMethodOptions: [
        {
          value: 1,
          label: "Numerical value",
        },
        {
          value: 2,
          label: "Percentage",
        },
      ],
      calculationMethodOption: [
        {
          value: 1,
          label: "数值",
        },
        {
          value: 2,
          label: "百分比",
        },
      ],
      unitOptions: [],
      attachmentList: [],
    };
  },
  mounted() {
    this.getUnit();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.status = this.$route.query.status;
      this.partDescription = this.$route.query.partDescription;
      console.log(this.status);
      this.getDetail();
    }
    this.getUnits();
  },
  methods: {
    getUnits() {
      this.timer = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      console.log(this.powers);
      if (this.powers == "en" || this.powers == null) {
        this.calculationMethodOptions = [
          {
            value: 1,
            label: "Numerical Value",
          },
          {
            value: 2,
            label: "Percentage",
          },
        ];
      } else {
        this.calculationMethodOptions = [
          {
            value: 1,
            label: "数值", //Numerical Value
          },
          {
            value: 2,
            label: "百分比", //Percentage
          },
        ];
      }
      clearInterval(this.timer);
    },
    getDetail() {
      let _this = this;
      let ids = [];
      ids.push(this.id);
      GetApplyDetail(ids, (res) => {
        console.log(res.result[0], "数据数据数据数据数据数据数据数据");
        let data = res.result[0];
        data.materialLine =
          data.materialList === "" || data.materialList === null
            ? []
            : data.materialList;
        data.purchaseLine =
          data.purchaseList === "" || data.purchaseList === null
            ? []
            : data.purchaseList;
        data.processLine =
          data.processList === "" || data.processList === null
            ? []
            : data.processList;

        data.manufacturingCost_PRO =
          Number(data.materialCost_SUM) +
          Number(data.purchaseCost_SUM) +
          Number(data.laborCost_SUM) +
          Number(data.burdenCost_SUM) +
          Number(data.setUpCost_SUM);

        this.previewData = data;

        let applicationList = [];
        let operateList = [];
        this.attachmentList = [];
        data.attachmentList.forEach(function (val, index) {
          if (val.applyType == 1) {
            operateList.push(val);
            _this.attachmentList.push(val.url);
          } else {
            applicationList.push(val);
          }
        });
        this.previewData.attachmentList = operateList;
      });
    },
    // 获取单位选项
    getUnit() {
      getCategorys(
        {
          TypeID: "MeasureUnit",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.unitOptions = response.data;
          }
        }
      );
    },
    // 取消
    cancle() {
      this.$router.back(-1);
    },
    // //开始 zj 2023.09.14 没有4这个状态，所以去除start事件处理
    // start() {
    //     this.isShow = !this.isShow;
    //     updateStatusCostApplys(
    //         {
    //             id: this.id,
    //             status: 4,
    //         },
    //         (res) => {
    //             console.log(res, "开始");
    //         }
    //     );
    // },
    //驳回
    reject() {
      UpdateApplyStatus(
        {
          nodeId: "3",
          id: this.id,
          status: 5,
          flowstatus: -1,
        },
        (res) => {
          console.log(res, "驳回");
          this.$router.back(-1);
        }
      );
    },
    // 已审批
    submit() {
      UpdateApplyStatus(
        {
          nodeId: "3",
          id: this.id,
          status: 6,
          flowstatus: 1,
        },
        (res) => {
          console.log(res, "已审批");
          this.$router.back(-1);
        }
      );
    },
    GoBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.tcm-content {
  padding: 50px 20px 20px 20px;
}
.tcm-title {
  width: calc(100% - 220px);
  position: fixed;
  top: 70px;
  left: 220px;
  padding: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #9a9a9a;
  z-index: 99;
  background: #fafafa;
}
.tcm-content-title {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  width: calc(100% - 50px);
  margin-left: 50px;
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}
.tcm-content-form table {
  /* table-layout: fixed;
		width: 100%; */
}
.tcm-content-form tr {
  display: flex;
  flex-wrap: nowrap;
}
.tcm-content-form td {
  width: 150px;
}
.mgt-10 {
  margin-top: 10px;
}
.form-type-title {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #f2f2f2;
  line-height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.btn-cla {
  width: 50%;
  margin: 20px auto;
  justify-content: space-around;
  display: flex;
}
.bts {
  /* width: 87px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 2px solid#335883;
  color: #335883; */

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.tcm-content-rawPic {
  position: absolute;
  top: 10px;
  left: 180px;
}
</style>