<template>
  <div>
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="Goback">Back</div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ item }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>

    <!-- 顶部主表信息及计算 -->
    <div style="background: #fff; padding-top: 10px">
      <div class="add-top">
        <el-row>
          <el-col :span="8">
            <div class="line-32 add-top-text">Part Number</div>
            <div class="line-32 add-top-desc">{{ form.partNumber }}</div>
          </el-col>
          <el-col :span="16">
            <div class="line-32 add-top-text">Description</div>
            <div class="line-32 add-top-desc">{{ form.description }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="line-32 add-top-text">Revision</div>
            <div class="line-32 add-top-desc">{{ form.revision }}</div>
          </el-col>
          <el-col :span="16">
            <div class="line-32 add-top-text">Supplier</div>
            <div class="line-32 add-top-desc">{{ form.supplier }}</div>
          </el-col>
        </el-row>
      </div>
      <div class="add-top">
        <el-row>
          <el-col :span="8">
            <div class="top-text">Set Up</div>
            <el-row>
              <div class="line-32 add-top-text">Machining type</div>
              <el-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="form.machiningType"
                class="mgr-20"
                style="width: 150px"
                @change="changeMachining"
              >
                <el-option
                  v-for="item in equOptions"
                  :key="item.id"
                  :label="item.machiningType"
                  :value="item.id"
                />
              </el-select>
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">Chip to chip</div>
              <el-input
                v-model="form.chipToChip"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">Position time</div>
              <el-input
                v-model="form.positionTime"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">TOOL CHANGE</div>
              <el-input
                v-model="form.toolChange"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">INDEX</div>
              <el-input
                v-model="form.indexValue"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">PALLET CHANGE</div>
              <el-input
                v-model="form.palletChange"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">DOOR OPEN/CLOSE TIME</div>
              <el-input
                v-model="form.openOrCloseTime"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">LOADING/UNLOADING TIME</div>
              <el-input
                v-model="form.loadingOrUnloadingTime"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">BTS time</div>
              <el-input
                v-model="form.btsTime"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">Cavity</div>
              <el-input
                v-model="form.cavity"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">Maximum Splindle Speed</div>
              <el-input
                v-model="form.maxSpeed"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">Tool Approach</div>
              <el-input
                v-model="form.toolApproach"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">Tool Retract</div>
              <el-input
                v-model="form.toolRetract"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">Material</div>
              <el-input
                v-model="form.materialName"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
          </el-col>
          <el-col :span="16">
            <div class="top-text">Feature Image</div>
            <el-row class="mgt-15">
              <el-upload
                class="custom-el-upload-picture"
                :file-list="imageList"
                action="/"
                :auto-upload="false"
                multiple
                :limit="5"
                list-type="picture-card"
                accept="image/*"
                :on-preview="handlePictureCardPreview"
                :on-remove="handlePictureCardRemove"
                :on-change="pictureCardChange"
              >
                <el-icon><Plus /></el-icon>
              </el-upload>
            </el-row>
            <div class="mgt-15 top-text">WORKPIECE SET-UP Q'TY</div>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">PIECE SET-UP / TABLE</div>
              <el-input
                v-model="form.pieceSetUp"
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <div class="mgt-15 top-text">MACHINING TIME</div>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">Real CT</div>
              <el-input
                v-model="form.realCT"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">No Real CT</div>
              <el-input
                v-model="form.noRealCT"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">CYCLE TIME(CAVITY)</div>
              <el-input
                v-model="form.cycleTime_Cavity"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">系数</div>
              <el-input
                v-model="form.coefficient"
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row class="mgt-15">
              <div class="line-32 add-top-text">CYCLE TIME(调整后)</div>
              <el-input
                v-model="form.cycleTime"
                disabled
                placeholder=""
                class="mgr-20"
                style="width: 150px"
              />
            </el-row>
            <el-row style="margin-top: 30px">
              <div class="line-32 add-top-text"></div>
              <div class="operate-btn" @click="computeData">Compute</div>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="tcm-table">
      <div class="regional_table">
        <el-table
          :empty-text="$t('tcmlists.tcmlist.noData')"
          :data="tableData"
          height="500"
          border
          stripe
          style="width: 100%"
        >
          <el-table-column
            prop="operate"
            label="Operate"
            width="150"
            align="center"
          >
            <template #default="scope">
              <div
                class="btn-text"
                v-show="!scope.row.type"
                @click="editData(scope.row, scope.$index)"
              >
                Edit
              </div>
              <div
                class="btn-text"
                v-show="!scope.row.type"
                @click="copyDetail(scope.row, scope.$index)"
              >
                Copy
              </div>
              <div
                class="btn-text"
                v-show="scope.row.type"
                @click="saveDetail(scope.row)"
              >
                Save
              </div>
              <div
                class="btn-text"
                @click="deleteData(scope.row, scope.$index)"
              >
                Delete
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="seq"
            label="Operation Sequence"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">{{ scope.row.seq }}</div>
              <el-input
                v-model="scope.row.seq"
                v-show="scope.row.type"
                @input="(v) => (scope.row.seq = v.replace(/[^\d.]/g, ''))"
                placeholder=""
                @change="
                  (x) => handleToolNameChange(scope.row, scope.$index, x)
                "
                class="mgr-20"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="operationDescription"
            label="Operation Description"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">
                {{ scope.row.operationDescription }}
              </div>
              <el-input
                v-model="scope.row.operationDescription"
                v-show="scope.row.type"
                placeholder=""
                @change="
                  (x) => handleToolNameChange(scope.row, scope.$index, x)
                "
                class="mgr-20"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="featureDescription"
            label="Feature Description"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">
                {{ scope.row.featureDescription }}
              </div>
              <el-input
                v-model="scope.row.featureDescription"
                v-show="scope.row.type"
                placeholder=""
                @change="
                  (x) => handleToolNameChange(scope.row, scope.$index, x)
                "
                class="mgr-20"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="machineMethod"
            label="Machining Mechod"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">{{ scope.row.machineMethod }}</div>
              <el-input
                v-model="scope.row.machineMethod"
                v-show="scope.row.type"
                placeholder=""
                class="mgr-20"
              />
              <!-- <el-select clearable   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" filterable v-model="scope.row.machineMethod" v-show="scope.row.type" placeholder="">
							<el-option
							    v-for="item in typeOptions"
							    :key="item.id"
							    :label="item.name"
							    :value="item.id"
							/>
						</el-select> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="toolType"
            label="Tool name"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">{{ scope.row.toolNameDesc }}</div>
              <!-- <el-input v-model="scope.row.toolType" v-show="scope.row.type" placeholder="" class="mgr-20" /> -->
              <el-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="scope.row.toolName"
                v-show="scope.row.type"
                @change="
                  (x) => handleToolNameChange(scope.row, scope.$index, x)
                "
              >
                <el-option
                  v-for="item in toolOptions"
                  :key="item.id"
                  :label="item.toolName"
                  :value="item.id"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="toolType"
            label="Tool type"
            width="110"
            align="center"
          >
            <template #default="scope">
              <!-- <div v-show="!scope.row.type">{{scope.row.toolType}}</div> -->
              <div>{{ scope.row.toolType }}</div>
              <!-- <el-input v-model="scope.row.toolType" v-show="scope.row.type" placeholder="" class="mgr-20" /> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="profileType"
            label="Profile Type"
            width="150"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">{{ scope.row.profileType }}</div>
              <el-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                filterable
                v-model="scope.row.profileType"
                v-show="scope.row.type"
                @change="
                  (x) => handleToolNameChange(scope.row, scope.$index, x)
                "
              >
                <el-option
                  v-for="item in profileOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="toolPicture"
            label="Tool Pictue"
            width="150"
            align="center"
          >
            <template #default="scope">
              <el-upload
                class="custom-el-upload-picture"
                :file-list="scope.row.toolPictureList"
                action="/"
                :auto-upload="false"
                multiple
                :limit="1"
                list-type="picture-card"
                accept="image/*"
                :on-preview="handleToolPicturePreview"
                :on-remove="toolPictureRemove"
                :on-change="toolPicturechange"
                :on-exceed="toolPictureExceed"
                v-show="scope.row.type"
              >
                <el-icon><Plus /></el-icon>
              </el-upload>
              <div
                class="tcm-content-rawPic"
                v-show="!scope.row.type && scope.row.toolPicture != ''"
              >
                <el-image
                  style="width: 40px; height: 40px; margin-right: 10px"
                  :src="scope.row.toolPicture"
                  fit="cover"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="diameterToolJob"
            label="Diameter of Tool/Job (mm)"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">
                {{ scope.row.diameterToolJob }}
              </div>
              <el-input
                v-model="scope.row.diameterToolJob"
                v-show="scope.row.type"
                @input="
                  (v) => (scope.row.diameterToolJob = v.replace(/[^\d.]/g, ''))
                "
                placeholder=""
                @change="
                  (x) => handleToolNameChange(scope.row, scope.$index, x)
                "
                class="mgr-20"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="cuttingLength"
            label="Cutting Length (mm)"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">{{ scope.row.cuttingLength }}</div>
              <el-input
                v-model="scope.row.cuttingLength"
                v-show="scope.row.type"
                @input="
                  (v) => (scope.row.cuttingLength = v.replace(/[^\d.]/g, ''))
                "
                placeholder=""
                @change="
                  (x) => handleToolNameChange(scope.row, scope.$index, x)
                "
                class="mgr-20"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="cuttingDepth"
            label="Cutting Depth (mm)"
            align="center"
          >
            <template #default="scope">
              <div>{{ scope.row.cuttingDepth }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="toolEntry"
            label="Tool Entry(mm)"
            align="center"
          >
            <template #default="scope">
              <div>{{ scope.row.toolEntry }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="toolExit" label="Tool Exit(mm)" align="center">
            <template #default="scope">
              <div>{{ scope.row.toolExit }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="maxDepth"
            label="Max Depth of Cut (mm)"
            align="center"
          >
            <template #default="scope">
              <div>{{ scope.row.maxDepth }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="spindleSpeed"
            label="Spindle Speed(rev/min)"
            width="110"
            align="center"
          >
            <template #default="scope">
              <!-- <div v-show="!scope.row.type">{{scope.row.spindleSpeed}}</div> -->
              <div>{{ scope.row.spindleSpeed }}</div>
              <!-- <el-input v-model="scope.row.spindleSpeed" v-show="scope.row.type" @input="(v) => (scope.row.spindleSpeed = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20"/> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="feed"
            label="Feed(mm/rev)"
            width="110"
            align="center"
          >
            <template #default="scope">
              <!-- <div v-show="!scope.row.type">{{scope.row.feed}}</div> -->
              <div>{{ scope.row.feed }}</div>
              <!-- <el-input v-model="scope.row.feed" v-show="scope.row.type" @input="(v) => (scope.row.feed = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20"/> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="actualLenthCut"
            label="Cutting Speed(m/min)"
            align="center"
          >
            <template #default="scope">
              <div>{{ scope.row.actualLenthCut }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="actualLenthCut"
            label="Actual Length of Cut(mm)"
            align="center"
          >
            <template #default="scope">
              <div>{{ scope.row.actualLenthCut }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="noPassesPlaces"
            label="No ofPasses/Places"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">{{ scope.row.noPassesPlaces }}</div>
              <el-input
                v-model="scope.row.noPassesPlaces"
                v-show="scope.row.type"
                @input="
                  (v) => (scope.row.noPassesPlaces = v.replace(/[^\d.]/g, ''))
                "
                placeholder=""
                @change="
                  (x) => handleToolNameChange(scope.row, scope.$index, x)
                "
                class="mgr-20"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="machineTime"
            label="Machining Time(sec)"
            align="center"
          >
            <template #default="scope">
              <div>{{ scope.row.machineTime }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="indexValue" label="Index(sec)" align="center">
            <template #default="scope">
              <div>{{ scope.row.indexValue }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="btsTime" label="BTS Time(sec)" align="center">
            <template #default="scope">
              <div>{{ scope.row.btsTime }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="positionTime"
            label="Positi-oning Time(sec)"
            align="center"
          >
            <template #default="scope">
              <div>{{ scope.row.positionTime }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="toolChangeTime"
            label="Tool Change Time(sec)"
            align="center"
          >
            <template #default="scope">
              <div>{{ scope.row.toolChangeTime }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="shutDownTime"
            label="Shutdown Time(sec)"
            width="110"
            align="center"
          >
            <template #default="scope">
              <div v-show="!scope.row.type">{{ scope.row.shutDownTime }}</div>
              <el-input
                v-model="scope.row.shutDownTime"
                v-show="scope.row.type"
                @input="
                  (v) => (scope.row.shutDownTime = v.replace(/[^\d.]/g, ''))
                "
                placeholder=""
                @change="
                  (x) => handleToolNameChange(scope.row, scope.$index, x)
                "
                class="mgr-20"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="cycleTime"
            label=" Cycle Time(sec)"
            align="center"
          >
            <template #default="scope">
              <div>{{ scope.row.cycleTime }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 搜索框 -->
    <div class="tcm-search">
      <table>
        <tr>
          <td colspan="4" style="padding-top: 20px">
            <div class="operate-btn mgr-10" @click="addData">Add</div>
            <div class="operate-btn mgr-10" @click="openDia">Add From List</div>
          </td>
        </tr>
      </table>
    </div>
    <el-dialog v-model="PredialogVisible">
      <img
        w-full
        :src="dialogImageUrl"
        alt="Preview Image"
        style="width: 100%"
      />
    </el-dialog>
    <CtDia ref="ctDias" :mainId="form.id" @selectCt="selectCt"></CtDia>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import CtDia from "./ctDia.vue";
import {
  GDeleteByMainIdsCtEquiment,
  CTCalculator_MainsGetCTMainDetail,
  CTCalculator_DetailsGet,
  LoadEquiment,
  LoadTool,
  uploadFile,
  SaveCalculateResult,
  AddOrUpdateCTDetails,
  DeleteCTDetails,
  AddBySelectMain,
  ReCalculateResult,
} from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
import "../../../assets/css/custom-el-upload-list-none.css";
export default {
  components: {
    CtDia,
  },
  data() {
    return {
      form: {
        partNumber: "",
        description: "",
        revision: "",
        supplier: "",
        machiningType: "",
        chipToChip: "",
        positionTime: "",
        toolChange: "",
        indexValue: "",
        palletChange: "",
        openOrCloseTime: "",
        loadingOrUnloadingTime: "",
        btsTime: "",
        cavity: "",
        maxSpeed: "",
        toolApproach: "",
        toolRetract: "",
        materialName: "",
        pieceSetUp: "",
        realCT: "",
        noRealCT: "",
        cycleTime_Cavity: "",
        coefficient: "",
        cycleTime: "",
        createId: "",
        createTime: "",
        modifyId: "",
        modifyTime: "",
        isDeleted: 0,
        attachmentList: [],
        id: "",
      },
      imageList: [],
      // 上传后获取到的url
      imagesUploaded: [],
      // 图片预览弹窗是否显示
      PredialogVisible: false,
      dialogImageUrl: "",
      // 选项
      equOptions: [],
      toolOptions: [],
      profileOptions: ["THROUGH", "BLIND"],
      // 行内上传图片
      toolPictureList: [],
      toolPicturePreview: [],
      // 行内图片上传后url
      toolPictureUpload: [],
      searchForm: {
        id: "",
      },
      // 详情列表数据
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 图片上传状态
      uploadStatus: false,
      toolUploadStatus: false,
      // 当前操作行
      operateRaw: {},
      // 当前操作行下标
      operateIndex: "",
      crumbs: ["CT Calculator", "CT Calculator List", "Calculator"],
    };
  },
  mounted() {
    this.getEquiment();
    // this.getTools()
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id;
      this.getComputeDetail();
      this.getList();
    }
  },
  watch: {
    uploadStatus: {
      handler(nval, oval) {
        if (nval) {
          this.computeData();
        }
      },
      deep: true,
    },
    toolUploadStatus: {
      handler(nval, oval) {
        if (nval) {
          this.submitDetail();
        }
      },
      deep: true,
    },
  },
  methods: {
    // 新增
    addData() {
      if (this.form.machiningType === "") {
        commonUtil.showTipInfo(
          "Please choose machining type first!",
          "",
          "warning"
        );
        return;
      }
      // 是否有待保存数据
      let isOperate = false;
      this.tableData.forEach(function (val, index) {
        if (val.type) {
          isOperate = true;
        }
      });
      if (isOperate) {
        commonUtil.showTipInfo(
          "There are data in operation, please save!",
          "",
          "warning"
        );
        return;
      }
      this.tableData.push({
        id: "",
        mainId: "",
        seq: "",
        operationDescription: "",
        featureDescription: "",
        machineMethod: "",
        toolName: "",
        toolType: "",
        profileType: "",
        toolPicture: "",
        diameterToolJob: "",
        cuttingLength: "",
        cuttingDepth: "",
        toolEntry: "",
        toolExit: "",
        maxDepth: "",
        spindleSpeed: "",
        feed: "",
        actualLenthCut: "",
        noPassesPlaces: "",
        machineTime: "",
        indexValue: "",
        btsTime: "",
        positionTime: "",
        toolChangeTime: "",
        shutDownTime: "",
        cycleTime: "",
        toolPictureList: [],
        toolPicturePreview: [],
        toolPictureUpload: [],
        type: "add",
      });
      this.operateIndex = this.tableData.length - 1;
    },
    // 保存新增数据
    saveDetail(row) {
      if (row.seq === "" || row.seq === null) {
        commonUtil.showTipInfo(
          "Please enter Operation Sequence!",
          "",
          "warning"
        );
        return;
      }
      if (row.toolName === "" || row.toolName === null) {
        commonUtil.showTipInfo("Please choose ToolName!", "", "warning");
        return;
      }
      this.operateRaw = row;
      this.uploadFilesTool(row.toolPictureList);
    },
    submitDetail() {
      let data = this.formatComputeDataV2(this.operateRaw);
      AddOrUpdateCTDetails(data, (response) => {
        if (response && response.code == 200) {
          this.getList();
          this.toolUploadStatus = false;
        } else {
          this.toolUploadStatus = false;
          commonUtil.showTipInfo(
            "Operation Sequence data duplication",
            "",
            "warning"
          );
        }
      });
    },
    // 编辑
    editData(row, index) {
      // 是否有待保存数据
      let isOperate = false;
      this.tableData.forEach(function (val, index) {
        if (val.type) {
          isOperate = true;
        }
      });
      if (isOperate) {
        commonUtil.showTipInfo(
          "There are data in operation, please save!",
          "",
          "warning"
        );
        return;
      }
      this.operateRaw = row;
      this.operateIndex = index;
      this.operateRaw.toolPictureList =
        this.operateRaw.toolPicture == ""
          ? []
          : [
              {
                url: this.operateRaw.toolPicture,
              },
            ];
      this.operateRaw.toolPicturePreview =
        this.operateRaw.toolPicture == ""
          ? []
          : [
              {
                url: this.operateRaw.toolPicture,
              },
            ];
      this.operateRaw.toolPictureUpload = [];
      this.operateRaw.type = "edit";
    },
    // 复制
    copyDetail(row, index) {
      // 是否有待保存数据
      let isOperate = false;
      this.tableData.forEach(function (val, index) {
        if (val.type) {
          isOperate = true;
        }
      });
      if (isOperate) {
        commonUtil.showTipInfo(
          "There are data in operation, please save!",
          "",
          "warning"
        );
        return;
      }
      this.operateRaw = JSON.parse(JSON.stringify(row));
      this.operateIndex = index;
      this.operateRaw.id = "";
      this.operateRaw.toolPictureList =
        this.operateRaw.toolPicture == ""
          ? []
          : [
              {
                url: this.operateRaw.toolPicture,
              },
            ];
      this.operateRaw.toolPicturePreview =
        this.operateRaw.toolPicture == ""
          ? []
          : [
              {
                url: this.operateRaw.toolPicture,
              },
            ];
      this.operateRaw.toolPictureUpload = [];
      this.operateRaw.type = "add";
      this.tableData.push(this.operateRaw);
    },
    deleteData(row, index) {
      // 是否有待保存数据
      let isOperate = false;
      let operateIndex = "";
      this.tableData.forEach(function (val, index) {
        if (val.type) {
          isOperate = true;
          operateIndex = index;
        }
      });
      if (isOperate && operateIndex != index) {
        commonUtil.showTipInfo(
          "There are data in operation, please save!",
          "",
          "warning"
        );
        return;
      }
      if (row.type == "add") {
        this.tableData.splice(index, 1);
      } else {
        let ids = [];
        ids.push(row.id);
        DeleteCTDetails(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
              "",
              "success"
            );
            this.search();
          }
        });
      }
    },
    // 根据id获取计算结果
    getComputeDetail(type) {
      CTCalculator_MainsGetCTMainDetail(
        {
          id: this.form.id,
        },
        (response) => {
          if (response && response.code == 200) {
            this.form = response.result;
            this.imageList = response.result.attachmentList;
            if (
              this.form.machiningType !== "" ||
              this.form.machiningType !== null
            ) {
              this.getTools();
            }
          }
        }
      );
    },
    // 初始化detail列表
    getList() {
      this.searchForm = {
        mainId: this.form.id,
        page: 1,
        limit: 9999,
        key: "",
      };
      this.search();
    },
    search() {
      CTCalculator_DetailsGet(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
        }
      });
    },
    // 获取equipment type选项
    getEquiment() {
      LoadEquiment(
        {
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.equOptions = response.data;
          }
        }
      );
    },
    // 获取tool选项
    getTools() {
      LoadTool(
        {
          machineType: this.form.machiningType,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.toolOptions = response.data;
          }
        }
      );
    },
    //格式化计算数据
    formatComputeData(form) {
      return {
        id: form.id,
        machiningType: form.machiningType,
        chipToChip:
          form.chipToChip === "" || form.chipToChip === null
            ? 0
            : parseFloat(form.chipToChip),
        positionTime:
          form.positionTime === "" || form.positionTime === null
            ? 0
            : parseFloat(form.positionTime),
        toolChange:
          form.toolChange === "" || form.toolChange === null
            ? 0
            : parseFloat(form.toolChange),
        indexValue:
          form.indexValue === "" || form.indexValue === null
            ? 0
            : parseFloat(form.indexValue),
        palletChange:
          form.palletChange === "" || form.palletChange === null
            ? 0
            : parseFloat(form.palletChange),
        openOrCloseTime:
          form.openOrCloseTime === "" || form.openOrCloseTime === null
            ? 0
            : parseFloat(form.openOrCloseTime),
        loadingOrUnloadingTime:
          form.loadingOrUnloadingTime === "" ||
          form.loadingOrUnloadingTime === null
            ? 0
            : parseFloat(form.loadingOrUnloadingTime),
        btsTime:
          form.btsTime === "" || form.btsTime === null
            ? 0
            : parseFloat(form.btsTime),
        cavity:
          form.cavity === "" || form.cavity === null
            ? 0
            : parseFloat(form.cavity),
        maxSpeed:
          form.maxSpeed === "" || form.maxSpeed === null
            ? 0
            : parseFloat(form.maxSpeed),
        toolApproach:
          form.toolApproach === "" || form.toolApproach === null
            ? 0
            : parseFloat(form.toolApproach),
        toolRetract:
          form.toolRetract === "" || form.toolRetract === null
            ? 0
            : parseFloat(form.toolRetract),
        materialName: form.materialName,
        pieceSetUp:
          form.pieceSetUp === "" || form.pieceSetUp === null
            ? 0
            : parseFloat(form.pieceSetUp),
        realCT:
          form.realCT === "" || form.realCT === null
            ? 0
            : parseFloat(form.realCT),
        noRealCT:
          form.noRealCT === "" || form.noRealCT === null
            ? 0
            : parseFloat(form.noRealCT),
        cycleTime_Cavity:
          form.cycleTime_Cavity === "" || form.cycleTime_Cavity === null
            ? 0
            : parseFloat(form.cycleTime_Cavity),
        coefficient:
          form.coefficient === "" || form.coefficient === null
            ? 0
            : parseFloat(form.coefficient),
        cycleTime:
          form.cycleTime === "" || form.cycleTime === null
            ? 0
            : parseFloat(form.cycleTime),
        attachmentList: this.imagesUploaded,
      };
    },
    formatComputeDataV2(d) {
      return {
        id: d.id,
        mainId: this.form.id,
        machiningType: this.form.machiningType,
        seq: d.seq,
        operationDescription: d.operationDescription,
        featureDescription: d.featureDescription,
        machineMethod: d.machineMethod,
        toolName: d.toolName,
        toolType: d.toolType,
        profileType: d.profileType,
        toolPicture:
          d.toolPictureUpload.length > 0 ? d.toolPictureUpload[0].url : "",
        diameterToolJob:
          d.diameterToolJob === "" || d.diameterToolJob === null
            ? 0
            : parseFloat(d.diameterToolJob),
        cuttingLength:
          d.cuttingLength === "" || d.cuttingLength === null
            ? 0
            : parseFloat(d.cuttingLength),
        cuttingDepth:
          d.cuttingDepth === "" || d.cuttingDepth === null
            ? 1.5
            : parseFloat(d.cuttingDepth),
        toolEntry:
          d.toolEntry === "" || d.toolEntry === null
            ? 0
            : parseFloat(d.toolEntry),
        toolExit:
          d.toolExit === "" || d.toolExit === null ? 0 : parseFloat(d.toolExit),
        maxDepth:
          d.maxDepth === "" || d.maxDepth === null ? 0 : parseFloat(d.maxDepth),
        spindleSpeed:
          d.spindleSpeed === "" || d.spindleSpeed === null
            ? 0
            : parseFloat(d.spindleSpeed),
        feed: d.feed === "" || d.feed === null ? 0 : parseFloat(d.feed),
        actualLenthCut:
          d.actualLenthCut === "" || d.actualLenthCut === null
            ? 0
            : parseFloat(d.actualLenthCut),
        noPassesPlaces:
          d.noPassesPlaces === "" || d.noPassesPlaces === null
            ? 0
            : parseFloat(d.noPassesPlaces),
        machineTime:
          d.machineTime === "" || d.machineTime === null
            ? 0
            : parseFloat(d.machineTime),
        indexValue:
          d.indexValue === "" || d.indexValue === null
            ? 0
            : parseFloat(d.indexValue),
        btsTime:
          d.btsTime === "" || d.btsTime === null ? 0 : parseFloat(d.btsTime),
        positionTime:
          d.positionTime === "" || d.positionTime === null
            ? 0
            : parseFloat(d.positionTime),
        toolChangeTime:
          d.toolChangeTime === "" || d.toolChangeTime === null
            ? 0
            : parseFloat(d.toolChangeTime),
        shutDownTime:
          d.shutDownTime === "" || d.shutDownTime === null
            ? 0
            : parseFloat(d.shutDownTime),
        cycleTime:
          d.cycleTime === "" || d.cycleTime === null
            ? 0
            : parseFloat(d.cycleTime),
      };
    },
    //明细行重新计算,但不保存
    rowReComputeDate(row, then) {
      let data = this.formatComputeDataV2(row);
      ReCalculateResult(data, (response) => {
        if (response && response.code == 200) {
          if (then) {
            then(response.result);
          }
        }
      });
    },
    // 计算且保存
    computeData() {
      let data = this.formatComputeData(this.form);
      SaveCalculateResult(data, (response) => {
        if (response && response.code == 200) {
          this.form = response.result;
          this.uploadStatus = false;
        }
      });
    },
    // 切换machiningtype
    changeMachining(val) {
      let _this = this;
      let isSure = false;
      if (this.tableData.length > 0) {
        ElMessageBox.confirm(
          "After switching, the details will be cleared, whether to continue?",
          {
            cancelButtonText: "cancle",
            confirmButtonText: "ok",
          }
        )
          .then((info) => {
            if (info === "confirm") {
              isSure = true;
              GDeleteByMainIdsCtEquiment([this.form.id], (response) => {
                if (response && response.code == 200) {
                  _this.equOptions.forEach(function (equval, index) {
                    if (equval.id == val) {
                      _this.form.chipToChip = equval.chipToChip;
                      _this.form.positionTime = equval.positionTime;
                      _this.form.toolChange = equval.toolChange;
                      _this.form.indexValue = equval.indexValue;
                      _this.form.palletChange = equval.palletChange;
                      _this.form.openOrCloseTime = equval.openOrCloseTime;
                      _this.form.loadingOrUnloadingTime =
                        equval.loadingOrUnloadingTime;
                      _this.form.btsTime = equval.btsTime;
                      _this.form.cavity = equval.cavity;
                      _this.form.maxSpeed = equval.maxSpeed;
                      _this.form.toolApproach = equval.toolApproach;
                      _this.form.toolRetract = equval.toolRetract;
                      _this.form.materialName = equval.materialName;
                    }
                  });
                  _this.getTools();
                  _this.getList();
                  _this.uploadFiles(this.imageList);
                }
              });
            }
            done();
          })
          .catch(() => {
            if (!isSure) {
              _this.getComputeDetail();
            }
            // catch error
          });
      } else {
        _this.equOptions.forEach(function (equval, index) {
          if (equval.id == val) {
            _this.form.chipToChip = equval.chipToChip;
            _this.form.positionTime = equval.positionTime;
            _this.form.toolChange = equval.toolChange;
            _this.form.indexValue = equval.indexValue;
            _this.form.palletChange = equval.palletChange;
            _this.form.openOrCloseTime = equval.openOrCloseTime;
            _this.form.loadingOrUnloadingTime = equval.loadingOrUnloadingTime;
            _this.form.btsTime = equval.btsTime;
            _this.form.cavity = equval.cavity;
            _this.form.maxSpeed = equval.maxSpeed;
            _this.form.toolApproach = equval.toolApproach;
            _this.form.toolRetract = equval.toolRetract;
            _this.form.materialName = equval.materialName;
          }
        });
        _this.getTools();
        _this.getList();
        _this.uploadFiles(this.imageList);
      }
    },
    // 预览上传的图片
    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.PredialogVisible = true;
    },
    // 删除图片
    handlePictureCardRemove(file, fileList) {
      this.imageList = fileList;
    },
    pictureCardChange(file, fileList) {
      this.imageList.push(file);
    },
    // 行内上传图片change事件
    toolPicturechange(file, fileList) {
      this.tableData[this.operateIndex].toolPictureList.push(file);
    },
    // 预览上传的图片
    handleToolPicturePreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.PredialogVisible = true;
    },
    // 删除图片
    toolPictureRemove(file, fileList) {
      this.tableData[this.operateIndex].toolPictureList = fileList;
    },
    // 上传图片超出限制
    toolPictureExceed() {
      commonUtil.showTipInfo("Only one image can be uploaded！", "", "warning");
    },
    // 顶部计算部分图片上传
    uploadFiles(files) {
      let _this = this;
      this.imagesUploaded = [];
      this.uploadStatus = false;
      let format = new FormData();
      // 需要上传的文件
      let uploadList = [];
      files.forEach(function (value, key) {
        if (value.raw) {
          format.append("file", value.raw);
          uploadList.push(value);
        } else {
          _this.imagesUploaded.push(value);
        }
      });
      if (uploadList.length > 0) {
        uploadFile(format, (response) => {
          if (response && response.code == 200) {
            response.data.forEach(function (val, key) {
              _this.imagesUploaded.push(val);
            });
            _this.uploadStatus = true;
          }
        });
      } else {
        _this.uploadStatus = true;
      }
    },
    // 行内图片上传
    uploadFilesTool(files) {
      let _this = this;
      this.operateRaw.toolPictureUpload = [];
      this.toolUploadStatus = false;
      let format = new FormData();
      // 需要上传的文件
      let uploadList = [];
      files.forEach(function (value, key) {
        if (value.raw) {
          format.append("file", value.raw);
          uploadList.push(value);
        } else {
          _this.operateRaw.toolPictureUpload.push(value);
        }
      });
      if (uploadList.length > 0) {
        uploadFile(format, (response) => {
          if (response && response.code == 200) {
            response.data.forEach(function (val, key) {
              _this.operateRaw.toolPictureUpload.push(val);
            });
            _this.toolUploadStatus = true;
          }
        });
      } else {
        _this.toolUploadStatus = true;
      }
    },
    // 打开选择弹窗
    openDia() {
      // 是否有待保存数据
      let isOperate = false;
      this.tableData.forEach(function (val, index) {
        if (val.type) {
          isOperate = true;
        }
      });
      if (isOperate) {
        commonUtil.showTipInfo(
          "There are data in operation, please save!",
          "",
          "warning"
        );
        return;
      }
      this.$refs.ctDias.getlist();
      this.$refs.ctDias.visible = true;
    },
    // 选择历史ct
    selectCt(id) {
      AddBySelectMain(
        {
          currId: this.form.id,
          selectId: id,
        },
        (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo("Operate successfully!", "", "success");
            this.search();
          } else {
            commonUtil.showTipInfo(
              "No detailed data under the current selection!",
              "",
              "warning"
            );
          }
        }
      );
    },
    handleToolNameChange(row, index, x) {
      this.rowReComputeDate(row, function (newRow) {
        row.toolEntry = newRow.toolEntry;
        row.toolExit = newRow.toolExit;
        row.actualLenthCut = newRow.actualLenthCut;
        row.machineTime = newRow.machineTime;
        row.indexValue = newRow.indexValue;
        row.btsTime = newRow.btsTime;
        row.positionTime = newRow.positionTime;
        row.toolChangeTime = newRow.toolChangeTime;
        row.cycleTime = newRow.cycleTime;
        row.spindleSpeed = newRow.spindleSpeed;
        row.feed = newRow.feed;
        row.toolNameDesc = newRow.toolNameDesc;
        row.toolType = newRow.toolType;
      });
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.add-top {
  padding: 20px 40px;
  box-sizing: border-box;
  border-bottom: 1px solid #8c8c8c;
}
.dis-flex {
  display: flex;
  justify-content: space-around;
}
.line-32 {
  line-height: 32px;
}
.height-32 {
  height: 32px;
}
.mgr-20 {
  margin-right: 20px;
}
.mgr-10 {
  margin-right: 10px;
}
.mgt-15 {
  margin-top: 12px;
}
.align-right {
  text-align: right;
}
.top-text {
  font-size: 16px;
  margin: 10px -10px;
}
.top-text::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  margin-right: 10px;
}
.add-top-text {
  font-size: 16px;
  font-weight: 500;
  width: 200px;
  display: inline-block;
}
.add-top-desc {
  font-size: 14px;
  color: #666 !important;
  display: inline-block;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.tcm-search {
  width: 100%;
  /* border-bottom: 1px solid #d9d9d9; */
  padding: 20px;
  box-sizing: border-box;
}
.search-text-cla {
  display: inline-flex;
  width: 120px;
}
.tcm-search td {
  width: 20%;
  padding: 0 10px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.btn-text {
  color: var(--themeColor);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin: 0 5px;
}
.color-ff0000 {
  color: #ff0000;
}
.color-F59A23 {
  color: #f59a23;
}
.color-0000FF {
  color: #0000ff;
}
.color-D9001B {
  color: #d9001b;
}
.color-67C23A {
  color: #40d4a8;
}

.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
.el-table {
  background: url("../../../assets/img/car-table-bg.png") no-repeat right;
  background-position: right 4% bottom 0;
  background-size: 500px 400px;
}
</style>