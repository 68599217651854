<template>
	<div class="nav-tabs" ref="tabs">
		<div class="nav-click-arrow" v-show="showArrow" @click="toLeft">
			<el-icon><ArrowLeft /></el-icon>
		</div>
		<div class="nav-click" ref="tabsmovew">
			
		    <div style="display: flex;" ref="tabsul" :style="{transform: 'translateX(' + move + 'px)'}">
				 <!-- @contextmenu.prevent="showContextMenu($event, item, index)" -->
		        <div class="nav-click-body" v-for="(item,index) in openedPageRouters" :key="item.fullPath"
					 
		            :title="item.meta.title + (item.query.flag || '')"
		            :class="{'nav-click-show':item.fullPath == $route.fullPath,'nav-click-default':!(item.fullPath == $route.fullPath)}">
		            <div class="nav-click-body-title hidden" v-show="transValue == 'zh'" @click="onClick(item)">
		                {{item.meta.zhTitle}} {{(item.query.flag || '')}}
		            </div>
					<div class="nav-click-body-title hidden" v-show="transValue == 'en'" @click="onClick(item)">
					    {{item.meta.title}} {{(item.query.flag || '')}}
					</div>
		            <div v-if="index!=0" class="nav-click-body-close" @click="onClose(item)"
		                :class="{'nav-click-body-show':item.fullPath == $route.fullPath,'nav-click-body-default':!(item.fullPath == $route.fullPath)}">
		                ×</div>
		        </div>
		    </div>
		    <div v-show="contextMenuVisible" style="position: fixed;"
		        :style="{ 'left': contextMenuLeft + 'px', 'top': contextMenuTop + 'px' }">
		        <ul class="__contextmenu">
		            <li>
		                <el-button type="text" @click="reloadRoute" size="mini">重新加载</el-button>
		            </li>
		            <li>
		                <el-button type="text" @click="closeOtherLeft" size="mini">关闭左边</el-button>
		            </li>
		            <li>
		                <el-button type="text" @click="closeOtherRight" size="mini">关闭右边</el-button>
		            </li>
		            <li>
		                <el-button type="text" @click="closeOther" size="mini">关闭其他</el-button>
		            </li>
		        </ul>
		    </div>
		</div>
		<div class="nav-click-arrow" v-show="showArrow" @click="toRight">
			<el-icon><ArrowRight /></el-icon>
		</div>
	</div>
    
</template>
<script>
    export default {
        name: 'syPageTabs',
        props: {
            keepAliveComponentInstance: {}, //keep-alive控件实例对象
            blankRouteName: {
                type: String,
                default: "blank",
            }, //空白路由的name值
            firstPageRouter: Object, // 系统首页路由对象
			transValue: {
				type: String,
				default: "en"
			}
        },
        watch: {
            //当路由变更时，执行打开页面的方法
            $route: {
                handler(v) {
                    this.openPage(v);
                },
                immediate: true,
            },
        },
        data() {
            return {
                openedPageRouters: [], //已打开的路由页面

                contextMenuVisible: false, //右键菜单是否显示
                contextMenuLeft: 0, //右键菜单显示位置
                contextMenuTop: 0, //右键菜单显示位置
                contextMenuTargetPageRoute: null, //右键所指向的菜单路由
				showArrow: false, //箭头按钮是否显示,
				move: 0, //左右移动距离
            }
        },

        mounted() {
            //添加点击关闭右键菜单
            window.addEventListener("click", this.closeContextMenu);
        },
        destroyed() {
            window.removeEventListener("click", this.closeContextMenu);
        },
        methods: {
            //右键显示菜单
            showContextMenu(e, route, index) {
                if (index == 0) {
                    return
                }
                this.contextMenuTargetPageRoute = route;
                this.contextMenuLeft = e.screenX //e.layerX;
                this.contextMenuTop = e.clientY //e.layerY;
                this.contextMenuVisible = true;
            },
            //隐藏右键菜单
            closeContextMenu() {
                this.contextMenuVisible = false;
                this.contextMenuTargetPageRoute = null;
            },


            // 插入首页
            insertFirstPage(route) {
                if (this.firstPageRouter && route.path !== this.firstPageRouter.path) {
                    this.openedPageRouters.push(this.firstPageRouter)
                }
            },

            //打开页面
            openPage(route) {
                // 如果还没创建过一个标签页，先将首页添加标签
                if (this.openedPageRouters.length < 1) {
                    this.insertFirstPage(route);
                }
				if (this.openedPageRouters.length > 1) {
					let hasPath = this.openedPageRouters.find(
					    (item) => item.path == "/tcmAdd" // 注意这边找的不是全路径
					);
					if (hasPath !== undefined && !hasPath.meta.showTag) {
						this.delRouteCache(hasPath.fullPath, hasPath);
						this.openedPageRouters.splice(this.openedPageRouters.indexOf(hasPath), 1);
					}
					let hasPathOperate = this.openedPageRouters.find(
					    (item) => item.path == "/addOperate" // 注意这边找的不是全路径
					);
					if (hasPathOperate !== undefined && !hasPathOperate.meta.showTag) {
						this.delRouteCache(hasPathOperate.fullPath, hasPathOperate);
						this.openedPageRouters.splice(this.openedPageRouters.indexOf(hasPathOperate), 1);
					}
					let hasPathRaw = this.openedPageRouters.find(
					    (item) => item.path == "/addRaw" // 注意这边找的不是全路径
					);
					if (hasPathRaw !== undefined && !hasPathRaw.meta.showTag) {
						this.delRouteCache(hasPathRaw.fullPath, hasPathRaw);
						this.openedPageRouters.splice(this.openedPageRouters.indexOf(hasPathRaw), 1);
					}
					let hasPathPur = this.openedPageRouters.find(
					    (item) => item.path == "/addPur" // 注意这边找的不是全路径
					);
					if (hasPathPur !== undefined && !hasPathPur.meta.showTag) {
						this.delRouteCache(hasPathPur.fullPath, hasPathPur);
						this.openedPageRouters.splice(this.openedPageRouters.indexOf(hasPathPur), 1);
					}
					let hasPathPro = this.openedPageRouters.find(
					    (item) => item.path == "/addPro" // 注意这边找的不是全路径
					);
					if (hasPathPro !== undefined && !hasPathPro.meta.showTag) {
						this.delRouteCache(hasPathPro.fullPath, hasPathPro);
						this.openedPageRouters.splice(this.openedPageRouters.indexOf(hasPathPro), 1);
					}
				}
                if (route.name == this.blankRouteName) {
                    return;
                }
                // 检查该标签页是否已创建
                let isExist = this.openedPageRouters.some(
                    (item) => item.fullPath == route.fullPath
                );

                // 如果没创建，并且路径不等于根路径
                if (!isExist && route.fullPath !== "/") {
                    let openedPageRoute = this.openedPageRouters.find(
                        (item) => item.path == route.path // 注意这边找的不是全路径
                    );
                    //判断页面是否支持不同参数多开页面功能，如果不支持且已存在path值一样的页面路由，那就替换它
                    if (!route.meta.canMultipleOpen && openedPageRoute != null) {
                        this.delRouteCache(openedPageRoute.fullPath);
                        this.openedPageRouters.splice(this.openedPageRouters.indexOf(openedPageRoute), 1, route);
                    } else {
                        this.openedPageRouters.push(route);
                    }
                }
				this.getWidth()
            },

            // 关闭所有的标签
            closeAllClick() {
                // 如果设置了系统首页标签，首页标签不能关掉
                if (this.firstPageRouter && this.openedPageRouters.length < 2) return;

                for (let i = 1; i < this.openedPageRouters.length; i++) {
                    this.delPageRoute(this.openedPageRouters[i]);
                }
                // 全部关闭后，路由跳到首页
                this.onClick(this.openedPageRouters[0]);
            },

            //点击页面标签卡时
            onClick(route) {
                if (route.fullPath !== this.$route.fullPath) {
                    this.$router.push(route.fullPath);
                }
            },
            //关闭页面标签时
            onClose(route) {
                let index = this.openedPageRouters.indexOf(route);
                this.delPageRoute(route);
                if (route.fullPath === this.$route.fullPath) {
                    //删除页面后，跳转到上一页面
                    this.$router.replace(
                        this.openedPageRouters[index == 0 ? 0 : index - 1]
                    );
                }
            },

            //删除页面
            delPageRoute(route) {
                let routeIndex = this.openedPageRouters.indexOf(route);
                if (routeIndex >= 0) {
                    this.openedPageRouters.splice(routeIndex, 1);
                }
                this.delRouteCache(route.fullPath, route);
            },

            //删除页面缓存
            delRouteCache(key, route) {
				this.$emit('delRouteCache', route)
				this.$store.commit('DEL_KEEP_ALIVE', route)
				// console.log(this.keepAliveComponentInstance)
    //             let cache = this.keepAliveComponentInstance.cache;
    //             let keys = this.keepAliveComponentInstance.keys;
    //             for (let i = 0; i < keys.length; i++) {
    //                 if (keys[i] == key) {
    //                     keys.splice(i, 1);
    //                     if (cache[key] != null) {
    //                         cache[key].componentInstance.$destroy()
    //                         delete cache[key];
    //                     }
    //                     break;
    //                 }
    //             }
            },

            //关闭右边页面
            closeOtherRight() {
                let index = this.openedPageRouters.indexOf(
                    this.contextMenuTargetPageRoute
                );
                let currentIndex = this.getPageRouteIndex(this.$route.fullPath);
                for (let i = index + 1; i < this.openedPageRouters.length; i++) {
                    let r = this.openedPageRouters[i];
                    this.delPageRoute(r);
                    i--;
                }
                if (index < currentIndex) {
                    this.$router.replace(this.contextMenuTargetPageRoute);
                }
            },
            //关闭左边页面
            closeOtherLeft() {
                let index = this.openedPageRouters.indexOf(
                    this.contextMenuTargetPageRoute
                );
                let currentIndex = this.getPageRouteIndex(this.$route.fullPath);
                if (index > currentIndex) {
                    this.$router.replace(this.contextMenuTargetPageRoute);
                }
                let startIndex = this.firstPageRouter ? 1 : 0;
                for (let i = startIndex; i < index; i++) {
                    let r = this.openedPageRouters[i];
                    this.delPageRoute(r);
                    i--;
                    index--;
                }
            },
            //关闭其他页面
            closeOther() {
                let startIndex = this.firstPageRouter ? 1 : 0;
                for (let i = startIndex; i < this.openedPageRouters.length; i++) {
                    let r = this.openedPageRouters[i];
                    if (r !== this.contextMenuTargetPageRoute) {
                        this.delPageRoute(r);
                        i--;
                    }
                }
                if (this.contextMenuTargetPageRoute.fullPath != this.$route.fullPath) {
                    this.$router.replace(this.contextMenuTargetPageRoute);
                }
            },

            // 重载指定标签页
            reloadRoute() {
                // 先销毁缓存
                this.delRouteCache(this.contextMenuTargetPageRoute.fullPath);
                // 跳到空页面，再跳回原路由页面
                this.$router.replace({
                    name: this.blankRouteName
                }).then(() => {
                    this.$router.replace(this.contextMenuTargetPageRoute);
                });
            },

            //重载页面
            reload() {
                // 先找到当前路由标签对象
                let i = this.getPageRouteIndex(this.$route.fullPath);
                let curr_router = this.openedPageRouters[i];

                // 先销毁缓存
                this.delRouteCache(curr_router.fullPath);
                // 跳到空页面，再跳回原路由页面
                this.$router.replace({
                    name: this.blankRouteName
                }).then(() => {
                    this.$router.replace(curr_router);
                });
            },

            //根据路径获取索引
            getPageRouteIndex(fullPath) {
                for (let i = 0; i < this.openedPageRouters.length; i++) {
                    if (this.openedPageRouters[i].fullPath === fullPath) {
                        return i;
                    }
                }
            },
			// 获取宽度
			getWidth() {
				if (this.$refs.tabs) {
					let _this = this
					let width = 0
					let childDoms = this.$refs.tabsul.children
					for (let i = 0; i < childDoms.length; i++) {
						width = childDoms[i].clientWidth + width + 5 + 20
					}
					if (width > this.$refs.tabs.offsetWidth) {
						this.showArrow = true
					}
				}
				
			},
			toLeft() {
				this.move = 0
			},
			toRight() {
				let width = 0
				let childDoms = this.$refs.tabsul.children
				for (let i = 0; i < childDoms.length; i++) {
					width = childDoms[i].clientWidth + width + 5 + 20
				}
				this.move =  this.$refs.tabsmovew.offsetWidth - width
			}
        }

    }
</script>
<style scoped>
	.nav-tabs{
		width: 100%;
		display: flex;
		padding: 8px 10px;
		box-sizing: border-box;
		border-bottom: 1px solid #9a9a9a;
	}
	.nav-click{
		width: calc(100% - 75px);
		overflow-x: auto;
		overflow-y: hidden;
		margin-right: 5px;
	}
	.nav-click::-webkit-scrollbar {
	    width: 8px;
	    height: 0px;
	    background-color: #fff;
	}
	
	.nav-click::-webkit-scrollbar-thumb {
	    display: block;
	    min-height: 0px;
	    min-width: 8px;
	    border-radius: 6px;
	    background-color: rgb(217, 217, 217);
	}
	
	.nav-click::-webkit-scrollbar-thumb:hover {
	    display: block;
	    min-height: 0px;
	    min-width: 8px;
	    border-radius: 6px;
	    background-color: rgb(159, 159, 159);
	}
	.nav-click ul{
		list-style-type: none;
		display: flex;
		padding: 0;
		margin: 0;
	}
	.nav-click-body{
		display: flex;
		box-sizing: border-box;
		height: 30px;
		margin-right: 5px;
		line-height: 30px;
		border: 1px solid #dcdfe6;
		border-radius: 4px;
		transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
		padding: 0 20px;
		box-sizing: border-box;
		list-style: none;
		font-size: 12px;
		font-weight: 500;
		color: #303133;
		position: relative;
		cursor: pointer;
		white-space: nowrap;
	}
	.nav-click-arrow{
		display: flex;
		box-sizing: border-box;
		height: 30px;
		margin-right: 5px;
		line-height: 30px;
		border: 1px solid #dcdfe6;
		border-radius: 4px;
		transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
		padding: 8px 10px;
		box-sizing: border-box;
		list-style: none;
		font-size: 12px;
		font-weight: 500;
		color: #303133;
		position: relative;
		cursor: pointer;
	}
	.nav-click-body-title{
		
	}
	.nav-click-show{
		background-color: var(--themeColor);
		color: #fff;
		border: 1px solid var(--themeColor);
	}
	.nav-click-body-close{
		margin-left: 10px;
		cursor: pointer;
	}
</style>
