<template>
  <div class="main">
    <div class="top-box">
      <div class="main-top_handle">
        <div class="title">Plastic - Circle Time</div>
      </div>
      <div class="handle-btn flex">
        <div class="handle-btn-item" @click="addRow">Add Row</div>
        <div class="handle-btn-item" @click="addColum">Add Column</div>
        <div class="handle-btn-item" @click="submitTable">Submit</div>
      </div>
    </div>
    <div class="table-cla">
      <div
        style="
          max-width: calc(100% - 30px);
          margin-right: 10px;
          overflow-x: auto;
        "
      >
        <table class="add-table">
          <tr style="background: #fff">
            <td>
              <div style="width: 60px">Operate</div>
            </td>
            <td>
              <div style="width: 100px">Material</div>
            </td>
            <td v-for="(it, ind) in cycleLst">
              <el-input
                v-model="it.colName"
                :disabled="type == 'detail'"
                placeholder=""
                style="width: 100px"
              />
            </td>
          </tr>
          <tr v-for="(item, index) in tableData">
            <td>
              <div class="btn-text" @click="deleteRow(index)">Delete</div>
            </td>
            <td>
              <el-input
                v-model="item.materialName"
                placeholder=""
                style="width: 100px"
                :disabled="type == 'detail'"
              />
            </td>
            <td v-for="(it, ind) in item.cycleLst">
              <el-input
                v-model="it.value"
                :class="
                  form.wall == cycleLst[ind].colName &&
                  form.material == item.materialName
                    ? 'custom-el-input-red'
                    : ''
                "
                :disabled="type == 'detail'"
                @input="(v) => (it.value = v.replace(/[^\d.]/g, ''))"
                placeholder=""
                style="width: 100px"
              />
            </td>
          </tr>
          <tr v-show="cycleLst.length > 0">
            <td>
              <div style="width: 60px"></div>
            </td>
            <td>
              <div style="width: 100px"></div>
            </td>
            <td v-for="(it, ind) in cycleLst">
              <div class="btn-text" @click="deleteColum(ind)">Delete</div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AddCycleTime,
  GetCycleTimeList,
  GetCycleTimeResult,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import "../../assets/css/custom-el-input-red.css";
export default {
  data() {
    return {
      TableHeight: 0,
      form: {
        moldClose: "",
        moldOpen: "",
        ejection: "",
        injection: "",
        holdingTime: "",
        coolingTime: "",
        total: "",
        wall: "",
        material: "",
      },
      wallOptions: [],
      materialOptions: [],
      tableData: [],
      cycleLst: [],
    };
  },
  mounted() {
    this.autoHeight();
    this.getInit();
  },
  methods: {
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getInit() {
      let _this = this;
      GetCycleTimeList("", (response) => {
        if (response && response.code == 200) {
          this.tableData = response.result;
          if (this.tableData.length > 0) {
            this.cycleLst = JSON.parse(
              JSON.stringify(this.tableData[0].cycleLst)
            );
          }
          this.materialOptions = [];
          this.wallOptions = [];
          this.tableData.forEach(function (val, index) {
            _this.materialOptions.push(val.materialName);
          });
          this.cycleLst.forEach(function (val, index) {
            _this.wallOptions.push(val.colName);
          });
          this.form.wall = "";
          this.form.material = "";
        }
      });
    },
    // 提交维护数据表格
    submitTable() {
      let data = [];
      let _this = this;
      let rowList = [];
      let colmunList = [];
      this.tableData.forEach(function (val, index) {
        val.cycleLst.forEach(function (cycleVal, cycleIndex) {
          data.push({
            rowName: val.materialName,
            rowIndex: index,
            colName: _this.cycleLst[cycleIndex].colName,
            value: cycleVal.value,
          });
        });
        rowList.push(val.materialName);
      });
      rowList.push("Material");
      let rowNameIsNull = false;
      let colmunNameIsNull = false;
      let valueNameIsNull = false;
      data.forEach(function (val, index) {
        if (val.rowName === "") {
          rowNameIsNull = true;
        }
        if (val.colName === "") {
          colmunNameIsNull = true;
        }
        if (val.value === "") {
          valueNameIsNull = true;
        }
      });
      this.cycleLst.forEach(function (val, index) {
        colmunList.push(val.colName);
      });
      if (rowNameIsNull) {
        commonUtil.showTipInfo(
          "Please complete the information! ",
          "",
          "warning"
        );
        return;
      }
      if (colmunNameIsNull) {
        commonUtil.showTipInfo(
          "Please complete the information! ",
          "",
          "warning"
        );
        return;
      }
      if (valueNameIsNull) {
        commonUtil.showTipInfo(
          "Please complete the information! ",
          "",
          "warning"
        );
        return;
      }
      if (Array.from(new Set(rowList)).length != rowList.length) {
        commonUtil.showTipInfo(
          "Material name cannot be repeated! ",
          "",
          "warning"
        );
        return;
      }
      if (Array.from(new Set(colmunList)).length != colmunList.length) {
        commonUtil.showTipInfo(
          "The list name cannot be repeated! ",
          "",
          "warning"
        );
        return;
      }
      AddCycleTime(data, (response) => {
        if (response && response.code == 200) {
          this.getInit();
        }
      });
    },
    // 添加列
    addColum() {
      this.cycleLst.push({
        colName: "",
      });
      this.tableData.forEach(function (val, index) {
        val.cycleLst.push({
          colName: "",
          value: "",
        });
      });
    },
    // 添加行
    addRow() {
      let rowData = {
        materialName: "",
        cycleLst: [],
      };
      rowData.materialName = "";
      this.cycleLst.forEach(function (val, index) {
        rowData.cycleLst.push({
          value: "",
          colName: "",
        });
      });
      this.tableData.push(rowData);
    },
    // 删除行
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    // 删除列
    deleteColum(index) {
      this.tableData.forEach(function (val, ind) {
        val.cycleLst.splice(index, 1);
      });
      this.cycleLst.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  padding-top: 70px;
  width: 100%;
  min-height: calc(100vh - 155px);
  background: url("../../assets/img/car-table-bg.png") no-repeat right;
  background-position: right 10% bottom 0;
  background-size: 650px 600px;
}
.top-box {
  padding-top: 15px;
  width: 100%;
  position: fixed;
  top: 70px;
  left: 235px;
  z-index: 999;
  background: #f2f2f2;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.add-top {
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #8c8c8c;
}
.dis-flex {
  display: flex;
  justify-content: space-around;
}
.line-32 {
  line-height: 32px;
}
.height-32 {
  height: 32px;
}
.padding-20 {
  padding: 20px;
  box-sizing: border-box;
}
.mgr-20 {
  margin-right: 20px;
}
.mgr-10 {
  margin-right: 10px;
}
.mgt-15 {
  margin-top: 12px;
}
.align-right {
  text-align: right;
}
.add-top-text {
  font-size: 14px;
  width: 150px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.size-16 {
  font-size: 16px;
}
.text-sub {
  font-size: 14px;
  margin-left: 20px;
  margin-top: 10px;
}
.btn-position {
  position: fixed;
  z-index: 10;
  top: 114px;
  width: calc(100% - 235px);
  padding: 20px 0;
  left: 222px;
  background-color: #fff;
}
.table-cla {
  position: relative;
  display: flex;
  margin-top: 10px;
}
.add-table {
  border-collapse: collapse;
}
.add-table td {
  border: 1px solid #dcdfe6;
  padding: 5px 10px;
  text-align: center;
}
.btn-text {
  color: #2f4cdd;
  cursor: pointer;
  font-size: 12px;
}
:deep .el-input__wrapper {
  background: transparent;
}
</style>