const info = {
    // 中文
    ZH: {
		new: 'New',
		edit: 'Edit',
		delete: 'Delete',
		import: 'Import',
		export: 'Export',
		downTemplate: 'Download template',
		cancle: 'Cancle',
		confirm: 'Confirm',
		search: 'Search',
		operate: 'Operate',
        nexteerPart: 'Nexteer Part',
        manufacturingPart: 'Manufacturing Part',
        deviceType: 'Device Type',
        status: 'Status',
        supplierName: 'Supplier Name',
        alternateOption: 'Alternate Option',
        type: 'Type',
        compDescription: 'Detailed Comp Description',
        currency: 'Currency',
		otherInfo: 'Other Info',
		ppapComplete: 'PPAP Complete',
		ppapDueDate: 'PPAP Due Date',
		note: 'Notes',
		no: 'No',
		year: 'Year',
		price: 'Price',
		noPN: 'Sub-Component PN',
		manufactureName: 'Manufacture Name',
		nexteerDirected: 'Nexteer Directed',
		description: 'Description',
		highPrice: 'Highest Price',
		mediumPrice: 'Medium  Price',
		lowPrice: 'Lowest Price',
		sharath: 'Sharath',
		remark: 'Remark',
		
		pleseEnterMain: '请先维护主表信息！',
		copy:"复制"
    },
    // 英文
    EN: {
		new: 'New',
		edit: 'Edit',
		delete: 'Delete',
		import: 'Import',
		export: 'Export',
		downTemplate: 'Download template',
		cancle: 'Cancle',
		confirm: 'Confirm',
		search: 'Search',
		operate: 'Operate',
        nexteerPart: 'Nexteer Part',
        manufacturingPart: 'Manufacturing Part',
        deviceType: 'Device Type',
        status: 'Status',
        supplierName: 'Supplier Name',
        alternateOption: 'Alternate Option',
        type: 'Type',
        compDescription: 'Detailed Comp Description',
        currency: 'Currency',
		otherInfo: 'Other Info',
		ppapComplete: 'PPAP Complete',
		ppapDueDate: 'PPAP Due Date',
		note: 'Notes',
		no: 'No',
		year: 'Year',
		price: 'Price',
		noPN: 'Sub-Component PN',
		manufactureName: 'Manufacture Name',
		nexteerDirected: 'Nexteer Directed',
		description: 'Description',
		highPrice: 'Highest Price',
		mediumPrice: 'Medium  Price',
		lowPrice: 'Lowest Price',
		sharath: 'Sharath',
		remark: 'Remark',
		
		pleseEnterMain: 'Please maintain the main table information first!',
		copy:"Copy"
    }
}
export default info