<template>
  <div class="Gen-info">
    <!-- <div class="Gen-info-l">
      <div class="img-box"></div>
    </div> -->
    <div class="Gen-info-r">
      <div class="gen-main-left">
        <el-row :gutter="20" justify="space-around">
          <el-col :span="10">
            <p>{{ $t("userlists.userlist.quoteType") }}</p>
            <el-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              v-model="form.quoteTypeId"
              :disabled="
                type == 'detail' ||
                (operateType == 'operation' && type != 'editImport')
              "
              placeholder=""
              @change="quoteTypeChange"
            >
              <el-option
                v-for="item in QuoteTypeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="10">
            <p>{{ $t("changePriceLists.changePriceList.programName") }}</p>
            <el-input
              v-model="form.programName"
              :disabled="
                type == 'detail' ||
                (operateType == 'operation' && type != 'editImport')
              "
              type="text"
            />
          </el-col>
        </el-row>
        <el-row :gutter="20" justify="space-around">
          <el-col :span="10">
            <p>{{ $t("changePriceLists.changePriceList.ecrNo") }}</p>
            <el-input
              v-model="form.ecrno"
              :disabled="
                type == 'detail' ||
                (operateType == 'operation' && type != 'editImport')
              "
              placeholder=""
            />
          </el-col>
          <el-col :span="10">
            <p>{{ $t("changePriceLists.changePriceList.commodity") }}</p>
            <el-tree-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              v-model="form.commodityId"
              :disabled="
                type == 'detail' ||
                (operateType == 'operation' && type != 'editImport')
              "
              :data="CommodityOptions"
              :props="defaultProps"
              check-strictly
              :render-after-expand="false"
              :default-expand-all="true"
              @change="changeCommodityId"
            >
              <template #default="{ data: { Name } }">
                <el-tooltip :content="Name" placement="top" effect="light">
                  {{ Name }}
                </el-tooltip>
              </template>
            </el-tree-select>
          </el-col>
        </el-row>
        <el-row :gutter="20" justify="space-around">
          <el-col :span="10">
            <p>{{ $t("changePriceLists.changePriceList.partDescription") }}</p>
            <el-input
              v-model="form.partDescription"
              @input="checkDescription(form.partDescription)"
              :disabled="
                type == 'detail' ||
                (operateType == 'operation' && type != 'editImport')
              "
              placeholder=""
            />
          </el-col>
          <el-col :span="10">
            <p>{{ $t("changePriceLists.changePriceList.partNumber") }}</p>
            <el-input
              v-model="form.partNumber"
              @input="(v) => (form.partNumber = v.replace(/[^\d.]/g, ''))"
              :disabled="
                type == 'detail' ||
                (operateType == 'operation' && type != 'editImport')
              "
              placeholder=""
            />
          </el-col>
        </el-row>
        <el-row :gutter="20" justify="space-around">
          <el-col :span="10">
            <p>{{ $t("changePriceLists.changePriceList.partRevision") }}</p>
            <el-input
              v-model="form.partRevision"
              :disabled="
                type == 'detail' ||
                (operateType == 'operation' && type != 'editImport')
              "
              placeholder=""
            />
          </el-col>
          <el-col :span="10"> </el-col>
        </el-row>
        <!--  -->
        <div v-if="genCurrOptions.length > 0">
          <div
            style="margin: 30px 0"
            v-for="(item, genIndex) in genList"
            :key="genIndex"
          >
            <el-row :gutter="20" justify="space-around">
              <el-col :span="10">
                <p>{{ $t("changePriceLists.changePriceList.currency") }}</p>
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.Currency"
                  filterable
                  :disabled="
                    (type == 'detail' || operateType == 'operation') &&
                    status != 0
                  "
                  @change="currencyChange(genIndex)"
                >
                  <el-option
                    v-for="it in genCurrOptions[genIndex].currencyOptions"
                    :key="it.currency"
                    :label="it.currencyName"
                    :value="it.currency"
                  />
                </el-select>
              </el-col>
              <el-col :span="10">
                <p>
                  {{ $t("changePriceLists.changePriceList.reportCurrency") }}
                </p>
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.ReportCurrency"
                  filterable
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                  @change="reportCurrencyChange(genIndex)"
                >
                  <el-option
                    v-for="it in genCurrOptions[genIndex].currencyReportOptions"
                    :key="it.currencyCNYReportCurrency"
                    :label="it.currencyCNYReportCurrencyName"
                    :value="it.currencyCNYReportCurrency"
                  />
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="20" justify="space-around">
              <el-col :span="10">
                <p>{{ $t("changePriceLists.changePriceList.exchangeRate") }}</p>
                <el-input
                  v-model="item.rate"
                  @input="(v) => (item.rate = v.replace(/[^\d.]/g, ''))"
                  @change="rateChange($event, genIndex)"
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                />
              </el-col>
              <el-col :span="10">
                <p>{{ $t("changePriceLists.changePriceList.region") }}</p>
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.regionId"
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                >
                  <el-option
                    v-for="item in RegionOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="20" justify="space-around">
              <el-col :span="10">
                <p>{{ $t("changePriceLists.changePriceList.supplierName") }}</p>
                <el-input
                  v-model="item.supplierName"
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                  placeholder=""
                />
              </el-col>
              <el-col :span="10">
                <p>
                  {{
                    $t("changePriceLists.changePriceList.materialDescription")
                  }}
                </p>
                <el-input
                  v-model="item.materialDescription"
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                  placeholder=""
                />
              </el-col>
            </el-row>
            <el-row :gutter="20" justify="space-around">
              <el-col :span="10">
                <p>{{ $t("changePriceLists.changePriceList.peakVolume") }}</p>
                <el-input
                  v-model="item.peakVolume"
                  @input="transferNum(item, 'peakVolume'), toCompute()"
                  :disabled="
                    type == 'detail' ||
                    (operateType == 'operation' && type != 'editImport')
                  "
                  placeholder=""
                />
              </el-col>
              <el-col :span="10">
                <p>
                  {{ $t("changePriceLists.changePriceList.lifetimeVolume") }}
                </p>
                <el-input
                  v-model="item.lifetimeVolume"
                  disabled
                  placeholder=""
                />
              </el-col>
            </el-row>
            <el-row :gutter="20" justify="space-around">
              <el-col :span="10">
                <p>{{ $t("changePriceLists.changePriceList.pegPoint") }}</p>
                <el-input
                  v-model="item.pegPoint"
                  :disabled="
                    type == 'detail' ||
                    (operateType == 'operation' && type != 'editImport')
                  "
                  placeholder=""
                />
              </el-col>
              <el-col :span="10"> </el-col>
            </el-row>
            <el-row :gutter="20" justify="space-around">
              <el-col :span="22">
                <p>
                  {{ $t("changePriceLists.changePriceList.comments") }}
                </p>
                <el-input
                  v-model="item.comment"
                  :rows="3"
                  type="textarea"
                  placeholder=""
                  :disabled="
                    type == 'detail' ||
                    (operateType == 'operation' && type != 'editImport')
                  "
                />
              </el-col>
            </el-row>
            <!-- Year-Volume Year-Volume Year-Volume -->
            <div style="padding-left: 30px">
              <div class="Year-Volume">
                <p>
                  {{ $t("changePriceLists.changePriceList.year") }}
                </p>
                <p>
                  {{ $t("changePriceLists.changePriceList.volume") }}
                </p>
                <span
                  v-show="
                    (type != 'detail' && operateType == 'application') ||
                    type == 'editImport'
                  "
                >
                  {{ $t("changePriceLists.changePriceList.operation") }}
                </span>
              </div>
              <div class="Year-Volume-ipt" v-for="(it, ind) in yearVolumeList">
                <el-input
                  class="year-ipt"
                  type="text"
                  v-model="it.year"
                  @input="(v) => (it.year = v.replace(/[^\d.]/g, ''))"
                  :disabled="
                    type == 'detail' ||
                    (operateType == 'operation' && type != 'editImport')
                  "
                />
                <el-input
                  class="Volume-ipt"
                  v-model="it.volume"
                  :disabled="
                    type == 'detail' ||
                    (operateType == 'operation' && type != 'editImport')
                  "
                  placeholder=""
                  style="width: 100px"
                  @input="transferFormat($event, it, 'volume', genIndex)"
                  @change="blurVol(genIndex)"
                  @blur="transferNum(it, 'volume'), blurVol(genIndex)"
                  type="text"
                />
                <div
                  class="Operation-box"
                  v-show="
                    (type != 'detail' && operateType == 'application') ||
                    type == 'editImport'
                  "
                >
                  <el-tooltip
                    :content="$t('changePriceLists.changePriceList.copy')"
                    placement="top-start"
                  >
                    <el-icon @click="addColoum(genIndex, ind)"
                      ><CopyDocument
                    /></el-icon>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('unifiedPrompts.unifiedPrompt.delete')"
                    placement="top-start"
                  >
                    <el-icon @click="toDetele(ind, 'year', genIndex)"
                      ><DeleteFilled
                    /></el-icon>
                  </el-tooltip>
                </div>
              </div>
              <div
                v-show="
                  (type != 'detail' && operateType == 'application') ||
                  type == 'editImport'
                "
                class="Year-Volume-ipt Year-Volume-add-btn"
                @click="addColoum(genIndex)"
              >
                <el-icon><Plus /></el-icon>
              </div>
            </div>
            <div class="General-Infor-top" style="margin-top: 10px">
              <div></div>
              <div
                class="flex"
                style="justify-content: flex-end"
                v-show="type != 'detail' && operateType == 'application'"
              >
                <div
                  class="submit-btn"
                  @click="toDetele(genIndex, 'gen')"
                  style="background: #b2bde7"
                >
                  {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
                </div>
                <div
                  class="submit-btn"
                  @click="copyGen(genIndex)"
                  style="background: #b2bde7"
                >
                  {{ $t("changePriceLists.changePriceList.copy") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tcm-form-solid"
          v-show="type != 'detail' && operateType == 'application'"
          @click="addGen"
        >
          <el-icon :size="35" color="#8c8c8c"><Plus /></el-icon>
        </div>
      </div>
      <div class="gen-main-right">
        <div class="General-Infor">
          <div class="General-Infor-top">
            <div>{{ $t("changePriceLists.changePriceList.attachments") }}</div>
            <div></div>
          </div>
          <div class="Attach-box">
            <div class="flex" style="margin-top: 10px">
              <div
                v-show="
                  (type != 'detail' && operateType == 'application') ||
                  type == 'editImport'
                "
              >
                <el-upload
                  class="custom-el-upload-list-none"
                  style="display: inline-block; margin-top: 10px"
                  action="/"
                  :auto-upload="false"
                  multiple
                  :limit="10"
                  :file-list="fileListx"
                  :on-change="filechange"
                >
                  <div class="upload-box">
                    <div class="upload-btn">
                      <div class="upload-icon">
                        <el-icon><UploadFilled /></el-icon>
                      </div>
                    </div>
                    <div class="upload-text">
                      <p>
                        {{ $t("changePriceLists.changePriceList.UploadFiles") }}
                      </p>
                      <span>PDF, DOC, PPT, JPG, PNG</span>
                    </div>
                  </div>
                </el-upload>
              </div>
              <div class="file-list-box flex">
                <div class="file-box" v-for="(item, index) in fileListx">
                  <div class="upload-btn">
                    <div class="upload-icon">
                      <img
                        src="../../assets/img/tcm/doc.png"
                        v-show="item.name.indexOf('.doc') > -1"
                      />
                      <img
                        src="../../assets/img/tcm/tupian.png"
                        v-show="
                          item.name.indexOf('.png') > -1 ||
                          item.name.indexOf('.jpg') > -1 ||
                          item.name.indexOf('.jpeg') > -1 ||
                          item.name.indexOf('.webp') > -1
                        "
                      />
                      <img
                        src="../../assets/img/tcm/pdf.png"
                        v-show="item.name.indexOf('.pdf') > -1"
                      />
                      <img
                        src="../../assets/img/tcm/ppt.png"
                        v-show="item.name.indexOf('.ppt') > -1"
                      />
                      <img
                        src="../../assets/img/tcm/txt.png"
                        v-show="item.name.indexOf('.txt') > -1"
                      />
                      <img
                        src="../../assets/img/tcm/xls.png"
                        v-show="
                          item.name.indexOf('.xls') > -1 ||
                          item.name.indexOf('.xlsx') > -1
                        "
                      />
                    </div>
                  </div>
                  <div class="upload-text">
                    <p>{{ item.name }}</p>
                    <!-- <span>xx KB</span> -->
                  </div>
                  <div
                    class="file-del"
                    v-show="
                      (type != 'detail' && operateType == 'application') ||
                      type == 'editImport'
                    "
                    @click="toDetele(index, 'file')"
                  >
                    <el-icon><Close /></el-icon>
                  </div>
                  <el-tooltip
                    :content="
                      $t('changePriceLists.changePriceList.downloadFile')
                    "
                    placement="top-start"
                  >
                    <div
                      class="file-del"
                      style="right: 20px; background-color: #44814e"
                      @click="downloadFile(index)"
                    >
                      <el-icon><Download /></el-icon>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div
              class="tcm-content-form"
              v-show="operateType != 'tcmResult' && type != 'editImport'"
            >
              <div class="tcm-content-titlex">
                <div style="line-height: 38px">
                  {{ $t("changePriceLists.changePriceList.email") }}
                </div>
                <div v-show="type != 'detail' && operateType == 'application'">
                  <el-tooltip
                    :content="
                      $t('changePriceLists.changePriceList.SelectEmail')
                    "
                    placement="top-start"
                  >
                    <img
                      src="../../assets/img/tcm/add.svg"
                      style="margin: 8px 10px 0 10px; cursor: pointer"
                      @click="addEmail"
                    />
                  </el-tooltip>
                </div>
                <div v-show="type != 'detail' && operateType == 'application'">
                  <el-tooltip
                    :content="
                      $t(
                        'changePriceLists.changePriceList.SelectHistoryMailbox'
                      )
                    "
                    placement="top-start"
                  >
                    <img
                      src="../../assets/img/tcm/memberAdd.svg"
                      style="margin-top: 8px; cursor: pointer"
                      @click="openEmailDia"
                    />
                  </el-tooltip>
                </div>
                <div v-show="type != 'detail' && operateType == 'application'">
                  <div
                    style="
                      margin-top: 5px;
                      margin-left: 10px;
                      margin-right: 10px;
                      font-size: 20px;
                      cursor: pointer;
                    "
                    @click="openCC"
                  >
                    cc
                  </div>
                </div>
                <div v-show="type != 'detail' && operateType == 'application'">
                  <el-tooltip
                    :content="
                      $t('changePriceLists.changePriceList.ExternalImport')
                    "
                    placement="top-start"
                  >
                    <img
                      src="../../assets/img/tcm/chaosong.png"
                      style="margin-top: 8px; cursor: pointer; width: 20px"
                      @click="openCCDia"
                    />
                  </el-tooltip>
                </div>
              </div>
              <!-- <el-col :span="12">
                    <div class="tcm-content-title-comment">
                      {{ $t("changePriceLists.changePriceList.comment") }}
                    </div>
                  </el-col> -->

              <div style="margin-top: 10px">
                <table class="gen-table">
                  <tr style="background: #fff">
                    <td style="width: 250px">
                      {{ $t("changePriceLists.changePriceList.email") }}
                    </td>
                    <td>
                      {{ $t("changePriceLists.changePriceList.name") }}
                    </td>
                    <!-- v-show="type == 'detail' || (operateType == 'operation')" -->
                    <td>
                      {{ $t("changePriceLists.changePriceList.type") }}
                    </td>
                    <td
                      v-show="
                        (type != 'detail' && operateType == 'application') ||
                        type == 'editImport'
                      "
                    >
                      {{ $t("changePriceLists.changePriceList.operation") }}
                    </td>
                  </tr>
                  <tr
                    v-for="(item, index) in mailTable"
                    style="background: #fff; font-size: 12px"
                  >
                    <td>{{ item.email }}</td>
                    <td>{{ item.name }}</td>
                    <td>
                      {{
                        item.type == 1
                          ? $t("changePriceLists.changePriceList.operator")
                          : item.type == 2
                          ? $t("changePriceLists.changePriceList.approver")
                          : $t("changePriceLists.changePriceList.cc")
                      }}
                    </td>
                    <td
                      v-show="type != 'detail' && operateType == 'application'"
                    >
                      <el-icon
                        style="font-size: 20px; color: #445581"
                        @click="toDetele(index, 'email')"
                        ><DeleteFilled
                      /></el-icon>
                      <!-- <img
                        src="../../assets/img/tcm/cuowu.png"
                        style="width: 20px; height: 20px; cursor: pointer"
                        @click="toDetele(index, 'email')"
                      /> -->
                    </td>
                  </tr>
                </table>
              </div>
              <div style="margin-top: 20px">
                <el-input
                  style="width: 80%"
                  v-model="form.mailComment"
                  :disabled="type == 'detail' || operateType == 'operation'"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 4 }"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <template v-if="type != 'editImport'">
                <p>{{ $t("changePriceLists.changePriceList.dueDate") }}</p>
                <el-date-picker
                  style="width: 100%"
                  v-model="form.doDate"
                  :disabled-date="disabledDate"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :disabled="type == 'detail' || operateType == 'operation'"
                  type="date"
                  placeholder=""
                />
              </template>
              <template v-else>
                <p>{{ $t("tcmdatalists.tcmdatalist.dueDate") }}</p>
                <el-date-picker
                  style="width: 100%"
                  v-model="form.approvedTime"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  type="date"
                  placeholder=""
                />
              </template>
            </el-col>
            <el-col :span="12">
              <p>{{ $t("changePriceLists.changePriceList.urgency") }}</p>
              <el-select
                clearable
                v-if="
                  $t('tcmdatalists.tcmdatalist.urgencyOptionss') ==
                  'urgencyOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.urgencyId"
                :disabled="type == 'detail' || operateType == 'operation'"
              >
                <el-option
                  v-for="item in urgencyOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t('tcmdatalists.tcmdatalist.urgencyOptionss') ==
                  'urgencyOptionss'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.urgencyId"
                :disabled="
                  type == 'detail' ||
                  (operateType == 'operation' && type != 'editImport')
                "
              >
                <el-option
                  v-for="item in urgencyOptionss"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-show="operateType == 'operation'">
            <el-col :span="12">
              <p>
                {{ $t("changePriceLists.changePriceList.productionBatch") }}
              </p>
              <el-input v-model="form.lotSize" disabled placeholder="" />
            </el-col>
            <el-col :span="12">
              <p>
                {{ $t("changePriceLists.changePriceList.annualProduction") }}
              </p>
              <el-input
                v-model="form.productionYear"
                :disabled="type == 'detail'"
                placeholder=""
                @input="
                  transferFormat($event, form, 'productionYear'), toCompute()
                "
              />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
  <!-- --- -->
  <!-- <div class="tcm-content">
    <div class="tcm-content-title">
      {{ $t("changePriceLists.changePriceList.generalInformation") }}
    </div>
    <div class="tcm-content-form">
      <el-form :inline="true" :model="form" label-width="150px">
        <el-row>
          <el-col :span="8" class="custom-el-form-rules">
            <el-form-item :label="$t('userlists.userlist.quoteType')">
              <el-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.quoteTypeId"
                :disabled="
                  type == 'detail' ||
                  (operateType == 'operation' && type != 'editImport')
                "
                placeholder=""
                style="width: 214px"
                @change="quoteTypeChange"
              >
                <el-option
                  v-for="item in QuoteTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col> 
          <el-col :span="8"> </el-col>
          <el-col :span="8"> </el-col>
        </el-row>
        <el-row>
          <el-col
            :span="8"
            :class="
              form.quoteTypeName == 'New pursuit' ||
              form.quoteTypeName == 'Soucing'
                ? 'custom-el-form-rules'
                : ''
            "
          >
            <el-form-item
              :label="$t('changePriceLists.changePriceList.programName')"
            >
              <el-input
                v-model="form.programName"
                :disabled="
                  type == 'detail' ||
                  (operateType == 'operation' && type != 'editImport')
                "
                placeholder=""
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.ecrNo')"
              :class="
                form.quoteTypeName == 'New pursuit'
                  ? 'custom-el-form-rules'
                  : ''
              "
            >
              <el-input
                v-model="form.ecrno"
                :disabled="
                  type == 'detail' ||
                  (operateType == 'operation' && type != 'editImport')
                "
                placeholder=""
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.commodity')"
              class="custom-el-form-rules"
            >
              <!== <el-tree-select clearable  
								:placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" 
								v-model="form.commodityId" 
								:disabled=" type == 'detail' || operateType == 'operation'" 
								:data="CommodityOptions" :props="defaultProps" 
								check-strictly 
								:render-after-expand="false" 
								:default-expand-all="true" 
								style="width: 214px;"/> ==>
              <el-tree-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.commodityId"
                :disabled="
                  type == 'detail' ||
                  (operateType == 'operation' && type != 'editImport')
                "
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
                style="width: 214px"
                @change="changeCommodityId"
              >
                <template #default="{ data: { Name } }">
                  <el-tooltip :content="Name" placement="top" effect="light">
                    {{ Name }}
                  </el-tooltip>
                </template>
              </el-tree-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.partDescription')"
              class="custom-el-form-rules"
            >
              <el-input
                v-model="form.partDescription"
                @input="checkDescription(form.partDescription)"
                :disabled="
                  type == 'detail' ||
                  (operateType == 'operation' && type != 'editImport')
                "
                placeholder=""
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.partNumber')"
              :class="
                form.quoteTypeName == 'New pursuit' ||
                form.quoteTypeName == 'Soucing' ||
                form.quoteTypeName == 'SOP'
                  ? 'custom-el-form-rules'
                  : ''
              "
            >
              <el-input
                v-model="form.partNumber"
                @input="(v) => (form.partNumber = v.replace(/[^\d.]/g, ''))"
                :disabled="
                  type == 'detail' ||
                  (operateType == 'operation' && type != 'editImport')
                "
                placeholder=""
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.partRevision')"
            >
              <el-input
                v-model="form.partRevision"
                :disabled="
                  type == 'detail' ||
                  (operateType == 'operation' && type != 'editImport')
                "
                placeholder=""
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-if="genCurrOptions.length > 0">
      <div
        class="tcm-content-form"
        v-for="(item, genIndex) in genList"
        :key="genIndex"
      >
        <el-form :inline="true" :model="form" label-width="150px">
          <!==-NoStated状态可修改 开始-==>
          <el-row>
            <el-col :span="8" class="custom-el-form-rules">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.currency')"
              >
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.Currency"
                  filterable
                  :disabled="
                    (type == 'detail' || operateType == 'operation') &&
                    status != 0
                  "
                  @change="currencyChange(genIndex)"
                  style="width: 214px"
                >
                  <el-option
                    v-for="it in genCurrOptions[genIndex].currencyOptions"
                    :key="it.currency"
                    :label="it.currencyName"
                    :value="it.currency"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" class="custom-el-form-rules">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.reportCurrency')"
              >
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.ReportCurrency"
                  filterable
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                  @change="reportCurrencyChange(genIndex)"
                  style="width: 214px"
                >
                  <el-option
                    v-for="it in genCurrOptions[genIndex].currencyReportOptions"
                    :key="it.currencyCNYReportCurrency"
                    :label="it.currencyCNYReportCurrencyName"
                    :value="it.currencyCNYReportCurrency"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" class="custom-el-form-rules">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.exchangeRate')"
              >
                <el-input
                  v-model="item.rate"
                  @input="(v) => (item.rate = v.replace(/[^\d.]/g, ''))"
                  @change="rateChange($event, genIndex)"
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" class="custom-el-form-rules">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.region')"
              >
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.regionId"
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                  style="width: 214px"
                >
                  <el-option
                    v-for="item in RegionOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.supplierName')"
              >
                <el-input
                  v-model="item.supplierName"
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="
                  $t('changePriceLists.changePriceList.materialDescription')
                "
              >
                <el-input
                  v-model="item.materialDescription"
                  :disabled="
                    (type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')) &&
                    status != 0
                  "
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!==-NoStated状态可修改 结束-==>
          <el-row>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.peakVolume')"
              >
                <el-input
                  v-model="item.peakVolume"
                  @input="transferNum(item, 'peakVolume'), toCompute()"
                  :disabled="
                    type == 'detail' ||
                    (operateType == 'operation' && type != 'editImport')
                  "
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.lifetimeVolume')"
              >
                <el-input
                  v-model="item.lifetimeVolume"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.pegPoint')"
              >
                <el-input
                  v-model="item.pegPoint"
                  :disabled="
                    type == 'detail' ||
                    (operateType == 'operation' && type != 'editImport')
                  "
                  placeholder=""
                  style="width: 214px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="operateType != 'tcmResult'">
            <el-col :span="22">
              <el-form-item
                :label="$t('changePriceLists.changePriceList.comments')"
                style="width: 100%"
              >
                <el-input
                  v-model="item.comment"
                  :rows="3"
                  type="textarea"
                  placeholder=""
                  :disabled="
                    type == 'detail' ||
                    (operateType == 'operation' && type != 'editImport')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="gen-table-cla" v-show="operateType != 'tcmResult'">
          <div
            style="
              max-width: calc(100% - 30px);
              margin-right: 10px;
              overflow-x: auto;
            "
          >
            <table class="gen-table">
              <tr>
                <td>{{ $t("changePriceLists.changePriceList.year") }}</td>
                <td v-for="(it, ind) in item.yearVolumeList">
                  <el-input
                    v-model="it.year"
                    @input="(v) => (it.year = v.replace(/[^\d.]/g, ''))"
                    :disabled="
                      type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')
                    "
                    placeholder=""
                    style="width: 100px"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t("changePriceLists.changePriceList.volume") }}</td>
                <td v-for="(it, ind) in item.yearVolumeList" :key="'vol' + ind">
                  <el-input
                    v-model="it.volume"
                    :disabled="
                      type == 'detail' ||
                      (operateType == 'operation' && type != 'editImport')
                    "
                    placeholder=""
                    style="width: 100px"
                    @input="transferFormat($event, it, 'volume', genIndex)"
                    @change="blurVol(genIndex)"
                    @blur="transferNum(it, 'volume'), blurVol(genIndex)"
                  />
                </td>
              </tr>
              <tr
                v-show="
                  (type != 'detail' && operateType == 'application') ||
                  type == 'editImport'
                "
              >
                <td>{{ $t("changePriceLists.changePriceList.operation") }}</td>
                <td v-for="(it, ind) in item.yearVolumeList">
                  <img
                    src="../../assets/img/tcm/fuzhi.png"
                    style="
                      width: 20px;
                      height: 20px;
                      cursor: pointer;
                      margin-right: 10px;
                    "
                    @click="addColoum(genIndex, ind)"
                  />
                  <img
                    src="../../assets/img/tcm/cuowu.png"
                    style="width: 20px; height: 20px; cursor: pointer"
                    @click="toDetele(ind, 'year', genIndex)"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div>
            <img
              v-show="
                (type != 'detail' && operateType == 'application') ||
                type == 'editImport'
              "
              src="../../assets/img/tcm/add.svg"
              style="width: 20px; height: 20px; cursor: pointer"
              @click="addColoum(genIndex)"
            />
          </div>
        </div>
        <div
          class="align-right mgt-5"
          v-show="type != 'detail' && operateType == 'application'"
        >
          <el-button type="info" @click="toDetele(genIndex, 'gen')">{{
            $t("unifiedPrompts.unifiedPrompt.delete")
          }}</el-button>
          <el-button type="info" @click="copyGen(genIndex)">{{
            $t("changePriceLists.changePriceList.copy")
          }}</el-button>
        </div>
      </div>
    </div>
    <div
      class="tcm-form-solid"
      @click="addGen"
      v-show="type != 'detail' && operateType == 'application'"
    >
      <el-icon :size="35" color="#8c8c8c"><Plus /></el-icon>
    </div>
    <div
      class="tcm-content-form"
      style="padding: 20px 80px"
      v-show="operateType != 'tcmResult'"
    >
      <el-row>
        <el-col :span="2">
          <div class="tcm-content-titlex" style="line-height: 40px">
            {{ $t("changePriceLists.changePriceList.attachments") }}
          </div>
        </el-col>
        <!== <div class="file-cla" v-show="fileListx.length > 0">
					<div v-for="(item, index) in fileListx" style="margin-right: 10px;">
						<div>
							<img src="../../assets/img/tcm/doc.png" v-show="item.name.indexOf('.doc') > -1" style="width: 30px;height: 30px;"/>
							<img src="../../assets/img/tcm/tupian.png" v-show="item.name.indexOf('.png') > -1 || item.name.indexOf('.jpg') > -1 || item.name.indexOf('.jpeg') > -1 || item.name.indexOf('.webp') > -1" style="width: 30px;height: 30px;"/>
							<img src="../../assets/img/tcm/pdf.png" v-show="item.name.indexOf('.pdf') > -1" style="width: 30px;height: 30px;"/>
							<img src="../../assets/img/tcm/ppt.png" v-show="item.name.indexOf('.ppt') > -1" style="width: 30px;height: 30px;"/>
							<img src="../../assets/img/tcm/txt.png" v-show="item.name.indexOf('.txt') > -1" style="width: 30px;height: 30px;"/>
							<img src="../../assets/img/tcm/doc.png" v-show="item.name.indexOf('.doc') > -1" style="width: 30px;height: 30px;"/>
							<img src="../../assets/img/tcm/xls.png" v-show="item.name.indexOf('.xls') > -1 || item.name.indexOf('.xlsx') > -1" style="width: 30px;height: 30px;"/>
						</div>
						<div style="text-align: center;">
							<img  v-show="type != 'detail' && operateType == 'application'" src="../../assets/img/tcm/cuowu.png" style="width: 12px;height: 12px;margin-right: 4px;cursor: pointer;" @click="toDetele(index, 'file')"/>
							<img src="../../assets/img/tcm/yunxiazai.png" style="width: 12px;height: 12px;cursor: pointer;" @click="downloadFile(index)"/>
						</div>
					</div>
				</div> ==>
        <el-col
          :span="2"
          v-show="
            (type != 'detail' && operateType == 'application') ||
            type == 'editImport'
          "
        >
          <el-upload
            class="custom-el-upload-list-none"
            style="display: inline-block; margin-top: 10px"
            action="/"
            :auto-upload="false"
            multiple
            :limit="10"
            :file-list="fileListx"
            :on-change="filechange"
          >
            <el-button size="small">{{
              $t("changePriceLists.changePriceList.upload")
            }}</el-button>
          </el-upload>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px" v-show="fileListx.length > 0">
        <div
          class="dis-flex"
          style="width: 100%"
          v-for="(item, index) in fileListx"
        >
          <div>
            <img
              src="../../assets/img/tcm/doc.png"
              v-show="item.name.indexOf('.doc') > -1"
              style="width: 30px; height: 30px"
            />
            <img
              src="../../assets/img/tcm/tupian.png"
              v-show="
                item.name.indexOf('.png') > -1 ||
                item.name.indexOf('.jpg') > -1 ||
                item.name.indexOf('.jpeg') > -1 ||
                item.name.indexOf('.webp') > -1
              "
              style="width: 30px; height: 30px"
            />
            <img
              src="../../assets/img/tcm/pdf.png"
              v-show="item.name.indexOf('.pdf') > -1"
              style="width: 30px; height: 30px"
            />
            <img
              src="../../assets/img/tcm/ppt.png"
              v-show="item.name.indexOf('.ppt') > -1"
              style="width: 30px; height: 30px"
            />
            <img
              src="../../assets/img/tcm/txt.png"
              v-show="item.name.indexOf('.txt') > -1"
              style="width: 30px; height: 30px"
            />
            <!== <img src="../../assets/img/tcm/doc.png" v-show="item.name.indexOf('.doc') > -1" style="width: 30px;height: 30px;"/> ==>
            <img
              src="../../assets/img/tcm/xls.png"
              v-show="
                item.name.indexOf('.xls') > -1 ||
                item.name.indexOf('.xlsx') > -1
              "
              style="width: 30px; height: 30px"
            />
          </div>
          <div class="file-name">{{ item.name }}</div>
          <div style="line-height: 36px">
            <img
              v-show="
                (type != 'detail' && operateType == 'application') ||
                type == 'editImport'
              "
              src="../../assets/img/tcm/cuowu.png"
              style="
                width: 12px;
                height: 12px;
                margin-right: 4px;
                cursor: pointer;
              "
              @click="toDetele(index, 'file')"
            />
            <img
              src="../../assets/img/tcm/yunxiazai.png"
              style="width: 12px; height: 12px; cursor: pointer"
              @click="downloadFile(index)"
            />
          </div>
        </div>
      </el-row>
    </div>
    <div class="tcm-content-form" v-show="operateType != 'tcmResult'">
      <el-form :inline="true" :model="form" label-width="150px">
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.dueDate')"
              v-show="type != 'editImport'"
              class="shortWidth"
            >
              <el-date-picker
                v-model="form.doDate"
                :disabled-date="disabledDate"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                :disabled="type == 'detail' || operateType == 'operation'"
                type="date"
                placeholder=""
              />
            </el-form-item>
            <el-form-item
              :label="$t('tcmdatalists.tcmdatalist.dueDate')"
              v-show="type == 'editImport'"
              class="shortWidth"
            >
              <el-date-picker
                v-model="form.approvedTime"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                type="date"
                placeholder=""
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.urgency')"
            >
              <el-select
                clearable
                v-if="
                  $t('tcmdatalists.tcmdatalist.urgencyOptionss') ==
                  'urgencyOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.urgencyId"
                :disabled="type == 'detail' || operateType == 'operation'"
              >
                <el-option
                  v-for="item in urgencyOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t('tcmdatalists.tcmdatalist.urgencyOptionss') ==
                  'urgencyOptionss'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.urgencyId"
                :disabled="
                  type == 'detail' ||
                  (operateType == 'operation' && type != 'editImport')
                "
              >
                <el-option
                  v-for="item in urgencyOptionss"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div
      class="tcm-content-form"
      style="padding: 20px 80px"
      v-show="operateType != 'tcmResult' && type != 'editImport'"
    >
      <el-row>
        <el-col :span="12">
          <div class="tcm-content-titlex">
            <div style="line-height: 38px">
              {{ $t("changePriceLists.changePriceList.email") }}
            </div>
            <!== <el-input  v-show="type != 'detail' && operateType == 'application'" v-model="mail" :placeholder="$t('changePriceLists.changePriceList.emails')" size="small" style="width: 150px; margin: 0 0 0 20px;"/>
						<el-input  v-show="type != 'detail' && operateType == 'application'" v-model="name" :placeholder="$t('changePriceLists.changePriceList.names')" size="small" style="width: 150px; margin: 0 20px;"/> ==>
            <div v-show="type != 'detail' && operateType == 'application'">
              <img
                src="../../assets/img/tcm/add.svg"
                style="margin: 8px 10px 0 10px; cursor: pointer"
                @click="addEmail"
              />
            </div>
            <div v-show="type != 'detail' && operateType == 'application'">
              <img
                src="../../assets/img/tcm/memberAdd.svg"
                style="margin-top: 8px; cursor: pointer"
                @click="openEmailDia"
              />
            </div>
            <div v-show="type != 'detail' && operateType == 'application'">
              <div
                style="
                  margin-top: 5px;
                  margin-left: 10px;
                  margin-right: 10px;
                  font-size: 20px;
                  cursor: pointer;
                "
                @click="openCC"
              >
                cc
              </div>
            </div>
            <div v-show="type != 'detail' && operateType == 'application'">
              <img
                src="../../assets/img/tcm/chaosong.png"
                style="margin-top: 8px; cursor: pointer; width: 20px"
                @click="openCCDia"
              />
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="tcm-content-title-comment">
            {{ $t("changePriceLists.changePriceList.comment") }}
          </div>
        </el-col>
      </el-row>

      <div style="margin-top: 20px">
        <el-row>
          <el-col :span="12">
            <table class="gen-table">
              <tr>
                <td style="width: 250px">
                  {{ $t("changePriceLists.changePriceList.email") }}
                </td>
                <td>{{ $t("changePriceLists.changePriceList.name") }}</td>
                <!== v-show="type == 'detail' || (operateType == 'operation')" ==>
                <td>{{ $t("changePriceLists.changePriceList.type") }}</td>
                <td v-show="type != 'detail' && operateType == 'application'">
                  {{ $t("changePriceLists.changePriceList.operation") }}
                </td>
              </tr>
              <tr v-for="(item, index) in mailTable">
                <td>{{ item.email }}</td>
                <td>{{ item.name }}</td>
                <td>
                  {{
                    item.type == 1
                      ? $t("changePriceLists.changePriceList.operator")
                      : item.type == 2
                      ? $t("changePriceLists.changePriceList.approver")
                      : $t("changePriceLists.changePriceList.cc")
                  }}
                </td>
                <td v-show="type != 'detail' && operateType == 'application'">
                  <img
                    src="../../assets/img/tcm/cuowu.png"
                    style="width: 20px; height: 20px; cursor: pointer"
                    @click="toDetele(index, 'email')"
                  />
                </td>
              </tr>
            </table>
          </el-col>
          <el-col :span="12">
            <el-input
              v-model="form.mailComment"
              :disabled="type == 'detail' || operateType == 'operation'"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4 }"
              placeholder=""
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="tcm-content-form" v-show="operateType == 'operation'">
      <el-form :inline="true" :model="form" label-width="250px">
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.productionBatch')"
              class="shortWidth"
            >
              <el-input
                v-model="form.lotSize"
                disabled
                placeholder=""
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.annualProduction')"
            >
              <el-input
                v-model="form.productionYear"
                :disabled="type == 'detail'"
                placeholder=""
                style="width: 214px"
                @input="
                  transferFormat($event, form, 'productionYear'), toCompute()
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <EmailDia ref="email" @getChooseData="getChooseData"></EmailDia>
    <ChooseUserDia ref="userDia" @getChooseUser="getChooseUser"></ChooseUserDia>
    <ChooseCCDia ref="ccDia" @getChooseCC="getChooseCC"></ChooseCCDia>
    <CompleteEmail
      ref="ccEmailDia"
      @getCompleteCC="getCompleteCC"
    ></CompleteEmail>
  </div> -->
</template>

<script>
import "../../assets/css/custom-el-upload-list-none.css";
import "../../assets/css/custom-el-form-label.css";
import "../../assets/css/custom-el-form-rules.css";
import commonUtil from "../../assets/js/commonUtil.js";
import {
  GetRate,
  AddOrUpdateMailHistory,
  GetSelCurrency,
} from "../../assets/js/requestApi.js";
import EmailDia from "./emailDia.vue";
import ChooseUserDia from "./chooseUserDia.vue";
import ChooseCCDia from "./chooseCCDia.vue";
import CompleteEmail from "./completeEmail.vue";
export default {
  props: [
    "RegionOptions",
    "QuoteTypeOptions",
    "CommodityOptions",
    "urgencyOptions",
    "urgencyOptionss",
    "type",
    "currencyOptions",
    "currencyReportOptions",
    "operateType",
  ],
  components: {
    EmailDia,
    ChooseUserDia,
    ChooseCCDia,
    CompleteEmail,
  },
  data() {
    return {
      form: {
        quoteTypeId: "",
        quoteTypeName: "",
        programName: "",
        ecrno: "",
        commodityId: "",
        partDescription: "",
        partNumber: "",
        partRevision: "",
        lotSize: "",
        productionYear: "",
        doDate: "",
        approvedTime: "",
        urgencyId: "",
        comment: "",
        mailComment: "",
      },
      /*表单状态*/
      status: -1,
      defaultProps: {
        children: "children",
        label: "Name",
      },
      genList: [
        {
          Currency: "",
          ReportCurrency: "",
          rate: "",
          currencyRateId: "",
          regionId: "",
          supplierName: "",
          materialDescription: "",
          peakVolume: "",
          lifetimeVolume: "",
          pegPoint: "",
          comment: "",
          yearVolumeList: [],
        },
      ],
      genCurrOptions: [],
      // 上传的文件
      fileListx: [],
      // 新增邮件名
      mail: "",
      // 新增邮箱姓名
      name: "",
      mailTable: [],
    };
  },
  mounted() {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    month = month > 9 ? month : "0" + month;
    day = day < 10 ? "0" + day : day;
    var today = year + "-" + month + "-" + day;
    this.form.doDate = today;
    this.form.approvedTime = today;
  },
  methods: {
    // 日期选择器禁用当前日期之前的日期
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    },
    //清空(初始化)
    emptyInput() {
      this.form = {
        quoteTypeId: "",
        quoteTypeName: "",
        programName: "",
        ecrno: "",
        commodityId: "",
        partDescription: "",
        partNumber: "",
        partRevision: "",
        lotSize: "",
        productionYear: "",
        doDate: "",
        approvedTime: "",
        urgencyId: "",
        comment: "",
        mailComment: "",
      };
      this.defaultProps = {
        children: "children",
        label: "Name",
      };
      this.genList = [
        {
          Currency: "",
          ReportCurrency: "",
          rate: "",
          currencyRateId: "",
          regionId: "",
          supplierName: "",
          materialDescription: "",
          peakVolume: "",
          lifetimeVolume: "",
          pegPoint: "",
          comment: "",
          yearVolumeList: [],
        },
      ];
      this.genCurrOptions = [];
      // 上传的文件
      this.fileListx = [];
      // 新增邮件名
      this.mail = "";
      // 新增邮箱姓名
      this.name = "";
      this.mailTable = [];
      console.log("this.genList");
      console.log(this.genList);
      this.$forceUpdate();
    },
    addColoum(index, ind) {
      console.log(ind);
      if (ind !== "" && ind !== null && ind !== undefined) {
        if (
          this.genList[index].yearVolumeList[ind].year === "" ||
          this.genList[index].yearVolumeList[ind].year === null
        ) {
          this.genList[index].yearVolumeList.splice(ind + 1, 0, {
            year: "",
            volume: this.genList[index].yearVolumeList[ind].volume,
          });
        } else {
          this.genList[index].yearVolumeList.splice(ind + 1, 0, {
            year: parseFloat(this.genList[index].yearVolumeList[ind].year) + 1,
            volume: this.genList[index].yearVolumeList[ind].volume,
          });
        }
      } else {
        this.genList[index].yearVolumeList.push({
          year: "",
          volume: "",
        });
      }
    },
    // 计算
    blurVol(index) {
      let _this = this;
      let VolList = [];
      let hasEmpty = false;
      let yearVolumeList = JSON.parse(
        JSON.stringify(this.genList[index].yearVolumeList)
      );
      this.genList[index].lifetimeVolume = 0;
      yearVolumeList.forEach(function (val, ind) {
        VolList.push(val.volume.toString().replace(/,/g, ""));
        if (val.volume !== "") {
          console.log(val.volume);
          val.volume = parseFloat(val.volume.toString().replace(/,/g, ""));
          _this.genList[index].lifetimeVolume += parseFloat(val.volume);
        } else {
          hasEmpty = true;
        }
      });
      _this.genList[index].lifetimeVolume = this.format_with_regex(
        _this.genList[index].lifetimeVolume.toFixed(2)
      );
      if (!hasEmpty) {
        let arr = VolList.sort((a, b) => b - a);
        this.genList[index].peakVolume = this.format_with_regex(arr[0]);
      }
      this.toCompute();
    },
    // currency下拉框change事件
    currencyChange(index) {
      console.log(index);
      if (this.genList[index].Currency != "") {
        this.getCurrencyOptions(index);
      }
      if (this.genList[index].ReportCurrency != "") {
        let data = {
          currency: this.genList[index].Currency,
          reportCurrency: this.genList[index].ReportCurrency,
        };
        this.getRates(data, index);
      }
    },
    // reportcurrency下拉框change事件
    reportCurrencyChange(index) {
      console.log(index);
      if (this.genList[index].ReportCurrency != "") {
        this.getCurrencyOptions(index);
      }
      if (this.genList[index].Currency != "") {
        let data = {
          currency: this.genList[index].Currency,
          reportCurrency: this.genList[index].ReportCurrency,
        };
        this.getRates(data, index);
      }
    },
    // 查询税率表区域选项
    getCurrencyOptions(index) {
      GetSelCurrency(
        {
          CurrId: this.genList[index].Currency,
          CurrCNYId: this.genList[index].ReportCurrency,
        },
        (response) => {
          if (response && response.code == 200) {
            this.genCurrOptions[index] = {
              currencyOptions: response.data[0].exchangeList,
              currencyReportOptions: response.data[0].exchangeCNYList,
            };
            this.$forceUpdate();
          } else {
            commonUtil.showTipInfo(
              this.$t("userlists.userlist.inqueryTimeout")
            );
          }
        }
      );
    },
    // 获取汇率方法
    getRates(data, index) {
      GetRate(data, (response) => {
        if (response && response.code == 200) {
          if (response.result == null) {
            commonUtil.showTipInfo(
              "当前数据无汇率，请重新选择！",
              "",
              "warning"
            );
            this.genList[index].rate = "";
            this.genList[index].currencyRateId = "";
          } else {
            this.genList[index].rate = response.result.exchangeRate;
            this.genList[index].currencyRateId = response.result.id;
          }
        }
        console.log(response);
      });
    },
    // 文件上传
    filechange(file, fileList) {
      this.fileListx.push(file);
      console.log(this.fileListx);
    },
    // 添加邮件
    addEmail() {
      //CheckPoint AddEmail
      if (
        this.form.commodityId === "" ||
        this.form.commodityId === null ||
        !this.form.commodityId
      ) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.commodityRequired"),
          "",
          "warning"
        );
        return;
      }

      this.$refs.userDia.getlist("2", this.form.commodityId);
      this.$refs.userDia.isMultiChose = false; //单选
      this.$refs.userDia.visible = true;
      // let _this = this
      // if (this.mail == '') {
      // 	this.$message({
      // 		message: '请输入邮箱！',
      // 		type: 'warning'
      // 	});
      // 	return;
      // }
      // let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
      // if (!reg.test(this.mail)) {
      // 	this.$message({
      // 		message: '请输入正确的邮箱！',
      // 		type: 'warning'
      // 	});
      // 	return;
      // }
      // let isSampleEmail = false
      // this.mailTable.forEach(function(val, index) {
      // 	if (val.email == _this.mail) {
      // 		isSampleEmail = true
      // 	}
      // })
      // if (isSampleEmail) {
      // 	this.$message({
      // 		message: '邮箱不可重复！',
      // 		type: 'warning'
      // 	});
      // 	return;
      // }
      // this.mailTable.push({
      // 	email: this.mail,
      // 	name: this.name
      // })
      // AddOrUpdateMailHistory({
      // 	email: this.mail,
      // 	name: this.name
      // }, response=>{
      // })
      // this.mail = ''
      // this.name = ''
    },
    // 获取勾选的邮箱数据
    getChooseData(data) {
      console.log(data);
      let _this = this;
      data.forEach(function (val, index) {
        // val.id = val.userId
        if (
          JSON.stringify(_this.mailTable).indexOf(JSON.stringify(val)) === -1
        ) {
          _this.mailTable.push(val);
        }
      });
      console.log(this.mailTable);
    },
    // 获取选择的用户
    getChooseUser(data) {
      let _this = this;
      // data.forEach(function(val, index) {
      // 	if (JSON.stringify(_this.mailTable).indexOf(JSON.stringify(val)) === -1) {
      // 		_this.mailTable.push(val)
      // 	}
      // })
      //直接覆盖
      _this.mailTable = data;
      console.log(this.mailTable);
    },
    // 获取选择的抄送人
    getChooseCC(data) {
      let _this = this;
      let ccList = [];
      this.mailTable.forEach(function (val, index) {
        if (val.type === 3 || val.type === 4) {
          ccList.push(val.name + "," + val.email);
        }
      });
      data.forEach(function (val, index) {
        // if (JSON.stringify(_this.mailTable).indexOf(JSON.stringify(val)) === -1) {
        // 	_this.mailTable.push(val)
        // }
        if (ccList.indexOf(val.name + "," + val.email) === -1) {
          _this.mailTable.push(JSON.parse(JSON.stringify(val)));
        }
      });
    },
    // 获取填写的邮箱和名称(cc)
    getCompleteCC(form) {
      let ccList = [];
      this.mailTable.forEach(function (val, index) {
        if (val.type === 3 || val.type === 4) {
          ccList.push(val.name + "," + val.email);
        }
      });
      if (ccList.indexOf(form.name + "," + form.email) === -1) {
        this.mailTable.push(JSON.parse(JSON.stringify(form)));
      }
    },
    // 删除邮件
    toDetele(index, type, ind) {
      if (type == "year") {
        this.genList[ind].yearVolumeList.splice(index, 1);
        if (this.genList[ind].yearVolumeList.length == 0) {
          this.genList[ind].lifetimeVolume = 0;
          this.genList[ind].peakVolume = 0;
        }
        this.toCompute();
        // this.tableData.splice(index, 1)
      } else if (type == "email") {
        this.mailTable.splice(index, 1);
      } else if (type == "file") {
        this.fileListx.splice(index, 1);
      } else if (type == "gen") {
        this.genList.splice(index, 1);
      }
    },
    // 复制
    copyGen(index) {
      this.genList.push(JSON.parse(JSON.stringify(this.genList[index])));
      this.genCurrOptions.push({
        currencyOptions: this.currencyOptions,
        currencyReportOptions: this.currencyReportOptions,
      });
    },
    // 新增
    addGen(index) {
      this.genList.push({
        Currency: "",
        ReportCurrency: "",
        rate: "",
        currencyRateId: "",
        regionId: "",
        supplierName: "",
        materialDescription: "",
        peakVolume: "",
        lifetimeVolume: "",
        pegPoint: "",
        comment: "",
        yearVolumeList: [],
      });
      this.genCurrOptions.push({
        currencyOptions: this.currencyOptions,
        currencyReportOptions: this.currencyReportOptions,
      });
    },
    // 计算
    toCompute() {
      let _this = this;
      if (
        this.form.productionYear !== "" &&
        this.form.productionYear !== null &&
        parseFloat(this.form.productionYear) != 0 &&
        this.genList[0].peakVolume != ""
      ) {
        this.form.lotSize =
          parseFloat(this.genList[0].peakVolume.toString().replace(/,/g, "")) /
          this.form.productionYear;
      } else {
        this.form.lotSize = "";
      }
      if (this.operateType == "operation") {
        if (this.form.lotSize === "") {
          this.$parent.$refs.proTcm.formData.forEach(function (val, index) {
            val.annualVolume = "";
            val.pieceLot = "";
            // _this.$parent.$refs.proTcm.toComputeAll(val)
          });
          this.$parent.$refs.proTcm.annualVolumeNew = "";
          this.$parent.$refs.proTcm.pieceLotNew = "";
        } else {
          this.$parent.$refs.proTcm.formData.forEach(function (val, index) {
            val.annualVolume = _this.genList[0].peakVolume.toString();
            val.pieceLot = _this.form.lotSize.toFixed(12);
            // _this.$parent.$refs.proTcm.toComputeAll(val)
          });
          this.$parent.$refs.proTcm.annualVolumeNew =
            _this.genList[0].peakVolume.toString();
          this.$parent.$refs.proTcm.pieceLotNew =
            _this.form.lotSize.toFixed(12);
        }
        this.$parent.$refs.proTcm.formData.forEach(function (val, index) {
          _this.$parent.$refs.proTcm.toComputeAll(val);
        });
      }
    },
    // 打开历史邮箱
    openEmailDia() {
      this.$refs.email.getlist();
      this.$refs.email.visible = true;
    },
    // 打开选择抄送人页面
    openCC() {
      this.$refs.ccDia.getlist();
      this.$refs.ccDia.visible = true;
    },
    // 打开填写抄送人邮箱弹窗
    openCCDia() {
      this.$refs.ccEmailDia.visible = true;
    },
    // 下载文件
    downloadFile(index) {
      if (
        this.fileListx[index].name.indexOf(".png") > -1 ||
        this.fileListx[index].name.indexOf(".jpg") > -1 ||
        this.fileListx[index].name.indexOf(".jpeg") > -1 ||
        this.fileListx[index].name.indexOf(".webp") > -1
      ) {
        if (this.fileListx[index].raw) {
          console.log("file");
          commonUtil.downloadPicByFile(
            this.fileListx[index].raw,
            this.fileListx[index].name.split(".")[0]
          );
        } else {
          commonUtil.downloadPicByUrl(
            this.fileListx[index].url,
            this.fileListx[index].name.split(".")[0]
          );
        }
      } else {
        console.log("文件");
        let arr = this.fileListx[index].name.split(".");
        if (this.fileListx[index].raw) {
          console.log("file");
          commonUtil.downloadFileByArryBuffer(
            this.fileListx[index].raw,
            this.fileListx[index].name.split(".")[0],
            arr[arr.length - 1]
          );
        } else {
          commonUtil.downloadFileByUrl(
            this.fileListx[index].url,
            this.fileListx[index].name
          );
        }
      }
    },
    quoteTypeChange(val) {
      let _this = this;
      this.QuoteTypeOptions.forEach(function (value, index) {
        if (value.id == val) {
          _this.form.quoteTypeName = value.name;
        }
      });
    },
    // 限制输入数字
    transferFormat(val, item, name) {
      if (val !== "") {
        item[name] = val.replace(/,/g, "");
        item[name] = item[name].replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
      }
      // item[name] = this.format_with_regex(item[name])
      // blurVol
    },
    transferNum(item, name) {
      item[name] = this.format_with_regex(item[name]);
    },
    // 金额转换千分位
    format_with_regex(n) {
      if (n !== "" && n !== null) {
        n = n.replace(/,/g, "");
        n = n.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
        if (n !== "" && n !== null) {
          let ns = n.split(".");
          var b = parseInt(ns[0]).toString();
          var len = b.length;
          if (len <= 3) {
            return b + (ns.length > 1 ? "." + ns[1] : "");
          }
          var r = len % 3;
          return r > 0
            ? b.slice(0, r) +
                "," +
                b.slice(r, len).match(/\d{3}/g).join(",") +
                (ns.length > 1 ? "." + ns[1] : "")
            : b.slice(r, len).match(/\d{3}/g).join(",") +
                (ns.length > 1 ? "." + ns[1] : "");
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    // 税率转变
    rateChange(val, index) {
      if (val !== "" && this.$parent.$refs.sumTcm) {
        this.$parent.$refs.sumTcm.form.totalPriceReport_SUM = (
          parseFloat(
            this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM === ""
              ? 0
              : this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM
          ) * parseFloat(val === "" ? 0 : val)
        ).toFixed(12);
      }
    },
    // 切换商品分类
    changeCommodityId(val) {
      if (this.type == "editImport") {
        this.$parent.CommodityId = val;
      }
    },
    // 计算lot run size
    computeLotSize() {
      let _this = this;
      if (this.operateType == "operation") {
        if (this.form.lotSize === "") {
          this.$parent.$refs.proTcm.formData.forEach(function (val, index) {
            val.annualVolume = "";
            val.pieceLot = "";
            // _this.$parent.$refs.proTcm.toComputeAll(val)
          });
          this.$parent.$refs.proTcm.annualVolumeNew = "";
          this.$parent.$refs.proTcm.pieceLotNew = "";
        } else {
          this.$parent.$refs.proTcm.formData.forEach(function (val, index) {
            val.annualVolume = _this.genList[0].peakVolume.toString();
            val.pieceLot = _this.form.lotSize.toFixed(12);
            // _this.$parent.$refs.proTcm.toComputeAll(val)
          });
          this.$parent.$refs.proTcm.annualVolumeNew =
            _this.genList[0].peakVolume.toString();
          this.$parent.$refs.proTcm.pieceLotNew =
            _this.form.lotSize.toFixed(12);
        }
        this.$parent.$refs.proTcm.formData.forEach(function (val, index) {
          _this.$parent.$refs.proTcm.toComputeAll(val);
        });
      }
    },
    // description添加特殊字符校验
    checkDescription(val) {
      if (val !== "" && val !== null) {
        this.form.partDescription = val.replace(/[\\\/:*?\"<>|]/g, "");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.General-Infor {
  // width: 100%;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 16px;
  .General-Infor-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }
}
.Gen-info-r {
  width: 100%;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
}

.gen-main-left {
  // flex: 1;
  width: 50%;
}
.gen-main-right {
  width: 50%;
  // flex: 1;
}
.Gen-info {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  .Gen-info-l {
    // width: 200px;
    // padding-left: 10px;
  }

  .img-box {
    width: 160px;
    height: 160px;
    background: #d3d6e4;
  }
  p {
    padding: 10px 0;
  }
  input {
    border-radius: 10px;
    border: 1px solid #2f4cdd;
  }
  .el-row {
    // width: 100%;
  }
  .Year-Volume {
    margin: 15px 0;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    p {
      flex: 1;
    }
    span {
      width: 100px;
    }
  }
  .Year-Volume-ipt {
    margin-bottom: 10px;
    width: 80%;
    display: flex;
    // border: 1px solid #2f4cdd;
    border-radius: 10px;
    .year-ipt,
    .Volume-ipt {
      flex: 1;
      height: 40px;
      border: none !important;
      background: transparent;
      border-radius: 0 !important;
    }
    .Volume-ipt {
      // border-left: 1px solid #2f4cdd !important;
      // border-right: 1px solid #2f4cdd !important;
    }
    .Operation-box {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #2f4cdd;
      .el-icon {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}

// 文件
.upload-box {
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
  border: 1px dashed #c2c2c2;
  background-color: #f4fcf6;
  border-radius: 10px;
  cursor: pointer;
}
.upload-btn {
  margin-left: 10px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  background-color: #44814e;
  color: #fff;
  border-radius: 5px;
}
.upload-icon img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.upload-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #171b1e;
  font-size: 14px;

  p {
    padding: 0;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    color: #717579;
    font-size: 13px;
  }
}
.file-list-box {
  width: 690px;
  flex-wrap: wrap;
  flex-shrink: 1;
}
.file-box {
  margin-left: 15px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #44814e;
  .upload-btn {
    background-color: #f4fcf6;
    color: #44814e;
  }
  .file-del {
    position: absolute;
    top: -10px;
    right: -7px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    background-color: #fd5353;
  }
}

// 邮箱

.gen-table-cla {
  position: relative;
  display: flex;
}
.gen-table {
  border-collapse: collapse;
}
.gen-table td {
  border: 1px solid #dcdfe6;
  padding: 5px 10px;
  text-align: center;
}

.tcm-content {
  padding: 20px 90px 200px 20px;
  box-sizing: border-box;
}
.mgt-5 {
  margin-top: 5px;
}
.align-right {
  text-align: right;
}
.tcm-content-title {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  margin-top: 30px;
  box-sizing: border-box;
}
.gen-table-cla {
  position: relative;
  display: flex;
}
.gen-table {
  border-collapse: collapse;
}
.gen-table td {
  border: 1px solid #dcdfe6;
  padding: 5px 10px;
  text-align: center;
}
.file-cla {
  padding: 0px 20px 0px 0;
  display: flex;
}
.tcm-content-titlex {
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  display: flex;
}
.tcm-content-title-comment {
  line-height: 38px;
}

.Gen-info :deep .el-input__wrapper,
:deep .el-textarea.is-disabled .el-textarea__inner {
  // border: 1px solid #2f4cdd;
}
.el-select {
  width: 100%;
}

// .Gen-info :deep .el-input__inner {
//   border: 1px solid #2f4cdd;
// }

.submit-btn {
  margin-right: 10px;
  padding: 0 30px;
  height: 30px;
  line-height: 30px;
  background: #445581;
  color: #fff;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}
.tcm-form-solid {
  width: calc(100% -50px);
  border-radius: 5px;
  border: 1px dashed #8c8c8c;
  /* margin-left: 50px; */
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.Year-Volume-add-btn {
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 1px solid #ccc;
}
</style>