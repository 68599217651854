<template>
  <div class="tcm-content">
    <!-- <div class="tcm-content-title">{{$t('changePriceLists.changePriceList.dutiesCustoms')}}</div> -->
    <div class="tcm-content-form">
      <el-form :model="form" label-width="250px" label-position="left">
        <!-- <el-row>
                    <el-col>
                        <el-form-item :label="$t('changePriceLists.changePriceList.calculationMethod')">
                            <el-select clearable  v-if="$t('changePriceLists.changePriceList.calculationMethodOption')=='calculationMethodOption'" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="form.calculationMethod" :disabled="operateType == 'detail'" @change="changeCalculationMethod">
                                <el-option v-for="item in calculationMethodOption" :key="item.value" :label="item.label" :value="item.value" />
                            </el-select>
                            <el-select clearable  v-if="$t('changePriceLists.changePriceList.calculationMethodOption')=='calculationMethodOptions'" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="form.calculationMethod" :disabled="operateType == 'detail'" @change="changeCalculationMethod">
                                <el-option v-for="item in calculationMethodOptions" :key="item.value" :label="item.label" :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row> -->
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.freightCost')"
            >
              <el-input
                v-model="form.freightCost"
                :disabled="operateType == 'detail'"
                style="width: 260px; margin-right: 10px"
                @input="
                  transferFormat($event, form, 'freightCost'), tocompute()
                "
                @change="tocompute"
                @blur="tocompute"
              >
                <template #suffix>
                  <el-icon
                    class="el-input__icon"
                    v-show="form.calculationMethod_Freight == 2"
                    >%</el-icon
                  >
                </template>
              </el-input>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOption'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.calculationMethod_Freight"
                :disabled="operateType == 'detail'"
                @change="changeCalculationMethod"
              >
                <el-option
                  v-for="item in calculationMethodOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.calculationMethod_Freight"
                :disabled="operateType == 'detail'"
                @change="changeCalculationMethod"
              >
                <el-option
                  v-for="item in calculationMethodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <span
                v-show="form.calculationMethod_Freight == 2"
                style="margin-left: 10px; color: gray"
                >{{ freightCostNum }}</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.wareCost')"
            >
              <el-input
                v-model="form.wareHouseCost"
                :disabled="operateType == 'detail'"
                style="width: 260px; margin-right: 10px"
                @input="
                  transferFormat($event, form, 'wareHouseCost'), tocompute()
                "
                @change="tocompute"
                @blur="tocompute"
              >
                <template #suffix>
                  <el-icon
                    class="el-input__icon"
                    v-show="form.calculationMethod_Ware == 2"
                    >%</el-icon
                  >
                </template>
              </el-input>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOption'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.calculationMethod_Ware"
                :disabled="operateType == 'detail'"
                @change="changeCalculationMethod"
              >
                <el-option
                  v-for="item in calculationMethodOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.calculationMethod_Ware"
                :disabled="operateType == 'detail'"
                @change="changeCalculationMethod"
              >
                <el-option
                  v-for="item in calculationMethodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <span
                v-show="form.calculationMethod_Ware == 2"
                style="margin-left: 10px; color: gray"
                >{{ wareHouseCostNum }}</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.packagingCost')"
            >
              <el-input
                v-model="form.packageCost"
                :disabled="operateType == 'detail'"
                style="width: 260px; margin-right: 10px"
                @input="
                  transferFormat($event, form, 'packageCost'), tocompute()
                "
                @change="tocompute"
                @blur="tocompute"
              >
                <template #suffix>
                  <el-icon
                    class="el-input__icon"
                    v-show="form.calculationMethod_Package == 2"
                    >%</el-icon
                  >
                </template>
              </el-input>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOption'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.calculationMethod_Package"
                :disabled="operateType == 'detail'"
                @change="changeCalculationMethod"
              >
                <el-option
                  v-for="item in calculationMethodOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.calculationMethod_Package"
                :disabled="operateType == 'detail'"
                @change="changeCalculationMethod"
              >
                <el-option
                  v-for="item in calculationMethodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <span
                v-show="form.calculationMethod_Package == 2"
                style="margin-left: 10px; color: gray"
                >{{ packageCostNum }}</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.taxesFees')"
            >
              <el-input
                v-model="form.otherCost"
                :disabled="operateType == 'detail'"
                style="width: 260px; margin-right: 10px"
                @input="transferFormat($event, form, 'otherCost'), tocompute()"
                @change="tocompute"
                @blur="tocompute"
              >
                <template #suffix>
                  <el-icon
                    class="el-input__icon"
                    v-show="form.calculationMethod_Taxes == 2"
                    >%</el-icon
                  >
                </template>
              </el-input>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOption'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.calculationMethod_Taxes"
                :disabled="operateType == 'detail'"
                @change="changeCalculationMethod"
              >
                <el-option
                  v-for="item in calculationMethodOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="form.calculationMethod_Taxes"
                :disabled="operateType == 'detail'"
                @change="changeCalculationMethod"
              >
                <el-option
                  v-for="item in calculationMethodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <span
                v-show="form.calculationMethod_Taxes == 2"
                style="margin-left: 10px; color: gray"
                >{{ otherCostNum }}</span
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tcm-content-form">
      <el-form :model="form" label-width="500px" label-position="left">
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.addedDescription')"
            >
              <el-input
                v-model="form.amortizationDescription"
                :disabled="operateType == 'detail'"
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalAddedCost')"
            >
              <el-input
                v-model="form.totalAmortize"
                :disabled="operateType == 'detail'"
                style="width: 214px"
                @input="
                  transferFormat($event, form, 'totalAmortize'), tocompute()
                "
                @change="tocompute"
                @blur="tocompute"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalPiecesAdded')"
            >
              <el-input
                v-model="form.totalPieces"
                :disabled="operateType == 'detail'"
                style="width: 214px"
                @input="
                  transferFormat($event, form, 'totalPieces'), tocompute()
                "
                @change="tocompute"
                @blur="tocompute"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.addedCost')"
            >
              <el-input
                v-model="form.amortizeCost"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["operateType", "type"],
  data() {
    return {
      form: {
        calculationMethod: "",
        calculationMethod_Freight: 1,
        freightCost: "",
        calculationMethod_Ware: 1,
        wareHouseCost: "",
        calculationMethod_Package: 1,
        packageCost: "",
        calculationMethod_Taxes: 1,
        otherCost: "",
        amortizationDescription: "",
        totalPieces: "",
        totalAmortize: "",
        amortizeCost: "",
      },
      freightCostNum: "",
      wareHouseCostNum: "",
      packageCostNum: "",
      otherCostNum: "",
      calculationMethodOptions: [
        {
          value: 1,
          label: "Numerical value",
        },
        {
          value: 2,
          label: "Percentage",
        },
      ],
      calculationMethodOption: [
        {
          value: 1,
          label: "数值",
        },
        {
          value: 2,
          label: "百分比",
        },
      ],
    };
  },

  methods: {
    tocompute() {
      console.log("ol计算", this.type);
      console.log(this.form.totalPieces);
      console.log(this.form.totalAmortize);
      // if (
      //     this.form.totalPieces !== "" &&
      //     this.form.totalAmortize !== ""
      // ) {
      console.log("this.form.totalPieces", this.form.totalPieces);
      if (
        this.form.totalAmortize === "0" ||
        this.form.totalPieces === "0" ||
        this.form.totalAmortize === 0 ||
        this.form.totalPieces === 0 ||
        this.form.totalAmortize === "" ||
        this.form.totalPieces === "" ||
        this.form.totalAmortize === null ||
        this.form.totalPieces === null
      ) {
        this.form.amortizeCost = 0;
      } else {
        this.form.amortizeCost = (
          parseFloat(
            this.form.totalAmortize === "" || this.form.totalAmortize === null
              ? 0
              : this.form.totalAmortize
          ) /
          parseFloat(
            this.form.totalPieces === "" || this.form.totalPieces === null
              ? 0
              : this.form.totalPieces
          )
        ).toFixed(12);
      }
      // }
      if (this.type != "template") {
        console.log("ol计算");
        this.$parent.$refs.sumTcm.form.otherCost_SUM =
          parseFloat(
            this.form.otherCost === "" || this.form.otherCost === null
              ? 0
              : this.form.otherCost
          ) +
          parseFloat(
            this.form.amortizeCost === "" || this.form.amortizeCost === null
              ? 0
              : this.form.amortizeCost
          );
        this.$parent.$refs.sumTcm.form.amortizeCost_SUM =
          this.form.amortizeCost;
        this.$parent.$refs.ohTcm.tocompute();
      }
    },
    changeCalculationMethod() {
      this.$parent.$refs.ohTcm.tocompute();
    },
    // 限制
    transferFormat(val, item, name) {
      item[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
    },
  },
};
</script>

<style scoped>
.tcm-content {
  padding: 20px 20px 20px 20px;
}
.tcm-content-title {
  /* background-color: #f2f2f2; */
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  /* width: calc(100% - 50px); */
  margin-left: 50px;
  /* background-color: #f2f3f5; */
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
}   
</style>