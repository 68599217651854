<template>
	<div class="year-table-cla">
		<div style="max-width: calc(100% - 30px); margin-right: 10px; overflow-x: auto;">
			<table class="year-table">
				<tr>
					<td>
						<div style="width: 60px;">Year</div>
					</td>
					<td v-for="(it, ind) in detailList">
						<el-input v-model="it.year" :disabled="type == 'detail'"  @input="(v) => (it.year = v.replace(/[^\d.]/g, ''))" placeholder="" style="width: 100px;"/>
					</td>
				</tr>
				<tr>
					<td>
						<div style="width: 60px;">Price</div>
					</td>
					<td v-for="(it, ind) in detailList" :key="'vol' + ind">
						<el-input v-model="it.price" placeholder="" style="width: 100px;" :disabled="type == 'detail'" @input="transferFormat($event, it , 'price')" @blur="transferNum(it , 'price')"/>
					</td>
				</tr>
				<tr>
					<td>
						<div style="width: 60px;">Volume</div>
					</td>
					<td v-for="(it, ind) in detailList" :key="'vol' + ind">
						<el-input v-model="it.volume" placeholder="" style="width: 100px;" :disabled="type == 'detail'" @input="transferFormat($event, it , 'volume')" @blur="transferNum(it , 'volume')"/>
					</td>
				</tr>
				<tr v-show="type != 'detail'">
					<td>
						<div style="width: 60px;">Operate</div>
					</td>
					<td v-for="(it, ind) in detailList">
						<img src="../../../assets/img/tcm/fuzhi.png" style="width: 20px;height: 20px;cursor: pointer; margin-right: 10px;" @click="addColoum(ind)"/>
						<img src="../../../assets/img/tcm/cuowu.png" style="width: 20px;height: 20px;cursor: pointer;" @click="toDetele(ind, 'year')"/>
						
					</td>
				</tr>
			</table>
		</div>
		<div v-show="type != 'detail'">
			<img src="../../../assets/img/tcm/add.svg" style="width: 20px;height: 20px; cursor: pointer;" @click="addColoum()"/>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['type'],
		data() {
			return {
				detailList: []
			}
		},
		methods: {
			// 新增/复制
			addColoum(ind) {
				if (ind !== '' && ind !== null && ind !== undefined) {
					if (this.detailList[ind].year === '' || this.detailList[ind].year === null) {
						this.detailList.splice(ind + 1, 0, {
							id: '',
							year: '',
							price: this.detailList[ind].price,
							volume: this.detailList[ind].volume
						})
					} else {
						this.detailList.splice(ind + 1, 0, {
							id: '',
							year: (parseFloat(this.detailList[ind].year) + 1).toString(),
							price: this.detailList[ind].price,
							volume: this.detailList[ind].volume
						})
					}
				} else {
					this.detailList.push({
						id: '',
						// yesPricingId: '',
						year: '',
						price: '',
						volume: ''
					})
				}
				
			},
			// 删除
			toDetele(index, type) {
				this.detailList.splice(index, 1)
			},
			// 限制输入数字
			transferFormat(val, item, name) {
				// console.log(item[name])
				if (val !== '') {
					item[name] = val.replace(/,/g, "")
					item[name] = item[name].replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, '$1')
				}
				// item[name] = this.format_with_regex(item[name])
				// blurVol
			},
			transferNum(item, name) {
				item[name] = this.format_with_regex(item[name])
			},
			// 金额转换千分位
			format_with_regex(n) {
				if (n !== '' && n !== null) {
					n = n.replace(/,/g, "")
					let ns = n.split('.')
					var b = parseInt(ns[0]).toString()
					var len = b.length
					if (len <= 3) {
						return b + (ns.length > 1 ? '.' + ns[1] : '')
					}
					var r = len % 3
					return r > 0
					? b.slice(0, r) + ',' + b.slice(r, len).match(/\d{3}/g).join(',') + (ns.length > 1 ? '.' + ns[1] : '')
					: b.slice(r, len).match(/\d{3}/g).join(',') + (ns.length > 1 ? '.' + ns[1] : '')
				} else {
					return ''
				}
				
			},
		}
	}
</script>

<style scoped>
	.year-table-cla{
		position: relative;
		display: flex;
		margin-top: 20px;
	}
	.year-table{
		border-collapse: collapse;
	}
	.year-table td{
		border: 1px solid #dcdfe6;
		padding: 5px 10px;
		text-align: center;
	}
</style>