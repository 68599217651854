<template>
  <el-dialog v-model="visible" width="60%" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">Choose CT calculator</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div style="height: 500px">
      <el-col :span="24">
        <table style="margin-bottom: 10px">
          <tr>
            <td style="width: 280px">
              Part Number
              <el-input
                v-model="searchForm.key"
                placeholder=""
                style="width: calc(100% - 150px)"
              />
            </td>
            <td style="width: 260px">
              Description
              <el-input
                v-model="searchForm.description"
                placeholder=""
                style="width: calc(100% - 150px)"
              />
            </td>
            <td style="width: 260px">
              <el-button
                style="
                  background-color: var(--themeColor);
                  color: var(--menuTextActive);
                  margin-right: 16px;
                "
                @click="search"
                >{{ $t("pcbas.pcba.search") }}</el-button
              >
            </td>
          </tr>
        </table>
      </el-col>
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        border
        style="width: 100%; height: calc(100% - 82px)"
      >
        <el-table-column
          prop="select"
          label="Select"
          width="110"
          align="center"
        >
          <template #default="scope">
            <div class="handle-btn-item" @click="select(scope.row.id)">
              select
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="partNumber" label="Part Number" align="center" />
        <el-table-column
          prop="description"
          label="Description"
          align="center"
        />
        <el-table-column prop="revision" label="Revision" align="center" />
        <el-table-column prop="supplier" label="Supplier" align="center">
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.supplier }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="cycleTime" label="Cycle Time" align="center">
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.cycleTime }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="cycleTime_Cavity"
          label="Cycle Time(cavity)"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.cycleTime_Cavity }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date" align="center">
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.date }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <p></p>
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          background
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- <template #footer>
		      <span class="dialog-footer">
		        <el-button @click="close">Cancel</el-button>
		        <el-button color="#335883" style="margin-right: 16px;" @click="close">Confirm</el-button>
		      </span>
		</template> -->
  </el-dialog>
</template>

<script>
import { CTCalculator_MainsLoad } from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
import { ElMessageBox } from "element-plus";
export default {
  props: ["mainId"],
  emits: ["selectCt"],
  data() {
    return {
      visible: false,
      searchForm: {
        description: "",
        page: 1,
        limit: 10,
        key: "",
      },
      // 总页数
      total: 0,
      tableData: [],
    };
  },
  mounted() {},
  methods: {
    close() {
      this.visible = false;
    },
    getlist() {
      this.searchForm = {
        description: "",
        page: 1,
        limit: 10,
        key: "",
        currId: this.mainId,
      };
      this.search();
    },
    // 查询
    search() {
      CTCalculator_MainsLoad(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 选择
    select(id) {
      let _this = this;
      ElMessageBox.confirm(
        "Using the history record will clear the current record. Do you want to continue?",
        {
          cancelButtonText: "cancle",
          confirmButtonText: "ok",
        }
      )
        .then(() => {
          _this.$emit("selectCt", id);
          _this.close();
          done();
        })
        .catch(() => {
          _this.close();
          // catch error
        });
    },
  },
};
</script>

<style scoped lang="scss">
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.handle-btn-item {
  padding: 0 20px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background: #b2bde7;
  color: #fff;
  border-radius: 25px;
  cursor: pointer;
  margin-left: 10px;
}
</style>