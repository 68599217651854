<template>
  <el-dialog v-model="visible" width="80%" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">{{ $t("tcmlists.tcmlist.selectData") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-row :gutter="10">
        <el-col :span="24">
          <table style="margin-bottom: 10px">
            <tr>
              <td style="width: 260px">
                <el-input
                  v-model="searchForm.Keyword"
                  :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                />
              </td>
              <td style="width: 260px">
                <el-button
                  style="
                    background-color: var(--themeColor);
                    color: var(--menuTextActive);
                    margin-right: 16px;
                  "
                  @click="search"
                  >{{ $t("userlists.userlist.search") }}</el-button
                >
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="24">
          <el-table
            ref="rawTable"
            :data="tableData"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :empty-text="$t('tcmlists.tcmlist.noData')"
          >
            <!-- @select-all="selectAll" -->
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="commodityName"
              :label="$t('ccls.ccl.commodity')"
              width="110"
              align="center"
            />
            <!-- <el-table-column
              prop="documentNo"
              :label="$t('tcmlists.tcmlist.documentNO')"
              align="center"
            /> -->
            <el-table-column
              prop="tcmRequest"
              :label="$t('tcmlists.tcmlist.tcmRequester')"
              width="120"
              align="center"
            />
            <el-table-column
              prop="partDescription"
              :label="$t('tcmlists.tcmlist.partDescription')"
              align="center"
            />
            <el-table-column
              prop="partNumber"
              :label="$t('tcmlists.tcmlist.partNumber')"
              width="120"
              align="center"
            />
            <el-table-column
              prop="date"
              :label="$t('tcmlists.tcmlist.startDate')"
              sortable
              width="125"
              align="center"
            />
            <el-table-column
              prop="doDate"
              :label="$t('tcmlists.tcmlist.dueDate')"
              sortable
              width="120"
              align="center"
            />
            <el-table-column
              prop="urgency"
              :label="$t('tcmlists.tcmlist.priceType')"
              width="110"
              align="center"
            >
              <template #default="scope">
                <div v-show="scope.row.urgency == 'High'" class="color-ff0000">
                  {{ $t("tcmdatalists.tcmdatalist.high") }}
                </div>
                <div
                  v-show="scope.row.urgency == 'Middle'"
                  class="color-F59A23"
                >
                  {{ $t("tcmdatalists.tcmdatalist.middle") }}
                </div>
                <div v-show="scope.row.urgency == 'Low'">
                  {{ $t("tcmdatalists.tcmdatalist.low") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="quoteType"
              :label="$t('tcmlists.tcmlist.quotationType')"
              width="120"
              align="center"
            />
            <el-table-column
              prop="tcmOwner"
              :label="$t('tcmlists.tcmlist.tcmOwner')"
              width="120"
              align="center"
            />
            <el-table-column
              prop="version"
              :label="$t('templateLists.templateList.version')"
              width="120"
              align="center"
            />
          </el-table>
          <div class="pagination">
            <span></span>
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              background
              :current-page="searchForm.page"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">{{
          $t("userlists.userlist.cancel")
        }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="toConfirm"
          >{{ $t("userlists.userlist.confirm") }}</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { getApplicationList } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: ["CommodityId", "addListNum"],
  emits: ["getChooseId"],
  data() {
    return {
      visible: false,
      searchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        status: 6,
        StartDate: "",
        EndDate: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        PageType: 0,
        versionType: 1,
        isDisable: 0,
      },
      // 总页数
      total: 0,
      // 表格数据
      tableData: [],
      // 已勾选数据
      chooseData: [],
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.chooseData = [];
    },
    toConfirm() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo("请选择需要对比的数据！", "", "warning");
        return;
      }
      if (this.chooseData.length > this.addListNum) {
        commonUtil.showTipInfo(
          "最多可选择" + this.addListNum + "条数据！",
          "",
          "warning"
        );
        return;
      }
      console.log(this.chooseData);
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      this.$emit("getChooseId", ids);
      this.close();
    },
    // 获取表格数据
    getlist() {
      this.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        status: 6,
        StartDate: "",
        EndDate: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        PageType: 0,
        key: "",
        versionType: 1,
        isDisable: 0,
      };
      this.search();
    },
    // 查询
    search() {
      getApplicationList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 表格单选选
    handleSelectionChange(val) {
      console.log(val);
      // this.chooseData = val[0]
      // if (val.length > 1) {
      // 	this.$refs.rawTable.clearSelection();
      // 	this.$refs.rawTable.toggleRowSelection(val.pop());
      // }
      this.chooseData = val;
    },
    // 表格全选
    selectAll() {
      this.$refs.rawTable.clearSelection();
      this.chooseData = [];
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.align-right {
  text-align: right;
}
.tcm-table {
  height: calc(100% - 90px);
  padding: 0 20px;
  box-sizing: border-box;
}
.tree-cla {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #ebeef5;
}
.el-row {
  height: 100%;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
</style>