<template>
  <div class="tcm-content">
    <div class="tcm-content-title">
      <div class="dis-flex">
        {{
          type == "template"
            ? $t("changePriceLists.changePriceList.newMateria")
            : $t("changePriceLists.changePriceList.rawMaterial")
        }}
      </div>
      <div class="dis-flex" v-show="type != 'template'">
        <div class="totaol-price-50" style="border-right: 1px solid">
          {{ $t("changePriceLists.changePriceList.totalCost") }}:
          {{ orimaterialCost_RM }}
        </div>
        <div class="totaol-price-50">
          {{ $t("changePriceLists.changePriceList.totalCost") }}:
          {{ materialCost_RM }}
        </div>
      </div>
    </div>
    <div class="dis-flex tcm-form">
      <div class="tcm-form-50" style="border-right: 1px solid">
        <table class="tcm-content-form-table">
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.currency") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.convertCurrency") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.exchangeRate") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.materialDescription") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.vendor") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.netWeight") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.grossWeight") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.unitMeasure") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.materialMeasure") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.scrapPrice") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.country") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.date") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.materialCost") }}</td>
          </tr>
          <tr v-for="(item, index) in oriFormData" :key="index">
            <td>{{ item.currencyName }}</td>
            <td>{{ item.convertCurrencyName }}</td>
            <td>{{ item.converRate }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.vendor }}</td>
            <td>{{ item.netMWeight }}</td>
            <td>{{ item.grossMWeight }}</td>
            <td>{{ item.unitMeasureName }}</td>
            <td>{{ item.unitCost }}</td>
            <td>{{ item.unitResaleValue }}</td>
            <td>{{ item.country }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.cost }}</td>
          </tr>
        </table>
      </div>
      <div class="tcm-form-50">
        <table class="tcm-content-form-table">
          <tr>
            <td>{{ $t("changePriceLists.changePriceList.currency") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.convertCurrency") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.exchangeRate") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.materialDescription") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.vendor") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.netWeight") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.grossWeight") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.unitMeasure") }}</td>
            <td>
              {{ $t("changePriceLists.changePriceList.materialMeasure") }}
            </td>
            <td>{{ $t("changePriceLists.changePriceList.scrapPrice") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.country") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.date") }}</td>
            <td>{{ $t("changePriceLists.changePriceList.materialCost") }}</td>
            <td>{{ $t("templateLists.templateList.operate") }}</td>
          </tr>
          <tr v-for="(item, index) in formData" :key="index">
            <td>{{ item.currencyName }}</td>
            <td>{{ item.convertCurrencyName }}</td>
            <td>{{ item.converRate }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.vendor }}</td>
            <td>{{ item.netMWeight }}</td>
            <td>{{ item.grossMWeight }}</td>
            <td>{{ item.unitMeasureName }}</td>
            <td>{{ item.unitCost }}</td>
            <td>{{ item.unitResaleValue }}</td>
            <td>{{ item.country }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.cost }}</td>
            <td>
              <span
                style="color: #409eff; cursor: pointer"
                @click="editRow(index)"
                >{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
    <CalculatorList ref="calList" @chooseRow="chooseRow"></CalculatorList>
    <PreviewDia
      ref="preDia"
      type="template"
      :formulaPre="outform.formulaPre"
      :previewResultx="previewResultx"
      :fileListUrl="fileListUrl"
      :intermediateBondList="intermediateBondList"
      :valueList="valueList"
      @tocompute="tocompute"
      @toConfirm="toConfirm"
    ></PreviewDia>
    <RawDia
      ref="rawDialog"
      :CommodityId="CommodityId"
      :CurrencyOptions="currencyOptions"
      @getChooseRawId="getChooseRawId"
    ></RawDia>
    <EditRm
      ref="editRmDia"
      :unitOptions="unitOptions"
      @submitRm="submitRm"
    ></EditRm>
  </div>
</template>

<script>
import {
  getCategorys,
  GetCalculatorDetail,
  InputParameterAnalysis,
  getTemplateMaterialsDetail,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import CalculatorList from "../processCalculatorManagement/calculatorListDia.vue";
import PreviewDia from "../processCalculatorManagement/previewDia.vue";
import RawDia from "../rawManagement/rawDia.vue";
import EditRm from "./editRm.vue";
export default {
  props: [
    "type",
    "operateType",
    "CommodityId",
    "CommodityName",
    "CommodityOptions",
    "regionOptions",
    "currencyOptions",
  ],
  emits: ["goBack", "confirmData"],
  components: {
    CalculatorList,
    PreviewDia,
    RawDia,
    EditRm,
  },
  data() {
    return {
      // 原始数据
      orimaterialCost_RM: "",
      oriFormData: [],
      // 修改后的数据
      materialCost_RM: "",
      formData: [
        {
          id: "",
          number: "",
          name: "",
          version: "",
          comment: "",
          isEnable: 1,
          description: "",
          netMWeight: "",
          grossMWeight: "",
          unitMeasureId: "",
          unitCost: "",
          unitResaleValue: "",
          country: "",
          cost: "",
          commodityId: "",
          regionId: "",
          cLines: [],
          converRate_Before: "",
          unitCost_Before: "",
          cost_Before: "",
          date: "",
        },
      ],
      // 单位选项
      unitOptions: [],
      // 商品分类显示字段
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 工艺计算器详细数据
      form: {
        id: "",
        process_Calculator_Code: "",
        name: "",
        commodityId: "",
        commodityName: "",
        version: "",
        comments: "",
      },
      // 上传的文件
      fileListx: [],
      fileListUrl: [],
      // 表格内容
      tableData: [],
      outform: {
        name: "",
        unit: "",
        formula: "",
        formulaVal: "",
        formulaPre: "",
      },
      // 符号
      symbol: ["+", "-", "*", "/", "sum", "if", "(", ")"],
      // 计算结果
      previewResultx: 0,
      // 打开计算器输入框类型
      calType: "input",
      // 中间键列表
      intermediateBondList: [],
      // 数值列表
      valueList: [],
      // 当前操作数据下标
      operateIndex: "",
      // 当前选择的工艺计算器id
      operateCalId: "",
      // 编辑时删除的组件id
      materialDelIds: [],
      // Net Material Weight/Material Scrap Resale Value / Unit of Measure乘积之和
      NmCost: 0,
      // 编辑的数据index
      editIndex: 0,
    };
  },
  mounted() {
    this.getUnit();
  },
  methods: {
    // 获取单位选项
    getUnit() {
      getCategorys(
        {
          TypeID: "MeasureUnit",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.unitOptions = response.data;
          }
        }
      );
    },
    // 取消
    cancle() {
      this.$emit("goBack");
    },
    // 提交
    confirm() {
      this.$emit("confirmData", this.formData);
    },
    // 打开计算器
    openCal(index) {
      if (this.operateType == "detail") {
        return;
      }
      // if (this.formData[index].netMWeight == '') {
      // 	commonUtil.showTipInfo('请输入Net Material Weight！', '', 'warning')
      // 	return;
      // }
      this.operateIndex = index;
      this.$refs.calList.getlist();
      this.$refs.calList.getTreeData();
      this.$refs.calList.visible = true;
    },
    // 获取选择的工艺计算器
    chooseRow(row) {
      this.operateCalId = row.id;
      this.getDetail(row.id);
    },
    // 根据id获取选择的工艺计算器详情
    getDetail(id) {
      let _this = this;
      GetCalculatorDetail('"' + id + '"', (response) => {
        if (response && response.code == 200) {
          this.form = {
            id: response.data[0].id,
            process_Calculator_Code: response.data[0].process_Calculator_Code,
            name: response.data[0].process_Calculator_Name,
            commodityId: response.data[0].commodityId,
            commodityName: response.data[0].commodList[0].name,
            version: response.data[0].version,
            comments: response.data[0].comments,
          };
          _this.intermediateBondList = [];
          _this.valueList = [];
          this.tableData = response.data[0].edit_List;
          this.tableData.forEach(function (val, key) {
            val.formulaVal = val.formulaPre
              ? val.formulaPre.replace(/\|/g, "")
              : "";
            if (val.formula != "") {
              _this.intermediateBondList.push(val);
            } else {
              _this.valueList.push(val);
            }
          });
          this.outform = {
            name: response.data[0].outputName,
            unit: response.data[0].outputUnit,
            formula: response.data[0].outputFormulaPre.replace(/\|/g, ""),
            formulaVal: response.data[0].outputFormula,
            formulaPre: response.data[0].outputFormulaPre,
          };
          this.fileListx = response.data[0].pictureList;
          // 计算默认值
          let list = [];
          this.valueList.forEach(function (val, key) {
            list.push({
              paramValue: val.thedefaultvalue,
              paramentName: val.parameterName,
            });
          });
          this.intermediateBondList.forEach(function (val, key) {
            list.push({
              paramValue: val.formulaPre,
              paramentName: val.parameterName,
            });
          });
          let data = {
            outputFormula: this.outform.formulaPre,
            inputParameterList: list,
          };
          this.$refs.preDia.visible = true;
          // this.tocompute(data)
        }
      });
    },
    // 计算结果
    tocompute(val) {
      InputParameterAnalysis(val, (response) => {
        if (response && response.code == 200) {
          this.previewResultx = response.data;
          this.$refs.preDia.visible = true;
        }
      });
    },
    // 获取最后计算的值
    toConfirm(val) {
      this.$refs.calList.visible = false;
      this.formData[this.operateIndex].grossMWeight = val;
      this.formData[this.operateIndex].cLines = [];
      this.formData[this.operateIndex].cLines.push({
        fieldName: "GrossMWeight",
        calculatorTableId: this.operateCalId,
      });
      this.toComputeCost(this.formData[this.operateIndex]);
    },
    // 添加组件
    addMoudle() {
      if (this.CommodityId == "") {
        commonUtil.showTipInfo("请选择商品类别！", "", "warning");
        return;
      }
      this.$refs.rawDialog.searchForm.page = 1;
      this.$refs.rawDialog.getlist();
      this.$refs.rawDialog.visible = true;
    },
    // 添加空组件
    addEmptyMoudle() {
      this.formData.push({
        id: "",
        number: "",
        name: "",
        version: "",
        comment: "",
        isEnable: 1,
        description: "",
        netMWeight: "",
        grossMWeight: "",
        unitMeasureId: "",
        unitCost: "",
        unitResaleValue: "",
        country: "",
        cost: "",
        commodityId: "",
        regionId: "",
        cLines: [],
      });
    },
    // 获取添加组件的详情信息
    getChooseRawId(id) {
      let _this = this;
      getTemplateMaterialsDetail(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            // this.CommodityId = response.result.commodityId
            response.result.forEach(function (resVal, resIndex) {
              let cLines = [];
              resVal.cLines.forEach(function (val, index) {
                cLines.push({
                  fieldName: val.paramName,
                  calculatorTableId: val.calculator_TableId,
                });
              });
              let moudle = resVal;
              moudle.id = "";
              moudle.cLines = cLines;
              _this.formData.push(moudle);
            });
            _this.materialCost_RM = 0;
            this.formData.forEach(function (val, index) {
              _this.materialCost_RM += parseFloat(
                val.cost === "" || !val.cost ? 0 : val.cost
              );
            });
            _this.materialCost_RM = _this.materialCost_RM.toFixed(3);
            this.$refs.rawDialog.visible = false;
          }
        }
      );
    },
    // 删除组件
    deleteMoudle(index) {
      if (this.materialDelIds.indexOf(this.formData[index].id) > -1) {
        this.materialDelIds.splice(
          this.materialDelIds.indexOf(this.formData[index].id),
          1
        );
      } else {
        this.materialDelIds.push(this.formData[index].id);
      }
      this.formData.splice(index, 1);
      if (this.formData.length > 0) {
        this.toComputeCost(this.formData[0]);
      }
    },
    // 复制组件
    copyMoudle(index) {
      let data = JSON.parse(JSON.stringify(this.formData[index]));
      data.id = "";
      this.formData.push(data);
      if (this.formData.length > 0) {
        this.toComputeCost(this.formData[0]);
      }
    },
    // 计算单个material cost
    toComputeCost(row) {
      let _this = this;
      if (
        row.netMWeight === "" ||
        row.grossMWeight === "" ||
        row.unitCost === "" ||
        row.unitResaleValue === ""
      ) {
        return;
      }
      row.cost = (
        parseFloat(
          row.grossMWeight === "" || !row.grossMWeight ? "0" : row.grossMWeight
        ) *
          parseFloat(row.unitCost) -
        (parseFloat(
          row.grossMWeight === "" || !row.grossMWeight ? "0" : row.grossMWeight
        ) -
          parseFloat(
            row.netMWeight === "" || !row.netMWeight ? "0" : row.netMWeight
          )) *
          parseFloat(row.unitResaleValue)
      ).toFixed(3);
      _this.materialCost_RM = 0;
      _this.NmCost = 0;
      this.formData.forEach(function (val, index) {
        _this.materialCost_RM += parseFloat(
          val.cost == "" || !val.cost ? "0" : val.cost
        );
        _this.NmCost +=
          parseFloat(val.netMWeight) *
          parseFloat(val.unitResaleValue == "" ? 0 : val.unitResaleValue);
      });
      _this.materialCost_RM = _this.materialCost_RM.toFixed(3);
      _this.NmCost = _this.NmCost.toFixed(3);
      this.$parent.$refs.sumTcm.form.materialCost_SUM = _this.materialCost_RM;
      let priceDiffrence = 0;
      priceDiffrence =
        parseFloat(_this.materialCost_RM === "" ? 0 : _this.materialCost_RM) -
        parseFloat(
          _this.orimaterialCost_RM === "" ? 0 : _this.orimaterialCost_RM
        );
      this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM =
        parseFloat(this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM) +
        priceDiffrence;
      this.$parent.$refs.sumTcm.form.totalPriceReport_SUM = parseFloat(
        (
          parseFloat(this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM) *
          parseFloat(
            this.$parent.$refs.gen.genList[0].rate == ""
              ? 0
              : this.$parent.$refs.gen.genList[0].rate
          )
        ).toFixed(3)
      );
    },
    // 修改分类
    changeCommodityId(val, index) {
      this.formData[index].commodityId = val;
    },
    // 切换顺序
    reorder(arrow, index) {
      if (index == 0 && arrow == "left") {
        commonUtil.showTipInfo("已在第一位，不可往左移！", "", "warning");
        return;
      }
      if (index == this.formData.length - 1 && arrow == "right") {
        commonUtil.showTipInfo("已在最后一位，不可往右移！", "", "warning");
        return;
      }
      if (arrow == "left") {
        [this.formData[index - 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index - 1],
        ];
        this.$forceUpdate();
      } else if (arrow == "right") {
        [this.formData[index + 1], this.formData[index]] = [
          this.formData[index],
          this.formData[index + 1],
        ];
        this.$forceUpdate();
      }
    },
    //编辑
    editRow(index) {
      this.editIndex = index;
      this.$refs.editRmDia.CurrencyOptions = this.currencyOptions;
      this.$refs.editRmDia.form = JSON.parse(
        JSON.stringify(this.formData[index])
      );
      this.$refs.editRmDia.visible = true;
    },
    // 编辑提交
    submitRm(form) {
      this.formData[this.editIndex] = form;
      this.toComputeCost(this.formData[this.editIndex]);
    },
  },
};
</script>

<style scoped>
.tcm-content-title {
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  line-height: 25px;
  font-weight: bold;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.totaol-price-50 {
  padding: 0 10px;
}
.tcm-form {
  box-sizing: border-box;
  flex-wrap: no-wrap;
  height: calc(100% - 92px);
  background-color: rgba(255, 255, 255, 0.7);
}
.tcm-form-50 {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  overflow: auto;
}
.tcm-content-form {
  width: 30%;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin: 0 1.6%;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.tcm-form-solid {
  width: 30%;
  border-radius: 5px;
  border: 1px dashed #8c8c8c;
  margin: 0 1.6%;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  line-height: 410px;
  cursor: pointer;
}
.dis-flex {
  display: flex;
}
.size-14 {
  font-size: 14px;
  position: relative;
}
.line-32 {
  line-height: 32px;
}
.padding-9-0 {
  padding: 9px 0;
}
.el-row {
  margin-bottom: 10px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.align-right {
  text-align: right;
}
.add-cla {
  padding-top: 5px;
  margin-left: 10px;
  cursor: pointer;
}
.color-red {
  color: red;
  position: absolute;
  left: -8px;
}
.mgr-10 {
  margin-right: 10px;
}
.tcm-arrows {
  text-align: right;
  margin-bottom: 10px;
}
.tcm-arrows-img {
  width: 25px;
  cursor: pointer;
}
table {
  border-spacing: 5px 10px;
  height: calc(100vh - 290px);
}
.tcm-content-form-table tr {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.tcm-content-form-table td {
  width: 120px;
  text-align: center;
}
</style>