import axios from 'axios'
import errorCodeEnum from '../enum/errorCodeEnum.js'
import configUtil from '../../../public/config'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
var request = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // baseURL: 'http://hw.zhongxing-sz.com.cn:80/',
    // 超时
    timeout: configUtil.requestTimeOut
});

// request拦截器
request.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
})
// 响应拦截器
request.interceptors.response.use(res => {
    // if (res.headers['authorization']) {
    // 	sessionStorage.setItem('token', res.headers['authorization']);
    // 	configUtil.token = res.headers['authorization']
    // }
    if (res.headers['content-disposition']) {
        let contents = res.headers['content-disposition'].split(';')
        if (contents.length > 1) {
            let files = contents[1].split('##')
            let file = []
            if (files[1].indexOf('xlsm') > -1) {
                file = files[1].split('.xlsm')
            } else if (files[1].indexOf('xlsx') > -1) {
                file = files[1].split('.xlsx')
            }
            let fileName = file[0]
            sessionStorage.setItem('tcmExportFileName', fileName);
        }
    }
    // 响应返回登陆页面或接口返回错误码为481时进行页面登出
    if (res.headers["sessionstate"] === "timeout" || res.headers["sessionstate"] === "nouser" ||
        res.headers["sessionstate"] === "tokenTimeout" || res.headers["authstatus"] === "401" ||
        (res.headers["content-type"] && res.headers["content-type"].indexOf("text/html") > -1) ||
        res.data.resultCode === errorCodeEnum.NOT_LOGGED_IN) {
        // window.top.postMessage({
        //     key: "loginOut",
        //     value: ""
        // }, "*");
    }
    return res.data;
},
    error => {
        return Promise.reject(error);
    }
)

export default request