<template>
    <el-dialog v-model="visible" width="600px" :show-close="false" @close="close">
        <template #header="{ close, titleId, titleClass }">
            <div class="my-header">
                <div class="size-16">Round</div>
                <div style="cursor: pointer;" @click="close">
                    <el-icon :size="20"  style="color: var(--themeColor);">
                        <CircleCloseFilled />
                    </el-icon>
                </div>
            </div>
        </template>
        <div class="operate-cla">
            <div class="mgb-10">
                <el-radio-group v-model="operateType">
                    <el-radio label="1">{{$t('ccls.ccl.inputValue')}}</el-radio>
                    <el-radio label="2">{{$t('ccls.ccl.intermediateBond')}}</el-radio>
                </el-radio-group>
            </div>
            <div v-show="operateType == '1'">{{$t('ccls.ccl.inputValue')}}
                <el-select clearable  :no-data-text="$t('tcmlists.tcmlist.noData')" v-model="form.value" class="mgl-10" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                    <el-option v-for="item in valueList" :key="item.parameterName" :label="item.parameterName" :value="item.parameterName" />
                </el-select>
            </div>
            <div v-show="operateType == '2'">{{$t('ccls.ccl.intermediateBond')}}
                <el-select clearable  :no-data-text="$t('tcmlists.tcmlist.noData')" v-model="form.intermediateBond" class="mgl-10" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                    <el-option v-for="item in intermediateBondList" :key="item.parameterName" :label="item.parameterName" :value="item.parameterName" />
                </el-select>
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="close">{{$t('ccls.ccl.cancel')}}</el-button>
                <el-button  style="background-color: var(--themeColor); color: var(--menuTextActive);margin-right: 16px" @click="submit">{{$t('ccls.ccl.confirm')}}</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
    props: ["valueList", "intermediateBondList", "roundType"],
    emits: ["setRoundFunc"],
    data() {
        return {
            visible: false,
            operateType: "1",
            form: {
                value: "",
                intermediateBond: "",
            },
        };
    },
    methods: {
        close() {
            this.form = {
                value: "",
                intermediateBond: "",
            };
            this.operateType = "1";
            this.visible = false;
        },
        submit() {
            let _this = this;
            if (this.form.value === "" && this.form.intermediateBond === "") {
                commonUtil.showTipInfo(
                    this.$t("ccls.ccl.pleaseMiddle"),
                    "",
                    "warning"
                );
                return;
            }
            // 公式
            let fun = [];
            // 序号公式
            let func = [];
            let valIndex = "";
            if (this.roundType == "round") {
                fun.push("Roundround");
                fun.push("(");
                fun.push("|");
                func.push("Roundround");
                func.push("(");
                func.push("|");

                if (this.operateType == "1") {
                    this.valueList.forEach(function (valuex, index) {
                        if (valuex.parameterName == _this.form.value) {
                            valIndex = index;
                        }
                    });
                    fun.push(this.form.value);
                    console.log(this.valueList[valIndex]);
                    func.push(this.valueList[valIndex].ParameterNumName);
                } else {
                    this.intermediateBondList.forEach(function (valuex, index) {
                        console.log(valuex);
                        console.log(_this.form.value);
                        if (
                            valuex.parameterName == _this.form.intermediateBond
                        ) {
                            valIndex = index;
                            console.log(valIndex);
                        }
                    });
                    fun.push(this.form.intermediateBond);
                    console.log(this.intermediateBondList[valIndex]);
                    func.push(
                        this.intermediateBondList[valIndex].ParameterNumName
                    );
                }
                fun.push("|");
                fun.push(")");
                fun.push("roundRound");
                func.push("|");
                func.push(")");
                func.push("roundRound");
            } else if (this.roundType == "roundup") {
                fun.push("Ceilingceiling");
                fun.push("(");
                fun.push("|");
                func.push("RoundUp");
                func.push("(");
                func.push("|");

                if (this.operateType == "1") {
                    this.valueList.forEach(function (valuex, index) {
                        if (valuex.parameterName == _this.form.value) {
                            valIndex = index;
                        }
                    });
                    fun.push(this.form.value);
                    console.log(this.valueList[valIndex]);
                    func.push(this.valueList[valIndex].ParameterNumName);
                } else {
                    this.intermediateBondList.forEach(function (valuex, index) {
                        if (
                            valuex.parameterName == _this.form.intermediateBond
                        ) {
                            valIndex = index;
                        }
                    });
                    fun.push(this.form.intermediateBond);
                    console.log(this.intermediateBondList[valIndex]);
                    func.push(
                        this.intermediateBondList[valIndex].ParameterNumName
                    );
                }
                fun.push("|");
                fun.push(")");
                fun.push("ceilingCeiling");
                func.push("|");
                func.push(")");
                func.push("RoundUp");
            }
            console.log(fun);
            this.$emit("setRoundFunc", fun, func);
            this.close();
        },
    },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
    margin-right: 0;
}
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.width-170 {
    width: 170px;
}
.width-31 {
    width: 31px;
}
.size-16 {
    font-size: 16px;
}
.mgb-10 {
    margin-bottom: 20px;
}
.mgl-10 {
    margin-left: 10px;
}
.mg-10 {
    margin: 0 10px;
}
.dis-between {
    display: flex;
    justify-content: space-between;
}
.operate-cla {
    width: 50%;
    margin: 0 auto;
}
</style>