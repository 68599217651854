<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.CBDPriceChange") }}</div>
      <div class="main-handle flex">
        <!-- CommodityId -->
        <div class="main-handle-item">
          {{ $t("tcmdatalists.tcmdatalist.commodity") }}
          <el-tree-select
            size="small"
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="searchForm.CommodityId"
            :data="CommodityOptions"
            :props="defaultProps"
            check-strictly
            :render-after-expand="false"
            :default-expand-all="true"
          />
        </div>
        <!-- 日期选择 -->
        <div class="main-handle-item" style="margin-top: 15px">
          <div class="select-box">
            <div class="select-btn">
              <el-icon class="Calendar"><Calendar /></el-icon>
              <span :style="{ color: searchForm.date ? '#606266' : '' }">{{
                searchForm.date
                  ? searchForm.date[0] + " - " + searchForm.date[1]
                  : $t("tcmdatalists.tcmdatalist.pleaseSlect")
              }}</span>
              <el-icon v-show="!searchForm.date">
                <ArrowDownBold />
              </el-icon>
              <el-icon :size="18" v-show="searchForm.date">
                <Close />
              </el-icon>
            </div>
            <el-date-picker
              class="date-picker"
              style="
                width: 200px;
                height: 30px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
              "
              v-model="searchForm.date"
              type="daterange"
              range-separator="To"
              :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
              :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
              size="mini"
              value-format="YYYY-MM-DD"
              @change="changeDate"
            />
          </div>
        </div>
        <div class="main-handle-item" style="margin-top: 15px">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" style="margin-top: 15px" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div class="handle-btn-item" @click="selectTcms">
        {{ $t("unifiedPrompts.unifiedPrompt.selectTCM") }}
      </div>
      <div class="handle-btn-item" @click="changePrice">
        {{ $t("ccls.ccl.changePrice") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :data="tableData"
        :height="TableHeight"
        @row-click="RowClick"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="commodityName"
          :label="$t('tcmdatalists.tcmdatalist.commodity')"
          width="110"
          align="center"
        />
        <!-- <el-table-column prop="programName" :label="$t('changePriceLists.changePriceList.programName')" align="center"/> -->
        <!-- <el-table-column
          prop="documentNo"
          :label="$t('tcmdatalists.tcmdatalist.documentNO')"
          align="center"
        /> -->
        <el-table-column
          prop="tcmOwner"
          :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="partDescription"
          :label="$t('tcmdatalists.tcmdatalist.partDescription')"
          align="center"
        />
        <el-table-column
          prop="partNumber"
          :label="$t('tcmdatalists.tcmdatalist.partNumber')"
          width="120"
          align="center"
        />
        <!-- <el-table-column prop="date" :label="($t('tcmdatalists.tcmdatalist.startDate'))" sortable width="125" align="center" /> -->
        <el-table-column
          prop="doDate"
          :label="$t('changePriceLists.changePriceList.dueDate')"
          sortable
          width="120"
          align="center"
        />
        <!-- <el-table-column prop="urgency" :label="($t('tcmdatalists.tcmdatalist.priority'))" width="110" align="center">
                    <template #default="scope">
                        <div v-show="scope.row.urgency == 'High'" class="color-ff0000">{{$t('tcmdatalists.tcmdatalist.high')}}</div>
                        <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23">{{$t('tcmdatalists.tcmdatalist.middle')}}</div>
                        <div v-show="scope.row.urgency == 'Low'">{{$t('tcmdatalists.tcmdatalist.low')}}</div>
                    </template>
                </el-table-column> -->
        <el-table-column
          prop="quoteType"
          :label="$t('tcmdatalists.tcmdatalist.quotationType')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="tcmRequest"
          :label="$t('tcmdatalists.tcmdatalist.tcmOwner')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="version"
          :label="$t('templateLists.templateList.version')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="priceType"
          :label="$t('tcmlists.tcmlist.priceType')"
          width="120"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.priceType == '0'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.unchangedPrice") }}
            </div>
            <div v-show="scope.row.priceType == '1'" class="color-67C23A">
              {{ $t("tcmdatalists.tcmdatalist.changedprice") }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          width="80"
          fixed="right"
          class-name="lastTd"
          align="center"
          prop="action"
          :label="$t('homePage.home.Action')"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div class="action-btn" @click="showDetail(scope.row)">
                  <img
                    style="width: 15px; height: 15px"
                    src="../../assets/img/tcm/computer.svg"
                  />
                  <span>{{ $t("ccls.ccl.detail") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
  <SelectTcm ref="tcmDia" @selectTCM="tcmImport"></SelectTcm>
  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <table>
        <tr>
          <td>
            {{ $t("tcmdatalists.tcmdatalist.commodity") }}
            <el-tree-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              v-model="searchForm.CommodityId"
              :data="CommodityOptions"
              :props="defaultProps"
              check-strictly
              :render-after-expand="false"
              :default-expand-all="true"
            />
            <!== <el-select clearable  v-model="searchForm.CommodityId"  placeholder="">
						    <el-option
						      v-for="item in commodityOptions"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value"
						    />
						</el-select> ==>
          </td>
          <td>
            {{ $t("tcmdatalists.tcmdatalist.date") }}
            <el-date-picker
              v-model="searchForm.date"
              type="daterange"
              range-separator="To"
              :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
              :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
              size="default"
              value-format="YYYY-MM-DD"
              @change="changeDate"
            />
          </td>
          <td>
            <el-input
              v-model="searchForm.Keyword"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </td>
          <td>
            <div class="operate-btn" style="margin-right: 10px" @click="search">
              {{ $t("tcmdatalists.tcmdatalist.search") }}
            </div>
            <!== <div class="operate-btn">Advanced search</div> ==>
          </td>
        </tr>
        <!== <tr>
                    <td colspan="5" style="padding-top: 10px;">
                        <div class="operate-btn mrg-10" v-if="this.btnAdd>0">{{$t('unifiedPrompts.unifiedPrompt.new')}}</div>
                        <div class="operate-btn mrg-10" v-if="this.btnDel>0">{{$t('unifiedPrompts.unifiedPrompt.batchDelete')}}</div>
                    </td>
                </tr> ==>
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <!== <div class="operate-btn mrg-10"  @click="selectCBDs">{{$t("unifiedPrompts.unifiedPrompt.selectCBD")}}</div> ==>
            <div class="operate-btn mrg-10" @click="selectTcms">
              {{ $t("unifiedPrompts.unifiedPrompt.selectTCM") }}
            </div>
            <div class="operate-btn mrg-10" @click="changePrice">
              {{ $t("ccls.ccl.changePrice") }}
            </div>
          </el-col>
        </el-row>
      </table>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :empty-text="$t('tcmlists.tcmlist.noData')"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column width="55" align="center">
          <template #default="scope">
            <img
              src="../../assets/img/tcm/computer.svg"
              style="cursor: pointer"
              @click="showDetail(scope.row)"
            />
            <!== <img src="../../assets/img/tcm/edit.svg" style="cursor: pointer;" v-if="this.btnEdit>0" @click="edit(scope.row.id)" /> ==>
          </template>
        </el-table-column>
        <el-table-column
          prop="commodityName"
          :label="$t('tcmdatalists.tcmdatalist.commodity')"
          width="110"
          align="center"
        />
        <!== <el-table-column prop="programName" :label="$t('changePriceLists.changePriceList.programName')" align="center"/> ==>
        <el-table-column
          prop="documentNo"
          :label="$t('tcmdatalists.tcmdatalist.documentNO')"
          align="center"
        />
        <el-table-column
          prop="tcmRequest"
          :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="partDescription"
          :label="$t('tcmdatalists.tcmdatalist.partDescription')"
          align="center"
        />
        <el-table-column
          prop="partNumber"
          :label="$t('tcmdatalists.tcmdatalist.partNumber')"
          width="120"
          align="center"
        />
        <!== <el-table-column prop="date" :label="($t('tcmdatalists.tcmdatalist.startDate'))" sortable width="125" align="center" /> ==>
        <el-table-column
          prop="doDate"
          :label="$t('changePriceLists.changePriceList.dueDate')"
          sortable
          width="120"
          align="center"
        />
        <!== <el-table-column prop="urgency" :label="($t('tcmdatalists.tcmdatalist.priority'))" width="110" align="center">
                    <template #default="scope">
                        <div v-show="scope.row.urgency == 'High'" class="color-ff0000">{{$t('tcmdatalists.tcmdatalist.high')}}</div>
                        <div v-show="scope.row.urgency == 'Middle'" class="color-F59A23">{{$t('tcmdatalists.tcmdatalist.middle')}}</div>
                        <div v-show="scope.row.urgency == 'Low'">{{$t('tcmdatalists.tcmdatalist.low')}}</div>
                    </template>
                </el-table-column> ==>
        <el-table-column
          prop="quoteType"
          :label="$t('tcmdatalists.tcmdatalist.quotationType')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="tcmOwner"
          :label="$t('tcmdatalists.tcmdatalist.tcmOwner')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="version"
          :label="$t('templateLists.templateList.version')"
          width="120"
          align="center"
        />
        <el-table-column
          prop="priceType"
          :label="$t('tcmlists.tcmlist.priceType')"
          width="120"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.priceType == '0'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.unchangedPrice") }}
            </div>
            <div v-show="scope.row.priceType == '1'" class="color-67C23A">
              {{ $t("tcmdatalists.tcmdatalist.changedprice") }}
            </div>
          </template>
        </el-table-column>
        <!== <el-table-column prop="status" :label="$t('tcmlists.tcmlist.workStatus')" align="center">
                    <template #default="scope">
                        <div v-show="scope.row.status == '0'" class="color-F59A23">{{$t('tcmdatalists.tcmdatalist.notStart')}}</div>
                        <div v-show="scope.row.status == '1'" class="color-D9001B">{{$t('tcmdatalists.tcmdatalist.inProcess')}}</div>
                        <div v-show="scope.row.status == '2'" class="color-0000FF">{{$t('tcmdatalists.tcmdatalist.toApproved')}}</div>
                        <div v-show="scope.row.status == '4'">{{$t('tcmdatalists.tcmdatalist.underApproved')}}</div>
                        <div v-show="scope.row.status == '5'" class="color-D9001B">{{$t('tcmdatalists.tcmdatalist.rejected')}}</div>
                        <div v-show="scope.row.status == '6'" class="color-67C23A">{{$t('tcmdatalists.tcmdatalist.approved')}}</div>
                    </template>
                </el-table-column> ==>
      </el-table>
    </div>
    <div class="page-cla">
      <div>
        <el-input
          v-model="searchForm.limit"
          @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
          min="1"
          style="width: 130px"
          @change="handleSizeChange"
        >
          <template #suffix>
            {{ $t("ccls.ccl.barPage") }}
          </template>
        </el-input>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
    <SelectTcm ref="tcmDia" @selectTCM="tcmImport"></SelectTcm>
    <!== <SelectCBD ref="cbdDia" @selectCBD="cbdImport"></SelectCBD> ==>
  </div> -->
</template>

<script>
import {
  LoadForPrice,
  deleteApplyDetail,
  updateStatusCostApplys,
  GetOrgs,
  AddOrUpdatePriceRelation,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import { ElMessageBox } from "element-plus";
import SelectTcm from "../tcmdata/selectTcm.vue";
import SelectCBD from "../tcmdata/selectCBD.vue";
export default {
  components: {
    SelectTcm,
    SelectCBD,
  },
  name: "ChangePriceList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "6",
        Keyword: "",
        priceType: "",
        PageType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
      },
      // commodity选项
      // commodity选项
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
    };
  },
  mounted() {
    this.autoHeight();
    this.getList();
    this.getCommdity();
    this.timer = setTimeout(this.gettime, 1000);
  },

  methods: {
    RowClick(row) {
      this.showDetail(row);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加") {
          this.btnAdd = 1;
        }
        if (e.name == "删除") {
          this.btnDel = 1;
        }
        if (e.name == "编辑") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    getList() {
      this.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "6",
        Keyword: "",
        priceType: "",
        PageType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    search() {
      LoadForPrice(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 新增
    addEntry() {
      this.$router.push({
        path: "/tcmAdd",
        name: "TcmAdd",
        query: {
          type: "add",
        },
      });
    },
    // 删除
    deleteApp() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      deleteApplyDetail(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 详情
    showDetail(row) {
      this.$router.push({
        path: "/tcmResult",
        name: "tcmResult",
        query: {
          id: row.id,
          type: row.priceType == "1" ? "changePriceDetail" : "",
          crumbs: ["CBD Price Change", "CBD Price Change Detail"],
        },
      });
    },
    changePrice() {
      if (this.chooseData.length === 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData[0].priceType === 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.notChangePrice"),
          "",
          "warning"
        );
        return;
      }
      this.edit(this.chooseData[0].id);
    },
    // 编辑
    edit(id) {
      this.$router.push({
        path: "/changePrice",
        name: "ChangePrice",
        query: {
          id: id,
        },
      });
    },
    // 选择tcm
    selectTcms() {
      this.$refs.tcmDia.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "6",
        Keyword: "",
        priceType: 0,
        PageType: 3,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
      };
      this.$refs.tcmDia.getlist();
      this.$refs.tcmDia.visible = true;
    },
    // 选择cbd
    // selectCBDs() {
    // 	this.$refs.cbdDia.getlist()
    // 	this.$refs.cbdDia.visible = true
    // },
    // 获取选择的tcm数据
    tcmImport(ids) {
      AddOrUpdatePriceRelation(
        ids,
        (response) => {
          if (response && response.code == 200) {
            this.$refs.tcmDia.close();
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importSuccessfully"),
              "",
              "success"
            );
            this.getList();
          }
        },
        (errResponse) => {
          if (errResponse && errResponse.code == 500) {
            commonUtil.showTipInfo(
              errResponse.message +
                this.$t("unifiedPrompts.unifiedPrompt.duplicateImport"),
              "",
              "warning"
            );
          }
        }
      );
    },
    // 获取选择的CBD数据
    // cbdImport(ids) {
    // }
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>