<template>
  <el-container>
    <el-aside :width="isCollapse ? '220px' : '70px'">
      <div class="Logo-box">
        <img
          :class="[isCollapse ? '' : 'ac-isCollapse']"
          src="../assets/img/logos.png"
        />
      </div>
      <!-- <div class="swich-menu-btn" @click="SwitchIsCollapse">
        <el-icon v-show="isCollapse"><ArrowLeftBold /></el-icon>
        <el-icon v-show="!isCollapse"><ArrowRightBold /></el-icon>
      </div> -->
      <div class="menu-box">
        <div
          v-show="!menuList.length"
          style="padding-top: 100px; text-align: center"
        >
          <el-icon :size="30" color="#2f4cdd"><Loading /></el-icon>
        </div>
        <el-scrollbar v-show="menuList.length">
          <el-menu
            class="el-menu-vertical-demo"
            style="border: none"
            :collapse="!isCollapse"
            text-color="#969BA0"
            active-text-color="#2f4cdd"
            :unique-opened="true"
            :default-active="$route.path"
            router
            @select="SelectMenu"
          >
            <MenuTree
              :menuList="menuList"
              :ActiveMenuItem="ActiveMenuItem"
              :TaskNum="TaskNum"
            ></MenuTree>
          </el-menu>
        </el-scrollbar>
      </div>
    </el-aside>
    <el-main>
      <div class="main-top">
        <div class="main-top-ipt">
          <!-- <input type="text" placeholder="Search Here" />
          <el-icon class="Search-icon"><Search /></el-icon> -->
        </div>
        <div class="main-top-right">
          <div class="Language-box">
            <el-select
              clearable
              :empty-text="$t('tcmlists.tcmlist.noData')"
              v-model="value"
              class="custom-el-select-home"
              @change="changeLanguage"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="Feature-box">
            <div class="Feature-item" @click="OpenPdf">
              <img
                style="width: 25px; height: 25px"
                src="../assets/img/Help.png"
                alt=""
              />
            </div>
            <div class="Feature-item Feature-item-center">
              <span>{{ WarningLCount }}</span>
              <img src="../assets/img/Layer2.png" alt="" />
            </div>
            <div class="Feature-item Feature-item-center">
              <span>{{ TaskNum }}</span>
              <img src="../assets/img/Layer1.png" alt="" />
            </div>
            <!-- <div class="Feature-item">
              <span>1</span>
              <img src="../assets/img/ic_setting.png" alt="" />
            </div> -->
          </div>
          <div class="user-box">
            <div class="user-name" v-if="memberAccount == 'MailAccout'">
              <span>{{ $t("logins.login.hello") }}, {{ memberName }}</span>
            </div>
            <div class="user-name" v-else>
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ $t("logins.login.hello") }},
                  {{ memberName }}
                  <el-icon class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-if="memberAccount != 'System' && isSupplier != '2'"
                      command="ChangePassword"
                      @click="ChangePassword"
                      >{{ $t("homePage.home.changePwd") }}
                    </el-dropdown-item>
                    <el-dropdown-item command="logout">{{
                      $t("homePage.home.logout")
                    }}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>

            <div class="user-sex">
              <img src="../assets/img/touxiang.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="main-content">
        <router-view v-slot="{ Component }">
          <keep-alive :include="$store.state.keepAliveList">
            <component
              :is="Component"
              :key="$route.fullPath"
              ref="child"
            ></component>
          </keep-alive>
        </router-view>
      </div>
    </el-main>
  </el-container>
  <Changepassword
    ref="changeDialog"
    @showMoudleDia="showMoudleDia"
    v-on:handleSubmit="modifySubmit"
  >
  </Changepassword>
</template>

<script>
import {
  GetModulesTree,
  logout,
  changepassword,
  getApplicationList,
  GetUserProfile,
  GetUserRoles,
  GetLoadResquest,
} from "../assets/js/requestApi.js";
import "../assets/css/custom-el-select-home.css";
import "../assets/css/custom-el-menu.css";
import Changepassword from "@/pages/changepassword.vue";
import MenuTree from "@/components/menuTree/menuTree.vue";
import PageTabs from "@/components/pageTabs/pageTabs.vue";
import commonUtil from "@/assets/js/commonUtil.js";
import { addColor } from "../theme/configColor";
import { getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n"; //要在js中使用国际化
import { onMounted } from "vue";
import configUtil from "../../public/config.js";
export default {
  setup() {
    // 切换中英文翻译
    const { proxy } = getCurrentInstance();
    onMounted(() => {
      proxy.value = localStorage.getItem("translate")
        ? JSON.parse(localStorage.getItem("translate"))
        : "en";
      proxy.$i18n.locale = proxy.value;
      if (proxy.value == "zh") {
        proxy.getmenu();
      } else {
        proxy.getmenus();
      }
    });
    function changeLanguage(type) {
      proxy.$i18n.locale = type;
      localStorage.setItem("translate", JSON.stringify(type));
      if (type == "zh") {
        proxy.getmenu();
        proxy.clickFn();
      } else {
        proxy.getmenus();
        proxy.clickFn();
      }
    }

    const { t } = useI18n();
    return { changeLanguage };
  },
  name: "HomePage",
  components: {
    MenuTree,
    Changepassword,
    PageTabs,
  },
  watch: {
    $route: {
      handler(val) {
        this.GetTaskNum();
        // this.GetContactsList();
        this.GetCalendarOverDate();
      },
    },
  },
  data() {
    return {
      ContactSum: 0,
      WarningLCount: 0,
      TaskNum: 0,
      isCollapse: true,
      timer: "",
      types: "",
      powers: "",
      memberName: "",
      memberAccount: "",
      isSupplier: "1",
      chooseFirstMenu: "Home",
      menuList: [],
      // keep-alive的控件实例对象
      keepAliveComponentInstance: null,
      // 用来控制应用加载时页面延迟显示
      showApp: false,
      // 键盘control是否被按下
      isUpKey_Control: false,
      websocketUrl: "",
      websocketModel: false,
      // 中英文
      value: "en",
      // 中英文选项
      options: [
        {
          value: "zh",
          label: "中文",
        },
        {
          value: "en",
          label: "Eng",
        },
      ],
      // 固定的系统首页路由对象
      firstPage: {
        name: "HomePage",
        meta: {
          title: "HomePage",
          zhTitle: "首页",
          requireAuth: false,
          showHeader: true,
          showNav: true,
          showtag: true,
          showPosition: true,
        },
        path: "/homePage",
        query: {},
        params: {},
        fullPath: "/homePage",
      },
      noExecGetCss: 0,
      ActiveMenuItem: null,
      LanguageFlag: null,
    };
  },
  beforeCreate() {},
  created() {
    this.GetTaskNum();

    this.memberName = sessionStorage.getItem("user");
    this.isSupplier = sessionStorage.getItem("isSupplier");
    // this.id = sessionStorage.getItem('id')
    // this.getmenus()
    this.getUserInfo();
    this.getUserRole();
    // this.getKeepAliveContainer();
    // this.setCurrentRoot()
    this.GetCalendarOverDate();
    // this.GetContactsList();
  },
  methods: {
    // 获取过期数据
    GetCalendarOverDate() {
      // 0 是全部 1过期
      let params = {
        IsOverDute: 1,
        IsFilter: 0,
        limit: 9999,
        // -----------
        versionType: 1,
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        key: "",
        PageType: 3,
        partNumber: "",
        programName: "",
      };
      getApplicationList(params, (response) => {
        if (response && response.code == 200) {
          this.WarningLCount = response.count;
        }
      });
    },
    // GetContactsList() {
    //   const { id } = JSON.parse(sessionStorage.getItem("userInfo"));
    //   let params = {
    //     TcmUserld: id,
    //     limit: 9999,
    //     IsFilter: 0,
    //     IsOverDute: 0,
    //   };
    //   getApplicationList(params, (response) => {
    //     if (response && response.code == 200) {
    //       this.ContactSum = response.count;
    //     }
    //   });
    // },
    GetTaskNum() {
      // const { id } = JSON.parse(sessionStorage.getItem("userInfo"));

      let params = {
        // TcmUserld: id,
        IsFilter: 0,
        versionType: 1,
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        PageType: 3,
        partNumber: "",
        programName: "",
      };
      getApplicationList(params, (response) => {
        if (response && response.code == 200) {
          this.TaskNum = response.count;
        }
      });
    },
    SwitchIsCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    SelectMenu(path, item) {
      this.ActiveMenuItem = item[0];
    },
    clickFn() {
      if (this.$route.fullPath == "/modulelist") {
        this.$nextTick(() => {
          this.$refs.child.getTreeData();
          this.$refs.child.getButton();
        });
      }
      this.$nextTick(() => {
        if (this.$refs.child.getUnits) {
          this.$refs.child.getUnits();
        }
        if (this.$refs.child.getUnitss) {
          this.$refs.child.getUnitss();
        }
      });
    },
    LinesValue(i) {},
    // 获取菜单
    getmenu() {
      GetModulesTree("", (response) => {
        if (response && response.code == 200) {
          this.menuList = response.result;
        }
      });
    },
    getmenus() {
      GetModulesTree("", (response) => {
        if (response && response.code == 200) {
          sessionStorage.setItem("menus", response.result);
          response.result.forEach((e) => {
            e.item.name = e.item.nameEn;
            if (e.item.code == "homePage") {
              e.item.src = require("../assets/icon/home.png");
              e.item.acSrc = require("../assets/icon/ac-home.png");
            }
            if (e.item.code == "My Task") {
              e.item.src = "txt";
              e.item.acSrc = "ac-txt";
            }
            if (e.item.code == "TCM DATA") {
              e.item.src = "round";
              e.item.acSrc = "ac-round";
            }
            if (e.item.code == "Tools") {
              e.item.src = "people";
              e.item.acSrc = "ac-people";
            }
            if (e.item.code == "Setting") {
              e.item.src = "home";
              e.item.acSrc = "ac-home";
            }
            e.children.forEach((e) => {
              e.item.name = e.item.nameEn;
              e.children.forEach((e) => {
                e.item.name = e.item.nameEn;
                e.children.forEach((e) => {
                  e.item.name = e.item.nameEn;
                  e.children.forEach((e) => {
                    e.item.name = e.item.nameEn;
                  });
                });
              });
            });
          });
          this.menuList = response.result;
        }
      });
    },
    // 获取登录用户资料
    getUserInfo() {
      GetUserProfile({}, (response) => {
        if (response && response.code == 200) {
          this.memberName = response.result.name;
          this.memberAccount = response.result.account;
          sessionStorage.setItem("user", response.result.name);
          sessionStorage.setItem("userInfo", JSON.stringify(response.result));
        }
      });
    },
    // 获取用户角色
    getUserRole() {
      GetUserRoles({}, (response) => {
        if (response && response.code == 200) {
          sessionStorage.setItem("roles", response.result);
        }
      });
    },
    // 一级菜单点击事件
    firstMenuClick(val) {
      this.chooseFirstMenu = val.name;
      this.$router.push(val.url);
    },
    setCurrentRoot() {
      if (
        sessionStorage.getItem("chooseMenu") &&
        sessionStorage.getItem("chooseMenu") != ""
      ) {
        let data = JSON.parse(sessionStorage.getItem("chooseMenu"));
        this.chooseFirstMenu = data.first;
        this.chooseSecondMenu = data.second;
        this.firstMenuClick(this.chooseFirstMenu);
      }
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    tologout() {
      let _this = this;
      logout({}, (response) => {
        if (response && response.code == 200) {
          sessionStorage.setItem("token", "");
          if (sessionStorage.getItem("phaseII") == 1) {
            _this.$router.push("/login");
          } else {
            if (sessionStorage.getItem("isSupplier") == 2) {
              this.$router.push({
                path: "/login",
                name: "Login",
                query: {
                  phaseII: "II",
                  isSupplier: "x",
                },
              });
            } else {
              this.$router.push({
                path: "/login",
                name: "Login",
                query: {
                  phaseII: "II",
                },
              });
            }
          }
        }
      });
    },
    // 右侧点击事件
    handleCommand(val) {
      if (val == "logout") {
        this.tologout();
      }
    },
    // 切换皮肤
    handleChangeTheme(val) {
      if (val == "Red") {
        addColor("red");
        localStorage.setItem("theme", "red");
      } else if (val == "Blue") {
        addColor("blue");
        localStorage.setItem("theme", "blue");
      }
    },
    // 修改密码
    ChangePassword() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.$refs.changeDialog.form.name = this.memberName;
      this.$refs.changeDialog.form.account = userInfo.account;
      // this.$refs.changeDialog.form.id = this.id;
      this.$refs.changeDialog.visible = true;
    },
    // 显示选择密码弹窗
    showMoudleDia(val) {
      if (val) {
        this.$refs.changeDialog.visible = true;
      }
    },
    modifySubmit(form) {
      let _this = this;
      changepassword(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo("修改成功！", "", "success");
          sessionStorage.setItem("token", "");
          _this.$router.push("/login");
        }
      });
    },
    // 刷新当前标签页组件
    reload() {
      this.$refs.tabs.reload();
    },
    delRouteCache(route) {
      let user = JSON.parse(sessionStorage.getItem("userInfo"));
      if (
        this.$refs.keepAliveContainer.childNodes[0].baseURI.indexOf("phaseII") >
          -1 &&
        user.account == "System"
      ) {
        sessionStorage.setItem("phaseII", 2);
      } else {
        sessionStorage.setItem("phaseII", 1);
      }
      let language = localStorage.getItem("translate")
        ? JSON.parse(localStorage.getItem("translate"))
        : "en";
      if (language == "zh") {
        this.getmenu();
        this.clickFn();
      } else {
        this.getmenus();
        this.clickFn();
      }
    },
    // 获取设置的语言
    getLanguage() {
      this.vaule = localStorage.getItem("translate")
        ? JSON.parse(localStorage.getItem("translate"))
        : "en";
    },
    closeOneTab() {
      conole.log("删除页签");
    },
    OpenPdf() {
      window.open(
        configUtil.gatewayUrl + "UploadFile/TCMDigitalSystemManual2.0.pdf"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  height: 100%;
}
.el-aside {
  position: relative;
  height: 100%;
}
.el-main {
  height: 100%;
  padding: 0 !important;
  background: #f2f2f2;
}
.Logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
}
.Logo-box img {
  width: 120px;
  height: 45px;
}
.ac-isCollapse {
  width: 70px !important;
}
.Shrink-switch {
  padding-left: 20px;
}
.menu-box {
  position: relative;
  overflow: hidden;
  height: calc(100% - 80px);
}
.main-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0px 40px 0 20px;
  background-color: rgba(255, 255, 255, 0.8);
  .main-top-ipt {
    position: relative;
  }
  .main-top-ipt input {
    width: 400px;
    height: 35px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    font-size: 12px;
    color: #666;
  }
  .Search-icon {
    position: absolute;
    right: 20px;
    transform: translateY(-50%);
    top: 50%;
    font-size: 18px;
    color: #969ba0;
  }
  input::placeholder {
    color: #969ba0; /* 自定义占位符文本颜色 */
    font-size: 12px;
  }
  .main-top-right {
    display: flex;
    align-items: center;
  }
  .Feature-box {
    display: flex;
    .Feature-item {
      position: relative;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 7px;
      background-color: #ffebe7;
      color: #ff6d4d;
      cursor: pointer;
    }
    img {
      width: 16px;
      height: 16px;
    }
    span {
      position: absolute;
      top: 8%;
      left: -20%;
      padding: 2px;
      min-width: 8px;
      height: 20px;
      line-height: 20px;
      margin-top: -11px;
      padding: 0 6px;
      font-weight: normal;
      color: white;
      text-align: center;
      text-shadow: 0 1px rgba(0, 0, 0, 0.2);
      background: #e23442;
      border-radius: 11px;
      background: #ff6d4d;
      color: #fff;
    }
    .Feature-item-center {
      background: #e9ecff;
      color: #2f4cdd;
      span {
        background: #2f4cdd;
        color: #fff;
      }
    }
  }
  .user-box {
    margin-left: 50px;
    position: relative;
    display: flex;
    height: 40px;
    line-height: 40px;
    align-items: center;
    .user-name {
      padding-left: 20px;
      padding-right: 30px;
      border-radius: 5px;
      // width: 150px;
      background: #2f4cdd;
      color: #fff;
    }
    .el-icon--right {
      margin-left: 10px;
    }
    .user-name::after {
      content: "";
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: block;
      background: #fff;
      position: absolute;
      top: 50%;
      right: -15px;
      transform: translateY(-50%);
    }
    .user-sex {
      position: absolute;
      right: -15px;
      top: 0px;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      overflow: hidden;
      background-color: #ccc;
      z-index: 999;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main-content {
  padding: 15px;
  height: calc(100% - 70px);
  overflow-y: scroll;
}
.el-dropdown-link {
  color: #fff;
  line-height: 40px;
}
.Language-box {
  margin-right: 30px;
}
.swich-menu-btn {
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  line-height: 25px;
  text-align: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #2f4cdd;
  color: #fff;
  z-index: 99;
  cursor: pointer;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 220px;
}
</style>

