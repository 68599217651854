<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import DevicePixelRatio from "./assets/js/devicePixelRatio";
import commonUtil from "./assets/js/commonUtil.js";
export default {
  name: "App",
  data() {
    return {
      screenWidth: "",
      screenHeight: "",
    };
  },
  watch: {
    screenWidth() {
      // new DevicePixelRatio().init();
    },
  },
  created() {
    // new DevicePixelRatio().init();
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
      })();
    };
  },
};
</script>

<style lang="scss">
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  /* margin-top: 60px; */
  height: 100%;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor) !important;
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
/* --------- inpit默认样式 */
input {
  padding-left: 5px;
  width: 100%;
  height: 30px;
  caret-color: #2f4cdd;
  outline: none; /* 移除默认边框 */
  border: none;
  font-size: 20px;
  color: #606266;
}
input::placeholder {
  // color: #2f4cdd; /* 自定义占位符文本颜色 */
  font-size: 14px;
}
* {
  padding: 0;
  margin: 0;
}
div {
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.title {
  padding-left: 15px;
  font-weight: 600;
  font-size: 24px;
  color: #000;
}

// 分页
.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3e4954;
}
// 分页
.pagination .el-pager {
  padding: 3px;
  background: #d3d6e4 !important;
  border-radius: 3px;
}
.el-pagination .el-pager li {
  background: #d3d6e4 !important;
  border-radius: 10px;
}
.el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
  background: #fff !important;
  color: #000 !important;
}
.el-pagination.is-background .btn-next.is-last,
.el-pagination.is-background .btn-prev.is-last {
  background: #d3d6e4 !important;
  padding: 20px 30px;
  border-radius: 5px;
  color: #2f4cdd !important;
}

.el-pagination.is-background .btn-next.is-first,
.el-pagination.is-background .btn-prev.is-first {
  background: #d3d6e4 !important;
  padding: 20px 30px;
  border-radius: 5px;
  color: #2f4cdd !important;
}
// 表格
:deep .el-table .cell {
  color: #000;
  text-align: center;
}
:deep .el-table__header-wrapper {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
//

// .regional_table .el-table {
//   height: 500px;
// }

.regional_table .el-table,
.regional_table .el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0.4) !important;
  color: #3e4954;
}
/* 表格内背景颜色 */
.el-table tr,
.el-table td {
  background-color: rgba(255, 255, 255, 0.1) !important;
  //表格字体颜色
  color: #3e4954;
  font-size: 13px;
}

.el-table__body tr.el-table__row--striped td {
  // background-color: rgba(159, 150, 150, 0.1) !important;
  background-color: rgba(167, 162, 162, 0.1) !important;
}

.regional_table .el-table .hover-row {
  background-color: #e9ecff !important;
}

.el-table__row:hover {
  background-color: #e9ecff !important;
}

.regional_table .el-checkbox__inner {
  background: #d9d9d9;
}

.el-table__fixed-right {
  z-index: 99; /* 设置右侧固定列的z-index */
}
.el-table__row {
  border-right: 1px solid #dfe6ec;
  // .lastTd {
  //   background-color: #fff !important;
  // }
}
.regional_table .el-table__row .lastTd {
  background-color: #fff !important;
}

// 日期选择器
.el-range-editor.el-input__wrapper {
  background: #f4f5f9;
  border: none;
}
.el-icon {
  cursor: pointer;
}
.action-box {
  .action-btn {
    display: flex;
    align-items: center;
    height: 25px;
    font-size: 16px;
    cursor: pointer;
  }
  .el-icon,
  img {
    margin: 0 20px 0 20px;
    font-weight: 700;
    font-size: 18px;
  }
  .action-btn:hover {
    color: #2f4cdd !important;
    background: #e9ecff;
  }
}

//  hover

.el-dialog .el-icon:hover {
  color: #2f4cdd !important;
}
.el-dialog {
  border-radius: 20px !important;
}
.el-button {
  border-radius: 20px !important;
}
.color-67C23A {
  color: #40d4a8;
}
.color-ff0000 {
  color: #ff0000;
}
.color-F59A23 {
  color: #f59a23;
}
.color-0000FF {
  color: #0000ff;
}
.color-D9001B {
  color: #d9001b;
}

.el-input.is-disabled .el-input__wrapper {
  background: #f4f5f9 !important;
}

// .el-select .el-input__wrapper {
//   font-size: 18px;
// }
.main-handle-item {
  margin-right: 10px;
  flex: 1;
  .el-select .el-input__wrapper {
    height: 30px;
  }
}

.my-badge {
  color: #ff6d4d;
  background: #ffebe7;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 20px;
  text-align: center;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  width: 100%;
}

.Total-MaterialCost {
  font-size: 15px;
  input {
    font-size: 14px !important;
  }
}

.el-select {
  font-size: 12px;
}
.select-btn {
  span {
    color: #3e4954;
    font-size: 15px;
  }
}
.crumbs-box {
  padding-left: 15px !important;
}
.color-red {
  padding-right: 3px;
  color: #d9001b;
  color: red;
  font-size: 18px;
}
.main-handle {
  line-height: 18px;
}
.main-handle input::placeholder {
  font-size: 14px !important;
  color: #a8abb2 !important;
  text-align: left !important;
}
.select-btn span {
  color: #a8abb2;
  font-size: 14px;
}
</style>
