<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">Change Rate</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <div class="rate-cla" v-for="(item, index) in addCellExchangeList">
        <div class="line-32">
          {{ type == "1" ? item.currencyName : item.extCurrencyName }}
        </div>
        <div>
          <img
            src="../../../assets/img/tcm/youjiantou.png"
            class="arrows-img mg-5"
          />
        </div>
        <div class="line-32 mgr-10">
          {{ type == "1" ? item.extCurrencyName : item.extCurrencyName2 }}
        </div>
        <div>
          <el-input
            v-model="item.currValue"
            @input="(v) => (item.currValue = v.replace(/[^\d.]/g, ''))"
            style="width: 60px"
          />
          <span style="margin: 0 10px">:</span>
          <el-input
            v-model="item.convertCurrValue"
            @input="(v) => (item.convertCurrValue = v.replace(/[^\d.]/g, ''))"
            style="width: 60px"
          />
          <!-- <el-input
					    v-model="item.rate"
						@input="(v) => (item.rate = v.replace(/[^\d.]/g, ''))"
					    style="width: 100px"
					/> -->
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">Cancle</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="addSubmit"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "@/assets/js/commonUtil.js";
export default {
  emits: ["rateSubmit"],
  data() {
    return {
      visible: false,
      addCellExchangeList: [],
      type: "",
    };
  },
  methods: {
    close() {
      this.addCellExchangeList = [];
      this.type = "";
      this.visible = false;
    },
    addSubmit() {
      let _this = this;
      let untaxed = 0;
      let isZero = 0;
      this.addCellExchangeList.forEach(function (val, index) {
        if (val.rate === "") {
          untaxed++;
        }
      });
      if (untaxed > 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.hasUntaxedToFill"),
          "",
          "warning"
        );
        return;
      }
      this.addCellExchangeList.forEach(function (val, index) {
        if (parseFloat(val.rate) === 0) {
          isZero++;
        }
      });
      if (isZero > 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.rateThanZero"),
          "",
          "warning"
        );
        return;
      }
      let exchangeList = [];
      this.addCellExchangeList.forEach(function (val, index) {
        exchangeList.push({
          currencyId: _this.type == "1" ? val.currencyId : val.extCurrencyId,
          currValue: val.currValue === "" ? 1 : parseFloat(val.currValue),
          convertCurrValue:
            val.convertCurrValue === "" ? 1 : parseFloat(val.convertCurrValue),
          // rate: val.rate
        });
      });
      // console.log(exchangeList)
      this.$emit("rateSubmit", exchangeList, this.type);
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
.rate-cla {
  display: flex;
  margin-bottom: 10px;
}
.line-32 {
  line-height: 32px;
}
.mg-5 {
  margin: 5px;
}
.mgr-10 {
  margin-right: 10px;
}
.arrows-img {
  width: 20px;
}
</style>
