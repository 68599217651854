<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.Template") }}</div>
      <div class="main-handle flex">
        <!-- 状态 -->
        <div class="main-handle-item">
          {{ $t("templateLists.templateList.enable") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.Enable"
            :placeholder="$t('templateLists.templateList.enable')"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="main-handle-item">
          {{ $t("templateLists.templateList.region") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.RegionId"
            :placeholder="$t('templateLists.templateList.region')"
          >
            <el-option
              v-for="item in regionOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <!-- 日期选择 -->
        <div class="main-handle-item" style="margin-top: 15px">
          <div class="select-box">
            <div class="select-btn">
              <el-icon class="Calendar"><Calendar /></el-icon>
              <span :style="{ color: searchForm.date ? '#606266' : '' }">{{
                searchForm.date
                  ? searchForm.date[0] + " - " + searchForm.date[1]
                  : $t("tcmdatalists.tcmdatalist.pleaseSlect")
              }}</span>
              <el-icon v-show="!searchForm.date">
                <ArrowDownBold />
              </el-icon>
              <el-icon :size="18" v-show="searchForm.date">
                <Close />
              </el-icon>
            </div>
            <el-date-picker
              class="date-picker"
              style="
                width: 200px;
                height: 30px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
              "
              v-model="searchForm.date"
              type="daterange"
              range-separator="To"
              :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
              :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
              size="mini"
              value-format="YYYY-MM-DD"
              @change="changeDate"
            />
          </div>
        </div>
        <div class="main-handle-item" style="margin-top: 15px">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search" style="margin-top: 15px">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>

    <div class="handle-btn flex">
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="addTemplate"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('start')"
      >
        {{ $t("templateLists.templateList.batchEnable") }}
      </div>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('stop')"
      >
        {{ $t("templateLists.templateList.batchDisabling") }}
      </div>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('delete')"
        v-if="this.btnDel > 0"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.batchDelete") }}
      </div>
      <!-- <div class="operate-btn" style="margin-right: 10px;" @click="bathOperate('delete')">批量删除</div> -->

      <div style="padding-left: 20px; text-align: right">
        <span style="line-height: 32px; margin-right: 10px; font-weight: 600">{{
          $t("templateLists.templateList.revisions")
        }}</span>
        <el-switch
          v-model="historyVal"
          class="ml-2"
          inline-prompt
          style="--el-switch-on-color: #b2bde7"
          active-text="Y"
          inactive-text="N"
        />
      </div>
    </div>
    <div class="tcm-table">
      <el-row :gutter="10">
        <el-col
          :span="4"
          style="
            width: 250px;
            min-width: 250px;
            max-width: 250px;
            background-color: #fff;
          "
        >
          <div class="tree-cla">
            <div class="tree-cla-search">
              <input
                v-model="filterText"
                :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
              />
            </div>
            <div :style="{ height: TableHeight + 'px' }" class="tree-box">
              <el-tree
                :empty-text="$t('tcmlists.tcmlist.noData')"
                ref="treeRef"
                class="filter-tree custom-el-tree"
                :data="treedata"
                :props="defaultProps"
                default-expand-all
                :filter-node-method="filterNode"
                :highlight-current="true"
                @node-click="handleNodeClick"
              >
                <template #default="{ node, data }">
                  <div class="custom-tree-node">
                    <span class="my-text" :title="node.label">{{
                      node.label
                    }}</span>
                    <span class="my-badge">{{ data.Quantity }}</span>
                  </div>
                </template>
              </el-tree>
            </div>
          </div>
        </el-col>
        <el-col :span="20" style="max-width: calc(100% - 250px)">
          <div class="regional_table">
            <el-table
              border
              stripe
              :empty-text="$t('tcmlists.tcmlist.noData')"
              :data="tableData"
              :height="TableHeight"
              style="width: 100%"
              @row-click="RowClick"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>

              <el-table-column
                prop="number"
                :label="$t('templateLists.templateList.templateNo')"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="name"
                :label="$t('templateLists.templateList.templateName')"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="regionName"
                :label="$t('templateLists.templateList.region')"
                width="100"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="currencyName"
                :label="$t('changePriceLists.changePriceList.currency')"
                width="90"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="version"
                :label="$t('templateLists.templateList.version')"
                width="100"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="commodityName"
                :label="$t('tcmdatalists.tcmdatalist.commodity')"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="comment"
                :label="$t('templateLists.templateList.comments')"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="creator"
                :label="$t('templateLists.templateList.creator')"
                width="100"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="date"
                :label="$t('templateLists.templateList.date')"
                show-overflow-tooltip
                align="center"
              />
              <el-table-column
                prop="enable"
                :label="$t('templateLists.templateList.enable')"
                width="80"
                show-overflow-tooltip
                align="center"
              >
                <template #default="scope">
                  <el-icon
                    :size="16"
                    color="#40D4A8"
                    v-show="scope.row.isEnable == '1'"
                  >
                    <SuccessFilled />
                  </el-icon>
                  <el-icon
                    :size="16"
                    color="#F56C6C"
                    v-show="scope.row.isEnable == '2'"
                  >
                    <CircleCloseFilled />
                  </el-icon>
                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                width="80"
                fixed="right"
                class-name="lastTd"
                :label="$t('templateLists.templateList.operate')"
                align="center"
              >
                <template #default="scope">
                  <el-popover placement="bottom" trigger="hover">
                    <template #reference>
                      <div style="text-align: center">
                        <el-icon><MoreFilled /></el-icon>
                      </div>
                    </template>
                    <div class="action-box">
                      <div
                        class="action-btn"
                        @click="previewTemplate(scope.row)"
                      >
                        <el-icon><View /></el-icon>
                        <span>{{
                          $t("unifiedPrompts.unifiedPrompt.preview")
                        }}</span>
                      </div>
                      <div
                        class="action-btn"
                        v-if="
                          scope.row.isrelevance && scope.row.isrelevance == 1
                        "
                        @click="editTemplate(scope.row)"
                      >
                        <el-icon><Edit /></el-icon>
                        <span>
                          {{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span
                        >
                      </div>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination">
            <div>
              {{ $t("homePage.home.Showing") }} {{ pageSize }}
              {{ $t("homePage.home.From") }} {{ total }}
              {{ $t("homePage.home.Data") }}
              <el-popover placement="top" :width="70" trigger="click">
                <template #reference>
                  <el-icon
                    style="
                      margin-left: 5px;
                      font-size: 17px;
                      vertical-align: middle;
                    "
                    ><Operation
                  /></el-icon>
                </template>
                <el-input
                  v-model="searchForm.limit"
                  @input="
                    (val) => (searchForm.limit = val.replace(/[^\d]/g, ''))
                  "
                  :min="1"
                  style="width: 130px"
                  @change="handleSizeChange"
                >
                  <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
                </el-input>
              </el-popover>
            </div>
            <el-pagination
              layout="prev, pager, next"
              background
              :total="total"
              :page-size="pageSize"
              :current-page="searchForm.page"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  GetOrgs,
  GetTemplateApplys,
  deleteTemplateApplys,
  startTemplateApplys,
  stopTemplateApplys,
  getRegions,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import "../../assets/css/custom-el-tree.css";
export default {
  name: "TemplateList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        date: "",
        Enable: 0,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: "",
        currencyId: "",
        regionId: "",
        isDraft: 0,
        VersionType: 1,
        page: 1,
        limit: 10,
      },
      // 总页数
      total: 0,
      pageSize: 10,
      // status选项
      statusOptions: [
        {
          value: 0,
          label: "All",
        },
        {
          value: 1,
          label: "Enable",
        },
        {
          value: 2,
          label: "Disable",
        },
      ],
      filterText: "",
      // 商品分类数据
      treedata: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // region选项
      regionOptions: [],
      // 表格数据
      tableData: [],
      // 已选择的商品分类名称
      CommodityName: "",
      // 已勾选数据
      chooseData: [],
      // 是否为历史版本
      historyVal: false,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      preview: "",
      timers: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
    historyVal(val) {
      if (val) {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 2;
        this.search();
      } else {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 1;
        this.search();
      }
    },
  },
  mounted() {
    this.autoHeight();
    this.getlist();
    this.getTreeData();
    this.getRegion();
    this.timer = setTimeout(this.gettime, 1000);
    this.getUnits();
  },
  methods: {
    RowClick(row) {
      this.previewTemplate(row);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.timers = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        this.statusOptions = [
          {
            value: 0,
            label: "All",
          },
          {
            value: 1,
            label: "Enable",
          },
          {
            value: 2,
            label: "Disable",
          },
        ];
      } else {
        this.statusOptions = [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "激活",
          },
          {
            value: 2,
            label: "禁用",
          },
        ];
      }
      clearTimeout(this.timers);
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加" || e.name == "Add") {
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.name == "Delete") {
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.name == "Edit") {
          this.btnEdit = 1;
        }
        if (e.name == "预览" || e.name == "Preview") {
          this.preview = 1;
        }
      });
      clearTimeout(this.timer);
    },
    getlist() {
      this.searchForm = {
        date: "",
        Enable: 0,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        regionId: "",
        CommodityId: "",
        currencyId: "",
        VersionType: 1,
        isDraft: 0,
        page: 1,
        limit: 10,
      };
      this.search();
    },
    // 查询
    search() {
      GetTemplateApplys(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addTemplate() {
      // if (this.searchForm.CommodityId == '') {
      // 	commonUtil.showTipInfo('请选择商品类别！', '', 'warning')
      // 	return;
      // }
      this.$router.push({
        path: "/addTemplate",
        name: "AddTemplate",
        query: {
          type: "add",
          crumbs: ["Template", "Add Template"],
        },
      });
    },
    // 编辑
    editTemplate(row) {
      this.$router.push({
        path: "/addTemplate",
        name: "AddTemplate",
        query: {
          type: "edit",
          CommodityName: row.commodityName,
          id: row.id,
          crumbs: ["Template", "Edit Template"],
        },
      });
    },
    // 复制
    copyData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$router.push({
        path: "/addTemplate",
        name: "AddTemplate",
        query: {
          type: "editCopy",
          CommodityName: this.chooseData[0].commodityName,
          id: this.chooseData[0].id,
        },
      });
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    // 获取组织树数据
    getTreeData() {
      let _this = this;
      GetOrgs(
        {
          type: 1,
          versionType: this.searchForm.VersionType,
          TypeCost: 0,
          isAll: 1,
        },
        (response) => {
          if (response && response.Code == 200) {
            let data = response.Result;
            data.forEach(function (val, key) {
              val.disabled = val.isEnable == 1 ? false : true;
            });
            this.treedata = _this.transferList(response.Result);
          }
        }
      );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {
      this.searchForm.CommodityId = "";
      this.CommodityName = "";
      if (data.disabled) {
        commonUtil.showTipInfo("当前商品分类已禁用！", "", "warning");
        return;
      }
      this.searchForm.CommodityId = data.Id;
      this.CommodityName = data.Name;
      this.search();
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.Id === item.ParentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 查看详情
    previewTemplate(row) {
      this.$router.push({
        path: "/addTemplate",
        name: "AddTemplate",
        query: {
          type: "detail",
          CommodityName: row.commodityName,
          id: row.id,
          crumbs: ["Template", "Template Detail"],
        },
      });
    },
    // 删除
    deleteTemplate(id) {
      let ids = [];
      ids.push(id);
      this.deleteTem(ids);
    },
    // 调用删除接口
    deleteTem(ids) {
      deleteTemplateApplys(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 批量操作
    bathOperate(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      if (type == "delete") {
        this.deleteTem(ids);
      } else if (type == "start") {
        // 启用
        startTemplateApplys(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.enableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      } else if (type == "stop") {
        // 禁用
        stopTemplateApplys(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.disableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.tree-box {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>