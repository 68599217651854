<template>
	<div class="tcm-content">
		<div class="tcm-content-form">
			<el-row>
				<el-col :span="12">
					<el-row>
						<el-col :span="20">
							<el-form :inline="true" :model="form" label-width="150px">
								<el-form-item label="Supplier Account" style="width: 100%;"> </el-form-item>
								<el-form-item label="Account" style="width: 100%;">
									<el-input v-model="form.quoteTypeId" placeholder="" style="width: calc(100% - 160px);"/>
								</el-form-item>
								<el-form-item label="Password" style="width: 100%;">
									<el-input v-model="form.quoteTypeId" placeholder="" style="width: calc(100% - 160px);"/>
								</el-form-item>
							</el-form>
						</el-col>
						<el-col :span="4" class="position-re">
							<div class="operate-cla">
								<div class="operate-btn" @click="submit">Create</div>
								<!-- <div class="operate-btn" @click="submit">Close</div> -->
							</div>
							
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</div>
	</div>
	
</template>

<script>
	export default{
		data() {
			return {
				form: {
					quoteTypeId: ''
				}
			}
		}
	}
</script>

<style scoped>
	.tcm-content {
		padding: 20px 90px 200px 20px ;
		box-sizing: border-box;
	}
	.tcm-content-form{
		width: calc(100%);
		/* margin-left: 50px; */
		background-color: #f2f2f2;
		border-radius: 5px;
		margin-top: 10px;
		padding: 20px 50px 10px 50px;
		box-sizing: border-box;
	}
	.operate-btn{
		border-radius: 5px;
		background-color: var(--themeColor);
		color: #fff;
		text-align: center;
		padding: 7px 10px;
		margin: 0 10px;
		display: inline-block;
		min-width: 80px;
		cursor: pointer;
	}
	.position-re{
		position: relative;
	}
	.operate-cla{
		position: absolute;
		bottom: 18px;
		left: 0;
	}
</style>