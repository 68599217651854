<template>
  <el-dialog v-model="visible" width="400px" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("unifiedPrompts.unifiedPrompt.hasUntaxedToFill") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <div class="rate-cla" v-for="(item, index) in addCellExchangeList">
        <div class="line-32">{{ item.currencyName }}</div>
        <div>
          <img
            src="../../assets/img/tcm/youjiantou.png"
            class="arrows-img mg-5"
          />
        </div>
        <div class="line-32 mgr-10">
          {{ item.currencyCNYReportCurrencyName }}
        </div>
        <div>
          <el-input
            v-model="item.exchangeRate"
            @input="(v) => (item.exchangeRate = v.replace(/[^\d.]/g, ''))"
            style="width: 240px"
            :disabled="item.hasRate"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="addSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "@/assets/js/commonUtil.js";
export default {
  emits: ["rateSubmit"],
  data() {
    return {
      visible: false,
      addCellExchangeList: [],
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    addSubmit() {
      let untaxed = 0;
      let isZero = 0;
      this.addCellExchangeList.forEach(function (val, index) {
        if (val.exchangeRate === "") {
          untaxed++;
        }
      });
      if (untaxed > 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.hasUntaxedToFill"),
          "",
          "warning"
        );
        return;
      }
      this.addCellExchangeList.forEach(function (val, index) {
        if (parseFloat(val.exchangeRate) === 0) {
          isZero++;
        }
      });
      if (isZero > 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.rateThanZero"),
          "",
          "warning"
        );
        return;
      }
      console.log(this.addCellExchangeList, " this.addCellExchangeList");
      this.$emit("rateSubmit", this.addCellExchangeList);
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
.rate-cla {
  display: flex;
  margin-bottom: 10px;
}
.line-32 {
  line-height: 32px;
}
.mg-5 {
  margin: 5px;
}
.mgr-10 {
  margin-right: 10px;
}
.arrows-img {
  width: 20px;
}
</style>
