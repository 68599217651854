<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">Role Management</div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          {{ $t("ccls.ccl.enable") }}
          <el-select
            clearable
            v-model="searchForm.Enable"
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            style="width: 160px"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>

        <div class="main-handle-item" style="margin-top: 15px">
          <input
            type="text"
            v-model="searchForm.key"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" style="margin-top: 15px" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div class="handle-btn-item" @click="addRoles" v-if="this.btnAdd > 0">
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div class="handle-btn-item" @click="allocate">
        {{ $t("userlists.userlist.assignModules") }}
      </div>
      <div class="handle-btn-item" @click="AssigningUsers">
        {{ $t("userlists.userlist.assignUsers") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :empty-text="$t('tcmlists.tcmlist.noData')"
        ref="roleTable"
        :data="Role_List"
        :height="TableHeight"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @select-all="selectAll"
        @row-click="RowClick"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="name"
          :label="$t('userlists.userlist.roleName')"
          align="center"
          width="150"
        />
        <el-table-column
          :label="$t('userlists.userlist.allocatedModules')"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ iszn ? scope.row.modulesName : scope.row.modulesNameEn }}
          </template>
        </el-table-column>
        <el-table-column
          prop="usersName"
          :label="$t('userlists.userlist.assignedUsers')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="status"
          :label="$t('userlists.userlist.enable')"
          align="center"
          width="100"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.status == '0'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.status == '1'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          fixed="right"
          class-name="lastTd"
          align="center"
          prop="action"
          label="action"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div
                  class="action-btn"
                  @click="editRoles(scope.row)"
                  v-if="this.btnEdit > 0"
                >
                  <el-icon><Edit /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
                <div
                  class="action-btn"
                  @click="del(scope.row.id)"
                  v-if="this.btnDel > 0"
                >
                  <el-icon><Delete /></el-icon>
                  <span> {{ $t("unifiedPrompts.unifiedPrompt.delete") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        Showing {{ pageSize }} from {{ total }} data
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <MoudleDia
      ref="moudleDialog"
      :roleId="searchForm.id"
      v-on:handleSubmit="assignSubmit"
    ></MoudleDia>
    <UserMode
      ref="userModelog"
      :roleId="searchForm.id"
      v-on:handleSubmit="userSubmit"
    ></UserMode>
    <AddRole ref="addRoleDia" v-on:handleSubmit="addSubmit"></AddRole>
    <EditRole ref="editRloe" v-on:handleSubmit="editSubmit"></EditRole>
  </div>
  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <table>
        <tr>
          <td>
            {{ $t("ccls.ccl.enable") }}
            <el-select
              clearable
              v-model="searchForm.Enable"
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              style="width: 160px"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </td>
          <td>
            {{ $t("unifiedPrompts.unifiedPrompt.keyword") }}
            <el-input
              v-model="searchForm.key"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
              style="width: 160px"
            />
          </td>
          <td>
            <div class="operate-btn mgr-10" @click="search">
              {{ $t("userlists.userlist.search") }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="5" style="padding-top: 10px">
            <div
              class="operate-btn mgr-10"
              @click="addRoles"
              v-if="this.btnAdd > 0"
            >
              {{ $t("unifiedPrompts.unifiedPrompt.new") }}
            </div>
            <div class="operate-btn" @click="allocate">
              {{ $t("userlists.userlist.assignModules") }}
            </div>
            <div class="operate-btn" @click="AssigningUsers">
              {{ $t("userlists.userlist.assignUsers") }}
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        ref="roleTable"
        :data="Role_List"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @select-all="selectAll"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          :label="$t('userlists.userlist.operate')"
          align="center"
          width="200"
        >
          <template #default="scope">
            <div class="flex-around">
              <div
                class="operate-btnx"
                @click="editRoles(scope.row)"
                v-if="this.btnEdit > 0"
              >
                {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
              </div>
              <div
                class="operate-btnx"
                @click="del(scope.row.id)"
                v-if="this.btnDel > 0"
              >
                {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('userlists.userlist.roleName')"
          align="center"
          width="150"
        />
        <el-table-column
          :label="$t('userlists.userlist.allocatedModules')"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            {{ iszn ? scope.row.modulesName : scope.row.modulesNameEn }}
          </template>
        </el-table-column>
        <el-table-column
          prop="usersName"
          :label="$t('userlists.userlist.assignedUsers')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="status"
          :label="$t('userlists.userlist.enable')"
          align="center"
          width="100"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.status == '0'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.status == '1'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-cla">
      <div>
        <el-input
          v-model="searchForm.limit"
          @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
          min="1"
          style="width: 130px"
          @change="handleSizeChange"
        >
          <template #suffix>
            {{ $t("ccls.ccl.barPage") }}
          </template>
        </el-input>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
    <MoudleDia
      ref="moudleDialog"
      :roleId="searchForm.id"
      v-on:handleSubmit="assignSubmit"
    ></MoudleDia>
    <UserMode
      ref="userModelog"
      :roleId="searchForm.id"
      v-on:handleSubmit="userSubmit"
    ></UserMode>
    <AddRole ref="addRoleDia" v-on:handleSubmit="addSubmit"></AddRole>
    <EditRole ref="editRloe" v-on:handleSubmit="editSubmit"></EditRole>
  </div> -->
</template>

<script>
import MoudleDia from "./moudleDia.vue";
import AddRole from "./addRole.vue";
import EditRole from "./editRole.vue";
import UserMode from "./userMode.vue";
import commonUtil from "../../assets/js/commonUtil.js";
import {
  roleList,
  getCalculatorList,
  delRole,
  addRole,
  editRole,
  assignRole,
  assignMoudle,
  cancelMoudle,
} from "@/assets/js/requestApi.js";
export default {
  name: "RoleList",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  components: {
    MoudleDia,
    AddRole,
    getCalculatorList,
    EditRole,
    UserMode,
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        commodity: "",
        Enable: 0,
        id: "",
        date: "",
        key: "",
        page: 1, //当前页
        limit: 10, //每页个数
      },
      form: {},
      // status选项
      statusOptions: [],
      Role_List: [],
      total: 0,
      pageSize: 10,
      treedata: [],
      // 已勾选的数据
      chooseData: [],
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      timers: "",
      // 是否是中文
      iszn: true,
    };
  },
  mounted() {
    this.autoHeight();
    this.initList();
    // this.getTreeData();
    this.timer = setTimeout(this.gettime, 1000);
    this.getUnitss();
  },
  methods: {
    RowClick(row) {
      if (this.btnEdit > 0) {
        this.editRoles(row);
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.$refs.addRoleDia.getUnits();
      this.iszn =
        JSON.parse(localStorage.getItem("translate")) == "en" ||
        JSON.parse(localStorage.getItem("translate")) == null
          ? false
          : true;
    },
    getUnitss() {
      this.timers = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      this.iszn =
        JSON.parse(localStorage.getItem("translate")) == "en" ||
        JSON.parse(localStorage.getItem("translate")) == null
          ? false
          : true;
      if (this.powers == "en" || this.powers == null) {
        this.statusOptions = [
          {
            value: 0,
            label: "All",
          },
          {
            value: 1,
            label: "Enable",
          },
          {
            value: 2,
            label: "Disable",
          },
        ];
      } else {
        this.statusOptions = [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "激活",
          },
          {
            value: 2,
            label: "禁用",
          },
        ];
      }
      clearTimeout(this.timers);
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加" || e.domId == "Add") {
          this.btnAdd = 1;
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.domId == "Del") {
          this.btnDel = 1;
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.domId == "Edit") {
          this.btnEdit = 1;
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    // 渲染列表数据
    initList() {
      this.searchForm = {
        Enable: 0,
        id: "",
        date: "",
        key: "",
        IsPhaseII: sessionStorage.getItem("phaseII") == 2 ? 1 : 0,
        page: 1, //当前页
        limit: 10, //每页个数
      };
      this.search();
    },
    // 表格单选
    handleSelectionChange(val) {
      if (val.length == 1) {
        this.searchForm.id = val[0].id;
      } else if (val.length > 1) {
        this.$refs.roleTable.clearSelection();
        this.$refs.roleTable.toggleRowSelection(val.pop());
      }
    },
    selectAll() {
      this.$refs.roleTable.clearSelection();
      this.searchForm.id = "";
    },
    // 为角色分配模块
    allocate() {
      if (this.searchForm.id == "") {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.moudleDialog.getTree();
      this.$refs.moudleDialog.visible = true;
      // this.$refs.moudleDialog.treeRef.setCheckedKeys([]);
    },
    assignSubmit(data, arr) {
      this.search();
    },
    // 为角色分配用户
    AssigningUsers() {
      if (this.searchForm.id == "") {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.userModelog.searchForm.page = 1;
      this.$refs.userModelog.getTreeData();
      var that = this;
      this.$refs.userModelog.getRemoteUserRoles(function () {
        that.$refs.userModelog.getlist();
      });
      this.$refs.userModelog.visible = true;
    },
    //为用户分配角色提交
    userSubmit(data) {
      assignRole(data, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 新增角色
    addRoles() {
      this.$refs.addRoleDia.visible = true;
    },
    // 新增角色提交
    addSubmit(form) {
      addRole(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 编辑角色
    editRoles(row) {
      this.$refs.editRloe.visible = true;
      this.$refs.editRloe.form.name = row.name;
      this.$refs.editRloe.form.nameEn = row.nameEn;
      this.$refs.editRloe.form.id = row.id;
      this.$refs.editRloe.form.status = row.status;
    },
    // 编辑角色 提交
    editSubmit(form) {
      editRole(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo("修改成功", "", "success");
          this.search();
        }
      });
    },
    // 查询
    search() {
      roleList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.Role_List = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 高级查询
    AdvancedSearch() {},
    // 删除
    del(id) {
      delRole((id = [id]), (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>