<template>
  <el-dialog v-model="visible" width="450px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{
            form.id === ""
              ? $t("ccls.ccl.addRegion")
              : $t("ccls.ccl.editRegion")
          }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("ccls.ccl.name") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input v-model="form.name" style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <!-- <span class="color-red">*</span> -->
            {{ $t("ccls.ccl.englishNames") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input v-model="form.nameEn" style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            {{ $t("ccls.ccl.enable") }}
          </div>
        </el-col>
        <el-col :span="13"><el-switch v-model="form.isEnable" /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        name: "",
        nameEn: "",
        isEnable: true,
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        id: "",
        name: "",
        nameEn: "",
        isEnable: true,
      };
    },
    confirm() {
      if (this.form.name == "") {
        commonUtil.showTipInfo(this.$t("ccls.ccl.pleaseName"), "", "warning");
        return;
      }
      this.$emit("confirmInfo", this.form);
      this.close();
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9 {
  padding: 9px;
}
.el-row {
  margin-bottom: 10px;
}
.color-red {
  color: red;
}
.align-right {
  text-align: right;
}
</style>