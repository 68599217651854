<template>
  <el-dialog v-model="visible" width="450px" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ saveType == "template" ? "Save template" : "Save draft" }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9-0">
            {{ saveType == "template" ? "Template Name" : "Draft Name" }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input v-model="form.name" style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9-0">Region</div>
        </el-col>
        <el-col :span="13">
          <el-select
            clearable
            v-model="form.regionId"
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
          >
            <el-option
              v-for="item in regionOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9-0">Comments</div>
        </el-col>
        <el-col :span="13"
          ><el-input v-model="form.comment" style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: ["saveType", "regionOptions"],
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        name: "",
        regionId: "",
        comment: "",
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        name: "",
        regionId: "",
        comment: "",
      };
    },
    confirm() {
      if (this.form.name === "") {
        commonUtil.showTipInfo("Please enter template name！", "", "warning");
        return;
      }
      if (this.form.regionId === "") {
        commonUtil.showTipInfo("Please choose region！", "", "warning");
        return;
      }
      this.$emit("confirmInfo", this.form);
      this.close();
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9-0 {
  padding: 9px 0;
}
.el-row {
  margin-bottom: 10px;
}
</style>