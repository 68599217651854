const info = {
    // 中文
    // tcmlists.tcmlist.
    ZH: {
        date: '日期',
        search: '搜索',
        commodity: '商品分类',
        tcmRequester: 'TCM申请人',
        partDescription: '零件描述',
        partNumber: '零件号',
        startDate: '开始日期',
        dueDate: '截至日期',
        quotationType: '请求类型',
        tcmOwner: 'TCM 所有者',
        workStatus: '任务状态',
        documentNO: '文件号',
        priority: '优先事项',
        addCompare: '加入比较',
        priceType: '价格类型',
        selectData: '选择对比数据',
        noData: '暂无数据',
        classification: '选择分类',
        department: '选择部门',
        selectclassification: '选择商品分类',
		chooseEmail: '选择邮箱',
		chooseHistoryEmaol: '选择历史邮箱'
    },
    // 英文
    EN: {
        date: 'Date',
        search: 'Search',
        commodity: 'Commodity',
        tcmRequester: 'TCM Requester',
        partDescription: 'Part Description',
        partNumber: 'Part Number',
        startDate: 'Start Date',
        dueDate: 'Due date',
        quotationType: 'Request Type',
        tcmOwner: 'TCM Owner',
        workStatus: 'Workflow Status',
        documentNO: 'Document NO',
        priority: 'Priority',
        addCompare: 'Add to compare',
        priceType: 'Price Type',
        selectData: 'Select comparison data',
        noData: 'No Data',
        classification: 'Select Classification',
        department: 'Select Department',
        selectclassification: 'Select Commodity Classification',
		chooseEmail: 'Select Email',
		chooseHistoryEmaol: 'Select A History Mailbox'
            // :label="$t('tcmlists.tcmlist.workStatus')" 
    }
}
export default info