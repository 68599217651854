<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">{{ $t("ccls.ccl.addRole") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form ref="formList" :model="form" :rules="rules" label-width="140px">
        <el-form-item :label="$t('ccls.ccl.roleName')" prop="name">
          <el-input v-model="form.name" style="width: 214px" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.roleNameEn')">
          <el-input v-model="form.nameEn" style="width: 214px" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.enable')">
          <el-switch
            v-model="form.status"
            active-value="1"
            inactive-value="0"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["handleSubmit"],
  data() {
    return {
      visible: false,
      form: {
        timer: "",
        id: "",
        name: "",
        nameEn: "",
        status: 1,
      },
      rules: {},
    };
  },
  created() {
    this.getUnits();
  },
  methods: {
    getUnits() {
      this.timer = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        this.rules = {
          name: [
            {
              required: true,
              message: "Please enter the name of the role",
              trigger: "blur",
            },
          ],
        };
      } else {
        this.rules = {
          name: [
            {
              required: true,
              message: "请输入角色名称",
              trigger: "blur",
            },
          ],
        };
      }
      clearInterval(this.timer);
    },
    handleSubmit() {
      // 给出添加弹框
      if (this.form.name == "") {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.pleaseRole"),
          "",
          "warning"
        );
        retrun;
      }
      this.$emit("handleSubmit", this.form);
      this.$refs.formList.resetFields();
      this.visible = false;
    },
    close() {
      this.visible = false;
      this.$refs.formList.resetFields();
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>