<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.MaterialModule") }}</div>
      <div class="main-handle flex">
        <!-- 状态 -->
        <div class="main-handle-item">
          {{ $t("templateLists.templateList.enable") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.Enable"
            :placeholder="$t('templateLists.templateList.enable')"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="main-handle-item">
          {{ $t("templateLists.templateList.region") }}
          <el-select
            size="small"
            clearable
            v-model="searchForm.RegionId"
            :placeholder="$t('templateLists.templateList.region')"
          >
            <el-option
              v-for="item in regionOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <!-- 日期选择 -->
        <div class="main-handle-item" style="margin-top: 15px">
          <div class="select-box">
            <div class="select-btn">
              <el-icon class="Calendar"><Calendar /></el-icon>
              <span :style="{ color: searchForm.date ? '#606266' : '' }">{{
                searchForm.date
                  ? searchForm.date[0] + " - " + searchForm.date[1]
                  : $t("tcmdatalists.tcmdatalist.pleaseSlect")
              }}</span>
              <!-- <el-icon><ArrowDownBold /></el-icon> -->
              <el-icon v-show="!searchForm.date">
                <ArrowDownBold />
              </el-icon>
              <el-icon :size="18" v-show="searchForm.date">
                <Close />
              </el-icon>
            </div>
            <el-date-picker
              class="date-picker"
              style="
                width: 200px;
                height: 30px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
              "
              v-model="searchForm.date"
              type="daterange"
              range-separator="To"
              :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
              :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
              size="mini"
              value-format="YYYY-MM-DD"
              @change="changeDate"
            />
          </div>
        </div>

        <div class="main-handle-item" style="margin-top: 15px">
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search" style="margin-top: 15px">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>

    <div class="handle-btn flex">
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="addTemplate"
        v-if="this.btnAdd > 0"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('start')"
      >
        {{ $t("templateLists.templateList.batchEnable") }}
      </div>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('stop')"
      >
        {{ $t("templateLists.templateList.batchDisabling") }}
      </div>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="bathOperate('delete')"
        v-if="this.btnDel > 0"
      >
        {{ $t("unifiedPrompts.unifiedPrompt.batchDelete") }}
      </div>
      <el-upload
        ref="upload"
        class="upload-demo custom-el-upload-list-none"
        action="/"
        :on-change="importData"
        :auto-upload="false"
      >
        <template #trigger>
          <div class="handle-btn-item" style="margin-right: 10px">
            {{ $t("ccls.ccl.import") }}
          </div>
        </template>
      </el-upload>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="exportData"
      >
        {{ $t("ccls.ccl.export") }}
      </div>
      <div class="handle-btn-item" @click="downTemplate">
        {{ $t("unifiedPrompts.unifiedPrompt.downTemplate") }}
      </div>
      <!-- <div class="operate-btn" style="margin-right: 10px;" @click="bathOperate('delete')">批量删除</div> -->
      <div
        class="handle-btn-item"
        style="margin-left: 10px"
        @click="ChangeCommodityBtn"
      >
        {{ $t("templateLists.templateList.ChangeCommodity") }}
      </div>
      <div style="padding-left: 20px; text-align: right">
        <span style="line-height: 32px; margin-right: 10px; font-weight: 600">{{
          $t("templateLists.templateList.revisions")
        }}</span>
        <el-switch
          v-model="historyVal"
          class="ml-2"
          inline-prompt
          style="--el-switch-on-color: #b2bde7"
          active-text="Y"
          inactive-text="N"
        />
      </div>
    </div>
    <el-row :gutter="10">
      <el-col
        :span="4"
        style="
          width: 250px;
          min-width: 250px;
          max-width: 250px;
          background-color: #fff;
        "
      >
        <div class="tree-cla">
          <div class="tree-cla-search">
            <input
              v-model="filterText"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </div>
          <div :style="{ height: TableHeight + 'px' }" class="tree-box">
            <el-tree
              ref="treeRef"
              class="filter-tree custom-el-tree"
              :data="treedata"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span class="my-text" :title="node.label">{{
                    node.label
                  }}</span>
                  <span class="my-badge">{{ data.Quantity }}</span>
                </span>
              </template>
            </el-tree>
          </div>
        </div>
      </el-col>
      <el-col :span="20" style="max-width: calc(100% - 250px)">
        <div class="regional_table">
          <el-table
            stripe
            :empty-text="$t('tcmlists.tcmlist.noData')"
            :data="tableData"
            border
            @row-click="RowClick"
            style="width: 100%"
            :height="TableHeight"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="number"
              :label="$t('templateLists.templateList.cellNo')"
              width="180"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="name"
              :label="$t('templateLists.templateList.cellName')"
              width="130"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="vendor"
              :label="$t('changePriceLists.changePriceList.vendor')"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="description"
              :label="$t('templateLists.templateList.materialDescription')"
              show-overflow-tooltip
              align="center"
              width="130"
            />
            <el-table-column
              prop="regionName"
              :label="$t('templateLists.templateList.region')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="currencyName"
              :label="$t('changePriceLists.changePriceList.currency')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="commodityName"
              :label="$t('tcmdatalists.tcmdatalist.commodity')"
              width="100"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="version"
              :label="$t('templateLists.templateList.version')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="comment"
              :label="$t('templateLists.templateList.comments')"
              width="110"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="creator"
              :label="$t('templateLists.templateList.creator')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="date"
              :label="$t('templateLists.templateList.date')"
              width="110"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="enable"
              :label="$t('templateLists.templateList.enable')"
              width="80"
              show-overflow-tooltip
              align="center"
            >
              <template #default="scope">
                <el-icon
                  :size="16"
                  color="#40D4A8"
                  v-show="scope.row.isEnable == '1'"
                >
                  <SuccessFilled />
                </el-icon>
                <el-icon
                  :size="16"
                  color="#F56C6C"
                  v-show="scope.row.isEnable == '2'"
                >
                  <CircleCloseFilled />
                </el-icon>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('templateLists.templateList.operate')"
              width="80"
              fixed="right"
              class-name="lastTd"
              align="center"
            >
              <template #default="scope">
                <el-popover placement="bottom" trigger="hover">
                  <template #reference>
                    <div style="text-align: center">
                      <el-icon><MoreFilled /></el-icon>
                    </div>
                  </template>
                  <div class="action-box">
                    <div class="action-btn" @click="previewTemplate(scope.row)">
                      <el-icon><View /></el-icon>
                      <span>{{
                        $t("unifiedPrompts.unifiedPrompt.preview")
                      }}</span>
                    </div>
                    <div
                      class="action-btn"
                      v-if="scope.row.isrelevance && scope.row.isrelevance == 1"
                      @click="editTemplate(scope.row)"
                    >
                      <el-icon><Edit /></el-icon>
                      <span>
                        {{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span
                      >
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <div>
            {{ $t("homePage.home.Showing") }} {{ pageSize }}
            {{ $t("homePage.home.From") }} {{ total }}
            {{ $t("homePage.home.Data") }}
            <el-popover placement="top" :width="70" trigger="click">
              <template #reference>
                <el-icon
                  style="
                    margin-left: 5px;
                    font-size: 17px;
                    vertical-align: middle;
                  "
                  ><Operation
                /></el-icon>
              </template>
              <el-input
                v-model="searchForm.limit"
                @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
                :min="1"
                style="width: 130px"
                @change="handleSizeChange"
              >
                <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
              </el-input>
            </el-popover>
          </div>
          <el-pagination
            layout="prev, pager, next"
            background
            :total="total"
            :page-size="pageSize"
            :current-page="searchForm.page"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-col>
    </el-row>
    <ChangeCommodity
      :treeData="treedata"
      @ModifyCommodity="ModifyCommodityFun"
      ref="ChangeCommodity"
    ></ChangeCommodity>
  </div>

  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <el-row>
        <el-col :span="24">
          <table>
            <tr>
              <td style="width: 25%">
                {{ $t("tcmdatalists.tcmdatalist.date") }}
                <el-date-picker
                  v-model="searchForm.date"
                  type="daterange"
                  range-separator="To"
                  :start-placeholder="
                    $t('unifiedPrompts.unifiedPrompt.startDate')
                  "
                  :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
                  size="default"
                  value-format="YYYY-MM-DD"
                  @change="changeDate"
                  style="width: calc(100% - 60px)"
                />
              </td>
              <td style="width: 20%">
                {{ $t("templateLists.templateList.enable") }}
                <el-select
                  clearable
                  v-model="searchForm.Enable"
                  style="width: calc(100% - 50px)"
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </td>
              <td style="width: 20%">
                {{ $t("templateLists.templateList.region") }}
                <el-select
                  clearable
                  v-model="searchForm.RegionId"
                  style="width: calc(100% - 50px)"
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                >
                  <el-option
                    v-for="item in regionOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </td>
              <td style="width: 13%">
                <el-input
                  v-model="searchForm.Keyword"
                  :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                />
              </td>
              <td style="width: 12%">
                <div
                  class="operate-btn"
                  style="margin-right: 10px"
                  @click="search"
                >
                  {{ $t("tcmdatalists.tcmdatalist.search") }}
                </div>
                <!== <div class="operate-btn">Advanced search</div> ==>
              </td>
            </tr>
          </table>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="20">
          <div
            class="operate-btn"
            style="margin-right: 10px"
            @click="addTemplate"
            v-if="this.btnAdd > 0"
          >
            {{ $t("unifiedPrompts.unifiedPrompt.new") }}
          </div>
          <div
            class="operate-btn"
            style="margin-right: 10px"
            @click="bathOperate('start')"
          >
            {{ $t("templateLists.templateList.batchEnable") }}
          </div>
          <div
            class="operate-btn"
            style="margin-right: 10px"
            @click="bathOperate('stop')"
          >
            {{ $t("templateLists.templateList.batchDisabling") }}
          </div>
          <div
            class="operate-btn"
            style="margin-right: 10px"
            @click="bathOperate('delete')"
            v-if="this.btnDel > 0"
          >
            {{ $t("unifiedPrompts.unifiedPrompt.batchDelete") }}
          </div>
          <div style="display: inline-block">
            <el-upload
              ref="upload"
              class="upload-demo custom-el-upload-list-none"
              action="/"
              :on-change="importData"
              :auto-upload="false"
            >
              <template #trigger>
                <div class="operate-btn" style="margin-right: 10px">
                  {{ $t("ccls.ccl.import") }}
                </div>
              </template>
            </el-upload>
          </div>
          <div
            class="operate-btn"
            style="margin-right: 10px"
            @click="exportData"
          >
            {{ $t("ccls.ccl.export") }}
          </div>
          <div class="operate-btn mrg-10" @click="downTemplate">
            {{ $t("unifiedPrompts.unifiedPrompt.downTemplate") }}
          </div>
          <div
            class="operate-btn"
            style="margin-left: 10px"
            @click="ChangeCommodityBtn"
          >
            {{ $t("templateLists.templateList.ChangeCommodity") }}
          </div>
          <!== <div class="operate-btn" style="margin-right: 10px;" @click="bathOperate('delete')">批量删除</div> ==>
        </el-col>
        <el-col :span="4">
          <div style="text-align: right">
            <span style="line-height: 32px; margin-right: 10px">{{
              $t("templateLists.templateList.revisions")
            }}</span>
            <el-switch
              v-model="historyVal"
              class="ml-2"
              inline-prompt
              style="--el-switch-on-color: #335883"
              active-text="Y"
              inactive-text="N"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-row :gutter="10">
        <el-col
          :span="4"
          style="width: 250px; min-width: 250px; max-width: 250px"
        >
          <div class="tree-cla">
            <el-input
              v-model="filterText"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
              style="margin-bottom: 10px"
            />
            <el-tree
              ref="treeRef"
              class="filter-tree custom-el-tree"
              :data="treedata"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span class="my-text" :title="node.label">{{
                    node.label
                  }}</span>
                  <span class="my-badge">{{ data.Quantity }}</span>
                </span>
              </template>
            </el-tree>
          </div>
        </el-col>
        <el-col :span="20" style="max-width: calc(100% - 250px)">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :empty-text="$t('tcmlists.tcmlist.noData')"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="status"
              :label="$t('templateLists.templateList.operate')"
              width="180"
              align="center"
            >
              <template #default="scope">
                <div class="flex-around">
                  <div
                    class="operate-btnx"
                    style="margin-right: 10px"
                    @click="previewTemplate(scope.row)"
                  >
                    {{ $t("unifiedPrompts.unifiedPrompt.preview") }}
                  </div>
                  <div
                    class="operate-btnx"
                    style="margin-right: 10px"
                    @click="editTemplate(scope.row)"
                    v-if="scope.row.isrelevance && scope.row.isrelevance == 1"
                  >
                    {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
                  </div>
                  <!== <div class="operate-btnx" @click="deleteTemplate(scope.row.id)">{{$t('unifiedPrompts.unifiedPrompt.delete')}}</div> ==>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="number"
              :label="$t('templateLists.templateList.cellNo')"
              width="180"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="name"
              :label="$t('templateLists.templateList.cellName')"
              width="130"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="vendor"
              :label="$t('changePriceLists.changePriceList.vendor')"
              width="100"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="description"
              :label="$t('templateLists.templateList.materialDescription')"
              show-overflow-tooltip
              align="center"
              width="130"
            />
            <el-table-column
              prop="regionName"
              :label="$t('templateLists.templateList.region')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="currencyName"
              :label="$t('changePriceLists.changePriceList.currency')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="commodityName"
              :label="$t('tcmdatalists.tcmdatalist.commodity')"
              width="100"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="version"
              :label="$t('templateLists.templateList.version')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="comment"
              :label="$t('templateLists.templateList.comments')"
              width="110"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="creator"
              :label="$t('templateLists.templateList.creator')"
              width="90"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="date"
              :label="$t('templateLists.templateList.date')"
              width="110"
              show-overflow-tooltip
              align="center"
            />
            <el-table-column
              prop="enable"
              :label="$t('templateLists.templateList.enable')"
              width="80"
              show-overflow-tooltip
              align="center"
            >
              <template #default="scope">
                <el-icon
                  :size="16"
                  color="#40D4A8"
                  v-show="scope.row.isEnable == '1'"
                >
                  <SuccessFilled />
                </el-icon>
                <el-icon
                  :size="16"
                  color="#F56C6C"
                  v-show="scope.row.isEnable == '2'"
                >
                  <CircleCloseFilled />
                </el-icon>
              </template>
            </el-table-column>
          </el-table>
          <div class="page-cla">
            <div>
              <el-input
                v-model="searchForm.limit"
                @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
                min="1"
                style="width: 130px"
                @change="handleSizeChange"
              >
                <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
              </el-input>
            </div>
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              :current-page="searchForm.page"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <ChangeCommodity
      :treeData="treedata"
      @ModifyCommodity="ModifyCommodityFun"
      ref="ChangeCommodity"
    ></ChangeCommodity>
  </div> -->
</template>

<script>
import {
  GetOrgs,
  GetTemplateMaterials,
  deleteTemplateMaterials,
  startTemplateMaterials,
  stopTemplateMaterials,
  ImportTemplateMaterialExcel,
  getRegions,
  ExportDataTableExcel,
  GetDataIsDuplicate,
  ExportTemplateMaterial,
  DownloadTemplateExcel,
  ModifyMaterialsCommodityByIds,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import "../../assets/css/custom-el-tree.css";
import { ElMessageBox } from "element-plus";
import ChangeCommodity from "../../components/comments/ChangeCommodity.vue";
export default {
  name: "RawList",
  components: {
    ChangeCommodity,
  },
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      TableHeight: 0,
      timers: "",
      searchForm: {
        date: "",
        Enable: 0,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: "",
        currencyId: "",
        VersionType: 1,
        RegionId: "",
        page: 1,
        limit: 10,
      },
      // 总页数
      total: 0,
      pageSize: 10,
      // status选项
      statusOptions: [],
      filterText: "",
      // 商品分类数据
      treedata: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // 表格数据
      tableData: [],
      // 已选择的商品分类名称
      CommodityName: "",
      // 已勾选数据
      chooseData: [],
      // 是否为历史版本
      historyVal: false,
      regionOptions: [],
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      preview: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
    historyVal(val) {
      console.log(val);
      if (val) {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 2;
        this.search();
      } else {
        this.searchForm.page = 1;
        this.searchForm.VersionType = 1;
        this.search();
      }
    },
  },
  mounted() {
    this.autoHeight();
    this.getlist();
    this.getTreeData();
    this.getRegion();
    this.timer = setTimeout(this.gettime, 1000);
    this.getUnits();
  },
  methods: {
    RowClick(row) {
      this.previewTemplate(row);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        console.log(1);
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    ModifyCommodityFun(CheckId) {
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      let params = {
        ids: ids,
        commodityId: CheckId,
      };
      ModifyMaterialsCommodityByIds(params, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    ChangeCommodityBtn() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.ChangeCommodity.visible = true;
    },
    getUnits() {
      this.timers = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      console.log(this.powers);
      if (this.powers == "en" || this.powers == null) {
        this.statusOptions = [
          {
            value: 0,
            label: "All",
          },
          {
            value: 1,
            label: "Enable",
          },
          {
            value: 2,
            label: "Disable",
          },
        ];
      } else {
        this.statusOptions = [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "激活",
          },
          {
            value: 2,
            label: "禁用",
          },
        ];
      }
      clearTimeout(this.timers);
    },

    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加" || e.name == "Add") {
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.name == "Delete") {
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.name == "Edit") {
          this.btnEdit = 1;
        }
        if (e.name == "预览" || e.name == "Preview") {
          this.preview = 1;
        }
      });
      clearTimeout(this.timer);
    },
    getlist() {
      this.searchForm = {
        date: "",
        Enable: 0,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        CommodityId: "",
        currencyId: "",
        VersionType: 1,
        RegionId: "",
        page: 1,
        limit: 10,
      };
      this.search();
    },
    // 查询
    search() {
      GetTemplateMaterials(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      console.log(this.searchForm.limit);
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addTemplate() {
      this.$router.push({
        path: "/addRaw",
        name: "AddRaw",
        query: {
          type: "add",
          CommodityId: this.searchForm.CommodityId,
          CommodityName: this.CommodityName,
        },
      });
    },
    // 编辑
    editTemplate(row) {
      console.log(row);
      this.$router.push({
        path: "/addRaw",
        name: "AddRaw",
        query: {
          type: "edit",
          CommodityName: row.commodityName,
          id: row.id,
        },
      });
    },
    // 获取组织树数据
    getTreeData() {
      let _this = this;
      GetOrgs(
        {
          type: 2,
          versionType: this.searchForm.VersionType,
          TypeCost: 0,
          isAll: 1,
        },
        (response) => {
          if (response && response.Code == 200) {
            let data = response.Result;
            data.forEach(function (val, key) {
              val.disabled = val.isEnable == 1 ? false : true;
            });
            this.treedata = _this.transferList(response.Result);
          }
          console.log(response);
        }
      );
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {
      console.log(data);
      this.searchForm.CommodityId = "";
      this.CommodityName = "";
      if (data.disabled) {
        commonUtil.showTipInfo("当前商品分类已禁用！", "", "warning");
        return;
      }
      this.searchForm.CommodityId = data.Id;
      this.CommodityName = data.Name;
      this.search();
    },
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.Id === item.ParentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      console.log(res);
      return res;
    },
    // 切换时间
    changeDate(val) {
      console.log(val);
      if (val != null) {
        this.searchForm.StartDate = val[0];
        this.searchForm.EndDate = val[1];
      } else {
        this.searchForm.StartDate = "";
        this.searchForm.EndDate = "";
      }
    },
    // 查看详情
    previewTemplate(row) {
      this.$router.push({
        path: "/addRaw",
        name: "AddRaw",
        query: {
          type: "detail",
          CommodityName: row.commodityName,
          id: row.id,
        },
      });
    },
    // 删除
    deleteTemplate(id) {
      let ids = [];
      ids.push(id);
      this.deleteTem(ids);
    },
    // 调用删除接口
    deleteTem(ids) {
      deleteTemplateMaterials(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      console.log(val);
      this.chooseData = val;
    },
    // 批量操作
    bathOperate(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      if (type == "delete") {
        this.deleteTem(ids);
      } else if (type == "start") {
        // 启用
        startTemplateMaterials(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.enableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      } else if (type == "stop") {
        // 禁用
        stopTemplateMaterials(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.disableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      } else if (type == "delete") {
        this.deleteTem(ids);
      }
    },
    // 导入
    // importData(files) {
    //     console.log(files);
    //     let format = new FormData();
    //     format.append("file", files.raw);
    //     format.append("Type", 1);
    //     ImportTemplateMaterialExcel(
    //         format,
    //         (response) => {
    //             if (response && response.code == 200) {
    //                 this.getlist();
    //                 this.$refs.upload.clearFiles();
    //                 commonUtil.showTipInfo(
    //                     this.$t(
    //                         "unifiedPrompts.unifiedPrompt.importSuccessfully"
    //                     ),
    //                     "",
    //                     "success"
    //                 );
    //             }
    //         },
    //         (errRes) => {
    //             console.log(errRes);
    //             if (errRes && errRes.code == 500) {
    //                 ExportDataTableExcel('"' + errRes.data + '"', (res) => {
    //                     console.log(res);
    //                 });
    //             } else {
    //                 commonUtil.showTipInfo(
    //                     this.$t("unifiedPrompts.unifiedPrompt.importFail"),
    //                     "",
    //                     "error"
    //                 );
    //             }
    //             this.getlist();
    //             this.$refs.upload.clearFiles();
    //         }
    //     );
    // },
    // 导入
    importExcel(format) {
      ImportTemplateMaterialExcel(
        format,
        (response) => {
          if (response && response.code == 200) {
            this.getlist();
            this.$refs.upload.clearFiles();
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importSuccessfully"),
              "",
              "success"
            );
          }
        },
        (errRes) => {
          console.log(errRes);
          if (errRes && errRes.code == 500) {
            ExportDataTableExcel('"' + errRes.data + '"', (res) => {
              console.log(res);
            });
          } else {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importFail"),
              "",
              "error"
            );
          }
          this.getlist();
          this.$refs.upload.clearFiles();
        }
      );
    },
    // 导入
    importData(files) {
      console.log(files);
      let format = new FormData();
      format.append("file", files.raw);
      format.append("Type", 1);
      let excelFormat = new FormData();
      excelFormat.append("file", files.raw);
      excelFormat.append("Type", 1);
      GetDataIsDuplicate(format, (response) => {
        console.log(response);
        if (response && response.code == 200) {
          // if (response.data) {
          if (response.data == "") {
            excelFormat.append("DataIsDuplicate", "");
            this.importExcel(excelFormat);
          } else {
            // 有重复数据
            ElMessageBox.confirm(response.data + "数据重复，是否继续导入？")
              .then(() => {
                excelFormat.append("DataIsDuplicate", "");
                this.isContinue = true;
                this.importExcel(excelFormat);
                done();
              })
              .catch(() => {
                if (!this.isContinue) {
                  excelFormat.append("DataIsDuplicate", response.data);
                  this.importExcel(excelFormat);
                }
                this.isContinue = false;
                // catch error
              });
          }
          // } else {
          // 	commonUtil.showTipInfo(response.message,"", "error");
          // 	this.getList()
          // 	this.$refs.upload.clearFiles()
          // }
        }
      });
    },
    // 导出(改版)
    exportData() {
      ExportTemplateMaterial(this.searchForm, (response) => {});
    },
    // 下载模板
    downTemplate() {
      DownloadTemplateExcel(4, (response) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.tree-box {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>