<template>
	<div>
		<div class="add-top">
			<el-row>
				<el-col :span="10">
					<el-row style="padding-top: 15px;">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Length</div>
								<el-input v-model="form.length" :disabled="type == 'detail'" @input="(v) => (form.length = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">mm</div>
							</div>
						</el-col>
						
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Width</div>
								<el-input v-model="form.width" :disabled="type == 'detail'" @input="(v) => (form.width = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">mm</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Height</div>
								<el-input v-model="form.height" :disabled="type == 'detail'" @input="(v) => (form.height = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">mm</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Wall Thickness</div>
								<el-input v-model="form.wallThickness" :disabled="type == 'detail'" @input="(v) => (form.wallThickness = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">mm</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Projected Area</div>
								<el-input v-model="form.projectedArea" :disabled="type == 'detail'" @input="(v) => (form.projectedArea = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">mm2</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Material</div>
								<el-input v-model="form.material" :disabled="type == 'detail'" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;"></div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">No. of Cavities</div>
								<el-input v-model="form.cavities" :disabled="type == 'detail'" @input="(v) => (form.cavities = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">Numbers</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Clamping Pressure</div>
								<el-input v-model="form.pressure" :disabled="type == 'detail'" @input="(v) => (form.pressure = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">Tons/Sq. In.</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Clamping Force</div>
								<el-input v-model="form.clampingForce" @input="(v) => (form.clampingForce = v.replace(/[^\d.]/g, ''))" disabled placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">Tons</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Safety Factor</div>
								<el-input v-model="form.factor" :disabled="type == 'detail'" @input="(v) => (form.factor = v.replace(/[^\d.]/g, ''))" placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">%</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Clamping Force</div>
								<el-input v-model="form.clampingForce2" disabled placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">Tons</div>
							</div>
						</el-col>
					</el-row>
					<el-row  class="mgt-15">
						<el-col :span="24">
							<div class="dis-flex">
								<div class="line-32 add-top-text">Selected Machine</div>
								<el-input v-model="form.machine" disabled placeholder="" class="mgr-20" style="width: 214px;"/>
								<div class="line-32" style="width: 214px;">T</div>
							</div>
						</el-col>
					</el-row>
				</el-col>
				<el-col :span="4" v-show="type != 'detail'">
					<div class="operate-btn" @click="compute">Compute</div>
				</el-col>
				<el-col :span="8">
					<div class="size-16">Tonnage</div>
					<div v-for="item in tonnageList" class="text-sub">{{item}} T</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import {GetTonnageComputeResultByParameter} from '../../assets/js/requestApi.js'
	export default {
		props: ['type'],
		data() {
			return {
				form: {
					length: '',
					width: '',
					height: '',
					wallThickness: '',
					projectedArea: '',
					material: '',
					cavities: '',
					pressure: '',
					factor: '',
					clampingForce: '',
					clampingForce2: '',
					machine: ''
				},
				tonnageList: ['50', '60', '80', '85', '90', '100', '120', '150', '160', '180', '200', '220', '250', '260', '280', '300', '330', '350', '380', '400', '450', '500']
			}
		},
		methods: {
			// 计算
			compute() {
				let form = {
					length: this.form.length === '' ? 0 : parseFloat(this.form.length),
					width: this.form.width === '' ? 0 : parseFloat(this.form.width),
					height: this.form.height === '' ? 0 : parseFloat(this.form.height),
					wallThickness: this.form.wallThickness === '' ? 0 : parseFloat(this.form.wallThickness),
					projectedArea: this.form.projectedArea === '' ? 0 : parseFloat(this.form.projectedArea),
					material: this.form.material,
					cavities: this.form.cavities === '' ? 0 : parseFloat(this.form.cavities),
					pressure: this.form.pressure === '' ? 0 : parseFloat(this.form.pressure),
					factor: this.form.factor === '' ? 0 : parseFloat(this.form.factor),
				}
				GetTonnageComputeResultByParameter(form, response=>{
					// console.log(response)
					if (response && response.code == 200) {
						this.form.clampingForce = response.result.clampingForce
						this.form.clampingForce2 = response.result.clampingForce2
						this.form.machine = response.result.machine
					}
				})
			}
		}
	}
</script>

<style scoped>
	.add-top{
		padding: 20px;
		box-sizing: border-box;
	}
	.dis-flex{
		display: flex;
		justify-content: space-around;
	}
	.line-32{
		line-height: 32px;
	}
	.height-32{
		height: 32px;
	}
	.mgr-20{
		margin-right: 20px;
	}
	.mgr-10 {
		margin-right: 10px;
	}
	.mgt-15{
		margin-top: 12px;
	}
	.align-right{
		text-align: right;
	}
	.add-top-text{
		font-size: 14px;
		width: 150px;
	}
	.operate-btn{
		border-radius: 5px;
		background-color: var(--themeColor);
		color: #fff;
		text-align: center;
		padding: 7px 10px;
		display: inline-block;
		min-width: 80px;
		cursor: pointer;
	}
	.size-16{
		font-size: 16px;
	}
	.text-sub{
		font-size: 14px;
		margin-left: 20px;
		margin-top: 10px;
	}
</style>