<template>
  <el-dialog v-model="visible" width="450px" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{
            form.id === "" ? $t("ccls.ccl.addRate") : $t("ccls.ccl.editRate")
          }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("userlists.userlist.currentCurrency") }}
          </div>
        </el-col>
        <el-col :span="13">
          <el-select
            clearable
            v-model="form.currency"
            filterable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
          >
            <el-option
              v-for="it in regionOptions"
              :key="it.id"
              :label="it.name"
              :value="it.id"
            />
          </el-select>
        </el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 align-right" style="padding: 0 10px">
            <span class="color-red">*</span
            >{{ $t("userlists.userlist.reportCurrency") }}
          </div>
        </el-col>
        <el-col :span="13">
          <el-select
            clearable
            v-model="form.currencyCNYReportCurrency"
            filterable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
          >
            <el-option
              v-for="it in regionOptions"
              :key="it.id"
              :label="it.name"
              :value="it.id"
            />
          </el-select>
        </el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span
            >{{ $t("userlists.userlist.exchangeRate") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input
            v-model="form.exchangeRate"
            @input="(v) => (form.exchangeRate = v.replace(/[^\d.]/g, ''))"
            style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>{{ $t("ccls.ccl.version") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input v-model="form.version" style="width: 214px" disabled
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            {{ $t("ccls.ccl.enable") }}
          </div>
        </el-col>
        <el-col :span="13"><el-switch v-model="form.isEnable" /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { loadCurrency } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        name: "",
        currency: "",
        currencyCNYReportCurrency: "",
        version: 1,
        exchangeRate: "",
        isEnable: true,
      },
      regionOptions: [],
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        id: "",
        name: "",
        currency: "",
        currencyCNYReportCurrency: "",
        version: 1,
        exchangeRate: "",
        isEnable: true,
      };
    },
    // 获取货币列表选项
    getRegion() {
      loadCurrency(
        {
          IsOrderNumber: 1,
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    confirm() {
      let _this = this;
      if (this.form.currency == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.currencyRequired"),
          "",
          "warning"
        );
        return;
      }
      if (this.form.currencyCNYReportCurrency == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseCurrency"),
          "",
          "warning"
        );
        return;
      }
      // if (this.form.currency == this.form.currencyCNYReportCurrency) {
      // 	commonUtil.showTipInfo('货币不可相同！', '', 'warning');
      // 	return;
      // }
      // if (this.form.version == '') {
      // 	commonUtil.showTipInfo('请输入货币符号！', '', 'warning');
      // 	return;
      // }
      if (this.form.exchangeRate == "") {
        commonUtil.showTipInfo(this.$t("ccls.ccl.pleaseRate"), "", "warning");
        return;
      }
      let curName = "";
      let repName = "";
      this.regionOptions.forEach(function (val, index) {
        if (val.id == _this.form.currency) {
          curName = val.name;
        }
        if (val.id == _this.form.currencyCNYReportCurrency) {
          repName = val.name;
        }
      });
      this.form.name = curName + "-" + repName;
      this.$emit("confirmInfo", this.form);
      this.close();
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9 {
  padding: 6px 10px;
}
.el-row {
  margin-bottom: 10px;
}
.color-red {
  color: red;
}
.align-right {
  text-align: right;
}
</style>