<template>
	<el-dialog v-model="visible" width="600px" :show-close="false" @close="close">
	    <template #header="{ close, titleId, titleClass }">
	      <div class="my-header">
	        <div class="size-16">{{$t('ccls.ccl.IFFunction')}}</div>
	        <div style="cursor: pointer;" @click="close">
	          <el-icon :size="20"  style="color: var(--themeColor);"><CircleCloseFilled /></el-icon>
	        </div>
	      </div>
	    </template>
	    <div>
			<div class="operate-cla">
				<div class="mgb-10">{{$t('ccls.ccl.judgementCondition')}}</div>
				<div>
					<div>
						<el-input v-model="form.leftCondition" resize="none" :readonly="true" @click="showCal('leftCondition')" :autosize="{ minRows: 1, maxRows: 2 }" type="textarea" placeholder=""/>
					</div>
					<div class="mgtb-10">
						<el-select clearable  v-model="form.middleCondition" style="width: 100%;" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
						    <el-option
						      v-for="item in middleConditionOptions"
						      :key="item.name"
						      :label="item.name"
						      :value="item.name"
						    />
						</el-select>
					</div>
					<div>
						<el-input v-model="form.rightCondition" @click="showCal('rightCondition')" resize="none" :readonly="true" :autosize="{ minRows: 1, maxRows: 2 }" type="textarea" @input="(v) => (form.rightCondition = v.replace(/[^\d.]/g, ''))" placeholder=""/>
					</div>
				</div>
			</div>
			<div class="operate-cla">
				<div class="mgb-10">{{$t('ccls.ccl.trueResult')}}</div>
				<div>
					<el-input v-model="form.tureResult" @click="showCal('tureResult')" resize="none" :readonly="true" :autosize="{ minRows: 1, maxRows: 2 }" type="textarea" @input="(v) => (form.tureResult = v.replace(/[^\d.]/g, ''))" placeholder=""/>
				</div>
			</div>
			<div class="operate-cla">
				<div class="mgb-10">{{$t('ccls.ccl.errorResult')}}</div>
				<div>
					<el-input v-model="form.errorResult" @click="showCal('errorResult')" resize="none" :readonly="true" :autosize="{ minRows: 1, maxRows: 2 }" type="textarea" @input="(v) => (form.errorResult = v.replace(/[^\d.]/g, ''))" placeholder=""/>
				</div>
			</div>
		</div>
		<template #footer>
		      <span class="dialog-footer">
		        <el-button @click="close">{{$t('ccls.ccl.cancel')}}</el-button>
		        <el-button  style="background-color: var(--themeColor); color: var(--menuTextActive);margin-right: 16px" @click="submit">{{$t('ccls.ccl.confirm')}}</el-button>
		      </span>
		</template>
		<CalculatorDia ref="calDia" @submitFormula="submitFormula"></CalculatorDia>
	  </el-dialog>
</template>

<script>
	import commonUtil from '../../assets/js/commonUtil.js'
	import CalculatorDia from "./calculatorNotIfDia.vue";
	export default{
		emits: ['setIfFunc'],
		components: {
			CalculatorDia
		},
		data() {
			return {
				visible: false,
				// 操作类型
				type: '',
				form: {
					leftCondition: '',
					leftFormula: '',
					leftFormulaArr: [],
					leftFormulaNum: [],
					middleCondition: '',
					rightCondition: '',
					rightFormula: '',
					rightFormulaArr: [],
					rightFormulaNum: [],
					tureResult: '',
					tureFormula: '',
					tureFormulaArr: [],
					tureFormulaNum: [],
					errorResult: '',
					errorFormula: '',
					errorFormulaArr: [],
					errorFormulaNum: [],
				},
				// 中间判断条件选项
				middleConditionOptions: [{
					name: '='
				}, {
					name: '>'
				}, {
					name: '<'
				}, {
					name: '>='
				}, {
					name: '<='
				}],
				// 符号
				symbol: [
				    "+",
				    "-",
				    "*",
				    "/",
				    "sum",
				    "if",
				    "(",
				    ")",
				    "Round",
				    "Ceiling",
				],
				// 输入项选项
				valueList: [],
				// 中间键选项
				intermediateBondList: []
			}
		},
		methods: {
			close() {
				this.form = {
					leftCondition: '',
					leftFormula: '',
					leftFormulaArr: [],
					leftFormulaNum: [],
					middleCondition: '',
					rightCondition: '',
					rightFormula: '',
					rightFormulaArr: [],
					rightFormulaNum: [],
					tureResult: '',
					tureFormula: '',
					tureFormulaArr: [],
					tureFormulaNum: [],
					errorResult: '',
					errorFormula: '',
					errorFormulaArr: [],
					errorFormulaNum: [],
				}
				this.visible = false
			},
			showCal(type) {
				console.log(11111)
				console.log(this.form)
				this.type = type
				if (type == 'leftCondition') {
					this.$refs.calDia.formula = this.form.leftCondition;
					this.$refs.calDia.formulaArr = JSON.parse(
					    JSON.stringify(this.form.leftFormulaArr)
					);
					this.$refs.calDia.formulaPronoun = JSON.parse(
					    JSON.stringify(this.form.leftFormulaNum)
					);
				}
				if (type == 'rightCondition') {
					this.$refs.calDia.formula = this.form.rightCondition;
					this.$refs.calDia.formulaArr = JSON.parse(
					    JSON.stringify(this.form.rightFormulaArr)
					);
					this.$refs.calDia.formulaPronoun = JSON.parse(
					    JSON.stringify(this.form.rightFormulaNum)
					);
				}
				if (type == 'tureResult') {
					this.$refs.calDia.formula = this.form.tureResult;
					this.$refs.calDia.formulaArr = JSON.parse(
					    JSON.stringify(this.form.tureFormulaArr)
					);
					this.$refs.calDia.formulaPronoun = JSON.parse(
					    JSON.stringify(this.form.tureFormulaNum)
					);
				}
				if (type == 'errorResult') {
					this.$refs.calDia.formula = this.form.errorResult;
					this.$refs.calDia.formulaArr = JSON.parse(
					    JSON.stringify(this.form.errorFormulaArr)
					);
					this.$refs.calDia.formulaPronoun = JSON.parse(
					    JSON.stringify(this.form.errorFormulaNum)
					);
				}
				this.$refs.calDia.isIf = true
				this.$refs.calDia.valueList = this.valueList
				this.$refs.calDia.intermediateBondList = this.intermediateBondList
				this.$refs.calDia.visible = true
			},
			submit() {
				if (this.form.leftCondition === '' || this.form.middleCondition === '' || this.form.rightCondition === '') {
					commonUtil.showTipInfo(this.$t('ccls.ccl.improveJudgmentCondition'), '', 'warning');
					return;
				}
				if (this.form.tureResult === '') {
					commonUtil.showTipInfo(this.$t('ccls.ccl.trueResultInfo'), '', 'warning');
					return;
				}
				if (this.form.errorResult === '') {
					commonUtil.showTipInfo(this.$t('ccls.ccl.errorResultInfo'), '', 'warning');
					return;
				}
				let form = {
					leftCondition: this.form.leftCondition.split(''),
					leftFormula: this.form.leftFormula.split(''),
					middleCondition: this.form.middleCondition,
					rightCondition: this.form.rightCondition.split(''),
					rightFormula: this.form.rightFormula.split(''),
					tureResult: this.form.tureResult.split(''),
					tureFormula: this.form.tureFormula.split(''),
					errorResult: this.form.errorResult.split(''),
					errorFormula: this.form.errorFormula.split(''),
				}
				let func = ['if', '(']
				form.leftCondition.forEach(function(val, index) {
					func.push(val)
				})
				func.push(form.middleCondition)
				form.rightCondition.forEach(function(val, index) {
					func.push(val)
				})
				func.push(',')
				form.tureResult.forEach(function(val, index) {
					func.push(val)
				})
				func.push(',')
				form.errorResult.forEach(function(val, index) {
					func.push(val)
				})
				func.push(')')
				
				let funcFormula = ['if', '(']
				form.leftFormula.forEach(function(val, index) {
					funcFormula.push(val)
				})
				funcFormula.push(form.middleCondition)
				form.rightFormula.forEach(function(val, index) {
					funcFormula.push(val)
				})
				funcFormula.push(',')
				form.tureFormula.forEach(function(val, index) {
					funcFormula.push(val)
				})
				funcFormula.push(',')
				form.errorFormula.forEach(function(val, index) {
					funcFormula.push(val)
				})
				funcFormula.push(')')
				this.$emit('setIfFunc', func, funcFormula)
				console.log(func)
				console.log(funcFormula)
				this.close()
			},
			// 计算器弹窗返回结果
			submitFormula(val, formulaPronoun) {
			    console.log(val);
				console.log(formulaPronoun)
				if (this.type == 'leftCondition') {
					this.form.leftFormulaArr = val
					this.form.leftFormulaNum = formulaPronoun
					this.form.leftCondition = this.converseDataSpecial(formulaPronoun)
					this.form.leftFormula = this.converseData(val)
				}
				if (this.type == 'rightCondition') {
					this.form.rightFormulaArr = val
					this.form.rightFormulaNum = formulaPronoun
					this.form.rightCondition = this.converseDataSpecial(formulaPronoun)
					this.form.rightFormula = this.converseData(val)
				}
				if (this.type == 'tureResult') {
					this.form.tureFormulaArr = val
					this.form.tureFormulaNum = formulaPronoun
					this.form.tureResult = this.converseDataSpecial(formulaPronoun)
					this.form.tureFormula = this.converseData(val)
				}
				if (this.type == 'errorResult') {
					this.form.errorFormulaArr = val
					this.form.errorFormulaNum = formulaPronoun
					this.form.errorResult = this.converseDataSpecial(formulaPronoun)
					this.form.errorFormula = this.converseData(val)
				}
			},
			// 计算器结果数据转化
			converseData(val) {
			    let _this = this;
			    let data = [];
			    // 初始数字位
			    let startnum = 0;
			    let endnum = 0;
			    val.forEach(function (value, key) {
			        if (_this.symbol.indexOf(value) > -1) {
			            endnum = key;
			            let num = val.slice(startnum, endnum);
			            data.push(num.join(""));
			            // if (value == "+") {
			            //     data.push("%2B");
			            // } else {
			                data.push(value);
			            // }
			            startnum = key + 1;
			        } else if (Array.isArray(value)) {
			            endnum = key;
			            let num = val.slice(startnum, endnum);
			            data.push(num.join(""));
			            value.forEach(function (valx, valueIndex) {
			                data.push(valx);
			            });
			            startnum = key + 1;
			        } else {
			            if (key == val.length - 1) {
			                endnum = -1;
			                let num =
			                    val.slice(startnum - val.length, endnum).join("") +
			                    val.slice(-1);
			                data.push(num);
			            }
			        }
			    });
			    // data = "~" + data.join("$").replace(/\$/g, "~") + "~";
				data = data.join("$").replace(/\$/g, "");
			    return data;
			},
			// 计算器结果数据转化(round, if, and, or)
			converseDataSpecial(val) {
			    let _this = this;
			    let data = [];
			    // 初始数字位
			    let startnum = 0;
			    let endnum = 0;
			    val.forEach(function (value, key) {
			        if (_this.symbol.indexOf(value) > -1) {
			            endnum = key;
			            let num = val.slice(startnum, endnum);
			            data.push(num.join(""));
			            data.push(value);
			            startnum = key + 1;
			        } else if (Array.isArray(value)) {
			            endnum = key;
			            let num = val.slice(startnum, endnum);
			            data.push(num.join(""));
			            if (
			                value.indexOf("Round") > -1 ||
			                value.indexOf("RoundUp") > -1
			            ) {
			                let roundArr = JSON.parse(JSON.stringify(value));
			                roundArr.pop();
			                roundArr.forEach(function (valx, valueIndex) {
			                    data.push(valx);
			                });
			            } else {
			                value.forEach(function (valx, valueIndex) {
			                    data.push(valx);
			                });
			            }
			
			            startnum = key + 1;
			        } else {
			            if (key == val.length - 1) {
			                endnum = -1;
			                let num =
			                    val.slice(startnum - val.length, endnum).join("") +
			                    val.slice(-1);
			                data.push(num);
			            }
			        }
			    });
			    data = data.join("$").replace(/\$/g, "");
			    data = data.replace(/\|/g, "");
				data = data.replace(/roundRound/g, "");
				data = data.replace(/round/g, "");
			    return data;
			},
		}
	}
</script>

<style scoped lang="scss">

	:deep(.el-dialog__header){
		margin-right: 0;
	}
	.my-header {
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	}
	.width-170{
		width: 170px;
	}
	.width-31{
		width: 31px;
	}
	.size-16{
		font-size: 16px;
	}
	.mgb-10{
		margin-bottom: 10px;
	}
	.mgtb-10{
		margin: 10px 0;
	}
	.mg-10{
		margin: 0 10px;
	}
	.dis-between{
		display: flex;
		justify-content: space-between;
	}
	.operate-cla{
		border-radius: 5px;
		padding: 10px;
		border: 1px solid rgb(219,215,215);
		box-sizing: border-box;
		margin-bottom: 10px;
	}
</style>