<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">Add Grade</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form ref="formList" :model="form" :rules="rules" label-width="140px">
        <el-form-item label="Material Type" prop="ferrous">
          <el-input v-model="form.ferrous" style="width: 214px" />
        </el-form-item>
        <el-form-item label="Material" prop="ferrousType">
          <!-- <el-input v-model="form.nonferrous" style="width: 214px" /> -->
          <el-radio-group v-model="form.ferrousType" class="ml-4">
            <el-radio label="0">Ferrous</el-radio>
            <el-radio label="1">Nonferrous</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Comment">
          <el-input v-model="form.comment" style="width: 214px" />
        </el-form-item>
        <!-- <el-form-item :label="$t('ccls.ccl.enable')">
					<el-switch
						v-model="form.isEnable"
						active-value="1"
						inactive-value="2"
					/>
				</el-form-item> -->
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["handleSubmit"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        ferrous: "",
        // nonferrous: '',
        comment: "",
        ferrousType: "0",
      },
      rules: {
        ferrous: [
          {
            required: true,
            message: "Please enter material type",
            trigger: "blur",
          },
        ],
        ferrousType: [
          { required: true, message: "Please enter material", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleSubmit() {
      // 给出添加弹框
      if (this.form.ferrous === "") {
        commonUtil.showTipInfo("Please enter material type！", "", "warning");
        return;
      }
      if (this.form.ferrousType === "") {
        commonUtil.showTipInfo("Please choose material！", "", "warning");
        return;
      }
      let form = JSON.parse(JSON.stringify(this.form));
      form.ferrousType = parseFloat(form.ferrousType);
      this.$emit("handleSubmit", this.form);
      this.close();
    },
    close() {
      this.visible = false;
      this.form = {
        id: "",
        ferrous: "",
        nonferrous: "",
        comment: "",
        isEnable: 1,
      };
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>