<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{
            form.id === ""
              ? $t("unifiedPrompts.unifiedPrompt.new")
              : $t("unifiedPrompts.unifiedPrompt.edit")
          }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("ccls.ccl.cclSize") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input v-model="form.cclSize" style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("ccls.ccl.cclLength") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input
            v-model="form.cclLength"
            @input="changeval('cclLength')"
            style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("ccls.ccl.cclWidth") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input
            v-model="form.cclWidth"
            @input="changeval('cclWidth')"
            style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            {{ $t("ccls.ccl.enable") }}
          </div>
        </el-col>
        <el-col :span="13"><el-switch v-model="form.isEnable" /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        cclSize: "",
        cclLength: "",
        cclWidth: "",
        workingPanelLength: 0,
        workingPanelWidth: 0,
        panelizationQTY: 0,
        panelQTY_CCL: 0,
        pcbqtY_CCL: 0,
        panelUtility: "",
        isEnable: true,
        isDeleted: 0,
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        id: "",
        cclSize: "",
        cclLength: "",
        cclWidth: "",
        workingPanelLength: 0,
        workingPanelWidth: 0,
        panelizationQTY: 0,
        panelQTY_CCL: 0,
        pcbqtY_CCL: 0,
        panelUtility: "",
        isEnable: true,
        isDeleted: 0,
      };
    },
    confirm() {
      if (this.form.cclSize == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseEnter") +
            " " +
            this.$t("ccls.ccl.cclSize") +
            "！",
          "",
          "warning"
        );
        return;
      }
      if (this.form.cclLength == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseEnter") +
            " " +
            this.$t("ccls.ccl.cclLength") +
            "！",
          "",
          "warning"
        );
        return;
      }
      if (this.form.cclWidth == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseEnter") +
            " " +
            this.$t("ccls.ccl.cclWidth") +
            "！",
          "",
          "warning"
        );
        return;
      }
      this.$emit("confirmInfo", this.form);
      this.close();
    },
    changeval(type) {
      // (v) => (form.depreciation = v.replace(/[^\d.]/g, ''))
      if (type == "cclLength") {
        this.form.cclLength = this.form.cclLength.replace(/[^\d.]/g, "");
      } else if (type == "cclWidth") {
        this.form.cclWidth = this.form.cclWidth.replace(/[^\d.]/g, "");
      }
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9 {
  padding: 9px;
}
.el-row {
  margin-bottom: 10px;
}
.color-red {
  color: red;
}
.align-right {
  text-align: right;
}
</style>