<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">Process Stamping - CalculatedDatelist</div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          <input
            type="text"
            v-model="searchForm.keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <div class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <div class="handle-btn-item" @click="addGrade">
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div class="handle-btn-item" @click="bathOperate('start')">
        {{ $t("userlists.userlist.batchEnable") }}
      </div>
      <div class="handle-btn-item" @click="bathOperate('stop')">
        {{ $t("userlists.userlist.batchDisabling") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        stripe
        border
        :empty-text="$t('tcmlists.tcmlist.noData')"
        ref="tableData"
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @row-click="RowClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="type" label="Calculated" align="center" />
        <el-table-column
          prop="fieId"
          label="Strength Details"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column prop="number" label="Number" align="center" />
        <el-table-column
          prop="isEnable"
          :label="$t('ccls.ccl.enable')"
          align="center"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.isEnable == '1'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.isEnable == '2'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          fixed="right"
          class-name="lastTd"
          align="center"
          prop="action"
          label="action"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon :size="15"><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div class="action-btn" @click="editRoles(scope.row)">
                  <el-icon><Edit /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        Showing {{ pageSize }} from {{ total }} data
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <AddTonnage ref="addTonnage" v-on:handleSubmit="addSubmit"></AddTonnage>
  </div>
  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <el-row>
        <el-col :span="16">
          <table>
            <tr>
              <td>
                <el-input
                  v-model="searchForm.keyword"
                  :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                  style="width: 160px; margin-right: 10px"
                />
              </td>
              <td>
                <div class="operate-btn mgr-10" @click="search">
                  {{ $t("userlists.userlist.search") }}
                </div>
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="8">
          <div class="align-right flex-end">
            <div class="operate-btn mgr-10" @click="addGrade">
              {{ $t("unifiedPrompts.unifiedPrompt.new") }}
            </div>
            <!== <div style="display: inline-block;">
							<el-upload ref="upload" class="upload-demo custom-el-upload-list-none"
								action="/"
								:limit="1"
								:on-change="importData"
								:auto-upload="false"
							>
								<template #trigger>
									<div class="operate-btn" style="margin-right: 10px;">Import</div>
								</template>
							</el-upload>
						</div>
						<div class="operate-btn mgr-10" @click="Export">export</div> ==>
            <div
              class="operate-btn"
              style="margin-right: 10px"
              @click="bathOperate('start')"
            >
              {{ $t("userlists.userlist.batchEnable") }}
            </div>
            <div
              class="operate-btn"
              style="margin-right: 10px"
              @click="bathOperate('stop')"
            >
              {{ $t("userlists.userlist.batchDisabling") }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        ref="tableData"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column prop="type" label="Calculated" align="center" />
        <el-table-column
          prop="fieId"
          label="Strength Details"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column prop="number" label="Number" align="center" />
        <el-table-column
          prop="isEnable"
          :label="$t('ccls.ccl.enable')"
          align="center"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.isEnable == '1'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.isEnable == '2'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column label="Operate" align="center" width="100">
          <template #default="scope">
            <div class="flex-around">
              <div class="operate-btnx" @click="editRoles(scope.row)">
                {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
              </div>
              <!== <div class="operate-btnx" @click="del(scope.row.id)">{{$t('unifiedPrompts.unifiedPrompt.delete')}}</div> ==>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-cla">
      <div>
        <el-input
          v-model="searchForm.limit"
          @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
          min="1"
          style="width: 130px"
          @change="handleSizeChange"
        >
          <template #suffix>{{ $t("ccls.ccl.barPage") }}</template>
        </el-input>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
    <AddTonnage ref="addTonnage" v-on:handleSubmit="addSubmit"></AddTonnage>
  </div> -->
</template>

<script>
import AddTonnage from "./addCalculatedDate.vue";
import commonUtil from "../../assets/js/commonUtil.js";
import {
  loadCalculatedDetails,
  AddOrUpdateCalculatedDetails,
  CalculatedDetailsEnableByIds,
  CalculatedDetailsDisableByIds,
} from "@/assets/js/requestApi.js";
export default {
  name: "CalculatedDatelist",
  components: {
    AddTonnage,
  },
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        keyword: "",
        page: 1, //当前页
        limit: 10, //每页个数
      },
      tableData: [],
      total: 0,
      pageSize: 10,
      treedata: [],
      // 已勾选的数据
      chooseData: [],
    };
  },
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  mounted() {
    this.autoHeight();

    this.initList();
    // this.getTreeData();
  },
  methods: {
    RowClick(row) {
      this.editRoles(row);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    // 渲染列表数据
    initList() {
      this.searchForm = {
        keyword: "",
        page: 1, //当前页
        limit: 10, //每页个数
      };
      this.search();
    },
    // 新增角色
    addGrade() {
      this.$refs.addTonnage.visible = true;
    },
    addSubmit(form) {
      AddOrUpdateCalculatedDetails(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 编辑角色
    editRoles(row) {
      this.$refs.addTonnage.form = {
        id: row.id,
        type: row.type,
        number: row.number,
        fieId: row.fieId,
        isEnable: row.isEnable.toString(),
      };
      this.$refs.addTonnage.visible = true;
    },
    // 查询
    search() {
      loadCalculatedDetails(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 批量操作
    bathOperate(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.id);
      });
      if (type == "start") {
        // 启用
        CalculatedDetailsEnableByIds(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.enableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      } else if (type == "stop") {
        // 禁用
        CalculatedDetailsDisableByIds(ids, (response) => {
          commonUtil.showTipInfo(
            this.$t("ccls.ccl.disableSuccess"),
            "",
            "success"
          );
          this.search();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>