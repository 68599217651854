<template>
  <el-dialog
    v-model="visible"
    width="400px"
    :show-close="false"
    :destroy-on-close="true"
  >
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("tcmlists.tcmlist.selectclassification") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-row>
        <el-col>
          <div class="tree-cla">
            <el-tree
              ref="treeRefs"
              class="filter-tree"
              :data="treedata"
              node-key="Id"
              :props="defaultProps"
              default-expand-all
              show-checkbox
              @check-change="handleCheckChange"
            />
          </div>
          <!-- :check-strictly="true" -->
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { GetOrgs, echoCommodity } from "@/assets/js/requestApi.js";
export default {
  props: ["userId"],
  data() {
    return {
      visible: false,
      defaultProps: {
        children: "children",
        label: "Name",
      },
      treedata: [],
      form: {
        Id: [],
        Name: [],
      },
    };
  },

  methods: {
    close() {
      this.visible = false;
    },
    // 树点击
    handleCheckChange(data, checked, indeterminate) {},
    // 提交按钮
    handleSubmit() {
      let res = this.$refs.treeRefs.getCheckedNodes();
      let _this = this;
      res.forEach(function (val) {
        _this.form.Name.push(val.Name);
        _this.form.Id.push(val.Id);
      });
      this.$refs.treeRefs.setCheckedKeys([], false);
      this.$emit("handleSubmit", this.form);
      this.form = {
        Name: [],
        Id: [],
      };
      this.visible = false;
    },

    // 树转化
    getTreeData() {
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            let treedata = response.Result;
            this.treedata = this.transferList(response.Result);
            // 数据回显
            echoCommodity('"' + this.userId + '"', (responses) => {
              if (responses && responses.code == 200) {
                let arr = [];
                for (let i = 0; i < treedata.length; i++) {
                  if (treedata[i].Name != "Commodity") {
                    for (let j = 0; j < responses.data.length; j++) {
                      if (treedata[i].Id == responses.data[j].id) {
                        arr.push(treedata[i]);
                      }
                    } //for循环
                  }
                } //for循环
                this.$refs.treeRefs.setCheckedNodes(arr);
              } else {
                this.$refs.treeRefs.setCheckedNodes([]);
              }
            }); //echoCommodity
          } //if结束
        }
      ); //GetOrgs
    }, //getTreeData
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 树点击事件
    handleNodeClick(data) {},
    // 转化树数据
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.Id === item.ParentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
  },
};
</script>

		<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>