<template>
  <el-dialog v-model="visible" width="600px" :show-close="false">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">{{ $t("ccls.ccl.editGroup") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20" style="color: var(--themeColor)"
            ><CircleCloseFilled
          /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form :model="form" label-width="120px" :rules="rules">
        <el-form-item
          :label="$t('ccls.ccl.userGroupName')"
          label-width="200px"
          prop="userGroupName"
        >
          <el-input v-model="form.userGroupName" style="width: 240px" />
        </el-form-item>
        <el-form-item
          :label="$t('ccls.ccl.tcmPersonnel')"
          label-width="200px"
          prop="tcmOperationPersonneAccount"
        >
          <el-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="form.tcmOperationPersonneAccount"
          >
            <el-option
              v-for="item in tcmOperationPersonneId"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('ccls.ccl.tcmApplicants')"
          label-width="200px"
          prop="tcmApplicationAccount"
        >
          <el-select
            clearable
            v-model="form.tcmApplicationAccount"
            multiple
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
          >
            <el-option
              v-for="item in tcmApplicationLists"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="$t('ccls.ccl.comments')"
          label-width="200px"
          prop="comments"
        >
          <el-input v-model="form.comments" style="width: 240px" />
        </el-form-item>
        <el-form-item
          :label="$t('ccls.ccl.enable')"
          label-width="200px"
          prop="isEnable"
        >
          <el-switch
            v-model="form.isEnable"
            :active-value="0"
            :inactive-value="1"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="editSubmit"
          >{{ $t("ccls.ccl.modify") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
import { userList } from "../../assets/js/requestApi.js";
export default {
  data() {
    return {
      visible: false,
      form: {
        id: "",
        userGroupName: "",
        comments: "",
        isEnable: 1,
      },
      tcmApplicationLists: [],
      tcmOperationPersonneId: [],
      rules: {
        userGroupName: [
          {
            required: true,
            message: "请输入用户组名称",
            trigger: "blur",
          },
        ],
        tcmApplicationAccount: [
          {
            required: true,
            message: "请选择申请人",
            trigger: "blur",
          },
        ],
        tcmOperationPersonneAccount: [
          {
            required: true,
            message: "请选择操作人",
            trigger: "blur",
          },
        ],
        isEnable: [{ required: true }],
      },
    };
  },
  methods: {
    // 获取焦点显示下拉数据
    getOptions() {
      userList(
        {
          page: 1,
          limit: 9999999,
        },
        (response) => {
          if (response && response.code == 200) {
            this.tcmApplicationLists = response.data;
            this.tcmOperationPersonneId = response.data;
          }
        }
      );
    },

    editSubmit() {
      this.visible = false;
      this.form.userGroup_List = [];
      // 选中的id数组 申请者
      for (let i = 0; i < this.form.tcmApplicationAccount.length; i++) {
        // 全部的数据
        for (let j = 0; j < this.tcmApplicationLists.length; j++) {
          if (
            this.form.tcmApplicationAccount[i] == this.tcmApplicationLists[j].id
          ) {
            this.form.userGroup_List.push({
              userGroupManageMentId: this.form.id,
              tcmApplication: this.tcmApplicationLists[j].id,
            });
          }
        }
      }
      // ----------------------------------------------------------------------------------------
      // 选中的id数组 操作者
      for (let i = 0; i < this.form.tcmOperationPersonneAccount.length; i++) {
        // 全部的数据
        for (let j = 0; j < this.tcmOperationPersonneId.length; j++) {
          if (
            this.form.tcmOperationPersonneAccount[i] ==
            this.tcmOperationPersonneId[j].id
          ) {
            this.form.tcmOperationPersonnel =
              this.tcmOperationPersonneId[j].name;
          }
        }
      }
      // this.form.tcmApplicationAccount.forEach((data) => {
      //   this.form.userGroup_List.push({
      //     tcmApplication: data,
      //   });
      // });
      this.$emit("handleSubmit", this.form);
    },

    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>