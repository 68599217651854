<template>
  <el-dialog v-model="visible" width="700px" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16"></div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <YearMoudle ref="yearCla" type="detail"></YearMoudle>
    </div>
  </el-dialog>
</template>

<script>
import "../../../assets/css/custom-el-form-rules.css";
import { EBom_YESPricingsGetDetail } from "../../../assets/js/requestApi.js";
import commonUtil from "@/assets/js/commonUtil.js";
import YearMoudle from "./yearMoudle.vue";
export default {
  components: {
    YearMoudle,
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {},
  methods: {
    close() {},
    // 获取详情
    getDetail(id) {
      let _this = this;
      EBom_YESPricingsGetDetail(
        {
          id: id,
        },
        (response) => {
          if (response && response.code == 200) {
            let detailList = [];
            response.result.detailLst.forEach(function (item, index) {
              detailList.push({
                id: item.id,
                year: item.year,
                price: _this.$refs.yearCla.format_with_regex(item.price),
                volume: _this.$refs.yearCla.format_with_regex(item.volume),
              });
            });
            this.$refs.yearCla.detailList = detailList;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
.rate-cla {
  display: flex;
  margin-bottom: 10px;
}
.line-32 {
  line-height: 32px;
}
.mg-5 {
  margin: 5px;
}
.mgr-10 {
  margin-right: 10px;
}
.arrows-img {
  width: 20px;
}
</style>
