<template>
	<div class="tcm-content">
		<div class="tcm-content-title">Supplier Basic Information</div>
		<div class="tcm-content-form">
			<el-form :inline="true" :model="form" label-width="150px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="Supplie Name:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Region:" style="width: 100%;">
							<el-select clearable   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);" @change="quoteTypeChange">
							    <el-option
							      v-for="item in QuoteTypeOptions"
							      :key="item.id"
							      :label="item.name"
							      :value="item.id"
							    />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Location/Address:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Websit Address:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Floor Area:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Turnover:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Operator Quantity:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Total head count:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Hours/Shift:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Shift /day:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Working day/week:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Production:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Customer:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Contact Information:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Contact Phone:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="E-mail:" style="width: 100%;">
							<el-input v-model="form.quoteTypeId" :disabled=" type == 'detail' || (operateType == 'operation' && type != 'editImport')" placeholder="" style="width: calc(100% - 160px);"/>
						</el-form-item>
					</el-col>
					<el-col :span="12">
					</el-col>
				</el-row>
			</el-form>
		</div>
		<el-row>
			<el-col :span="12">
				<div class="operate-cla">
					<div class="operate-btn" @click="submit">Cancle</div>
					<div class="operate-btn" @click="submit">Submit</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default{
		data() {
			return {
				form: {
					quoteTypeId: ''
				},
				QuoteTypeOptions: []
			}
		}
	}
</script>

<style scoped>
	.tcm-content {
		padding: 20px 90px 200px 20px ;
		box-sizing: border-box;
	}
	.mgt-5{
		margin-top: 5px;
	}
	.align-right{
		text-align: right;
	}
	.tcm-content-title{
		background-color: #f2f2f2;
		border-radius: 5px;
		padding: 20px 20px 20px 50px;
		font-size: 16px;
		font-weight: bold;
		position: relative;
	}
	.tcm-content-title:before{
		content: '';
		display: table;
		width: 6px;
		height: 6px;
		background: #000;
		border-radius: 50%;
		position: absolute;
		left: 30px;
		top: 27px;
	}
	.tcm-content-form{
		width: calc(100%);
		/* margin-left: 50px; */
		background-color: #f2f2f2;
		border-radius: 5px;
		margin-top: 10px;
		padding: 20px 50px 10px 50px;
		box-sizing: border-box;
	}
	.operate-cla{
		padding: 20px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
	}
	.operate-btn{
		border-radius: 5px;
		background-color: var(--themeColor);
		color: #fff;
		text-align: center;
		padding: 7px 10px;
		margin: 0 10px;
		display: inline-block;
		min-width: 80px;
		cursor: pointer;
	}
</style>