<template>
	<el-dialog v-model="visible" top="50px" width="35%" :show-close="false">
	    <template #header="{ close, titleId, titleClass }">
	      <div class="my-header">
	        <div class="size-16"></div>
	        <div style="cursor: pointer;" @click="close">
	          <el-icon :size="20"  style="color: var(--themeColor);"><CircleCloseFilled /></el-icon>
	        </div>
	      </div>
	    </template>
		<div class="tcm-cla">
			<el-row>
				<el-col :span="9">
					<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.currency')}}</div>
				</el-col>
				<el-col :span="13">
					<el-select clearable   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" filterable v-model="form.currencyId" placeholder="" style="width: calc(100% - 20px);" disabled>
						<el-option
						    v-for="item in CurrencyOptions"
						    :key="item.id"
						    :label="item.name"
						    :value="item.id"
						/>
					</el-select>
				</el-col>
				<el-col :span="2"></el-col>
			</el-row>
			<el-row >
				<el-col :span="9">
					<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.convertCurrency')}}</div>
				</el-col>
				<el-col :span="13">
					<el-select clearable   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" filterable v-model="form.convertCurrencyId" placeholder="" style="width: calc(100% - 20px);" disabled>
						<el-option
						    v-for="item in CurrencyOptions"
						    :key="item.id"
						    :label="item.name"
						    :value="item.id"
						/>
					</el-select>
				</el-col>
				<el-col :span="2"></el-col>
			</el-row>
			<el-row >
				<el-col :span="9">
					<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.exchangeRate')}}</div>
				</el-col>
				<el-col :span="13"><el-input v-model="form.converRate" @input="transferFormat($event, form, 'converRate'), toCompute(form)" @change="toCompute(form)" @blur="toCompute(form)" style="width: calc(100% - 20px);" /></el-col>
				<el-col :span="2"></el-col>
			</el-row>
			<el-row>
				<el-col :span="9">
					<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.processDescription')}}</div>
				</el-col>
				<el-col :span="13" v-show="type != 'template'"><el-input v-model="form.name" style="width: calc(100% - 20px);" disabled /></el-col>
				<el-col :span="2">
					<img src="../../assets/img/tcm/renminbi.png" style="width: 25px;margin-top: 3.5px;"/>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="9">
					<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.vendor')}}</div>
				</el-col>
				<el-col :span="13"><el-input v-model="form.vendor" style="width: calc(100% - 20px);" disabled /></el-col>
				<el-col :span="2">
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="9">
					<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.consumption')}}</div>
				</el-col>
				<el-col :span="13"><el-input v-model="form.quantity" style="width: calc(100% - 20px);" disabled /></el-col>
				<el-col :span="2"></el-col>
			</el-row>
			<el-row>
				<el-col :span="9">
					<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.unitMeasure')}}</div>
				</el-col>
				<el-col :span="13">
					<el-select clearable   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="form.unit" style="width: calc(100% - 20px);" disabled>
					    <el-option
					      v-for="it in unitOptions"
					      :key="it.id"
					      :label="it.name"
					      :value="it.id"
					    />
					</el-select>
				</el-col>
				<el-col :span="2"></el-col>
			</el-row>
			<el-row>
				<el-col :span="9">
					<div class="size-14 padding-9-0"><span class="color-red">*</span>{{$t('changePriceLists.changePriceList.costUoM')}}</div>
				</el-col>
				<el-col :span="13"><el-input v-model="form.unitCost" style="width: calc(100% - 20px);" @input="transferFormat($event, form , 'unitCost'), toCompute(form)"  @change="toCompute(form)" @blur="toCompute(form)" /></el-col>
				<el-col :span="2"></el-col>
			</el-row>
			<el-row>
				<el-col :span="9">
					<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.subTotalCost')}}</div>
				</el-col>
				<el-col :span="13"><el-input v-model="form.totalCost" style="width: calc(100% - 20px);" disabled /></el-col>
				<el-col :span="2"></el-col>
			</el-row>
			<el-row>
				<el-col :span="9">
					<div class="size-14 padding-9-0">{{$t('changePriceLists.changePriceList.priceReference')}}</div>
				</el-col>
				<el-col :span="13"><el-input v-model="form.price" style="width: calc(100% - 20px);" disabled /></el-col>
				<el-col :span="2"></el-col>
			</el-row>
		</div>
		<template #footer>
		      <span class="dialog-footer">
		        <el-button @click="close">{{$t('ccls.ccl.cancel')}}</el-button>
		        <el-button  style="background-color: var(--themeColor); color: var(--menuTextActive);margin-right: 16px" @click="submit">{{$t('ccls.ccl.confirm')}}</el-button>
		      </span>
		</template>
	</el-dialog>
</template>

<script>
	export default{
		props: ['unitOptions'],
		emits: ['submitPur'],
		data() {
			return {
				visible: false,
				CurrencyOptions: [],
				form: {
					isEnable: 1,
					processName: '',
					vendor: '',
					quantity: '',
					unit: '',
					unitCost: '',
					totalCost: '',
					price: '',
					commodityId: '',
					regionId: ''
				}
			}
		},
		mounted() {
		},
		methods: {
			close() {
				this.visible = false
			},
			// 提交
			submit() {
				if (this.form.unitCost === '') {
					commonUtil.showTipInfo('Please enter Material Cost / Unit of Measure！', '', 'warning');
					return;
				}
				console.log(this.form)
				this.$emit('submitPur', this.form)
				this.close()
			},
			// 计算
			toCompute(row) {
				console.log(row)
				let _this = this
				if (row.unitCost == '' || row.quantity == '') {
					return;
				}
				row.totalCost = (parseFloat(row.quantity) * parseFloat(row.unitCost)).toFixed(3)
			},
			// 限制输入数字
			transferFormat(val, item, name) {
				if (val !== '') {
					item[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, '$1')
				}
				if (name === 'converRate' && item[name] !== '') {
					let exchangeList = item.exchangeRate.split('.')
					let converRateList = item[name].split('.')
					item.unitCost = parseFloat((item.unitCost === '' || item.unitCost === null || !item.unitCost) ? '0' : item.unitCost) / parseFloat(item.exchangeRate === 0 || item.exchangeRate === '0' || (exchangeList.length == 2 && exchangeList[1] == '') ? 1 : item.exchangeRate) * (parseFloat(item[name]) === 0 || (converRateList.length == 2 && converRateList[1] == '') ? 1 : parseFloat(item[name]))
					item.exchangeRate = item[name]
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	:deep(.el-dialog__header){
		margin-right: 0;
	}
	.my-header {
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	}
	.tcm-cla{
		height: calc(100% - 90px);
		padding: 0 20px;
		box-sizing: border-box;
	}
	.size-14{
		font-size: 14px;
		position: relative;
	}
	.line-32{
		line-height: 32px;
	}
	.padding-9-0{
		padding: 9px 0;
	}
	.el-row {
	  margin-bottom: 10px;
	}
	.el-row:last-child {
	  margin-bottom: 0;
	}
	.color-red{
		color: red;
		position: absolute;
		left: -8px;
	}
</style>