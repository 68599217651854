<template>
  <div class="tcm-add">
    <div class="tcm-title">
      <div class="tcm-title-left">
        <div class="mgr-30">New Template Interface</div>
        <!-- <div class="size-14 padding-9-0 mgr-10">Commodity</div>
				<el-tree-select clearable v-model="CommodityId" :disabled=" operateType == 'detail'" :data="CommodityOptions" :props="defaultProps" check-strictly :render-after-expand="false" /> -->
      </div>
      <div>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          v-show="operateType != 'detail'"
          @click="submit"
          >OK</el-button
        >
      </div>
    </div>
    <div
      style="
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 50px 0 50px;
      "
    >
      <!-- <el-row v-show="operateType != 'add'"> -->
      <el-col :span="2" v-show="operateType != 'add'">
        <div class="size-14 padding-9-0">
          {{ $t("templateLists.templateList.cellNo") }}
        </div>
      </el-col>
      <el-col :span="6" v-show="operateType != 'add'"
        ><el-input v-model="number" style="width: 214px" disabled
      /></el-col>
      <!-- </el-row>
			<el-row> -->
      <el-col :span="2">
        <div class="size-14 padding-9-0">
          {{ $t("templateLists.templateList.cellName") }}
        </div>
      </el-col>
      <el-col :span="6"
        ><el-input
          v-model="name"
          style="width: 214px"
          :disabled="operateType == 'detail'"
      /></el-col>
      <!-- </el-row>
			<el-row> -->
      <el-col :span="2">
        <div class="size-14 padding-9-0">
          {{ $t("tcmlists.tcmlist.commodity") }}
        </div>
      </el-col>
      <el-col :span="6">
        <el-tree-select
          clearable
          :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
          style="width: 214px"
          v-model="CommodityId"
          :disabled="operateType == 'detail'"
          :data="CommodityOptions"
          :props="defaultProps"
          check-strictly
          :render-after-expand="false"
        />
      </el-col>
      <!-- </el-row>
			<el-row> -->
      <el-col :span="2">
        <div class="size-14 padding-9-0">
          {{ $t("templateLists.templateList.version") }}
        </div>
      </el-col>
      <el-col :span="6"
        ><el-input
          v-model="version"
          style="width: 214px"
          :disabled="operateType == 'detail'"
      /></el-col>
      <!-- </el-row>
			<el-row> -->
      <el-col :span="2">
        <div class="size-14 padding-9-0">
          {{ $t("templateLists.templateList.comments") }}
        </div>
      </el-col>
      <el-col :span="6"
        ><el-input
          v-model="comment"
          style="width: 214px"
          :disabled="operateType == 'detail'"
      /></el-col>
      <!-- </el-row> -->
    </div>
    <div class="hover-menu">
      <div
        :class="chooseCurrent == 'rm' ? 'choose-btn' : 'hover-btn'"
        style="margin-bottom: 10px"
        @click="chooseMenu('rm')"
      >
        RM
      </div>
      <div
        :class="chooseCurrent == 'pur' ? 'choose-btn' : 'hover-btn'"
        style="margin-bottom: 10px"
        @click="chooseMenu('pur')"
      >
        PUR
      </div>
      <div
        :class="chooseCurrent == 'pro' ? 'choose-btn' : 'hover-btn'"
        style="margin-bottom: 10px"
        @click="chooseMenu('pro')"
      >
        PRO
      </div>
      <div
        :class="chooseCurrent == 'p&l' ? 'choose-btn' : 'hover-btn'"
        style="margin-bottom: 10px"
        @click="chooseMenu('p&l')"
      >
        P&L
      </div>
      <div
        :class="chooseCurrent == 'oh' ? 'choose-btn' : 'hover-btn'"
        @click="chooseMenu('oh')"
      >
        OH
      </div>
    </div>
    <TcmRm
      v-show="chooseCurrent == 'rm'"
      ref="rwTcm"
      type="all"
      :operateType="operateType"
      :CommodityId="CommodityId"
    ></TcmRm>
    <TcmPur
      v-show="chooseCurrent == 'pur'"
      ref="purTcm"
      type="all"
      :operateType="operateType"
      :CommodityId="CommodityId"
    ></TcmPur>
    <TcmPro
      v-show="chooseCurrent == 'pro'"
      ref="proTcm"
      type="all"
      :operateType="operateType"
      :CommodityId="CommodityId"
    ></TcmPro>
    <TcmPl
      v-show="chooseCurrent == 'p&l'"
      ref="plTcm"
      type="all"
      :operateType="operateType"
    ></TcmPl>
    <TcmOh
      v-show="chooseCurrent == 'oh'"
      ref="ohTcm"
      type="all"
      :operateType="operateType"
    ></TcmOh>
  </div>
</template>

<script>
import TcmRm from "../tcmTemplateManagement/tcmRm.vue";
import TcmPur from "../tcmTemplateManagement/tcmPur.vue";
import TcmPro from "../tcmTemplateManagement/tcmPro.vue";
import TcmPl from "../tcmTemplateManagement/tcmPL.vue";
import TcmOh from "../tcmTemplateManagement/tcmOh.vue";
import {
  GetOrgs,
  AddOrUpdateTemplateApplys,
  GetTemplateOrDraftDetail,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  components: {
    TcmRm,
    TcmPur,
    TcmPro,
    TcmPl,
    TcmOh,
  },
  data() {
    return {
      id: "",
      number: "",
      name: "",
      version: "",
      comment: "",
      isEnable: 1,
      chooseCurrent: "rm",
      CommodityId: "",
      CommodityName: "",
      operateType: "add",
      CommodityOptions: [],
      // 商品分类显示字段
      defaultProps: {
        children: "children",
        label: "Name",
      },
    };
  },
  mounted() {
    this.operateType = this.$route.query.type;
    if (this.operateType == "add") {
      this.CommodityId = this.$route.query.CommodityId;
    } else {
      let id = this.$route.query.id;
      this.getDetail(id);
    }
    this.CommodityName = this.$route.query.CommodityName;
    this.getCommdity();
  },
  methods: {
    chooseMenu(val) {
      this.chooseCurrent = val;
    },
    // 获取详情
    getDetail(id) {
      GetTemplateOrDraftDetail({ id: id }, (response) => {
        if (response && response.code == 200) {
          let result = response.result;
          this.id = result.id;
          this.number = result.number;
          this.name = result.name;
          this.version = result.version;
          this.comment = result.comment;
          this.isEnable = result.isEnable;
          this.$refs.rwTcm.materialCost_RM = result.materialCost_RM;
          result.materialList.forEach(function (val, index) {
            let cLines = [];
            val.cLines.forEach(function (va, ind) {
              cLines.push({
                fieldName: va.paramName,
                calculatorTableId: va.calculator_TableId,
              });
            });
            val.cLines = cLines;
          });
          this.$refs.rwTcm.formData = result.materialList;
          this.$refs.rwTcm.materialDelIds = [];
          this.$refs.purTcm.purchaseCost_PUR = result.purchaseCost_PUR;
          this.$refs.purTcm.formData = result.purchaseList;
          this.$refs.purTcm.purchaseDelIds = [];
          this.$refs.proTcm.laborCost_PRO = result.laborCost_PRO;
          this.$refs.proTcm.setUpCost_PRO = result.setUpCost_PRO;
          this.$refs.proTcm.burdenCost_PRO = result.burdenCost_PRO;
          this.$refs.proTcm.manufacturingCost_PRO =
            result.manufacturingCost_PRO;
          result.processList.forEach(function (val, index) {
            let cLines = [];
            val.cLines.forEach(function (va, ind) {
              cLines.push({
                fieldName: va.paramName,
                calculatorTableId: va.calculator_TableId,
              });
            });
            val.cLines = cLines;
          });
          this.$refs.proTcm.formData = result.processList;
          this.$refs.proTcm.processDelIds = [];
          this.$refs.plTcm.form.calculationMethod = result.calculationMethod;
          this.$refs.plTcm.form.freightCost = result.freightCost;
          this.$refs.plTcm.form.wareHouseCost = result.wareHouseCost;
          this.$refs.plTcm.form.packageCost = result.packageCost;
          this.$refs.plTcm.form.otherCost = result.otherCost;
          this.$refs.plTcm.form.amortizationDescription =
            result.amortizationDescription;
          this.$refs.plTcm.form.totalPieces = result.totalPieces;
          this.$refs.plTcm.form.totalAmortize = result.totalAmortize;
          this.$refs.plTcm.form.amortizeCost = result.amortizeCost;
          this.$refs.ohTcm.form.sga = result.sga;
          this.$refs.ohTcm.form.sgaCost = result.sgaCost;
          this.$refs.ohTcm.form.materialProfit = result.materialProfit;
          this.$refs.ohTcm.form.addProfit = result.addProfit;
          this.$refs.ohTcm.form.purchaseProfit = result.purchaseProfit;
          this.$refs.ohTcm.form.totalProfit = result.totalProfit;
          this.$refs.ohTcm.form.totalProfitCost = result.totalProfitCost;
          this.$refs.ohTcm.form.materialPC = result.materialPC;
          this.CommodityId = result.commodityId;
        }
      });
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 提交
    submit() {
      let data = {
        id: this.id,
        number: this.number,
        name: this.name,
        version: this.version,
        comment: this.comment,
        isEnable: 1,
        materialCost_RM: this.$refs.rwTcm.materialCost_RM,
        materialLine: this.$refs.rwTcm.formData,
        materialDelIds: this.$refs.rwTcm.materialDelIds,
        purchaseCost_PUR: this.$refs.purTcm.purchaseCost_PUR,
        purchaseLine: this.$refs.purTcm.formData,
        purchaseDelIds: this.$refs.purTcm.purchaseDelIds,
        laborCost_PRO: this.$refs.proTcm.laborCost_PRO,
        setUpCost_PRO: this.$refs.proTcm.setUpCost_PRO,
        burdenCost_PRO: this.$refs.proTcm.burdenCost_PRO,
        manufacturingCost_PRO: this.$refs.proTcm.manufacturingCost_PRO,
        processLine: this.$refs.proTcm.formData,
        processDelIds: this.$refs.proTcm.processDelIds,
        calculationMethod: this.$refs.plTcm.form.calculationMethod,
        freightCost: this.$refs.plTcm.form.freightCost,
        wareHouseCost: this.$refs.plTcm.form.wareHouseCost,
        packageCost: this.$refs.plTcm.form.packageCost,
        otherCost: this.$refs.plTcm.form.otherCost,
        amortizationDescription: this.$refs.plTcm.form.amortizationDescription,
        totalPieces: this.$refs.plTcm.form.totalPieces,
        totalAmortize: this.$refs.plTcm.form.totalAmortize,
        amortizeCost: this.$refs.plTcm.form.amortizeCost,
        sga: this.$refs.ohTcm.form.sga,
        sgaCost: this.$refs.ohTcm.form.sgaCost,
        materialProfit: this.$refs.ohTcm.form.materialProfit,
        addProfit: this.$refs.ohTcm.form.addProfit,
        purchaseProfit: this.$refs.ohTcm.form.purchaseProfit,
        totalProfit: this.$refs.ohTcm.form.totalProfit,
        totalProfitCost: this.$refs.ohTcm.form.totalProfitCost,
        materialPC: this.$refs.ohTcm.form.materialPC,
        commodityId: this.CommodityId,
        isDraft: 1,
      };
      AddOrUpdateTemplateApplys(data, (response) => {
        if (this.operateType == "add") {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
        } else {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.editSuccess"),
            "",
            "success"
          );
        }
        this.$router.back(-1);
      });
    },
  },
};
</script>

<style scoped>
.tcm-add {
  position: relative;
}
.tcm-title {
  border-bottom: 2px solid #9a9a9a;
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.tcm-title-left {
  display: flex;
}
.mgr-30 {
  margin-right: 30px;
}
.mgr-10 {
  margin-right: 10px;
}
.hover-menu {
  width: 80px;
  padding: 15px 0;
  box-sizing: border-box;
  background-color: rgba(215, 215, 215, 0.474509803921569);
  border-radius: 5px;
  position: fixed;
  top: 150px;
  right: 0;
  z-index: 888;
}
.hover-btn {
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  color: #fff;
  background: rgba(174, 174, 174, 0.623529411764706);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  margin-left: 30px;
  cursor: pointer;
}
.choose-btn {
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  margin-left: 30px;
  text-align: center;
  border-radius: 0px 5px 5px 0;
  padding: 8px 0;
  background-color: #fff;
  border-left: 4px solid var(--themeColor);
  cursor: pointer;
}
.size-14 {
  font-size: 14px;
}
.padding-9-0 {
  padding: 9px 0;
}
</style>