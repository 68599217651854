const info = {
    // 中文
    ZH: {

        menuList: 'menuList',




    },
    // 英文
    EN: {
        menuList: 'menuLists',

    }
}
export default info