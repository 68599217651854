<template>
  <el-dialog v-model="visible" width="70%" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16" v-show="searchForm.isDraft === 0">
          {{ $t("changePriceLists.changePriceList.selectTemplate") }}
        </div>
        <div class="size-16" v-show="searchForm.isDraft === 1">
          {{ $t("changePriceLists.changePriceList.selectOwerTemplate") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-col :span="24">
        <table style="margin-bottom: 10px">
          <tr>
            <td>
              {{ $t("tcmdatalists.tcmdatalist.commodity") }}
              <el-tree-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="searchForm.CommodityId"
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
                style="display: inline-block"
              />
            </td>
            <td>
              {{ $t("changePriceLists.changePriceList.currency") }}
              <el-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="searchForm.currencyId"
                placeholder=""
              >
                <el-option
                  v-for="item in CurrencyOptions"
                  :key="item.currency"
                  :label="item.currencyName"
                  :value="item.currency"
                />
              </el-select>
            </td>
            <td>
              Region
              <el-select
                clearable
                v-model="searchForm.regionId"
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              >
                <el-option
                  v-for="item in regionOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </td>
            <td>
              <el-input
                v-model="searchForm.Keyword"
                :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
              />
            </td>
            <td>
              <el-button
                style="
                  background-color: var(--themeColor);
                  color: var(--menuTextActive);
                  margin-left: 16px;
                "
                @click="search"
                >{{ $t("tcmlists.tcmlist.search") }}</el-button
              >
            </td>
          </tr>
        </table>
      </el-col>
      <div class="regional_table">
        <el-table
          :empty-text="$t('tcmlists.tcmlist.noData')"
          :data="tableData"
          border
          stripe
          height="400"
          style="width: 100%"
        >
          <el-table-column
            prop="select"
            :label="$t('changePriceLists.changePriceList.select')"
            width="110"
            align="center"
          >
            <template #default="scope">
              <el-button
                style="background-color: #b2bde7; color: var(--menuTextActive)"
                @click="select(scope.row.id)"
                >{{ $t("changePriceLists.changePriceList.select") }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="number"
            :label="$t('changePriceLists.changePriceList.templateCode')"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="name"
            :label="$t('changePriceLists.changePriceList.templateName')"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="commodityName"
            :label="$t('tcmdatalists.tcmdatalist.commodity')"
            width="110"
            align="center"
          />
          <el-table-column
            prop="regionName"
            :label="$t('changePriceLists.changePriceList.region')"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            prop="currencyName"
            :label="$t('changePriceLists.changePriceList.currency')"
            width="90"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            prop="version"
            :label="$t('templateLists.templateList.version')"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="comment"
            :label="$t('templateLists.templateList.comments')"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="creator"
            :label="$t('templateLists.templateList.creator')"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="date"
            :label="$t('templateLists.templateList.time')"
            align="center"
            show-overflow-tooltip
          />
        </el-table>
      </div>

      <div class="pagination">
        <p>{{ total }} data</p>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- <template #footer>
		      <span class="dialog-footer">
		        <el-button @click="close">Cancel</el-button>
		        <el-button color="#335883" style="margin-right: 16px;" @click="close">Confirm</el-button>
		      </span>
		</template> -->
  </el-dialog>
</template>

<script>
import { GetTemplateApplys, getRegions } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: ["isdraft", "CurrencyOptions", "CommodityOptions"],
  emits: ["selectTemplate"],
  data() {
    return {
      visible: false,
      searchForm: {
        date: "",
        Enable: 1,
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        regionId: "",
        CommodityId: "",
        currencyId: "",
        isDraft: 0,
        VersionType: 1,
        page: 1,
        limit: 10,
      },
      // 总页数
      total: 0,
      tableData: [],
      // commodity选项
      defaultProps: {
        children: "children",
        label: "Name",
      },
    };
  },
  mounted() {
    this.getRegion();
  },
  methods: {
    close() {
      this.visible = false;
    },
    getlist() {
      // this.searchForm = {
      // 	date: '',
      // 	Enable: 1,
      // 	StartDate: '',
      // 	EndDate: '',
      // 	status: '',
      // 	Keyword: '',
      // 	regionId: '',
      // 	CommodityId: '',
      // 	isDraft: 0,
      // 	VersionType: 1,
      // 	page: 1,
      // 	limit: 10
      // }
      // this.searchForm.isDraft = this.isdraft
      // console.log(this.isdraft)
      this.search();
    },
    // 查询
    search() {
      GetTemplateApplys(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 选择
    select(id) {
      console.log(id);
      this.$emit("selectTemplate", id);
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>