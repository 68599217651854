<template>
  <div class="tcm-list">
    <div class="tcm-search">
      <table>
        <tr>
          <td>
            <div class="search-text-cla">Supplier Name</div>
            <el-input
              v-model="searchForm.key"
              placeholder=""
              style="width: calc(100% - 150px)"
            />
          </td>
          <td>
            <div class="search-text-cla">Part Number</div>
            <el-input
              v-model="searchForm.description"
              placeholder=""
              style="width: calc(100% - 150px)"
            />
          </td>
          <td>
            <div class="operate-btn" style="margin-right: 10px" @click="search">
              {{ $t("pcbas.pcba.search") }}
            </div>
          </td>
          <td></td>
        </tr>
        <tr>
          <td colspan="4" style="padding-top: 10px">
            <div class="operate-btn mrg-10" @click="addData">
              {{ $t("pcbas.pcba.new") }}
            </div>
            <div class="operate-btn mrg-10" @click="editData">Intelex</div>
            <div class="operate-btn mrg-10" @click="editAccount">Account</div>
            <div class="operate-btn mrg-10" @click="deleteData">Request</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="eBOMPN"
          label="Operate"
          width="130"
          align="center"
        >
          <template #default="scope">
            <div class="operate-btn plr-10" @click="editDetail(scope.row)">
              Edit
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="partNumber"
          label="Supplier Code"
          align="center"
        />
        <el-table-column
          prop="description"
          label="Supplier Name"
          align="center"
        />
        <el-table-column prop="revision" label="DUNs" align="center" />
        <el-table-column
          prop="supplier"
          label="Location/Address"
          align="center"
        />
        <el-table-column prop="cycleTime" label="Region" align="center" />
        <el-table-column
          prop="cycleTime_Cavity"
          label="Production"
          align="center"
        />
        <el-table-column
          prop="date"
          label="Contact Information"
          align="center"
        />
        <el-table-column prop="date" label="Contact Phone" align="center" />
        <el-table-column prop="date" label="Customer" align="center" />
      </el-table>
    </div>
    <div class="page-cla">
      <div>
        <el-input
          v-model="searchForm.limit"
          @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
          min="1"
          style="width: 130px"
          @change="handleSizeChange"
        >
          <template #suffix>
            {{ $t("ccls.ccl.barPage") }}
          </template>
        </el-input>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  CTCalculator_MainsLoad,
  CTCalculator_MainsAddOrUpdateBaseInfo,
  CTCalculator_MainsDelete,
} from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
import { CollectionTag } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      searchForm: {
        description: "",
        page: 1,
        limit: 10,
        key: "",
      },
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      timers: "",
      // 数据重复是否继续导入
      isContinue: false,
    };
  },

  mounted() {
    this.getList();
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加") {
          this.btnAdd = 1;
        }
        if (e.name == "删除") {
          this.btnDel = 1;
        }
        if (e.name == "编辑") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    getList() {
      this.searchForm = {
        description: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    search() {
      CTCalculator_MainsLoad(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addData() {
      this.$router.push({
        path: "/supplierCoAdd",
        name: "supplierCoAdd",
        query: {
          type: "add",
        },
      });
    },
    // 分配账号
    editAccount() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$router.push({
        path: "/supplierAccount",
        name: "supplierAccount",
        query: {
          id: this.chooseData[0].id,
        },
      });
    },
    confirmInfo(form) {
      CTCalculator_MainsAddOrUpdateBaseInfo(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.$refs.addDia.close();
          this.getList();
        }
      });
    },
    // 编辑数据
    editData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addDia.form = JSON.parse(JSON.stringify(this.chooseData[0]));
      this.$refs.addDia.visible = true;
    },
    // 编辑详情数据
    editDetail(row) {
      this.$router.push({
        path: "/ctDetailList",
        name: "ctDetailList",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 删除
    deleteData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      CTCalculator_MainsDelete(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
  },
};
</script>

<style scoped lang="scss">
.ElDrawer {
  :deep(.el-drawer__title) {
    font-size: 2rem;
  }
}
.tcm-search {
  width: 100%;
  background-color: var(--searchBg);
  padding: 20px;
  box-sizing: border-box;
}
.search-text-cla {
  display: inline-flex;
  width: 120px;
}
.tcm-search td {
  width: 20%;
  padding: 0 10px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.btn-text {
  color: var(--themeColor);
  cursor: pointer;
}
.color-ff0000 {
  color: #ff0000;
}
.color-F59A23 {
  color: #f59a23;
}
.color-0000FF {
  color: #0000ff;
}
.color-D9001B {
  color: #d9001b;
}
.color-67C23A {
  color: #40d4a8;
}
.plr-10 {
  padding: 0 10px;
}
.mlg-10 {
  margin-left: 10px;
}
.mrg-10 {
  margin-right: 10px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.box-card {
  margin-bottom: 10px;
}
.drawer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.drawer-footer {
  display: flex;
  justify-content: flex-end;
}
</style>