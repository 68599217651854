import { changepassword } from "@/assets/js/requestApi"

const info = {
    // 中文
    ZH: {
        welcome: '欢迎访问耐世特成本工程数字化系统',
        login: '登录',
        password: '密码',
        forgetPwd: '忘记密码',
        name: '用户名',
        nameverification: '请输入您的用户名',
        passwordverification: '请输入您的用户密码',
        hello: '你好',
        loginsuccess: '登录成功',
        loginfailed: '登录失败',
        welcomeSupplier: '耐世特成本工程数字化系统',
        supplierLogin: '供应商登录',
        forgetPwdSuccess: '密码已发送您的邮箱，请查看！',
        forgetPwdFailed: '用户名无效，请输入正确的用户名！',
        nopremission: '您无此权限，无法操作！',
        changepasswordMsg: "密码已重置,已发送至邮箱！",
        Forgot: '忘记',
        AccessApp: '输入您的账号密码访问应用程序',
    },
    // 英文
    EN: {
        welcome: 'WELCOME TO NEXTEER TCM DIGITAL SYSTEM',
        login: 'Login',
        password: 'Password',
        forgetPwd: 'Forgot Password',
        name: 'User Name',
        nameverification: 'Please Input USER NAME',
        passwordverification: 'Please Enter Password',
        hello: 'Hello',
        loginsuccess: 'Login SuccessFully',
        loginfailed: 'Login Failed',
        welcomeSupplier: 'NEXTEER TCM DIGITAL SYSTEM',
        supplierLogin: 'Supplier Login',
        forgetPwdSuccess: 'Password has been sent to your email, please check!',
        forgetPwdFailed: 'User name is invalid, please enter the correct user name!',
        nopremission: 'You do not have this permission, cannot operate!',
        changepasswordMsg: "Password reset, sent to email!",
        Forgot: 'Forgot',
        AccessApp: 'Access the app by entering your credentials',
    }
}
export default info