<template>
  <!-- <div class="rongqi">
      <!== 左侧日历 ==>
      <div style="padding-left: 10px">
        <!== 代办事项 ==>
        <div>
          <!== <div class="backlog">待办事项</div> ==>
          <div class="backlog">{{ $t("homePage.home.toItems") }}</div>
          <div class="backlogs" v-if="$t('homePage.home.daiban') == 'daiban'">
            <div v-for="(item, index) in daiban" :key="index">
              <div
                class="font-color"
                style="
                  font-size: 30px;
                  font-weight: 600;
                  cursor: pointer;
                  text-align: center;
                "
                @click="backlog(index)"
              >
                {{ item.num }}
              </div>
              <div style="text-align: center">{{ item.name }}</div>
            </div>
          </div>
          <div class="backlogs" v-if="$t('homePage.home.daiban') == 'daibans'">
            <div v-for="(item, index) in daibans" :key="index">
              <div
                class="font-color"
                style="
                  font-size: 30px;
                  font-weight: 600;
                  cursor: pointer;
                  text-align: center;
                "
                @click="backlog(index)"
              >
                {{ item.num }}
              </div>
              <div style="text-align: center">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <!== 第一个日历 ==>
        <div
          class="calender"
          style="margin: 50px 0 30px 0"
          v-if="$t('homePage.home.locale') == 'locale'"
        >
          <el-config-provider :locale="locale">
            <el-calendar v-model="value" class="custom-el-calender-table">
              <template #dateCell="{ data }">
                <div class="calendars" @click="calendardate(data)">
                  <div
                    :class="doDateList.indexOf(data.day) > -1 ? 'active' : ''"
                  >
                    {{ data.day.split("-").slice(2).join("-") }}
                  </div>
                </div>
              </template>
            </el-calendar>
          </el-config-provider>
        </div>
        <div
          class="calender"
          style="margin: 50px 0 30px 0"
          v-if="$t('homePage.home.locale') == 'locales'"
        >
          <el-config-provider :locale="locales">
            <el-calendar v-model="value" class="custom-el-calender-table">
              <template #dateCell="{ data }">
                <div class="calendars" @click="calendardate(data)">
                  <div
                    :class="doDateList.indexOf(data.day) > -1 ? 'active' : ''"
                  >
                    {{ data.day.split("-").slice(2).join("-") }}
                  </div>
                </div>
              </template>
            </el-calendar>
          </el-config-provider>
        </div>
        <!== 第二个日历 ==>
        <div class="calender" v-if="$t('homePage.home.locale') == 'locale'">
          <el-config-provider :locale="locale">
            <el-calendar v-model="values" class="custom-el-calender-table">
              <template #dateCell="{ data }">
                <div class="calendars" @click="calendardate(data)">
                  <div
                    :class="doDateList.indexOf(data.day) > -1 ? 'active' : ''"
                  >
                    {{ data.day.split("-").slice(2).join("-") }}
                  </div>
                </div>
              </template>
            </el-calendar>
          </el-config-provider>
        </div>
        <div class="calender" v-if="$t('homePage.home.locale') == 'locales'">
          <el-config-provider :locale="locales">
            <el-calendar v-model="values" class="custom-el-calender-table">
              <template #dateCell="{ data }">
                <div class="calendars" @click="calendardate(data)">
                  <div
                    :class="doDateList.indexOf(data.day) > -1 ? 'active' : ''"
                  >
                    {{ data.day.split("-").slice(2).join("-") }}
                  </div>
                </div>
              </template>
            </el-calendar>
          </el-config-provider>
        </div>
      </div>
      <!== 右侧清单 + 快捷操作 ==>
      <div
        style="
          width: calc(100% - 610px);
          padding: 20px 20px 0 40px;
          box-sizing: border-box;
        "
      >
        <!== <div style="height: 150px">
					<div class="inventoryTwo">快捷操作</div>
					<div class="inventoryTwos">
						<div v-for="(item, index) in operation" :key="index" class="caozuo">
							<img :src="item.icon" style="width: 40px;height: 40px;">
								<div>{{ item.name }}</div>
							</div>
						</div>
                </div> ==>
        <div class="inventory">
          1231232
          <div class="inventory-title">{{ $t("homePage.home.backlog") }}</div>
          <el-scrollbar height="260px">
            <div
              v-for="(item, index) in inventory.pendingItems"
              :key="index"
              @click="editProcess(item.id)"
            >
              <div
                style="
                  display: flex;
                  width: 100%;
                  margin-bottom: 8px;
                  cursor: pointer;
                "
              >
                <div style="display: inline-block">
                  <!== <div class="renwu">任务</div> ==>
                  <div class="renwu">{{ $t("homePage.home.task") }}</div>
                </div>
                <div style="display: inline-block; width: calc(100% - 125px)">
                  <div class="process-desc">
                    <div class="size-14 reneu-title">
                      {{ item.partDescription }}
                    </div>
                    <div class="renwu-no">
                      {{ $t("homePage.home.no") }}{{ item.documentNo }}
                    </div>
                    <div style="width: 70px; line-height: 30px">
                      {{ item.doDate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="inventory">
          123
          <div class="inventory-title">
            {{ $t("homePage.home.recentlyOpened") }}
          </div>
          <el-scrollbar height="260px">
            <div
              v-for="(item, index) in inventory.recentContactItems"
              :key="index"
              @click="showDetail(item.id)"
            >
              <div
                style="
                  display: flex;
                  width: 100%;
                  margin-bottom: 8px;
                  cursor: pointer;
                "
              >
                <div style="display: inline-block">
                  <div class="renwu">{{ $t("homePage.home.task") }}</div>
                </div>
                <div style="display: inline-block; width: calc(100% - 125px)">
                  <div class="process-desc">
                    <div class="size-14 reneu-title">
                      {{ item.partDescription }}
                    </div>
                    <div class="renwu-no">
                      {{ $t("homePage.home.no") }}{{ item.documentNo }}
                    </div>
                    <div style="width: 70px; line-height: 30px">
                      {{ item.doDate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="inventory">
          3333
          <div class="inventory-title">
            {{ $t("homePage.home.rencentlyContact") }}
          </div>
          <el-scrollbar height="260px">
            <div
              v-for="(item, index) in inventory.recentOpenItems"
              :key="index"
            >
              <div
                style="
                  display: flex;
                  width: 100%;
                  margin-bottom: 8px;
                  cursor: pointer;
                "
              >
                <div style="display: inline-block">
                  <!== <div class="renwu">任务</div> ==>
                  <div class="renwu">{{ $t("homePage.home.task") }}</div>
                </div>
                <div style="display: inline-block; width: calc(100% - 125px)">
                  <div class="process-desc">
                    <div class="size-14 reneu-title">
                      {{ item.partDescription }}
                    </div>
                    <div class="renwu-no">
                      {{ $t("homePage.home.no") }}{{ item.documentNo }}
                    </div>
                    <div style="width: 70px; line-height: 30px">
                      {{ item.doDate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div> -->
  <div class="main">
    <div class="main-top">
      <div class="main-top-l">
        <div class="home-title">
          <p>{{ $t("homePage.home.Homepage") }}</p>
          {{ $t("homePage.home.WelcomeSystem") }}
        </div>
        <div class="Calendar">
          <el-config-provider
            :locale="$t('homePage.home.locale') == 'locale' ? locale : locales"
          >
            <el-calendar v-model="value" class="custom-el-calender-table">
              <!-- <span class="Calendar-text"
                ><span>Calendar</span> {{ date }}</span
              > -->
              <template #header="{ date }"> </template>
              <template #dateCell="{ data }">
                <!-- -->
                <div class="calendars" @click="calendardate(data)">
                  <div
                    :class="[doDateList.indexOf(data.day) > -1 ? 'active' : '']"
                  >
                    {{ data.day.split("-").slice(2).join("-") }}
                    <div class="day-msg">
                      <img
                        v-show="doDateList.indexOf(data.day) > -1"
                        style="width: 18px; height: 18px"
                        src="../../assets/img/Vector.png"
                        alt=""
                      />
                      <div
                        class="todo-item-btn"
                        v-show="PaddingDateList.indexOf(data.day) > -1"
                      >
                        {{ $t("homePage.home.task") }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-calendar>
          </el-config-provider>
        </div>
      </div>
      <div class="main-top-r">
        <div class="todo-box shadow">
          <div class="todo-box-top">
            <div class="card-title">{{ $t("homePage.home.backlog") }}</div>
            <div></div>
          </div>
          <div class="todo-content">
            <div
              class="todo-item"
              v-for="(item, index) in PaddingList"
              :key="index"
              @click="editProcess(item.id, item.partDescription)"
            >
              <div class="todo-item-l flex">
                <div class="todo-item-btn">{{ $t("homePage.home.task") }}</div>
                <span>{{ item.partNumber }}</span>
              </div>
              <div class="todo-item-r">
                #{{ item.documentNo }}
                <span style="padding: 0" v-if="item.doDate"
                  >- {{ item.doDate }}</span
                >
              </div>
            </div>
            <div class="NoData-box" v-if="!PaddingList.length">
              {{ $t("homePage.home.NoData") }}
            </div>
          </div>
        </div>
        <div class="todo-box shadow">
          <div class="todo-box-top">
            <div class="card-title">
              <!-- <img
                style="width: 20px; height: 20px"
                src="../../assets/img/Vector.png"
                alt=""
              /> -->

              {{ $t("homePage.home.Warning") }}
            </div>
            <div></div>
          </div>
          <div class="todo-content">
            <div
              class="todo-item"
              v-for="item in WarningList"
              @click="editProcess(item.id, item.partDescription)"
            >
              <!-- <img
                style="width: 20px; height: 20px"
                src="../../assets/img/Vector.png"
                alt=""
              /> -->

              <div class="todo-item-l flex">
                <img
                  style="width: 20px; height: 20px"
                  src="../../assets/img/Vector.png"
                  alt=""
                />
                <!-- <div
                  class="todo-item-btn"
                  style="background-color: #e93030; color: #fff"
                >
                  
                </div> -->
                <span>{{ item.partNumber }}</span>
              </div>
              <div class="todo-item-r">
                #{{ item.documentNo }}
                <span style="padding: 0" v-if="item.doDate"
                  >- {{ item.doDate }}</span
                >
              </div>
            </div>
            <div class="NoData-box" v-if="!WarningList.length">
              {{ $t("homePage.home.NoData") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-bottom">
      <div class="main-bottom-l flex">
        <!-- <div class="Overview-line">
          <div class="card-title">Overview</div>
          <div class="Overview-main">
            <div class="Overview-item">
              <div class="Overview-item-flag"></div>
              <!== <div class="Overview-item-info">
                <p>Casting</p>
                <div>
                  <span
                    style="color: #333; font-size: 13px; padding-right: 30px"
                    >Casting</span
                  >
                  <span>/123</span>
                </div>
              </div> ==>
            </div>
            <div class="Overview-item">
              <div class="Overview-item-flag Contracts"></div>
              <!== <div class="Overview-item-info">
                <p>Casting</p>
                <div>
                  <span
                    style="color: #333; font-size: 13px; padding-right: 30px"
                    >Casting</span
                  >
                  <span>/123</span>
                </div>
              </div> ==>
            </div>
            <div class="Overview-item">
              <div class="Overview-item-flag reject"></div>
              <!== <div class="Overview-item-info">
                <p>Casting</p>
                <div>
                  <span
                    style="color: #333; font-size: 13px; padding-right: 30px"
                    >Casting</span
                  >
                  <span>/123</span>
                </div>
              </div> ==>
            </div>
            <div class="Overview-item">
              <div class="Overview-item-flag success"></div>
              <!== <div class="Overview-item-info">
                <p>Casting</p>
                <div>
                  <span
                    style="color: #333; font-size: 13px; padding-right: 30px"
                    >Casting</span
                  >
                  <span>/123</span>
                </div>
              </div> ==>
            </div>
          </div>
        </div>
        <div class="Overview-round flex">
          <div class="Overview-round-item flex">
            <el-progress width="50" color="#461EE7" type="circle" />
            <!== <p>Casting</p> ==>
            <!== :percentage="percentage" ==>
          </div>
          <div class="Overview-round-item flex Contracts">
            <el-progress
              width="50"
              :indeterminate="true"
              type="circle"
              indeterminate
              striped
              striped-flow
            />
            <!== <p>Casting</p> ==>
          </div>
          <div class="Overview-round-item flex reject">
            <el-progress width="50" indeterminate type="circle" />
            <!== <p>Casting</p> ==>
          </div>
          <div class="Overview-round-item flex success">
            <el-progress width="50" indeterminate type="circle" />
            <!== :percentage="percentage" ==>

            <!== <p>Casting</p> ==>
          </div>
        </div> -->
      </div>
      <div class="main-bottom-r shadow">
        <div class="Contacts-top">
          <div class="Contacts-text">
            <p>{{ $t("homePage.home.Contacts") }}</p>
            <!-- <span>You have 0 contacts</span> -->
          </div>
        </div>
        <div class="Contacts">
          <div
            :class="[
              'Contacts-item',
              ActiveContacts == '' ? 'ac-Contacts' : '',
            ]"
            @click="SelectContacts('')"
          >
            <div class="Contacts-item-img">
              {{ $t("homePage.home.All") }}
            </div>
          </div>
          <div
            :class="[
              'Contacts-item',
              ActiveContacts == item.tcmRequestId ? 'ac-Contacts' : '',
            ]"
            @click="SelectContacts(item.tcmRequestId)"
            v-for="item in ContactsList"
            :key="item.tcmRequestId"
          >
            <div class="Contacts-item-img">{{ item.tcmRequest }}</div>
          </div>
        </div>
        <!-- <div class="Contacts-More-data">View More</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCalendar,
  LoadForHome,
  GetApplyDetail,
  needtobedealtwith,
  getApplicationList,
  GetLoadResquest,
  GetUserProfile,
} from "@/assets/js/requestApi.js";
import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "../../assets/css/custom-el-calender-table.css";
import { Filter } from "@element-plus/icons-vue";
export default defineComponent({
  name: "HomePage",
  components: {
    ElConfigProvider,
  },

  data() {
    return {
      flagCount: 0,
      ActiveContacts: "",
      ActiveDate: "",
      status: "",
      toBeApproved: [],
      daibans: [
        { num: "", name: "待执行" },
        { num: "", name: "待审批" },
      ],
      daiban: [
        { num: "", name: "To be implemented" },
        { num: "", name: "Pending approval" },
      ],
      operation: [
        { icon: "", name: "XXX" },
        { icon: "", name: "XXX" },
        { icon: "", name: "XXX" },
        { icon: "", name: "XXX" },
      ],
      value: new Date(),
      values: new Date().setMonth(new Date().getMonth() + 1),
      doDateList: [], //日历匹配
      PaddingDateList: [], //日历匹配
      pageType: "", //日历跳转
      inventory: [], //清单接口
      percentage: 1,
      PaddingList: [],
      WarningList: [],
      ContactsList: [],
      TcmUserld: "",
    };
  },
  created() {
    // const { id } = JSON.parse(sessionStorage.getItem("userInfo"));
    // this.TcmUserld = id;
    setInterval(() => {
      this.percentage++;
    }, 1000);
    // getCalendar({}, (res) => {
    //   this.pageType = res.data.pageType;
    //   this.doDateList = [];
    //   if (res.data.dueDateForHome) {
    //     res.data.dueDateForHome.forEach((e) => {
    //       if (e.doDateList !== null) {
    //         this.doDateList.push(
    //           e.doDateList.split(" ")[0].split("-").slice(0).join("-")
    //         );
    //       }
    //     });
    //     console.log(this.doDateList, " this.doDateList");
    //   }
    // });
    this.LoadForHome();
    this.needtobedealtwith();
    // this.GetPaddingList();
    // this.GetContactsList();
  },
  activated() {
    this.GetCalendarOverDate();
    this.GetContactsList();
    this.FilterData();

    getApplicationList(
      {
        IsFilter: 0,
        limit: 9999,
        TcmUserId: "",
        versionType: 1,
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        key: "",
        PageType: 3,
        partNumber: "",
        programName: "",
      },
      (response) => {
        if (response && response.code == 200) {
          if (response.data.length) {
            response.data.forEach((e) => {
              this.PaddingDateList.push(e.doDate);
            });
          }
        }
      }
    );
  },
  methods: {
    SelectContacts(id) {
      this.ActiveContacts = id;
      this.FilterData();
    },
    // 获取过期数据
    GetCalendarOverDate() {
      // 0 是全部 1过期
      let params = {
        IsOverDute: 1,
        limit: 9999,
        versionType: 1,
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        key: "",
        PageType: 3,
        partNumber: "",
        programName: "",
      };
      getApplicationList(params, (response) => {
        if (response && response.code == 200) {
          this.doDateList = [];
          // this.WarningList = response.data;
          if (response.data.length) {
            response.data.forEach((e) => {
              this.doDateList.push(e.doDate);
            });
          }
        }
      });
    },
    GetPaddingList(val) {
      // 0 是全部 1过期
      let params = {
        IsFilter: 0,
        IsOverDute: 0,
        limit: 9999,
      };
      if (val) params = val;
      getApplicationList(params, (response) => {
        if (response && response.code == 200) {
          this.PaddingList = response.data;
        }
      });
    },
    GetWarningList(params) {
      // 0 是全部 1过期
      // let params = {
      //   IsOverDute: 1,
      //   limit: 9999,
      // };
      getApplicationList(params, (response) => {
        if (response && response.code == 200) {
          this.WarningList = response.data;
        }
      });
    },
    GetContactsList() {
      GetUserProfile({}, (response) => {
        if (response && response.code == 200) {
          this.TcmUserld = response.result.id;
          // 0 是全部 1过期
          let params = {
            TcmUserId: this.TcmUserld,
            // TcmRequsetId: "",
            limit: 9999,
            // -------------
            CommodityId: "",
            currencyId: "",
            date: "",
            StartDate: "",
            EndDate: "",
            status: "",
            Keyword: "",
            priceType: 0,
            isDraft: 0,
            page: 1,
            key: "",
            PageType: 3,
            versionType: 1,
            partNumber: "",
            programName: "",
          };
          // const { name } = JSON.parse(sessionStorage.getItem("userInfo"));
          GetLoadResquest(params, (response) => {
            if (response && response.code == 200) {
              // this.ContactsList = response.data

              // this.ContactsList = response.data.filter(function (x) {
              //   return x.tcmRequest !== name;
              // });
              this.ContactsList = response.data;
            }
          });
        }
      });
    },

    // getUnits() {
    //     this.timers = setInterval(this.valChange, 1000)
    // },
    // valChange() {
    //     this.powers = JSON.parse(localStorage.getItem('translate'))
    //     console.log(this.powers)
    //     if (this.powers == 'en' || this.powers == null) {
    //         this.locale = 'en'
    //         console.log('sssssssssssss')
    //     } else {
    //         this.locale = 'zhCn'
    //         console.log('zzzzzzzzzzzzzzzzzzz')
    //     }
    //     clearTimeout(this.timers)
    // },
    // 编辑
    editProcess(id, partDescription) {
      let ids = [];
      ids.push(id);
      GetApplyDetail(ids, (response) => {
        this.status = response.result[0].status;
        if (this.status == 0 || this.status == 1) {
          this.$router.push({
            path: "/addOperate.vue",
            name: "AddOperate",
            query: {
              type: "detail",
              id: id,
              crumbs: ["Pending Items", "Task"],
            },
          });
        } else {
          this.$router.push({
            path: "/apprcverSUM.vue",
            name: "apprcverSUM",
            query: {
              type: "edit",
              id: id,
              partDescription: partDescription,
              status: this.status,
            },
          });
        }
      });
    },
    //详情页
    showDetail(id) {
      let ids = [];
      ids.push(id);
      GetApplyDetail(ids, (response) => {
        this.status = response.result[0].status;
        this.$router.push({
          path: "/tcmResult",
          name: "tcmResult",
          query: {
            id: id,
          },
        });
      });
    },
    backlog(idx) {
      let _this = this;
      if (idx == 0) {
        _this.$router.push({
          path: "/OperateList",
          name: "OperateList",
          query: {
            status: 0,
          },
        });
      } else {
        _this.$router.push({
          path: "/Approver",
          name: "Approver",
          query: {
            status: 2,
          },
        });
      }
    },
    //主页待办
    needtobedealtwith() {
      needtobedealtwith({}, (res) => {
        if (res.result.length > 0) {
          this.daiban[0].num = res.result[0].toBeImplemented;
          this.daiban[1].num = res.result[0].toBeApproved;
          this.daibans[0].num = res.result[0].toBeImplemented;
          this.daibans[1].num = res.result[0].toBeApproved;
        } else {
          this.daiban[0].num = 0;
          this.daiban[1].num = 0;
          this.daibans[0].num = 0;
          this.daibans[1].num = 0;
        }
      });
      for (let i = 0; i < this.operation.length; i++) {
        let num = i + 1;
        this.operation[i].icon = require("@/assets/img/home/" +
          "u" +
          num +
          ".png");
      }
    },
    calendardate(data) {
      let Time = data.day;
      this.ActiveDate = Time;
      this.FilterData();
    },

    FilterData() {
      this.GetWarningList({
        CurrentDate: this.ActiveDate,
        IsOverDute: 1,
        IsFilter: 0,
        limit: 9999,
        // TcmUserld: this.TcmUserld,
        TcmUserId: this.ActiveContacts,
        // TcmRequsetId: this.ActiveContacts,
        // -----------
        versionType: 1,
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        key: "",
        PageType: 3,
        partNumber: "",
        programName: "",
      });

      this.GetPaddingList({
        // TcmUserld: this.TcmUserld,
        CurrentDate: this.ActiveDate,
        IsFilter: 0,
        // IsOverDute: 0,
        limit: 9999,
        TcmUserId: this.ActiveContacts,
        // TcmRequsetId: this.ActiveContacts,
        // -------------------------------------
        versionType: 1,
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "",
        Keyword: "",
        priceType: 0,
        isDraft: 0,
        page: 1,
        key: "",
        PageType: 3,
        partNumber: "",
        programName: "",
      });
    },
    LoadForHome() {
      LoadForHome({}, (res) => {
        this.inventory = res.data;
      });
    },
  },
  setup() {
    return {
      locale: zhCn,
      locales: "",
    };
  },
});
</script>



<style lang="scss" scoped>
.main {
  width: 100%;
  // display: flex;
  // flex-direction: column;
  height: 100%;
}

.home-title {
  height: 50px;
  padding-left: 15px;
  p {
    padding-bottom: 5px;
    font-weight: 600;
    font-size: 24px;
    color: #000;
  }
  color: #969ba0;
}
.main-l-content {
  display: flex;
  flex-direction: column;
}
.main-top {
  display: flex;
  height: 67%;
}
.main-top-l {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
}
.main-top-l,
.main-bottom-l {
  width: 100%;
  flex: 1;
}
.main-bottom-l {
  width: 100%;
  background: #fff;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.main-bottom {
  display: flex;
  // height: calc(100% - 400px);
  height: 33%;
}
.main-top-r,
.main-bottom-r {
  margin-left: 20px;
  width: 35%;
}
.main-bottom-r {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 0;
  .Contacts-top {
    height: 30px;
    display: flex;
    justify-content: space-between;
  }
  .Contacts-text {
    p {
      color: #363b64;
      font-weight: 600;
      font-size: 18px;
    }
    color: #a098ae;
    font-size: 12px;
  }
  .Contacts-handle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 25px;
    background: #363b64;
    font-size: 18px;
    cursor: pointer;
  }
}
// 代办事项
.todo-box {
  margin-bottom: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: calc(50% - 20px);
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  .todo-box-top-title {
  }
  .todo-box-top {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .todo-content {
    height: 170px;
    height: calc(100% - 30px);
    overflow-y: scroll;
  }
  .todo-item {
    display: flex;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    font-size: 12px;
    color: #363b64;
    span {
      padding-left: 20px;
    }
    .todo-item-l {
      align-items: center;
    }
    .todo-item-btn {
      width: 60px;
      height: 20px;
      line-height: 20px;
      border-radius: 5px;
      text-align: center;
      background: #e9ecff;
      color: #2f4cdd;
      cursor: pointer;
    }
  }
}
.warning-box {
  width: 100%;
  height: 300px;
  padding: 15px;
  border-radius: 20px;
  background-color: #fff;
}

.Calendar {
  width: 100%;
  // height: 700px;
  background-color: #fff;
}
.Calendar {
  // max-height: 440px;
  // margin-top: 10px;
  width: 100%;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  // overflow: hidden;
}
.Calendar-text {
  span {
    margin-right: 15px;
    color: #363b64;
    font-size: 20px;
    font-weight: 600;
  }
}
.calendars {
  height: 40px;
  color: #000;
  font-weight: 600;
}
.active {
  color: #e93030 !important;
}
.Calendar-handle-btn {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 25px !important;
  border: 1px solid #363b64;
  color: #363b64;
  font-weight: 700;
  border-radius: 80px;
  cursor: pointer;
}
.day-msg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .todo-item-btn {
    margin-left: 5px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    background: #e9ecff;
    color: #2f4cdd;
    font-weight: 400;
  }
}

.Overview-round {
  width: 60%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .Overview-round-item {
    margin-left: 20px;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-shrink: 0;
    // margin-top: 20px;
    // width: px;
    // height: 120px;
    border-radius: 10px;
    background: linear-gradient(190deg, #ac39d4, #d439c5);
    color: #fff;
  }
  :deep .el-progress__text {
    color: #fff;
    font-size: 14px !important;
  }
}
.card-title {
  // display: flex;
  // align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #171b1e;
}
.Overview-line {
  flex: 1;
  padding: 0 20px;
  .Overview-main {
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .Overview-item {
    height: 30px;
    display: flex;
    font-size: 12px;
  }
  .Overview-item-flag {
    width: 10px;
    height: 30px;
    border-radius: 10px;
    background: linear-gradient(190deg, #ac39d4, #d439c5);
  }
  .Overview-item-info {
    height: 30px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #717579;
  }
}
.Contracts {
  background: linear-gradient(190deg, #40d4a8, #40bad4) !important;
}
.reject {
  background: linear-gradient(190deg, #ff827a, #ffa825) !important;
}
.success {
  background: linear-gradient(190deg, #461ee7, #1eb6e7) !important;
}

.Contacts-item {
  margin-top: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  height: 20px;
  padding: 0 20px;
  line-height: 20px;
  background: #ccc;
  border-radius: 5px;
  cursor: pointer;
  // .Contacts-item-img {
  // }
}
.Contacts-item:hover {
  background: #b2bde7;
  color: #fff;
}
.ac-Contacts {
  background: #b2bde7;
  color: #fff;
}
.Contacts {
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  height: calc(100% - 50px);
  overflow-y: scroll;
}
.Contacts-More-data {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #363b64;
  cursor: pointer;
}
//
.shadow {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2),
    -2px -2px 5px rgba(255, 255, 255, 0.2);
}
// 日历
:deep .el-button-group > .el-button:first-child,
:deep .el-button-group > .el-button:not(:first-child):not(:last-child),
:deep .el-button-group > .el-button:last-child {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 30px;
  border: 1px solid #363b64;
  color: #363b64;
  font-weight: 700;
  border-radius: 80px;
  cursor: pointer;
}
:deep .el-calendar__body {
  padding: 10px;
  padding-top: 0px;
}
:deep .el-calendar__title {
  font-size: 16px;
  font-weight: 600;
  color: #363b64;
}
.el-card {
  padding: 0;
}
:deep .el-card__body {
  padding: 0;
}
/depp/ .el-calendar .custom-el-calender-table .el-calendar__header {
  padding: 0;
}

.todo-item-r {
  margin-left: 10px;
  overflow: hidden; /* 确保超出容器的文本被隐藏 */
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}
.todo-item:hover {
  background: #f5f7fa;
}
.NoData-box {
  padding: 15px;
  font-size: 14px;
  text-align: center;
  color: #666;
}
</style>