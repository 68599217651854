<template>
  <el-dialog v-model="visible" width="30%" destroy-on-close :show-close="false">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">VemChange</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20" style="color: var(--themeColor)"
            ><Close
          /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-form">
      <el-form :model="formData">
        <el-form-item label="New VEM">
          <el-input
            v-model="formData.userEmail"
            readonly="true"
            @click="selectUser"
          />
        </el-form-item>
        <el-form-item label="Comment">
          <el-input v-model="formData.comment" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{
          $t("userlists.userlist.cancel")
        }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="toConfirm"
          >{{ $t("userlists.userlist.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
  <ChooseUserDia
    ref="userDiaMain"
    @getChooseUser="vemChangeUserData"
  ></ChooseUserDia>
</template>
<script>
import ChooseUserDia from "../../pages/tcmApplication/chooseUserDia.vue";
export default {
  emits: ["ChangeUserConfirm"],

  components: {
    ChooseUserDia,
  },
  data() {
    return {
      visible: false,
      commodityId: "",
      formData: {
        userId: "",
        userEmail: "",
        comment: "",
      },
    };
  },
  mounted() {
    this.formData = {
      userId: "",
      userEmail: "",
      comment: "",
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    selectUser() {
      this.$refs.userDiaMain.getlist("3", this.commodityId);
      this.$refs.userDiaMain.isMultiChose = false; //单选
      this.$refs.userDiaMain.visible = true;
    },
    vemChangeUserData(data) {
      this.formData.userId = data[0].id;
      this.formData.userEmail = data[0].email;
    },
    toConfirm() {
      if (!this.formData.userId) {
        commonUtil.showTipInfo("Please Select User!", "", "warning");
        return false;
      }
      this.$emit("ChangeUserConfirm", this.formData);
    },
  },
};
</script>
<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0 !important;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.align-right {
  text-align: right;
}
.tcm-table {
  height: calc(100% - 90px);
  padding: 0 20px;
  box-sizing: border-box;
}
.tree-cla {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #ebeef5;
}
.el-row {
  height: 100%;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
</style>
