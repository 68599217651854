<template>
  <el-dialog v-model="visible" width="500px" :show-close="false">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form :model="form" :rules="rules" ref="form" label-width="120px">
        <el-form-item :label="$t('ccls.ccl.name')" prop="name">
          <el-input v-model="form.name" style="width: 240px" />
        </el-form-item>
        <el-form-item :label="$t('ccls.ccl.englishNames')" prop="nameEn">
          <el-input v-model="form.nameEn" style="width: 240px" />
        </el-form-item>
        <!-- <el-form-item label="所属部门" prop="parentName">
					<div @click="showMoudleDia">
						<el-input v-model="form.parentName" style="width: 240px" />
					</div>
				</el-form-item> -->
        <el-form-item :label="$t('ccls.ccl.enable')">
          <el-switch
            v-model="form.isEnable"
            :active-value="2"
            :inactive-value="1"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="addSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
    <TreeCommodity
      ref="TreeDepartmentDialog"
      @handleSubmit="DepartmentTree"
    ></TreeCommodity>
  </el-dialog>
</template>

<script>
import { login } from "@/assets/js/requestApi";
import commonUtil from "../../assets/js/commonUtil.js";
import TreeCommodity from "./treeCommodity.vue";
export default {
  components: {
    TreeCommodity,
  },
  data() {
    return {
      visible: false,
      form: {
        name: "",
        nameEn: "",
        parentName: "",
        parentId: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        nameEn: [
          {
            required: true,
            message: "请输入英文名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    // 显示选择部门弹窗
    showMoudleDia() {
      this.$refs.TreeDepartmentDialog.getTree();
      this.$refs.TreeDepartmentDialog.visible = true;
    },
    addSubmit() {
      // 给出添加弹框
      if (this.form.name == "") {
        commonUtil.showTipInfo("请输入商品名称", "", "warning");
        retrun;
      }
      this.visible = false;
      this.$emit("handleSubmit", this.form);
      // this.$refs.rules.validate();
      this.$refs.form.resetFields();
    },
    DepartmentTree(form) {
      this.form.parentName = form.Name;
      this.form.parentId = form.Id;
      this.$refs.TreeDepartmentDialog.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-16 {
  font-size: 16px;
}
</style>
