<template>
  <el-dialog v-model="visible" top="50px" width="800px" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16"></div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-cla">
      <el-row>
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane
            :label="$t('unifiedPrompts.unifiedPrompt.bar')"
            name="bar"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('unifiedPrompts.unifiedPrompt.pie')"
            name="pie"
          ></el-tab-pane>
          <!-- <el-tab-pane :label="$t('unifiedPrompts.unifiedPrompt.selectccl')" name="third"></el-tab-pane> -->
        </el-tabs>
      </el-row>
      <div
        id="compareCla"
        style="width: 100%; height: 600px"
        v-show="activeName == 'bar'"
      ></div>
      <div
        id="pieCla"
        style="width: 600px; height: 600px; margin-left: 50px"
        v-show="activeName == 'pie'"
      ></div>
    </div>
  </el-dialog>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      visible: false,
      activeName: "bar",
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    // 柱形图
    drawChart(data) {
      this.visible = true;
      setTimeout(() => {
        const compareCla = echarts.init(document.getElementById("compareCla"));
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            bottom: "1%",
          },
          grid: {
            top: "2%",
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true,
          },
          yAxis: {
            type: "value",
          },
          xAxis: {
            type: "category",
            data: ["Tcm"],
          },
          series: [
            {
              name: "Raw Material Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [data.materialCost_SUM],
            },
            {
              name: "Purchased Components Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [data.purchaseCost_SUM],
            },
            {
              name: "Direct Labor Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [data.laborCost_SUM],
            },
            {
              name: "Burden Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [data.burdenCost_SUM],
            },
            {
              name: "Set Up Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [data.setUpCost_SUM],
            },
            {
              name: "Packaging & Freight Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [data.packageCost_SUM],
            },
            {
              name: "Amortization Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [data.amortizeCost_SUM],
            },
            {
              name: "SG&A and Profit",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [data.sgaProfit_SUM],
            },
            {
              name: "Process Scrap Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: [data.scrapCost_SUM],
            },
          ],
        };
        compareCla.setOption(option);
      }, 300);
      this.drawPie(data);
    },
    // 饼图
    drawPie(data) {
      console.log(data);
      setTimeout(() => {
        const pieCla = echarts.init(document.getElementById("pieCla"));
        let option = {
          legend: {
            bottom: "1%",
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              center: ["50%", "40%"],
              label: {
                alignTo: "edge",
                formatter: "{b}:\n {c}",
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                  time: {
                    fontSize: 10,
                    color: "#999",
                  },
                },
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80,
              },
              data: [
                {
                  name: "Raw Material Cost",
                  value: data.materialCost_SUM,
                },
                {
                  name: "Purchased Components Cost",
                  value: data.purchaseCost_SUM,
                },
                {
                  name: "Direct Labor Cost",
                  value: data.laborCost_SUM,
                },
                {
                  name: "Burden Cost",
                  value: data.burdenCost_SUM,
                },
                {
                  name: "Set Up Cost",
                  value: data.setUpCost_SUM,
                },
                {
                  name: "Packaging & Freight Cost",
                  value: data.packageCost_SUM,
                },
                {
                  name: "Amortization Cost",
                  value: data.amortizeCost_SUM,
                },
                {
                  name: "SG&A and Profit",
                  value: data.sgaProfit_SUM,
                },
                {
                  name: "Process Scrap Cost",
                  value: data.scrapCost_SUM,
                },
              ],
            },
          ],
        };
        pieCla.setOption(option);
      }, 300);
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tcm-cla {
  height: calc(100% - 90px);
  padding: 0 20px;
  box-sizing: border-box;
}
</style>