<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">
        {{ $t("homePage.home.CCLSize") }}
      </div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          Nexteer Part
          <input type="text" v-model="searchForm.key" />
        </div>
        <div class="main-handle-item">
          {{ $t("ccls.ccl.desc") }}
          <input type="text" v-model="searchForm.keyword" />
        </div>
        <div class="main-handle-item">
          {{ $t("ccls.ccl.project") }}
          <input type="text" v-model="searchForm.project" />
        </div>
        <div class="search-btn" style="margin-top: 15px" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>

    <!-- <div class="handle-btn flex">
      <div class="handle-btn-item" style="margin-right: 10px" @click="addData">
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div class="handle-btn-item" style="margin-right: 10px" @click="editData">
        {{ $t("pcbas.pcba.edit") }}
      </div>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="deleteData"
      >
        {{ $t("pcbas.pcba.delete") }}
      </div>
    </div> -->
    <div class="regional_table" style="margin-top: 20px">
      <el-table
        stripe
        border
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @row-click="RowClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="cclSize_Code"
          :label="$t('ccls.ccl.code')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="nexteerPart"
          :label="$t('ccls.ccl.nexteerPart')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="name"
          :label="$t('ccls.ccl.cclname')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="description"
          :label="$t('ccls.ccl.desc')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="revisionID"
          :label="$t('ccls.ccl.revision')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="project"
          :label="$t('ccls.ccl.project')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="regionName"
          :label="$t('changePriceLists.changePriceList.region')"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="annualVolume"
          :label="$t('ccls.ccl.annualVolumn')"
          align="center"
        />
        <el-table-column
          prop="status"
          width="80"
          fixed="right"
          class-name="lastTd"
          :label="$t('templateLists.templateList.operate')"
          align="center"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon :size="15"><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div
                  class="action-btn"
                  @click="edit(scope.row)"
                  v-if="this.btnEdit > 0"
                >
                  <el-icon><Edit /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
                <div class="action-btn" @click="deleteData(scope.row.id)">
                  <el-icon><Delete /></el-icon>
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.delete") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        Showing {{ pageSize }} from {{ total }} data
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        layout="prev, pager, next"
        background
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <!-- <div class="tcm-list">
        <div class="tcm-search">
        	<table>
        		<tr>
        			<td>
        				<div  class="search-text-cla">Nexteer Part</div>
        				<el-input v-model="searchForm.key" placeholder="" style="width: calc(100% - 150px);" />
        			</td>
        			<td>
        				<div class="search-text-cla">Description</div>
        				<el-input v-model="searchForm.keyword" placeholder="" style="width: calc(100% - 150px);" />
        			</td>
        			<td>
        				<div class="search-text-cla">Project</div>
        				<el-input v-model="searchForm.project" placeholder="" style="width: calc(100% - 150px);" />
        			</td>
        			<td>
        				<div class="operate-btn" style="margin-right: 10px;" @click="search">
        					{{$t('tcmdatalists.tcmdatalist.search')}}
        				</div>
        			</td>
        		</tr>
        	</table>
        
        </div>
        <div class="tcm-hold"></div>
        <div class="tcm-table">
            <el-table :empty-text="$t('tcmlists.tcmlist.noData')" ref="tcmTable" :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" align="center" width="55" />
				<el-table-column :label="$t('ccls.ccl.operate')" align="center" width="150">
				    <template #default="scope">
				        <div class="flex-around">
				            <div class="operate-btnx" @click="edit(scope.row)" v-if="this.btnEdit>0">{{$t('unifiedPrompts.unifiedPrompt.edit')}}</div>
				            <div class="operate-btnx" @click="deleteData(scope.row.id)">{{$t('unifiedPrompts.unifiedPrompt.delete')}}</div>
				        </div>
				    </template>
				</el-table-column>
                <el-table-column prop="cclSize_Code" :label="$t('ccls.ccl.code')" align="center" show-overflow-tooltip />
				<el-table-column prop="nexteerPart" :label="$t('ccls.ccl.nexteerPart')" align="center" show-overflow-tooltip />
				<el-table-column prop="name" :label="$t('ccls.ccl.cclname')" align="center" show-overflow-tooltip />
				<el-table-column prop="description" :label="$t('ccls.ccl.desc')" align="center" show-overflow-tooltip />
				<el-table-column prop="revisionID" :label="$t('ccls.ccl.revision')" align="center" show-overflow-tooltip />
				<el-table-column prop="project" :label="$t('ccls.ccl.project')" align="center" show-overflow-tooltip />
				<el-table-column prop="regionName" :label="$t('changePriceLists.changePriceList.region')" align="center" show-overflow-tooltip />
                <el-table-column prop="annualVolume" :label="$t('ccls.ccl.annualVolumn')" align="center"/>
            </el-table>
        </div>
        <div class="page-cla">
            <div>
                <el-input v-model="searchForm.limit" @input="val=> searchForm.limit = val.replace(/[^\d]/g, '')" min="1" style="width: 130px" @change="handleSizeChange">
                    <template #suffix>
                        {{$t('ccls.ccl.barPage')}}
                    </template>
                </el-input>
            </div>
            <el-pagination layout="prev, pager, next" :total="total" :current-page="searchForm.page" @current-change="handleCurrentChange" />
        </div>
    </div> -->
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
import {
  loadCCLSizes,
  CCLSizesEnableByIds,
  CCLSizesDisableByIds,
  UpdateProcessIndirect,
  CCLSizesDelete,
} from "@/assets/js/requestApi.js";
export default {
  name: "CCLsize",
  activated() {
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {
    // 在从 DOM 上移除、进入缓存
    // 以及组件卸载时调用
  },
  data() {
    return {
      searchForm: {
        keyword: "",
        key: "",
        project: "",
        page: 1, //当前页
        limit: 10, //每页个数
      },
      tableData: [],
      total: 0,
      // 勾选数据
      chooseData: [],

      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
    };
  },
  mounted() {
    this.autoHeight();
    this.initList();
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    RowClick(row) {
      if (this.btnEdit > 0) {
        this.edit(row);
      }
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 215; // 这里的X就是顶部栏的高度
      });
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加" || e.name == "Add") {
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.name == "Delete") {
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.name == "Edit") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    // 渲染列表数据
    initList() {
      this.searchForm = {
        keyword: "",
        key: "",
        project: "",
        page: 1, //当前页
        limit: 10, //每页个数
      };
      this.search();
    },
    // 查询
    search() {
      loadCCLSizes(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.search();
    },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 批量操作
    operateBtn(type) {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      if (type == "enable") {
        CCLSizesEnableByIds(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
              "",
              "success"
            );
            this.search();
          }
        });
      } else if (type == "disabled") {
        CCLSizesDisableByIds(ids, (response) => {
          if (response && response.code == 200) {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
              "",
              "success"
            );
            this.search();
          }
        });
      }
    },
    // 新增
    addData() {
      this.$router.push({
        path: "/calculator",
        name: "calculator",
        query: {
          type: "add",
        },
      });
    },
    // 编辑
    edit(row) {
      this.$router.push({
        path: "/calculator",
        name: "calculator",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 删除
    deleteData(id) {
      let ids = [];
      ids.push(id);
      CCLSizesDelete(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>