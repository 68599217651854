<template>
	<div class="tcm-content">
		<div class="tcm-content-title">Forex  Information</div>
		<div class="tcm-content-form">
			<el-form :model="form" label-width="140px">
				<el-form-item label="Currency">
					<el-select clearable  v-model="form.Currency" :disabled=" type == 'detail'" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
					    <el-option
					      v-for="item in CurrencyOptions"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value"
					    />
					</el-select>
				</el-form-item>
				<el-form-item label="Report Currency">
					<el-select clearable  v-model="form.ReportCurrency" :disabled=" type == 'detail'"  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
					    <el-option
					      v-for="item in ReportCurrencyOptions"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value"
					    />
					</el-select>
				</el-form-item>
				<el-form-item label="Exchange Rate">
					<el-input v-model="form.rate" :disabled=" type == 'detail'" style="width: 214px;" />
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['type'],
		data() {
			return {
				form: {
					Currency: '',
					ReportCurrency: '',
					rate: '',
					currencyRateId: ''
				},
				// currency选项
				CurrencyOptions: [{
					value: 'Option1',
					label: 'Option1'
				}, {
					value: 'Option2',
					label: 'Option2'
				}],
				// ReportCurrency选项
				ReportCurrencyOptions: [{
					value: 'Option1',
					label: 'Option1'
				}, {
					value: 'Option2',
					label: 'Option2'
				}]
			}
		}
	}
</script>

<style scoped>
	.tcm-content {
		padding: 20px 90px 20px 20px ;
	}
	.tcm-content-title{
		background-color: #f2f2f2;
		border-radius: 5px;
		padding: 20px 20px 20px 50px;
		font-size: 16px;
		font-weight: bold;
		position: relative;
	}
	.tcm-content-title:before{
		content: '';
		display: table;
		width: 6px;
		height: 6px;
		background: #000;
		border-radius: 50%;
		position: absolute;
		left: 30px;
		top: 27px;
	}
	.tcm-content-form{
		width: calc(100% - 50px);
		margin-left: 50px;
		background-color: #f2f2f2;
		border-radius: 5px;
		margin-top: 10px;
		padding: 20px;
		box-sizing: border-box;
	}
</style>