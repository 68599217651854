<template>
  <div class="tcm-list">
    <div class="tcm-search">
      <el-row>
        <el-col :span="20">
          <table>
            <tr>
              <td>
                <el-input
                  v-model="searchForm.keywords"
                  :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
                />
              </td>
              <td>
                <div class="operate-btn" style="margin-right: 10px">
                  {{ $t("userlists.userlist.search") }}
                </div>
              </td>
            </tr>
          </table>
        </el-col>
        <el-col :span="4">
          <div class="align-right">
            <div
              class="operate-btn"
              style="margin-top: 3px"
              @click="add"
              v-if="this.btnAdd > 0"
            >
              {{ $t("unifiedPrompts.unifiedPrompt.new") }}
            </div>
            <!-- <div class="operate-btn" style="margin-top: 3px;" @click="add">为组织分配角色</div> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="GroupList"
        border
        style="width: 100%"
      >
        <!-- <el-table-column type="selection" width="55" align="center" /> -->
        <el-table-column
          prop="userGroupName"
          :label="$t('userlists.userlist.userGroupName')"
          align="center"
        />
        <el-table-column
          prop="tcmApplicationLists"
          :label="$t('userlists.userlist.tcmApplicants')"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            <span
              v-for="item in scope.row.tcmApplicationLists"
              :key="item.userId"
              >{{ item.tcmApplicationAccount }},
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="tcmOperationPersonneId"
          :label="$t('userlists.userlist.operationPersonnel')"
          align="center"
        >
          <template #default="scope">
            <span
              v-for="item in scope.row.tcmOperationPersonneId"
              :key="item.userId"
              >{{ item.tcmOperationPersonneAccount }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="comments"
          :label="$t('userlists.userlist.comments')"
          align="center"
        />
        <el-table-column
          :label="$t('userlists.userlist.enable')"
          align="center"
          prop="isEnable"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.isEnable == '1'"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.isEnable == '0'"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('userlists.userlist.operate')"
          align="center"
          width="250"
        >
          <template #default="scope">
            <div class="flex-around">
              <div
                class="operate-btnx"
                @click="edit(scope.row)"
                v-if="this.btnEdit > 0"
              >
                {{ $t("unifiedPrompts.unifiedPrompt.edit") }}
              </div>
              <div
                class="operate-btnx"
                @click="del(scope.row.id)"
                v-if="this.btnDel > 0"
              >
                {{ $t("unifiedPrompts.unifiedPrompt.delete") }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-cla">
        <div>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix>
              {{ $t("ccls.ccl.barPage") }}
            </template>
          </el-input>
        </div>
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <AddGroup ref="addDialog" v-on:handleSubmit="addSubmit"></AddGroup>
    <EditGroup ref="editDialog" v-on:handleSubmit="editSubmit"></EditGroup>
    <MoudleDia ref="moudleDialog"></MoudleDia>
  </div>
</template>

<script>
import AddGroup from "./addGroup.vue";
import EditGroup from "./editGroup.vue";
import {
  editGroup,
  userGroupList,
  delGroupList,
  addGroupList,
} from "@/assets/js/requestApi";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  components: {
    AddGroup,
    EditGroup,
  },
  data() {
    return {
      searchForm: {
        comments: "",
        userGroupName: "",
        date: "",
        keywords: "",
        tcmApplicationLists: {
          tcmApplicationAccount: "",
        },
        tcmOperationPersonneId: {},
        page: 1, //当前页
        limit: 10, //每页个数
      },
      filterText: "",
      total: 0,
      pageSize: 10,
      GroupList: [],

      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
    };
  },
  mounted() {
    this.initList();
    this.search();
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));

      this.power.forEach((e) => {
        if (e.name == "添加" || e.domId == "Add") {
          this.btnAdd = 1;
          this.btnAdd = 1;
        }
        if (e.name == "删除" || e.domId == "Del") {
          this.btnDel = 1;
          this.btnDel = 1;
        }
        if (e.name == "编辑" || e.domId == "Edit") {
          this.btnEdit = 1;
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    // 渲染列表
    initList() {
      this.searchForm = {
        comments: "",
        userGroupName: "",
        date: "",
        isEnable: "",
        keywords: "",
        tcmApplicationLists: {
          tcmApplicationAccount: [],
        },
        tcmOperationPersonneId: {},
        page: 1, //当前页
        limit: 10, //每页个数
      };
      this.search();
    },
    // 查询
    search() {
      userGroupList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.GroupList = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 删除用户
    del(id) {
      delGroupList((id = [id]), (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 新增
    add() {
      this.$refs.addDialog.visible = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    addSubmit(form) {
      form.tcmOperationPersonnel = form.tcmOperationPersonneAccount;
      // form.tcmApplicationAccount = form.userGroup_List.tcmApplication;
      addGroupList(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 编辑
    edit(row) {
      this.$refs.editDialog.getOptions();
      this.$refs.editDialog.form.id = row.id;
      this.$refs.editDialog.form.userGroupName = row.userGroupName;
      this.$refs.editDialog.form.comments = row.comments;
      this.$refs.editDialog.form.tcmApplicationAccount = [];
      row.tcmApplicationLists.forEach((data) => {
        this.$refs.editDialog.form.tcmApplicationAccount.push(data.userId);
      });
      this.$refs.editDialog.form.tcmOperationPersonneAccount =
        row.tcmOperationPersonneId[0].userId;
      this.$refs.editDialog.form.isEnable = row.isEnable;
      this.$refs.editDialog.visible = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    editSubmit(form) {
      form.tcmOperationPersonnel = form.tcmOperationPersonneAccount;
      addGroupList(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.editSuccess"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页数量修改
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 显示选择部门弹窗
    showMoudleDia(val) {
      if (val) {
        this.$refs.moudleDialog.visible = true;
      }
    },
  },
};
</script>

<style scoped>
.tcm-list {
  height: 100%;
}
.tcm-search {
  width: 100%;
  background-color: var(--searchBg);
  padding: 20px;
  box-sizing: border-box;
}
.tcm-search td {
  /* width: 20%; */
  padding: 0 10px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.color-ff0000 {
  color: #ff0000;
}
.color-F59A23 {
  color: #f59a23;
}
.color-0000FF {
  color: #0000ff;
}
.color-D9001B {
  color: #d9001b;
}
.align-right {
  text-align: right;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.operate-btnx {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 2px 5px;
  min-width: 60px;
  cursor: pointer;
}
.tcm-table {
  height: calc(100% - 90px);
  padding: 0px 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
.tree-cla {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #ebeef5;
}
.el-row {
  height: 100%;
}
</style>