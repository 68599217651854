<template>
  <el-dialog v-model="visible" width="400px" :show-close="false">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("tcmlists.tcmlist.department") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-row>
        <el-col>
          <div class="tree-cla">
            <el-tree
              ref="treeRef"
              class="filter-tree"
              :data="treedata"
              node-key="id"
              :props="defaultProps"
              default-expand-all
              show-checkbox
              @check-change="handleCheckChange"
              :check-strictly="true"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { treeDepartment, echoList } from "@/assets/js/requestApi.js";
export default {
  props: ["userId"],
  data() {
    return {
      visible: false,
      defaultProps: {
        children: "children",
        label: "name",
      },
      treedata: [],
      form: {
        organizations: [],
        organizationIds: [],
      },
    };
  },

  methods: {
    close() {
      this.visible = false;
    },
    // 树点击
    handleCheckChange(data, checked, indeterminate) {
    },
    // 提交按钮
    handleSubmit() {
      let res = this.$refs.treeRef.getCheckedNodes();
      let _this = this;
      res.forEach(function (val) {
        _this.form.organizations.push(val.name);
        _this.form.organizationIds.push(val.id);
      });
      this.$refs.treeRef.setCheckedKeys([], false);
      this.$emit("handleSubmit", this.form);
      this.form = {
        organizations: [],
        organizationIds: [],
      };
      this.visible = false;
    },

    // 树接口
    getTree(data) {
      treeDepartment(
        {
          id: "",
          page: 1,
          limit: 99999,
        },
        (response) => {
          if (response && response.code == 200) {
            let treedata = response.data;
            this.treedata = this.transferList(response.data);
            // 部门列表回显--bianji
            echoList('"' + this.userId + '"', (response) => {
              if (response && response.code == 200) {
                let arr = [];
                for (let i = 0; i < treedata.length; i++) {
                  for (let j = 0; j < response.data.length; j++) {
                    if (treedata[i].id == response.data[j].id) {
                      arr.push(treedata[i]);
                    }
                  }
                }
                this.arr = arr;

                this.$refs.treeRef.setCheckedNodes(arr);
              }
            });
          }
        }
      );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 数据转化
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.id === item.parentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>