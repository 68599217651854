<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">{{ $t("homePage.home.TCMData") }}</div>
      <div class="main-handle flex">
        <!-- 状态 -->
        <div class="main-handle-item">
          {{ $t("templateLists.templateList.enable") }}
          <el-select
            clearable
            v-model="searchForm.isDisable"
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="main-handle-item">
          {{ $t("tcmdatalists.tcmdatalist.commodity") }}
          <!-- CommodityId -->
          <el-tree-select
            size="small"
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="searchForm.CommodityId"
            :data="CommodityOptions"
            :props="defaultProps"
            check-strictly
            :render-after-expand="false"
            :default-expand-all="true"
          />
        </div>
        <div class="main-handle-item">
          {{ $t("changePriceLists.changePriceList.programName") }}
          <input
            type="text"
            v-model="searchForm.programName"
            :placeholder="$t('changePriceLists.changePriceList.programName')"
          />
        </div>
        <div class="main-handle-item">
          {{ $t("changePriceLists.changePriceList.partNumber") }}
          <input
            type="text"
            v-model="searchForm.partNumber"
            :placeholder="$t('changePriceLists.changePriceList.partNumber')"
          />
        </div>
        <div class="main-handle-item">
          {{ $t("unifiedPrompts.unifiedPrompt.keyword") }}
          <input
            type="text"
            v-model="searchForm.Keyword"
            :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
          />
        </div>
        <!-- 日期选择 -->
        <div class="main-handle-item" style="margin-top: 13px">
          <div class="select-box">
            <div class="select-btn">
              <el-icon class="Calendar"><Calendar /></el-icon>
              <span :style="{ color: searchForm.date ? '#606266' : '' }">{{
                searchForm.date
                  ? searchForm.date[0] + " - " + searchForm.date[1]
                  : $t("tcmdatalists.tcmdatalist.pleaseSlect")
              }}</span>
              <el-icon v-show="!searchForm.date">
                <ArrowDownBold />
              </el-icon>
              <el-icon :size="18" v-show="searchForm.date">
                <Close />
              </el-icon>
            </div>
            <el-date-picker
              class="date-picker"
              style="
                width: 200px;
                height: 30px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
              "
              v-model="searchForm.date"
              type="daterange"
              range-separator="To"
              :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
              :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
              size="mini"
              value-format="YYYY-MM-DD"
              @change="changeDate"
            />
          </div>
        </div>

        <div class="search-btn" @click="search" style="margin-top: 13px">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
        <div
          class="search-btn"
          @click="OpenExportFormFlag"
          style="margin-top: 13px; margin-left: 5px"
        >
          {{ $t("ccls.ccl.AdvanceSetting") }}
        </div>
      </div>
    </div>
    <div class="handle-btn flex">
      <!-- <div class="handle-btn-item" @click="edit">xxx</div> -->
      <!--  -->
      <div
        class="handle-btn-item"
        v-if="EditBtnFlag"
        @click="bathOperate('start')"
      >
        {{ $t("templateLists.templateList.batchEnable") }}
      </div>
      <div
        class="handle-btn-item"
        v-if="EditBtnFlag"
        @click="bathOperate('stop')"
      >
        {{ $t("templateLists.templateList.batchDisabling") }}
      </div>
      <div class="handle-btn-item" @click="exportData">
        {{ $t("ccls.ccl.ExportTcm") }}
      </div>
      <div class="handle-btn-item" @click="importList">
        {{ $t("ccls.ccl.importList") }}
      </div>
      <div class="handle-btn-item" @click="ExportFormFun">
        {{ $t("ccls.ccl.ExportForm") }}
      </div>
      <!-- /TODO -->
      <!-- <div class="handle-btn-item" @click="GoNewPage">newpage</div> -->
    </div>
    <div class="regional_table">
      <el-table
        stripe
        border
        :data="tableData"
        :height="TableHeight"
        @row-click="RowClick"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column resizable type="selection" width="55" />
        <el-table-column
          v-if="settingForm.PartNumberFlag"
          width="120"
          prop="partNumber"
          :label="$t('tcmdatalists.tcmdatalist.partNumber')"
          align="center"
        />
        <el-table-column
          v-if="settingForm.ProgramFlag"
          prop="programName"
          width="120"
          :label="$t('changePriceLists.changePriceList.programName')"
          align="center"
        />
        <el-table-column
          v-if="settingForm.CommodityFlag"
          prop="commodityName"
          :label="$t('tcmdatalists.tcmdatalist.commodity')"
          width="110"
          align="center"
        />
        <el-table-column
          v-if="settingForm.RegionFlag"
          prop="regionName"
          :label="$t('tcmdatalists.tcmdatalist.regionName')"
          align="center"
        />

        <el-table-column
          v-if="settingForm.RequesterFlag"
          prop="tcmOwner"
          :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
          align="center"
        />
        <el-table-column
          v-if="settingForm.DescriptionFlag"
          width="180"
          prop="partDescription"
          :label="$t('tcmdatalists.tcmdatalist.partDescription')"
          align="center"
        />
        <el-table-column
          v-if="settingForm.RequestTypeFlag"
          prop="quoteType"
          width="110"
          :label="$t('tcmdatalists.tcmdatalist.quotationType')"
          align="center"
        />
        <el-table-column
          v-if="settingForm.OwnerFlag"
          prop="tcmRequest"
          width="110"
          :label="$t('tcmdatalists.tcmdatalist.tcmOwner')"
          align="center"
        />

        <el-table-column
          v-if="settingForm.PriceFlag"
          prop="totalPriceCurr_SUM"
          width="110"
          :label="$t('tcmdatalists.tcmdatalist.Price')"
          align="center"
        >
           <template #default="scope">
            {{
              scope.row.totalPriceCurr_SUM
                ? scope.row.totalPriceCurr_SUM.toFixed(3)
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="settingForm.VersionFlag"
          prop="version"
          :label="$t('templateLists.templateList.version')"
          align="center"
        />

        <el-table-column
          v-if="settingForm.CurrencyFlag"
          prop="currencyName"
          width="110"
          :label="$t('changePriceLists.changePriceList.currency')"
          align="center"
        />
        <el-table-column
          v-if="settingForm.ReportFlag"
          prop="report_Currency"
          width="110"
          :label="$t('changePriceLists.changePriceList.reportCurrency')"
          align="center"
        />
        <el-table-column
          prop="approvedTime"
          width="110"
          :label="$t('tcmdatalists.tcmdatalist.dueDate')"
          sortable
          align="center"
        />
        <el-table-column
          prop="status"
          width="110"
          :label="$t('templateLists.templateList.enable')"
          align="center"
        >
          <template #default="scope">
            <el-icon
              :size="16"
              color="#40D4A8"
              v-show="scope.row.isDisable === 0"
            >
              <SuccessFilled />
            </el-icon>
            <el-icon
              :size="16"
              color="#F56C6C"
              v-show="scope.row.isDisable === 1"
            >
              <CircleCloseFilled />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column
          v-if="settingForm.TotalSellFlag"
          prop="totalPriceReport_SUM"
          :label="$t('changePriceLists.changePriceList.totalSellCurrency')"
          align="center"
        >
          <template #default="scope">
            {{
              scope.row.totalPriceReport_SUM
                ? scope.row.totalPriceReport_SUM.toFixed(3)
                : ""
            }}
          </template>
        </el-table-column>
        <!-- Rm  Column -->
        <el-table-column
          label="Raw Materials"
          v-if="RmTitleFlag"
          align="center"
        >
          <template v-for="item in RmColumn" :key="item.key">
            <el-table-column
              v-if="item.isCheck"
              :label="item.key"
              width="150"
              align="center"
            >
              <template #default="scope">
                <span v-if="scope.row.materialList.length">
                  {{
                    scope.row.materialList[item.index]
                      ? scope.row.materialList[item.index][item.value]
                      : ""
                  }}
                </span>
                <span v-else> </span>
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- Pur  Column -->

        <el-table-column
          v-if="PurTitleFlag"
          label="Purchase Component"
          align="center"
        >
          <template v-for="item in PurColumn" :key="item.key">
            <el-table-column
              v-if="item.isCheck"
              :label="item.key"
              align="center"
              width="200"
            >
              <template #default="scope">
                <span v-if="scope.row.purchaseList.length">
                  {{
                    scope.row.purchaseList[item.index]
                      ? scope.row.purchaseList[item.index][item.value]
                      : ""
                  }}
                </span>
                <span v-else> </span>
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- ProColumn -->
        <el-table-column v-if="ProTitleFlag" label="Processing" align="center">
          <template v-for="item in ProColumn" :key="item.key">
            <el-table-column
              v-if="item.isCheck"
              :label="item.key"
              width="200"
              align="center"
            >
              <template #default="scope">
                <span v-if="scope.row.processList.length">
                  {{
                    scope.row.processList[item.index]
                      ? scope.row.processList[item.index][item.value]
                      : ""
                  }}
                </span>
                <span v-else> </span>
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- PLColumn -->
        <el-table-column v-if="PLTitleFlag" label="P&L" align="center">
          <template v-for="item in PLColumn" :key="item.key">
            <el-table-column
              v-if="item.isCheck"
              :label="item.key"
              width="200"
              align="center"
            >
              <template #default="scope">
                {{ scope.row[item.value] }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- OHColumn -->
        <el-table-column v-if="OHTitleFlag" label="OH" align="center">
          <template v-for="item in OHColumn" :key="item.key">
            <el-table-column
              v-if="item.isCheck"
              :label="item.key"
              align="center"
              width="200"
            >
              <template #default="scope">
                {{ scope.row[item.value] }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>
        <el-table-column
          v-if="SumTitleFlag"
          label="COST SUMMARY"
          align="center"
        >
          <template v-for="item in SumColumn" :key="item.key">
            <el-table-column
              v-if="item.isCheck"
              :label="item.key"
              width="200"
              align="center"
            >
              <template #default="scope">
                {{ scope.row[item.value] }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <el-table-column
          width="80"
          fixed="right"
          class-name="lastTd"
          align="center"
          prop="action"
          :label="$t('homePage.home.Action')"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div class="action-btn" @click="toCompare(scope.row)">
                  <img
                    style="width: 15px; height: 15px"
                    src="../../assets/img/tcm/add.svg"
                  />
                  <span>{{ $t("homePage.home.Compare") }}</span>
                </div>
                <!-- v-if="EditBtnFlag" -->
                <div
                  class="action-btn"
                  v-if="scope.row.isOperation === 0"
                  @click="EditBtn(scope.row)"
                >
                  <img
                    style="width: 15px; height: 15px"
                    src="../../assets/img/tcm/computer.svg"
                  />
                  <span>{{ $t("unifiedPrompts.unifiedPrompt.edit") }}</span>
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        {{ $t("homePage.home.Showing") }} {{ pageSize }}
        {{ $t("homePage.home.From") }} {{ total }}
        {{ $t("homePage.home.Data") }}
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
  <ExportForm ref="ExportForm"></ExportForm>
</template>
<script>
import {
  GetOrgs,
  getApplicationList,
  GetCostPartLoad,
  deleteApplyDetail,
  ImportTCMDataBaseExcel,
  ExportTCMDataBaseExcel,
  ExportDataTableExcel,
  GetDataIsDuplicate,
  ExportTCMDataBaseExcelNew,
  tcmDataDisableByIds,
  DownloadTcmDataExcel,
  GetExportSettingLoad,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
import { ElMessageBox } from "element-plus";
import ExportForm from "./ExportForm.vue";
export default {
  name: "tcmdatalist",
  components: {
    ExportForm,
  },
  activated() {
    // this.autoHeight();
    // 在首次挂载、
    // 以及每次从缓存中被重新插入的时候调用
    this.search();
  },
  deactivated() {},
  data() {
    return {
      settingForm: {},
      TableHeight: 0,
      searchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        StartDate_Approved: "",
        EndDate_Approved: "",
        status: 6,
        Keyword: "",
        priceType: "",
        PageType: 5,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 0,
        isDisable: 0,
        partNumber: "",
        programName: "",
      },
      // commodity选项
      CommodityOptions: [],
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // status选项
      statusOptions: [],
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      timers: "",
      // 数据重复是否继续导入
      isContinue: false,
      // 是否为历史版本
      historyVal: false,
      // 当前用户账号
      userAccount: "",
      EditBtnFlag: false,
      // GenColumn: [],
      RmColumn: [],
      RmTitleFlag: false,
      PurColumn: [],
      PurTitleFlag: false,
      ProColumn: [],
      ProTitleFlag: false,
      PLColumn: [],
      PLTitleFlag: false,
      OHColumn: [],
      OHTitleFlag: false,
      SumColumn: [],
      SumTitleFlag: false,
      // GenFlag: true,
    };
  },

  mounted() {
    this.autoHeight();
    this.getList();
    this.getCommdity();
    this.timer = setTimeout(this.gettime, 1000);
    this.getUnits();
    let userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      this.userAccount = JSON.parse(userInfo).account;
    }
    if (sessionStorage.getItem("user") == "Admin") {
      this.EditBtnFlag = true;
    }
  },
  watch: {
    historyVal(val) {
      if (val) {
        this.searchForm.page = 1;
        this.searchForm.versionType = 2;
        this.search();
      } else {
        this.searchForm.page = 1;
        this.searchForm.versionType = 1;
        this.search();
      }
    },
  },
  methods: {
    GetSettingList() {
      GetExportSettingLoad("", (res) => {
        if (res && res.code == 200) {
          this.RmTitleFlag = false;
          this.PurTitleFlag = false;
          this.ProTitleFlag = false;
          this.PLTitleFlag = false;
          this.OHTitleFlag = false;
          this.SumTitleFlag = false;
          let count = 0;
          res.data.general_Informations.forEach((item) => {
            if (item.isCheck) count++;
            switch (item.name) {
              case "Request Type":
                this.settingForm.RequestTypeFlag = item.isCheck;
                break;
              case "TCM Requester":
                this.settingForm.RequesterFlag = item.isCheck;
                break;
              case "Region Name":
                this.settingForm.RegionFlag = item.isCheck;
                break;
              case "Commodity":
                this.settingForm.CommodityFlag = item.isCheck;
                break;
              case "Program Name":
                this.settingForm.ProgramFlag = item.isCheck;
                break;
              case "Part Description":
                this.settingForm.DescriptionFlag = item.isCheck;
                break;
              case "TCM Owner":
                this.settingForm.OwnerFlag = item.isCheck;
                break;
              case "Price":
                this.settingForm.PriceFlag = item.isCheck;
                break;
              case "Currency":
                this.settingForm.CurrencyFlag = item.isCheck;
                break;
              case "Version":
                this.settingForm.VersionFlag = item.isCheck;
                break;
              case "Complete Date":
                this.settingForm.CompleteFlag = item.isCheck;
                break;
              case "Total Sell Price(Report Currency)":
                this.settingForm.TotalSellFlag = item.isCheck;
                break;
              case "Report Currency":
                this.settingForm.ReportFlag = item.isCheck;
                break;
              case "Part Number":
                this.settingForm.PartNumberFlag = item.isCheck;
                break;
            }
          });
          if (!count) {
            this.settingForm.RequestTypeFlag = true;
            this.settingForm.RequesterFlag = true;
            this.settingForm.RegionFlag = true;
            this.settingForm.CommodityFlag = true;
            this.settingForm.ProgramFlag = true;
            this.settingForm.DescriptionFlag = true;
            this.settingForm.OwnerFlag = true;
            this.settingForm.PriceFlag = true;
            this.settingForm.CurrencyFlag = true;
            this.settingForm.VersionFlag = true;
            this.settingForm.CompleteFlag = true;
            this.settingForm.TotalSellFlag = true;
            this.settingForm.ReportFlag = true;
            this.settingForm.PartNumberFlag = true;
          }
          // RM
          res.data.raw_Materials.forEach((item) => {
            if (item.isCheck) this.RmTitleFlag = true;
            switch (item.name) {
              case "Material Description":
                this.settingForm.Rm_Description = item.isCheck;
                break;
              case "Material_Currency":
                this.settingForm.Rm_Currency = item.isCheck;
                break;
              case "From Currency":
                this.settingForm.Rm_FromCurrency = item.isCheck;
                break;
              case "Exchange Rate":
                this.settingForm.Rm_ExchangeRate = item.isCheck;
                break;
              case "vendor":
                this.settingForm.Rm_vendor = item.isCheck;
                break;
              case "Net Material weight":
                this.settingForm.Rm_NetWeight = item.isCheck;
                break;
              case "Gross Material Weight ":
                this.settingForm.Rm_GrossWeight = item.isCheck;
                break;
              case "Unit of Measure":
                this.settingForm.Rm_UnitMeasure = item.isCheck;
                break;
              case "Material Cost Unit of Measure":
                this.settingForm.Rm_MaterialCostMeasure = item.isCheck;
                break;
              case "Country":
                this.settingForm.Rm_Country = item.isCheck;
                break;
              case "Date":
                this.settingForm.Rm_Date = item.isCheck;
                break;
              case "Material Cost":
                this.settingForm.Rm_MaterialCost = item.isCheck;
                break;
              case "Material Scrap Resale Value /Unit of Measure":
                this.settingForm.Rm_MaterialResaleValue = item.isCheck;
                break;
            }
          });
          // PUR
          res.data.purchase_Components.forEach((item) => {
            if (item.isCheck) this.PurTitleFlag = true;
            switch (item.name) {
              case "Item, Material or Process Description":
                this.settingForm.Pur_Description = item.isCheck;
                break;
              case "Purchase_Currency":
                this.settingForm.Pur_Currency = item.isCheck;
                break;
              case "Purchase_From Currency":
                this.settingForm.Pur_FormCurrency = item.isCheck;
                break;
              case "Purchase_Exchange Rate":
                this.settingForm.Pur_ExchangeRate = item.isCheck;
                break;
              case "Purchase_Vendor":
                this.settingForm.Pur_vendor = item.isCheck;
                break;
              case "Purchase_Quantity Per Assembly":
                this.settingForm.Pur_Quantity = item.isCheck;
                break;
              case "Purchase_Unit of Measure":
                this.settingForm.Pur_UnitMeasure = item.isCheck;
                break;
              case "Cost / UoM":
                this.settingForm.Pur_CostUoM = item.isCheck;
                break;
              case "Purchase_Sub-Total Cost":
                this.settingForm.Pur_SubTotal = item.isCheck;
                break;
              case "Price Reference":
                this.settingForm.Pur_PriceReference = item.isCheck;
                break;
            }
          });
          // PRO
          res.data.processings.forEach((item) => {
            if (item.isCheck) this.ProTitleFlag = true;
            switch (item.name) {
              case "Operation Description":
                this.settingForm.Pro_Description = item.isCheck;
                break;
              case "Processing_Currency":
                this.settingForm.Pro_Currency = item.isCheck;
                break;
              case "Processing_From Currency":
                this.settingForm.Pro_FromCurrency = item.isCheck;
                break;
              case "Processing_Exchange Rate":
                this.settingForm.Pro_ExchangeRate = item.isCheck;
                break;
              case "Processing_Vendor":
                this.settingForm.Pro_Vendor = item.isCheck;
                break;
              case "Annual Volume":
                this.settingForm.Pro_AnnualVolume = item.isCheck;
                break;
              case "Set Up Time(Minutes)":
                this.settingForm.Pro_SetTime = item.isCheck;
                break;
              case "of Set Up Persons":
                this.settingForm.Pro_Persons = item.isCheck;
                break;
              case "Pieces Per Lot":
                this.settingForm.Pro_Pieces = item.isCheck;
                break;
              case "Set Up Costs Per Piece":
                this.settingForm.Pro_SetUpCosts = item.isCheck;
                break;
              case "Equipment Description & Size":
                this.settingForm.Pro_EquipmentSize = item.isCheck;
                break;
              case "Quantity Per Assembly":
                this.settingForm.Pro_QuantityAssembly = item.isCheck;
                break;
              case "Pieces/Hour":
                this.settingForm.Pro_PiecesHour = item.isCheck;
                break;
              case "Pieces Per Cycle":
                this.settingForm.Pro_PiecesCycle = item.isCheck;
                break;
              case "Manufacturing Efficiency %":
                this.settingForm.Pro_Efficiency = item.isCheck;
                break;
              case "Standard Hour":
                this.settingForm.Pro_StandardHour = item.isCheck;
                break;
              case "Direct Labor Wage Rate":
                this.settingForm.Pro_LaborWageRate = item.isCheck;
                break;
              case "Set Up Labor Wage Rate":
                this.settingForm.Pro_SetUpWageRate = item.isCheck;
                break;
              case "No. of Direct Operators":
                this.settingForm.Pro_DirectOperators = item.isCheck;
                break;
              case "Direct Labor Cost/ Assembly":
                this.settingForm.Pro_DirectLaborCost = item.isCheck;
                break;
              case "Burden Variable Rate / hr":
                this.settingForm.Pro_VariableRate = item.isCheck;
                break;
              case "Burden Fixed Rate / hr":
                this.settingForm.Pro_FixedRate = item.isCheck;
                break;
              case "Burden Depreciation Rate / hr":
                this.settingForm.Pro_DepreciationRate = item.isCheck;
                break;
              case "Total Burden Rate / hr":
                this.settingForm.Pro_TotalBurdenRate = item.isCheck;
                break;
              case "Processing_Burden Cost":
                this.settingForm.Pro_Processing_Burden = item.isCheck;
                break;
              case "Sub-Total Cost":
                this.settingForm.Pro_SubTotal = item.isCheck;
                break;
              case "Process Scrap%":
                this.settingForm.Pro_ProcessScrap = item.isCheck;
                break;
            }
          });
          this.FilterColumn();
          let PLArr = [];
          res.data.p_Ls.forEach((item) => {
            if (item.isCheck) this.PLTitleFlag = true;
            PLArr.push({
              key: item.name,
              value: this.FilterValue(item.name),
              isCheck: item.isCheck,
            });
          });
          this.PLColumn = PLArr;
          // oh
          let OHArr = [];
          res.data.oHs.forEach((item) => {
            if (item.isCheck) this.OHTitleFlag = true;
            OHArr.push({
              key: item.name,
              value: this.FilterValue(item.name),
              isCheck: item.isCheck,
            });
          });
          this.OHColumn = OHArr;
          // sum
          let SumArr = [];
          res.data.cost_Summarys.forEach((item) => {
            if (item.isCheck) this.SumTitleFlag = true;
            SumArr.push({
              key: item.name,
              value: this.FilterValue(item.name),
              isCheck: item.isCheck,
            });
          });
          this.SumColumn = SumArr;
        }
      });
    },
    FilterValue(name) {
      switch (name) {
        // PL
        case "Freight Cost 2":
          return "freightCost";
        case "Ware Housing Cost":
          return "wareHouseCost";
        case "Packaging Cost":
          return "packageCost";
        case "Customs, Duties, Taxes & Fees":
          return "otherCost";
        case "Absorbed Description (Resinking, Perpectual tooling)":
          return "amortizationDescription";
        case "Total Absorbed Cost(Resinking Cost,Perpectual Tooling)":
          return "totalAmortize";
        case "Total Pieces Absorbed(Pieces per Resink, Pieces per perpectual tool)":
          return "totalPieces";
        case "P&L_Absorbed Cost":
          return "amortizeCost";
        // oh
        case "Profit on Purchased Components %":
          return "purchaseProfit";
        case "Total Profit Cost":
          return "totalProfitCost";
        case "Total Profit %":
          return "totalProfit";
        case "Material/PC Scrap %":
          return "materialPC";
        case "SG&A %":
          return "sga";
        case "SG&A Cost":
          return "sgaCost";
        case "Profit on Material %":
          return "materialProfit";
        case "Profit on Value Add %":
          return "addProfit";
        // sum
        case "Raw Material Cost":
          return "materialCost_SUM";
        case "Purchased Components Cost":
          return "purchaseCost_SUM";
        case "Direct Labor Cost":
          return "laborCost_SUM";
        case "Burden Cost":
          return "burdenCost_SUM";
        case "Set Up Cost":
          return "setUpCost_SUM";
        case "TOTAL Manufacturing Cost":
          return "manufacturingCost_PRO";
        case "Packaging & Freight Cost":
          return "packageCost_SUM";
        case "Absorbed Cost + Customs, Duties, Taxes & Fees":
          return "otherCost_SUM";
        case "Absorbed Cost":
          return "amortizeCost_SUM";
        case "Process Scrap Cost":
          return "scrapCost_SUM";
        case "SG&A and Profit":
          return "sgaProfit_SUM";
        case "Total Sell Price(Currency)":
          return "totalPriceCurr_SUM";
        case "Total Sell Price ( Report Currency)":
          return "totalPriceReport_SUM";
      }
    },
    RowClick(row) {
      this.showDetail(row);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    getUnits() {
      this.timers = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        this.statusOptions = [
          {
            value: -1,
            label: "All",
          },
          {
            value: 0,
            label: "Enable",
          },
          {
            value: 1,
            label: "Disable",
          },
        ];
      } else {
        this.statusOptions = [
          {
            value: -1,
            label: "全部",
          },
          {
            value: 0,
            label: "激活",
          },
          {
            value: 1,
            label: "禁用",
          },
        ];
      }
      clearInterval(this.timers);
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加") {
          this.btnAdd = 1;
        }
        if (e.name == "删除") {
          this.btnDel = 1;
        }
        if (e.name == "编辑") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    getList() {
      this.searchForm = {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        StartDate_Approved: "",
        EndDate_Approved: "",
        status: 6,
        Keyword: "",
        priceType: "",
        PageType: 5,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 0,
        isDisable: 0,
        partNumber: "",
        programName: "",
      };
      this.search();
    },
    search() {
      GetCostPartLoad(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
          this.GetSettingList();
        }
      });
    },
    FilterColumn() {
      this.RmColumn = [];
      this.PurColumn = [];
      this.ProColumn = [];
      // RM
      // var maxLength = this.tableData[0].materialList.length;
      // 先算出数据中materialList数量最多的一项
      var maxLength = 0;
      let RmData = {
        materialList: [
          {
            description: "",
            currencyName: "",
            convertCurrencyName: "",
            converRate: "",
            vendor: "",
            netMWeight: "",
            grossMWeight: "",
            unitMeasureName: "",
            unitCost: "",
            country: "",
            date: "",
            cost: "",
            unitResaleValue: "",
          },
        ],
      };
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].materialList.length > maxLength) {
          maxLength = this.tableData[i].materialList.length;
          RmData = this.tableData[i];
        }
      }
      RmData.materialList.forEach((item, index) => {
        let arr = [
          {
            key: "Material Description" + "(" + (index + 1) + ")",
            value: "description",
            index: index,
            isCheck: this.settingForm.Rm_Description,
          },
          {
            key: "Material_Currency" + "(" + (index + 1) + ")",
            value: "currencyName",
            index: index,
            isCheck: this.settingForm.Rm_Currency,
          },
          {
            key: "From Currency" + "(" + (index + 1) + ")",
            value: "convertCurrencyName",
            index: index,
            isCheck: this.settingForm.Rm_FromCurrency,
          },
          {
            key: "Exchange Rate" + "(" + (index + 1) + ")",
            value: "converRate",
            index: index,
            isCheck: this.settingForm.Rm_ExchangeRate,
          },
          {
            key: "vendor" + "(" + (index + 1) + ")",
            value: "vendor",
            index: index,
            isCheck: this.settingForm.Rm_vendor,
          },
          {
            key: "Net Material weight" + "(" + (index + 1) + ")",
            value: "netMWeight",
            index: index,
            isCheck: this.settingForm.Rm_NetWeight,
          },
          {
            key: "Gross Material Weight" + "(" + (index + 1) + ")",
            value: "grossMWeight",
            index: index,
            isCheck: this.settingForm.Rm_GrossWeight,
          },
          {
            key: "Unit of Measure" + "(" + (index + 1) + ")",
            value: "unitMeasureName",
            index: index,
            isCheck: this.settingForm.Rm_UnitMeasure,
          },
          {
            key: "Material Cost Unit of Measure" + "(" + (index + 1) + ")",
            value: "unitCost",
            index: index,
            isCheck: this.settingForm.Rm_MaterialCostMeasure,
          },
          {
            key: "Country" + "(" + (index + 1) + ")",
            value: "country",
            index: index,
            isCheck: this.settingForm.Rm_Country,
          },
          {
            key: "Date" + "(" + (index + 1) + ")",
            value: "date",
            index: index,
            isCheck: this.settingForm.Rm_Date,
          },
          {
            key: "Material Cost" + "(" + (index + 1) + ")",
            value: "cost",
            index: index,
            isCheck: this.settingForm.Rm_MaterialCost,
          },
          {
            key:
              "Material Scrap Resale Value /Unit of Measure" +
              "(" +
              (index + 1) +
              ")",
            value: "unitResaleValue",
            index: index,
            isCheck: this.settingForm.Rm_MaterialResaleValue,
          },
        ];
        this.RmColumn = this.RmColumn.concat(arr);
      });

      // PUR
      var PurMaxlength = 0;
      let PurData = {
        purchaseList: [
          {
            name: "",
            currencyName: "",
            convertCurrencyName: "",
            converRate: "",
            vendor: "",
            quantity: "",
            unitMeasureName: "",
            unitCost: "",
            totalCost: "",
            price: "",
          },
        ],
      };
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].purchaseList.length > PurMaxlength) {
          PurMaxlength = this.tableData[i].purchaseList.length;
          PurData = this.tableData[i];
        }
      }
      PurData.purchaseList.forEach((item, index) => {
        let arr = [
          {
            key:
              "Item, Material or Process Description" + "(" + (index + 1) + ")",
            value: "name",
            index: index,
            isCheck: this.settingForm.Pur_Description,
          },
          {
            key: "Purchase_Currency" + "(" + (index + 1) + ")",
            value: "currencyName",
            index: index,
            isCheck: this.settingForm.Pur_Currency,
          },
          {
            key: "Purchase_From Currency" + "(" + (index + 1) + ")",
            value: "convertCurrencyName",
            index: index,
            isCheck: this.settingForm.Pur_FormCurrency,
          },
          {
            key: "Purchase_Exchange Rate" + "(" + (index + 1) + ")",
            value: "converRate",
            index: index,
            isCheck: this.settingForm.Pur_ExchangeRate,
          },
          {
            key: "Purchase_Vendor" + "(" + (index + 1) + ")",
            value: "vendor",
            index: index,
            isCheck: this.settingForm.Pur_vendor,
          },
          {
            key: "Purchase_Quantity Per Assembly" + "(" + (index + 1) + ")",
            value: "quantity",
            index: index,
            isCheck: this.settingForm.Pur_Quantity,
          },
          {
            key: "Purchase_Unit of Measure" + "(" + (index + 1) + ")",
            value: "unitMeasureName",
            index: index,
            isCheck: this.settingForm.Pur_UnitMeasure,
          },
          {
            key: "Cost / UoM" + "(" + (index + 1) + ")",
            value: "unitCost",
            index: index,
            isCheck: this.settingForm.Pur_CostUoM,
          },
          {
            key: "Purchase_Sub-Total Cost" + "(" + (index + 1) + ")",
            value: "totalCost",
            index: index,
            isCheck: this.settingForm.Pur_SubTotal,
          },
          {
            key: "Price Reference" + "(" + (index + 1) + ")",
            value: "price",
            index: index,
            isCheck: this.settingForm.Pur_PriceReference,
          },
        ];
        this.PurColumn = this.PurColumn.concat(arr);
      });
      // PRO
      var ProMaxlength = 0;
      let ProData = {
        processList: [
          {
            name: "",
            currencyName: "",
            convertCurrencyName: "",
            converRate: "",
            vendor: "",
            annualVolume: "",
            setTime: "",
            setPerson: "",
            pieceLot: "",
            pieceCost: "",
            equSize: "",
            quantity: "",
            pieceHour: "",
            pieceCycle: "",
            efficiencyPercent: "",
            standardHour: "",
            directRate: "",
            setUpRate: "",
            directOperateQty: "",
            assemblyDirectCost: "",
            variableRate: "",
            fixedRate: "",
            depreciationRate: "",
            assemblyBurdenCost: "",
            totalCost: "",
            scrapPercent: "",
          },
        ],
      };
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].processList.length > ProMaxlength) {
          ProMaxlength = this.tableData[i].processList.length;
          ProData = this.tableData[i];
        }
      }
      ProData.processList.forEach((item, index) => {
        let arr = [
          {
            key: "Description" + "(" + (index + 1) + ")",
            value: "name",
            index: index,
            isCheck: this.settingForm.Pro_Description,
          },
          {
            key: "Currency" + "(" + (index + 1) + ")",
            value: "currencyName",
            index: index,
            isCheck: this.settingForm.Pro_Currency,
          },
          {
            key: "From Currency" + "(" + (index + 1) + ")",
            value: "convertCurrencyName",
            index: index,
            isCheck: this.settingForm.Pro_FromCurrency,
          },
          {
            key: "Exchange Rate" + "(" + (index + 1) + ")",
            value: "converRate",
            index: index,
            isCheck: this.settingForm.Pro_ExchangeRate,
          },
          {
            key: "vendor" + "(" + (index + 1) + ")",
            value: "vendor",
            index: index,
            isCheck: this.settingForm.Pro_Vendor,
          },
          {
            key: "Annual Volume" + "(" + (index + 1) + ")",
            value: "annualVolume",
            index: index,
            isCheck: this.settingForm.Pro_AnnualVolume,
          },
          {
            key: "Set Up Time(Minutes)" + "(" + (index + 1) + ")",
            value: "setTime",
            index: index,
            isCheck: this.settingForm.Pro_SetTime,
          },
          {
            key: "of Set Up Persons" + "(" + (index + 1) + ")",
            value: "setPerson",
            index: index,
            isCheck: this.settingForm.Pro_Persons,
          },
          {
            key: "Pieces Per Lot" + "(" + (index + 1) + ")",
            value: "pieceLot",
            index: index,
            isCheck: this.settingForm.Pro_Pieces,
          },
          {
            key: "Set Up Costs Per Piece" + "(" + (index + 1) + ")",
            value: "pieceCost",
            index: index,
            isCheck: this.settingForm.Pro_SetUpCosts,
          },
          {
            key: "Equipment Description & Size" + "(" + (index + 1) + ")",
            value: "equSize",
            index: index,
            isCheck: this.settingForm.Pro_EquipmentSize,
          },
          {
            key: "Quantity Per Assembly" + "(" + (index + 1) + ")",
            value: "quantity",
            index: index,
            isCheck: this.settingForm.Pro_QuantityAssembly,
          },
          {
            key: "Pieces/Hour" + "(" + (index + 1) + ")",
            value: "pieceHour",
            index: index,
            isCheck: this.settingForm.Pro_PiecesHour,
          },
          {
            key: "Pieces Per Cycle" + "(" + (index + 1) + ")",
            value: "pieceCycle",
            index: index,
            isCheck: this.settingForm.Pro_PiecesCycle,
          },
          {
            key: "Manufacturing Efficiency %" + "(" + (index + 1) + ")",
            value: "efficiencyPercent",
            index: index,
            isCheck: this.settingForm.Pro_Efficiency,
          },
          {
            key: "Standard Hour" + "(" + (index + 1) + ")",
            value: "standardHour",
            index: index,
            isCheck: this.settingForm.Pro_StandardHour,
          },
          {
            key: "Direct Labor Wage Rate" + "(" + (index + 1) + ")",
            value: "directRate",
            index: index,
            isCheck: this.settingForm.Pro_LaborWageRate,
          },
          {
            key: "Set Up Labor Wage Rate" + "(" + (index + 1) + ")",
            value: "setUpRate",
            index: index,
            isCheck: this.settingForm.Pro_SetUpWageRate,
          },
          {
            key: "No. of Direct Operators" + "(" + (index + 1) + ")",
            value: "directOperateQty",
            index: index,
            isCheck: this.settingForm.Pro_DirectOperators,
          },
          {
            key: "Direct Labor Cost/ Assembly" + "(" + (index + 1) + ")",
            value: "assemblyDirectCost",
            index: index,
            isCheck: this.settingForm.Pro_DirectLaborCost,
          },
          {
            key: "Burden Variable Rate / hr" + "(" + (index + 1) + ")",
            value: "variableRate",
            index: index,
            isCheck: this.settingForm.Pro_VariableRate,
          },
          {
            key: "Burden Fixed Rate / hr" + "(" + (index + 1) + ")",
            value: "fixedRate",
            index: index,
            isCheck: this.settingForm.Pro_FixedRate,
          },
          {
            key: "Burden Depreciation Rate / hr" + "(" + (index + 1) + ")",
            value: "depreciationRate",
            index: index,
            isCheck: this.settingForm.Pro_DepreciationRate,
          },
          {
            key: "Total Burden Rate / hr" + "(" + (index + 1) + ")",
            value: "totalBurdenRate",
            index: index,
            isCheck: this.settingForm.Pro_TotalBurdenRate,
          },
          {
            key: "Processing_Burden Cost" + "(" + (index + 1) + ")",
            value: "assemblyBurdenCost",
            index: index,
            isCheck: this.settingForm.Pro_Processing_Burden,
          },
          {
            key: "Sub-Total Cost" + "(" + (index + 1) + ")",
            value: "totalCost",
            index: index,
            isCheck: this.settingForm.Pro_SubTotal,
          },
          {
            key: "Process Scrap%" + "(" + (index + 1) + ")",
            value: "scrapPercent",
            index: index,
            isCheck: this.settingForm.Pro_ProcessScrap,
          },
        ];
        this.ProColumn = this.ProColumn.concat(arr);
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 切换时间
    changeDate(val) {
      if (val != null) {
        // this.searchForm.StartDate = val[0]
        // this.searchForm.EndDate = val[1]
        this.searchForm.StartDate_Approved = val[0];
        this.searchForm.EndDate_Approved = val[1];
      } else {
        this.searchForm.StartDate_Approved = "";
        this.searchForm.EndDate_Approved = "";
      }
    },
    // 	// 新增
    // addEntry() {
    // 	this.$router.push({
    // 		path: '/tcmAdd',
    // 		name: 'TcmAdd',
    // 		query: {
    // 			type: 'add',
    // 		}
    // 	})
    // },
    // 		// 删除
    // deleteApp() {
    // 	console.log(this.chooseData)
    // 	if (this.chooseData.length == 0) {
    // 		commonUtil.showTipInfo(this.$t('userlists.userlist.selecWant'), '', 'warning');
    // 		return;
    // 	}
    // 	let ids = []
    // 	this.chooseData.forEach(function(val, index){
    // 		ids.push(val.id)
    // 	})
    // 	deleteApplyDetail(ids, response=>{
    // 		if (response && response.code == 200) {
    // 			commonUtil.showTipInfo(this.$t('unifiedPrompts.unifiedPrompt.deleteSuccessfully'), '', 'success')
    // 			this.search()
    // 		}
    // 	})
    // },
    // 编辑
    // edit(id) {
    // 	this.$router.push({
    // 		path: '/addOperate',
    // 		name: 'AddOperate',
    // 		query: {
    // 			type: 'edit',
    // 			id: id
    // 		}
    // 	})
    // },
    // 详情
    showDetail(row) {
      this.$router.push({
        path: "/tcmResult",
        name: "tcmResult",
        query: {
          id: row.costApplyId,
          type: "detail",
          flag: "tcmdata",
          crumbs: ["TCM Data", "Detail"],
        },
      });
      // }
    },
    EditBtn(row) {
      this.$router.push({
        path: "/EditTcmData",
        name: "EditTcmData",
        query: {
          id: row.costApplyId,
          type: "detail",
        },
      });
    },
    // 导入
    importData(files) {
      let format = new FormData();
      format.append("file", files.raw);
      format.append("Type", 5);
      let excelFormat = new FormData();
      excelFormat.append("file", files.raw);
      GetDataIsDuplicate(format, (response) => {
        if (response && response.code == 200) {
          // if (response.data) {
          if (response.data == "") {
            excelFormat.append("DataIsDuplicate", "");
            this.importExcel(excelFormat, files);
          } else {
            // 有重复数据
            ElMessageBox.confirm(
              response.data +
                this.$t("unifiedPrompts.unifiedPrompt.dupImportQue")
            )
              .then(() => {
                excelFormat.append("DataIsDuplicate", "");
                this.isContinue = true;
                this.importExcel(excelFormat, files);
                done();
              })
              .catch(() => {
                if (!this.isContinue) {
                  excelFormat.append("DataIsDuplicate", response.data);
                  this.importExcel(excelFormat, files);
                }
                this.isContinue = false;
                // catch error
              });
          }
          // } else {
          // 	commonUtil.showTipInfo(response.message,"", "error");
          // 	this.getList()
          // 	this.$refs.upload.handleRemove(files)
          // }
        }
      });
    },
    // 导入
    importExcel(format, files) {
      ImportTCMDataBaseExcel(
        format,
        (response) => {
          if (response && response.code == 200) {
            this.getList();
            this.$refs.upload.handleRemove(files);
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importSuccessfully"),
              "",
              "success"
            );
          }
        },
        (errRes) => {
          if (errRes && errRes.code == 500) {
            ExportDataTableExcel('"' + errRes.data + '"', (res) => {});
          } else {
            commonUtil.showTipInfo(
              this.$t("unifiedPrompts.unifiedPrompt.importFail"),
              "",
              "error"
            );
          }
          this.getList();
          this.$refs.upload.handleRemove(files);
        }
      );
    },
    // // 导入
    // importData(files) {
    // 	console.log('files', files)
    // 	let format = new FormData()
    // 	format.append('file', files.raw)
    // 	format.append('Type', 1)
    // 	console.log(format,1111111111111);
    // 	ImportTCMDataBaseExcel(format, response=>{
    // 		if (response && response.code == 200) {
    // 			this.getList()
    // 			this.$refs.upload.clearFiles()
    // 			commonUtil.showTipInfo(this.$t('unifiedPrompts.unifiedPrompt.importSuccessfully'), "", "success");
    // 		}
    // 	}, errRes=>{
    // 		console.log(errRes)
    // 		if (errRes && errRes.code == 500) {
    // 			ExportDataTableExcel('"' + errRes.data + '"', res=>{
    // 				console.log(res)
    // 			})
    // 		} else {
    // 			commonUtil.showTipInfo(this.$t('unifiedPrompts.unifiedPrompt.importFail') ,"", "error");
    // 		}
    // 		this.getList()
    // 		this.$refs.upload.clearFiles()
    // 	})
    // },
    // 表格多选
    handleSelectionChange(val) {
      this.chooseData = val;
    },
    // 导出
    // exportData() {
    // 	console.log(this.chooseData)
    // 	if (this.chooseData.length == 0) {
    // 		commonUtil.showTipInfo(this.$t('userlists.userlist.selecWant'), '', 'warning')
    // 		return;
    // 	}
    // 	let ids = []
    // 	this.chooseData.forEach(function(val, key) {
    // 		ids.push(val.id)
    // 	})
    // 	ExportTCMDataBaseExcel(ids, response=>{

    // 	})
    // },
    // 导出(改版)
    exportData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.costApplyId);
      });
      ExportTCMDataBaseExcelNew(ids, (response) => {});
    },
    // 跳转临时数据列表
    importList() {
      this.$router.push({
        path: "/importList",
        name: "importList",
      });
    },
    // 跳转改价列表
    changePrice() {
      this.$router.push({
        path: "/changePriceList",
        name: "ChangePriceList",
      });
    },
    // 批量操作
    bathOperate(type) {
      if (this.userAccount != "System") {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.onlySystemOperate"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.costApplyId);
      });
      if (type == "delete") {
        this.deleteTem(ids);
      } else if (type == "start") {
        // 启用
        tcmDataDisableByIds(
          {
            ids: ids,
            disable: 0,
          },
          (response) => {
            commonUtil.showTipInfo(
              this.$t("ccls.ccl.enableSuccess"),
              "",
              "success"
            );
            this.search();
          }
        );
      } else if (type == "stop") {
        // 禁用
        tcmDataDisableByIds(
          {
            ids: ids,
            disable: 1,
          },
          (response) => {
            commonUtil.showTipInfo(
              this.$t("ccls.ccl.disableSuccess"),
              "",
              "success"
            );
            this.search();
          }
        );
      }
    },
    // 关闭选择对比数据弹窗
    handleClose(done) {
      done();
      // this.$confirm('确认关闭？')
      // .then(_ => {
      //     done();
      // })
      // .catch(_ => {});
    },
    cancelDrawer() {
      this.drawer = false;
      clearTimeout(this.timer);
    },
    // 去对比
    toCompare(row) {
      if (this.compareList.indexOf(row) > -1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.compareNotRepeat"),
          "",
          "warning"
        );
      } else if (this.compareList.length == 5) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.compareFive"),
          "",
          "warning"
        );
      } else if (this.compareList.indexOf(row) < 0) {
        this.compareList.push(row);
      }
      this.startCompare();
      // this.drawer = true
    },
    // 取消对比
    cancleCompare(item) {
      this.compareList.splice(this.compareList.indexOf(item), 1);
    },
    // 开始对比
    startCompare() {
      if (this.compareList.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.compareList.forEach(function (val, key) {
        ids.push(val.costApplyId);
      });
      this.$router.push({
        path: "/tcmCompare",
        name: "TcmCompare",
        query: {
          ids: JSON.stringify(ids),
        },
      });
    },
    OpenExportFormFlag() {
      let powers = JSON.parse(localStorage.getItem("translate"));
      this.$refs.ExportForm.powers =
        powers == "en" || powers == null ? "en" : "zh";
      this.$refs.ExportForm.visible = true;
      this.$refs.ExportForm.GetSettingList();
    },
    ExportFormFun() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, key) {
        ids.push(val.costApplyId);
      });
      DownloadTcmDataExcel(ids, (response) => {});
    },
  },
};
</script>
<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}
.main-top_handle {
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.main-handle {
  width: 80%;
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}

.main-handle /deep/ .el-input__inner::placeholder {
  font-size: 14px;
}

.main-handle input::placeholder {
  font-size: 14px;
  color: #a8abb2;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
    span {
      color: #a8abb2;
      font-size: 14px;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
:deep(.el-scrollbar__bar.is-horizontal) {
  height: 15px;
}
</style>