<template>
  <el-dialog
    v-model="visible"
    width="1200px"
    :destroy-on-close="true"
    :show-close="false"
    @close="close"
  >
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">{{ $t("userlists.userlist.assignModules") }}</div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="tree-cla">
            <el-tree
              :empty-text="$t('tcmlists.tcmlist.noData')"
              ref="treeRef"
              class="filter-tree"
              :data="treedata"
              node-key="id"
              :props="defaultProps"
              default-expand-all
              show-checkbox
              :check-strictly="true"
              :default-checked-keys="idArr"
              @check-change="handleCheckChange"
              @node-click="handleNodeClick"
            />
          </div>
        </el-col>
        <el-col :span="16">
          <el-table
            :empty-text="$t('tcmlists.tcmlist.noData')"
            ref="buttonRef"
            :data="buttonList"
            style="width: 100%"
            @select="handleSelectionChange"
            @select-all="selectAll"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column
              property="name"
              :label="$t('userlists.userlist.existingMenu')"
            />
          </el-table>
        </el-col>
      </el-row>
    </div>
    <!-- <template #footer>
			<span class="dialog-footer">
				<el-button @click="close">Cancel</el-button>
				<el-button color="#335883" style="margin-right: 16px" @click="handleSubmit">Confirm</el-button>
			</span>
		</template> -->
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
import {
  moudleRole,
  assignMoudle,
  lookmoudleRole,
  cancelMoudle,
  butttonList,
  echoButton,
} from "@/assets/js/requestApi.js";
export default {
  props: ["roleId"],
  emits: ["handleSubmit"],
  data() {
    return {
      powers: "",
      visible: false,
      defaultProps: {
        children: "children",
        label: "name",
      },
      treedata: [],
      form: {
        type: "RoleModule",
        firstId: "",
        "secIds[]": "",
      },
      idArr: [],
      showIds: [],
      showLength: 0,
      uuuu: false,
      buttonList: [],
      echoList: [],
    };
  },
  mounted() {
    // this.getTree()
  },
  methods: {
    close() {
      this.idArr = [];
      this.showIds = [];
      this.showLength = 0;
      this.visible = false;
      this.$parent.search();
    },

    // 树
    handleCheckChange(data, checked, indeterminate) {
      this.showLength++;
      if (checked == true) {
        if (this.showIds.indexOf(data.id) < 0) {
          assignMoudle(
            {
              type: "RoleModule",
              firstId: this.roleId,
              secIds: [data.id],
            },
            (response) => {
              if (response && response.code == 200) {
                commonUtil.showTipInfo(
                  this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
                  "",
                  "success"
                );
              }
            }
          );
        }
      }
      if (checked == false) {
        if (this.showIds.indexOf(data.id) < 0) {
          cancelMoudle(
            {
              type: "RoleModule",
              firstId: this.roleId,
              secIds: [data.id],
            },
            (response) => {
              if (response && response.code == 200) {
                commonUtil.showTipInfo("取消成功", "", "success");
              }
            }
          );
        }
      }
      if (this.showLength == this.showIds.length) {
        this.showIds = [];
      }
    },
    // 树点击
    handleNodeClick(e) {
      if (e.id != "") {
        butttonList({ moduleId: e.id }, (response) => {
          if (response && response.code == 200 && this.powers == "zh") {
            this.buttonList = response.result;
            console.log(this.buttonList, "列表数据");
            // 回显菜单
            echoButton({ moduleId: e.id, firstId: this.roleId }, (response) => {
              if (response && response.code == 200) {
                this.echoList = response.result;
                console.log(this.echoList, "勾选数据");
                for (let i = 0; i < this.echoList.length; i++) {
                  for (let j = 0; j < this.buttonList.length; j++) {
                    if (this.echoList[i].id == this.buttonList[j].id) {
                      this.$refs.buttonRef.toggleRowSelection(
                        this.buttonList[j],
                        true
                      );
                    }
                  }
                  console.log(this.echoList[i]);
                  console.log(this.$refs.buttonRef);
                }
              }
            });
          } else {
            response.result.forEach((e) => {
              if (e.name == "删除" || e.name == "Delete") {
                e.name = "Delete";
                console.log(e.name);
              }
              if (e.name == "添加" || e.name == "Add") {
                e.name = "Add";
                console.log(e.name);
              }
              if (e.name == "编辑" || e.name == "Edit") {
                e.name = "Edit";
                console.log(e.name);
              }
              if (e.name == "预览" || e.name == "Preview") {
                e.name = "Preview";
              }
            });
            this.buttonList = response.result;
            console.log(this.buttonList, "列表数据");
            // 回显菜单
            echoButton({ moduleId: e.id, firstId: this.roleId }, (response) => {
              if (response && response.code == 200) {
                this.echoList = response.result;
                console.log(this.echoList, "勾选数据");
                for (let i = 0; i < this.echoList.length; i++) {
                  for (let j = 0; j < this.buttonList.length; j++) {
                    if (this.echoList[i].id == this.buttonList[j].id) {
                      this.$refs.buttonRef.toggleRowSelection(
                        this.buttonList[j],
                        true
                      );
                    }
                  }
                  console.log(this.echoList[i]);
                  console.log(this.$refs.buttonRef);
                }
              }
            });
          }
        });
      }
    },
    // 分配按钮
    handleSelectionChange(selection, row) {
      console.log(selection, row);
      let selected = selection.length && selection.indexOf(row) !== -1;
      // this.showLength++
      if (selected) {
        assignMoudle(
          {
            type: "RoleElement",
            firstId: this.roleId,
            secIds: [row.id],
          },
          (response) => {
            if (response && response.code == 200) {
              commonUtil.showTipInfo(
                this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
                "",
                "success"
              );
            }
          }
        );
      } else {
        cancelMoudle(
          {
            type: "RoleElement",
            firstId: this.roleId,
            secIds: [row.id],
          },
          (response) => {
            if (response && response.code == 200) {
              commonUtil.showTipInfo("取消成功", "", "success");
            }
          }
        );
      }
    },

    selectAll() {
      this.$refs.buttonRef.clearSelection();
    },
    // 提交
    handleSubmit() {
      this.visible = false;
      this.$emit("handleSubmit", "");
    },

    // 树接口
    getTree() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "zh") {
        moudleRole(
          { IsPhaseII: sessionStorage.getItem("phaseII") == 2 ? 1 : 0 },
          (response) => {
            if (response && response.code == 200) {
              let treedata = response.result;
              this.treedata = this.transferList(response.result);
              this.$refs.treeRef.setCheckedKeys([], false);
              // 查看已分配模块数据
              lookmoudleRole(
                {
                  firstId: this.roleId,
                },
                (response) => {
                  if (response && response.code == 200) {
                    for (let i = 0; i < treedata.length; i++) {
                      for (let j = 0; j < response.result.length; j++) {
                        if (
                          treedata[i].id == response.result[j].id &&
                          (response.result[j].parentId != null ||
                            (response.result[j].parentId == null &&
                              response.result[j].parentName == "根节点"))
                        ) {
                          this.idArr.push(treedata[i]);
                          this.showIds.push(treedata[i].id);
                        }
                      }
                    }

                    this.$refs.treeRef.setCheckedNodes(this.idArr);
                  }
                }
              );
            }
          }
        );
      } else {
        moudleRole(
          { IsPhaseII: sessionStorage.getItem("phaseII") == 2 ? 1 : 0 },
          (response) => {
            if (response && response.code == 200) {
              response.result.forEach((e) => {
                e.name = e.nameEn;
              });
              let treedata = response.result;
              this.treedata = this.transferList(response.result);
              this.$refs.treeRef.setCheckedKeys([], false);
              // 查看已分配模块数据
              lookmoudleRole(
                {
                  firstId: this.roleId,
                },
                (response) => {
                  if (response && response.code == 200) {
                    for (let i = 0; i < treedata.length; i++) {
                      for (let j = 0; j < response.result.length; j++) {
                        if (
                          treedata[i].id == response.result[j].id &&
                          (response.result[j].parentId != null ||
                            (response.result[j].parentId == null &&
                              response.result[j].parentName == "根节点"))
                        ) {
                          this.idArr.push(treedata[i]);
                          this.showIds.push(treedata[i].id);
                        }
                      }
                    }

                    this.$refs.treeRef.setCheckedNodes(this.idArr);
                  }
                }
              );
            }
          }
        );
      }
      this.buttonList = [];
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 数据转化
    transferList(data) {
      const res = [];
      data.forEach((item) => {
        const parent = data.find((node) => node.id === item.parentId);
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(item);
        } else {
          // * 根节点
          res.push(item);
        }
      });
      return res;
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tcm-table {
  height: 600px;
  overflow: auto;
}
.size-16 {
  font-size: 16px;
}
</style>
