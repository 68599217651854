const info = {
    // 中文
    ZH: {
        //计算机
        supplier: '供应商',
        Layers: '板层',
        innerThickness: '内层铜厚',
        outerThickness: '外层铜厚',
        material: 'TG',
        prepreg: '半固态片',
        viaFilling: '塞孔',
        soldermask: '防焊（绿漆）',
        silkscreen: '文印',
        depanel: '分板',
        surfaceArtwork: '表面处理',
        numberHoles: '通孔数量',
        lengthPcb: 'PCB 长度',
        widthPcb: 'PCB 宽度',
        ViasDiameter: '通过直径',
        workingLength: '完成板长度（mm）',
        workingWidth: '完成板宽度（mm）',
        panelization: "拼版数量",
        otherCost: '其他间接成本',
        directCost: '直接材料成本',
        processCost: '工艺成本',
        logisticsCost: '物流成本',
        managementCharge: '管理费用',
        cclSize: 'CCL 尺寸',
        cclLength: 'CCL长度(mm)',
        cclWidth: 'CCL宽度(mm)',
        totalCost: '总成本/m2',
        totalCostCCL: '总成本/Selected CCL',
        pricePc: '单价',
        pcbQtyAll: "PCB数量 (PCS)",
        // CCL 尺寸
        code: '编码',
        batchEnable: '分批激活',
        batchDisabling: '分批取消',
        operate: '操作',
        enable: '激活',
        // Direct Material
        cclType: 'CCL 类型',
        lamination: 'Kingboard',
        syLamination: 'Shenyi',
        // 间接工序
        process: '工序',
        depreciation: '另加成本',
        indirectMaterial: '间接材料',
        directOperator: '直接操作员成本',
        totalCosts: '汇总成本',
        item: '项目',
        disable: '是否禁用',
        processName: '工艺名称',
        cancel: '取消',
        confirm: '确定',
        ok: '确定',
        pleaseOperation: '请确认是否撤回操作?',
        pleaseReject: '请确认是否退回申请？',
        pleaseRework: '请确认是否打回申请？',
        submit: '提交',
        NewCalculator: '新建工艺计算器',
        chooseCalculator: '选择工艺计算器',
        name: '名称',
        nameEn: '英文名称',
        nameSpain: '菜单西班牙名称',
        namePoland: '菜单波兰名称',
        isPhaseII: '是否为二期模块',
        commodity: '商品分类',
        version: '版本',
        comments: '备注',
        pictures: '图片',
        upload: '上传',
        input: '输入',
        defaultValue: '初始值',
        unit: '单位',
        formula: '公式',
        operate: '操作',
        output: '输出',
        modify: '修改',
        saveType: '保存类型',
        addType: '新增类型',
        editType: '编辑类型',
        currency: '货币',
        cnyCurrency: '转换货币',
        exchangeRate: '汇率',
        saveRegion: '保持区域',
        addRegion: '新增区域',
        editRegion: '编辑区域',
        addRate: '新增税率',
        editRate: '编辑税率',
        saveCurrency: '保存货币',
        addCurrency: '新增货币',
        editCurrency: '编辑货币',
        symbol: '货币符号',
        addRole: '添加角色',
        roleName: '角色名称',
        roleNameEn: '角色英文名称',
        editRole: '编辑角色',
        roleStatus: '角色状态',

        addGroup: '添加用户组',
        userGroupName: '用户组名称',
        tcmPersonnel: 'TCM 操作人员',
        tcmApplicants: 'TCM 申请人',
        editGroup: '编辑用户组',

        addOrganization: '添加组织',
        editOrganization: '编辑组织',
        organizationName: '组织名称',
        sequence: '序列',
        department: '部门',

        import: '导入',
        export: '导出',
        dataImport: '数据导入',
        dataExport: '数据导出',
        newModule: '新增模块',
        newModules: '新建按钮',
        deleteBtn: '删除按钮',
        menuBtn: '菜单按钮',
        search: '搜索',
        editMenu: '编辑按钮',
        enables: '激活',
        newButton: '新建按钮',
        editButton: '编辑按钮',
        newMenu: '新增菜单',
        menuName: '菜单名称',
        buttonName: '按钮名称',
        buttonId: '按钮标识',
        englishName: '菜单英文名称',
        englishNames: '英文名称',
        parentMenu: '父级菜单',
        route: '路径',
        moduleId: '模块标识',
        disable: '禁用',
        menuId: '菜单标识',
        sort: '排序',
        pleaseName: '请输入名称!',
        currencyRequired: '请选择币种!',
        pleaseCurrency: '请选择换算币种!',
        pleaseRate: '请输入汇率!',
        pleaseProduct: '请输入商品名称!',
        pleaseEnterRole: '请输入角色名称!',
        pleaseMenuName: '请输入菜单名称',
        pleaseMenuId: '请输入菜单标识',
        pleaseSort: '请输入排序',
        pleaseModuleName: '请输入模块名称',
        pleasePath: '请输入路径',
        disableSuccess: '禁用成功',
        enableSuccess: '启用成功',
        editParameter: '编辑参数/新增参数',
        parameterName: '参数名称',
        theValue: '初始值',
        editFormula: '编辑公式',
        pleaseNamse: '请输入参数名称！',
        pleaseValue: '请输入默认值！',
        theRepeated: '输入值名称不可重复！',
        theFormula: '输入的公式有误，请确认公式！',
        computationTimeout: '计算失败,请求超时',
        inputValue: '输入值',
        intermediateBond: '中间键',
        pleaseMiddle: '请选择输入值或中间键!',
        barPage: '条/页',
        pleaseDChooseCommdity: '请选择商品分类节点！',
        IFFunction: 'IF函数',
        judgementCondition: '判断条件',
        trueResult: '条件为真',
        errorResult: '条件为假',
        improveJudgmentCondition: '请完善判断条件，不可为空！',
        trueResultInfo: '请输入条件为真的结果！',
        errorResultInfo: '请输入条件为假的结果！',
        changePrice: '改价',
        importList: '导入列表',
        plastic: 'Plastic',
        previewCal: '预览计算器',
        computeCal: '计算',
        pleaseEnter: '请输入',
        saveBasicData: '请先保存基础数据！',
        chooseCSToCal: '请重新选择CCL Type 和 Supplier，并重新计算！',
        enterNexteerPart: '请输入Nexteer Part!',
        pleaseCompleteInfo: '请完善数据！',
        pleaseCompleteSupplierInfo: '请维护供应商数据！',
        csRepeat: 'CCL Type and size不可重复！',
        columnRepeat: '列名不能重复！',
        nexteerPart: '产品编码',
        cclname: '名称',
        desc: '描述',
        revision: '版本',
        project: '项目',
        annualVolumn: '年用量',
        cclSelection: '覆铜板数量输入',
        cclPanel: '拼板数量 (PCS)',
        cclPcb: 'PCB数量 (PCS)',
        cclUtility: '利用率 (%)',
        cclProfit: '利润率',
        cclIndex: '系数',
        detail: '详情',
        chooseCal: '选择计算器',
        proName: '工序名称',
        type: '类型',
        cost: '单价',
        qty: '数量',
        totalCostList: '总价',
        ExportTcm: '导出TCM',
        AdvanceSetting: '高级设置',
        ExportForm: '导出内容',

    },
    // 英文
    EN: {
        disableSuccess: 'Disable successfully',
        enableSuccess: 'Enabled successfully',
        pleaseModuleName: 'Please enter the module name',
        pleasePath: 'Please enter the path',
        pleaseMenuName: 'Please enter the menu name',
        pleaseMenuId: 'Please enter the menu ID',
        pleaseSort: 'Please enter sort',
        pleaseEnterRole: 'Please enter the name of the role',
        pleaseProduct: 'Please enter the product name!',
        currencyRequired: 'Currency required',
        pleaseCurrency: 'Please choose report currency！',
        pleaseRate: 'Please choose enter rate！',
        newButton: 'New Button',
        editButton: 'Edit Button',
        buttonName: 'Button Name',
        buttonId: 'Button ID',
        newMenu: 'New Menu',
        menuName: 'Menu Name',
        englishName: 'English name of menu',
        pleaseName: 'Please enter a name',
        englishNames: 'English name',
        parentMenu: 'Parent menu',
        route: 'Route',
        moduleId: 'Module ID',
        disable: 'Disable',
        menuId: 'Menu ID',
        sort: 'Sort',
        //计算机
        //     {{$t('ccls.ccl.newModule')}}
        import: 'Import',
        export: 'Export',
        search: 'Search',
        dataImport: ' Data Import',
        dataExport: 'Data Export',
        newModule: 'New Module',
        newModules: 'New Button',
        editMenu: 'Edit Button',
        deleteBtn: 'Delete Button',
        menuBtn: 'Menu Button',

        addOrganization: 'Add Organization',
        editOrganization: 'Edit Organization',
        organizationName: 'Organization name',
        sequence: 'Sequence',
        department: 'department',
        editGroup: 'Edit User Group',
        addGroup: 'Add User Group',
        userGroupName: 'userGroupName',
        tcmPersonnel: 'TCM Operation Personnel',
        tcmApplicants: 'TCM Applicants',

        roleStatus: 'Role Status',
        editRole: 'Edit Role',
        addRole: 'Add Role',
        roleName: 'Role name',
        roleNameEn: 'English name',
        saveCurrency: 'save Currency',
        addCurrency: 'Add Currency',
        editCurrency: 'Edit Currency',
        symbol: 'Symbol',
        submit: 'Submit',
        supplier: 'Supplier',
        Layers: "Q'ty of Layers",
        innerThickness: 'Inner Copper thickness(Inner)',
        outerThickness: 'Outer Copper thickness(Outer)',
        material: 'Material TG',
        prepreg: 'Prepreg',
        viaFilling: 'Via Filling',
        soldermask: 'Soldermask',
        silkscreen: 'Silkscreen',
        depanel: 'Depanel (Shape)',
        surfaceArtwork: 'Surface artwork(Finish)',
        numberHoles: 'Number of holes(vias) /PCB',
        lengthPcb: 'Length of PCB (mm)',
        widthPcb: 'Width of PCB (mm)',
        ViasDiameter: 'Vias Diameter(mm) ',
        workingLength: 'working panel length (mm)',
        workingWidth: 'working panel width (mm)',
        panelization: "Panelization Q'TY",
        otherCost: 'other Indirect Cost',
        directCost: 'Direct Material Cost',
        processCost: 'Process Cost',
        logisticsCost: 'Logistics Cost',
        managementCharge: 'Management Charge',
        cclSize: 'CCL size',
        cclLength: 'CCL length(mm)',
        cclWidth: 'CCL Width(mm)',
        totalCost: 'Total cost/m2',
        totalCostCCL: 'Total cost/Selected CCL',
        pricePc: 'price/pc （￥）',
        pcbQtyAll: "PCB Q'ty (PCS)",
        // CCL 尺寸
        code: 'code',
        batchEnable: 'Batch Enable',
        batchDisabling: 'Batch Disabling',
        operate: 'Operate',
        enable: 'Enable',
        enables: 'Enable',
        // Direct Material
        cclType: 'CCL Type',
        lamination: 'KB Lamination',
        syLamination: 'SY Lamination',
        // 间接工序
        process: 'Process',
        depreciation: 'Absorbed Cost',
        indirectMaterial: 'Indirect Material',
        directOperator: 'Direct Operator Cost',
        totalCosts: 'Total Cost',
        item: 'Item',
        disable: 'Disable',
        processName: 'Process Name',
        cancel: 'Cancel',
        ok: 'OK',
        pleaseOperation: 'Please confirm whether to withdraw the operation?',
        pleaseReject: 'Please confirm whether to call back the application?',
        pleaseRework: 'Please confirm whether to call back the application?',
        confirm: 'Confirm',
        modify: 'Modify',
        saveType: 'save Type',
        addType: 'Add Type',
        editType: 'Edit Type',
        currency: 'Currency',
        cnyCurrency: 'Currency CNY Report Currency',
        exchangeRate: 'Exchange Rate',
        //                 {{$t('ccls.ccl.cancel')}}
        //                 {{$t('ccls.ccl.confirm')}}
        //                 {{$t('ccls.ccl.modify')}}
        // 新增
        NewCalculator: 'New Process Calculator',
        chooseCalculator: 'Choose Process Calculator',
        name: 'Name',
        nameEn: 'English Name',
        nameSpain: 'Spain name of menu',
        namePoland: 'Poland name of menu',
        isPhaseII: 'is a phase II module',
        commodity: 'commodity',
        version: 'Version',
        comments: 'Comments',
        pictures: 'Pictures',
        upload: 'Upload',
        input: 'Input',
        defaultValue: 'Default value',
        unit: 'Unit',
        formula: 'Formula',
        operate: 'Operate',
        output: 'Output',
        saveRegion: 'save Region',
        addRegion: 'Add Region',
        editRegion: 'Edit Region',
        addRate: 'Add Rate',
        editRate: 'Edit Rate',
        editParameter: 'Edit Parameter/New Parameter',
        parameterName: 'Parameter name',
        theValue: 'The default value',
        editFormula: 'Edit formula',
        pleaseNamse: 'Please enter a parameter name!',
        pleaseValue: 'Please enter the default value!',
        theRepeated: 'The input value name cannot be repeated!',
        theFormula: 'The input formula is wrong, please confirm the formula!',
        computationTimeout: 'Computation failed, request timeout',

        inputValue: 'Input',
        intermediateBond: 'Middle',
        pleaseMiddle: 'Please select input or middle',
        barPage: 'Bar/Page',
        pleaseDChooseCommdity: 'Please select the product category node!',
        IFFunction: 'IF Function',
        judgementCondition: 'Judgement Condition',
        trueResult: 'Condition Is True',
        errorResult: 'Condition Is False',
        improveJudgmentCondition: 'Please improve the judgment condition, not empty!',
        trueResultInfo: 'Please enter the result of the condition as true!',
        errorResultInfo: 'Please enter the result with false condition!',
        changePrice: 'Change Price',
        importList: 'Import TCM',
        plastic: 'Plastic',
        previewCal: 'Preview Process Calculator',
        computeCal: 'compute',
        pleaseEnter: 'Please enter',
        saveBasicData: 'Save basic data first!',
        chooseCSToCal: 'Please re-select CCL Type and Supplier and recalculate!',
        enterNexteerPart: 'Please enter Nexteer Part!',
        pleaseCompleteInfo: 'Please complete the information! ',
        pleaseCompleteSupplierInfo: 'Please maintain supplier data!',
        csRepeat: 'CCL Type and size cannot be repeated!',
        columnRepeat: 'Column names cannot be repeated!',
        nexteerPart: 'Nexteer Part',
        cclname: 'Name',
        desc: 'Description',
        revision: 'Revision ID',
        project: 'Project',
        annualVolumn: 'Annual Volume',
        cclSelection: 'CCL Q’ty',
        cclPanel: "Panel Q'TY/CCL (PCS)",
        cclPcb: "PCB Q'TY/CCL (PCS)",
        cclUtility: 'Panel Utility (%)',
        cclProfit: 'Profit',
        cclIndex: 'Index',
        detail: 'Detail',
        chooseCal: 'Choose Calculator',
        proName: 'Process Name',
        type: 'Type',
        cost: 'Cost',
        qty: "Q'ty",
        totalCostList: 'Total Cost',
        ExportTcm: 'Export TCM',
        AdvanceSetting: 'Advance Setting',
        ExportForm: 'Export Form',
        // 
    }
}
export default info