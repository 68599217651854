<template>
	<el-dialog v-model="visible" width="600px" :show-close="false" @close="close">
		<template #header="{ close }">
			<div class="my-header">
				<div class="size-16">{{$t('ccls.ccl.addGroup')}}</div>
				<div style="cursor: pointer" @click="close">
					<el-icon :size="20"  style="color: var(--themeColor);">
						<CircleCloseFilled />
					</el-icon>
				</div>
			</div>
		</template>
		<div>
			<el-form :model="form" label-width="120px" :rules="rules" ref="list">
				<el-form-item :label="$t('ccls.ccl.userGroupName')" label-width="200px" prop="userGroupName">
					<el-input v-model="form.userGroupName" style="width: 240px" />
				</el-form-item>
				<el-form-item :label="$t('ccls.ccl.tcmPersonnel')" label-width="200px" prop="tcmOperationPersonneAccount">
					<el-select clearable   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="form.tcmOperationPersonneAccount" @click="Focus()">
						<el-option v-for="item in tcmOperationPersonneId" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('ccls.ccl.tcmApplicants')" label-width="200px" prop="tcmApplicationAccount">
					<el-select clearable   :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="form.tcmApplicationAccount" @click="Focus()" multiple>
						<el-option v-for="item in tcmApplicationLists" :key="item.id" :label="item.name"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item :label="$t('ccls.ccl.comments')"  label-width="200px" prop="comments">
					<el-input v-model="form.comments" style="width: 240px" />
				</el-form-item>
				<el-form-item :label="$t('ccls.ccl.enable')"  label-width="200px" prop="isEnable">
					<el-switch v-model="form.isEnable" :active-value="1" :inactive-value="0" />
				</el-form-item>
			</el-form>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="close">{{$t('ccls.ccl.cancel')}}</el-button>
				<el-button  style="background-color: var(--themeColor); color: var(--menuTextActive);margin-right: 16px" @click="addSubmit">{{$t('ccls.ccl.confirm')}}</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import commonUtil from "../../assets/js/commonUtil.js";
	import {
		userList
	} from "../../assets/js/requestApi.js";
	export default {
		data() {
			return {
				visible: false,
				form: {
					userGroupName: "",
					// tcmApplicationAccount: "",
					tcmOperationPersonnel: "",
					tcmOperationPersonneAccount: "",
					comments: "",
				},
				tcmApplicationLists: [],
				tcmOperationPersonneId: [],
				rules: {
					userGroupName: [{
						required: true,
						message: "请输入用户组名称",
						trigger: "blur"
					}, ],
					tcmApplicationAccount: [{
						required: true,
						message: "请选择申请人",
						trigger: "blur"
					}, ],
					tcmOperationPersonneAccount: [{
						required: true,
						message: "请选择操作人",
						trigger: "blur"
					}, ],
					isEnable: [{
						required: true
					}],
				},
			};
		},
		methods: {
			// 获取焦点显示下拉数据
			Focus() {
				console.log("获取焦点");
				userList({
					page: 1,
					limit: 9999999
				}, (response) => {
					if (response && response.code == 200) {
						console.log("接口成功", response);
						this.tcmApplicationLists = response.data;
						this.tcmOperationPersonneId = response.data;
					}
				});
			},

			addSubmit() {
				// 给出添加弹框
				if (this.form.name == "") {
					commonUtil.showTipInfo("请输入名称", "", "warning");
					retrun;
				}
				this.visible = false;
				// 申请人回显
				this.form.userGroup_List = [];
				// 选中的id数组
				for (let i = 0; i < this.form.tcmApplicationAccount.length; i++) {
					// 全部的数据
					for (let j = 0; j < this.tcmApplicationLists.length; j++) {
						if (
							this.form.tcmApplicationAccount[i] == this.tcmApplicationLists[j].id
						) {
							this.form.userGroup_List.push({
								userGroupManageMentId: this.tcmApplicationLists[j].id,
								tcmApplication: this.tcmApplicationLists[j].name,
							});
						}
					}
				}
				// ----------------------------------------------------------------------------------------
				// 选中的id数组
				for (let i = 0; i < this.form.tcmOperationPersonneAccount.length; i++) {
					// 全部的数据
					for (let j = 0; j < this.tcmOperationPersonneId.length; j++) {
						if (
							this.form.tcmOperationPersonneAccount[i] ==
							this.tcmOperationPersonneId[j].id
						) {
							this.form.tcmOperationPersonnel =
								this.tcmOperationPersonneId[j].name;
						}
					}
				}
				this.$emit("handleSubmit", this.form);
				this.$refs.list.resetFields();
			},

			close() {
				this.$refs.list.resetFields();
				this.visible = false;

			},
		},
	};
</script>

<style scoped>
	.my-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.size-16 {
		font-size: 16px;
	}
</style>
