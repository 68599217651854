const info = {
    // 中文
    ZH: {
        '处理步骤':'处理步骤',
        '签名':'签名',
        '操作':'操作',
        '日期':'日期',
        '备注':'备注'
    },
    // 英文
    EN: {
        '处理步骤':'Step',
        '签名':'Operator',
        '操作':'Operation',
        '日期':'Date',
        '备注':'Comment'
    }
}
export default info