<template>
  <el-dialog v-model="visible" width="90%" :show-close="false" @close="close">
    <template #header="{ close }">
      <div class="my-header">
        <div class="size-16">
          <!-- {{ $t("unifiedPrompts.unifiedPrompt.hasUntaxedToFill") }} -->
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20">
            <Close />
          </el-icon>
        </div>
      </div>
    </template>
    <div class="mian-box">
      <addOperate
        :key="timer"
        :editFlag="operateType"
        ref="addOperateChangeprice"
        @submitClose="submitClose"
      ></addOperate>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "@/assets/js/commonUtil.js";
import addOperate from "../tcmOperation/changePriceAddOperate.vue";
export default {
  props: ["operateType"],
  components: {
    addOperate,
  },
  data() {
    return {
      visible: false,
      timer: "1",
    };
  },
  mounted() {
    //
    // this.$refs.addoperate.previewShow = true;
  },
  methods: {
    close() {
      this.visible = false;
    },
    submitClose(newData, oldData) {
      this.visible = false;
      this.$emit("changePrice", newData, oldData);
    },
    GetChangePriceId(id, Ischange) {
      // this.$forceUpdate();
      this.timer = new Date().getTime();
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.addOperateChangeprice.getDetail(id, Ischange);
      });
    },
    GetChangePriceData(data, Ischange, oldData) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.addOperateChangeprice.AssignDetail(data, Ischange, oldData);
      });
    },
    addSubmit() {},
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mian-box {
  min-height: 500px !important;
}
.size-16 {
  font-size: 16px;
}
.rate-cla {
  display: flex;
  margin-bottom: 10px;
}
.line-32 {
  line-height: 32px;
}
.mg-5 {
  margin: 5px;
}
.mgr-10 {
  margin-right: 10px;
}
.arrows-img {
  width: 20px;
}
</style>
