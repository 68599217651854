<template>
  <el-dialog v-model="visible" width="60%" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{ $t("unifiedPrompts.unifiedPrompt.selectCompleteCBD") }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-col :span="24">
        <table style="margin-bottom: 10px">
          <tr>
            <td style="width: 280px">
              {{ $t("tcmdatalists.tcmdatalist.commodity") }}
              <el-tree-select
                clearable
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="searchForm.CommodityId"
                :data="CommodityOptions"
                :props="defaultProps"
                check-strictly
                :render-after-expand="false"
                :default-expand-all="true"
                style="width: 200px; display: inline-block"
              />
            </td>
            <td style="width: 260px">
              <el-input v-model="searchForm.Keyword" placeholder="keywords" />
            </td>
            <td style="width: 260px">
              <el-button
                style="
                  background-color: var(--themeColor);
                  color: var(--menuTextActive);
                  margin-right: 16px;
                "
                @click="search"
                >{{ $t("userlists.userlist.search") }}</el-button
              >
            </td>
          </tr>
        </table>
      </el-col>
      <div class="regional_table">
        <el-table
          stripe
          :empty-text="$t('tcmlists.tcmlist.noData')"
          :data="tableData"
          border
          height="400"
          style="width: 100%"
        >
          <el-table-column
            prop="select"
            label="Select"
            width="110"
            align="center"
          >
            <template #default="scope">
              <el-button
                style="background-color: #b2bde7; color: var(--menuTextActive)"
                @click="select(scope.row.id)"
                >select</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="commodityName"
            :label="$t('tcmdatalists.tcmdatalist.commodity')"
            width="110"
            align="center"
          />
          <!-- <el-table-column
            prop="documentNo"
            :label="$t('tcmdatalists.tcmdatalist.documentNO')"
            align="center"
          /> -->
          <el-table-column
            prop="tcmOwner"
            :label="$t('tcmdatalists.tcmdatalist.tcmRequester')"
            width="120"
            align="center"
          />
          <el-table-column
            prop="currencyName"
            :label="$t('changePriceLists.changePriceList.currency')"
            width="90"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            prop="partDescription"
            :label="$t('tcmdatalists.tcmdatalist.partDescription')"
            align="center"
          />
          <el-table-column
            prop="partNumber"
            :label="$t('tcmdatalists.tcmdatalist.partNumber')"
            width="120"
            align="center"
          />
          <el-table-column
            prop="date"
            :label="$t('tcmdatalists.tcmdatalist.startDate')"
            sortable
            width="125"
            align="center"
          />
          <el-table-column
            prop="doDate"
            :label="$t('changePriceLists.changePriceList.dueDate')"
            sortable
            width="120"
            align="center"
          />
          <el-table-column
            prop="version"
            :label="$t('templateLists.templateList.version')"
            width="120"
            align="center"
          />
        </el-table>
      </div>
      <div class="pagination">
        <P>{{ total }} data</P>
        <el-pagination
          layout="prev, pager, next"
          background
          :total="total"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- <template #footer>
		      <span class="dialog-footer">
		        <el-button @click="close">Cancel</el-button>
		        <el-button color="#335883" style="margin-right: 16px;" @click="close">Confirm</el-button>
		      </span>
		</template> -->
  </el-dialog>
</template>

<script>
import { getApplicationList } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  props: ["currencyId", "CommodityOptions"],
  emits: ["selectCompleted"],
  data() {
    return {
      visible: false,
      searchForm: {
        CommodityId: "",
        currencyId: "",
        date: "",
        StartDate: "",
        EndDate: "",
        status: "6",
        Keyword: "",
        PageType: 3,
        priceType: 0,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
        isDisable: 0,
      },
      // 总页数
      total: 0,
      tableData: [],
      // commodity选项
      defaultProps: {
        children: "children",
        label: "Name",
      },
    };
  },
  mounted() {},
  methods: {
    close() {
      this.visible = false;
    },
    getlist() {
      this.searchForm = {
        CommodityId: "",
        currencyId: this.currencyId,
        date: "",
        StartDate: "",
        EndDate: "",
        status: "6",
        Keyword: "",
        priceType: 0,
        PageType: 3,
        isDraft: 0,
        page: 1,
        limit: 10,
        key: "",
        versionType: 1,
        isDisable: 0,
      };
      this.search();
    },
    // 查询
    search() {
      getApplicationList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 选择
    select(id) {
      this.$emit("selectCompleted", id);
      this.close();
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>