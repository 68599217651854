<template>
  <div class="tcm-list">
    <div class="tcm-search">
      <table>
        <tr>
          <td>
            {{ $t("tcmlists.tcmlist.commodity") }}
            <el-select
              clearable
              v-model="searchForm.commodity"
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            >
              <el-option
                v-for="item in commodityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </td>
          <td>
            {{ $t("tcmlists.tcmlist.date") }}
            <el-date-picker
              v-model="searchForm.date"
              type="daterange"
              range-separator="To"
              :start-placeholder="$t('unifiedPrompts.unifiedPrompt.startDate')"
              :end-placeholder="$t('unifiedPrompts.unifiedPrompt.endDate')"
              size="default"
            />
          </td>
          <!-- CalcDefaul To unifiedPrompts -->
          <td>
            {{ $t("tcmlists.tcmlist.workStatus") }}
            <el-select
              clearable
              v-model="searchForm.status"
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </td>
          <td>
            <el-input
              v-model="searchForm.keywords"
              :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
            />
          </td>
          <td>
            <div class="operate-btn" style="margin-right: 10px">
              {{ $t("ccls.ccl.search") }}
            </div>
            <!-- <div class="operate-btn">Advanced search</div> -->
          </td>
        </tr>
        <tr>
          <td colspan="5" style="padding-top: 10px">
            <div class="operate-btn" style="margin-right: 10px">
              {{ $t("ccls.ccl.dataExport") }}
            </div>
            <div class="operate-btn">{{ $t("ccls.ccl.dataImport") }}</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column
          resizable
          prop="Commodity"
          :label="$t('tcmlists.tcmlist.commodity')"
          width="110"
          align="center"
        />
        <!-- <el-table-column prop="DocumentNO" :label="$t('tcmlists.tcmlist.documentNO')" align="center" /> -->
        <el-table-column
          prop="TCMRequest"
          :label="$t('tcmlists.tcmlist.tcmRequester')"
          align="center"
        />
        <el-table-column
          prop="PartDescription"
          :label="$t('tcmlists.tcmlist.partDescription')"
          align="center"
        />
        <el-table-column
          prop="PartNumber"
          :label="$t('tcmlists.tcmlist.partNumber')"
          align="center"
        />
        <el-table-column
          prop="Duedate"
          :label="$t('tcmlists.tcmlist.date')"
          sortable
          align="center"
        />
        <el-table-column
          prop="QuoteType"
          :label="$t('tcmlists.tcmlist.quotationType')"
          align="center"
        />
        <el-table-column
          prop="TCMOwner"
          :label="$t('tcmlists.tcmlist.tcmOwner')"
          align="center"
        />
        <el-table-column
          prop="WorkflowStatus"
          :label="$t('tcmlists.tcmlist.workStatus')"
          align="center"
        >
          <template #default="scope">
            <div v-show="scope.row.WorkflowStatus == '0'" class="color-F59A23">
              {{ $t("tcmdatalists.tcmdatalist.notStart") }}
            </div>
            <div v-show="scope.row.WorkflowStatus == '1'" class="color-D9001B">
              {{ $t("tcmdatalists.tcmdatalist.inProcess") }}
            </div>
            <div v-show="scope.row.WorkflowStatus == '2'" class="color-0000FF">
              {{ $t("tcmdatalists.tcmdatalist.toApproved") }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-cla">
      <div>
        <el-input
          v-model="searchForm.limit"
          @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
          min="1"
          style="width: 130px"
          @change="handleSizeChange"
        >
          <template #suffix>
            {{ $t("ccls.ccl.barPage") }}
          </template>
        </el-input>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        commodity: "",
        date: "",
        status: "",
        keywords: "",
      },
      // commodity选项
      commodityOptions: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
      ],
      // status选项
      statusOptions: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
      ],
      tableData: [
        {
          Commodity: "CASTING",
          DocumentNO: "202110111212-2",
          TCMRequest: "Jay Xiong",
          PartDescription: "ASSIST COVER",
          PartNumber: "38007234",
          Duedate: "2021-12-11",
          QuoteType: "New Purist",
          TCMOwner: "Bruce LI",
          WorkflowStatus: "0",
        },
        {
          Commodity: "CASTING",
          DocumentNO: "202110111212-2",
          TCMRequest: "Jay Xiong",
          PartDescription: "ASSIST COVER",
          PartNumber: "38007234",
          Duedate: "2021-12-11",
          QuoteType: "New Purist",
          TCMOwner: "Bruce LI",
          WorkflowStatus: "1",
        },
        {
          Commodity: "CASTING",
          DocumentNO: "202110111212-2",
          TCMRequest: "Jay Xiong",
          PartDescription: "ASSIST COVER",
          PartNumber: "38007234",
          Duedate: "2021-12-11",
          QuoteType: "New Purist",
          TCMOwner: "Bruce LI",
          WorkflowStatus: "2",
        },
        {
          Commodity: "CASTING",
          DocumentNO: "202110111212-2",
          TCMRequest: "Jay Xiong",
          PartDescription: "ASSIST COVER",
          PartNumber: "38007234",
          Duedate: "2021-12-11",
          QuoteType: "New Purist",
          TCMOwner: "Bruce LI",
          WorkflowStatus: "3",
        },
      ],
    };
  },
  methods: {
    addEntry() {
      this.$router.push("/tcmAdd");
    },
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo("请设置每页数据展示数量！", "", "warning");
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
  },
};
</script>

<style scoped>
.tcm-search {
  width: 100%;
  background-color: var(--searchBg);
  padding: 20px;
  box-sizing: border-box;
}
.tcm-search td {
  /* width: 20%; */
  padding: 0 10px;
}
.operate-btn {
  border-radius: 5px;
  background-color: var(--themeColor);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  display: inline-block;
  min-width: 80px;
  cursor: pointer;
}
.color-ff0000 {
  color: #ff0000;
}
.color-F59A23 {
  color: #f59a23;
}
.color-0000FF {
  color: #0000ff;
}
.color-D9001B {
  color: #d9001b;
}
.page-cla {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>