<template>
  <el-dialog v-model="visible" width="40%" destroy-on-close :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">{{ $t("tcmlists.tcmlist.chooseEmail") }}</div>
        <div @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-table">
      <div class="search-box">
        <input
          type="text"
          v-model="searchForm.key"
          :placeholder="$t('unifiedPrompts.unifiedPrompt.keyword')"
        />
        <div class="search-btn" style="margin-right: 10px" @click="search">
          {{ $t("userlists.userlist.search") }}
        </div>
      </div>
      <div class="regional_table">
        <el-table
          :empty-text="$t('tcmlists.tcmlist.noData')"
          ref="rawTable"
          height="400"
          :data="tableData"
          border
          stripe
          :row-key="
            (row) => {
              return row.id;
            }
          "
          @selection-change="handleSelectionChange"
          @select-all="selectAll"
        >
          <el-table-column
            type="selection"
            reserve-selection
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="email"
            :label="$t('changePriceLists.changePriceList.email')"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column
            prop="name"
            :label="$t('changePriceLists.changePriceList.name')"
            show-overflow-tooltip
            align="center"
          />
        </el-table>
      </div>
      <div class="pagination">
        <div>{{ total }} data</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="searchForm.page"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="toConfirm"
          >{{ $t("userlists.userlist.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { userList } from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["getChooseCC"],
  data() {
    return {
      visible: false,
      searchForm: {
        id: "",
        key: "",
        page: 1, //当前页
        limit: 10, //每页个数
      },
      // 总页数
      total: 0,
      // 表格数据
      tableData: [],
      // 已勾选数据
      chooseData: [],
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.chooseData = [];
    },
    toConfirm() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseChooseData"),
          "",
          "warning"
        );
        return;
      }
      console.log(this.chooseData);
      let data = [];
      this.chooseData.forEach(function (val, index) {
        data.push({
          id: val.id,
          email: val.email,
          name: val.name,
          type: 3,
        });
      });
      this.$emit("getChooseCC", data);
      this.close();
    },
    // 获取表格数据
    getlist(type, CommId) {
      this.searchForm = {
        id: "",
        key: "",
        page: 1, //当前页
        limit: 10, //每页个数
      };
      this.search();
    },
    // 查询
    search() {
      userList(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        } else {
          commonUtil.showTipInfo(this.$t("userlists.userlist.inqueryTimeout"));
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    // 表格单选选
    handleSelectionChange(val) {
      console.log(val);
      this.chooseData = val;
      // this.chooseData = val
    },
    // 表格全选
    selectAll() {
      this.chooseData = this.tableData;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}

.search-box {
  display: flex;
  input {
    width: 50%;
    background: #f4f5f9;
    border-radius: 5px;
    font-size: 13px;
  }
  input::placeholder {
    color: #445581;
  }
  .search-btn {
    margin-left: 15px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #445581;
    color: #fff;
    border-radius: 5px;
  }
}
.regional_table {
  margin: 10px 0;
}

// .page-cla {
//   padding: 20px;
//   display: flex;
//   justify-content: flex-end;
// }
// .align-right {
//   text-align: right;
// }
// .tcm-table {
//   height: calc(100% - 90px);
//   padding: 0 20px;
//   box-sizing: border-box;
// }
// .tree-cla {
//   padding: 10px;
//   box-sizing: border-box;
//   height: 100%;
//   border: 1px solid #ebeef5;
// }
// .el-row {
//   height: 100%;
// }
// .flex-around {
//   display: flex;
//   justify-content: space-around;
// }
// .operate-btn {
//   border-radius: 5px;
//   background-color: var(--themeColor);
//   color: #fff;
//   text-align: center;
//   padding: 7px 10px;
//   display: inline-block;
//   min-width: 80px;
//   cursor: pointer;
// }
</style>