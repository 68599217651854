<template>
  <el-dialog
    v-model="visible"
    width="600px"
    :show-close="false"
    :destroy-on-close="true"
    @close="close"
  >
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16"></div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-form ref="formList" :model="form" :rules="rules" label-width="230px">
        <el-form-item :label="$t('homePage.home.Grade')" prop="gradeId">
          <el-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="form.gradeFerrousId"
            @change="changeGrade"
            style="width: 214px"
          >
            <el-option
              v-for="it in gradeOptions"
              :key="it.id"
              :label="it.ferrous"
              :value="it.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('homePage.home.OperationDetails')"
          prop="operation_DetailsId"
        >
          <el-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="form.operation_DetailsId"
            @change="changeOD"
            style="width: 214px"
          >
            <el-option
              v-for="it in operationOptions"
              :key="it.id"
              :label="it.process"
              :value="it.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('homePage.home.SectionProfile')"
          prop="section_Profile"
        >
          <el-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="form.section_Profile"
            @change="computeTlcbf"
            style="width: 214px"
          >
            <el-option
              v-for="it in sectionOptions"
              :key="it"
              :label="it"
              :value="it"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('homePage.home.Length')"
          prop="length"
          v-show="
            form.section_Profile == 'Rectangular' ||
            form.section_Profile == 'Square'
          "
        >
          <el-input
            v-model="form.length"
            @input="transferFormat($event, form, 'length')"
            @change="transferFormat($event, form, 'length'), computeTlcbf()"
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item
          prop="width"
          :label="$t('homePage.home.Width')"
          v-show="
            form.section_Profile == 'Rectangular' ||
            form.section_Profile == 'Square'
          "
        >
          <el-input
            v-model="form.width"
            @input="transferFormat($event, form, 'width')"
            @change="transferFormat($event, form, 'width'), computeTlcbf()"
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item
          label="OD"
          prop="circle_OD"
          v-show="form.section_Profile == 'Circle'"
        >
          <el-input
            v-model="form.circle_OD"
            @input="transferFormat($event, form, 'circle_OD')"
            @change="transferFormat($event, form, 'circle_OD'), computeTlcbf()"
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item
          label="ID"
          prop="circle_ID"
          v-show="form.section_Profile == 'Circle'"
        >
          <el-input
            v-model="form.circle_ID"
            @input="transferFormat($event, form, 'circle_ID')"
            @change="transferFormat($event, form, 'circle_ID'), computeTlcbf()"
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item label="Thk" prop="thk">
          <el-input
            v-model="form.thk"
            @input="transferFormat($event, form, 'thk')"
            @change="
              transferFormat($event, form, 'thk'),
                computeTlcbf(),
                computeTonnage()
            "
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item :label="$t('homePage.home.TotalLength_Cut_bend_Form')">
          <el-input
            v-model="form.total_Length_Of_Cut_bend"
            disabled
            @input="
              (v) => (form.total_Length_Of_Cut_bend = v.replace(/[^\d]/g, ''))
            "
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item :label="$t('homePage.home.Calculated')">
          <el-input
            v-model="form.calculated"
            disabled
            @input="(v) => (form.calculated = v.replace(/[^\d]/g, ''))"
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item :label="$t('homePage.home.Manual')">
          <el-input
            v-model="form.manual"
            @input="transferFormat($event, form, 'manual'), computeTonnage()"
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item :label="$t('homePage.home.TypeStrength')">
          <el-input
            v-model="form.type_Of_Strength"
            disabled
            @input="(v) => (form.type_Of_Strength = v.replace(/[^\d]/g, ''))"
            style="width: 214px"
          />
        </el-form-item>
        <el-form-item :label="$t('homePage.home.StrippingForce')">
          <el-input
            v-model="form.stripping_Force"
            disabled
            @input="(v) => (form.stripping_Force = v.replace(/[^\d]/g, ''))"
            style="width: 214px"
          >
            <template #suffix>%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="FOS">
          <el-input
            v-model="form.fos"
            disabled
            @input="(v) => (form.fos = v.replace(/[^\d]/g, ''))"
            style="width: 214px"
          >
            <template #suffix>%</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('homePage.home.CalculatedTonnage')">
          <el-input
            v-model="form.calculated_Tonnage"
            disabled
            @input="(v) => (form.calculated_Tonnage = v.replace(/[^\d]/g, ''))"
            style="width: 214px"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="handleSubmit"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
import {
  loadOperationDetailss,
  loadGradeFerrouss,
  GetCalculatedResult,
  GetTotalLengthResult,
  GetCalculatedTonnageResult,
} from "@/assets/js/requestApi.js";
export default {
  emits: ["handleSubmit"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        mainId: "",
        gradeFerrousId: "",
        operation_DetailsId: "",
        section_Profile: "",
        length: "",
        width: "",
        circle_OD: "",
        circle_ID: "",
        thk: "",
        total_Length_Of_Cut_bend: "",
        calculatedMax: "",
        calculated: "",
        manual: "",
        type_Of_Strength: "",
        stripping_Force: "25",
        fos: "15",
        calculated_Tonnage: "",
        type: "",
      },
      // operation_DetailsId选项
      operationOptions: [],
      // grade选项
      gradeOptions: [],
      // section_Profile选项
      sectionOptions: ["Rectangular", "Square", "Circle"],
      rules: {
        gradeId: [
          { required: true, message: "please enter grade", trigger: "blur" },
        ],
        operation_DetailsId: [
          {
            required: true,
            message: "please choose Operation Details",
            trigger: "change",
          },
        ],
        section_Profile: [
          {
            required: true,
            message: "please choose Section Profile",
            trigger: "change",
          },
        ],
        length: [
          { required: true, message: "please enter length", trigger: "blur" },
        ],
        width: [
          { required: true, message: "please enter width", trigger: "blur" },
        ],
        circle_OD: [
          {
            required: true,
            message: "please enter circle OD",
            trigger: "blur",
          },
        ],
        circle_ID: [
          {
            required: true,
            message: "please enter circle ID",
            trigger: "blur",
          },
        ],
        thk: [{ required: true, message: "please enter thk", trigger: "blur" }],
        manual: [
          { required: true, message: "please enter manual", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      loadOperationDetailss(
        {
          keyword: "",
          page: 1, //当前页
          limit: 9999, //每页个数
        },
        (response) => {
          if (response && response.code == 200) {
            this.operationOptions = response.data;
          }
        }
      );
      loadGradeFerrouss(
        {
          keyword: "",
          page: 1, //当前页
          limit: 9999, //每页个数
        },
        (response) => {
          if (response && response.code == 200) {
            this.gradeOptions = response.data;
          }
        }
      );
    },
    // 切换operation detail
    changeGrade() {
      if (
        this.form.gradeFerrousId !== "" &&
        this.form.operation_DetailsId !== ""
      ) {
        GetCalculatedResult(
          {
            gradeId: this.form.gradeFerrousId,
            typeOfStrength: this.form.type_Of_Strength,
            type: this.form.type,
          },
          (response) => {
            console.log(response);
            if (response && response.code == 200) {
              this.form.calculated = response.result;
              this.computeTonnage();
            }
          }
        );
      }
    },
    // 切换operation detail
    changeOD() {
      let _this = this;
      if (this.form.operation_DetailsId !== "") {
        this.operationOptions.forEach(function (item, index) {
          if (item.id === _this.form.operation_DetailsId) {
            _this.form.type_Of_Strength = item.strength_Details;
          }
        });
        this.changeGrade();
      }
    },
    // 计算Total Length of Cut / bend / Form
    computeTlcbf() {
      if (this.form.section_Profile !== "" && this.form.thk !== "") {
        let form = {
          section_Profile: this.form.section_Profile,
          length: this.form.length,
          width: this.form.width,
          circle_OD: this.form.circle_OD,
          circle_ID: this.form.circle_ID,
          thk: this.form.thk,
        };
        if (
          this.form.section_Profile === "Circle" &&
          this.form.circle_OD !== "" &&
          this.form.circle_ID !== ""
        ) {
          form.length = 0;
          form.width = 0;
        }
        if (
          (this.form.section_Profile === "Rectangular" ||
            this.form.section_Profile === "Square") &&
          this.form.length !== "" &&
          this.form.width !== ""
        ) {
          form.circle_OD = 0;
          form.circle_ID = 0;
        }
        GetTotalLengthResult(form, (response) => {
          if (response && response.code == 200) {
            this.form.total_Length_Of_Cut_bend = response.result;
            this.computeTonnage();
          }
        });
      }
    },
    // 计算tonnage
    computeTonnage() {
      // if (this.form.manual !== '') {
      GetCalculatedTonnageResult(
        {
          thk: this.form.thk === "" ? 0 : this.form.thk,
          totalLength:
            this.form.total_Length_Of_Cut_bend === ""
              ? 0
              : this.form.total_Length_Of_Cut_bend,
          calculated: this.form.calculated === "" ? 0 : this.form.calculated,
          manual: this.form.manual,
          force: this.form.stripping_Force,
          fos: this.form.fos,
        },
        (response) => {
          if (response && response.code == 200) {
            this.form.calculated_Tonnage = response.result;
          }
        }
      );
      // }
    },
    // 转换数据类型
    transferFormat(val, item, name) {
      item[name] = val.replace(/^\D*(\d*(?:\.\d{0,12})?).*$/g, "$1");
    },
    handleSubmit() {
      let _this = this;
      if (this.form.gradeFerrousId === "") {
        commonUtil.showTipInfo("Please enter grade！", "", "warning");
        return;
      }
      if (this.form.operation_DetailsId === "") {
        commonUtil.showTipInfo(
          "Please choose Operation Details！",
          "",
          "warning"
        );
        return;
      }
      if (this.form.section_Profile === "") {
        commonUtil.showTipInfo(
          "Please choose Section Profile！",
          "",
          "warning"
        );
        return;
      }
      if (
        (this.form.section_Profile == "Rectangular" ||
          this.form.section_Profile == "Square") &&
        this.form.length === ""
      ) {
        commonUtil.showTipInfo("Please enter length！", "", "warning");
        return;
      }
      if (
        (this.form.section_Profile == "Rectangular" ||
          this.form.section_Profile == "Square") &&
        this.form.width === ""
      ) {
        commonUtil.showTipInfo("Please enter width！", "", "warning");
        return;
      }
      if (this.form.section_Profile == "Circle" && this.form.circle_OD === "") {
        commonUtil.showTipInfo("Please enter circle OD！", "", "warning");
        return;
      }
      if (this.form.section_Profile == "Circle" && this.form.circle_ID === "") {
        commonUtil.showTipInfo("Please enter circle ID！", "", "warning");
        return;
      }
      if (this.form.thk === "") {
        commonUtil.showTipInfo("Please enter thk！", "", "warning");
        return;
      }
      // if (this.form.manual === "") {
      // 	commonUtil.showTipInfo("Please enter manual！", "", "warning");
      // 	return;
      // }
      let form = {
        id: this.form.id,
        mainId: this.form.mainId,
        gradeFerrousId: this.form.gradeFerrousId,
        operation_DetailsId: this.form.operation_DetailsId,
        section_Profile: this.form.section_Profile,
        length: this.form.length === "" ? 0 : parseFloat(this.form.length),
        width: this.form.width === "" ? 0 : parseFloat(this.form.width),
        circle_OD:
          this.form.circle_OD === "" ? 0 : parseFloat(this.form.circle_OD),
        circle_ID:
          this.form.circle_ID === "" ? 0 : parseFloat(this.form.circle_ID),
        thk: parseFloat(this.form.thk),
        total_Length_Of_Cut_bend: parseFloat(
          this.form.total_Length_Of_Cut_bend
        ),
        calculatedMax: this.form.calculatedMax,
        calculated: parseFloat(this.form.calculated),
        manual: this.form.manual,
        type_Of_Strength: this.form.type_Of_Strength,
        stripping_Force: parseFloat(this.form.stripping_Force),
        fos: parseFloat(this.form.fos),
        calculated_Tonnage: parseFloat(this.form.calculated_Tonnage),
        type: this.form.type,
      };
      this.$emit("handleSubmit", form);
      this.close();
    },
    close() {
      this.visible = false;
      this.form = {
        id: "",
        mainId: "",
        gradeFerrousId: "",
        operation_DetailsId: "",
        section_Profile: "",
        length: "",
        width: "",
        circle_OD: "",
        circle_ID: "",
        thk: "",
        total_Length_Of_Cut_bend: "",
        calculatedMax: "",
        calculated: "",
        manual: "",
        type_Of_Strength: "",
        stripping_Force: "25",
        fos: "15",
        calculated_Tonnage: "",
        type: "",
      };
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
</style>