// 错误码枚举
export default {
    /**
     *请求成功
     */
    OK: "200",
	/**
	 * 账号无效
	 */
	Invalid_Account: "50013",
	/**
	 *根据NexteerPart和Version版本判断是否重复(ebomList)
	 */
	NexteerPart_ebomList: '50019',
	/**
	 *根据NexteerPart和Status，ManufacturingPart判断是否重复(yesPrice)
	 */
	NexteerPart_YesPrice: '50017',
	/**
	 *根据NexteerPart判断是否重复 (noPrice)
	 */
	NexteerPart_NoPrice: '50018',
	/**
	 *根据NexteerPart判断是否重复 (ebom)
	 */
	NexteerPart_ebom: '50020',
	/**
	 *根据toolName判断是否重复 (ct)
	 */
	toolName_ct: '500221',
	/**
	 *根据seq判断是否重复 (ct)
	 */
	seq_ct: '50023',
	/**
	 *根据machineType判断是否重复 (ct)
	 */
	machineType_ct: '50022',
	/**
	 *选择历史ct下无明细
	 */
	hasNoData_ct: '50024',
    /**
     * 重定向
     */
    REDIRECT_CODE: "301",
    /**
     * 未登录
     */
    NOT_LOGGED_IN: "481",
	ERROR_CODE: '400'
}