const info = {
    // 中文

    //{{$t('userlists.userlist.search')}}
    // {{$t('userlists.userlist.batchDisabling')}}
    ZH: {
        //用户管理
        search: '搜索',
        user: '新增用户',
        jobNumber: '账号',
        role: '汇报者',
        mailbox: '邮箱',
        name: '姓名',
        role: '角色',
        phone: '电话',
        commodity: '商品分类',
        isEnable: '激活',
        operate: '操作',
        password: '密码',
        surePassword: '确认密码',
        changePassword: '修改密码',
        department: '部门',
        commodityType: '商品类型',
        sex: '性别',
        thePassword: '默认密码为初始密码',
        cancel: '取消',
        confirm: '确认',
        id: '编号',
        names: '名称',
        currentCurrency: '币种',
        reportCurrency: '换算货币',
        exchangeRate: '汇率',
        version: '版本',
        batchEnable: '分批激活',
        batchDisabling: '分批取消',
        revisions: '版本',
        enable: '激活',
        userGroupName: '用户组名称',
        tcmApplicants: 'TCM 申请人',
        operationPersonnel: 'TCM 操作人员',
        comments: '备注',
        symbol: '货币符号',
        currency: '币种',
        assignModules: '模块分配角色',
        assignUsers: '用户分配角色',
        roleName: '角色名称',
        allocatedModules: '分配模块',
        assignedUsers: '分配用户',
        organizationName: '组织名称',
        hierarchy: '等级',
        sequence: '次序',
        parentNodeName: '父节点名称',
        parent: '本体',
        path: '路径',
        pleaseNumber: '请输入账号',
        pleaseFirstName: '请输入姓名',
        pleasePhoneNumber: '请输入手机号',
        pleaseEmailaddress: '请输入邮箱',
        pleasePhoneNumbers: '请输入正确的手机号码!',
        pleaseEmailaddresss: '请输入正确的电子邮件地址!',
        pleaseDepartments: '请选择部门',
        pleaseGender: '请选择性别',
        pleaseCurrency: '请输入货币名称',
        pleaseSymbol: '请输入货币符号',
        selecWant: '选择要操作的数据!',
        pleaseRole: '请输入角色名称！',
        pleaseDepartment: '请选择部门根目录！',
        pleaseEnterDepartment: '请输入部门',
        pleaseCommodity: '请输入商品名称！',
        quoteType: '请求类型',
        kb_Lamination: 'kB_层压',
        sy_Lamination: 'sY_层压',
        type: '类型',
        man: '男',
        woman: '女',
        modify: '修改',
        editUser: '用户编辑',
        existingMenu: '已有菜单',
        deleteOne: '一次仅可删除一条数据！',
        deleteNoStart: '仅可删除未开始的数据！',
        deleteFinished: '仅可删除未完成的数据！',
        onlySystemOperate: '仅管理员可进行操作！'
    },
    // 英文
    EN: {

        search: 'Search',
        user: 'New User',
        jobNumber: 'Account',
        role: 'Role',
        mailbox: 'Mailbox',
        name: 'Name',
        role: 'role',
        phone: 'phone',
        commodity: 'Commodity',
        isEnable: 'Is Enable',
        operate: 'Operate',
        password: 'Password',
        surePassword: 'Confirm Password',
        changePassword: 'Change Password',
        department: 'Department',
        commodityType: 'CommodityType',
        sex: 'Sex',
        thePassword: 'The default password is the initial password',
        cancel: 'Cancel',
        confirm: 'Confirm',
        id: 'ID',
        names: 'Name',
        currentCurrency: 'Current Currency',
        reportCurrency: 'Report Currency',
        exchangeRate: 'Exchange Rate',
        version: 'Version',
        batchEnable: 'Batch Enable',
        batchDisabling: 'Batch Disabling',
        revisions: 'Revisions',
        enable: 'Enable',
        userGroupName: 'User Group Name',
        tcmApplicants: 'TCM Applicants',
        operationPersonnel: 'TCM Operation Personnel',
        comments: 'Comments',
        symbol: 'Symbol',
        currency: 'Currency',
        assignModules: 'Assign Modules To Roles',
        assignUsers: 'Assign Users To Roles',
        roleName: 'Role Name',
        allocatedModules: 'Allocated Modules',
        assignedUsers: 'Assigned Users',
        organizationName: 'Organization Name',
        hierarchy: 'Hierarchy ID',
        sequence: 'Sequence',
        parentNodeName: 'Parent Node Name',
        parent: 'Parent',
        path: 'Path',
        pleaseNumber: 'Please enter account number',
        pleaseFirstName: 'Please enter First name&Last name',
        pleasePhoneNumber: 'Please enter mobile phone number',
        pleasePhoneNumbers: 'Please enter the correct mobile phone number',
        pleaseEmailaddress: 'Please enter emailaddress',
        pleaseEmailaddresss: 'Please enter the correct emailaddress',
        pleaseDepartments: 'Please select Department',
        pleaseGender: 'Please select gender',
        pleaseCurrency: 'Please input currency',
        pleaseSymbol: 'Please input currency symbol',
        selecWant: 'Selec the data you want',
        pleaseRole: 'Please enter the name of the role',
        pleaseDepartment: 'Please select the root directory of the department!',
        pleaseEnterDepartment: 'Please enter the department',
        pleaseCommodity: 'Please enter the commodity',
        quoteType: 'Request Type',
        kb_Lamination: 'kB_Lamination',
        sy_Lamination: 'sY_Lamination',
        type: 'Type',
        man: 'man',
        woman: 'woman',
        modify: 'Modify',
        editUser: 'Edit User',
        existingMenu: 'Existing menu',
        deleteOne: 'Only one piece of data can be deleted at a time!',
        deleteNoStart: 'Only unstarted data can be deleted!',
        deleteFinished: 'Only unfinished data can be deleted!',
        onlySystemOperate: 'Only administrators can operate!',
    }


}
export default info