<template>
	<div class="tcm-add">
		<div class="tcm-title">
			<div style="margin-right: 30px;" v-show="type != 'add'">{{$t('changePriceLists.changePriceList.document')}} # :{{documentNo}}</div>
			<div  v-show="type != 'add'">{{$t('changePriceLists.changePriceList.date')}} :{{doDate}}</div>
			<div class="operate-cla"  v-show="type != 'detail'">
				<div class="operate-btn" @click="submit">{{$t('changePriceLists.changePriceList.submit')}}</div>
			</div>
		</div>
		<!-- <div class="hover-menu">
			<div :class="chooseCurrent == 'fx' ? 'choose-btn' : 'hover-btn'" style="margin-bottom: 10px;" @click="chooseMenu('fx')">FX</div>
			<div :class="chooseCurrent == 'gen' ? 'choose-btn' : 'hover-btn'" @click="chooseMenu('gen')">Gen</div>
		</div> -->
		<!-- <TcmFx v-show="chooseCurrent == 'fx'" ref="fx" :type="type"></TcmFx> -->
		<TcmGen v-show="chooseCurrent == 'gen'" ref="gen" :type="type" operateType="application" :QuoteTypeOptions="typeOptions" :RegionOptions="regionOptions" :CommodityOptions="CommodityOptions" :urgencyOptions="urgencyOptions" :urgencyOptionss="urgencyOptionss" :currencyOptions="currencyOptions" :currencyReportOptions="currencyReportOptions"></TcmGen>
	</div>
</template>

<script>
	import TcmFx from './tcmfx.vue'
	import TcmGen from './tcmgen.vue'
	import {getQuoteTypes, getRegions, GetOrgs, getCategorys, uploadFile, AddOrUpdateForAppllicant, GetApplyDetail, GetSelCurrency} from '../../assets/js/requestApi.js'
	import commonUtil from '../../assets/js/commonUtil.js'
	export default{
		name: 'TcmAdd',
		components: {
			TcmFx,
			TcmGen
		},
		data() {
			return {
				id: '',
				type: 'add',
				chooseCurrent: 'gen',
				// 报价类型选项
				typeOptions: [],
				// 区域选项
				regionOptions: [],
				// 商品分类选项
				CommodityOptions: [],
				// 等级选项
				urgencyOptions: [],
            	urgencyOptionss: [],
				// 上传后的文件列表
				pictureList: [],
				// 是否上传结束
				uploadStatus: false,
				// 税率表区域选项
				currencyOptions: [],
				currencyReportOptions: [],
				// 操作时间
				doDate: '',
				// 序号
			}
		},
		mounted() {
			this.$route.meta.showTag = true
			this.getQuoteType()
			this.getRegion()
			this.getCommdity()
			this.getCategorysOptions()
			this.getCategorysOptionss();
			this.getCurrencyOptions({
				CurrId: '',
				CurrCNYId: '',
				isEnable: 1,
			})
			this.type = this.$route.query.type
			if (this.$route.query.id) {
				this.id = this.$route.query.id
				this.getDetail()
			}
		},
		watch: {
			uploadStatus: {
				handler(nval, oval) {
					if (nval) {
						this.addTcm()
					}
				},
				deep: true
			}
		},
		methods: {
			// 获取报价类型选项
			getQuoteType() {
				getQuoteTypes({
					Keyword: '',
					isEnable: 1,
					page: 1,
					limit: 9999,
					key: ''
				}, response=>{
					if (response && response.code == 200) {
						this.typeOptions = response.data
						console.log(response.data,"报价类型");
					}
				})
			},
			// 获取区域列表选项
			getRegion() {
				getRegions({
					Keyword: '',
					isEnable: 1,
					page: 1,
					limit: 9999,
					key: ''
				}, response=>{
					if (response && response.code == 200) {
						this.regionOptions = response.data
					}
				})
			},
			// 获取商品分类数据
			getCommdity() {
				let _this = this
				GetOrgs({
					type: 0,
					versionType: 0,
					TypeCost: 1,
					isAll: 0
				}, response=>{
					if (response && response.Code == 200) {
						this.CommodityOptions = commonUtil.transferList(response.Result) 
						console.log(this.CommodityOptions)
					}
					
				})
			},
			// 获取等级选项
			getCategorysOptions() {
				getCategorys({
					TypeID: 'ApplyUrgency',
					Keyword: '',
					page: 1,
					limit: 9999,
					key: ''
				}, response=>{
					if (response && response.code == 200) {
						this.urgencyOptions = response.data
					}
				})
			},
			getCategorysOptionss() {
				getCategorys(
					{
						TypeID: "ApplyUrgency",
						Keyword: "",
						page: 1,
						limit: 9999,
						key: "",
					},
					(response) => {
						if (response && response.code == 200) {
							response.data.forEach((e) => {
								e.name = e.description;
							});
							this.urgencyOptionss = response.data;
						}
					}
				);
			},
			// 查询税率表区域选项
			getCurrencyOptions(data) {
				GetSelCurrency(data, response=>{
					if (response && response.code == 200) {
						this.currencyOptions = response.data[0].exchangeList
						this.currencyReportOptions = response.data[0].exchangeCNYList
						this.$refs.gen.genCurrOptions[0] = {
							currencyOptions: this.currencyOptions,
							currencyReportOptions: this.currencyReportOptions
						}
						console.log('this.$refs.gen.genCurrOptions')
						console.log(this.$refs.gen.genCurrOptions)
					}
					else{
                  commonUtil.showTipInfo(this.$t('userlists.userlist.inqueryTimeout'))
			    }
					console.log(response)
				})
			},
			// 获取详情
			getDetail() {
				let ids = []
				ids.push(this.id)
				GetApplyDetail(ids, response=>{
					if (response && response.code == 200) {
						let data = response.result[0]
						this.documentNo = data.documentNo
						this.doDate = data.doDate
						if (data.exchangeRate != null) {
							// console.log(this.$refs.fx.form.Currency)
							// this.$refs.fx.form.Currency = data.exchangeRate.currency
							// this.$refs.fx.form.ReportCurrency = data.exchangeRate.currencyCNYReportCurrency
							// this.$refs.fx.form.rate = data.exchangeRate.exchangeRate
							// this.$refs.fx.form.currencyRateId = data.exchangeRate.id
						}
						this.$refs.gen.form.quoteTypeId = data.quoteTypeId
						this.$refs.gen.form.programName = data.programName
						this.$refs.gen.form.ecrno = data.ecrno
						this.$refs.gen.form.commodityId = data.commodityId
						this.$refs.gen.form.partDescription = data.partDescription
						this.$refs.gen.form.partNumber = data.partNumber
						this.$refs.gen.form.partRevision = data.partRevision
						this.$refs.gen.form.lotSize = data.lotSize
						this.$refs.gen.form.productionYear = data.productionYear
						this.$refs.gen.form.doDate = data.doDate
						this.$refs.gen.form.urgencyId = data.urgencyId
						if (data.exchangeRate != null) {
							this.$refs.gen.genList[0].Currency = data.exchangeRate.currency
							this.$refs.gen.genList[0].ReportCurrency = data.exchangeRate.currencyCNYReportCurrency
							this.$refs.gen.genList[0].rate = data.rate
							this.$refs.gen.genList[0].currencyRateId = data.exchangeRate.id
						}
						this.$refs.gen.genList[0].regionId = data.regionId
						this.$refs.gen.genList[0].supplierName = data.supplierName
						this.$refs.gen.genList[0].materialDescription = data.materialDescription
						this.$refs.gen.genList[0].peakVolume = data.peakVolume
						this.$refs.gen.genList[0].lifetimeVolume = data.lifetimeVolume
						this.$refs.gen.genList[0].pegPoint = data.pegPoint
						this.$refs.gen.genList[0].yearVolumeList = data.yearVolumeList
						this.$refs.gen.genList[0].comment = data.comment
						this.$refs.gen.mailTable = data.mailList
						this.$refs.gen.form.mailComment = data.mailComment
						this.$refs.gen.fileListx = data.attachmentList
						/*zj 2023.9.14 新增字段 status*/
						this.$refs.gen.status=data.status;
                    	console.log("--------",data.status,data)
					}
					console.log(response)
				})
			},
			chooseMenu(val) {
				this.chooseCurrent = val
			},
			// 确定按钮点击事件
			submit() {
				console.log(this.$route)
				let genForm = this.$refs.gen.form
				let genList = this.$refs.gen.genList
				if (genForm.quoteTypeId === '' || genForm.quoteTypeId === null || !genForm.quoteTypeId) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.requestRequired'), '', 'warning');
					return;
				}
				if ((genForm.quoteTypeName === 'New pursuit' || genForm.quoteTypeName === 'Soucing') && (genForm.programName === '' || genForm.programName === null || !genForm.programName)) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.projectRequired'), '', 'warning');
					return;
				}
				if ((genForm.quoteTypeName === 'New pursuit') && (genForm.ecrno === '' || genForm.ecrno === null || !genForm.ecrno)) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.ecrNoRequired'), '', 'warning');
					return;
				}
				if (genForm.commodityId === '' || genForm.commodityId === null || !genForm.commodityId) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.commodityRequired'), '', 'warning');
					return;
				}
				if (genForm.partDescription === '' || genForm.partDescription === null || !genForm.partDescription) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.partRequired'), '', 'warning');
					return;
				}
				if ((genForm.quoteTypeName === 'New pursuit' || genForm.quoteTypeName === 'Soucing' || genForm.quoteTypeName === 'SOP') && (genForm.partNumber === '' || genForm.partNumber === null || !genForm.partNumber)) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.partNumberRequired'), '', 'warning');
					return;
				}
				let currency = false
				let reportCurrency = false
				let rate = false
				let region = false
				genList.forEach(function(val, index) {
					if (val.Currency === '' || val.Currency === null || !val.Currency) {
						currency = true
						return;
					} else if (val.ReportCurrency === '' || val.ReportCurrency === null || !val.ReportCurrency) {
						reportCurrency = true
						return;
					} else if (val.rate === '' || val.rate === null || !val.rate) {
						rate = true
						return;
					} else if (val.regionId === '' || val.regionId === null || !val.regionId) {
						region = true
						return;
					}
				})
				if (currency) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.currencyRequired'), '', 'warning');
					return;
				}
				if (reportCurrency) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.reportRequired'), '', 'warning');;
					return;
				}
				if (rate) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.exRquired'), '', 'warning');
					return;
				}
				if (region) {
					commonUtil.showTipInfo(this.$t('changePriceLists.changePriceList.regionRquired'), '', 'warning');
					return;
				}
				let hasOperator = false
				this.$refs.gen.mailTable.forEach(function(val, index) {
					if (val.type == 1) {
						hasOperator = true
					}
				})
				if (this.$refs.gen.mailTable.length === 0 || (this.$refs.gen.mailTable.length > 0 && !hasOperator)) {
					commonUtil.showTipInfo(this.$t('unifiedPrompts.unifiedPrompt.chooseEmail'), '', 'warning');
					return;
				}
				let pictures = this.$refs.gen.fileListx
				this.uploadFiles(pictures)
			},
			addTcm() {
				// let fxForm = this.$refs.fx.form
				let genForm = this.$refs.gen.form
				let genList = this.$refs.gen.genList
				let mailTable = this.$refs.gen.mailTable
				// console.log(fxForm)
				let userIds = []
				let mailCCIds = []
				let mailCCMails = []
				mailTable.forEach(function(val, index) {
					if (val.type == 1) {
						userIds.push(val.id)
					}
					if (val.type == 3) {
						mailCCIds.push(val.id)
					}
					if (val.type == 4) {
						mailCCMails.push(val)
					}
				})
				let data = {
					id: this.id,
					templeteId: '',
					// rate: fxForm.rate,
					// currencyRateId: fxForm.currencyRateId,
					quoteTypeId: genForm.quoteTypeId,
					programName: genForm.programName,
					ecrno: genForm.ecrno,
					commodityId: genForm.commodityId,
					partDescription: genForm.partDescription,
					partNumber: genForm.partNumber,
					partRevision: genForm.partRevision,
					lotSize: genForm.lotSize,
					productionYear: genForm.productionYear,
					doDate: genForm.doDate,
					urgencyId: genForm.urgencyId,
					// comment: genForm.comment,
					genList: genList,
					attachmentList: this.pictureList,
					mailList: [],
					UserId: userIds.toString(),
					mailCCIds: mailCCIds.toString(),
					mailCCMails: mailCCMails,
					mailComment: genForm.mailComment
				}
				console.log(data)
				// console.log(this.$parent.$parent.$parent)
				AddOrUpdateForAppllicant(data, response=>{
					if (response && response.code == 200) {
						commonUtil.showTipInfo(this.$t('unifiedPrompts.unifiedPrompt.addedSuccess'), '', 'success')
						this.$refs.gen.emptyInput()
						// this.getCurrencyOptions({
						// 	CurrId: '',
						// 	CurrCNYId: '',
						// 	isEnable: 1,
						// })
						this.$route.meta.showTag = false
						this.$router.back(-1)
					}
				}, errorRes=>{
					this.uploadStatus = false
				})
			},
			// 文件上传
			uploadFiles(files) {
				let _this = this
				this.pictureList = []
				this.uploadStatus = false
				let format = new FormData()
				// 需要上传的文件
				let uploadList = []
				files.forEach(function(value, key) {
					if (value.raw) {
						format.append('file', value.raw)
						uploadList.push(value)
					} else {
						_this.pictureList.push(value)
					}
					
				})
				if (uploadList.length > 0) {
					console.log('需要上传')
					uploadFile(format, response=>{
						if (response && response.code == 200) {
							response.data.forEach(function(val, key) {
								_this.pictureList.push(val)
							})
							_this.uploadStatus = true
						}
					})
				} else {
					console.log('不需要上传')
					_this.uploadStatus = true
				}
			},
		}
	}
</script>

<style scoped>
	.tcm-add{
		position: relative;
		padding-top: 70px;
	}
	.tcm-title{
		width: calc(100% - 202px);
		border-bottom: 2px solid #9a9a9a;
		padding: 20px;
		box-sizing: border-box;
		font-size: 14px;
		display: flex;
		font-weight: bold;
		position: relative;
		height: 61px;
		position: fixed;
		/* justify-content: end; */
		top: 114px;
		z-index: 10;
		background: #fff;
	}
	.hover-menu{
		width: 80px;
		height: 120px;
		padding: 15px 0;
		box-sizing: border-box;
		background-color: rgba(215, 215, 215, 0.474509803921569);
		border-radius: 5px;
		position: fixed;
		top: 150px;
		right: 0;
		z-index:888;
	}
	.hover-btn{
		font-size: 18px;
		font-weight: bold;
		width: 50px;
		color: #fff;
		background: rgba(174, 174, 174, 0.623529411764706);
		text-align: center;
		border-radius: 5px;
		padding: 8px 0;
		margin-left: 30px;
		cursor: pointer;
	}
	.choose-btn{
		font-size: 18px;
		font-weight: bold;
		width: 50px;
		margin-left: 30px;
		text-align: center;
		border-radius: 0px 5px 5px 0;
		padding: 8px 0;
		background-color: #fff;
		border-left: 4px solid var(--themeColor);
		cursor: pointer;
	}
	.operate-cla{
		position: absolute;
		right: 10px;
		top: 12px;
	}
	.operate-btn{
		border-radius: 5px;
		background-color: var(--themeColor);
		color: #fff;
		text-align: center;
		padding: 7px 10px;
		display: inline-block;
		min-width: 80px;
		cursor: pointer;
	}
</style>