<template>
    <div class="tcm-list">
        <div class="tcm-search" v-show="status != 'detail'">
            <el-row>
                <el-col :span="24">
                    <div>
                        <div class="operate-btn mgr-10" @click="addGrade">{{$t('unifiedPrompts.unifiedPrompt.new')}}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
		<div class="padding-20">
			<el-select clearable   filterable v-model="type" :disabled="status == 'detail'" placeholder="" @change="changeType" style="width: 214px">
				<el-option
				    v-for="item in typeOptions"
				    :key="item"
				    :label="item"
				    :value="item"
				/>
			</el-select>
		</div>
        <div class="tcm-table">
            <el-table :empty-text="$t('tcmlists.tcmlist.noData')" ref="roleTable" :data="tableData" border style="width: 100%">
				<el-table-column :label="$t('ccls.ccl.operate')" align="center" width="160" v-if="status != 'detail'">
				    <template #default="scope">
				        <div class="flex-around">
				            <div class="operate-btnx" @click="edit(scope.row, scope.$index)">{{$t('unifiedPrompts.unifiedPrompt.edit')}}</div>
				            <div class="operate-btnx" @click="del(scope.row)">{{$t('unifiedPrompts.unifiedPrompt.delete')}}</div>
				        </div>
				    </template>
				</el-table-column>
                <el-table-column prop="gradeFerrousName" label="Grade" align="center" />
                <el-table-column prop="operation_DetailsName" label="Operation Details" align="center" show-overflow-tooltip />
                <el-table-column prop="section_Profile" label="Section Profile" align="center" />
                <el-table-column prop="length" label="Length" align="center" />
                <el-table-column prop="width" label="Width" align="center" />
                <el-table-column prop="circle_OD" label="OD" align="center" />
                <el-table-column prop="circle_ID" label="ID" align="center" />
                <el-table-column prop="thk" label="Thickness" align="center" />
                <el-table-column prop="total_Length_Of_Cut_bend" label="Total Length of Cut / bend / Form" align="center" />
                <el-table-column prop="calculated" label="Calculated" align="center" />
                <el-table-column prop="manual" label="Manual" align="center" />
                <el-table-column prop="type_Of_Strength" label="Type of Strength" align="center" />
                <el-table-column prop="stripping_Force" label="Stripping Force" align="center" />
                <el-table-column prop="fos" label="FOS" align="center" />
                <el-table-column prop="calculated_Tonnage" label="Calculated Tonnage" align="center" />
                <!-- <el-table-column prop="total_CalculatedTonnage" label="Recommended Tonnage" align="center" /> -->
            </el-table>
        </div>
        <div class="flex-between">
            <div class="total-text"></div>
            <div class="page-cla">
                <div>
                    <el-input v-model="searchForm.limit" @input="val=> searchForm.limit = val.replace(/[^\d]/g, '')" min="1" style="width: 130px" @change="handleSizeChange">
                        <template #suffix>
                            {{$t('ccls.ccl.barPage')}}
                        </template>
                    </el-input>
                </div>
                <el-pagination layout="prev, pager, next" :total="total" :page-size="pageSize" :current-page="searchForm.page" @current-change="handleCurrentChange" />
            </div>

        </div>
		<div class="total-text">
			<el-row :gutter="10">
				<el-col :span="3" class="align-right"> Total Tonnage：</el-col>
				<el-col :span="3">
					<el-input v-model="total_CalculatedTonnage" disabled placeholder="" style="width: 100%"/>
				</el-col>
				<el-col :span="3" class="pl-10">T</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="3" class="align-right"> Recommended Tonnage：</el-col>
				<el-col :span="3">
					<el-input v-model="calculatedTonnage" disabled placeholder="" style="width: 100%;"/>
				</el-col>
				<el-col :span="3" class="pl-10">T</el-col>
				<el-col :span="3" v-show="status != 'detail'">
					<div class="operate-btn mgr-10" @click="computed">Compute</div>
				</el-col>
			</el-row>
		</div>
        <AddTonnageCalculator ref="addGrade" @handleSubmit="addSubmit"></AddTonnageCalculator>
    </div>
</template>

<script>
import AddTonnageCalculator from "./addTonnageCalculator.vue";
import commonUtil from "../../assets/js/commonUtil.js";
import {
	LoadStamping,
    GetTonnageCost,
    GetRecommendedTonnageCost,
	AddOrUpdateStampingMain,
	DeleteStamping,
	GetComputeMainResultRes,
	UpdateTypeStamping,
	GetStampingDetail
} from "@/assets/js/requestApi.js";
export default {
	props: ['applyId', 'status'],
    components: {
        AddTonnageCalculator,
    },
    data() {
        return {
			id: '',
            searchForm: {
                mainId: "",
                keyword: "",
				key: '',
                page: 1, //当前页
                limit: 10, //每页个数
            },
            tableData: [],
            total: 0,
            pageSize: 10,
            // 已勾选的数据
            chooseData: [],
			// 类型选择项
			type: 'Max',
			typeOptions: ['Max', 'Avg', 'Min'],
            power: [],
            btnAdd: "",
            btnDel: "",
            btnEdit: "",
            timer: "",
            // 总值
            total_CalculatedTonnage: 0,
            // 吨数值
            calculatedTonnage: 0,
            // 数据操作状态
            operateType: "",
            // 数据操作下标
            operateIndex: "",
        };
    },
    mounted() {
		if (this.$route.query.id) {
			// this.id = this.$route.query.id
			// this.type = this.$route.query.calculatedType
			// this.searchForm.mainId = this.$route.query.id
			// this.total_CalculatedTonnage = this.$route.query.totalTonnage
			// this.calculatedTonnage = this.$route.query.recommendTonnage
			// this.getDetail()
			// this.initList();
		}
        this.timer = setTimeout(this.gettime, 1000);
    },
    methods: {
        gettime() {
            this.power = JSON.parse(localStorage.getItem("power"));
            this.power.forEach((e) => {
                if (e.name == "添加" || e.name == "Add") {
                    this.btnAdd = 1;
                }
                if (e.name == "删除" || e.name == "Delete") {
                    this.btnDel = 1;
                }
                if (e.name == "编辑" || e.name == "Edit") {
                    this.btnEdit = 1;
                }
            });
            clearTimeout(this.timer);
        },
		// 获取主表详细信息
		getDetail() {
			GetStampingDetail({
				id: this.id
			}, response=>{
				console.log(response)
				if (response && response.code == 200) {
					if (response.result) {
						this.type = response.result.type
						this.total_CalculatedTonnage = response.result.tonnage
						this.calculatedTonnage = response.result.recommendedTonnage
					}
					
				}
			})
		},
        // 渲染列表数据
        initList(id) {
			this.id = id
			this.searchForm.mainId = id
            this.searchForm.page = 1
            this.searchForm.limit = 10
            this.search()
        },
        // 新增
        addGrade() {
            this.operateType = "add";
			this.$refs.addGrade.form.mainId = this.id
			this.$refs.addGrade.form.type = this.type
            // this.$refs.addGrade.init();
            this.$refs.addGrade.visible = true;
        },
        addSubmit(form) {
			form.costApplyId = this.applyId
            AddOrUpdateStampingMain(form, (response) => {
                console.log(response);
                if (response && response.code == 200) {
					// this.searchForm.mainId = response.result
					// this.id = response.result
					this.initList(response.result)
                }
            });
        },
        // 查询
        search() {
            LoadStamping(this.searchForm, (response) => {
              if (response && response.code == 200) {
                this.tableData = response.data;
                this.total = response.count;
              }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.searchForm.page = val;
            this.search();
        },
        // 分页数量修改
        handleSizeChange() {
            this.pageSize = parseInt(this.searchForm.limit);
            if (this.searchForm.limit === "") {
                commonUtil.showTipInfo(
                    "请设置每页数据展示数量！",
                    "",
                    "warning"
                );
                return;
            }
            if (this.searchForm.limit == 0) {
                commonUtil.showTipInfo("每页数据需大于0！", "", "warning");
                return;
            }
            this.searchForm.page = 1;
            this.search();
        },
        // 批量操作
        bathOperate(type) {
            if (this.chooseData.length == 0) {
                commonUtil.showTipInfo(
                    this.$t("userlists.userlist.selecWant"),
                    "",
                    "warning"
                );
                return;
            }
            let ids = [];
            this.chooseData.forEach(function (val, key) {
                ids.push(val.id);
            });
            if (type == "start") {
                // 启用
                GradeFerroussEnableByIds(ids, (response) => {
                    commonUtil.showTipInfo("启用成功！", "", "success");
                    this.search();
                });
            } else if (type == "stop") {
                // 禁用
                GradeFerroussDisableByIds(ids, (response) => {
                    commonUtil.showTipInfo("禁用成功！", "", "success");
                    this.search();
                });
            } else if (type == "delete") {
                // 删除
                GradeFerroussDelete(ids, (response) => {
                    commonUtil.showTipInfo(
                        this.$t(
                            "unifiedPrompts.unifiedPrompt.deleteSuccessfully"
                        ),
                        "",
                        "success"
                    );
                    this.search();
                });
            }
        },
        // 导出
        Export() {},
        // 编辑
        edit(row, index) {
            this.operateType = "edit";
            this.operateIndex = index;
            // this.$refs.addGrade.init();
            this.$refs.addGrade.form = {
				id: row.id,
				mainId: this.id,
				gradeFerrousId: row.gradeFerrousId,
				operation_DetailsId: row.operation_DetailsId,
				section_Profile: row.section_Profile,
				length: row.length,
				width: row.width,
				circle_OD: row.circle_OD,
				circle_ID: row.circle_ID,
				thk: row.thk,
				total_Length_Of_Cut_bend: row.total_Length_Of_Cut_bend,
				calculatedMax: row.calculatedMax,
				calculated: row.calculated,
				manual: row.manual,
				type_Of_Strength: row.type_Of_Strength,
				stripping_Force: '25',
				fos: '15',
				calculated_Tonnage: row.calculated_Tonnage,
				type: this.type
			}
            this.$refs.addGrade.visible = true;
        },
        // 删除
        del(row) {
			let ids = []
			ids.push(row.id)
            DeleteStamping(ids, (response) => {
                commonUtil.showTipInfo(
                    this.$t(
                        "unifiedPrompts.unifiedPrompt.deleteSuccessfully"
                    ),
                    "",
                    "success"
                );
                this.search();
            });
        },
        // 计算总值
        computed() {
            let _this = this;
            GetComputeMainResultRes({
				id: this.id
			}, (response) => {
				 if (response && response.code == 200) {
					if (response.result) {
						this.total_CalculatedTonnage = response.result.totalTonnage;
						this.calculatedTonnage = response.result.recommendedTonnage;
					}
				}
            });
        },
		// 修改类型
		changeType() {
			if (this.id != '') {
				UpdateTypeStamping({
					mainId: this.id,
					type: this.type
				}, response=>{
					console.log(response)
					if (response && response.code == 200) {
						commonUtil.showTipInfo('Change success!', "", "success");
						this.search()
					}
				})
			}
			
		}
    },
};
</script>

<style scoped>
.tcm-search {
    width: 100%;
    /* background-color:var(--searchBg); */
    padding: 20px;
    box-sizing: border-box;
}
.tcm-search td {
    /* width: 20%; */
    /* padding: 0 10px; */
    vertical-align: center;
}
.operate-btn {
    margin-right: 10px;
    border-radius: 5px;
    background-color: var(--themeColor);
    color: #fff;
    text-align: center;
	line-height: 14px !important;
    padding: 7px 10px;
    display: inline-block;
    min-width: 80px;
    cursor: pointer;
}
.color-ff0000 {
    color: #ff0000;
}
.color-F59A23 {
    color: #f59a23;
}
.color-0000FF {
    color: #0000ff;
}
.color-D9001B {
    color: #d9001b;
}
.mgr-10 {
    margin-right: 10px;
}
.page-cla {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}
.flex-around {
    display: flex;
    justify-content: space-around;
}
.operate-btnx {
    border-radius: 5px;
    background-color: var(--themeColor);
    color: #fff;
    font-size: 12px;
	
    text-align: center;
    padding: 2px 5px;
    min-width: 60px;
    cursor: pointer;
}
.align-right {
    text-align: right;
}
.flex-between {
    display: flex;
    justify-content: space-between;
}
.total-text {
    line-height: 40px;
    padding-left: 10px;
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
}
.padding-20{
	padding: 20px;
	box-sizing: border-box;
}
.align-right{
	text-align: right;
}
.pl-10{
	padding-left: 10px !important;
}
</style>