import Homepage from "@/pages/homepage/homepage.vue"

const info = {
    // 中文
    ZH: {
        realTime: '实时数据',
        static: '数据统计',
        changePwd: '修改密码',
        logout: '退出登录',
        Pending: '待处理',
        all: '所有任务',
        template: '可用模板',
        Completed: '已处理',
        text: '耐世特成本工程数字化系统',

        toItems: '待办事项',
        year: '年',
        month: '月',
        lastMonth: '上个月',
        today: '今天',
        nextMonth: '下个月',
        day: '日',
        one: '一',
        two: '二',
        three: '三',
        four: '四',
        five: '五',
        six: '六',
        task: '任务',
        no: '编号.',
        daiban: 'daibans',
        locale: 'locale',
        backlog: '待办事项',
        recentlyOpened: '最近浏览',
        rencentlyContact: '最近联系',
        Back: '返回',
        WelcomeSystem: '欢迎登入VEM数字系统',
        Homepage: '首页',
        Application: '申请',
        Action: '操作',
        Showing: '显示',
        From: '来自',
        Data: '数据',
        Callback: '驳回',
        AttachFile: '附件',
        Operation: "操作",
        Gen: '基本信息',
        Rm: '原材料',
        PUR: '采购件和外购加工',
        PRO: '工序',
        Pl: '包装，物流，摊销，关税和海关',
        CostSummary: '成本汇总',
        MyDraft: '我的草稿',
        CC: '抄送',
        TCMData: 'TCM数据库',
        Compare: '对比',
        Summary: '汇总',
        More: '更多',
        ProcessingModule: '工艺模板',
        Components: '采购模板',
        MaterialModule: '物料模板',
        CBDPriceChange: 'CBD改价',
        Template: '模板管理',
        Individualtemplates: '个人模板',
        ProcessCalculator: '工艺计算器',
        ProcessStamping: '工序冲压',
        TonnageCalculator: '吨位计算器',
        TotalTonnage: '总吨数',
        RecommendedTonnage: '建议吨数',
        Grade: '等级',
        OperationDetails: '操作细节',
        SectionProfile: '部分资料',
        Length: '长度',
        Width: '宽度',
        Thickness: '厚度',
        TotalLength_Cut_bend_Form: '切割/弯曲/成形的总长度',
        Calculated: '计算',
        Manual: '说明书',
        TypeStrength: '强度类型',
        StrippingForce: '脱模力',
        CalculatedTonnage: '吨位计算',
        Compute: '计算',
        CCLSize: 'CCL 尺寸',
        UserManagement: '用户管理',
        ModulesManagement: '模块管理',
        RequestQuoteType: '报价类型',
        RegionManagement: '区域管理',
        Warning: '预警',
        Contacts: '联系人',
        ExpiredTask: '超期任务',
        All: '全部',
        NoData: '没有数据',
    },
    // 英文
    EN: {
        realTime: 'Real-time Data',
        static: 'Data Statistics',
        changePwd: 'Change Password',
        logout: 'Log Out',
        Pending: 'Pending',
        All: 'All TCM',
        template: 'Be used as a template',
        Completed: 'Completed',
        text: 'Welcome to Nexteer TCM Digital  System !',

        toItems: 'To-do items',
        year: 'Year',
        month: 'Month',
        lastMonth: 'Last Month',
        today: 'Today',
        nextMonth: 'Next Month',
        day: 'Day',
        one: 'One',
        two: 'Two',
        three: 'Three',
        four: 'Four',
        five: 'Five',
        six: 'Six',
        task: 'Task',
        no: 'No.',
        daiban: 'daiban',
        locale: 'locales',
        backlog: 'Pending Items',
        recentlyOpened: 'Recently Opened Items',
        rencentlyContact: 'Recently Contacts',
        Back: 'Back',
        WelcomeSystem: 'Welcome To VEM Digital System',
        Homepage: 'Home Page',
        Application: 'Application',
        Action: 'Action',
        Showing: 'Showing',
        From: 'From',
        Data: 'Data',
        Callback: 'Callback',
        AttachFile: 'Attach File',
        Operation: "Operation",
        Gen: 'General Information',
        Rm: 'Raw Material',
        PUR: 'Purchased Components&Outside Processing',
        PRO: 'Processing',
        Pl: 'Packaging, Logistics, Amortization, Duties & Customs',
        CostSummary: 'Cost Summary',
        MyDraft: 'My Draft',
        CC: 'C C',
        TCMData: 'TCM Data',
        Compare: 'Compare',
        Summary: 'Summary',
        More: 'More',
        ProcessingModule: 'Processing Module',
        Components: 'Components',
        MaterialModule: 'Material Module',
        CBDPriceChange: 'CBD Price Change',
        Template: 'Template',
        Individualtemplates: 'Individual Templates',
        ProcessCalculator: 'Process Calculator',
        ProcessStamping: 'Process Stamping',
        TonnageCalculator: 'Tonnage Calculator',
        TotalTonnage: 'Total Tonnage',
        RecommendedTonnage: 'Recommended Tonnage',
        Grade: 'Grade',
        OperationDetails: 'Operation Details',
        SectionProfile: 'Section Profile',
        Length: 'Length',
        Width: 'Width',
        Thickness: 'Thickness',
        TotalLength_Cut_bend_Form: 'Total Length of Cut / bend / Form',
        Calculated: 'Calculated',
        Manual: 'Manual',
        TypeStrength: 'Type of Strength',
        StrippingForce: 'Stripping Force',
        CalculatedTonnage: 'Calculated Tonnage',
        Compute: 'Compute',
        CCLSize: 'CCL Size',
        UserManagement: 'User Management',
        ModulesManagement: 'Modules Management',
        RequestQuoteType: 'Request Quote Type',
        RegionManagement: 'Region Management',
        ExchangeRate: 'Exchange Rate',
        Warning: 'Warning',
        Contacts: 'Contacts',
        ExpiredTask: 'Expired Task',
        All: 'All',
        NoData: 'No Data',

        // :label="$t('homePage.home.Action')"
        // {{ $t("homePage.home.Back") }}
        // {{ $t("ccls.ccl.submit") }}
        // {{ $t("unifiedPrompts.unifiedPrompt.reject") }}
        // <div class="operate-btn mrg-10" @click="toRevoke">{{$t('unifiedPrompts.unifiedPrompt.revoke')}}</div>
        // <div class="operate-btn mrg-10" @click="toApprove">{{$t('unifiedPrompts.unifiedPrompt.approve')}}</div>
        // <div class="operate-btn mrg-10" @click="toReject">{{$t('unifiedPrompts.unifiedPrompt.reject')}}</div>



        // {{ $t("homePage.home.Showing") }} {{ pageSize }}
        // {{ $t("homePage.home.From") }} {{ total }}
        // {{ $t("homePage.home.Data") }}
    }
}
export default info