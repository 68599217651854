<template>
  <div class="tcm-content">
    <!-- <div class="tcm-content-title">{{$t('changePriceLists.changePriceList.sgaProfitScrap')}}</div> -->
    <div class="tcm-content-form">
      <el-form :model="form" :rules="rules" label-width="300px">
        <div class="form-type-title">
          {{ $t("changePriceLists.changePriceList.sgas") }}
        </div>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.sga')"
              prop="sga"
            >
              <el-input
                v-model="form.sga"
                :disabled="operateType == 'detail'"
                style="width: 214px"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.sgaCost')"
            >
              <el-input v-model="form.sgaCost" disabled style="width: 214px" />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-type-title">
          {{ $t("changePriceLists.changePriceList.profit") }}
        </div>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.profitMaterial')"
            >
              <el-input
                v-model="form.materialProfit"
                :disabled="operateType == 'detail'"
                style="width: 214px"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.profitComponents')"
            >
              <el-input
                v-model="form.addProfit"
                :disabled="operateType == 'detail'"
                style="width: 214px"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.profitValue')"
            >
              <el-input
                v-model="form.purchaseProfit"
                :disabled="operateType == 'detail'"
                style="width: 214px"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalProfit')"
            >
              <el-input
                v-model="form.totalProfit"
                disabled
                style="width: 214px"
              >
                <template #suffix>
                  <el-icon class="el-input__icon">%</el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalprofitCost')"
            >
              <el-input
                v-model="form.totalProfitCost"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tcm-content-form">
      <table class="tcm-content-table">
        <tr>
          <td class="table-title" style="width: 155px; text-align: right">
            {{ $t("changePriceLists.changePriceList.materialScrap") }}
          </td>
          <td style="width: 250px">
            <el-input
              v-model="form.materialPC"
              :disabled="operateType == 'detail'"
              style="width: 214px"
            >
              <template #suffix>
                <el-icon class="el-input__icon">%</el-icon>
              </template>
            </el-input>
          </td>
          <td class="table-title" style="width: 155px"></td>
          <td style="width: 250px"></td>
          <!-- <td class="table-title" style="width: 350px;">Freight + Warehousing Cost</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td> -->
        </tr>
        <!-- <tr>
						<td class="table-title">Material Scrap  Credit</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
						<td class="table-title">Total Sell Price(Supplier Local Currency)</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
					</tr>
					<tr>
						<td class="table-title">Process Scrap Cost</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
						<td class="table-title">Total Sell Price (Customer Paid Currency)</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
					</tr>
					<tr>
						<td class="table-title">Packaging Cost</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
						<td class="table-title">Amortization Cost + Customs, Duties,Taxes & Fees</td>
						<td><el-input v-model="form.ExchangeRate" style="width: 214px;" /></td>
					</tr> -->
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["operateType"],
  data() {
    return {
      form: {
        sga: "",
        sgaCost: "",
        materialProfit: "",
        addProfit: "",
        purchaseProfit: "",
        totalProfit: "",
        totalProfitCost: "",
        materialPC: "",
        ExchangeRate: "",
      },
      // currency选项
      CurrencyOptions: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
      ],
      // ReportCurrency选项
      ReportCurrencyOptions: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
      ],
      rules: {
        sga: [{ required: true, message: "请输入sga", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 计算
    tocompute() {
      console.log("xxxxxxx");
      let threeAll = 0;
      if (
        this.$parent.$refs.rwTcm.materialCost_RM != "" &&
        this.$parent.$refs.purTcm.purchaseCost_PUR != "" &&
        this.$parent.$refs.proTcm.laborCost_PRO != "" &&
        this.$parent.$refs.proTcm.burdenCost_PRO != "" &&
        this.$parent.$refs.proTcm.setUpCost_PRO != "" &&
        this.form.sga != ""
      ) {
        threeAll =
          parseFloat(this.$parent.$refs.rwTcm.materialCost_RM) +
          parseFloat(this.$parent.$refs.purTcm.purchaseCost_PUR) +
          parseFloat(this.$parent.$refs.proTcm.laborCost_PRO) +
          parseFloat(this.$parent.$refs.proTcm.burdenCost_PRO) +
          parseFloat(this.$parent.$refs.proTcm.setUpCost_PRO);
        this.form.sgaCost = (
          (parseFloat(this.$parent.$refs.rwTcm.materialCost_RM) +
            parseFloat(this.$parent.$refs.purTcm.purchaseCost_PUR) +
            parseFloat(this.$parent.$refs.proTcm.laborCost_PRO) +
            parseFloat(this.$parent.$refs.proTcm.burdenCost_PRO) +
            parseFloat(this.$parent.$refs.proTcm.setUpCost_PRO)) *
          (parseFloat(this.form.sga) / 100)
        ).toFixed(3);
        this.form.totalProfitCost = 0;
        if (
          this.form.materialProfit != "" &&
          (this.form.addProfit == "" || this.form.purchaseProfit == "")
        ) {
          console.log(11);
          this.form.totalProfitCost =
            parseFloat(this.form.materialProfit / 100) *
              parseFloat(this.$parent.$refs.rwTcm.materialCost_RM) +
            (this.form.addProfit == ""
              ? 0
              : parseFloat(this.form.addProfit) / 100) *
              parseFloat(this.$parent.$refs.purTcm.purchaseCost_PUR);
          // + ((this.form.purchaseProfit == '' ? 0 : (parseFloat(this.form.purchaseProfit) / 100))) * (parseFloat(this.$parent.$refs.proTcm.laborCost_PRO) + parseFloat(this.$parent.$refs.proTcm.burdenCost_PRO) + parseFloat(this.$parent.$refs.proTcm.setUpCost_PRO))
        } else if (
          this.form.addProfit != "" &&
          (this.form.materialProfit == "" || this.form.purchaseProfit == "")
        ) {
          console.log(22);
          this.form.totalProfitCost =
            ((this.form.materialProfit == ""
              ? 0
              : parseFloat(this.form.materialProfit / 100)) *
              parseFloat(this.$parent.$refs.rwTcm.materialCost_RM) +
              parseFloat(this.form.addProfit) / 100) *
              parseFloat(this.$parent.$refs.purTcm.purchaseCost_PUR) +
            (this.form.purchaseProfit == ""
              ? 0
              : parseFloat(this.form.purchaseProfit) / 100) *
              (parseFloat(this.$parent.$refs.proTcm.laborCost_PRO) +
                parseFloat(this.$parent.$refs.proTcm.burdenCost_PRO) +
                parseFloat(this.$parent.$refs.proTcm.setUpCost_PRO));
        } else if (
          this.form.purchaseProfit != "" &&
          (this.form.materialProfit == "" || this.form.addProfit == "")
        ) {
          console.log(33);
          console.log(this.form.purchaseProfit);
          this.form.totalProfitCost =
            (this.form.materialProfit == ""
              ? 0
              : parseFloat(this.form.materialProfit) / 100) *
              parseFloat(this.$parent.$refs.rwTcm.materialCost_RM) +
            (this.form.addProfit == ""
              ? 0
              : parseFloat(this.form.addProfit) / 100) *
              parseFloat(this.$parent.$refs.purTcm.purchaseCost_PUR) +
            (parseFloat(this.form.purchaseProfit) / 100) *
              (parseFloat(this.$parent.$refs.proTcm.laborCost_PRO) +
                parseFloat(this.$parent.$refs.proTcm.burdenCost_PRO) +
                parseFloat(this.$parent.$refs.proTcm.setUpCost_PRO));
        } else if (
          this.form.materialProfit != "" &&
          this.form.addProfit != "" &&
          this.form.purchaseProfit != ""
        ) {
          console.log(44);
          this.form.totalProfitCost =
            parseFloat(this.form.materialProfit / 100) *
              parseFloat(this.$parent.$refs.rwTcm.materialCost_RM) +
            (parseFloat(this.form.addProfit) / 100) *
              parseFloat(this.$parent.$refs.purTcm.purchaseCost_PUR) +
            (parseFloat(this.form.purchaseProfit) / 100) *
              (parseFloat(this.$parent.$refs.proTcm.laborCost_PRO) +
                parseFloat(this.$parent.$refs.proTcm.burdenCost_PRO) +
                parseFloat(this.$parent.$refs.proTcm.setUpCost_PRO));
        }
        this.form.totalProfitCost = this.form.totalProfitCost.toFixed(3);
        if (
          this.form.sgaCost != "" &&
          this.form.totalProfitCost != "" &&
          this.form.totalProfitCost != 0 &&
          this.$parent.$refs.sumTcm
        ) {
          console.log("this.form.sgaCost this.form.totalProfitCost");
          console.log(this.form.totalProfitCost);
          console.log(this.form.sgaCost);
          this.$parent.$refs.sumTcm.form.sgaProfit_SUM = (
            parseFloat(this.form.sgaCost) +
            parseFloat(this.form.totalProfitCost)
          ).toString();
          console.log(this.$parent.$refs.sumTcm.form.sgaProfit_SUM);
        }
      }
      if (this.$parent.$refs.sumTcm) {
        this.$parent.$refs.sumTcm.form.materialCost_SUM =
          this.$parent.$refs.rwTcm.materialCost_RM;
        this.$parent.$refs.sumTcm.form.setUpCost_SUM =
          this.$parent.$refs.proTcm.setUpCost_PRO;
        this.$parent.$refs.sumTcm.form.laborCost_SUM =
          this.$parent.$refs.proTcm.laborCost_PRO;
        this.$parent.$refs.sumTcm.form.burdenCost_SUM =
          this.$parent.$refs.proTcm.burdenCost_PRO;
        this.$parent.$refs.sumTcm.form.purchaseCost_SUM =
          this.$parent.$refs.purTcm.purchaseCost_PUR;
        console.log("this.$parent.$refs.plTcm.form.freightCost");
        let freightCost = "";
        let wareHouseCost = "";
        let packageCost = "";
        let otherCost = "";
        if (this.$parent.$refs.plTcm.form.calculationMethod == 1) {
          freightCost =
            this.$parent.$refs.plTcm.form.freightCost === "" ||
            !this.$parent.$refs.plTcm.form.freightCost
              ? "0"
              : this.$parent.$refs.plTcm.form.freightCost;
          wareHouseCost =
            this.$parent.$refs.plTcm.form.wareHouseCost === "" ||
            !this.$parent.$refs.plTcm.form.wareHouseCost
              ? "0"
              : this.$parent.$refs.plTcm.form.wareHouseCost;
          packageCost =
            this.$parent.$refs.plTcm.form.packageCost === "" ||
            !this.$parent.$refs.plTcm.form.packageCost
              ? "0"
              : this.$parent.$refs.plTcm.form.packageCost;
          otherCost =
            this.$parent.$refs.plTcm.form.otherCost === "" ||
            !this.$parent.$refs.plTcm.form.otherCost
              ? "0"
              : this.$parent.$refs.plTcm.form.otherCost;
        } else {
          freightCost =
            (this.$parent.$refs.plTcm.form.freightCost === "" ||
            !this.$parent.$refs.plTcm.form.freightCost
              ? "0"
              : parseFloat(this.$parent.$refs.plTcm.form.freightCost) / 100) *
            parseFloat(
              this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
                !this.$parent.$refs.proTcm.manufacturingCost_PRO
                ? "0"
                : this.$parent.$refs.proTcm.manufacturingCost_PRO
            );
          wareHouseCost =
            (this.$parent.$refs.plTcm.form.wareHouseCost === "" ||
            !this.$parent.$refs.plTcm.form.wareHouseCost
              ? "0"
              : parseFloat(this.$parent.$refs.plTcm.form.wareHouseCost) / 100) *
            parseFloat(
              this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
                !this.$parent.$refs.proTcm.manufacturingCost_PRO
                ? "0"
                : this.$parent.$refs.proTcm.manufacturingCost_PRO
            );
          packageCost =
            (this.$parent.$refs.plTcm.form.packageCost === "" ||
            !this.$parent.$refs.plTcm.form.packageCost
              ? "0"
              : parseFloat(this.$parent.$refs.plTcm.form.packageCost) / 100) *
            parseFloat(
              this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
                !this.$parent.$refs.proTcm.manufacturingCost_PRO
                ? "0"
                : this.$parent.$refs.proTcm.manufacturingCost_PRO
            );
          otherCost =
            (this.$parent.$refs.plTcm.form.otherCost === "" ||
            !this.$parent.$refs.plTcm.form.otherCost
              ? "0"
              : parseFloat(this.$parent.$refs.plTcm.form.otherCost) / 100) *
            parseFloat(
              this.$parent.$refs.proTcm.manufacturingCost_PRO === "" ||
                !this.$parent.$refs.proTcm.manufacturingCost_PRO
                ? "0"
                : this.$parent.$refs.proTcm.manufacturingCost_PRO
            );
          this.$parent.$refs.plTcm.freightCostNum = freightCost.toFixed(3);
          this.$parent.$refs.plTcm.wareHouseCostNum = wareHouseCost.toFixed(3);
          this.$parent.$refs.plTcm.packageCostNum = packageCost.toFixed(3);
          this.$parent.$refs.plTcm.otherCostNum = otherCost.toFixed(3);
        }

        console.log(
          this.$parent.$refs.plTcm.form.freightCost,
          this.$parent.$refs.plTcm.form.calculationMethod
        );
        this.$parent.$refs.sumTcm.form.packageCost_SUM = (
          parseFloat(freightCost) +
          parseFloat(wareHouseCost) +
          parseFloat(packageCost)
        ).toFixed(3);
        if (threeAll != 0) {
          let processScapeCost = 0;
          console.log(
            this.$parent.$refs.proTcm.manufacturingCost_PRO,
            "this.$parent.$refs.proTcm.manufacturingCost_PRO"
          );
          if (
            this.$parent.$refs.proTcm.manufacturingCost_PRO != "" &&
            this.$parent.$refs.rwTcm.materialCost_RM != "" &&
            this.form.materialPC != "" &&
            this.$parent.$refs.purTcm.purchaseCost_PUR != ""
          ) {
            processScapeCost =
              this.$parent.$refs.proTcm.manufacturingCost_PRO *
              ((parseFloat(this.$parent.$refs.rwTcm.materialCost_RM) *
                parseFloat(
                  this.form.materialPC == "" ? 0 : this.form.materialPC / 100
                ) +
                parseFloat(this.$parent.$refs.purTcm.purchaseCost_PUR) *
                  parseFloat(
                    this.form.materialPC == "" ? 0 : this.form.materialPC / 100
                  ) +
                parseFloat(this.$parent.$refs.proTcm.sbSum)) /
                threeAll);
          }
          console.log(
            this.$parent.$refs.rwTcm.NmCost,
            "this.$parent.$refs.rwTcm.NmCost"
          );
          console.log(
            this.$parent.$refs.proTcm.sbSum,
            "this.$parent.$refs.rwTcm.NmCost"
          );
          console.log(threeAll, "this.$parent.$threeAll");

          let materialScrapCost =
            parseFloat(
              this.$parent.$refs.rwTcm.NmCost === "" ||
                !this.$parent.$refs.rwTcm.NmCost ||
                isNaN(this.$parent.$refs.rwTcm.NmCost)
                ? "0"
                : this.$parent.$refs.rwTcm.NmCost
            ) *
            ((parseFloat(
              this.$parent.$refs.rwTcm.materialCost_RM == ""
                ? "0"
                : this.$parent.$refs.rwTcm.materialCost_RM
            ) *
              parseFloat(
                this.form.materialPC == "" ? 0 : this.form.materialPC / 100
              ) +
              parseFloat(
                this.$parent.$refs.purTcm.purchaseCost_PUR == ""
                  ? 0
                  : this.$parent.$refs.purTcm.purchaseCost_PUR
              ) *
                parseFloat(
                  this.form.materialPC == "" ? 0 : this.form.materialPC / 100
                ) +
              parseFloat(
                this.$parent.$refs.proTcm.sbSum == ""
                  ? "0"
                  : this.$parent.$refs.proTcm.sbSum
              )) /
              threeAll);

          console.log(materialScrapCost, "materialScrapCost");
          console.log(processScapeCost, "processScapeCost");
          this.$parent.$refs.sumTcm.form.scrapCost_SUM =
            processScapeCost - materialScrapCost;
          this.$parent.$refs.sumTcm.form.scrapCost_SUM =
            this.$parent.$refs.sumTcm.form.scrapCost_SUM.toFixed(3);
          let freightWarehousingCost =
            parseFloat(freightCost) + parseFloat(wareHouseCost);
          console.log(freightWarehousingCost, "freightWarehousingCost");
          let otherCost954 =
            parseFloat(otherCost) +
            parseFloat(
              this.$parent.$refs.plTcm.form.amortizeCost == ""
                ? 0
                : this.$parent.$refs.plTcm.form.amortizeCost
            );
          console.log(otherCost954, "otherCost954");
          this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM = (
            threeAll +
            parseFloat(
              this.$parent.$refs.sumTcm.form.sgaProfit_SUM
                ? this.$parent.$refs.sumTcm.form.sgaProfit_SUM
                : 0
            ) +
            processScapeCost +
            parseFloat(
              this.$parent.$refs.sumTcm.form.packageCost_SUM
                ? this.$parent.$refs.sumTcm.form.packageCost_SUM
                : 0
            ) +
            otherCost954
          ).toFixed(3);
          this.$parent.totalSellPrice =
            this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM;
          this.$parent.$refs.sumTcm.form.totalPriceReport_SUM = (
            parseFloat(this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM) *
            parseFloat(
              this.$parent.$refs.gen.genList[0].rate == ""
                ? 0
                : this.$parent.$refs.gen.genList[0].rate
            )
          ).toFixed(3);
          // + freightWarehousingCost
          this.form.totalProfit = (
            this.form.totalProfitCost == 0
              ? 0
              : (this.form.totalProfitCost /
                  this.$parent.$refs.sumTcm.form.totalPriceCurr_SUM) *
                100
          ).toFixed(3);
        }
      }

      console.log(this.$parent.$refs.proTcm.sbSum, "sbSum");
      console.log(this.$parent.$refs.rwTcm.NmCost, "NmCost");
    },
  },
};
</script>

<style scoped>
.tcm-content {
  padding: 20px 90px 20px 20px;
  min-height: calc(100vh - 170px);
  background: rgba(255, 255, 255, 0.5);
}
.tcm-content-title {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  width: calc(100% - 50px);
  margin-left: 50px;
  /* background-color: #f2f2f2; */
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.form-type-title {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  line-height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.tcm-content-table {
  width: 100%;
}
.tcm-content-table td {
  padding-bottom: 10px;
}
.table-title {
  font-size: 14px;
  color: #606266;
}
</style>