<template>
  <div>
    <template v-for="(item, index) in menuList">
      <!-- 有次级菜单的,则展开子选项-->
      <el-sub-menu
        class="custom-el-menu"
        popper-class="el-sub-menu-class"
        v-if="
          item.children && item.children.length > 0 && item.item.status == 0
        "
        :key="item.item.id"
        :index="item.item.id"
      >
        <template #title>
          <!-- <el-icon v-if="item.item.icon != ''"
            ><component :is="item.item.icon"
          /></el-icon> -->
          <!-- v-show="item.item.id == ActiveMenuItem" -->
          <div
            class="active-Menu"
            v-show="item.item.id == ActiveMenuItem"
          ></div>

          <div class="Menu-img-box" v-show="!item.item.parentId">
            <img
              class="Menu-img"
              v-show="item.item.code == 'Tools'"
              src="../../assets/icon/people.png"
              alt=""
            />

            <img
              v-show="item.item.code == 'My Task'"
              class="Menu-img"
              src="../../assets/icon/txt.png"
              alt=""
            />
            <!-- <img
              v-show="item.item.code == 'Setting'"
              class="Menu-img"
              src="../../assets/icon/setting.png"
              alt=""
            /> -->
            <el-icon :size="18" v-show="item.item.code == 'Setting'"
              ><setting
            /></el-icon>
          </div>
          <!-- :src="'./../assets/icon/' + item.item.src " -->

          <!-- <el-icon v-show="!item.item.parentId"><setting /></el-icon> -->
          <span class="menu-text" :title="item.item.name">{{
            item.item.name
          }}</span>
          <div v-show="item.item.code == 'My Task'" class="task-num">
            {{ TaskNum }}
          </div>
        </template>
        <!-- 递归,实现无限级展开 -->
        <MenuTree
          :menuList="item.children"
          :ActiveMenuItem="ActiveMenuItem"
        ></MenuTree>
      </el-sub-menu>

      <!-- 没有次级菜单的 -->
      <el-menu-item
        class="custom-el-menu"
        v-if="item.children && item.children.length == 0"
        :key="item.item.id"
        :index="item.item.url"
        @click="getIds(item.item.id)"
      >
        <div
          class="active-Menu"
          v-show="$route.path == item.item.url && !item.item.parentId"
        ></div>
        <!-- <el-icon v-show="!item.item.parentId"><setting /></el-icon> -->
        <div class="Menu-img-box" v-show="!item.item.parentId">
          <img
            class="Menu-img"
            v-show="item.item.code == 'homePage'"
            src="../../assets/icon/home.png"
            alt=""
          />
          <img
            v-show="item.item.code == 'TCM DATA'"
            class="Menu-img"
            src="../../assets/icon/round.png"
            alt=""
          />
        </div>

        <span class="menu-text" :title="item.item.name">{{
          item.item.name
        }}</span>
      </el-menu-item>
    </template>
  </div>
</template>
<script>
import "../../assets/css/custom-el-menu.css";
import { LoadMenus } from "@/assets/js/requestApi.js";
export default {
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
    ActiveMenuItem: {
      type: String,
    },
    TaskNum: {
      type: Number,
    },
  },

  data() {
    return {
      moduleId: "",
      publicPath: process.env.BASE_URL,
    };
  },
  name: "MenuTree",
  created() {},
  methods: {
    getIds(id) {
      LoadMenus({ moduleId: id }, (response) => {
        if (response && response.code == 200) {
          localStorage.setItem("power", JSON.stringify(response.result));
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/* 菜单栏选中后背景色 */
.el-menu-item.is-active {
  background-color: #fafbff;
}

.el-sub-menu,
.el-menu-item {
  position: relative;
  font-weight: 500;
}
.el-sub-menu .el-icon,
.el-menu-item .el-icon {
  // font-size: 15px !important;
  // margin-right: 10px !important;
}
.active-Menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 40px;
  background: #2f4cdd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.el-menu-item,
:deep .el-sub-menu__title {
  height: 40px;
  line-height: 40px;
}
.el-sub-menu .el-menu-item {
  height: 40px;
  line-height: 40px;
}
.el-menu-item:hover {
  background-color: #fafbff;
}
:deep .el-sub-menu__title:hover {
  background-color: #fafbff !important;
}
.menu-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-num {
  margin-left: 10px;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  font-size: 12px;
  border-radius: 5px;
  background-color: #2f4cdd;
  color: #fff;
}
.Menu-img-box {
  display: flex;
  align-items: center;
  font-size: 19px !important;
  .el-icon {
    margin-left: -2px;
  }
}
.Menu-img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
</style>