<template>
  <div class="tcm-content">
    <div class="tcm-content-title">
      {{ $t("changePriceLists.changePriceList.rawMaterial") }}
      <div class="tcm-content-rawPic">
        <el-image
          v-for="(item, index) in previewData.attachmentList"
          style="width: 40px; height: 40px; margin-right: 10px"
          :src="item.url"
          :zoom-rate="1.2"
          :preview-src-list="attachmentList"
          :initial-index="index"
          fit="cover"
        />
      </div>
    </div>
    <div class="tcm-content-form">
      <table>
        <tr>
          <td style="width: 150px">
            {{ $t("changePriceLists.changePriceList.materialDescription") }}
          </td>
          <td v-for="item in previewData.materialLine">
            <el-input v-model="item.description" disabled />
          </td>
        </tr>
        <tr>
          <td style="width: 150px">
            {{ $t("changePriceLists.changePriceList.vendor") }}
          </td>
          <td v-for="item in previewData.materialLine">
            <el-input v-model="item.vendor" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.netWeight") }}</td>
          <td v-for="item in previewData.materialLine">
            <el-input v-model="item.netMWeight" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.grossWeight") }}</td>
          <td v-for="item in previewData.materialLine">
            <el-input v-model="item.grossMWeight" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.unitMeasure") }}</td>
          <td v-for="item in previewData.materialLine">
            <el-select
              clearable
              v-model="item.unitMeasureId"
              disabled
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            >
              <el-option
                v-for="item in unitOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.materialMeasure") }}</td>
          <td v-for="item in previewData.materialLine">
            <el-input v-model="item.unitCost" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.scrapPrice") }}</td>
          <td v-for="item in previewData.materialLine">
            <el-input v-model="item.unitResaleValue" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.country") }}</td>
          <td v-for="item in previewData.materialLine">
            <el-input v-model="item.country" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.date") }}</td>
          <td v-for="item in previewData.materialLine">
            <el-input v-model="item.date" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.materialCost") }}</td>
          <td v-for="item in previewData.materialLine">
            <el-input v-model="item.cost" disabled />
          </td>
        </tr>
      </table>
    </div>
    <div class="tcm-content-title mgt-10">
      {{ $t("changePriceLists.changePriceList.purchasedComponents") }}
    </div>
    <div class="tcm-content-form">
      <table>
        <tr>
          <td style="width: 150px">
            {{ $t("changePriceLists.changePriceList.processDescription") }}
          </td>
          <td v-for="item in previewData.purchaseLine">
            <el-input v-model="item.name" disabled />
          </td>
        </tr>
        <tr>
          <td style="width: 150px">
            {{ $t("changePriceLists.changePriceList.vendor") }}
          </td>
          <td v-for="item in previewData.purchaseLine">
            <el-input v-model="item.vendor" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.consumption") }}</td>
          <td v-for="item in previewData.purchaseLine">
            <el-input v-model="item.quantity" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.unitMeasure") }}</td>
          <td v-for="item in previewData.purchaseLine">
            <el-select
              clearable
              v-model="item.unit"
              disabled
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            >
              <el-option
                v-for="item in unitOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.costUoM") }}</td>
          <td v-for="item in previewData.purchaseLine">
            <el-input v-model="item.unitCost" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.subTotalCost") }}</td>
          <td v-for="item in previewData.purchaseLine">
            <el-input v-model="item.totalCost" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.priceReference") }}</td>
          <td v-for="item in previewData.purchaseLine">
            <el-input v-model="item.price" disabled />
          </td>
        </tr>
      </table>
    </div>
    <div class="tcm-content-title mgt-10">
      {{ $t("changePriceLists.changePriceList.processing") }}
    </div>
    <div class="tcm-content-form">
      <table>
        <tr>
          <td style="width: 150px">
            <div class="form-type-title">
              {{ $t("changePriceLists.changePriceList.setUp") }}# 1
            </div>
          </td>
        </tr>
        <tr>
          <td style="width: 150px">
            {{ $t("changePriceLists.changePriceList.annualVolume") }}
          </td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.annualVolume" disabled />
          </td>
        </tr>
        <tr>
          <td style="width: 150px">
            {{ $t("changePriceLists.changePriceList.setUpTime") }}
          </td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.setTime" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.setUpPersons") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.setPerson" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.piecesPerLot") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.pieceLot" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.setUpCosts") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.pieceCost" disabled />
          </td>
        </tr>
        <tr>
          <td>
            <div class="form-type-title">
              {{ $t("changePriceLists.changePriceList.operation") }} # 1
            </div>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t("changePriceLists.changePriceList.operationDescription") }}
          </td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.name" disabled />
          </td>
        </tr>
        <tr>
          <td style="width: 150px">
            {{ $t("changePriceLists.changePriceList.vendor") }}
          </td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.vendor" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.descriptionSize") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.equSize" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.consumption") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.quantity" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.piecesHour") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.pieceHour" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.piecesPer") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.pieceCycle" disabled />
          </td>
        </tr>
        <tr>
          <td>
            {{ $t("changePriceLists.changePriceList.manufacturingEfficiency") }}
          </td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.efficiencyPercent" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.standardHour") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.standardHour" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.directRate") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.directRate" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.setUpRate") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.setUpRate" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.directOperators") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.directOperateQty" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.directAssembly") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.assemblyDirectCost" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.burdenVariable") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.variableRate" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.burdenRate") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.fixedRate" disabled />
          </td>
        </tr>
        <tr>
          <td>
            {{ $t("changePriceLists.changePriceList.burdenDepreciation") }}
          </td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.depreciationRate" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.totalBurdenRate") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.totalBurdenRate" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.burdenCost") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.assemblyBurdenCost" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.subTotalCost") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.totalCost" disabled />
          </td>
        </tr>
        <tr>
          <td>{{ $t("changePriceLists.changePriceList.processScrap") }}</td>
          <td v-for="item in previewData.processLine">
            <el-input v-model="item.scrapPercent" disabled />
          </td>
        </tr>
      </table>
    </div>
    <div class="tcm-content-title mgt-10">
      {{ $t("changePriceLists.changePriceList.dutiesCustoms") }}
    </div>
    <div class="tcm-content-form">
      <el-form :model="previewData" label-width="200px">
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.freightCost')"
            >
              <el-input
                v-model="previewData.freightCost"
                disabled
                style="width: 214px; margin-right: 10px"
              >
                <template #suffix>
                  <el-icon
                    class="el-input__icon"
                    v-show="previewData.calculationMethod_Freight == 2"
                    >%</el-icon
                  >
                </template>
              </el-input>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOption'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="previewData.calculationMethod_Freight"
                disabled
              >
                <el-option
                  v-for="item in calculationMethodOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="previewData.calculationMethod_Freight"
                disabled
              >
                <el-option
                  v-for="item in calculationMethodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <span
                v-show="previewData.calculationMethod_Freight == 2"
                style="margin-left: 10px; color: gray"
              >
                {{
                  previewData.calculationMethod_Freight == 2 &&
                  previewData.manufacturingCost_PRO !== "" &&
                  previewData.freightCost !== "" &&
                  previewData.manufacturingCost_PRO !== null &&
                  previewData.freightCost
                    ? (
                        (previewData.freightCost / 100) *
                        previewData.manufacturingCost_PRO
                      ).toFixed(3)
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.wareCost')"
            >
              <el-input
                v-model="previewData.wareHouseCost"
                disabled
                style="width: 214px; margin-right: 10px"
              >
                <template #suffix>
                  <el-icon
                    class="el-input__icon"
                    v-show="previewData.calculationMethod_Ware == 2"
                    >%</el-icon
                  >
                </template>
              </el-input>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOption'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="previewData.calculationMethod_Ware"
                disabled
              >
                <el-option
                  v-for="item in calculationMethodOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="previewData.calculationMethod_Ware"
                disabled
              >
                <el-option
                  v-for="item in calculationMethodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <span
                v-show="previewData.calculationMethod_Ware == 2"
                style="margin-left: 10px; color: gray"
              >
                {{
                  previewData.calculationMethod_Freight == 2 &&
                  previewData.manufacturingCost_PRO !== "" &&
                  previewData.wareHouseCost !== "" &&
                  previewData.manufacturingCost_PRO !== null &&
                  previewData.wareHouseCost
                    ? (
                        (previewData.wareHouseCost / 100) *
                        previewData.manufacturingCost_PRO
                      ).toFixed(3)
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.packagingCost')"
            >
              <el-input
                v-model="previewData.packageCost"
                disabled
                style="width: 214px; margin-right: 10px"
              >
                <template #suffix>
                  <el-icon
                    class="el-input__icon"
                    v-show="previewData.calculationMethod_Package == 2"
                    >%</el-icon
                  >
                </template>
              </el-input>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOption'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="previewData.calculationMethod_Package"
                disabled
              >
                <el-option
                  v-for="item in calculationMethodOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="previewData.calculationMethod_Package"
                disabled
              >
                <el-option
                  v-for="item in calculationMethodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <span
                v-show="previewData.calculationMethod_Package == 2"
                style="margin-left: 10px; color: gray"
              >
                {{
                  previewData.calculationMethod_Freight == 2 &&
                  previewData.manufacturingCost_PRO !== "" &&
                  previewData.packageCost !== "" &&
                  previewData.manufacturingCost_PRO !== null &&
                  previewData.packageCost
                    ? (
                        (previewData.packageCost / 100) *
                        previewData.manufacturingCost_PRO
                      ).toFixed(3)
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.taxesFees')"
            >
              <el-input
                v-model="previewData.otherCost"
                disabled
                style="width: 214px; margin-right: 10px"
              >
                <template #suffix>
                  <el-icon
                    class="el-input__icon"
                    v-show="previewData.calculationMethod_Taxes == 2"
                    >%</el-icon
                  >
                </template>
              </el-input>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOption'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="previewData.calculationMethod_Taxes"
                disabled
              >
                <el-option
                  v-for="item in calculationMethodOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-select
                clearable
                v-if="
                  $t(
                    'changePriceLists.changePriceList.calculationMethodOption'
                  ) == 'calculationMethodOptions'
                "
                :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                v-model="previewData.calculationMethod_Taxes"
                disabled
              >
                <el-option
                  v-for="item in calculationMethodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <span
                v-show="previewData.calculationMethod_Taxes == 2"
                style="margin-left: 10px; color: gray"
              >
                {{
                  previewData.otherCost == 2 &&
                  previewData.manufacturingCost_PRO !== "" &&
                  previewData.otherCost !== "" &&
                  previewData.manufacturingCost_PRO !== null &&
                  previewData.otherCost
                    ? (
                        (previewData.otherCost / 100) *
                        previewData.manufacturingCost_PRO
                      ).toFixed(3)
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form :model="previewData" label-width="500px" label-position="left">
        <!-- <el-row>
					<el-col>
						<el-form-item :label="$t('changePriceLists.changePriceList.taxesFees')">
							<el-input v-model="previewData.otherCost" disabled style="width: 214px;" />
						</el-form-item>
					</el-col>
				</el-row> -->
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.addedDescription')"
            >
              <el-input
                v-model="previewData.amortizationDescription"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalPiecesAdded')"
            >
              <el-input
                v-model="previewData.totalPieces"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalAddedCost')"
            >
              <el-input
                v-model="previewData.totalAmortize"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              :label="$t('changePriceLists.changePriceList.addedCost')"
            >
              <el-input
                v-model="previewData.amortizeCost"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tcm-content-title mgt-10">
      {{ $t("changePriceLists.changePriceList.sgaProfitScrap") }}
    </div>
    <div class="tcm-content-form">
      <el-form :model="previewData" label-width="300px">
        <div class="form-type-title">
          {{ $t("changePriceLists.changePriceList.sgas") }}
        </div>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('changePriceLists.changePriceList.sga')">
              <el-input
                v-model="previewData.sga"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.sgaCost')"
            >
              <el-input
                v-model="previewData.sgaCost"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-type-title">
          {{ $t("changePriceLists.changePriceList.profit") }}
        </div>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.profitMaterial')"
            >
              <el-input
                v-model="previewData.materialProfit"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.profitComponents')"
            >
              <el-input
                v-model="previewData.addProfit"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalprofitCost')"
            >
              <el-input
                v-model="previewData.totalProfitCost"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.profitValue')"
            >
              <el-input
                v-model="previewData.purchaseProfit"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalProfit')"
            >
              <el-input
                v-model="previewData.totalProfit"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.materialScrap')"
            >
              <el-input
                v-model="previewData.materialPC"
                disabled
                style="width: 214px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tcm-content-title mgt-10">
      {{ $t("changePriceLists.changePriceList.costSummary") }}
    </div>
    <div class="tcm-content-form">
      <el-form :model="previewData" label-width="400px">
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.rawMaterialCost')"
            >
              <el-input
                v-model="previewData.materialCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  isNaN(materialCost_SUM_Computed)
                    ? ""
                    : materialCost_SUM_Computed + "%"
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.purchasedCost')"
            >
              <el-input
                v-model="previewData.purchaseCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  isNaN(purchaseCost_SUM_Computed)
                    ? ""
                    : purchaseCost_SUM_Computed + "%"
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.directLaborCost')"
            >
              <el-input
                v-model="previewData.laborCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{ isNaN(t1) ? "" : t1 + "%" }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.burdenCost')"
            >
              <el-input
                v-model="previewData.burdenCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{ isNaN(t2) ? "" : t2 + "%" }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.setUpCost')"
            >
              <el-input
                v-model="previewData.setUpCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{ isNaN(t3) ? "" : t3 + "%" }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalManufacturing')"
            >
              <!--制造成本总计-->
              <!-- manufacturingCost_PRO -->

              <!-- 未修改之前用的这个字段 manufacturingCostSum -->
              <el-input
                v-model="previewData.manufacturingCost_PRO"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  (
                    parseFloat(
                      isNaN(purchaseCost_SUM_Computed)
                        ? 0
                        : purchaseCost_SUM_Computed
                    ) +
                    parseFloat(
                      isNaN(materialCost_SUM_Computed)
                        ? 0
                        : materialCost_SUM_Computed
                    ) +
                    parseFloat(isNaN(t1) ? 0 : t1) +
                    parseFloat(isNaN(t2) ? 0 : t2) +
                    parseFloat(isNaN(t3) ? 0 : t3)
                  ).toFixed(3)
                }}%
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="
                $t('changePriceLists.changePriceList.packagingFreightCost')
              "
            >
              <el-input
                v-model="previewData.packageCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  previewData.packageCost_SUM !== "" &&
                  previewData.totalPriceCurr_SUM !== "" &&
                  previewData.packageCost_SUM !== null &&
                  previewData.totalPriceCurr_SUM
                    ? (
                        (previewData.packageCost_SUM /
                          previewData.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.amortizationFees')"
            >
              <el-input
                v-model="previewData.otherCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  previewData.otherCost_SUM !== "" &&
                  previewData.totalPriceCurr_SUM !== "" &&
                  previewData.otherCost_SUM !== null &&
                  previewData.totalPriceCurr_SUM
                    ? (
                        (previewData.otherCost_SUM /
                          previewData.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.amortizationCost')"
            >
              <el-input
                v-model="previewData.amortizeCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  previewData.amortizeCost_SUM !== "" &&
                  previewData.totalPriceCurr_SUM !== "" &&
                  previewData.amortizeCost_SUM !== null &&
                  previewData.totalPriceCurr_SUM
                    ? (
                        (previewData.amortizeCost_SUM /
                          previewData.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.processScrapCost')"
            >
              <el-input
                v-model="previewData.scrapCost_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  previewData.scrapCost_SUM !== "" &&
                  previewData.totalPriceCurr_SUM !== "" &&
                  previewData.scrapCost_SUM !== null &&
                  previewData.totalPriceCurr_SUM
                    ? (
                        (previewData.scrapCost_SUM /
                          previewData.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.sgaAndProfit')"
            >
              <el-input
                v-model="previewData.sgaProfit_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix>{{ exchangeRateUnit }}</template>
              </el-input>
              <span style="margin-left: 10px; color: gray; line-height: 32px">
                {{
                  previewData.sgaProfit_SUM !== "" &&
                  previewData.totalPriceCurr_SUM !== "" &&
                  previewData.sgaProfit_SUM !== null &&
                  previewData.totalPriceCurr_SUM
                    ? (
                        (previewData.sgaProfit_SUM /
                          previewData.totalPriceCurr_SUM) *
                        100
                      ).toFixed(3) + "%"
                    : ""
                }}
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalSellPrice')"
            >
              <el-input
                v-model="totalPriceCurr_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix
                  >{{ exchangeRateUnit }}
                  {{
                    parseFloat(
                      previewData.totalPriceCurr_SUM === "" ||
                        previewData.totalPriceCurr_SUM === null
                        ? 0
                        : previewData.totalPriceCurr_SUM
                    ).toFixed(3)
                  }}</template
                >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('changePriceLists.changePriceList.totalSellCurrency')"
            >
              <el-input
                v-model="totalPriceReport_SUM"
                disabled
                style="width: 214px"
              >
                <template #prefix
                  >{{ reportExchangeRateUnit }}
                  {{
                    parseFloat(
                      previewData.totalPriceReport_SUM === "" ||
                        previewData.totalPriceReport_SUM === null
                        ? 0
                        : previewData.totalPriceReport_SUM
                    ).toFixed(3)
                  }}</template
                >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div
      class="tcm-content-title mgt-10"
      style="display: flex"
      v-show="operateType !== 'editImport'"
    >
      {{ $t("changePriceLists.changePriceList.email") }}
      <div>
        <el-tooltip
          :content="$t('changePriceLists.changePriceList.SelectEmail')"
          placement="top-start"
        >
          <img
            src="../../assets/img/tcm/add.svg"
            style="margin: 0px 10px 0 10px; cursor: pointer"
            @click="addEmail"
          />
        </el-tooltip>
      </div>
      <!-- <div>
				<img src="../../assets/img/tcm/memberAdd.svg" style="cursor: pointer;" @click="openEmailDia"/>
			</div> -->
    </div>
    <div class="tcm-content-form" v-show="operateType !== 'editImport'">
      <table class="gen-table">
        <tr>
          <td style="width: 250px">
            {{ $t("changePriceLists.changePriceList.email") }}
          </td>
          <td>{{ $t("changePriceLists.changePriceList.name") }}</td>
          <td>{{ $t("changePriceLists.changePriceList.operation") }}</td>
        </tr>
        <tr v-for="item in mailTable">
          <td style="width: 250px">{{ item.email }}</td>
          <td>{{ item.name }}</td>
          <td>
            <img
              src="../../assets/img/tcm/cuowu.png"
              style="width: 20px; height: 20px; cursor: pointer"
              @click="toDetele(index, 'email')"
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="btn-cla">
      <el-button
        style="
          background-color: var(--themeColor);
          color: var(--menuTextActive);
        "
        @click="cancle"
        >{{ $t("changePriceLists.changePriceList.cancle") }}</el-button
      >
      <el-button
        style="
          background-color: var(--themeColor);
          color: var(--menuTextActive);
        "
        @click="submit"
        >{{ $t("changePriceLists.changePriceList.submit") }}</el-button
      >
    </div>
    <ChooseUserDia ref="userDia" @getChooseUser="getChooseUser"></ChooseUserDia>
  </div>
</template>

<script>
import { getCategorys } from "../../assets/js/requestApi.js";
import ChooseUserDia from "../tcmApplication/chooseUserDia.vue";
export default {
  props: [
    "previewData",
    "exchangeRateUnit",
    "reportExchangeRateUnit",
    "operateType",
  ],
  emits: ["canclePreview", "submitPreview"],
  components: {
    ChooseUserDia,
  },
  data() {
    return {
      totalPriceCurr_SUM: "",
      totalPriceReport_SUM: "",
      calculationMethodOptions: [
        {
          value: 1,
          label: "Numerical value",
        },
        {
          value: 2,
          label: "Percentage",
        },
      ],
      calculationMethodOption: [
        {
          value: 1,
          label: "数值",
        },
        {
          value: 2,
          label: "百分比",
        },
      ],
      unitOptions: [],
      mailTable: [],
      CommId: "",
      attachmentList: [],
    };
  },
  computed: {
    t1: function () {
      return this.previewData.laborCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.laborCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.laborCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    t2: function () {
      return this.previewData.burdenCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.burdenCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.burdenCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    t3: function () {
      return this.previewData.setUpCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.setUpCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.setUpCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    materialCost_SUM_Computed: function () {
      return this.previewData.materialCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.materialCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.materialCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
    purchaseCost_SUM_Computed: function () {
      return this.previewData.purchaseCost_SUM !== "" &&
        this.previewData.totalPriceCurr_SUM !== "" &&
        this.previewData.purchaseCost_SUM !== null &&
        this.previewData.totalPriceCurr_SUM
        ? (
            (this.previewData.purchaseCost_SUM /
              this.previewData.totalPriceCurr_SUM) *
            100
          ).toFixed(3)
        : NaN;
    },
  },
  mounted() {
    this.getUnit();
    this.getUnits();
  },
  methods: {
    getUnits() {
      this.timer = setInterval(this.valChange, 1000);
    },
    valChange() {
      this.powers = JSON.parse(localStorage.getItem("translate"));
      if (this.powers == "en" || this.powers == null) {
        this.calculationMethodOptions = [
          {
            value: 1,
            label: "Numerical Value",
          },
          {
            value: 2,
            label: "Percentage",
          },
        ];
      } else {
        this.calculationMethodOptions = [
          {
            value: 1,
            label: "数值", //Numerical Value
          },
          {
            value: 2,
            label: "百分比", //Percentage
          },
        ];
      }
      clearInterval(this.timer);
    },
    // 获取单位选项
    getUnit() {
      getCategorys(
        {
          TypeID: "MeasureUnit",
          Keyword: "",
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.unitOptions = response.data;
          }
        }
      );
    },
    // 取消
    cancle() {
      this.$emit("canclePreview");
    },
    // 提交
    submit() {
      this.$emit("submitPreview");
    },
    // 添加邮件
    addEmail() {
      this.$refs.userDia.getlist("3", this.CommId);
      this.$refs.userDia.visible = true;
      this.$refs.userDia.isMultiChose = false;
    },
    // 获取选择的用户
    getChooseUser(data) {
      let _this = this;
      // data.forEach(function(val, index) {
      // 	if (JSON.stringify(_this.mailTable).indexOf(JSON.stringify(val)) === -1) {
      // 		_this.mailTable.push(val)
      // 	}
      // })
      _this.mailTable = data; //只能选择一个
    },
    // 删除邮件
    toDetele(index, type, ind) {
      if (type == "year") {
        this.genList[ind].yearVolumeList.splice(index, 1);
        // this.tableData.splice(index, 1)
      } else if (type == "email") {
        this.mailTable.splice(index, 1);
      } else if (type == "file") {
        this.fileListx.splice(index, 1);
      } else if (type == "gen") {
        this.genList.splice(index, 1);
      }
    },
    // 打开历史邮箱
    openEmailDia() {
      this.$refs.email.getlist();
      this.$refs.email.visible = true;
    },
  },
};
</script>

<style scoped>
.tcm-content {
  padding: 20px 20px 20px 20px;
}
.tcm-content-title {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-rawPic {
  position: absolute;
  top: 10px;
  left: 180px;
}
.tcm-content-form {
  width: calc(100% - 50px);
  margin-left: 50px;
  /* background-color: #f2f2f2; */
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}
.tcm-content-form table {
  /* table-layout: fixed;
		width: 100%; */
}
.tcm-content-form tr {
  display: flex;
  flex-wrap: nowrap;
}
.tcm-content-form td {
  width: 150px;
}
.mgt-10 {
  margin-top: 10px;
}
.form-type-title {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  line-height: 35px;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.btn-cla {
  width: 50%;
  margin: 20px auto;
  justify-content: space-around;
  display: flex;
}
.gen-table {
  border-collapse: collapse;
}
.gen-table td {
  border: 1px solid #dcdfe6;
  padding: 5px 10px;
  text-align: center;
}
table td {
  margin-bottom: 10px;
}
</style>