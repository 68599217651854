<template>
  <el-dialog v-model="visible" top="50px" width="60%" :show-close="false">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16"></div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div class="tcm-cla">
      <div id="compareCla" style="width: 100%; height: 700px"></div>
    </div>
  </el-dialog>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    // this.drawChart()
  },
  methods: {
    close() {
      this.visible = false;
    },
    drawChart(data) {
      this.visible = true;
      setTimeout(() => {
        const compareCla = echarts.init(document.getElementById("compareCla"));
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            bottom: "1%",
          },
          grid: {
            top: "2%",
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true,
          },
          yAxis: {
            type: "value",
          },
          xAxis: {
            type: "category",
            data: data.partDescription,
          },
          series: [
            {
              name: "Raw Material Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data.materialCost_SUM,
            },
            {
              name: "Purchased Components Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data.purchaseCost_SUM,
            },
            {
              name: "Direct Labor Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data.laborCost_SUM,
            },
            {
              name: "Burden Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data.burdenCost_SUM,
            },
            {
              name: "Set Up Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data.setUpCost_SUM,
            },
            {
              name: "Packaging & Freight Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data.packageCost_SUM,
            },
            {
              name: "Amortization Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data.amortizeCost_SUM,
            },
            {
              name: "SG&A and Profit",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data.sgaProfit_SUM,
            },
            {
              name: "Process Scrap Cost",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data.scrapCost_SUM,
            },
          ],
        };
        compareCla.setOption(option);
      }, 300);
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  margin-right: 0;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tcm-cla {
  height: calc(100% - 90px);
  padding: 0 20px;
  box-sizing: border-box;
}
</style>