<template>
  <div>
    <div class="step-menu">
      <div
        :class="['step-menu-item', filterStatus(item)]"
        v-for="item in MenuData"
        @click="chooseMenu(item.key)"
      >
        {{ item.lan }}
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  name: "stepMenu",
  props: {
    MenuData: {
      type: Array,
    },
    chooseCurrent: {
      type: String,
    },
  },
  watch: {},
  data() {
    return {};
  },
  mounted() {},
  destroyed() {},
  methods: {
    chooseMenu(key) {
      this.$emit("chooseMenu", key);
    },
    filterStatus(item) {
      if (this.chooseCurrent == item.key) {
        return "active";
      }
    },
  },
};
</script>
    <style scoped lang="scss">
.step-menu {
  width: 90%;
  display: flex;
  border-bottom: 1px solid #f4f5f7;
  .step-menu-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: #718ebf;
    text-align: center;
    cursor: pointer;
  }
  .active {
    color: #2d60ff;
    font-size: 14px;
    border-bottom: 2px solid #2d60ff;
  }
}
</style>
    