<template>
  <el-dialog v-model="visible" width="500px" :show-close="false" @close="close">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="size-16">
          {{
            form.id === ""
              ? $t("unifiedPrompts.unifiedPrompt.new")
              : $t("unifiedPrompts.unifiedPrompt.edit")
          }}
        </div>
        <div style="cursor: pointer" @click="close">
          <el-icon :size="20"><Close /></el-icon>
        </div>
      </div>
    </template>
    <div>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("ccls.ccl.processName") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input v-model="form.processName" style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("ccls.ccl.depreciation") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input
            v-model="form.depreciation"
            @input="changeval('depreciation')"
            style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("ccls.ccl.indirectMaterial") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input
            v-model="form.indirectMaterial"
            @input="changeval('indirectMaterial')"
            style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("ccls.ccl.directOperator") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input
            v-model="form.directOperatorCost"
            @input="changeval('directOperatorCost')"
            style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            <span class="color-red">*</span>
            {{ $t("ccls.ccl.totalCosts") }}
          </div>
        </el-col>
        <el-col :span="13"
          ><el-input
            v-model="form.totalCost"
            @input="(v) => (form.totalCost = v.replace(/[^\d.]/g, ''))"
            disabled
            style="width: 214px"
        /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
      <el-row>
        <el-col :span="9">
          <div class="size-14 padding-9 align-right">
            {{ $t("ccls.ccl.enable") }}
          </div>
        </el-col>
        <el-col :span="13"><el-switch v-model="form.isEnable" /></el-col>
        <el-col :span="2"></el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">{{ $t("ccls.ccl.cancel") }}</el-button>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
            margin-right: 16px;
          "
          @click="confirm"
          >{{ $t("ccls.ccl.confirm") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  emits: ["confirmInfo"],
  data() {
    return {
      visible: false,
      form: {
        id: "",
        processName: "",
        depreciation: "",
        indirectMaterial: "",
        directOperatorCost: "",
        totalCost: "",
        isEnable: true,
        typeProcess: "",
      },
    };
  },
  methods: {
    close() {
      this.visible = false;
      this.form = {
        id: "",
        processName: "",
        depreciation: "",
        indirectMaterial: "",
        directOperatorCost: "",
        totalCost: "",
        isEnable: true,
        typeProcess: "",
      };
    },
    confirm() {
      if (this.form.processName == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseEnter") +
            " " +
            this.$t("ccls.ccl.processName") +
            "！",
          "",
          "warning"
        );
        return;
      }
      if (this.form.depreciation == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseEnter") +
            " " +
            this.$t("ccls.ccl.depreciation") +
            "！",
          "",
          "warning"
        );
        return;
      }
      if (this.form.indirectMaterial == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseEnter") +
            " " +
            this.$t("ccls.ccl.indirectMaterial") +
            "！",
          "",
          "warning"
        );
        return;
      }
      if (this.form.directOperatorCost == "") {
        commonUtil.showTipInfo(
          this.$t("ccls.ccl.pleaseEnter") +
            " " +
            this.$t("ccls.ccl.directOperator") +
            "！",
          "",
          "warning"
        );
        return;
      }
      this.$emit("confirmInfo", this.form);
      this.close();
    },
    changeval(type) {
      // (v) => (form.depreciation = v.replace(/[^\d.]/g, ''))
      if (type == "depreciation") {
        this.form.depreciation = this.form.depreciation.replace(/[^\d.]/g, "");
      } else if (type == "indirectMaterial") {
        this.form.indirectMaterial = this.form.indirectMaterial.replace(
          /[^\d.]/g,
          ""
        );
      } else if (type == "directOperatorCost") {
        this.form.directOperatorCost = this.form.directOperatorCost.replace(
          /[^\d.]/g,
          ""
        );
      }
      this.form.totalCost =
        (this.form.depreciation === ""
          ? 0
          : parseFloat(this.form.depreciation)) +
        (this.form.indirectMaterial === ""
          ? 0
          : parseFloat(this.form.indirectMaterial)) +
        (this.form.directOperatorCost === ""
          ? 0
          : parseFloat(this.form.directOperatorCost));
    },
  },
};
</script>

<style scoped>
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.size-16 {
  font-size: 16px;
}
.padding-9 {
  padding: 9px;
}
.el-row {
  margin-bottom: 10px;
}
.color-red {
  color: red;
}
.align-right {
  text-align: right;
}
</style>