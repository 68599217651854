<template>
	<div class="tcm-add">
		<div class="hover-menu">
			<div :class="chooseCurrent == 'xr' ? 'choose-btn' : 'hover-btn'" style="margin-bottom: 10px;font-size: 12px;" @click="chooseMenu('xr')">Exchange Rates</div>
			<div :class="chooseCurrent == 'qt' ? 'choose-btn' : 'hover-btn'" style="margin-bottom: 10px;font-size: 12px;" @click="chooseMenu('qt')">Quote Type</div>
			<div :class="chooseCurrent == 'pro' ? 'choose-btn' : 'hover-btn'" style="margin-bottom: 10px;" @click="chooseMenu('pro')">PRO</div>
			<div :class="chooseCurrent == 'p&l' ? 'choose-btn' : 'hover-btn'" style="margin-bottom: 10px;" @click="chooseMenu('p&l')">P&L</div>
			<div :class="chooseCurrent == 'oh' ? 'choose-btn' : 'hover-btn'" @click="chooseMenu('oh')">OH</div>
		</div>
		<ExchangeRate v-show="chooseCurrent == 'xr'"></ExchangeRate>
		<TcmPur v-show="chooseCurrent == 'qt'"></TcmPur>
		<TcmPro v-show="chooseCurrent == 'pro'"></TcmPro>
		<TcmPl v-show="chooseCurrent == 'p&l'"></TcmPl>
		<TcmOh v-show="chooseCurrent == 'oh'"></TcmOh>
	</div>
</template>

<script>
	import ExchangeRate from './exchangeRate.vue'
	import TcmPur from '../tcmTemplateManagement/tcmPur.vue'
	import TcmPro from '../tcmTemplateManagement/tcmPro.vue'
	import TcmPl from '../tcmTemplateManagement/tcmPL.vue'
	import TcmOh from '../tcmTemplateManagement/tcmOh.vue'
	export default{
		components: {
			ExchangeRate,
			TcmPur,
			TcmPro,
			TcmPl,
			TcmOh
		},
		data() {
			return {
				chooseCurrent: 'xr'
			}
		},
		methods: {
			chooseMenu(val) {
				this.chooseCurrent = val
			}
		}
	}
</script>

<style scoped>
	.tcm-add{
		position: relative;
	}
	.tcm-title{
		border-bottom: 2px solid #9a9a9a;
		padding: 20px;
		box-sizing: border-box;
		font-size: 16px;
		line-height: 32px;
		display: flex;
		justify-content: space-between;
		font-weight: bold;
	}
	.hover-menu{
		width: 80px;
		padding: 15px 0;
		box-sizing: border-box;
		background-color: rgba(215, 215, 215, 0.474509803921569);
		border-radius: 5px;
		position: fixed;
		top: 150px;
		right: 0;
		z-index: 10;
	}
	.hover-btn{
		font-size: 18px;
		font-weight: bold;
		width: 50px;
		color: #fff;
		background: rgba(174, 174, 174, 0.623529411764706);
		text-align: center;
		border-radius: 5px;
		padding: 8px 0;
		margin-left: 30px;
		cursor: pointer;
	}
	.choose-btn{
		font-size: 18px;
		font-weight: bold;
		width: 50px;
		margin-left: 30px;
		text-align: center;
		border-radius: 0px 5px 5px 0;
		padding: 8px 0;
		background-color: #fff;
		border-left: 4px solid var(--themeColor);
		cursor: pointer;
	}
</style>