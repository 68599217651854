<template>
  <div class="main">
    <div class="main-top_handle">
      <div class="title">Process Calculator - PCBA - eBom</div>
      <div class="main-handle flex">
        <div class="main-handle-item">
          eBOM PN
          <input type="text" v-model="searchForm.Keyword" />
        </div>
        <div class="main-handle-item">
          Description
          <input type="text" v-model="searchForm.description" />
        </div>
        <div style="margin-top: 15px" class="search-btn" @click="search">
          {{ $t("tcmlists.tcmlist.search") }}
        </div>
      </div>
    </div>

    <div class="handle-btn flex">
      <div class="handle-btn-item" style="margin-right: 10px" @click="addData">
        {{ $t("unifiedPrompts.unifiedPrompt.new") }}
      </div>
      <div class="handle-btn-item" style="margin-right: 10px" @click="editData">
        {{ $t("pcbas.pcba.edit") }}
      </div>
      <div
        class="handle-btn-item"
        style="margin-right: 10px"
        @click="deleteData"
      >
        {{ $t("pcbas.pcba.delete") }}
      </div>
    </div>
    <div class="regional_table">
      <el-table
        border
        stripe
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        :height="TableHeight"
        style="width: 100%"
        @row-click="RowClick"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="eBOMPN"
          label="eBOM PN"
          width="110"
          align="center"
        />
        <el-table-column prop="name" label="Name" align="center" />
        <el-table-column
          prop="description"
          label="Description"
          align="center"
        />
        <el-table-column prop="version" label="Revision" align="center" />
        <el-table-column prop="project" label="Project" align="center" />
        <el-table-column prop="regionName" label="Region" align="center" />
        <el-table-column
          prop="annualVolume"
          label="Annual Volume"
          align="center"
        />
        <el-table-column
          prop="extCurrencyName"
          label="Ext. Currency1"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.extCurrencyName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="extTotalPrice"
          label="Total Ext.Price1"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.extTotalPrice }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="extCurrencyName2"
          label="Ext. Currency2"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.extCurrencyName2 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="extTotalPrice2"
          label="Total Ext.Price2"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.extTotalPrice2 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date" align="center">
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.date }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          width="80"
          fixed="right"
          class-name="lastTd"
          :label="$t('templateLists.templateList.operate')"
          align="center"
        >
          <template #default="scope">
            <el-popover placement="bottom" trigger="hover">
              <template #reference>
                <div style="text-align: center">
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </template>
              <div class="action-box">
                <div class="action-btn" @click="editDetail(scope.row)">
                  <el-icon style="margin: 0 10px"><Edit /></el-icon>
                  <span style="font-size: 12px; white-space: nowrap"
                    >Compute Detail</span
                  >
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <div>
        Showing {{ pageSize }} from {{ total }} data
        <el-popover placement="top" :width="70" trigger="click">
          <template #reference>
            <el-icon
              style="margin-left: 5px; font-size: 17px; vertical-align: middle"
              ><Operation
            /></el-icon>
          </template>
          <el-input
            v-model="searchForm.limit"
            @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
            :min="1"
            style="width: 130px"
            @change="handleSizeChange"
          >
            <template #suffix> {{ $t("ccls.ccl.barPage") }}</template>
          </el-input>
        </el-popover>
      </div>
      <el-pagination
        layout="prev, pager, next"
        background
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <!-- <div class="tcm-list">
    <div class="tcm-search">
      <table>
        <tr>
          <td>
            <div class="search-text-cla">eBOM PN</div>
            <el-input
              v-model="searchForm.key"
              placeholder=""
              style="width: calc(100% - 150px)"
            />
          </td>
          <td>
            <div class="search-text-cla">Description</div>
            <el-input
              v-model="searchForm.description"
              placeholder=""
              style="width: calc(100% - 150px)"
            />
          </td>
          <td>
            <div class="operate-btn" style="margin-right: 10px" @click="search">
              {{ $t("pcbas.pcba.search") }}
            </div>
          </td>
          <td></td>
        </tr>
        <tr>
          <td colspan="4" style="padding-top: 10px">
            <div class="operate-btn mrg-10" @click="addData">
              {{ $t("pcbas.pcba.new") }}
            </div>
            <div class="operate-btn mrg-10" @click="editData">
              {{ $t("pcbas.pcba.edit") }}
            </div>
            <div class="operate-btn mrg-10" @click="deleteData">
              {{ $t("pcbas.pcba.delete") }}
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="tcm-hold"></div>
    <div class="tcm-table">
      <el-table
        :empty-text="$t('tcmlists.tcmlist.noData')"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="eBOMPN"
          label="Operate"
          width="130"
          align="center"
        >
          <template #default="scope">
            <div class="btn-text" @click="editDetail(scope.row)">
              Compute Detail
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="eBOMPN"
          label="eBOM PN"
          width="110"
          align="center"
        />
        <el-table-column prop="name" label="Name" align="center" />
        <el-table-column
          prop="description"
          label="Description"
          align="center"
        />
        <el-table-column prop="version" label="Revision" align="center" />
        <el-table-column prop="project" label="Project" align="center" />
        <el-table-column prop="regionName" label="Region" align="center" />
        <el-table-column
          prop="annualVolume"
          label="Annual Volume"
          align="center"
        />
        <el-table-column
          prop="extCurrencyName"
          label="Ext. Currency1"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.extCurrencyName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="extTotalPrice"
          label="Total Ext.Price1"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.extTotalPrice }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="extCurrencyName2"
          label="Ext. Currency2"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.extCurrencyName2 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="extTotalPrice2"
          label="Total Ext.Price2"
          align="center"
        >
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.extTotalPrice2 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date" align="center">
          <template #default="scope">
            <div class="color-F59A23">{{ scope.row.date }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-cla">
      <div>
        <el-input
          v-model="searchForm.limit"
          @input="(val) => (searchForm.limit = val.replace(/[^\d]/g, ''))"
          min="1"
          style="width: 130px"
          @change="handleSizeChange"
        >
          <template #suffix>
            {{ $t("ccls.ccl.barPage") }}
          </template>
        </el-input>
      </div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="searchForm.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div> -->
  <AddEBom ref="addDia" @confirmInfo="confirmInfo"></AddEBom>
</template>

<script>
import {
  EBomsLoad,
  AddOrUpdateEBoms,
  EBomsDelete,
} from "../../../assets/js/requestApi.js";
import commonUtil from "../../../assets/js/commonUtil.js";
import { CollectionTag } from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
import AddEBom from "./addEBom.vue";
export default {
  components: {
    AddEBom,
  },
  name: "eBomList",
  data() {
    return {
      TableHeight: 0,
      searchForm: {
        description: "",
        page: 1,
        limit: 10,
        key: "",
      },
      tableData: [],
      // 已选择数据
      chooseData: [],
      // 已选择对比数据
      drawer: false,
      // 选择的对比数据列表
      compareList: [],
      // 总页数
      total: 0,
      pageSize: 10,
      power: [],
      btnAdd: "",
      btnDel: "",
      btnEdit: "",
      timer: "",
      timers: "",
      // 数据重复是否继续导入
      isContinue: false,
    };
  },

  mounted() {
    this.autoHeight();
    this.getList();
    this.timer = setTimeout(this.gettime, 1000);
  },
  methods: {
    RowClick(row) {
      this.editDetail(row);
    },
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        console.log(1);
        this.setHeight();
      });
    },
    setHeight() {
      this.$nextTick(() => {
        this.TableHeight = window.innerHeight - 235; // 这里的X就是顶部栏的高度
      });
    },
    gettime() {
      this.power = JSON.parse(localStorage.getItem("power"));
      this.power.forEach((e) => {
        if (e.name == "添加") {
          this.btnAdd = 1;
        }
        if (e.name == "删除") {
          this.btnDel = 1;
        }
        if (e.name == "编辑") {
          this.btnEdit = 1;
        }
      });
      clearTimeout(this.timer);
    },
    getList() {
      this.searchForm = {
        description: "",
        page: 1,
        limit: 10,
        key: "",
      };
      this.search();
    },
    search() {
      EBomsLoad(this.searchForm, (response) => {
        if (response && response.code == 200) {
          this.tableData = response.data;
          this.total = response.count;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.search();
    },
    handleSizeChange() {
      // console.log(this.searchForm.limit)
      this.pageSize = parseInt(this.searchForm.limit);
      if (this.searchForm.limit === "") {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.setPageNum"),
          "",
          "warning"
        );
        return;
      }
      if (this.searchForm.limit == 0) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.pageThanZero"),
          "",
          "warning"
        );
        return;
      }
      this.searchForm.page = 1;
      this.search();
    },
    // 新增
    addData() {
      this.$refs.addDia.visible = true;
    },
    confirmInfo(form) {
      // console.log(form)
      AddOrUpdateEBoms(form, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.operateSuccessfully"),
            "",
            "success"
          );
          this.$refs.addDia.close();
          this.search();
        } else {
          commonUtil.showTipInfo(
            "eBOM PN, Revision ID data duplication",
            "",
            "warning"
          );
        }
      });
    },
    // 编辑数据
    editData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      if (this.chooseData.length > 1) {
        commonUtil.showTipInfo(
          this.$t("unifiedPrompts.unifiedPrompt.onlyOne"),
          "",
          "warning"
        );
        return;
      }
      this.$refs.addDia.form = JSON.parse(JSON.stringify(this.chooseData[0]));
      this.$refs.addDia.visible = true;
    },
    // 编辑详情数据
    editDetail(row) {
      this.$router.push({
        path: "/eBomDetail",
        name: "eBomDetail",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 删除
    deleteData() {
      if (this.chooseData.length == 0) {
        commonUtil.showTipInfo(
          this.$t("userlists.userlist.selecWant"),
          "",
          "warning"
        );
        return;
      }
      let ids = [];
      this.chooseData.forEach(function (val, index) {
        ids.push(val.id);
      });
      EBomsDelete(ids, (response) => {
        if (response && response.code == 200) {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.deleteSuccessfully"),
            "",
            "success"
          );
          this.search();
        }
      });
    },
    // 表格多选
    handleSelectionChange(val) {
      // console.log(val)
      this.chooseData = val;
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/car-table-bg.png") no-repeat center;
  background-size: contain;
}

.main-top_handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.tree-cla {
  background: #fff;
  .tree-cla-search {
    padding: 10px;
  }
  input {
    display: block;
    width: calc(100% - 10px);
    height: 40px;
    border-radius: 10px;
    background: #f5f7ff;
  }
}
.main-handle {
  align-items: center;
}
.main-handle-item {
  margin-right: 10px;
  flex: 1;
}
.main-handle input,
.main-handle .el-select .el-input {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
}
.main-handle input::placeholder {
  color: #3e4954;
  text-align: center;
  font-size: 14px;
}

.handle-btn {
  align-items: center;
  height: 40px;
  .handle-btn-item {
    padding: 0 20px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #b2bde7;
    color: #fff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
}
// 日期选择器
.select-box {
  position: relative;
  .select-btn {
    width: 200px;
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
    .Calendar,
    .Switch {
      font-size: 18px;
      color: #2f4cdd;
    }
  }
}
.search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  background: #445581;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
</style>