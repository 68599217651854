<template>
  <div>
    <div class="crumbs-box">
      <div class="crumbs-box-item parent" @click="Goback">
        {{ $t("homePage.home.Back") }}
      </div>
      <div class="line"></div>
      <div class="crumbs-box-item" v-for="(item, index) in crumbs">
        {{ item }}
        <el-icon v-show="index != crumbs.length - 1"><ArrowRight /></el-icon>
      </div>
    </div>

    <div class="tcm-title">
      <div class="tcm-title-left">
        <div class="mgr-30">
          {{ $t("templateLists.templateList.newInterface") }}
        </div>
      </div>
      <div>
        <el-button
          style="
            background-color: var(--themeColor);
            color: var(--menuTextActive);
          "
          v-show="operateType != 'detail'"
          @click="submit"
          >{{ $t("ccls.ccl.ok") }}</el-button
        >
      </div>
    </div>
    <div class="main">
      <div class="tcm-add">
        <div
          style="
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            padding: 20px 50px 0 50px;
          "
        >
          <div
            v-show="operateType != 'add' && operateType != 'editCopy'"
            class="ipt-item"
          >
            <div class="size-14 padding-9-0">
              {{ $t("templateLists.templateList.templateNo") }}
            </div>
            <el-input v-model="number" style="width: 214px" disabled />
          </div>
          <div class="ipt-item">
            <div class="size-14 padding-9-0 rules-red">
              {{ $t("templateLists.templateList.templateName") }}
            </div>
            <el-input
              v-model="name"
              style="width: 214px"
              :disabled="operateType == 'detail'"
            />
          </div>
          <div class="ipt-item">
            <div class="size-14 padding-9-0 rules-red">
              {{ $t("changePriceLists.changePriceList.commodity") }}
            </div>
            <el-tree-select
              clearable
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              style="width: 214px"
              v-model="CommodityId"
              :disabled="operateType == 'detail'"
              :data="
                operateType == 'detail' ? AllCommodityOptions : CommodityOptions
              "
              :props="defaultProps"
              check-strictly
              :render-after-expand="false"
              :default-expand-all="true"
            />
            <!-- operateType == 'detail' ? AllCommodityOptions :  -->
          </div>
          <div class="ipt-item">
            <div class="size-14 padding-9-0 rules-red">
              {{ $t("templateLists.templateList.region") }}
            </div>
            <el-select
              clearable
              v-model="regionId"
              :disabled="operateType == 'detail'"
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              style="width: 214px"
            >
              <el-option
                v-for="item in regionOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="ipt-item">
            <div class="size-14 padding-9-0 rules-red">
              {{ $t("changePriceLists.changePriceList.currency") }}
            </div>
            <el-select
              clearable
              v-model="currencyId"
              filterable
              :disabled="operateType == 'detail'"
              @change="changeCurrency"
              :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
              style="width: 214px"
            >
              <el-option
                v-for="item in currencyOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>

          <div class="ipt-item">
            <div class="size-14 padding-9-0">
              {{ $t("templateLists.templateList.comments") }}
            </div>
            <el-input
              v-model="comment"
              style="width: 214px"
              :disabled="operateType == 'detail'"
            />
          </div>
          <div
            class="ipt-item"
            v-show="
              operateType != 'add' && !myTemplate && operateType != 'editCopy'
            "
          >
            <div class="size-14 padding-9-0">
              {{ $t("templateLists.templateList.version") }}
            </div>
            <el-input v-model="version" style="width: 214px" disabled />
          </div>
        </div>
        <div class="hover-menu">
          <div
            :class="chooseCurrent == 'rm' ? 'choose-btn' : 'hover-btn'"
            style="margin-bottom: 10px"
            @click="chooseMenu('rm')"
          >
            RM
          </div>
          <div
            :class="chooseCurrent == 'pur' ? 'choose-btn' : 'hover-btn'"
            style="margin-bottom: 10px"
            @click="chooseMenu('pur')"
          >
            PUR
          </div>
          <div
            :class="chooseCurrent == 'pro' ? 'choose-btn' : 'hover-btn'"
            style="margin-bottom: 10px"
            @click="chooseMenu('pro')"
          >
            PRO
          </div>
          <div
            :class="chooseCurrent == 'p&l' ? 'choose-btn' : 'hover-btn'"
            style="margin-bottom: 10px"
            @click="chooseMenu('p&l')"
          >
            P&L
          </div>
          <div
            :class="chooseCurrent == 'oh' ? 'choose-btn' : 'hover-btn'"
            @click="chooseMenu('oh')"
          >
            OH
          </div>
        </div>
        <div style="padding-right: 80px">
          <TcmRm
            v-show="chooseCurrent == 'rm'"
            ref="rwTcm"
            type="all"
            :myTemplate="myTemplate"
            :operateType="operateType"
            :CurrencyOptions="currencyOptions"
            :currencyId="currencyId"
            :CommodityId="CommodityId"
          ></TcmRm>
          <TcmPur
            v-show="chooseCurrent == 'pur'"
            ref="purTcm"
            type="all"
            :operateType="operateType"
            :CurrencyOptions="currencyOptions"
            :currencyId="currencyId"
            :CommodityId="CommodityId"
          ></TcmPur>
          <TcmPro
            v-show="chooseCurrent == 'pro'"
            ref="proTcm"
            type="all"
            :operateType="operateType"
            :CurrencyOptions="currencyOptions"
            :currencyId="currencyId"
            :CommodityId="CommodityId"
          ></TcmPro>
          <TcmPl
            v-show="chooseCurrent == 'p&l'"
            ref="plTcm"
            type="all"
            :operateType="operateType"
          ></TcmPl>
          <TcmOh
            v-show="chooseCurrent == 'oh'"
            ref="ohTcm"
            type="all"
            :operateType="operateType"
          ></TcmOh>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TcmRm from "./tcmRm.vue";
import TcmPur from "./tcmPur.vue";
import TcmPro from "./tcmPro.vue";
import TcmPl from "./tcmPL.vue";
import TcmOh from "./tcmOh.vue";
import {
  GetOrgs,
  AddOrUpdateTemplateApplys,
  GetTemplateOrDraftDetail,
  getRegions,
  loadCurrency,
} from "../../assets/js/requestApi.js";
import commonUtil from "../../assets/js/commonUtil.js";
export default {
  components: {
    TcmRm,
    TcmPur,
    TcmPro,
    TcmPl,
    TcmOh,
  },
  // watch: {
  //   operateType: {
  //     immediate: true,
  //     handler(nval) {
  //       switch (nval) {
  //         case "add":
  //           this.crumbs = ["Template", "Add Template"];
  //           break;
  //         case "edit":
  //           this.crumbs = ["Template", "Edit Template"];
  //           break;
  //         case "detail":
  //           this.crumbs = ["Template", "Template Detail"];
  //           break;
  //       }
  //     },
  //   },
  // },
  data() {
    return {
      id: "",
      number: "",
      name: "",
      version: "",
      comment: "",
      isEnable: 1,
      chooseCurrent: "rm",
      regionId: "",
      CommodityId: "",
      CommodityName: "",
      operateType: "add",
      CommodityOptions: [],
      AllCommodityOptions: [],
      // 商品分类显示字段
      defaultProps: {
        children: "children",
        label: "Name",
      },
      // region下拉选项
      regionOptions: [],
      // 货币选项
      currencyOptions: [],
      // 已选择的货币
      currencyId: "",
      // 保存原始currencyId
      oldCurrencyId: "",
      myTemplate: false,
      crumbs: [],
    };
  },
  mounted() {
    this.operateType = this.$route.query.type;
    if (this.operateType == "add") {
      this.CommodityId = this.$route.query.CommodityId;
    } else {
      let id = this.$route.query.id;
      this.getDetail(id);
    }
    if (this.$route.query.template) {
      this.myTemplate = true;
    } else {
      this.myTemplate = false;
    }
    if (this.$route.query.crumbs) {
      this.crumbs = this.$route.query.crumbs;
    }
    this.CommodityName = this.$route.query.CommodityName;
    this.getCommdity();
    this.getRegion();
    this.getCurrencyOptions();
  },
  methods: {
    chooseMenu(val) {
      this.chooseCurrent = val;
    },
    // 获取区域列表选项
    getRegion() {
      getRegions(
        {
          Keyword: "",
          isEnable: 1,
          page: 1,
          limit: 9999,
          key: "",
        },
        (response) => {
          if (response && response.code == 200) {
            this.regionOptions = response.data;
          }
        }
      );
    },
    // 查询货币
    getCurrencyOptions() {
      loadCurrency(
        {
          IsOrderNumber: 0,
          Keyword: "",
          page: 1, //当前页
          limit: 99999, //每页个数
        },
        (response) => {
          if (response && response.code == 200) {
            this.currencyOptions = response.data;
          }
        }
      );
    },
    // 获取详情
    getDetail(id) {
      let _this = this;
      GetTemplateOrDraftDetail({ id: id }, (response) => {
        if (response && response.code == 200) {
          let result = response.result;
          this.id = result.id;
          if (this.operateType != "editCopy") {
            this.number = result.number;
          }
          this.name = result.name;
          if (this.operateType == "edit") {
            this.version = parseFloat(result.version) + 1;
          } else {
            this.version = result.version;
          }
          this.comment = result.comment;
          this.regionId = result.regionId;
          this.currencyId = result.currencyId;
          this.isEnable = result.isEnable;
          this.$refs.rwTcm.materialCost_RM = result.materialCost_RM
            ? result.materialCost_RM
            : "";
          result.materialList.forEach(function (val, index) {
            let cLines = [];
            val.cLines.forEach(function (va, ind) {
              cLines.push({
                fieldName: va.paramName,
                calculatorTableId: va.calculator_TableId,
              });
            });
            val.cLines = cLines;
            val.exchangeRate = val.converRate;
            // 模板和编辑复制的模板不用传id
            if (!_this.myTemplate || _this.operateType == "editCopy") {
              val.id = "";
            }
          });
          this.$refs.rwTcm.formData = result.materialList;
          this.$refs.rwTcm.materialDelIds = [];
          this.$refs.purTcm.purchaseCost_PUR = result.purchaseCost_PUR
            ? result.purchaseCost_PUR
            : "";
          result.purchaseList.forEach(function (val, index) {
            val.exchangeRate = val.converRate;
            if (!_this.myTemplate || _this.operateType == "editCopy") {
              val.id = "";
            }
          });
          this.$refs.purTcm.formData = result.purchaseList;
          this.$refs.purTcm.purchaseDelIds = [];
          this.$refs.proTcm.laborCost_PRO = result.laborCost_PRO
            ? result.laborCost_PRO
            : "";
          this.$refs.proTcm.setUpCost_PRO = result.setUpCost_PRO
            ? result.setUpCost_PRO
            : "";
          this.$refs.proTcm.burdenCost_PRO = result.burdenCost_PRO
            ? result.burdenCost_PRO
            : "";
          this.$refs.proTcm.manufacturingCost_PRO = result.manufacturingCost_PRO
            ? result.manufacturingCost_PRO
            : "";
          result.processList.forEach(function (val, index) {
            let cLines = [];
            val.cLines.forEach(function (va, ind) {
              cLines.push({
                fieldName: va.paramName,
                calculatorTableId: va.calculator_TableId,
              });
            });
            val.exchangeRate = val.converRate;
            val.cLines = cLines;
            if (!_this.myTemplate || _this.operateType == "editCopy") {
              val.id = "";
            }
            val.descShow = false;
            val.setShow = false;
            val.opeShow = true;
          });
          this.$refs.proTcm.formData = result.processList;
          this.$refs.proTcm.processDelIds = [];
          this.$refs.plTcm.form.calculationMethod = result.calculationMethod;
          this.$refs.plTcm.form.freightCost = result.freightCost;
          this.$refs.plTcm.form.wareHouseCost = result.wareHouseCost;
          this.$refs.plTcm.form.packageCost = result.packageCost;
          this.$refs.plTcm.form.otherCost = result.otherCost;
          this.$refs.plTcm.form.amortizationDescription =
            result.amortizationDescription;
          this.$refs.plTcm.form.totalPieces = result.totalPieces;
          this.$refs.plTcm.form.totalAmortize = result.totalAmortize;
          this.$refs.plTcm.form.amortizeCost = result.amortizeCost;
          this.$refs.ohTcm.form.sga = result.sga;
          this.$refs.ohTcm.form.sgaCost = result.sgaCost;
          this.$refs.ohTcm.form.materialProfit = result.materialProfit;
          this.$refs.ohTcm.form.addProfit = result.addProfit;
          this.$refs.ohTcm.form.purchaseProfit = result.purchaseProfit;
          this.$refs.ohTcm.form.totalProfit = result.totalProfit;
          this.$refs.ohTcm.form.totalProfitCost = result.totalProfitCost;
          this.$refs.ohTcm.form.materialPC = result.materialPC;
          this.CommodityId = result.commodityId;
          this.$refs.rwTcm.materialfileList = result.attachmentList;
        }
      });
    },
    // 获取商品分类数据
    getCommdity() {
      let _this = this;
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 0,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.CommodityOptions = commonUtil.transferList(response.Result);
            console.log(this.CommodityOptions, "CommodityOptions");
          }
        }
      );
      GetOrgs(
        {
          type: 0,
          versionType: 0,
          TypeCost: 0,
          isAll: 1,
        },
        (response) => {
          if (response && response.Code == 200) {
            this.AllCommodityOptions = commonUtil.transferList(response.Result);
          }
        }
      );
    },
    // 提交
    submit() {
      // let proForm = JSON.parse(JSON.stringify(this.$refs.proTcm.formData))
      // proForm.forEach(function(val, index) {
      // 	val.name = val.processName
      // })
      let _this = this;
      if (this.name === "" || this.name === null) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.templateRequired"),
          "",
          "warning"
        );
        return;
      }
      if (this.CommodityId === "" || this.CommodityId === null) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.commodityRequired"),
          "",
          "warning"
        );
        return;
      }
      if (this.regionId === "" || this.regionId === null) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.regionRquired"),
          "",
          "warning"
        );
        return;
      }
      if (this.currencyId === "" || this.currencyId === null) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.currencyRequired"),
          "",
          "warning"
        );
        return;
      }
      // 判断物料必填项
      let rawDescriptionIsEmpty = false;
      let rawUnitMeasureIdsEmpty = false;
      let rawUnitCostIsEmpty = false;
      let rawUnitResaleValueIsEmpty = false;
      let rawExchangerateIsEmpty = false;
      this.$refs.rwTcm.formData.forEach(function (val, index) {
        if (val.converRate === "") {
          rawExchangerateIsEmpty = true;
          return;
        }
        if (val.description === "") {
          rawDescriptionIsEmpty = true;
          return;
        }
        if (val.unitMeasureId === "") {
          rawUnitMeasureIdsEmpty = true;
          return;
        }
        if (val.unitCost === "") {
          rawUnitCostIsEmpty = true;
          return;
        }
        if (val.unitResaleValue === "") {
          rawUnitResaleValueIsEmpty = true;
          return;
        }
      });
      if (rawExchangerateIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.exRquired"),
          "",
          "warning"
        );
        return;
      }
      if (rawDescriptionIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.materialRequired"),
          "",
          "warning"
        );
        return;
      }
      if (rawUnitMeasureIdsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.unitRequired"),
          "",
          "warning"
        );
        return;
      }
      if (rawUnitCostIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.UnitMaterialRequired"),
          "",
          "warning"
        );
        return;
      }
      if (rawUnitResaleValueIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.materialRequireds"),
          "",
          "warning"
        );
        return;
      }
      // 判断工艺必填项
      let setTimeIssample = false;
      let setPersonIssample = false;
      let proprocessNameIssample = false;
      let equSizeIssample = false;
      let pieceHourIssample = false;
      let pieceCycleIssample = false;
      let efficiencyPercentIssample = false;
      let directRateIssample = false;
      let setUpRateIssample = false;
      let directOperateQtyIssample = false;
      let variableRateIssample = false;
      let scrapPercentIssample = false;
      let proExchangerateIsEmpty = false;
      this.$refs.proTcm.formData.forEach(function (val, index) {
        if (val.converRate === "") {
          proExchangerateIsEmpty = true;
          return;
        }
        if (val.setTime === "") {
          setTimeIssample = true;
          return;
        }
        if (val.setPerson === "") {
          setPersonIssample = true;
          return;
        }
        if (val.name === "") {
          proprocessNameIssample = true;
          return;
        }
        if (val.equSize === "") {
          equSizeIssample = true;
          return;
        }
        if (val.pieceHour === "") {
          pieceHourIssample = true;
          return;
        }
        if (val.pieceCycle === "") {
          pieceCycleIssample = true;
          return;
        }
        if (val.efficiencyPercent === "") {
          efficiencyPercentIssample = true;
          return;
        }
        if (val.directRate === "") {
          directRateIssample = true;
          return;
        }
        if (val.setUpRate === "") {
          setUpRateIssample = true;
          return;
        }
        if (val.directOperateQty === "") {
          directOperateQtyIssample = true;
          return;
        }
        if (val.variableRate === "") {
          variableRateIssample = true;
          return;
        }
        if (val.scrapPercent === "") {
          scrapPercentIssample = true;
          return;
        }
      });
      if (proExchangerateIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.exRquired"),
          "",
          "warning"
        );
        return;
      }
      if (setTimeIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.setRequired"),
          "",
          "warning"
        );
        return;
      }
      if (setPersonIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.ofMissing"),
          "",
          "warning"
        );
        return;
      }
      if (proprocessNameIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseDescription"),
          "",
          "warning"
        );
        return;
      }
      if (equSizeIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseSize"),
          "",
          "warning"
        );
        return;
      }
      if (pieceHourIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseHour"),
          "",
          "warning"
        );
        return;
      }
      if (pieceCycleIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleasePieceCycle"),
          "",
          "warning"
        );
        return;
      }
      if (efficiencyPercentIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseEfficiency"),
          "",
          "warning"
        );
        return;
      }
      if (directRateIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseRate"),
          "",
          "warning"
        );
        return;
      }
      if (setUpRateIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.setUpRates"),
          "",
          "warning"
        );
        return;
      }
      if (directOperateQtyIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseOperators"),
          "",
          "warning"
        );
        return;
      }
      if (variableRateIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseVariable"),
          "",
          "warning"
        );
        return;
      }
      if (scrapPercentIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseScrap"),
          "",
          "warning"
        );
        return;
      }
      // 判断采购必填项
      let processNameIssample = false;
      let quantityIssample = false;
      let unitIssample = false;
      let unitCostIssample = false;
      let purExchangerateIsEmpty = false;
      this.$refs.purTcm.formData.forEach(function (val, index) {
        if (val.converRate === "") {
          purExchangerateIsEmpty = true;
          return;
        }
        if (val.name === "") {
          processNameIssample = true;
          return;
        }
        if (val.quantity === "") {
          quantityIssample = true;
          return;
        }
        if (val.unit === "") {
          unitIssample = true;
          return;
        }
        if (val.unitCost === "") {
          unitCostIssample = true;
          return;
        }
      });
      if (purExchangerateIsEmpty) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.exRquired"),
          "",
          "warning"
        );
        return;
      }
      if (processNameIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseProcess"),
          "",
          "warning"
        );
        return;
      }
      if (quantityIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseAssembly"),
          "",
          "warning"
        );
        return;
      }
      if (unitIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseMeasure"),
          "",
          "warning"
        );
        return;
      }
      if (unitCostIssample) {
        commonUtil.showTipInfo(
          this.$t("changePriceLists.changePriceList.pleaseUoM"),
          "",
          "warning"
        );
        return;
      }
      let data = {
        id: this.myTemplate ? this.id : "",
        number: this.number,
        name: this.name,
        version: this.version,
        comment: this.comment,
        isEnable: 1,
        materialCost_RM: this.$refs.rwTcm.materialCost_RM,
        materialLine: this.$refs.rwTcm.formData,
        materialDelIds: this.$refs.rwTcm.materialDelIds,
        purchaseCost_PUR: this.$refs.purTcm.purchaseCost_PUR,
        purchaseLine: this.$refs.purTcm.formData,
        purchaseDelIds: this.$refs.purTcm.purchaseDelIds,
        laborCost_PRO: this.$refs.proTcm.laborCost_PRO,
        setUpCost_PRO: this.$refs.proTcm.setUpCost_PRO,
        burdenCost_PRO: this.$refs.proTcm.burdenCost_PRO,
        manufacturingCost_PRO: this.$refs.proTcm.manufacturingCost_PRO,
        processLine: this.$refs.proTcm.formData,
        // processLine: proForm,
        processDelIds: this.$refs.proTcm.processDelIds,
        calculationMethod: this.$refs.plTcm.form.calculationMethod,
        calculationMethod_Freight:
          this.$refs.plTcm.form.calculationMethod_Freight,
        calculationMethod_Ware: this.$refs.plTcm.form.calculationMethod_Ware,
        calculationMethod_Package:
          this.$refs.plTcm.form.calculationMethod_Package,
        calculationMethod_Taxes: this.$refs.plTcm.form.calculationMethod_Taxes,
        freightCost: this.$refs.plTcm.form.freightCost,
        wareHouseCost: this.$refs.plTcm.form.wareHouseCost,
        packageCost: this.$refs.plTcm.form.packageCost,
        otherCost: this.$refs.plTcm.form.otherCost,
        amortizationDescription: this.$refs.plTcm.form.amortizationDescription,
        totalPieces: this.$refs.plTcm.form.totalPieces,
        totalAmortize: this.$refs.plTcm.form.totalAmortize,
        amortizeCost: this.$refs.plTcm.form.amortizeCost,
        sga: this.$refs.ohTcm.form.sga,
        sgaCost: this.$refs.ohTcm.form.sgaCost,
        materialProfit: this.$refs.ohTcm.form.materialProfit,
        addProfit: this.$refs.ohTcm.form.addProfit,
        purchaseProfit: this.$refs.ohTcm.form.purchaseProfit,
        totalProfit: this.$refs.ohTcm.form.totalProfit,
        totalProfitCost: this.$refs.ohTcm.form.totalProfitCost,
        materialPC: this.$refs.ohTcm.form.materialPC,
        commodityId: this.CommodityId,
        regionId: this.regionId,
        currencyId: this.currencyId,
        isDraft: this.myTemplate ? 1 : 0,
      };
      AddOrUpdateTemplateApplys(data, (response) => {
        if (this.operateType == "add") {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.addedSuccess"),
            "",
            "success"
          );
        } else {
          commonUtil.showTipInfo(
            this.$t("unifiedPrompts.unifiedPrompt.editSuccess"),
            "",
            "success"
          );
        }
        this.$router.back(-1);
      });
    },
    // 切换模板币种
    changeCurrency(val) {
      let _this = this;
      this.$refs.rwTcm.formData.forEach(function (val, index) {
        if (val.convertCurrencyId === "") {
          val.convertCurrencyId = _this.currencyId;
          if (val.converRate === "") {
            val.converRate = "1";
            val.exchangeRate = "1";
          } else {
            val.exchangeRate = val.converRate;
          }
        }
        val.currencyId = _this.currencyId;
      });
      this.$refs.proTcm.formData.forEach(function (val, index) {
        if (val.convertCurrencyId === "") {
          val.convertCurrencyId = _this.currencyId;
          if (val.converRate === "") {
            val.converRate = "1";
            val.exchangeRate = "1";
          } else {
            val.exchangeRate = val.converRate;
          }
        }
        val.currencyId = _this.currencyId;
      });
      this.$refs.purTcm.formData.forEach(function (val, index) {
        if (val.convertCurrencyId === "") {
          val.convertCurrencyId = _this.currencyId;
          if (val.converRate === "") {
            val.converRate = "1";
            val.exchangeRate = "1";
          } else {
            val.exchangeRate = val.converRate;
          }
        }
        val.currencyId = _this.currencyId;
      });
    },
    Goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  padding-top: 70px;
  width: 100%;
  min-height: 100%;
  background: url("../../assets/img/car-table-bg.png") no-repeat right;
  background-position: right 4% bottom 0;
  background-size: 650px 600px;
  border-radius: 20px;
  background-color: #fff;
}
.tcm-add {
  position: relative;
}
.tcm-title {
  position: fixed;
  top: 100px;
  left: 235px;
  width: calc(100% - 260px);
  border-bottom: 1px solid #9a9a9a;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  background-color: #fff;
  z-index: 99;
}
.tcm-title-left {
  display: flex;
}
.mgr-30 {
  margin-right: 30px;
}
.mgr-10 {
  margin-right: 10px;
}
.hover-menu {
  width: 80px;
  padding: 15px 0;
  box-sizing: border-box;
  background-color: rgba(215, 215, 215, 0.474509803921569);
  border-radius: 5px;
  position: fixed;
  top: 200px;
  right: 0;
  z-index: 888;
}
.hover-btn {
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  color: #fff;
  background: rgba(174, 174, 174, 0.623529411764706);
  text-align: center;
  border-radius: 5px;
  padding: 8px 0;
  margin-left: 30px;
  cursor: pointer;
}
.choose-btn {
  font-size: 18px;
  font-weight: bold;
  width: 50px;
  margin-left: 30px;
  text-align: center;
  border-radius: 0px 5px 5px 0;
  padding: 8px 0;
  background-color: #fff;
  border-left: 4px solid #2f4cdd;
  cursor: pointer;
}
.size-14 {
  font-size: 14px;
}
.padding-9-0 {
  padding: 7px 9px;
}
.rules-red::before {
  content: "* ";
  color: red;
}
.ipt-item {
  flex: 1;
}
.crumbs-box {
  position: fixed;
  top: 70px;
  left: 220px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  z-index: 999;
  // border-bottom: 1px solid #e4e7ed;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: #3e4954;
  .crumbs-box-item {
    display: flex;
    align-items: center;
  }
  .el-icon {
    margin: 0 10px;
  }
  .parent {
    color: #2f4cdd;
    cursor: pointer;
  }
  .line {
    margin: 0 15px;
    width: 2px;
    height: 15px;
    background: #3e4954;
  }
}
</style>