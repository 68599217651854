<template>
  <div>
    <div class="General-Infor">
      <div class="General-Infor-top">
        <div>
          · {{ $t("changePriceLists.changePriceList.generalInformation") }}
        </div>
        <div class="submit-btn" @click="Submit">Submit</div>
      </div>
      <el-row :gutter="20" justify="space-between">
        <!-- quoteType -->
        <el-col :span="4">
          <p>{{ $t("userlists.userlist.quoteType") }}</p>
          <el-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="form.quoteTypeId"
            disabled
            placeholder=""
            style="width: 214px"
            @change="quoteTypeChange"
          >
            <el-option
              v-for="item in QuoteTypeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>
        <!-- commodity -->
        <el-col :span="4">
          <p>{{ $t("changePriceLists.changePriceList.commodity") }}</p>
          <el-tree-select
            clearable
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="form.commodityId"
            disabled
            :data="CommodityOptions"
            :props="defaultProps"
            check-strictly
            :render-after-expand="false"
            :default-expand-all="true"
          />
        </el-col>
        <!-- partDescription -->
        <el-col :span="4">
          <p>{{ $t("changePriceLists.changePriceList.partDescription") }}</p>
          <el-input
            v-model="form.partDescription"
            @input="checkDescription(form.partDescription)"
            disabled
            placeholder=""
            style="width: 214px"
          />
        </el-col>
        <!-- programName -->
        <el-col :span="4">
          <p
            :class="
              form.quoteTypeId == '6df7494e-4a29-4f38-9a0a-abbff3bf9ef1' ||
              form.quoteTypeId == '67a02fe3-b2a8-48a5-922c-b5c1e7da5622'
                ? 'custom-el-form-rules'
                : ''
            "
          >
            {{ $t("changePriceLists.changePriceList.programName") }}
          </p>
          <el-input
            v-model="form.programName"
            disabled
            placeholder=""
            style="width: 214px"
          />
        </el-col>
        <!-- ecrNo -->
        <el-col :span="4">
          <p
            :class="
              form.quoteTypeId == '6df7494e-4a29-4f38-9a0a-abbff3bf9ef1'
                ? 'custom-el-form-rules'
                : ''
            "
          >
            {{ $t("changePriceLists.changePriceList.ecrNo") }}
          </p>
          <el-input v-model="form.ecrno" disabled placeholder="" />
        </el-col>
      </el-row>
      <el-row :gutter="20" justify="space-between">
        <!-- partNumber -->
        <el-col :span="4">
          <p
            :class="
              form.quoteTypeId == '6df7494e-4a29-4f38-9a0a-abbff3bf9ef1' ||
              form.quoteTypeId == '67a02fe3-b2a8-48a5-922c-b5c1e7da5622' ||
              form.quoteTypeId == 'cdc850d9-a228-4f63-bf28-ed6b1ce6a2c9'
                ? 'custom-el-form-rules'
                : ''
            "
          >
            {{ $t("changePriceLists.changePriceList.partNumber") }}
          </p>
          <el-input
            v-model="form.partNumber"
            @input="(v) => (form.partNumber = v.replace(/[^\d.]/g, ''))"
            disabled
            placeholder=""
            style="width: 214px"
          />
        </el-col>
        <!-- partRevision -->
        <el-col :span="4">
          <p>{{ $t("changePriceLists.changePriceList.drawingsRevision") }}</p>
          <el-input
            v-model="form.partRevision"
            @input="(v) => (form.partRevision = v.replace(/[^\d.]/g, ''))"
            disabled
            placeholder=""
            style="width: 214px"
          />
        </el-col>
        <!-- dueDate -->
        <el-col :span="4" v-show="operateType != 'tcmResult'">
          <p>{{ $t("changePriceLists.changePriceList.dueDate") }}</p>
          <el-date-picker
            v-model="form.doDate"
            disabled
            type="date"
            placeholder=""
          />
        </el-col>
        <!-- urgency -->
        <el-col :span="4" v-show="operateType != 'tcmResult'">
          <p>{{ $t("changePriceLists.changePriceList.urgency") }}</p>
          <el-select
            clearable
            v-if="
              $t('tcmdatalists.tcmdatalist.urgencyOptionss') == 'urgencyOptions'
            "
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="form.urgencyId"
            disabled
          >
            <el-option
              v-for="item in urgencyOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-select
            clearable
            v-if="
              $t('tcmdatalists.tcmdatalist.urgencyOptionss') ==
              'urgencyOptionss'
            "
            :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
            v-model="form.urgencyId"
            disabled
          >
            <el-option
              v-for="item in urgencyOptionss"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-col>
        <el-col :span="4"></el-col>
      </el-row>
    </div>

    <div v-if="genCurrOptions.length > 0">
      <div
        class="General-Infor"
        v-for="(item, genIndex) in genList"
        :key="genIndex"
      >
        <el-row justify="space-between">
          <el-col :span="14">
            <el-row :gutter="21" justify="space-between">
              <!-- currency -->
              <el-col :span="7">
                <p>{{ $t("changePriceLists.changePriceList.currency") }}</p>
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.Currency"
                  filterable
                  disabled
                  @change="currencyChange(genIndex)"
                  style="width: 214px"
                >
                  <el-option
                    v-for="it in genCurrOptions[genIndex].currencyOptions"
                    :key="it.currency"
                    :label="it.currencyName"
                    :value="it.currency"
                  />
                </el-select>
              </el-col>
              <!-- reportCurrency -->
              <el-col :span="7">
                <p>
                  {{ $t("changePriceLists.changePriceList.reportCurrency") }}
                </p>
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.ReportCurrency"
                  filterable
                  disabled
                  @change="reportCurrencyChange(genIndex)"
                  style="width: 214px"
                >
                  <el-option
                    v-for="it in genCurrOptions[genIndex].currencyReportOptions"
                    :key="it.currencyCNYReportCurrency"
                    :label="it.currencyCNYReportCurrencyName"
                    :value="it.currencyCNYReportCurrency"
                  />
                </el-select>
              </el-col>
              <!-- exchangeRate -->
              <el-col :span="7">
                <p>{{ $t("changePriceLists.changePriceList.exchangeRate") }}</p>
                <el-input v-model="item.rate" disabled style="width: 214px" />
              </el-col>
            </el-row>
            <el-row
              style="margin-top: 10px"
              :gutter="21"
              justify="space-between"
            >
              <!-- region -->
              <el-col :span="7">
                <p>{{ $t("changePriceLists.changePriceList.region") }}</p>
                <el-select
                  clearable
                  :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')"
                  v-model="item.regionId"
                  disabled
                  style="width: 214px"
                >
                  <el-option
                    v-for="item in RegionOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-col>
              <!-- supplierName -->
              <el-col :span="7">
                <p>{{ $t("changePriceLists.changePriceList.supplierName") }}</p>
                <el-input
                  v-model="item.supplierName"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </el-col>
              <!-- materialDescription -->
              <el-col :span="7">
                <p>
                  {{
                    $t("changePriceLists.changePriceList.materialDescription")
                  }}
                </p>
                <el-input
                  v-model="item.materialDescription"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </el-col>
            </el-row>
            <el-row
              style="margin-top: 10px"
              :gutter="20"
              justify="space-between"
            >
              <!-- peakVolume -->
              <el-col :span="7">
                <p>{{ $t("changePriceLists.changePriceList.peakVolume") }}</p>
                <el-input
                  v-model="item.peakVolume"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </el-col>
              <!-- lifetimeVolume -->
              <el-col :span="7">
                <p>
                  {{ $t("changePriceLists.changePriceList.lifetimeVolume") }}
                </p>
                <el-input
                  v-model="item.lifetimeVolume"
                  disabled
                  placeholder=""
                  style="width: 214px"
                />
              </el-col>
              <!-- pegPoint -->
              <el-col :span="7">
                <p>
                  {{ $t("changePriceLists.changePriceList.pegPoint") }}
                </p>
                <el-input
                  v-model="item.pegPoint"
                  :disabled="
                    type == 'detail' ||
                    (operateType == 'operation' && type != 'editImport')
                  "
                  placeholder=""
                  style="width: 214px"
                />
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="10">
            <div class="Year-Volume">
              <p>Year</p>
              <p>Volume</p>
              <span v-show="type != 'detail' && operateType == 'application'"
                >Operation</span
              >
            </div>
            <div
              class="Year-Volume-ipt"
              v-for="(it, ind) in item.yearVolumeList"
            >
              <el-input
                class="year-ipt"
                type="text"
                disabled
                v-model="it.year"
                @input="(v) => (it.year = v.replace(/[^\d.]/g, ''))"
              />
              <el-input
                class="Volume-ipt"
                type="text"
                v-model="it.volume"
                disabled
                placeholder=""
                style="width: 100px"
                @input="transferFormat($event, it, 'volume', genIndex)"
                @change="blurVol(genIndex)"
                @blur="transferNum(it, 'volume'), blurVol(genIndex)"
              />
              <div
                class="Operation-box"
                v-show="type != 'detail' && operateType == 'application'"
              >
                <el-icon @click="addColoum(genIndex, ind)"
                  ><CopyDocument
                /></el-icon>
                <el-icon @click="toDetele(ind, 'year', genIndex)"
                  ><DeleteFilled
                /></el-icon>
              </div>
            </div>

            <div
              v-show="type != 'detail' && operateType == 'application'"
              class="Year-Volume-ipt Year-Volume-add-btn"
              @click="addColoum(genIndex)"
            >
              <el-icon><Plus /></el-icon>
            </div>
            <template v-show="operateType != 'tcmResult'">
              <p>{{ $t("changePriceLists.changePriceList.comments") }}</p>
              <el-input
                v-model="item.comment"
                :rows="3"
                type="textarea"
                placeholder=""
                style="background: transparent"
                disabled
              />
            </template>
          </el-col>
        </el-row>
        <!-- ---- -->
        <div
          class="General-Infor-top"
          style="margin-top: 10px"
          v-show="type != 'detail' && operateType == 'application'"
        >
          <div></div>
          <div class="flex">
            <div
              class="submit-btn"
              @click="toDetele(genIndex, 'gen')"
              style="background: #d3d6e4"
            >
              delect
            </div>
            <div
              class="submit-btn"
              @click="copyGen(genIndex)"
              style="background: #d3d6e4"
            >
              copy
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="tcm-form-solid"
      @click="addGen"
      v-show="type != 'detail' && operateType == 'application'"
    >
      <el-icon :size="35" color="#8c8c8c"><Plus /></el-icon>
    </div>

    <div class="General-Infor" v-show="operateType != 'tcmResult'">
      <div class="General-Infor-top">
        <div>Attach File</div>
        <div></div>
      </div>
      <div class="Attach-box">
        <div class="flex" style="margin-top: 10px">
          <div v-show="type != 'detail' && operateType == 'application'">
            <el-upload
              class="custom-el-upload-list-none"
              action="/"
              :auto-upload="false"
              multiple
              :limit="10"
              :file-list="fileListx"
              :on-change="filechange"
            >
              <div class="upload-box">
                <div class="upload-btn">
                  <div class="upload-icon">
                    <el-icon><UploadFilled /></el-icon>
                  </div>
                </div>
                <div class="upload-text">
                  <p>Upload Files</p>
                  <span>PDF, DOC, PPT, JPG, PNG</span>
                </div>
              </div>
            </el-upload>
          </div>
          <div v-show="fileListx.length > 0" class="flex">
            <div class="file-box" v-for="(item, index) in fileListx">
              <div class="upload-btn">
                <div class="upload-icon">
                  <img
                    src="../../assets/img/tcm/doc.png"
                    v-show="item.name.indexOf('.doc') > -1"
                  />
                  <img
                    src="../../assets/img/tcm/tupian.png"
                    v-show="
                      item.name.indexOf('.png') > -1 ||
                      item.name.indexOf('.jpg') > -1 ||
                      item.name.indexOf('.jpeg') > -1 ||
                      item.name.indexOf('.webp') > -1
                    "
                  />
                  <img
                    src="../../assets/img/tcm/pdf.png"
                    v-show="item.name.indexOf('.pdf') > -1"
                  />
                  <img
                    src="../../assets/img/tcm/ppt.png"
                    v-show="item.name.indexOf('.ppt') > -1"
                  />
                  <img
                    src="../../assets/img/tcm/txt.png"
                    v-show="item.name.indexOf('.txt') > -1"
                  />
                  <!-- <img src="../../assets/img/tcm/doc.png" v-show="item.name.indexOf('.doc') > -1" style="width: 30px;height: 30px;"/> -->
                  <img
                    src="../../assets/img/tcm/xls.png"
                    v-show="
                      item.name.indexOf('.xls') > -1 ||
                      item.name.indexOf('.xlsx') > -1
                    "
                  />
                </div>
              </div>
              <div class="upload-text">
                <p>{{ item.name }}</p>
                <!-- <span>xx KB</span> -->
              </div>
              <div
                class="file-del"
                v-show="type != 'detail' && operateType == 'application'"
                @click="toDetele(index, 'file')"
              >
                <el-icon><Close /></el-icon>
              </div>
              <el-tooltip
                :content="$t('changePriceLists.changePriceList.downloadFile')"
                placement="top-start"
              >
                <div
                  class="file-del"
                  style="right: 20px; background-color: #44814e"
                  @click="downloadFile(index)"
                >
                  <el-icon><Download /></el-icon>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="tcm-content-form" v-show="operateType != 'tcmResult'">
          <el-row>
            <el-col :span="12">
              <div class="tcm-content-titlex">
                <div style="line-height: 38px">
                  {{ $t("changePriceLists.changePriceList.email") }}
                </div>
                <el-input
                  v-show="type != 'detail' && operateType == 'application'"
                  v-model="mail"
                  placeholder="email"
                  size="small"
                  style="width: 150px; margin: 0 0 0 20px"
                />
                <el-input
                  v-show="type != 'detail' && operateType == 'application'"
                  v-model="name"
                  placeholder="name"
                  size="small"
                  style="width: 150px; margin: 0 20px"
                />
                <div v-show="type != 'detail' && operateType == 'application'">
                  <el-tooltip
                    :content="
                      $t('changePriceLists.changePriceList.SelectEmail')
                    "
                    placement="top-start"
                  >
                    <img
                      src="../../assets/img/tcm/add.svg"
                      style="margin: 8px 10px 0 10px; cursor: pointer"
                      @click="addEmail"
                    />
                  </el-tooltip>
                </div>
                <div v-show="type != 'detail' && operateType == 'application'">
                  <el-tooltip
                    :content="
                      $t(
                        'changePriceLists.changePriceList.SelectHistoryMailbox'
                      )
                    "
                    placement="top-start"
                  >
                    <img
                      src="../../assets/img/tcm/memberAdd.svg"
                      style="margin-top: 8px; cursor: pointer"
                      @click="openEmailDia"
                    />
                  </el-tooltip>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <!-- <div class="tcm-content-title-comment">
                {{ $t("changePriceLists.changePriceList.comment") }}
              </div> -->
            </el-col>
          </el-row>

          <div style="margin-top: 20px">
            <table class="gen-table">
              <tr style="background: #fff">
                <td style="width: 250px">
                  {{ $t("changePriceLists.changePriceList.email") }}
                </td>
                <td>{{ $t("changePriceLists.changePriceList.name") }}</td>
                <!-- v-show="type == 'detail' || (operateType == 'operation')" -->
                <td>{{ $t("changePriceLists.changePriceList.type") }}</td>
                <td v-show="type != 'detail' && operateType == 'application'">
                  {{ $t("changePriceLists.changePriceList.operation") }}
                </td>
              </tr>
              <tr
                v-for="(item, index) in mailTable"
                style="background: #fff; font-size: 12px"
              >
                <td>{{ item.email }}</td>
                <td>{{ item.name }}</td>
                <td v-show="type == 'detail' || operateType == 'operation'">
                  {{
                    item.type == 1
                      ? $t("changePriceLists.changePriceList.operator")
                      : item.type == 2
                      ? $t("changePriceLists.changePriceList.approver")
                      : $t("changePriceLists.changePriceList.cc")
                  }}
                </td>
                <td v-show="type != 'detail' && operateType == 'application'">
                  <el-icon
                    style="font-size: 20px; color: #445581"
                    @click="toDetele(index, 'email')"
                    ><DeleteFilled
                  /></el-icon>
                  <!-- <img
                        src="../../assets/img/tcm/cuowu.png"
                        style="width: 20px; height: 20px; cursor: pointer"
                        @click="toDetele(index, 'email')"
                      /> -->
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="tcm-content-form" v-show="operateType == 'operation'">
          <el-form :inline="true" :model="form" label-width="250px">
            <el-row>
              <el-col :span="8">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.productionBatch')
                  "
                  class="shortWidth"
                >
                  <el-input
                    v-model="form.lotSize"
                    disabled
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="
                    $t('changePriceLists.changePriceList.annualProduction')
                  "
                >
                  <el-input
                    v-model="form.productionYear"
                    disabled
                    placeholder=""
                    style="width: 214px"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <EmailDia ref="email" @getChooseData="getChooseData"></EmailDia>
  </div>

  <!-- <div class="tcm-content">
        <div class="tcm-content-title">{{$t('changePriceLists.changePriceList.generalInformation')}}</div>
        <div class="tcm-content-form">
            <el-form :inline="true" :model="form" label-width="150px">
                <el-row>
                    <el-col :span="8" class="custom-el-form-rules">
                        <el-form-item :label="($t('changePriceLists.changePriceList.quotationType'))">
                            <el-select clearable  v-model="form.quoteTypeId" disabled :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                                <el-option v-for="item in QuoteTypeOptions" :key="item.id" :label="item.name" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                    </el-col>
                    <el-col :span="8">
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8" :class="(form.quoteTypeId == '6df7494e-4a29-4f38-9a0a-abbff3bf9ef1' || form.quoteTypeId == '67a02fe3-b2a8-48a5-922c-b5c1e7da5622') ? 'custom-el-form-rules' : ''">
                        <el-form-item :label="($t('changePriceLists.changePriceList.programName'))">
                            <el-input v-model="form.programName" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.ecrNo'))" :class="form.quoteTypeId == '6df7494e-4a29-4f38-9a0a-abbff3bf9ef1' ? 'custom-el-form-rules' : ''">
                            <el-input v-model="form.ecrno" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.commodity'))" class="custom-el-form-rules">
                            <el-tree-select clearable :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')" v-model="form.commodityId" disabled :data="CommodityOptions" :props="defaultProps" check-strictly :render-after-expand="false" :default-expand-all="true" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.partDescription'))" class="custom-el-form-rules">
                            <el-input v-model="form.partDescription" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.partNumber'))" :class="(form.quoteTypeId == '6df7494e-4a29-4f38-9a0a-abbff3bf9ef1' || form.quoteTypeId == '67a02fe3-b2a8-48a5-922c-b5c1e7da5622' || form.quoteTypeId == 'cdc850d9-a228-4f63-bf28-ed6b1ce6a2c9') ? 'custom-el-form-rules' : ''">
                            <el-input v-model="form.partNumber" type="number" @input="(v) => (form.partNumber = v.replace(/[^\d.]/g, ''))" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.drawingsRevision'))">
                            <el-input v-model="form.partRevision" type="number" @input="(v) => (form.partRevision = v.replace(/[^\d.]/g, ''))" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="tcm-content-form" v-for="(item, genIndex) in genList" :key="genIndex" v-if="genCurrOptions.length > 0">
            <el-form :inline="true" :model="form" label-width="150px">
                <el-row>
                    <el-col :span="8" class="custom-el-form-rules">
                        <el-form-item :label="($t('changePriceLists.changePriceList.currency'))">
                            <el-select clearable  v-model="item.Currency" filterable disabled @change="currencyChange(genIndex)" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                                <el-option v-for="it in genCurrOptions[genIndex].currencyOptions" :key="it.currency" :label="it.currencyName" :value="it.currency" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="custom-el-form-rules">
                        <el-form-item :label="($t('changePriceLists.changePriceList.reportCurrency'))">
                            <el-select clearable  v-model="item.ReportCurrency" filterable disabled @change="reportCurrencyChange(genIndex)" :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                                <el-option v-for="it in genCurrOptions[genIndex].currencyReportOptions" :key="it.currencyCNYReportCurrency" :label="it.currencyCNYReportCurrencyName" :value="it.currencyCNYReportCurrency" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="custom-el-form-rules">
                        <el-form-item :label="($t('changePriceLists.changePriceList.exchangeRate'))">
                            <el-input v-model="item.rate" disabled style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8" class="custom-el-form-rules">
                        <el-form-item :label="($t('changePriceLists.changePriceList.region'))">
                            <el-select clearable  v-model="item.regionId" disabled :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                                <el-option v-for="item in RegionOptions" :key="item.id" :label="item.name" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.supplierName'))">
                            <el-input v-model="item.supplierName" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.materialDescription'))">
                            <el-input v-model="item.materialDescription" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.peakVolume'))">
                            <el-input v-model="item.peakVolume" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.lifetimeVolume'))">
                            <el-input v-model="item.lifetimeVolume" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.pegPoint'))">
                            <el-input v-model="item.pegPoint" type="number" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="operateType != 'tcmResult'">
                    <el-col :span="22">
                        <el-form-item :label="($t('changePriceLists.changePriceList.comments'))" style="width: 100%">
                            <el-input v-model="item.comment" :rows="3" type="textarea" placeholder="" disabled />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="gen-table-cla" v-show="operateType != 'tcmResult'">
                <div style="max-width: calc(100% - 30px); margin-right: 10px; overflow-x: auto;">
                    <table class="gen-table">
                        <tr>
                            <td>{{$t('changePriceLists.changePriceList.year')}}</td>
                            <td v-for="(it, ind) in item.yearVolumeList">
                                <el-input v-model="it.year" type="number" @input="(v) => (it.year = v.replace(/[^\d.]/g, ''))" disabled placeholder="" style="width: 100px;" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('changePriceLists.changePriceList.volume')}}</td>
                            <td v-for="(it, ind) in item.yearVolumeList" :key="'vol' + ind">
                                <el-input v-model="it.volume" type="number" disabled placeholder="" style="width: 100px;" @input="blurVol(genIndex)" @change="blurVol(genIndex)" @blur="blurVol(genIndex)" />
                            </td>
                        </tr>
                        <tr v-show=" type != 'detail' && operateType == 'application'">
                            <td>Operation</td>
                            <td v-for="(it, ind) in item.yearVolumeList">
                                <img src="../../assets/img/tcm/cuowu.png" style="width: 20px;height: 20px;cursor: pointer;" @click="toDetele(ind, 'year', genIndex)" />
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <img v-show="type != 'detail' && operateType == 'application'" src="../../assets/img/tcm/add.svg" style="width: 20px;height: 20px; cursor: pointer;" @click="addColoum(genIndex)" />
                </div>

            </div>
            <div class="align-right mgt-5" v-show="type != 'detail' && operateType == 'application'">
                <el-button type="info" @click="toDetele(genIndex, 'gen')">{{$t('unifiedPrompts.unifiedPrompt.delete')}}</el-button>
                <el-button type="info" @click="copyGen(genIndex)">Copy</el-button>
            </div>
        </div>
        <div class="tcm-form-solid" @click="addGen" v-show="type != 'detail' && operateType == 'application'">
            <el-icon :size="35" color="#8c8c8c">
                <Plus />
            </el-icon>
        </div>
        <div class="tcm-content-form" style="padding: 20px 80px;" v-show="operateType != 'tcmResult'">
            <el-row>
                <el-col :span="2">
                    <div style="line-height: 40px;">{{$t('changePriceLists.changePriceList.attachments')}}</div>
                </el-col>
                <div class="file-cla" v-show="fileListx.length > 0">
                    <div v-for="(item, index) in fileListx" style="margin-right: 10px;">
                        <div>
                            <img src="../../assets/img/tcm/doc.png" v-show="item.name.indexOf('.doc') > -1" style="width: 30px;height: 30px;" />
                            <img src="../../assets/img/tcm/tupian.png" v-show="item.name.indexOf('.png') > -1 || item.name.indexOf('.jpg') > -1 || item.name.indexOf('.jpeg') > -1 || item.name.indexOf('.webp') > -1" style="width: 30px;height: 30px;" />
                            <img src="../../assets/img/tcm/pdf.png" v-show="item.name.indexOf('.pdf') > -1" style="width: 30px;height: 30px;" />
                            <img src="../../assets/img/tcm/ppt.png" v-show="item.name.indexOf('.ppt') > -1" style="width: 30px;height: 30px;" />
                            <img src="../../assets/img/tcm/txt.png" v-show="item.name.indexOf('.txt') > -1" style="width: 30px;height: 30px;" />
                            <img src="../../assets/img/tcm/doc.png" v-show="item.name.indexOf('.doc') > -1" style="width: 30px;height: 30px;" />
                            <img src="../../assets/img/tcm/xls.png" v-show="item.name.indexOf('.xls') > -1 || item.name.indexOf('.xlsx') > -1" style="width: 30px;height: 30px;" />
                        </div>
                        <div style="text-align: center;">
                            <img v-show="type != 'detail' && operateType == 'application'" src="../../assets/img/tcm/cuowu.png" style="width: 12px;height: 12px;margin-right: 4px;cursor: pointer;" @click="toDetele(index, 'file')" />
                            <img src="../../assets/img/tcm/yunxiazai.png" style="width: 12px;height: 12px;cursor: pointer;" @click="downloadFile(index)" />
                        </div>
                    </div>
                </div>
                <el-col :span="2" v-show="type != 'detail' && operateType == 'application'">
                    <el-upload class="custom-el-upload-list-none" style="display: inline-block;margin-top: 10px;" action="/" :auto-upload="false" multiple :limit="10" :file-list="fileListx" :on-change="filechange">
                        <el-button size="small">Upload</el-button>
                    </el-upload>
                </el-col>
            </el-row>
        </div>
        <div class="tcm-content-form" v-show="operateType != 'tcmResult'">
            <el-form :inline="true" :model="form" label-width="150px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.dueDate'))" class="shortWidth">
                            <el-date-picker v-model="form.doDate" disabled type="date" placeholder="" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.urgency'))">
                            <el-select clearable  v-if="$t('tcmdatalists.tcmdatalist.urgencyOptionss')=='urgencyOptions' " v-model="form.urgencyId" disabled :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                                <el-option v-for="item in urgencyOptions" :key="item.id" :label="item.name" :value="item.id" />
                            </el-select>
                            <el-select clearable  v-if="$t('tcmdatalists.tcmdatalist.urgencyOptionss')=='urgencyOptionss' " v-model="form.urgencyId" disabled :placeholder="$t('tcmdatalists.tcmdatalist.pleaseSlect')">
                                <el-option v-for="item in urgencyOptionss" :key="item.id" :label="item.name" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
        </div>
        <div class="tcm-content-form" style="padding: 20px 80px;" v-show="operateType != 'tcmResult'">
            <div class="tcm-content-titlex">
                <div style="line-height: 38px;">{{$t('changePriceLists.changePriceList.email')}}</div>
                <el-input v-show="type != 'detail' && operateType == 'application'" v-model="mail" placeholder="email" size="small" style="width: 150px; margin: 0 0 0 20px;" />
                <el-input v-show="type != 'detail' && operateType == 'application'" v-model="name" placeholder="name" size="small" style="width: 150px; margin: 0 20px;" />
                <div v-show="type != 'detail' && operateType == 'application'">
                    <img src="../../assets/img/tcm/add.svg" style="margin-top: 8px;margin-right: 10px; cursor: pointer;" @click="addEmail" />
                </div>
                <div v-show="type != 'detail' && operateType == 'application'">
                    <img src="../../assets/img/tcm/memberAdd.svg" style="margin-top: 8px; cursor: pointer;" @click="openEmailDia" />
                </div>
            </div>
            <div style="margin-top: 20px;">
                <table class="gen-table">
                    <tr>
                        <td style="width: 250px;">{{$t('changePriceLists.changePriceList.email')}}</td>
                        <td>{{$t('changePriceLists.changePriceList.name')}}</td>
						<td v-show="type == 'detail' || (operateType == 'operation')">{{$t('changePriceLists.changePriceList.type')}}</td>
                        <td v-show="type != 'detail' && operateType == 'application'">{{$t('changePriceLists.changePriceList.operation')}}</td>
                    </tr>
                    <tr v-for="(item, index) in mailTable">
                        <td>{{item.email}}</td>
                        <td>{{item.name}}</td>
						<td v-show="type == 'detail' || (operateType == 'operation')">
							{{item.type == 1 ? $t('changePriceLists.changePriceList.operator') : item.type == 2 ? $t('changePriceLists.changePriceList.approver') : $t('changePriceLists.changePriceList.cc')}}
						</td>
                        <td v-show="type != 'detail' && operateType == 'application'">
                            <img src="../../assets/img/tcm/cuowu.png" style="width: 20px;height: 20px;cursor: pointer;" @click="toDetele(index, 'email')" />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="tcm-content-form" v-show="operateType == 'operation'">
            <el-form :inline="true" :model="form" label-width="250px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.productionBatch'))" class="shortWidth">
                            <el-input v-model="form.lotSize" disabled placeholder="" style="width: 214px;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="($t('changePriceLists.changePriceList.annualProduction'))">
                            <el-input v-model="form.productionYear" disabled placeholder="" style="width: 214px;" @input="toCompute" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <EmailDia ref="email" @getChooseData="getChooseData"></EmailDia>
    </div> -->
</template>

<script>
import "../../assets/css/custom-el-upload-list-none.css";
import "../../assets/css/custom-el-form-label.css";
import "../../assets/css/custom-el-form-rules.css";
import commonUtil from "../../assets/js/commonUtil.js";
import {
  GetRate,
  AddOrUpdateMailHistory,
  GetSelCurrency,
} from "../../assets/js/requestApi.js";
import EmailDia from "../tcmApplication/emailDia.vue";
export default {
  props: [
    "RegionOptions",
    "QuoteTypeOptions",
    "CommodityOptions",
    "urgencyOptions",
    "urgencyOptionss",
    "type",
    "currencyOptions",
    "currencyReportOptions",
    "operateType",
  ],
  components: {
    EmailDia,
  },
  data() {
    return {
      form: {
        quoteTypeId: "",
        programName: "",
        ecrno: "",
        commodityId: "",
        partDescription: "",
        partNumber: "",
        partRevision: "",
        lotSize: "",
        productionYear: "",
        doDate: "",
        urgencyId: "",
        comment: "",
      },
      defaultProps: {
        children: "children",
        label: "Name",
      },
      genList: [
        {
          Currency: "",
          ReportCurrency: "",
          rate: "",
          currencyRateId: "",
          regionId: "",
          supplierName: "",
          materialDescription: "",
          peakVolume: "",
          lifetimeVolume: "",
          pegPoint: "",
          comment: "",
          yearVolumeList: [],
        },
      ],
      genCurrOptions: [],
      // 上传的文件
      fileListx: [],
      // 新增邮件名
      mail: "",
      // 新增邮箱姓名
      name: "",
      mailTable: [],
    };
  },
  methods: {
    addColoum(index) {
      this.genList[index].yearVolumeList.push({
        year: "",
        volume: "",
      });
    },
    // 计算
    blurVol(index) {
      let _this = this;
      let VolList = [];
      this.genList[index].lifetimeVolume = 0;
      this.genList[index].yearVolumeList.forEach(function (val, ind) {
        VolList.push(val.volume);
        val.volume = parseFloat(val.volume);
        if (val.volume != "") {
          _this.genList[index].lifetimeVolume += parseFloat(val.volume);
        }
      });
      let arr = VolList.sort((a, b) => b - a);
      this.genList[index].peakVolume = arr[0];
    },
    // currency下拉框change事件
    currencyChange(index) {
      if (this.genList[index].Currency != "") {
        this.getCurrencyOptions(index);
      }
      if (this.genList[index].ReportCurrency != "") {
        let data = {
          currency: this.genList[index].Currency,
          reportCurrency: this.genList[index].ReportCurrency,
        };
        this.getRates(data, index);
      }
    },
    // reportcurrency下拉框change事件
    reportCurrencyChange(index) {
      if (this.genList[index].ReportCurrency != "") {
        this.getCurrencyOptions(index);
      }
      if (this.genList[index].Currency != "") {
        let data = {
          currency: this.genList[index].Currency,
          reportCurrency: this.genList[index].ReportCurrency,
        };
        this.getRates(data, index);
      }
    },
    // 查询税率表区域选项
    getCurrencyOptions(index) {
      GetSelCurrency(
        {
          CurrId: this.genList[index].Currency,
          CurrCNYId: this.genList[index].ReportCurrency,
          isEnable: 1,
        },
        (response) => {
          if (response && response.code == 200) {
            this.genCurrOptions[index] = {
              currencyOptions: response.data[0].exchangeList,
              currencyReportOptions: response.data[0].exchangeCNYList,
            };
            this.$forceUpdate();
          } else {
            commonUtil.showTipInfo(
              this.$t("userlists.userlist.inqueryTimeout")
            );
          }
        }
      );
    },
    // 获取汇率方法
    getRates(data, index) {
      GetRate(data, (response) => {
        if (response && response.code == 200) {
          if (response.result == null) {
            commonUtil.showTipInfo(
              "当前数据无汇率，请重新选择！",
              "",
              "warning"
            );
            this.genList[index].rate = "";
            this.genList[index].currencyRateId = "";
          } else {
            this.genList[index].rate = response.result.exchangeRate;
            this.genList[index].currencyRateId = response.result.id;
          }
        }
      });
    },
    // 文件上传
    filechange(file, fileList) {
      this.fileListx.push(file);
    },
    // 添加邮件
    addEmail() {
      let _this = this;
      if (this.mail == "") {
        this.$message({
          message: "请输入邮箱！",
          type: "warning",
        });
        return;
      }
      let reg =
        /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      if (!reg.test(this.mail)) {
        this.$message({
          message: "请输入正确的邮箱！",
          type: "warning",
        });
        return;
      }
      let isSampleEmail = false;
      this.mailTable.forEach(function (val, index) {
        if (val.email == _this.mail) {
          isSampleEmail = true;
        }
      });
      if (isSampleEmail) {
        this.$message({
          message: "邮箱不可重复！",
          type: "warning",
        });
        return;
      }
      this.mailTable.push({
        email: this.mail,
        name: this.name,
      });
      AddOrUpdateMailHistory(
        {
          email: this.mail,
          name: this.name,
        },
        (response) => {}
      );
      this.mail = "";
      this.name = "";
    },
    // 获取勾选的邮箱数据
    getChooseData(data) {
      let _this = this;
      data.forEach(function (val, index) {
        if (
          JSON.stringify(_this.mailTable).indexOf(JSON.stringify(val)) === -1
        ) {
          _this.mailTable.push(val);
        }
      });
    },
    // 删除邮件
    toDetele(index, type, ind) {
      if (type == "year") {
        this.genList[ind].yearVolumeList.splice(index, 1);
        // this.tableData.splice(index, 1)
      } else if (type == "email") {
        this.mailTable.splice(index, 1);
      } else if (type == "file") {
        this.fileListx.splice(index, 1);
      } else if (type == "gen") {
        this.genList.splice(index, 1);
      }
    },
    // 复制
    copyGen(index) {
      this.genList.push(JSON.parse(JSON.stringify(this.genList[index])));
      this.genCurrOptions.push({
        currencyOptions: this.currencyOptions,
        currencyReportOptions: this.currencyReportOptions,
      });
    },
    // 新增
    addGen(index) {
      this.genList.push({
        Currency: "",
        ReportCurrency: "",
        rate: "",
        currencyRateId: "",
        regionId: "",
        supplierName: "",
        materialDescription: "",
        peakVolume: "",
        lifetimeVolume: "",
        pegPoint: "",
        comment: "",
        yearVolumeList: [],
      });
      this.genCurrOptions.push({
        currencyOptions: this.currencyOptions,
        currencyReportOptions: this.currencyReportOptions,
      });
    },
    // 计算
    toCompute() {
      let _this = this;
      if (this.form.productionYear != "" && this.genList[0].peakVolume != "") {
        this.form.lotSize =
          this.genList[0].peakVolume / this.form.productionYear;
      }
      if (this.operateType == "operation" && this.form.lotSize != "") {
        this.$parent.$refs.proTcm.formData.forEach(function (val, index) {
          val.annualVolume = _this.genList[0].peakVolume;
          val.pieceLot = _this.form.lotSize;
          // _this.$parent.$refs.proTcm.toComputeAll(val)
        });
        this.$parent.$refs.proTcm.formData.forEach(function (val, index) {
          _this.$parent.$refs.proTcm.toComputeAll(val);
        });
      }
    },
    // 打开历史邮箱
    openEmailDia() {
      this.$refs.email.getlist();
      this.$refs.email.visible = true;
    },
    // 下载文件
    downloadFile(index) {
      if (
        this.fileListx[index].name.indexOf(".png") > -1 ||
        this.fileListx[index].name.indexOf(".jpg") > -1 ||
        this.fileListx[index].name.indexOf(".jpeg") > -1 ||
        this.fileListx[index].name.indexOf(".webp") > -1
      ) {
        if (this.fileListx[index].raw) {
          commonUtil.downloadPicByFile(
            this.fileListx[index].raw,
            this.fileListx[index].name.split(".")[0]
          );
        } else {
          commonUtil.downloadPicByUrl(
            this.fileListx[index].url,
            this.fileListx[index].name.split(".")[0]
          );
        }
      } else {
        let arr = this.fileListx[index].name.split(".");
        if (this.fileListx[index].raw) {
          commonUtil.downloadFileByArryBuffer(
            this.fileListx[index].raw,
            this.fileListx[index].name.split(".")[0],
            arr[arr.length - 1]
          );
        } else {
          commonUtil.downloadFileByUrl(
            this.fileListx[index].url,
            this.fileListx[index].name
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* ---------------------------- */
/deep/ .el-input__wrapper,
/deep/ .el-textarea__inner {
  background-color: transparent !important;
}
.General-Infor {
  margin-bottom: 10px;
  padding: 20px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.7);
  .General-Infor-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }
}
.submit-btn {
  margin-right: 10px;
  padding: 0 30px;
  height: 30px;
  line-height: 30px;
  background: #445581;
  color: #fff;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}
.el-row p {
  padding: 10px 0;
  font-size: 14px;
  color: #202020;
}

.upload-box {
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
  border: 1px dashed #c2c2c2;
  background-color: #f4fcf6;
  border-radius: 10px;
  cursor: pointer;
}
.upload-btn {
  margin-left: 10px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  background-color: #44814e;
  color: #fff;
  border-radius: 5px;
}
.upload-icon img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.upload-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #171b1e;
  font-size: 14px;

  p {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    color: #717579;
    font-size: 13px;
  }
}
.file-box {
  margin-left: 15px;
  position: relative;
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #44814e;
  .upload-btn {
    background-color: #f4fcf6;
    color: #44814e;
  }
  .file-del {
    position: absolute;
    top: -10px;
    right: -7px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    background-color: #fd5353;
  }
}

.Year-Volume {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  p {
    flex: 1;
  }
  span {
    width: 100px;
  }
}
.Year-Volume-ipt >>> .el-input__inner {
  border: 0 !important;
  outline: none; /* 移除默认边框 */
  border: none;
  border-radius: 0;
  caret-color: red;
}
.Year-Volume-ipt >>> .el-input__inner .is-focus {
  border: 0 !important;
  outline: none; /* 移除默认边框 */
  border: none;
}
.Year-Volume-ipt {
  margin-top: 10px;
  display: flex;
  border: 1px solid #969ba0;
  border-radius: 5px;
  /deep/ .el-input__inner {
    border: 0 !important;
  }
  /deep/.el-input__inner:focus {
    // el-input输入时设置边框颜色
    border: none !important;
  }
  .year-ipt,
  .Volume-ipt {
    flex: 1;
    height: 25px;
    background: transparent;
    border-radius: 0 !important;
  }
  .Volume-ipt {
    border: none !important;
    border-left: 1px solid #969ba0 !important;
    border-right: 1px solid #969ba0 !important;
  }
  .Operation-box {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--themeColor);
    .el-icon {
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
.Year-Volume-add-btn {
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.tcm-form-solid {
  width: calc(100% -50px);
  border-radius: 5px;
  border: 1px dashed #8c8c8c;
  /* margin-left: 50px; */
  margin: 10px 0;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}
// 邮箱

.gen-table-cla {
  position: relative;
  display: flex;
}
.gen-table {
  border-collapse: collapse;
}
.gen-table td {
  border: 1px solid #dcdfe6;
  padding: 5px 10px;
  text-align: center;
}

.tcm-content {
  padding: 20px 90px 200px 20px;
  box-sizing: border-box;
}
.mgt-5 {
  margin-top: 5px;
}
.align-right {
  text-align: right;
}
.tcm-content-title {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px 20px 20px 50px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}
.tcm-content-title:before {
  content: "";
  display: table;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 27px;
}
.tcm-content-form {
  margin-top: 30px;
  box-sizing: border-box;
}
.gen-table-cla {
  position: relative;
  display: flex;
}
.gen-table {
  border-collapse: collapse;
}
.gen-table td {
  border: 1px solid #dcdfe6;
  padding: 5px 10px;
  text-align: center;
}
.file-cla {
  padding: 0px 20px 0px 0;
  display: flex;
}
.tcm-content-titlex {
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  display: flex;
}
.tcm-content-title-comment {
  line-height: 38px;
}
</style>