var configUtil = {
	// 开发环境
	//gatewayUrl:"http://localhost:52789/",
	// gatewayUrl: "http://47.100.136.70:85/",
	// 测试环境
	// gatewayUrl: "http://47.100.168.238:81/",
	// 正式环境
	gatewayUrl: "https://vemapi.nexteerchina.cn/",
	// 二期环境
	// gatewayUrl: "http://47.100.136.70:86/",
	//请求超时时间(毫秒)
	requestTimeOut: 300000
}
export default configUtil